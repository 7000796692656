const errorAnimation = {
  v: "5.4.4",
  fr: 60,
  ip: 0,
  op: 151,
  w: 640,
  h: 640,
  nm: "Cono-Error 4",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "linea 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: { a: 0, k: [298.5, 112.5, 0], ix: 2 },
        a: { a: 0, k: [-44, -183, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-44, -183],
                    [-74.5, -183]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.815686334348, 0.023529413635, 0.105882360421, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.6], y: [0] },
                t: 64,
                s: [0],
                e: [100]
              },
              { t: 72 }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.167], y: [0.167] },
                t: 52,
                s: [0],
                e: [100]
              },
              { t: 60 }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 52,
      op: 216,
      st: 36,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "linea 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 45, ix: 10 },
        p: { a: 0, k: [281.5, 119.5, 0], ix: 2 },
        a: { a: 0, k: [-44, -183, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-44, -183],
                    [-74.5, -183]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.815686334348, 0.023529413635, 0.105882360421, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.6], y: [0] },
                t: 64,
                s: [0],
                e: [100]
              },
              { t: 72 }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.167], y: [0.167] },
                t: 52,
                s: [0],
                e: [100]
              },
              { t: 60 }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 52,
      op: 216,
      st: 36,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "linea 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [276, 137, 0], ix: 2 },
        a: { a: 0, k: [-44, -183, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-44, -183],
                    [-74.5, -183]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.815686334348, 0.023529413635, 0.105882360421, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.6], y: [0] },
                t: 64,
                s: [0],
                e: [100]
              },
              { t: 72 }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.167], y: [0.167] },
                t: 52,
                s: [0],
                e: [100]
              },
              { t: 60 }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 52,
      op: 216,
      st: 36,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 25, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [320, 317, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [266.852, 10.895], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [10.426, 166.447], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "fl",
          c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: "Fill 1",
          mn: "ADBE Vector Graphic - Fill",
          hd: false
        }
      ],
      ip: 17,
      op: 48,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 3,
      nm: "bounce",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: { a: 0, k: 326, ix: 3 },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.229], y: [1] },
                o: { x: [0.075], y: [0.25] },
                t: 16,
                s: [483.5],
                e: [409.5]
              },
              {
                i: { x: [0.964], y: [0.525] },
                o: { x: [0.565], y: [0] },
                t: 28,
                s: [409.5],
                e: [483.5]
              },
              { t: 48 }
            ],
            ix: 4
          }
        },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, -15.667] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 3,
              s: [0, 0, 100],
              e: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 16,
              s: [100, 100, 100],
              e: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 22,
              s: [100, 100, 100],
              e: [100, 100, 100]
            },
            {
              i: { x: [0.432, 0.99, 0.99], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 48,
              s: [100, 100, 100],
              e: [110, 91, 100]
            },
            {
              i: { x: [0.6, 0.6, 0.6], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, -0.167, -2.5] },
              t: 51,
              s: [110, 91, 100],
              e: [95, 106, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
              o: { x: [0.152, 0.245, 0.4], y: [0, 0, 0] },
              t: 60,
              s: [95, 106, 100],
              e: [100, 100, 100]
            },
            { t: 70 }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 3,
      nm: "escalador",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [320, 320, 0], ix: 2 },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [400, 400, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "cono 3",
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-67.732, 54.796, 0], ix: 2 },
        a: { a: 0, k: [1.567, 82.574, 0], ix: 1 },
        s: { a: 0, k: [400, 400, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.716, 0],
                    [0, 0],
                    [0, 0.717],
                    [-0.715, 0],
                    [0, 0],
                    [0, -0.716]
                  ],
                  o: [
                    [0, 0],
                    [-0.715, 0],
                    [0, -0.716],
                    [0, 0],
                    [0.716, 0],
                    [0, 0.717]
                  ],
                  v: [
                    [7.674, 1.298],
                    [-7.673, 1.298],
                    [-8.971, 0.001],
                    [-7.673, -1.298],
                    [7.674, -1.298],
                    [8.971, 0.001]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.059000000299, 0.169000004787, 0.289999988032, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [54.505, 73.284], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.718, 0],
                    [0, 0],
                    [0, 0.717],
                    [-0.717, 0],
                    [0, 0],
                    [0, -0.716]
                  ],
                  o: [
                    [0, 0],
                    [-0.717, 0],
                    [0, -0.716],
                    [0, 0],
                    [0.718, 0],
                    [0, 0.717]
                  ],
                  v: [
                    [13.458, 1.298],
                    [-13.46, 1.298],
                    [-14.757, 0.001],
                    [-13.46, -1.298],
                    [13.458, -1.298],
                    [14.757, 0.001]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.059000000299, 0.169000004787, 0.289999988032, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [25.807, 73.284], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 2",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.717, 0],
                    [0, 0],
                    [0, 0.716],
                    [0, 0],
                    [-0.716, 0],
                    [0, 0],
                    [0, 0],
                    [-0.598, 0],
                    [0, 0],
                    [-0.139, -0.582],
                    [0, 0],
                    [0, 0],
                    [0, -0.716],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-0.716, 0],
                    [0, 0],
                    [0, -0.716],
                    [0, 0],
                    [0, 0],
                    [0.141, -0.582],
                    [0, 0],
                    [0.597, 0],
                    [0, 0],
                    [0, 0],
                    [0.717, 0],
                    [0, 0],
                    [0, 0.716]
                  ],
                  v: [
                    [32.458, 41.949],
                    [-32.459, 41.949],
                    [-33.755, 40.652],
                    [-33.755, 31.336],
                    [-32.459, 30.038],
                    [-22.965, 30.038],
                    [-5.78, -40.958],
                    [-4.52, -41.949],
                    [4.521, -41.949],
                    [5.78, -40.958],
                    [22.965, 30.038],
                    [32.458, 30.038],
                    [33.754, 31.336],
                    [33.754, 40.652]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.139, 0.582],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.598, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.597, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.141, 0.582],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-31.162, 39.354],
                    [31.161, 39.354],
                    [31.161, 32.633],
                    [21.944, 32.633],
                    [20.685, 31.641],
                    [3.499, -39.355],
                    [-3.499, -39.355],
                    [-20.684, 31.641],
                    [-21.944, 32.633],
                    [-31.162, 32.633]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 2",
              mn: "ADBE Vector Filter - Merge",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.059000000299, 0.169000004787, 0.289999988032, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [33.754, 41.949], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 3",
          np: 5,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-32.458, 2.285],
                    [32.458, 2.285],
                    [32.458, -2.285],
                    [-32.458, -2.285]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [33.754, 75.569], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 4",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [5.836, 35.993],
                    [-10.933, -35.993],
                    [-7.028, -35.993],
                    [10.933, 35.993]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [45.303, 37.291], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 5",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-17.63, 4.154],
                    [17.63, 4.154],
                    [17.63, -4.154],
                    [-17.63, -4.154]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [33.508, 54.44], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 6",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [14.191, 4.154],
                    [-14.191, 4.154],
                    [-12.182, -4.154],
                    [12.018, -4.154]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [34.371, 36.769], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 7",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [21.943, 31.335],
                    [4.518, -40.652],
                    [2.168, -40.652],
                    [-2.169, -40.652],
                    [-4.519, -40.652],
                    [-21.946, 31.335],
                    [-32.458, 31.335],
                    [-32.458, 40.652],
                    [32.458, 40.652],
                    [32.458, 31.335]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.816000007181, 0.023999999551, 0.105999995213, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [34.291, 41.949], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 8",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 193,
      st: 13,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [177.195, 341.379, 0], ix: 2 },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [158, 158, 100], ix: 6 }
      },
      ao: 0,
      ip: 47,
      op: 12657,
      st: 31,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "o1 Outlines",
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [60, 60, 0], ix: 2 },
        a: { a: 0, k: [4.95, 4.96, 0], ix: 1 },
        s: { a: 0, k: [200, 200, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.52, 0],
                    [0, -1.524],
                    [1.519, 0],
                    [0, 1.524]
                  ],
                  o: [
                    [1.519, 0],
                    [0, 1.524],
                    [-1.52, 0],
                    [0, -1.524]
                  ],
                  v: [
                    [0, -2.76],
                    [2.75, 0],
                    [0, 2.76],
                    [-2.75, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.050980395897, 0.309803921569, 0.501960784314, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.64], y: [1] },
                    o: { x: [0.36], y: [0] },
                    t: 63,
                    s: [0],
                    e: [2.2]
                  },
                  { t: 100 }
                ],
                ix: 5
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [4.95, 4.96], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.529, 0.529], y: [1, 1] },
                    o: { x: [0.259, 0.259], y: [0, 0] },
                    t: 63,
                    s: [0, 0],
                    e: [120, 120]
                  },
                  {
                    i: { x: [0.49, 0.49], y: [1, 1] },
                    o: { x: [0.362, 0.362], y: [0, 0] },
                    t: 84.35,
                    s: [120, 120],
                    e: [100, 100]
                  },
                  { t: 97 }
                ],
                ix: 3
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 63,
      op: 12673,
      st: 44,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "mancha Outlines 2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.36], y: [0] },
              t: 0,
              s: [0],
              e: [100]
            },
            { t: 16 }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [332.493, 354.208, 0], ix: 2 },
        a: { a: 0, k: [58.593, 51.424, 0], ix: 1 },
        s: { a: 0, k: [400, 400, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-35.069, 5.209],
                    [-5.087, 8.567],
                    [20.22, -0.165],
                    [37.913, -10.871],
                    [-5.324, -18.568]
                  ],
                  o: [
                    [35.069, -5.209],
                    [9.85, -16.588],
                    [-19.724, 4.125],
                    [-23.753, 6.811],
                    [5.325, 18.568]
                  ],
                  v: [
                    [2.952, 45.965],
                    [48.493, 24.407],
                    [29.589, -21.777],
                    [-23.984, -40.303],
                    [-53.019, 4.408]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.898039215686, 0.898039215686, 0.898039215686, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [58.593, 51.424], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export default errorAnimation;
