import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: 50,
    width: "inherit",
    borderTop: props =>
      props.borderTop ? `1px solid ${theme.palette.divider}` : "",
    background: props =>
      props.borderTop ? theme.palette.background.paper : "",
    zIndex: "1000"
  },
  inner: {
    padding: theme.spacing(2, 2, 4),
    color: theme.palette.common.black
  },
  bottomBar: {
    height: props => (props.height ? props.height : "50px"),
    paddingBottom: "50px"
  }
}));

export const DrawerStaticBottomNote = ({
  noteComponent,
  height,
  borderTop
}) => {
  const classes = useStyles({ height, borderTop });

  return (
    <>
      <div className={classes.bottomBar} />
      <div className={classes.root}>
        <div className={classes.inner}>{noteComponent}</div>
      </div>
    </>
  );
};

DrawerStaticBottomNote.propTypes = {
  noteComponent: PropTypes.node,
  height: PropTypes.string,
  borderTop: PropTypes.bool
};
