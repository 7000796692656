import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

export const PhoneMask = React.forwardRef(function NumberFormatCustom({
  ...rest
}) {
  return (
    <MaskedInput
      {...rest}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={"\u2000"}
    />
  );
});

PhoneMask.propTypes = {
  inputRef: PropTypes.func
};
