import React, { useContext } from "react";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { OwnerAlertContext } from "Components/Utils/OwnerAlertProvider";

const OwnerGeneralAlert = () => {
  const { getJSONValue } = useContext(FirebaseContext);
  const { disableAlert } = useContext(OwnerAlertContext);

  const configuration = getJSONValue("owner_web_banner");
  return (
    <Alert
      sx={{ backgroundColor: grey[800] }}
      variant="filled"
      severity="error"
      onClose={disableAlert}
    >
      <Typography variant="body1" component="p">
        {configuration?.description}
      </Typography>
    </Alert>
  );
};

export default OwnerGeneralAlert;
