import React, { useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomDateDialog from "Components/Dialog/CustomDateDialog";
import {
  updateTransactionDateFilters,
  handleTransactionsCustomDateFilters,
  toggleTransactionsDateDialog
} from "Redux/Financials/ActionCreators";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  customMenuItem: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: "8px"
  },
  lastMenuItem: {
    marginBottom: "6px"
  },
  formControl: {
    display: "flex",
    minWidth: "210px"
  }
}));

export const TransactionsDateFilter = connect(
  state => ({
    dateFilters: get(state, "fleet.financials.transactions")
  }),
  {
    toggleTransactionsDateDialog,
    updateTransactionDateFilters,
    handleTransactionsCustomDateFilters
  }
)(
  ({
    dateFilters,
    updateTransactionDateFilters,
    handleTransactionsCustomDateFilters,
    toggleTransactionsDateDialog,
    customHandlers,
    customOptions,
    defaultStartDate,
    defaultEndDate,
    updateFieldDateFilters,
    handleFieldCustomDateFilters,
    toggleFieldDateDialog,
    customDateFilters,
    dateRangeMaxDays = 90
  }) => {
    const classes = useStyles();
    let location = useLocation();

    useEffect(() => {
      if (location?.state?.initializeDate) {
        updateTransactionDateFilters(location?.state?.initializeDate);
      }
    }, [location.state, updateTransactionDateFilters]);

    const dateFiltersObject = customHandlers ? customDateFilters : dateFilters;

    const {
      dateDescriptionText,
      startDate,
      endDate,
      customDateDialogOpen
    } = dateFiltersObject;

    const handleDateRangeSelectionChange = selectedRange => {
      if (selectedRange.toLowerCase() !== "custom") {
        customHandlers
          ? updateFieldDateFilters(selectedRange)
          : updateTransactionDateFilters(selectedRange);
      } else {
        handleCustomDateSelect();
      }
    };

    const handleCustomDateSelect = () =>
      customHandlers
        ? toggleFieldDateDialog(true)
        : toggleTransactionsDateDialog(true);

    const handleDialogClose = () => {
      customHandlers
        ? toggleFieldDateDialog(false)
        : toggleTransactionsDateDialog(false);
    };

    const handleCustomDateRange = (startDate, endDate) => {
      const customRange = {
        startDate: startDate.startOf("day"),
        endDate: endDate.endOf("day")
      };
      customHandlers
        ? handleFieldCustomDateFilters(customRange)
        : handleTransactionsCustomDateFilters(customRange);
    };

    // The oldest account in Stripe - also our CEO's account :)
    const oldestAccountDate = new Date("10/01/2015");
    const formattedStart = moment(startDate).format("MMM D, YYYY");
    const formattedEnd = moment(endDate).format("MMM D, YYYY");
    const viewingLabel =
      formattedEnd === formattedStart
        ? formattedStart
        : `${formattedStart} - ${formattedEnd}`;

    const selectLabel = startDate ? viewingLabel : "";

    const renderDropdown = () => {
      if (customOptions) {
        return (
          <Select
            value={dateDescriptionText}
            onChange={e => handleDateRangeSelectionChange(e.target.value)}
            labelId="transactions-date-select"
            name="filter"
            label={selectLabel}
          >
            {customOptions.map((optionText, index) => {
              const menuItemProps = {
                value: optionText
              };
              if (index === customOptions.length - 1) {
                menuItemProps.classes = { root: classes.customMenuItem };
              }
              if (index === customOptions.length - 2) {
                menuItemProps.classes = { root: classes.lastMenuItem };
              }
              return (
                <MenuItem key={optionText} {...menuItemProps}>
                  {optionText}
                </MenuItem>
              );
            })}
          </Select>
        );
      }
      return (
        <Select
          value={dateDescriptionText}
          onChange={e => handleDateRangeSelectionChange(e.target.value)}
          labelId="transactions-date-select"
          name="filter"
          variant="standard"
          label={selectLabel}
        >
          <MenuItem value={"Today"}>Today</MenuItem>
          <MenuItem value={"Yesterday"}>Yesterday</MenuItem>
          <MenuItem value={"Past 7 days"}>Past 7 days</MenuItem>
          <MenuItem value={"Past 30 days"}>Past 30 days</MenuItem>
          <MenuItem value={"Past 90 days"}>Past 90 days</MenuItem>
          <MenuItem value={"Week to Date"}>Week to Date</MenuItem>
          <MenuItem value={"Month to Date"}>Month to Date</MenuItem>
          <MenuItem className={classes.lastMenuItem} value={"Quarter to Date"}>
            Quarter to Date
          </MenuItem>
          <MenuItem
            className={classes.customMenuItem}
            onClick={() => handleCustomDateSelect()}
            value={"Custom"}
          >
            Custom
          </MenuItem>
        </Select>
      );
    };

    return (
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="transactions-date-select">
          {selectLabel}
        </InputLabel>
        {renderDropdown()}
        {customDateDialogOpen && (
          <CustomDateDialog
            open={customDateDialogOpen}
            handleUpdate={handleCustomDateRange}
            handleClose={handleDialogClose}
            defaultStartDate={customHandlers ? defaultStartDate : startDate}
            defaultEndDate={customHandlers ? defaultEndDate : endDate}
            dateRangeMaxDays={dateRangeMaxDays}
            oldestAcceptableDate={oldestAccountDate}
            invalidDateMessage={"Please select a date range up to 90 days"}
          />
        )}
      </FormControl>
    );
  }
);
