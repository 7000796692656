import React, { useContext } from "react";
import jwtDecode from "jwt-decode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import get from "lodash/get";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { Logo } from "Components/Logo/Logo";
import { checkAllPermissions } from "Utils/RooftopUtils";
import { RooftopDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  gutters: {
    padding: ({ matches }) => matches && theme.spacing(0)
  },
  divider: {
    backgroundColor: "white",
    height: theme.spacing(4.375),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2)
  },
  rooftopHeaderTitle: {
    fontSize: theme.spacing(3)
  },
  rooftopHeaderTitleMobile: {
    fontSize: theme.spacing(2),
    overflow: "hidden"
  }
}));

export const HCBusinessHeader = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const { scopes, currentRooftopToken } = useContext(ClientFactoryContext);

  const currentRooftopName = get(jwtDecode(currentRooftopToken), "name");

  return (
    <AppBar position="fixed">
      <ToolBar classes={{ gutters: classes.gutters }}>
        <Grid container alignItems="center">
          {!matches ? (
            <>
              {checkAllPermissions(
                [RooftopDomainPermissionPaths.viewHeader],
                scopes
              ) ? (
                <>
                  <Logo containStretch subTitle="FOR BUSINESS" />
                  <Divider orientation="vertical" className={classes.divider} />
                  <Typography className={classes.rooftopHeaderTitle}>
                    {currentRooftopName}
                  </Typography>
                </>
              ) : (
                <Logo containStretch />
              )}
            </>
          ) : (
            <>
              {checkAllPermissions(
                [RooftopDomainPermissionPaths.viewHeader],
                scopes
              ) ? (
                <Typography className={classes.rooftopHeaderTitleMobile}>
                  {currentRooftopName.length < 24
                    ? currentRooftopName
                    : `${currentRooftopName.slice(0, 24)}...`}
                </Typography>
              ) : (
                <Logo />
              )}
            </>
          )}
        </Grid>
        {children}
      </ToolBar>
    </AppBar>
  );
};

HCBusinessHeader.propTypes = {
  children: PropTypes.node
};
