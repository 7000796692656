import React from "react";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";

const rows = ["70%", "90%", "60%", "80%"];

const SummarySkeleton = ({ columns }) => {
  const fixedWidthColumns = [...Array(columns - 1)].map((e, i) => (
    <TableCell align="right" key={i}>
      <Skeleton />
    </TableCell>
  ));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="45%">
            <Skeleton width="80%" />
          </TableCell>
          {fixedWidthColumns}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              <Skeleton width={row} />
            </TableCell>
            {fixedWidthColumns}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

SummarySkeleton.propTypes = {
  columns: PropTypes.number
};

export default SummarySkeleton;
