import { gql } from "@apollo/client";

import { AuthMutationFragments } from "./AuthMutationFragments";

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      success
      message
    }
  }
`;

export const CONFIRM_RESET_PASSWORD = gql`
  mutation ConfirmResetPassword(
    $password: String!
    $confirmPassword: String!
    $token: String!
  ) {
    confirmResetPassword(
      input: {
        token: $token
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      message
      success
    }
  }
`;

export const SIGN_UP_USER = gql`
  mutation SignUpUser(
    $firstName: String!
    $lastName: String!
    $userType: UserTypeEnum!
    $password: String!
    $email: String!
    $phone: String!
    $zip: String!
    $referrer: ReferrerInput
    $impactClickId: String
  ) {
    signUpUser(
      input: {
        name: { firstName: $firstName, lastName: $lastName }
        userType: $userType
        password: $password
        email: $email
        phone: $phone
        zip: $zip
        referrer: $referrer
        impactClickId: $impactClickId
      }
    ) {
      ...SignUpUserFragment
    }
  }
  ${AuthMutationFragments.signUpUserFragment}
`;

export const RECAPTCHA = gql`
  mutation Recaptcha($data: RecaptchaInput!) {
    recaptcha(data: $data)
  }
`;
