import gql from "graphql-tag";

import { InboxTemplateFragments } from "Queries/InboxTemplate/InboxTemplateFragments";

export const UPDATE_INBOX_TEMPLATE = gql`
  mutation UpdateInboxTemplate($input: UpdateInboxTemplateInput!) {
    updateInboxTemplate(input: $input) {
      ...InboxTemplateInfo
    }
  }
  ${InboxTemplateFragments.inboxTemplate}
`;
