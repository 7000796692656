import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import { FormTextField } from "Components/Inputs/FormTextField";
import { FormSelect } from "Components/Inputs/FormSelect";
import { ProtectionPlanEnum } from "Enums/StateEnums";
import { centsToDollarsInt } from "Utils/Calculations";

const FORM_NAME = "CAR_PRICE_UPDATE";

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    height: theme.spacing(14)
  },
  note: {
    fontSize: 12,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  warning: {
    color: theme.palette.warning.main
  }
}));

const capitalize = text =>
  text ? `${text[0].toUpperCase()}${text.substring(1).toLowerCase()}` : "";

const protectionPlanOptions = Object.values(ProtectionPlanEnum).map(plan => ({
  label: capitalize(plan),
  value: plan
}));

const normalizePrice = (value, previousValue) => {
  const regex = /^[0-9]*$/i;
  return regex.test(value) ? value : previousValue;
};

const Form = ({ price, oldPriceInCents }) => {
  const classes = useStyles();
  const hasDifferentPrices =
    centsToDollarsInt(oldPriceInCents) !== parseInt(price);

  return (
    <>
      <div className={classes.fieldContainer}>
        <Field
          name="price"
          label="Price"
          fullWidth
          variant="filled"
          component={FormTextField}
          normalize={normalizePrice}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
        {hasDifferentPrices && (
          <Typography
            className={`${classes.note} ${classes.warning}`}
          >{`Change to $${centsToDollarsInt(
            oldPriceInCents
          )} to match.`}</Typography>
        )}
      </div>
      <Field
        name="plan"
        label="Protection Plan"
        fullWidth
        variant="filled"
        options={protectionPlanOptions}
        component={FormSelect}
        className={classes.fields}
      />
    </>
  );
};

const selector = formValueSelector(FORM_NAME);

const PriceUpdateFormFields = connect(state => ({
  price: selector(state, "price")
}))(Form);

Form.propTypes = {
  oldPriceInCents: PropTypes.number,
  price: PropTypes.string
};

export default PriceUpdateFormFields;
