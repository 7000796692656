import { gql } from "@apollo/client";

export const PaymentAccountsFragments = {
  payoutAccount: gql`
    fragment PayoutAccount on PayoutAccount {
      id
      createdAt
      updatedAt
      type
      stripePayoutInformation {
        bank {
          id
          last4
          bankName
        }
        accountId
        id
      }
    }
  `,
  paymentAccount: gql`
    fragment PaymentAccount on PaymentAccount {
      id
      createdAt
      updatedAt
      type
      status
      stripePaymentInformation {
        id
        customerId
        source {
          id
          last4
          brand
          funding
        }
      }
    }
  `
};
