/* eslint-disable react/jsx-key */
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2)
    }
  },
  payoutCards: {
    [theme.breakpoints.down("md")]: {
      minWidth: "240px"
    },
    border: "1px solid #E5E5E5",
    padding: "20px 20px 10px 20px",
    width: "100%"
  },
  payoutCardsLeft: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "16px"
    }
  },
  payoutCardsCenter: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "16px",
      marginRight: "16px"
    },
    marginLeft: "24px",
    marginRight: "24px"
  },
  payoutCardsRight: {
    [theme.breakpoints.down("md")]: {
      marginRight: "15px"
    }
  },
  rightBuffer: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex"
    },
    minWidth: "1px"
  },
  money: {
    color: "black",
    marginBottom: theme.spacing(1)
  },
  title: {
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "1.5px",
    marginBottom: theme.spacing(1)
  },
  date: {
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0.25px"
  },
  helpIcon: {
    color: "#9E9E9E",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      padding: "4px",
      right: "-8px",
      top: "-8px"
    }
  },
  tooltipDiv: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "flex-end"
  },
  link: {
    textDecoration: "none"
  },
  linkText: {
    marginTop: "24px",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1px"
  }
}));

const PayoutTooltip = (
  <React.Fragment>
    <p style={{ fontSize: "12px", letterSpacing: "0.4px", lineHeight: "15px" }}>
      This is the current sum of recent transactions that have not yet been
      grouped into a payout. As more transactions occur, this number may change
      until a payout is created.
    </p>
  </React.Fragment>
);

const SkeletonText = ({ text, loading, error, typographyProps }) => (
  <Typography {...typographyProps}>
    {loading || error ? <Skeleton /> : text}
  </Typography>
);

const PayoutCard = ({
  ToolTipComponent,
  error,
  loading,
  title,
  text,
  caption,
  position,
  link,
  to
}) => {
  const classes = useStyles();
  const typographyProps = {
    money: {
      component: "h4",
      variant: "h4",
      align: "left",
      className: classes.money
    },
    title: {
      component: "div",
      variant: "subtitle2",
      align: "left",
      className: classes.title
    },
    date: {
      component: "div",
      variant: "subtitle2",
      align: "left",
      className: classes.date
    }
  };
  return (
    <Paper
      elevation={0}
      className={classNames(classes.payoutCards, classes[position])}
    >
      {ToolTipComponent && (
        <div className={classes.tooltipDiv}>
          <Tooltip enterTouchDelay={0} title={ToolTipComponent}>
            <div className={classes.helpIcon}>
              <HelpOutlineIcon />
            </div>
          </Tooltip>
        </div>
      )}
      <Typography {...typographyProps.title}>{title}</Typography>
      <SkeletonText
        error={error}
        typographyProps={typographyProps.money}
        text={text}
        loading={loading}
      />
      <SkeletonText
        error={error}
        typographyProps={typographyProps.date}
        text={caption}
        loading={loading}
      />
      {link && to ? (
        <Grid>
          <Link to={to} className={classes.link}>
            <Typography
              className={classes.linkText}
              color="primary"
              display="block"
            >
              {link}
            </Typography>
          </Link>
        </Grid>
      ) : null}
    </Paper>
  );
};

export const PayoutsCards = ({ data, loading, error }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <PayoutCard
        ToolTipComponent={PayoutTooltip}
        loading={loading}
        error={error}
        title="ESTIMATED NEXT PAYOUT"
        text={`${data?.estimatedNextPayouts}`}
        caption={"Within 3-7 business days"}
        position={"payoutCardsLeft"}
        link={"RECENT TRANSACTIONS"}
        to={{
          pathname: "/fleet/dashboard/financials/transactions",
          state: { initializeDate: "Past 7 days" }
        }}
      />
      <PayoutCard
        loading={loading}
        error={error}
        title="PREVIOUS PAYOUT"
        text={`${data?.previousPayout?.netEarnings}`}
        caption={
          data?.previousPayout?.availableOn
            ? `${moment
                .unix(data.previousPayout.availableOn)
                .format("MMM D, YYYY")}`
            : "No Previous Payout"
        }
        position={"payoutCardsCenter"}
        link={"VIEW PAYOUT DETAILS"}
        to={
          data?.previousPayout?.payoutId
            ? `/fleet/dashboard/financials/payout/${data?.previousPayout?.payoutId}`
            : null
        }
      />
      <PayoutCard
        position={"payoutCardsRight"}
        loading={loading}
        error={error}
        title="MTD NET EARNINGS"
        text={data?.mtdNetEarnings}
        caption={`${moment().format("MMM")} 1 - ${moment().format(
          "MMM D, YYYY"
        )}`}
        link={"VIEW ALL TRANSACTIONS"}
        to={{
          pathname: "/fleet/dashboard/financials/transactions",
          state: { initializeDate: "Month to Date" }
        }}
      />
      <div className={classes.rightBuffer}></div>
    </Grid>
  );
};
