import { gql } from "@apollo/client";

import { RooftopFragments } from "Queries/Rooftop/RooftopFragments";

export const GENERATE_ROOFTOP_TOKENS = gql`
  mutation GenerateRooftopTokens {
    generateRooftopTokens
  }
`;

export const CREATE_ROOFTOP = gql`
  mutation CreateRooftop($input: CreateRooftopInput) {
    createRooftop(input: $input) {
      ...RooftopInfo
    }
  }
  ${RooftopFragments.rooftopInfo}
`;
