import { compose, withProps, pure } from "recompose";
import { graphql } from "@apollo/client/react/hoc";

import { analytics } from "Analytics/index";
import CurrentUserTypeQuery from "Driver/Containers/Auth/queries/CurrentUserTypeQuery";
import { loadingState } from "Driver/utils/hocs/withLoadingApollo";
import withMessagingManager from "Driver/Containers/Messaging/hocs/withMessagingManager";
import withConfig from "Driver/utils/hocs/withConfig";
import { UserTypeEnum } from "Enums/StateEnums";

const withCurrentUser = compose(
  withConfig,
  graphql(CurrentUserTypeQuery, {
    options: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-first"
    }
  }),
  loadingState(),
  pure,
  withMessagingManager,
  withProps(({ data, updateMessagingClient }) => {
    return {
      currentUserError: data.error,
      currentUser: data.viewer ? data.viewer.me : null,
      userType: UserTypeEnum.driver,
      refetchCurrentUser: async () => {
        try {
          const user = await data.refetch();
          if (user.data && user.data.viewer && user.data.viewer.me) {
            updateMessagingClient(user.data.viewer.me);
          } else {
            analytics.reset();
          }
          return user;
        } catch (e) {
          console.error(e);
          return {};
        }
      }
    };
  })
);

export const withCurrentUserRoot = compose(
  withConfig,
  graphql(CurrentUserTypeQuery, {
    options: {
      fetchPolicy: "network-only"
    }
  }),
  pure,
  loadingState(),
  withMessagingManager,
  withProps(({ data, updateMessagingClient }) => {
    return {
      currentUserError: data.error,
      currentUser: data.viewer ? data.viewer.me : null,
      userType: UserTypeEnum.driver,
      refetchCurrentUser: async () => {
        try {
          const user = await data.refetch();
          if (user.data && user.data.viewer && user.data.viewer.me) {
            updateMessagingClient(user.data.viewer.me);
          } else {
            analytics.reset();
          }
          return user;
        } catch (e) {
          console.error(e);
          return {};
        }
      }
    };
  })
);

export default withCurrentUser;
