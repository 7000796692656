import PropTypes from "prop-types";

export const JoyrideTutorialEnum = {
  home: "HOME",
  rentals: "RENTALS",
  listings: "LISTINGS",
  financials: "FINANCIALS",
  inbox: "INBOX",
  resources: "RESOURCES",
  settings: "SETTINGS"
};

export const JoyrideController = () => {
  return null;
};

JoyrideController.propTypes = {
  tutorial: PropTypes.oneOf([
    "HOME",
    "RENTALS",
    "LISTINGS",
    "FINANCIALS",
    "INBOX",
    "RESOURCES",
    "SETTINGS"
  ])
};
