import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  STATUS_WARNING,
  STATUS_ERROR
} from "Components/Drawers/ListingDrawer/ListingDocuments";
import { AcceptEnum } from "Enums/AcceptEnum";
import { useDropzone } from "Components/Forms/Dropzone/hooks/useDropzone";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { AsyncDropzoneLoadingContext } from "Components/Forms/Dropzone/AsyncDropzoneLoadingProvider";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  rootError: {
    border: `2px dashed ${theme.palette.error.main} !important`
  },
  disabled: {
    border: `2px dashed ${theme.palette.divider} !important`
  },
  action: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    outline: "none",
    cursor: "pointer",
    border: `2px dashed ${theme.palette.primary[500]}`
  },
  actionText: {
    width: "100%"
  },
  actionIconError: {
    color: theme.palette.error.main
  },
  actionIconDisabled: {
    color: theme.palette.grey[500]
  },
  previewAction: ({ warning, error }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "2px solid lightgrey",
    ...(warning && { borderColor: theme.palette.warning.dark }),
    ...(error && { borderColor: theme.palette.error.dark })
  }),
  viewButton: {
    padding: " 0 14px"
  },
  replaceButton: {
    marginBottom: theme.spacing(3)
  },
  background: {
    backgroundImage: props =>
      props.background ? `url(${props.background})` : "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100%"
  }
}));

export const DocumentDropzone = ({
  disabled,
  accept,
  asyncAction,
  input: { value, onChange },
  meta: { touched, invalid },
  expirationStatus,
  documentType,
  disableUpload,
  id
}) => {
  const [background, setBackground] = useState("");
  const classes = useStyles({
    background,
    warning: expirationStatus === STATUS_WARNING,
    error: expirationStatus === STATUS_ERROR
  });

  const { setLoading } = useContext(AsyncDropzoneLoadingContext);
  const { getAsset } = useContext(FirebaseContext);

  const { getRootProps, getInputProps, isPdf, loading } = useDropzone({
    multiple: false,
    value: value,
    onChange: onChange,
    disabled,
    accept,
    asyncAction
  });

  useEffect(() => {
    if (value) {
      if (isPdf) {
        setBackground(getAsset("pdfPreviewIcon"));
      } else {
        setBackground(asyncAction ? value.url : URL.createObjectURL(value));
      }
    }
  }, [value, isPdf, asyncAction, getAsset]);

  useEffect(() => {
    if (setLoading) setLoading(documentType, loading);
  }, [loading, setLoading, documentType]);

  return (
    <div className={classNames(classes.root)}>
      {loading ? (
        <div className={classes.action}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {value ? (
            <div
              className={classNames(classes.previewAction, classes.background)}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.replaceButton}
                startIcon={<AddAPhotoIcon />}
                {...getRootProps()}
                disabled={disableUpload}
              >
                REPLACE
              </Button>
              <Button
                variant="contained"
                color="primary"
                href={asyncAction ? value.url : URL.createObjectURL(value)}
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<OpenInNewIcon />}
                onClick={e => e.stopPropagation()}
                classes={{
                  label: classes.viewButton
                }}
              >
                VIEW
              </Button>
            </div>
          ) : (
            <div
              id={id && id}
              className={classNames(classes.action, {
                [classes.rootError]: touched && invalid,
                [classes.disabled]: disabled
              })}
              {...getRootProps()}
            >
              <AddToPhotosIcon
                color="primary"
                className={classNames({
                  [classes.actionIconDisabled]: disabled,
                  [classes.actionIconError]: touched && invalid
                })}
              />
              <Typography
                component="span"
                variant="subtitle1"
                align="center"
                display="block"
                className={classes.actionText}
              >
                ADD IMAGE
              </Typography>
            </div>
          )}
        </>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

DocumentDropzone.propTypes = {
  disabled: PropTypes.bool,
  objectFit: PropTypes.bool,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf([AcceptEnum.jpeg, AcceptEnum.png, AcceptEnum.pdf])
    )
  ]).isRequired,
  asyncAction: PropTypes.func,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool
  }).isRequired,
  expirationStatus: PropTypes.string,
  documentType: PropTypes.string,
  disableUpload: PropTypes.bool
};
