import React, { useCallback, useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useDropzone } from "react-dropzone-latest";
import makeStyles from "@mui/styles/makeStyles";
import { Avatar } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddIcon from "@mui/icons-material/Add";
import LoopIcon from "@mui/icons-material/Loop";
import PropTypes from "prop-types";
import get from "lodash/get";

import { UPLOAD_FILE } from "Mutations/File/FileMutations";
import { FileTypeEnum } from "Enums/StateEnums";
import { AcceptEnum } from "Enums/AcceptEnum";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles(() => ({
  root: {
    display: "inline-block",
    cursor: "pointer"
  },
  avatar: {
    width: 82,
    height: 82
  }
}));

export const AvatarDropzoneField = ({ input, defaultSrc, loading }) => {
  const { onChange } = input;
  const [hover, setHover] = useState(false);
  const [src, setSrc] = useState(defaultSrc);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [uploadFile] = useMutation(UPLOAD_FILE, {
    client: currentRooftopClient
  });

  useEffect(() => {
    setSrc(defaultSrc);
  }, [defaultSrc]);

  return (
    <AvatarDropzone
      asyncAction={async acceptedFiles => {
        const { data } = await uploadFile({
          variables: {
            file: acceptedFiles[0],
            type: FileTypeEnum.profilePhoto
          }
        });
        onChange(get(data, "uploadFile.id"));
        setSrc(get(data, "uploadFile.url"));
      }}
      icon={hover ? <AddIcon /> : <CameraAltIcon />}
      uploadingIcon={<LoopIcon />}
      loading={loading}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      src={hover ? null : src}
    />
  );
};

export const AvatarDropzone = ({
  asyncAction,
  onMouseEnter,
  onMouseLeave,
  accept = [AcceptEnum.jpeg, AcceptEnum.png],
  icon,
  uploadingIcon,
  loading,
  src
}) => {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async acceptedFiles => {
      setUploading(true);
      try {
        await asyncAction(acceptedFiles);
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    },
    [asyncAction]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
    noDrag: true
  });

  return loading ? (
    <Skeleton variant="circular" classes={{ circular: classes.avatar }} />
  ) : (
    <div
      className={classes.root}
      {...getRootProps({
        onMouseEnter,
        onMouseLeave
      })}
    >
      {uploading ? (
        <Avatar className={classes.avatar}>{uploadingIcon}</Avatar>
      ) : (
        <Avatar src={src} className={classes.avatar}>
          {icon}
        </Avatar>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

AvatarDropzone.propTypes = {
  asyncAction: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  accept: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.node,
  uploadingIcon: PropTypes.node,
  loading: PropTypes.bool,
  src: PropTypes.string
};
