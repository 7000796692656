import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Zoom from "@mui/material/Zoom";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

import { deselectFilter } from "Redux/Header/ActionCreators";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(2)
    }
  },
  chipsContainer: {
    [theme.breakpoints.down("md")]: {
      overflow: "hidden"
    }
  },
  chipsInnerContainer: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
      whiteSpace: "nowrap",
      scrollbarWidth: "none",
      flexWrap: "nowrap",
      flexDirection: "row",
      alignItems: "center"
    }
  },
  chip: {
    margin: theme.spacing(0, 1)
  }
}));

export const CarInfoFilter = connect(
  state => ({
    selectedFilters: state.fleet.header.selectedFilters
  }),
  { deselectFilter }
)(({ selectedFilters, deselectFilter }) => {
  const classes = useStyles();

  return selectedFilters.length === 0 ? null : (
    <Grid container className={classes.root} direction="column">
      <Grid
        item
        sm={12}
        component="div"
        className={classes.chipsContainer}
        container
      >
        <div className={classes.chipsInnerContainer}>
          {selectedFilters.map(filter => {
            return (
              <Zoom key={filter.label} in={true}>
                <Chip
                  label={filter.chipLabel ? filter.chipLabel : filter.label}
                  onDelete={() => deselectFilter(filter)}
                  color="primary"
                  className={classNames(classes.chip)}
                  style={{ backgroundColor: filter.chipColor }}
                />
              </Zoom>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
});
