import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { RentalCheckOut } from "./RentalCheckOut";
import { RateDriver } from "./RateDriver";

const useStyles = makeStyles(theme => ({
  closeIcon: {
    color: theme.palette.common.white
  }
}));

export const RentalCheckOutController = ({ onClose }) => {
  const [ratingView, setRatingView] = useState(false);

  const sendToRatingView = () => {
    setRatingView(true);
  };

  const classes = useStyles();

  return (
    <>
      <DrawerAppBar
        icon={
          <IconButton
            onClick={onClose}
            className={classes.closeIcon}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        }
        title={ratingView ? "Check-out Complete" : "Check-out"}
        border
        primaryTheme
      />
      {ratingView ? (
        <RateDriver onCancel={onClose} claim={false} />
      ) : (
        <RentalCheckOut onFinish={sendToRatingView} onCancel={onClose} />
      )}
    </>
  );
};

RentalCheckOutController.propTypes = {
  onClose: PropTypes.func
};
