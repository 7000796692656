export const GoogleMapsEnum = {
  country: "country",
  state: "administrative_area_level_1",
  county: "administrative_area_level_2",
  town: "administrative_area_level_3",
  neighborhood: "neighborhood",
  city: "locality",
  zip: "postal_code",
  streetName: "route",
  streetNumber: "street_number"
};
