import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles(theme => ({
  divider: { margin: theme.spacing(2, 0, 2, 0) }
}));

export const Footer = () => {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
};
