import { gql } from "@apollo/client";

import { RooftopFragments } from "Queries/Rooftop/RooftopFragments";
import { OrganizationFragments } from "Queries/Organization/OrganizationFragments";

export const ROOFTOP_INFO = gql`
  query RooftopInfo($id: ID!) {
    rooftop(input: { id: $id }) {
      ...RooftopInfo
      # members {
      #   ...RooftopMember
      # }
      legacyRooftop {
        ...LegacyRooftop
      }
      address {
        ...OrganizationAddress
      }
    }
  }
  ${RooftopFragments.rooftopInfo}
  ${RooftopFragments.legacyRooftop}
  # ${RooftopFragments.rooftopMember}
  ${OrganizationFragments.organizationAddress}
`;
