import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { useMutation, useQuery } from "@apollo/client";
import get from "lodash/get";
import Switch from "@mui/material/Switch";
import capitalize from "lodash/capitalize";

import { AvatarWithName } from "Components/Avatar/AvatarWithName";
import { TeamStatusEnum } from "Enums/TeamStatusEnum";
import { truncateData } from "Utils/Helpers";
import { ORGANIZATION_MEMBERS } from "Queries/Organization/OrganizationQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import {
  ACTIVATE_ORGANIZATION_MEMBER,
  DEACTIVATE_ORGANIZATION_MEMBER
} from "Mutations/Organization/OrganizationMutations";

const useMapTeamData = data => {
  const theme = useTheme();
  const { currentOrganizationClient, currentOrganizationInfo } = useContext(
    ClientFactoryContext
  );

  const loggedInUserId = get(currentOrganizationInfo, "organizationMemberId");

  const [activateOrganizationMember] = useMutation(
    ACTIVATE_ORGANIZATION_MEMBER,
    {
      client: currentOrganizationClient
    }
  );
  const [deactivateOrganizationMember] = useMutation(
    DEACTIVATE_ORGANIZATION_MEMBER,
    {
      client: currentOrganizationClient
    }
  );

  const matches = theme.breakpoints.down("lg");
  const members = get(data, "organization.organizationMembers", []);
  const sortedData = [...members].sort((a, b) => {
    // sort logged in member first
    if (a.id === loggedInUserId) {
      return -1;
    } else if (b.id === loggedInUserId) {
      return 1;
    }
    // compare lowercase english version of strings, sort by name
    // maybe sort by role in future
    return (a.user?.name?.familyName ?? "").localeCompare(
      b.user?.name?.familyName ?? "",
      "en",
      {
        sensitivity: "base"
      }
    );
  });

  const changeStatusDeactive = async memberId => {
    try {
      await deactivateOrganizationMember({
        variables: {
          data: {
            id: memberId
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const changeStatusActive = async memberId => {
    try {
      await activateOrganizationMember({
        variables: {
          data: {
            id: memberId
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const setRowStyle = (isOwner, status) => {
    let rowStyle = {};
    if (isOwner) {
      rowStyle = { pointerEvents: "none", opacity: 1 };
    } else {
      if (
        status === TeamStatusEnum.invited ||
        status === TeamStatusEnum.deactivated
      ) {
        rowStyle = { opacity: 0.5 };
      } else if (status === TeamStatusEnum.active) {
        rowStyle = { opacity: 1 };
      }
    }
    return rowStyle;
  };

  return sortedData.map((member, index) => {
    const isOwner = get(member, "role.name").toLowerCase() === "owner";
    const status = get(member, "status");

    const rowStyle = setRowStyle(isOwner, status);
    const memberId = get(member, "id");

    return {
      id: get(member, "id"),
      name: (
        <AvatarWithName
          // dont have profile photo yet
          url={get(member, "user.legacyUser.profilePhoto.url")}
          firstName={
            get(member, "user.name.givenName")
              ? get(member, "user.name.givenName")
              : get(member, "user.id")
          }
          lastName={`${member?.user?.name?.familyName ?? ""}${
            index === 0 ? " (me)" : ""
          }`}
          greyed={
            status === TeamStatusEnum.deactivated ||
            status === TeamStatusEnum.invited
          }
        />
      ),
      role: capitalize(get(member, "role.name")),
      rooftops: truncateData(
        get(member, "rooftops", [])
          .map(rooftop => rooftop.name)
          .join(", "),
        matches ? 30 : 65
      ),
      status: get(member, "status"),
      statusButton: isOwner ? (
        <span></span>
      ) : (
        <div onClick={e => e.stopPropagation()}>
          <Switch
            color="primary"
            onChange={
              status === TeamStatusEnum.active
                ? () => changeStatusDeactive(memberId)
                : () => changeStatusActive(memberId)
            }
            checked={status === TeamStatusEnum.active ? true : false}
            disabled={status === TeamStatusEnum.invited ? true : false}
          />
        </div>
      ),
      style: rowStyle
    };
  });
};

// const useLoadMore = (loading, error, fetchMore, pageInfo, options) => {
//   const [fetchingMore, updateFetchingMore] = useState(false);
//   const { hasNextPage, endCursor } = pageInfo;
//   const first = options.first || 10;

//   const fetchMoreData = () => {
//     if (!loading && !error) {
//       if (hasNextPage) {
//         updateFetchingMore(true);
//         fetchMore({
//           variables: {
//             first: first,
//             after: endCursor
//           },
//           // todo fix object chains once we have data
//           updateQuery: (previousResult, { fetchMoreResult }) => {
//             const previousEdges = get(
//               previousResult,
//               "viewer.me.usersConnection.edges",
//               []
//             );
//             const nextEdges = get(
//               fetchMoreResult,
//               "viewer.me.usersConnection.edges",
//               []
//             );
//             const nextPageInfo = get(
//               fetchMoreResult,
//               "viewer.me.usersConnection.pageInfo",
//               {}
//             );
//             set(
//               fetchMoreResult,
//               "viewer.me.usersConnection.edges",
//               concat(previousEdges, nextEdges)
//             );
//             set(
//               fetchMoreResult,
//               "viewer.me.usersConnection.pageInfo",
//               nextPageInfo
//             );
//             return {
//               ...fetchMoreResult
//             };
//           }
//         })
//           .then(() => {
//             updateFetchingMore(false);
//           })
//           .catch(() => {
//             updateFetchingMore(false);
//           });
//       }
//     }
//   };

//   return {
//     fetchingMore,
//     fetchMoreData
//   };
// };

export const useTeamTable = options => {
  const { data, loading, error } = useQuery(ORGANIZATION_MEMBERS, options);
  const mappedData = useMapTeamData(data);
  // todo get page info once we know structure of data
  // const pageInfo = get(data, "viewer.me.usersConnection.pageInfo", {});
  // no fetch more until api allows for pagination inputs
  // const { fetchingMore, fetchMoreData } = useLoadMore(
  //   loading,
  //   error,
  //   fetchMore,
  //   pageInfo,
  //   options
  // );

  return {
    data,
    mappedData,
    // pageInfo,
    loading,
    error
    // fetchingMore,
    // fetchMoreData
  };
};
