import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { reduxForm, Field } from "redux-form";
import { useSnackbar } from "notistack";
import pick from "lodash/pick";
import PropTypes from "prop-types";

import { CAR_SUMMARY } from "Queries/Car/Car";
import { FLEET_UPDATE_CARS } from "Mutations/Owner/OwnerMutations";
import { DrawerExpansionPanel } from "Components/Drawers/DrawerExpansionPanel";
import {
  LocationAutoComplete,
  validateLocation
} from "Components/Forms/Location/LocationAutoComplete";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { UnsavedProgressDialogContext } from "Components/Utils/UnsavedProgressDialogProvider";

export const ListingLocationForm = reduxForm({
  destroyOnUnmount: true,
  form: "LISTING_LOCATION",
  onChange: (v, d, props) => props.setUnsavedProgressExistsState(props.dirty),
  onSubmitSuccess: (r, d, props) => props.setUnsavedProgressExistsState(false),
  validate: values => validateLocation(values, "address")
})(
  ({
    handleSubmit,
    submitting,
    setDisplayUnsavedProgressDialog,
    unsavedProgressExists
  }) => (
    <DrawerExpansionPanel
      onCancel={
        unsavedProgressExists
          ? () => setDisplayUnsavedProgressDialog(true)
          : undefined
      }
      onSubmit={handleSubmit}
      submitting={submitting}
      title="Pick Up Address"
    >
      <Field name="address" component={LocationAutoComplete} showMap />
    </DrawerExpansionPanel>
  )
);

export const ListingLocation = ({ carId }) => {
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;
  const { enqueueSnackbar } = useSnackbar();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    setDisplayUnsavedProgressDialog,
    setUnsavedProgressExistsState,
    unsavedProgressExists
  } = useContext(UnsavedProgressDialogContext);

  const [updateLocation] = useMutation(FLEET_UPDATE_CARS, {
    client: currentRooftopClient
  });
  const {
    data: { car }
  } = useQuery(CAR_SUMMARY, {
    variables: {
      id
    },
    client: currentRooftopClient,
    fetchPolicy: "cache-only"
  });

  const onSubmit = async values => {
    try {
      await updateLocation({
        variables: {
          input: [
            {
              id,
              location: {
                update: {
                  ...pick(values.address, [
                    "lat",
                    "lng",
                    "street",
                    "city",
                    "state",
                    "zip"
                  ]),
                  formattedAddress: values.address.address
                }
              }
            }
          ]
        }
      });
      enqueueSnackbar("Successfully updated this cars location.", {
        variant: "success"
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <ListingLocationForm
      onSubmit={onSubmit}
      initialValues={{
        address: {
          ...pick(car.location, [
            "city",
            "state",
            "street",
            "zip",
            "lat",
            "lng"
          ]),
          address: car.location && car.location.formattedAddress,
          selected: true
        }
      }}
      setDisplayUnsavedProgressDialog={setDisplayUnsavedProgressDialog}
      setUnsavedProgressExistsState={setUnsavedProgressExistsState}
      unsavedProgressExists={unsavedProgressExists}
    />
  );
};

ListingLocation.propTypes = {
  carId: PropTypes.string
};
