import React, { useState, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import {
  calculateMonthlyDiscount,
  calculateMonthlyPriceInCentsWithDiscountFromDollars,
  calculateWeeklyDiscount,
  calculateWeeklyPriceInCentsWithDiscountFromDollars,
  centsToDollarsInt,
  dollarsToCents
} from "Utils/Calculations";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import PriceUpdateModalForm from "./PriceUpdateModalForm";
import { UPDATE_CAR_DETAILS } from "Mutations/Car/CarMutations";
import ImportantNote from "./ImportantNote";

const SNACKBAR_DURATION_MS = 8000;

const useStyles = makeStyles(theme => ({
  editButton: { color: theme.palette.primary.contrastText }
}));

const DifferentPriceOrPlanContainer = ({
  oldCar,
  newCar,
  onUpdate,
  showNote
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { editButton } = useStyles();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [updateCarDetails] = useMutation(UPDATE_CAR_DETAILS, {
    client: currentRooftopClient
  });

  const currentWeeklyDiscount = calculateWeeklyDiscount(
    newCar.dailyPriceInCents,
    newCar.weeklyPriceInCents
  );

  const getNewWeeklyPriceInCents = newDailyPrice =>
    calculateWeeklyPriceInCentsWithDiscountFromDollars(
      newDailyPrice,
      currentWeeklyDiscount
    );

  const currentMonthlyDiscount = calculateMonthlyDiscount(
    newCar.dailyPriceInCents,
    newCar.monthlyPriceInCents
  );

  const getNewMonthlyPriceInCents = newDailyPrice =>
    calculateMonthlyPriceInCentsWithDiscountFromDollars(
      newDailyPrice,
      currentMonthlyDiscount
    );

  const handleEditButton = key => {
    setModalOpen(true);
    closeSnackbar(key);
  };

  const showSuccessSnackbar = () => {
    const key = enqueueSnackbar("Changed successfully", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      variant: "success",
      autoHideDuration: SNACKBAR_DURATION_MS,
      action: () => (
        <>
          <Button className={editButton} onClick={() => handleEditButton(key)}>
            Edit
          </Button>
          <Button className={editButton} onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        </>
      )
    });
  };

  const showErrorSnackbar = () =>
    enqueueSnackbar("Failed to update", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      variant: "error",
      autoHideDuration: SNACKBAR_DURATION_MS
    });

  const updateRemoteData = newPriceAndPlan =>
    updateCarDetails({
      variables: {
        data: {
          dailyPriceInCents: dollarsToCents(newPriceAndPlan.price),
          weeklyPriceInCents: getNewWeeklyPriceInCents(newPriceAndPlan.price),
          monthlyPriceInCents: getNewMonthlyPriceInCents(newPriceAndPlan.price),
          defaultProtectionPlan: newPriceAndPlan.plan.toUpperCase(),
          carId: newCar.id
        }
      }
    });

  const getUpdatedDailyPrice = data =>
    data?.data?.updateCarDetails?.car?.dailyPriceInCents;

  const getUpdatedWeeklyPrice = data =>
    data?.data?.updateCarDetails?.car?.weeklyPriceInCents;

  const getUpdatedMonthlyPrice = data =>
    data?.data?.updateCarDetails?.car?.monthlyPriceInCents;

  const getUpdatedPlan = data =>
    data?.data?.updateCarDetails?.car?.defaultProtectionPlan;

  const update = async newPriceAndPlan => {
    try {
      const updatedData = await updateRemoteData(newPriceAndPlan);
      const defaultProtectionPlan = getUpdatedPlan(updatedData);
      const dailyPriceInCents = getUpdatedDailyPrice(updatedData);
      const weeklyPriceInCents = getUpdatedWeeklyPrice(updatedData);
      const monthlyPriceInCents = getUpdatedMonthlyPrice(updatedData);
      const updatedCar = {
        ...newCar,
        dailyPriceInCents,
        defaultProtectionPlan,
        weeklyPriceInCents,
        monthlyPriceInCents
      };
      onUpdate(updatedCar);
      showSuccessSnackbar();
    } catch (error) {
      showErrorSnackbar();
    }
  };

  return (
    <>
      {showNote ? <ImportantNote setModalOpen={setModalOpen} /> : null}
      <PriceUpdateModalForm
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={update}
        initialValues={{
          price: centsToDollarsInt(newCar.dailyPriceInCents).toString(),
          plan: newCar.defaultProtectionPlan.toUpperCase()
        }}
        oldPriceInCents={oldCar.dailyPriceInCents}
      />
    </>
  );
};

DifferentPriceOrPlanContainer.propTypes = {
  oldCar: PropTypes.object,
  newCar: PropTypes.object,
  onUpdate: PropTypes.func,
  showNote: PropTypes.bool
};

export default DifferentPriceOrPlanContainer;
