import { withProps } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "redux";

import CurrentUserTypeQuery from "Driver/Containers/Auth/queries/CurrentUserTypeQuery";
import { loadingState } from "Driver/utils/hocs/withLoadingApollo";

export default compose(
  graphql(CurrentUserTypeQuery, {
    options: {
      errorPolicy: "all"
    }
  }),
  loadingState(),
  withProps(({ data }) => ({
    currentUser: data && data.viewer ? data.viewer.me : null
  }))
);
