import React, { Fragment, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";

import { openNavigation } from "Redux/Navigation/ActionCreators";
import { RooftopSelector } from "Components/Header/RooftopSelector";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { UniversalSearch } from "./universalSearch/UniversalSearch";
import OwnerGeneralAlert from "Components/Alerts/OwnerGeneralAlert";
import { OwnerAlertContext } from "Components/Utils/OwnerAlertProvider";

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1
  },
  icon: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)
  }
}));

export const InboxHeader = connect(null, { openNavigation })(
  ({ openNavigation, tabs }) => {
    const classes = useStyles();
    const { organizationTokens, role } = useContext(ClientFactoryContext);
    const showRooftopSelector = organizationTokens.length > 1;
    const { showAlert } = useContext(OwnerAlertContext);

    return (
      <Fragment>
        <AppBar position="fixed">
          <ToolBar>
            <IconButton
              className={classes.icon}
              onClick={openNavigation}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            {showRooftopSelector && <RooftopSelector location="Inbox" />}
            {!showRooftopSelector && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.flexGrow}
              >
                Inbox
              </Typography>
            )}
            {role !== "p2p" && <UniversalSearch />}
          </ToolBar>
          {tabs && tabs}
          {showAlert && <OwnerGeneralAlert />}
        </AppBar>
      </Fragment>
    );
  }
);
