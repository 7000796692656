import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import startCase from "lodash/startCase";

import CarListItem, { STATUS_DISABLED } from "Components/Car/CarListItem";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const CarOption = ({ car, onChange, value }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  const showIneligibleError = () => {
    enqueueSnackbar(
      `The car you selected is not eligible for a Car Switch because one or more documents needs updating. Please select a different car or call ${customerServicePhoneNumber} for a manual car switch.`,
      {
        variant: "error",
        persist: true,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        style: { maxWidth: 500 },
        action: function DismissButton(key) {
          return (
            <Button
              style={{ color: "#fff" }}
              onClick={() => closeSnackbar(key)}
            >
              OK
            </Button>
          );
        }
      }
    );
  };

  const handleClick = () => {
    if (car.ineligibleError) {
      showIneligibleError();
      onChange({});
    } else {
      closeSnackbar();
      onChange(car);
    }
  };

  const getSecondaryText = car =>
    car.ineligibleError ? getInelidibleLabel(car) : getPriceLabel(car);

  const getInelidibleLabel = car => `Ineligible - ${car.ineligibleError}`;

  const getPriceLabel = car =>
    `$${getCarPrice(car.dailyPriceInCents)} / ${startCase(
      car.defaultProtectionPlan.toLowerCase()
    )}`;

  const getCarPrice = price => {
    const priceString = price.toString();
    if (priceString.slice(-2) === "00") {
      return priceString.substring(0, priceString.length - 2);
    } else {
      return price / 100;
    }
  };

  return (
    <CarListItem
      car={car}
      onClick={handleClick}
      checked={value.id === car.id}
      secondaryText={getSecondaryText(car)}
      status={car.ineligibleError && STATUS_DISABLED}
    />
  );
};

CarOption.propTypes = {
  car: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default CarOption;
