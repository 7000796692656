import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import get from "lodash/get";
import jwtDecode from "jwt-decode";

import { ListingDrawer } from "Components/Drawers/ListingDrawer/ListingDrawer";
import { ActionDialog } from "Components/Dialog/ActionDialog";
import { TabsWithRoutes } from "Components/Tabs/TabsWithRoutes";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { RouteEnum } from "Enums/RouteEnum";
import {
  CarVerificationEnum,
  CarStatusEnum,
  ListingStatusCopyEnum
} from "Enums/StateEnums";
import { ListingsTable } from "Components/Tables/ListingsTable";
import { ListingsCardView } from "Components/Dashboard/ListingsCardView";
import { CURRENT_PAYOUT_ACCOUNT_QUERY } from "Queries/User/UserQueries";
import { MobileHeader } from "Components/Header/MobileHeader";
import {
  enableHeader,
  disableHeader,
  resetFilters,
  resetSearch
} from "Redux/Header/ActionCreators";
import { RouterTab } from "Components/Tabs/RouterTab";
import {
  JoyrideController,
  JoyrideTutorialEnum
} from "Components/Joyride/JoyrideController";
import { NoDataComponent } from "Components/Tables/NoData";
import { getListingStatusOptions } from "Components/hooks/useListingStatusOptions";
import emptyStateIcon from "Assets/images/empty-state.svg";
import { openSupportPage } from "Utils/openSupportPage";
import {
  CarsDomainPermissionPaths,
  PaymentDomainPermissionPaths
} from "Components/Utils/Permissions/PermissionsPaths";
import { checkPermission } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { useCsvListings } from "Queries/Fleet/hooks/useCsvListings";
import { useFleetStatusAggregate } from "Queries/Fleet/hooks/useFleetStatusAggregate";
import { Chimes } from "Components/Loading/Chimes";

const useStyles = makeStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.common.white
  },
  dialogContentText: {
    marginBottom: theme.spacing(4)
  }
}));

export const Listings = connect(null, {
  enableHeader,
  disableHeader,
  resetFilters,
  resetSearch
})(({ enableHeader, disableHeader, resetFilters, resetSearch }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { scopes, currentRooftopToken } = useContext(ClientFactoryContext);
  const currentRooftopId = currentRooftopToken
    ? jwtDecode(currentRooftopToken).id
    : "";

  const pathname = history.location.pathname;
  const [activeTabValue, setActiveTabValue] = useState(
    pathname === RouteEnum.listings ? RouteEnum.listingsAll : pathname
  );

  const { data: payoutData, loading: payoutLoading } = useQuery(
    CURRENT_PAYOUT_ACCOUNT_QUERY,
    {
      context: {
        apiv2: true,
        headers: {
          "x-rooftop-authorization": currentRooftopToken
        }
      },
      fetchPolicy: "network-only"
    }
  );

  const listedCar = get(history, "location.state.listedCar", false);
  const listedCSV = get(history, "location.state.listedCSV", false);
  const totalCSVCarCount = get(history, "location.state.totalCSVCarCount", 0);
  const failedCSVListings = get(
    history,
    "location.state.failedCSVListings",
    []
  );
  const successfulCSVCarCount = totalCSVCarCount - failedCSVListings.length;
  const failedCSVTemplateData = [
    ["vin", "odometer", "color", "plate", "description", "error"],
    ...failedCSVListings
  ];
  const failedCSV =
    "data:text/csv;charset=utf-8," +
    failedCSVTemplateData.map(e => e.join(",")).join("\n");
  const encodedUri = encodeURI(failedCSV);
  const iccDealer = false;
  const requiresAch =
    get(
      payoutData,
      "getPayoutAccountInfo.payoutAccounts.stripePayoutInformation",
      null
    ) === null &&
    checkPermission(PaymentDomainPermissionPaths.createACH, scopes);

  const handleCloseListedCar = () =>
    history.replace(RouteEnum.listingsProgress);

  const getAchRedirectionTo = pathname => ({
    pathname,
    state: { showAchForm: true }
  });

  const handleSendToAch = () =>
    checkPermission(PaymentDomainPermissionPaths.viewACH, scopes)
      ? history.push(getAchRedirectionTo(RouteEnum.settingsPayment))
      : history.push(
          getAchRedirectionTo(
            `${RouteEnum.settingsRooftopsEdit}/${currentRooftopId}`
          )
        );

  const handleOnRowClick = car => {
    history.push(`${history.location.pathname}/${car.id}`);
  };

  const sendToListCarFlow = () => history.push(RouteEnum.manual);
  const sendToSelectFlow = () => history.push(RouteEnum.selectListingType);

  useEffect(() => {
    if (matches) {
      disableHeader();
    } else {
      enableHeader();
    }

    return enableHeader;
  }, [matches, enableHeader, disableHeader]);

  const listingsCsvData = useCsvListings();

  const downloadData = {
    [RouteEnum.listingsAll]: listingsCsvData
  };

  const [activeDataToDownload, setActiveDataToDownload] = useState(
    (pathname === RouteEnum.listings
      ? downloadData[RouteEnum.listingsAll]
      : downloadData[pathname]) || []
  );

  const {
    loading: fleetStatusAggregateLoading,
    availableAggregate,
    snoozedAggregate,
    rentedAggregate,
    deletedAggregate,
    refetch: fleetStatusAggregateRefetch
  } = useFleetStatusAggregate();

  useEffect(() => {
    fleetStatusAggregateRefetch();
  }, [history.location.pathname, fleetStatusAggregateRefetch]);

  const allListingsFilters = [
    {
      key: "status_in",
      value: CarStatusEnum.available,
      label: ListingStatusCopyEnum.live,
      chipLabel:
        availableAggregate > 0
          ? `${ListingStatusCopyEnum.live} (${availableAggregate})`
          : ListingStatusCopyEnum.live,
      chipColor: getListingStatusOptions(
        CarStatusEnum.available,
        CarVerificationEnum.verified,
        theme
      ).color
    },
    {
      key: "status_in",
      value: CarStatusEnum.removed,
      label: ListingStatusCopyEnum.snoozed,
      chipLabel:
        snoozedAggregate > 0
          ? `${ListingStatusCopyEnum.snoozed} (${snoozedAggregate})`
          : ListingStatusCopyEnum.snoozed,
      chipColor: getListingStatusOptions(
        CarStatusEnum.removed,
        CarVerificationEnum.verified,
        theme
      ).color
    },
    {
      key: "status_in",
      value: CarStatusEnum.rented,
      label: ListingStatusCopyEnum.rented,
      chipLabel:
        rentedAggregate > 0
          ? `${ListingStatusCopyEnum.rented} (${rentedAggregate})`
          : ListingStatusCopyEnum.rented,
      chipColor: getListingStatusOptions(
        CarStatusEnum.rented,
        CarVerificationEnum.verified,
        theme
      ).color
    },
    {
      key: "status_in",
      value: CarStatusEnum.deleted,
      label: ListingStatusCopyEnum.deleted,
      chipLabel:
        deletedAggregate > 0
          ? `${ListingStatusCopyEnum.deleted} (${deletedAggregate})`
          : ListingStatusCopyEnum.deleted,
      chipColor: getListingStatusOptions(CarStatusEnum.deleted, null, theme)
        .color
    }
  ];

  const inProgressListingsFilters = [
    {
      key: "verification_in",
      value: CarVerificationEnum.pendingVerification,
      label: ListingStatusCopyEnum.pending,
      chipColor: getListingStatusOptions(
        CarStatusEnum.removed,
        CarVerificationEnum.pendingVerification,
        theme
      ).color
    },
    {
      key: "verification_in",
      value: CarVerificationEnum.failedVerification,
      label: ListingStatusCopyEnum.failed,
      chipColor: getListingStatusOptions(
        CarStatusEnum.removed,
        CarVerificationEnum.failedVerification,
        theme
      ).color
    }
  ];

  return (
    <>
      <JoyrideController tutorial={JoyrideTutorialEnum.listings} />
      <Route
        path={[
          `${RouteEnum.listingsInventory}/:id`,
          `${RouteEnum.listingsProgress}/:id`,
          `${RouteEnum.listingsAll}/:id`
        ]}
        component={ListingDrawer}
      />
      <ActionDialog
        open={!payoutLoading && listedCar}
        onClose={handleCloseListedCar}
        title="Listing Submitted"
        content={
          requiresAch ? (
            <>
              <Typography
                component="p"
                variant="body1"
                className={classes.dialogContentText}
              >
                You successfully created a listing, but cannot receive payment
                until you set up your ACH form.
              </Typography>
              <Typography component="p" variant="body1">
                Would you like to complete your ACH form now?
              </Typography>
            </>
          ) : (
            <>
              <Typography component="p" variant="body1" gutterBottom>
                You successfully created a listing. Our agents are doing a final
                check and will notify you when your listing goes live. You can
                edit and keep track of your in-progress listings here in the In
                Progress tab of your Inventory page.
              </Typography>
              <Typography component="p" variant="body1">
                Got questions? Contact{" "}
                <Link onClick={() => openSupportPage()} underline="hover">
                  Support
                </Link>
              </Typography>
            </>
          )
        }
        actionLabel={requiresAch ? "Setup Payment" : "Confirm - Car Listed"}
        action={requiresAch ? handleSendToAch : handleCloseListedCar}
        cancelLabel={requiresAch ? "Cancel" : null}
        cancel={handleCloseListedCar}
      />
      <ActionDialog
        open={listedCSV}
        onClose={handleCloseListedCar}
        title={`${successfulCSVCarCount} of ${totalCSVCarCount} Listing${
          totalCSVCarCount > 1 ? "s" : ""
        } Created`}
        maxWidth="sm"
        content={
          <>
            {successfulCSVCarCount !== totalCSVCarCount && (
              <>
                <Typography component={"p"} className={classes.modalText}>
                  {`Download a CSV of your unsuccessful uploads `}
                  <Link
                    href={encodedUri}
                    download="failed_listings.csv"
                    underline="hover"
                  >
                    HERE
                  </Link>
                  .
                </Typography>
                <br />
              </>
            )}
            {successfulCSVCarCount !== 0 && (
              <>
                <Typography component={"p"} className={classes.modalText}>
                  Your pending listings are being reviewed by our agents. They
                  will notify you when your listings have been approved and
                  become Live on the marketplace.
                </Typography>
                <br />
              </>
            )}
            <Typography component={"p"} className={classes.modalText}>
              {"For additional help, please "}
              <Button
                onClick={() => openSupportPage()}
                color="primary"
                size="small"
              >
                Contact Support
              </Button>
            </Typography>
          </>
        }
        actionLabel="Let's Go"
        action={handleCloseListedCar}
        cancelLabel="Add More"
        cancel={
          checkPermission(CarsDomainPermissionPaths.csvRooftopSelect, scopes)
            ? matches
              ? sendToListCarFlow
              : sendToSelectFlow
            : sendToListCarFlow
        }
        cancelOnLeft
        cancelVariant="contained"
      />

      {matches ? (
        <MobileHeader
          title="Listings"
          downloadData={activeDataToDownload}
          tabs={
            <Tabs
              variant="fullWidth"
              textColor="inherit"
              value={activeTabValue}
              classes={{ indicator: classes.indicator }}
              onChange={(event, value) => {
                resetFilters();
                resetSearch();
                setActiveTabValue(value);
                setActiveDataToDownload(downloadData[value]);
              }}
            >
              <RouterTab
                to={RouteEnum.listingsAll}
                label="Listings"
                value={RouteEnum.listingsAll}
              />
              <RouterTab
                to={RouteEnum.listingsProgress}
                label="In Progress"
                value={RouteEnum.listingsProgress}
              />
              {iccDealer && (
                <RouterTab
                  to={RouteEnum.listingsInventory}
                  label="Inventory"
                  value={RouteEnum.listingsInventory}
                />
              )}
            </Tabs>
          }
        />
      ) : (
        <TabsWithRoutes
          paths={[
            {
              to: RouteEnum.listingsAll,
              label: "Listings"
            },
            {
              to: RouteEnum.listingsProgress,
              label: "In Progress"
            },
            {
              to: RouteEnum.listingsInventory,
              label: "Inventory",
              disabled: true
            }
          ]}
        />
      )}
      <DashboardLayout fixed hasAppBar={matches}>
        <Route
          path={RouteEnum.listings}
          exact
          render={() => {
            return <Redirect to={RouteEnum.listingsAll} />;
          }}
        />
        <Switch>
          <Route
            path={RouteEnum.listingsAll}
            key={RouteEnum.listingsAll}
            render={() => {
              if (matches) {
                return (
                  <ListingsCardView
                    noDataComponent={
                      <NoDataComponent
                        title="No HyreCar Listings"
                        subtitle={
                          !checkPermission(
                            CarsDomainPermissionPaths.listCar,
                            scopes
                          )
                            ? ""
                            : "In the meantime, you could..."
                        }
                        buttons={[
                          {
                            content: "Create a listing",
                            onClick: sendToListCarFlow,
                            hidden: !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                          }
                        ]}
                        image={emptyStateIcon}
                      />
                    }
                    initialWhere={{
                      OR: [
                        {
                          verification: CarVerificationEnum.verified
                        },
                        {
                          status: CarStatusEnum.deleted
                        }
                      ]
                    }}
                    filters={allListingsFilters}
                  />
                );
              } else {
                return fleetStatusAggregateLoading ? (
                  <Chimes loadingText={"Loading..."} />
                ) : (
                  <ListingsTable
                    onRowClick={handleOnRowClick}
                    tableName="All Listings"
                    downloadData={listingsCsvData}
                    noDataComponent={
                      <NoDataComponent
                        title="You do not have any HyreCar Listings"
                        subtitle={
                          !checkPermission(
                            CarsDomainPermissionPaths.listCar,
                            scopes
                          )
                            ? ""
                            : "In the meantime, you could..."
                        }
                        buttons={[
                          {
                            content: "Create a listing",
                            onClick: checkPermission(
                              CarsDomainPermissionPaths.csvRooftopSelect,
                              scopes
                            )
                              ? matches
                                ? sendToListCarFlow
                                : sendToSelectFlow
                              : sendToListCarFlow,
                            hidden: !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                          }
                        ]}
                      />
                    }
                    initialColumns={[
                      {
                        field: "car",
                        title: "Year, Make, Model",
                        checked: true
                      },
                      {
                        field: "vin",
                        title: "VIN",
                        checked: true
                      },
                      {
                        field: "dailyPriceInDollars",
                        title: "Daily",
                        checked: true
                      },
                      {
                        field: "protectionPlan",
                        title: "Protection",
                        checked: true
                      },
                      {
                        field: "updatedAt",
                        title: "Updated",
                        checked: true
                      },
                      {
                        field: "statusTableCell",
                        title: "Status",
                        checked: true
                      }
                    ]}
                    initialWhere={{
                      OR: [
                        {
                          verification: CarVerificationEnum.verified
                        },
                        {
                          status: CarStatusEnum.deleted
                        }
                      ]
                    }}
                    filters={allListingsFilters}
                    initialFilters={allListingsFilters.slice(0, -1)}
                  />
                );
              }
            }}
          />
          <Route
            path={RouteEnum.listingsProgress}
            key={RouteEnum.listingsProgress}
            render={() => {
              if (matches) {
                return (
                  <ListingsCardView
                    noDataComponent={
                      <NoDataComponent
                        title="No In Progress Listings"
                        subtitle={
                          !checkPermission(
                            CarsDomainPermissionPaths.listCar,
                            scopes
                          )
                            ? ""
                            : "In the meantime, you could..."
                        }
                        buttons={[
                          {
                            content: "Create a listing",
                            onClick: matches
                              ? sendToListCarFlow
                              : sendToSelectFlow,
                            hidden: !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                          }
                        ]}
                        image={emptyStateIcon}
                      />
                    }
                    initialWhere={{
                      AND: [
                        {
                          verification_in: [
                            CarVerificationEnum.pendingVerification,
                            CarVerificationEnum.failedVerification
                          ]
                        },
                        {
                          status_not: CarStatusEnum.deleted
                        }
                      ]
                    }}
                    filters={inProgressListingsFilters}
                  />
                );
              } else {
                return (
                  <ListingsTable
                    onRowClick={handleOnRowClick}
                    tableName="In Progress"
                    noDataComponent={
                      <NoDataComponent
                        title="You do not have any In Progress Listings"
                        subtitle={
                          !checkPermission(
                            CarsDomainPermissionPaths.listCar,
                            scopes
                          )
                            ? ""
                            : "In the meantime, you could..."
                        }
                        buttons={[
                          {
                            content: "Create a listing",
                            onClick: checkPermission(
                              CarsDomainPermissionPaths.csvRooftopSelect,
                              scopes
                            )
                              ? matches
                                ? sendToListCarFlow
                                : sendToSelectFlow
                              : sendToListCarFlow,
                            hidden: !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                          }
                        ]}
                      />
                    }
                    initialColumns={[
                      {
                        field: "car",
                        title: "Year, Make, Model",
                        checked: true
                      },
                      {
                        field: "vin",
                        title: "VIN",
                        checked: true
                      },
                      {
                        field: "dailyPriceInDollars",
                        title: "Daily",
                        checked: true
                      },
                      {
                        field: "protectionPlan",
                        title: "Protection",
                        checked: true
                      },
                      {
                        field: "updatedAt",
                        title: "Updated",
                        checked: true
                      },
                      {
                        field: "statusTableCell",
                        title: "Status",
                        checked: true
                      }
                    ]}
                    initialWhere={{
                      AND: [
                        {
                          verification_in: [
                            CarVerificationEnum.pendingVerification,
                            CarVerificationEnum.failedVerification
                          ]
                        },
                        {
                          status_not: CarStatusEnum.deleted
                        }
                      ]
                    }}
                    filters={inProgressListingsFilters}
                  />
                );
              }
            }}
          />
          {iccDealer && (
            <Route
              path={RouteEnum.listingsInventory}
              key={RouteEnum.listingsInventory}
              render={() => {
                if (matches) {
                  return (
                    <ListingsCardView
                      noDataLabel="No Inventory"
                      initialWhere={{
                        verification: CarVerificationEnum.incomplete
                      }}
                    />
                  );
                } else {
                  return (
                    <ListingsTable
                      onRowClick={handleOnRowClick}
                      tableName="Inventory"
                      noDataLabel="No Inventory"
                      initialWhere={{
                        verification: CarVerificationEnum.incomplete
                      }}
                      initialColumns={[
                        {
                          field: "car",
                          title: "Year, Make, Model",
                          checked: true
                        },
                        {
                          field: "vin",
                          title: "VIN",
                          checked: true
                        },
                        {
                          field: "dailyPriceInDollars",
                          title: "Daily",
                          checked: true
                        },
                        {
                          field: "protectionPlan",
                          title: "Protection",
                          checked: true
                        },
                        {
                          field: "updatedAt",
                          title: "Updated",
                          checked: true
                        },
                        {
                          field: "statusTableCell",
                          title: "Status",
                          checked: true
                        }
                      ]}
                    />
                  );
                }
              }}
            />
          )}
        </Switch>
      </DashboardLayout>
    </>
  );
});
