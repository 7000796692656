import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  topBar: {
    height: theme.spacing(8)
  },
  root: {
    top: ({ isStatic }) => isStatic && theme.spacing(0),
    position: ({ isStatic }) => isStatic && "fixed",
    width: ({ isStatic }) => isStatic && "inherit",
    zIndex: ({ isStatic }) => isStatic && "1000",
    background: props => {
      if (props.primaryThemeLight) return theme.palette.primary[500];
      else
        return props.primaryTheme
          ? theme.palette.primary[800]
          : theme.palette.background.paper;
    },
    boxShadow: props => (props.border ? null : theme.shadows[4]),
    borderBottom: props =>
      props.border ? `1px solid ${theme.palette.divider}` : null
  },
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: props =>
      props.primaryTheme ? theme.spacing(1, 2, 1, 0) : theme.spacing(1, 2)
  },
  title: {
    fontSize: "1.25em",
    lineHeight: "30px",
    letterSpacing: "0.15px",
    marginLeft: theme.spacing(3),
    color: props =>
      props.primaryTheme || props.primaryThemeLight
        ? theme.palette.common.white
        : "rgba(0,0,0,0.87)"
  },
  rightIcon: {
    marginLeft: "auto"
  }
}));

/**
 * The DrawerAppBar has the look and feel of a material AppBar, but inside of a drawer.
 * It can act as navigation and also provides information as to what the user is looking at
 * inside of the drawer.
 */
export const DrawerAppBar = props => {
  const {
    icon,
    title,
    border = false,
    primaryTheme,
    primaryThemeLight,
    rightIcon,
    isStatic,
    content,
    ...rest
  } = props;
  const classes = useStyles({
    border,
    primaryTheme,
    primaryThemeLight,
    isStatic,
    ...rest
  });

  return (
    <>
      {isStatic && <div className={classes.topBar} />}
      <div className={classes.root}>
        <div className={classes.inner}>
          {icon}
          {title && (
            <Typography component="span" variant="h6" className={classes.title}>
              {title}
            </Typography>
          )}
          {content}
          {rightIcon && <div className={classes.rightIcon}>{rightIcon}</div>}
        </div>
      </div>
    </>
  );
};

DrawerAppBar.propTypes = {
  icon: PropTypes.node,
  rightIcon: PropTypes.node,
  isStatic: PropTypes.bool,
  title: PropTypes.string,
  border: PropTypes.bool,
  primaryTheme: PropTypes.bool,
  primaryThemeLight: PropTypes.bool,
  helpIcon: PropTypes.node,
  content: PropTypes.any
};

DrawerAppBar.defaultProps = {
  border: false,
  isStatic: false
};
