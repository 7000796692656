import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import { centsToDollars } from "Utils/Calculations";
import { ownerGetCarDeepLink as getCarDeepLink } from "Utils/BranchHelper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  button: {
    width: "85px",
    height: "36px"
  },
  image: {
    width: "220px",
    height: "168px",
    marginRight: "64px",
    borderRadius: "25px"
  },
  shareCarCard: {
    width: "30%"
  },
  bold: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "30px"
  }
}));

export const ShareCarCard = ({ carData }) => {
  const classes = useStyles();
  const [copyLink, setCopyLink] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const car = carData?.node;

  useEffect(() => {
    (async () => {
      if (car) {
        try {
          const carDeepLink = await getCarDeepLink({
            car: {
              ...car,
              car_photo: car?.photos[0]?.file?.url,
              p2p_price_daily: car?.dailyPriceInCents
            },
            utmSource: "listing_summary",
            componentName: "Share Car Card"
          });
          setCopyLink(carDeepLink);
        } catch (e) {
          setCopyLink("");
        }
      }
    })();
  }, [car]);

  return (
    <Grid container direction="column" className={classes.shareCarCard}>
      <Avatar
        src={car?.photos[0]?.file?.url}
        alt="car"
        variant="square"
        className={classes.image}
      />
      <Typography className={classes.bold}>
        {`${car?.year} ${car?.make} ${car?.model}`}
      </Typography>
      <Typography>
        {`$${centsToDollars(car?.dailyPriceInCents)} / day`}
      </Typography>
      <CopyToClipboard
        text={copyLink}
        onCopy={() =>
          enqueueSnackbar("Copied to Clipboard!", { variant: "success" })
        }
      >
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disableElevation
        >
          SHARE
        </Button>
      </CopyToClipboard>
    </Grid>
  );
};
