import React from "react";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import { FindingLocationAnimation, WithTheme } from "hyrecar-web-components";

class GoogleMap extends React.PureComponent {
  static propTypes = {
    onCenterChange: PropTypes.func, // @controllable generated fn
    onZoomChange: PropTypes.func, // @controllable generated fn
    onBoundsChange: PropTypes.func,
    center: PropTypes.any,
    zoom: PropTypes.number
  };

  static defaultProps = {
    center: [34.0522, -118.2437],
    zoom: 10
  };

  _onChange = ({ center, zoom, bounds, marginBounds }) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({ center, zoom, bounds, marginBounds });
    } else {
      this.props.onCenterChange(center);
      this.props.onZoomChange(zoom);
    }
  };
  render() {
    return (
      <GoogleMapReact
        options={this.props.options}
        center={this.props.center}
        zoom={this.props.zoom}
        onChange={this._onChange}
      >
        {this.props.children}
      </GoogleMapReact>
    );
  }
}

GoogleMap.Loading = () => {
  return (
    <WithTheme>
      {theme => (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme.grayLighter,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FindingLocationAnimation />
        </div>
      )}
    </WithTheme>
  );
};

GoogleMap.Marker = props => (
  <WithTheme>
    {() => {
      return (
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 20
          }}
          {...props}
        />
      );
    }}
  </WithTheme>
);

export default GoogleMap;
