import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";

const FormSelect = ({
  label,
  options,
  input,
  meta: { touched, invalid, error },
  ...rest
}) => (
  <FormControl error={touched && invalid} {...rest}>
    <InputLabel htmlFor={input.name}>{label}</InputLabel>
    <Select
      value={input.value}
      onChange={input.onChange}
      inputProps={{
        name: input.name,
        id: input.name,
        onBlur: input.onBlur
      }}
      label={label}
    >
      {options.map((option, index) => (
        <MenuItem value={option} key={index}>
          {option}
        </MenuItem>
      ))}
    </Select>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    invalid: PropTypes.bool,
    touched: PropTypes.bool
  })
};

export default FormSelect;
