import React from "react";
import { Field } from "redux-form";
import Grid from "@mui/material/Grid";

import { FormTextField } from "Components/Inputs/FormTextField";

export const ResetPasswordFormFields = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          name="email"
          component={FormTextField}
          variant="filled"
          fullWidth
          placeholder="Email"
          label="Email"
        />
      </Grid>
    </Grid>
  );
};
