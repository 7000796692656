import React, { useContext } from "react";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { DriverAlertContext } from "Components/Utils/DriverAlertProvider";

const GeneralAlert = () => {
  const { getJSONValue } = useContext(FirebaseContext);
  const { disableAlert } = useContext(DriverAlertContext);

  const configuration = getJSONValue("driver_web_banner");
  return (
    <Alert
      sx={{ backgroundColor: grey[800] }}
      variant="filled"
      severity="error"
      onClose={disableAlert}
    >
      <Typography fontSize={14} variant="body1" component="p">
        {configuration?.description}
      </Typography>
    </Alert>
  );
};

export default GeneralAlert;
