import React from "react";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";

import { RouteEnum } from "Enums/RouteEnum";

const useStyles = makeStyles(() => ({
  noACHButton: {
    marginTop: "24px"
  }
}));

const SetupNowButton = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Button
      onClick={() => history.push(RouteEnum.settingsPayment)}
      variant="outlined"
      color="primary"
      className={classes.noACHButton}
    >
      SETUP NOW
    </Button>
  );
};

export default SetupNowButton;
