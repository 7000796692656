import { Component } from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import get from "lodash/get";

import { updateUsersImpactClickId } from "Mutations/User/hocs/UserMutationHocs";

class ImpactRadius extends Component {
  componentDidMount() {
    this.parseImpactClickId();
    this.updateUsersImpactClickId();
  }

  updateUsersImpactClickId() {
    const { userIsAuthenticated, mutate } = this.props;

    if (userIsAuthenticated) {
      const currentImpactClickId = document.cookie.replace(
        /(?:(?:^|.*;\s*)ImpactClickId\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );

      if (currentImpactClickId) {
        mutate({
          variables: {
            impactClickId: currentImpactClickId
          }
        })
          .then(res => {
            if (!get(res, "data.updateUsersImpactClickId.success")) {
              console.error(
                get(
                  res,
                  "data.updateUsersImpactClickId.message",
                  "[Error]: Impact Click Id was not properly updated."
                )
              );
            }
          })
          .catch(console.error);
      }
    }
  }

  parseImpactClickId() {
    const parsed = queryString.parse(window.location.search);
    Object.setPrototypeOf(parsed, Object.prototype);

    if (
      Object.prototype.hasOwnProperty.call(parsed, "clickid") &&
      parsed.clickid !== null
    ) {
      const currentImpactClickId = document.cookie.replace(
        /(?:(?:^|.*;\s*)ImpactClickId\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );

      if (
        currentImpactClickId === "" ||
        currentImpactClickId !== parsed.clickid
      ) {
        document.cookie = `ImpactClickId=${parsed.clickid}`;
      }
    }
  }

  render() {
    return null;
  }
}

ImpactRadius.propTypes = {
  userIsAuthenticated: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired
};

export default updateUsersImpactClickId()(ImpactRadius);
