import firebase from "firebase/app";
import "firebase/firebase-remote-config";
import config from "Config/config";

firebase.initializeApp(config.firebaseConfig);
const remoteConfig = firebase.remoteConfig();
if (process.env.REACT_APP_STAGE !== "production")
  remoteConfig.settings.minimumFetchIntervalMillis = 3000;
if (process.env.REACT_APP_STAGE === "production")
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
export { remoteConfig };
export default firebase;
