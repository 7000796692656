import React from "react";
import { Field, formValues } from "redux-form";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";

import { ProtectionCardSelect } from "Components/Cards/ProtectionCardSelect";
import { FormTextField } from "Components/Inputs/FormTextField";
import { FormSelect } from "Components/Inputs/FormSelect";
import { getEarnings } from "Utils/Helpers";
import {
  mileage_options,
  normalize
} from "Components/Forms/FormControllers/hooks/useCSVFormController";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(0.75)
  },
  subtitle: {
    marginBottom: theme.spacing(3.5),
    marginTop: theme.spacing(2)
  },
  divider: {
    height: theme.spacing(0.25),
    marginTop: theme.spacing(4.625),
    marginBottom: theme.spacing(4.625)
  }
}));

export const ProtectionPriceFormFields = formValues({
  daily_rate: "daily_rate",
  weekly_discount: "weekly_discount",
  monthly_discount: "monthly_discount",
  defaultProtectionPlan: "defaultProtectionPlan",
  address: "address"
})(
  ({
    daily_rate,
    weekly_discount,
    monthly_discount,
    defaultProtectionPlan,
    address
  }) => {
    const classes = useStyles();
    const { netDailyPrice, weeklyEarnings, monthlyEarnings } = getEarnings({
      daily_rate,
      weekly_discount,
      monthly_discount,
      protection_plan: defaultProtectionPlan
    });

    return (
      <>
        <Typography variant="h6" className={classes.title}>
          Select a HyreCar Protection Plan
        </Typography>
        <Divider />
        <Typography className={classes.subtitle}>
          Choose a default protection plan that best fits your needs. Stick with
          more protection in exchange for less commission, or risk limited
          coverage for more earnings.
        </Typography>
        <Field
          name="defaultProtectionPlan"
          component={ProtectionCardSelect}
          fullWidth
        />
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.title}>
          Pricing
        </Typography>
        <Typography gutterBottom className={classes.subtitle}>
          {`We've made some suggestions based on the median price in ${address.city}. 
          A competitive price will make your listing
          stand out in the marketplace.`}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Field
              name="daily_rate"
              label="Daily Price"
              variant="filled"
              fullWidth
              margin="normal"
              helperText={`Earn $${netDailyPrice} daily`}
              normalize={normalize}
              component={FormTextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="maxDailyMiles"
              label="Daily Allowed Miles"
              variant="filled"
              fullWidth
              margin="normal"
              component={FormSelect}
              options={mileage_options}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Field
              name="weekly_discount"
              label="Weekly Discount"
              variant="filled"
              fullWidth
              margin="normal"
              helperText={`Earn $${weeklyEarnings} weekly`}
              normalize={normalize}
              component={FormTextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="monthly_discount"
              label="Monthly Discount"
              variant="filled"
              fullWidth
              margin="normal"
              helperText={`Earn $${monthlyEarnings} monthly`}
              normalize={normalize}
              component={FormTextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);
