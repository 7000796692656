import React from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    padding: theme.spacing(0)
  },
  title: {
    color: theme.palette.common.black
  },
  content: {
    padding: theme.spacing(0)
  },
  actions: {
    padding: theme.spacing(0),
    paddingTop: ({ matches }) => (matches ? null : theme.spacing(2))
  }
}));

export const FullScreenDialog = props => {
  const {
    open,
    onClose,
    maxWidth,
    fullScreen,
    title,
    dialogContentText,
    content,
    actions,
    ...rest
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles({ classes: rest.classes, matches });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth ? maxWidth : "xs"}
      fullWidth
      classes={{
        paper: classes.dialogPaper
      }}
      fullScreen={fullScreen}
    >
      {title && typeof title === "string" ? (
        <DialogTitle className={classes.title}>{title}</DialogTitle>
      ) : (
        title && title
      )}
      <DialogContent classes={{ root: classes.content }}>
        {dialogContentText && (
          <DialogContentText>{dialogContentText}</DialogContentText>
        )}
        {content && content}
      </DialogContent>
      {actions && (
        <DialogActions classes={{ root: classes.actions }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  fullScreen: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dialogContentText: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node,
    PropTypes.object
  ]),
  actions: PropTypes.node,
  classes: PropTypes.object
};

FullScreenDialog.defaultProps = {
  fullScreen: false
};
