export const requiredFields = (values, fields) => {
  const errors = {};
  fields.map(field => {
    errors[field] = !values[field] ? "Required" : undefined;
    return errors;
  });
  return errors;
};

export const blurAndChangeFields = (blur, change, fields) => {
  fields.forEach(field => {
    if (field) {
      blur(field.name, { value: field.value, label: field.value });
      change(field.name, { value: field.value, label: field.value });
    }
  });
};

export const resetFieldValues = (change, fields) => {
  fields.forEach(field => {
    if (field) change(field, "");
  });
};

export const untouchFields = (untouch, fields) => {
  fields.forEach(field => {
    if (field) untouch(field);
  });
};

export const touchFields = (touch, fields) => {
  fields.forEach(field => {
    if (field) touch(field);
  });
};
