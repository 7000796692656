import React, { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import NumberFormat from "react-number-format";

import { Card } from "./Card";
import { CardCell } from "./CardCell";
import { CardHeader } from "./CardHeader";
import { RouteEnum } from "Enums/RouteEnum";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { EARNINGS_QUERY } from "Queries/Transactions/TransactionsQueries";

export const EarningsCard = () => {
  const { currentRooftopToken, currentRooftopClient } = useContext(
    ClientFactoryContext
  );
  const { data, loading, refetch } = useQuery(EARNINGS_QUERY, {
    client: currentRooftopClient,
    context: {
      apiv2: true
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    refetch();
  }, [currentRooftopToken, refetch]);

  let networkErrorState = !data?.getDashboardEarnings?.dashboardEarnings;

  const dashboardEarnings = data?.getDashboardEarnings?.dashboardEarnings;

  const renderCardCell = (value, label) => (
    <Grid container item md={6} xs={12}>
      <CardCell
        value={
          <NumberFormat
            value={(value / 100).toFixed(0)}
            thousandSeparator={true}
            displayType={"text"}
            prefix={"$"}
          />
        }
        label={label}
        loading={loading || networkErrorState}
      />
    </Grid>
  );

  return (
    <Card>
      <CardHeader
        label="Net Earnings"
        to={RouteEnum.financials}
        link="View More"
      />
      <Grid container>
        {renderCardCell(dashboardEarnings?.pastThreeDays, "Past 3 Days")}
        {renderCardCell(dashboardEarnings?.pastSevenDays, "Past 7 Days")}
        {renderCardCell(dashboardEarnings?.pastFourteenDays, "Past 14 Days")}
        {renderCardCell(dashboardEarnings?.pastThirtyDays, "Past 30 Days")}
      </Grid>
    </Card>
  );
};
