import React, { useContext } from "react";

import { DocumentsForm } from "./DocumentsForm";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { StepperFunctions } from "../ManualContainer";

export const Documents = () => {
  const { onSubmit, previousStep } = useContext(StepperFunctions);

  const left = (
    <DocumentsForm onSubmit={onSubmit} previousStep={previousStep} />
  );

  return <ColumnFlowLayout leftComponent={left} useBottomDrawer />;
};
