import React, { useContext } from "react";
import { reduxForm } from "redux-form";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsReimbursmentController";
import { UploadImagesFormFields } from "Components/Forms/FormFields/Claims/UploadImagesFormFields";
import { useClaimsReimbursementFormController } from "Components/Forms/FormControllers/hooks/useClaimsReimbursementFormController";

const ClaimsReimbursmentImagesForm = reduxForm({
  form: "CLAIMS_REIMBURSEMENT",
  destroyOnUnmount: false
})(({ handleSubmit, submitting, prevStep, form }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      nextStepLabel={submitting ? "Submitting..." : "Submit"}
      previousStep={prevStep}
      previousStepLabel="back"
      header="Upload Images"
      disabled={submitting}
    >
      <UploadImagesFormFields
        text={
          <>
            Please provide proof for any reimbursements you are claiming.
            <br />
            (Examples include receipts, pictures of your odometer or gas level
            before and after the rental, or other.)
            <br />
            Maximum File Size: 7MB, Max Uploads: 10
          </>
        }
        form={form}
      />
    </FlowContentContainer>
  );
});

export const ClaimsReimbursmentImages = () => {
  const { onSubmit, prevStep } = useContext(StepperContext);
  const { validate } = useClaimsReimbursementFormController();

  const leftComponent = (
    <ClaimsReimbursmentImagesForm
      onSubmit={onSubmit}
      prevStep={prevStep}
      validate={validate}
    />
  );

  return <ColumnFlowLayout leftComponent={leftComponent} />;
};
