import React, { useState } from "react";
import { Field } from "redux-form";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { FormTextField } from "Components/Inputs/FormTextField";
import { FormCheckbox } from "Components/Inputs/FormCheckbox";
import { PhoneMask } from "Components/Forms/InputMasks/PhoneMask";
import { ZipMask } from "Components/Forms/InputMasks/ZipMask";
import styled from "styled-components";
import { USER_COMMUNICATION_OPTIONS } from "Login/SignupFlow/UserComunicationOptions";

const StyledCheckbox = styled(FormCheckbox)`
  && {
    > label > span {
      align-self: start;
      padding-top: 0;
      font-size: 15px;
    }
  }
`;

export const SignUpFormFields = () => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            name="email"
            component={FormTextField}
            fullWidth
            variant="filled"
            placeholder="Email"
            label="Email"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="password"
            component={FormTextField}
            fullWidth
            variant="filled"
            placeholder="Password"
            label="Password"
            autoComplete="new-password"
            type={hidePassword ? "password" : "text"}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setHidePassword(prevVal => !prevVal)}
                  size="large"
                >
                  {hidePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="firstName"
            component={FormTextField}
            fullWidth
            variant="filled"
            placeholder="First Name"
            label="First Name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="lastName"
            component={FormTextField}
            fullWidth
            variant="filled"
            placeholder="Last Name"
            label="Last Name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="phone"
            component={FormTextField}
            fullWidth
            variant="filled"
            placeholder="Phone Number"
            label="Phone Number"
            inputComponent={PhoneMask}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="zip"
            component={FormTextField}
            fullWidth
            variant="filled"
            placeholder="Zip Code"
            label="Zip Code"
            inputComponent={ZipMask}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Field
            name="marketing"
            component={StyledCheckbox}
            label={USER_COMMUNICATION_OPTIONS.marketing}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="transactional"
            component={StyledCheckbox}
            label={USER_COMMUNICATION_OPTIONS.transactional}
          />
        </Grid>
      </Grid>
    </>
  );
};
