import React from "react";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

export const FormDatePicker = ({
  label,
  input: { value, name, onChange },
  meta: { invalid, error, touched },
  disabled,
  id
}) => (
  <LocalizationProvider dateAdapter={DateAdapter}>
    <DatePicker
      renderInput={props => {
        return (
          <TextField
            id={id && id}
            helperText={touched && error}
            variant="filled"
            fullWidth
            {...props}
            error={touched & invalid}
            disabled={disabled}
          />
        );
      }}
      label={label}
      name={name}
      value={value ? value : null}
      onChange={onChange}
      disabled={disabled}
    />
  </LocalizationProvider>
);

FormDatePicker.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  })
};
