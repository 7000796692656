import { combineReducers } from "redux";

import {
  ADD_ICC_LIST_FLOW_CAR_ID,
  REMOVE_ICC_LIST_FLOW_CAR_ID,
  CLEAR_ICC_LIST_FLOW_CAR_ID
} from "./Actions";

const iccListFlowCarIds = (state = [], action) => {
  switch (action.type) {
    case ADD_ICC_LIST_FLOW_CAR_ID: {
      return [...state, action.carId];
    }
    case REMOVE_ICC_LIST_FLOW_CAR_ID: {
      const removedCarId = action.carId;
      return state.filter(carId => carId !== removedCarId);
    }
    case CLEAR_ICC_LIST_FLOW_CAR_ID: {
      return [];
    }
    default:
      return state;
  }
};

export default combineReducers({
  iccListFlowCarIds
});
