import React, { useContext, useMemo } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { FirebaseContext } from "Components/Utils/FirebaseProvider";

export const UserBlockedModal = ({ show }) => {
  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  return (
    <Modal show={show} className="fleet-modal action-modal-container">
      <Modal.Header>
        <Modal.Title>Account Deactivated</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your account has been deactivated. Please contact support at{" "}
        {customerServicePhoneNumber} for further information.
      </Modal.Body>
    </Modal>
  );
};

UserBlockedModal.propTypes = {
  show: PropTypes.bool
};
