import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  checkboxError: {
    margin: theme.spacing(1, 1.5)
  }
}));

export const FormCheckbox = ({
  label,
  input: { name, value, onChange, onBlur },
  meta: { touched, error, invalid },
  id,
  dataTestID,
  ...custom
}) => {
  const classes = useStyles();

  const handleChecked = e => onChange(e.target.checked);

  return (
    <FormControl error={touched && invalid} {...custom}>
      <FormControlLabel
        control={
          <Checkbox
            id={id && id}
            name={name}
            checked={!!value}
            onChange={handleChecked}
            onBlur={onBlur}
            color="primary"
            inputProps={{
              "data-testid": dataTestID
            }}
          />
        }
        label={label}
      />
      {touched && error && (
        <FormHelperText className={classes.checkboxError}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FormCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  }).isRequired,
  custom: PropTypes.any
};
