import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { ResourceCard } from "Components/Cards/ResourceCard";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { RouteEnum } from "Enums/RouteEnum";
import { MobileHeader } from "Components/Header/MobileHeader";
import { enableHeader, disableHeader } from "Redux/Header/ActionCreators";

export const Resources = connect(null, { enableHeader, disableHeader })(
  ({ enableHeader, disableHeader }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const { getJSONValue, getAsset } = useContext(FirebaseContext);
    const cards = getJSONValue("web_resourceCards");

    useEffect(() => {
      if (matches) {
        disableHeader();
      }

      return enableHeader;
    }, [matches, enableHeader, disableHeader]);

    return (
      <>
        {matches && <MobileHeader title="Resources" />}
        <DashboardLayout fixed>
          <Grid container spacing={5}>
            <Grid item lg={4} md={6} xs={12}>
              <ResourceCard
                image={getAsset("findCar")}
                imageTitle="Find a Car"
                subTitle="Tool"
                title="Compare Listings"
                linkText="View"
                isRoute
                to={RouteEnum.marketplace}
              >
                <Typography variant="body2" component="p">
                  Find out how other owners are pricing their listings in your
                  area.
                </Typography>
              </ResourceCard>
            </Grid>
            {cards?.map((card, i) => (
              <Grid key={i} item lg={4} md={6} xs={12}>
                <ResourceCard
                  image={card.image.uri}
                  imageTitle={card.image.title}
                  subTitle={card.subtitle}
                  title={card.title}
                  to={card.link.href}
                  linkText={card.link.text}
                >
                  <Typography variant="body2" component="p">
                    {card.content}
                  </Typography>
                </ResourceCard>
              </Grid>
            ))}
          </Grid>
        </DashboardLayout>
      </>
    );
  }
);
