import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

import { Table } from "./Table";
import { TableHead } from "./TableHead";
import { TableFooter } from "./TableFooter";
import { useListingsTable } from "Queries/Fleet/hooks/useListingsTable";
import { useTableState } from "Components/Tables/hooks/useTableState";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

import {
  removeExtraMiddleSpaces,
  removeTrailingSpaces
} from "Utils/textFormatting";

const formatSearchText = text => {
  if (!text) return text;
  const valueNoTrailingSpaces = removeTrailingSpaces(text);
  const valueNoExtraMiddleSpaces = removeExtraMiddleSpaces(
    valueNoTrailingSpaces
  );
  return valueNoExtraMiddleSpaces;
};

export const ListingsTable = ({
  initialColumns,
  initialWhere,
  filters,
  tableName,
  noDataLabel,
  noDataComponent,
  onRowClick,
  downloadData,
  initialFilters
}) => {
  const {
    search,
    first,
    orderBy,
    page,
    selectedFilters,
    columns,
    handleSearchChange,
    handleFirstChange,
    nextPage,
    previousPage,
    handleSelectFilter,
    handleDeleteFilter
  } = useTableState({
    initialFirst: 10,
    initialOrderBy: "updatedAt_DESC",
    initialColumns,
    initialFilters
  });
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const { mappedData, pageInfo, loading } = useListingsTable({
    variables: {
      searchText: formatSearchText(search),
      first,
      orderBy,
      skip: page * first,
      where: {
        ...initialWhere,
        ...selectedFilters.reduce((acc, val) => {
          if (Object.prototype.hasOwnProperty.call(acc, `${val.key}`)) {
            acc[val.key].push(val.value);
          } else {
            acc[val.key] = [val.value];
          }
          return acc;
        }, {})
      }
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  return (
    <Paper>
      <TableHead
        tableName={tableName}
        onSearchChange={handleSearchChange}
        search={search}
        searchPlaceholder="Search by VIN, Year, Make, Model"
        filters={filters}
        selectedFilters={selectedFilters}
        onSelectFilter={handleSelectFilter}
        onDeleteFilter={handleDeleteFilter}
        loading={loading}
        downloadData={downloadData}
      />
      <Table
        columns={columns}
        data={mappedData}
        noDataLabel={noDataLabel}
        noDataComponent={noDataComponent}
        loading={loading}
        onRowClick={onRowClick}
      />
      <TableFooter
        rowsPerPage={first}
        onRowsPerPageChange={handleFirstChange}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={page > 0}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        page={page}
      />
    </Paper>
  );
};

ListingsTable.propTypes = {
  initialColumns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      checked: PropTypes.bool
    })
  ),
  initialWhere: PropTypes.object,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  tableName: PropTypes.string,
  noDataLabel: PropTypes.string,
  noDataComponent: PropTypes.node,
  onRowClick: PropTypes.func
};
