import React, { useMemo, useCallback, useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import pick from "lodash/pick";

import { FileTypeEnum } from "Enums/StateEnums";
import { UPLOAD_FILE } from "Mutations/File/FileMutations";
import { DocumentDropzone } from "Components/Forms/Dropzone/DocumentDropzone";
import { DraggableContainer, Draggable } from "Components/Utils/Draggable";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FilesLightbox } from "Components/Lightbox/FilesLightbox";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  item: {
    width: "140px",
    height: "140px",
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(2)
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
    border: `1px solid ${theme.palette.grey[400]}`
  },
  selected: {
    border: `2px solid ${theme.palette.primary[500]}`
  },
  placeholder: {
    width: "100%",
    height: "100%",
    background: theme.palette.grey[100]
  },
  overlay: {
    background: "rgba(244,247,252,.8)",
    textAlign: "center",
    pointerEvents: "none",
    marginTop: "-32px",
    marginLeft: "1px",
    width: "122px"
  },
  subtitle: {
    marginBottom: "1.5em"
  },
  fab: {
    marginLeft: "100px",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white
    },
    position: "absolute"
  },
  icon: {
    height: "0.9em",
    width: "0.9em"
  }
}));

const CoverImageLabel = ({ classes, i }) =>
  i === 0 && <div className={classes.overlay}>{"Cover image"}</div>;

export const AsyncImagesOrderer = ({
  input: { value, onChange },
  onSelect,
  selected,
  dropzoneWhenEmpty,
  numberOfImages,
  subtitle
}) => {
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [uploadFile] = useMutation(UPLOAD_FILE, {
    client: currentRooftopClient
  });
  const images = value ? value : [];
  const classes = useStyles();

  const asyncAction = useCallback(
    async value => {
      try {
        const { data } = await uploadFile({
          variables: {
            file: value,
            type: FileTypeEnum.carPhoto
          }
        });
        const upload = data.uploadFile;

        return {
          ...pick(upload, ["id", "url", "contentType"])
        };
      } catch (e) {
        console.error(e);
      }
    },
    [uploadFile]
  );

  const onDropzoneDrop = useCallback(
    file => onChange(value ? [...value, file] : [file]),
    [onChange, value]
  );

  const onDelete = useCallback(
    index => {
      const imagesCopy = images.slice(0, images.length);
      imagesCopy.splice(index, 1);
      onChange(imagesCopy);
    },
    [onChange, value]
  );

  const onDrop = useCallback(
    ({ destination, dragged }) => {
      const dragIndex = get(dragged, "index", null);
      const destinationIndex = get(destination, "index", null);

      if (
        typeof dragIndex === "number" &&
        typeof destinationIndex === "number" &&
        dragIndex !== destinationIndex
      ) {
        const imagesCopy = images.slice(0, images.length);
        const dragImage = imagesCopy[dragIndex];
        imagesCopy[dragIndex] = null;

        const leftSliceOfImages = imagesCopy.slice(
          0,
          destinationIndex > dragIndex ? destinationIndex + 1 : destinationIndex
        );
        const rightSliceOfImages = imagesCopy.slice(
          destinationIndex > dragIndex
            ? destinationIndex + 1
            : destinationIndex,
          imagesCopy.length
        );

        const mergedImages = [
          ...leftSliceOfImages,
          dragImage,
          ...rightSliceOfImages
        ].filter(image => image !== null);

        if (dragIndex === selected) {
          onSelect(destinationIndex);
        } else {
          if (
            dragIndex > destinationIndex &&
            selected >= destinationIndex &&
            selected <= dragIndex
          ) {
            onSelect(selected + 1);
          } else if (
            dragIndex < destinationIndex &&
            selected <= destinationIndex &&
            selected >= dragIndex
          ) {
            onSelect(selected - 1);
          }
        }

        onChange(mergedImages);
      }
    },
    [images, onChange, onSelect, selected]
  );

  const grid = useMemo(() => {
    const grid = [];

    for (let i = 0; i < numberOfImages; i++) {
      if (images[i]) {
        grid.push(
          <DraggableContainer onDrop={onDrop}>
            <Draggable id={uuidv4()} index={i}>
              {draggable => {
                return (
                  <>
                    <Fab
                      color="primary"
                      size="small"
                      onClick={() => onDelete(i)}
                      className={classes.fab}
                      component={"button"}
                    >
                      <DeleteForeverIcon
                        color="error"
                        className={classes.icon}
                      />
                    </Fab>
                    <img
                      id="editListingImageThumbnail"
                      className={classNames(classes.image, {
                        [classes.selected]: i === selected
                      })}
                      src={images[i].url}
                      onClick={() => {
                        setLightboxIndex(i);
                        onSelect && onSelect(i);
                      }}
                      {...draggable}
                      alt="Car"
                    />
                    <CoverImageLabel classes={classes} i={i} />
                  </>
                );
              }}
            </Draggable>
          </DraggableContainer>
        );
      } else if (i === images.length) {
        if (i === 0 && !dropzoneWhenEmpty) {
          grid.push(<div className={classes.placeholder} />);
        } else {
          grid.push(
            <DocumentDropzone
              accept="image/jpeg, image/png"
              input={{
                onChange: onDropzoneDrop
              }}
              meta={{}}
              asyncAction={asyncAction}
            />
          );
        }
      } else {
        grid.push(<div className={classes.placeholder} />);
      }
    }

    return grid;
  }, [
    selected,
    classes.image,
    classes.placeholder,
    classes.selected,
    classes.overlay,
    dropzoneWhenEmpty,
    images,
    numberOfImages,
    onDrop,
    onDropzoneDrop,
    onSelect,
    asyncAction
  ]);

  return (
    <>
      {subtitle && (
        <div className={classes.subtitle}>
          <Typography variant="caption">{subtitle}</Typography>
        </div>
      )}
      <div className={classes.root}>
        {grid.map((gridItem, index) => (
          <div className={classes.item} key={index}>
            {gridItem}
          </div>
        ))}
        {lightboxIndex >= 0 && (
          <FilesLightbox
            files={images}
            index={lightboxIndex}
            setIndex={setLightboxIndex}
          />
        )}
      </div>
    </>
  );
};

AsyncImagesOrderer.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
  }),
  onSelect: PropTypes.func,
  selected: PropTypes.number,
  dropzoneWhenEmpty: PropTypes.bool,
  numberOfImages: PropTypes.number.isRequired
};
