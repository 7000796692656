import { FilesLightbox } from "Components/Lightbox/FilesLightbox";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

const trackListingPhotoClick = () =>
  analytics.track(AnalyticsEvents.label.owner.listingPhotoSlideShowOpened, {
    category: AnalyticsEvents.category.userInteraction,
    action: AnalyticsEvents.action.buttonClicked,
    label: AnalyticsEvents.label.owner.listings,
    property: JSON.stringify({}),
    value: "",
    context: ""
  });

const Image = ({ className, imageUrl, setIndex }) => (
  <img
    alt="car"
    className={className}
    onClick={() => {
      setIndex(0);
      trackListingPhotoClick();
    }}
    src={imageUrl}
  />
);

const SlideShow = ({ className, imageUrl, index, photos, setIndex }) =>
  index > -1 && (
    <div>
      <img
        alt="car"
        className={className}
        onClick={() => {
          setIndex(0);
          trackListingPhotoClick();
        }}
        src={imageUrl}
      />
      <FilesLightbox
        files={photos.map(photo => ({ url: photo?.file?.url }))}
        index={index}
        setIndex={setIndex}
      />
    </div>
  );

export const ListingPhotoSlideShow = props => {
  const { photos } = props;
  const [index, setIndex] = useState(-1);

  return index < 0
    ? Image({
        ...props,
        setIndex
      })
    : SlideShow({
        ...props,
        index,
        photos,
        setIndex
      });
};

ListingPhotoSlideShow.propTypes = {
  carImageUrl: PropTypes.string,
  className: PropTypes.string,
  photos: PropTypes.array
};
