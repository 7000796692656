import React, { Fragment, useState, useEffect } from "react";
import { Field } from "redux-form";
import { getDatesFromRange } from "Redux/utils";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import makeStyles from "@mui/styles/makeStyles";

import { FormTextField } from "Components/Inputs/FormTextField";
import { TransactionsDateFilter } from "Fleet/Dashboard/DashboardViews/Financials/Transactions/TransactionsDateFilter";

const useStyles = makeStyles(() => ({
  paddingBottom: {
    paddingBottom: "0 !important"
  }
}));

export const ExportTransactionsFormFields = props => {
  const { dateFilters, change, payoutIds } = props;
  const classes = useStyles();
  const [numberOfEmails, setNumberOfEmails] = useState([]);
  const [startDate, setStartDate] = useState(
    !payoutIds ? dateFilters.startDate : null
  );
  const [endDate, setEndDate] = useState(
    !payoutIds ? dateFilters.endDate : null
  );
  const [dateDescriptionText, setDateDescriptionText] = useState(
    !payoutIds ? dateFilters.dateDescriptionText : ""
  );
  const [customDateDialogOpen, setCustomDateDialogOpen] = useState(
    !payoutIds ? dateFilters.customDateDialogOpen : ""
  );

  useEffect(() => {
    // do once
    change("startDate", !payoutIds ? startDate : null);
    change("endDate", !payoutIds ? endDate : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFieldDateFilters = selectedRange => {
    const selectedDates = getDatesFromRange(selectedRange);
    setStartDate(selectedDates.startDate);
    setEndDate(selectedDates.endDate);
    setDateDescriptionText(selectedRange);
    change("startDate", selectedDates.startDate);
    change("endDate", selectedDates.endDate);
  };
  const handleFieldCustomDateFilters = customRange => {
    setStartDate(customRange.startDate);
    setEndDate(customRange.endDate);
    setDateDescriptionText("Custom");
    setCustomDateDialogOpen(false);
    change("startDate", customRange.startDate);
    change("endDate", customRange.endDate);
  };
  const toggleFieldDateDialog = isOpen => {
    setCustomDateDialogOpen(isOpen);
  };

  const customDateFilters = {
    startDate,
    endDate,
    dateDescriptionText,
    customDateDialogOpen
  };

  const handleAddEmail = () => {
    if (numberOfEmails.length < 3) {
      setNumberOfEmails(prev => [...prev.concat(0)]);
    }
  };

  const handleRemoveEmail = () => {
    if (numberOfEmails.length > 0) {
      setNumberOfEmails(prev => {
        prev.pop();
        return [...prev];
      });
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        {!payoutIds ? (
          <Fragment>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Typography variant="subtitle1">Select Dates</Typography>
            </Grid>
            <Grid item xs={12}>
              <TransactionsDateFilter
                defaultStartDate={dateFilters.startDate}
                defaultEndDate={dateFilters.endDate}
                updateFieldDateFilters={updateFieldDateFilters}
                handleFieldCustomDateFilters={handleFieldCustomDateFilters}
                toggleFieldDateDialog={toggleFieldDateDialog}
                customDateFilters={customDateFilters}
                customHandlers
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        ) : null}
        <Grid item xs={12} className={classes.paddingBottom}>
          <Typography variant="subtitle1">Email To:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="email0"
            component={FormTextField}
            placeholder="Email"
            fullWidth
            variant="filled"
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>
        {numberOfEmails.map((val, index) => (
          <Grid item xs={12} key={index}>
            <Field
              name={`email${index + 1}`}
              component={FormTextField}
              placeholder="Email"
              fullWidth
              variant="filled"
            />
          </Grid>
        ))}
        <Grid
          container
          direction="row"
          justifyContent={
            numberOfEmails.length < 3 ? "space-between" : "flex-end"
          }
          paddingLeft={3}
        >
          {numberOfEmails.length < 3 && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddEmail}
            >
              Add Another
            </Button>
          )}
          {numberOfEmails.length > 0 && (
            <Button
              color="primary"
              startIcon={<RemoveIcon />}
              onClick={handleRemoveEmail}
            >
              Remove Last
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
