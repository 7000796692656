import React, { useState } from "react";
import { Field } from "redux-form";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { FormTextField } from "Components/Inputs/FormTextField";

export const LoginFormFields = () => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          name="username"
          component={FormTextField}
          label="Email"
          placeholder="Email"
          variant="filled"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password"
          component={FormTextField}
          label="Password"
          placeholder="Password"
          type={hidePassword ? "password" : "text"}
          variant="filled"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setHidePassword(prevVal => !prevVal)}
                size="large"
              >
                {hidePassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};
