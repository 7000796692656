const lottieAnimationJson = {
  v: "5.5.7",
  meta: { g: "LottieFiles AE 0.1.20", a: "", k: "", d: "", tc: "" },
  fr: 50,
  ip: 0,
  op: 200,
  w: 1000,
  h: 1000,
  nm: "8.10",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "LINE",
          parent: 7,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50.25, 43.875, 0], ix: 2 },
            a: { a: 0, k: [-8.75, -307.125, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-8.75, -307.25],
                              [-8.75, -307]
                            ],
                            c: false
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-8.75, -307.25],
                              [-8.75, -270.75]
                            ],
                            c: false
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 137,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-8.75, -307.25],
                              [-8.75, -275]
                            ],
                            c: false
                          }
                        ]
                      },
                      {
                        t: 144,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-8.75, -292.25],
                              [-8.75, -275]
                            ],
                            c: false
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941,
                      0.6627450980392157,
                      0.9568627450980393,
                      1
                    ],
                    ix: 3
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 200,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Layer 3 Outlines 6",
          parent: 7,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [50, 93.815, 0],
                  to: [0, -4.396, 0],
                  ti: [0, 4.396, 0]
                },
                { t: 147, s: [50, 67.44, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [143.693, 38, 0], ix: 1 },
            s: { a: 0, k: [158, 158, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-7.168, 0],
                        [0, -7.168],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, -7.168],
                        [7.168, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-13, 8],
                        [-13, 5],
                        [0, -8],
                        [13, 5],
                        [13, 8]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "mm",
                  mm: 4,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [143.693, 38], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 200,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Layer 3 Outlines 4",
          parent: 7,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 67.44, 0], ix: 2 },
            a: { a: 0, k: [143.693, 38, 0], ix: 1 },
            s: { a: 0, k: [158, 158, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-7.168, 0],
                        [0, -7.168],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, -7.168],
                        [7.168, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-13, 8],
                        [-13, 5],
                        [0, -8],
                        [13, 5],
                        [13, 8]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "mm",
                  mm: 4,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941,
                      0.6627450980392157,
                      0.9568627450980393,
                      1
                    ],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [143.693, 38], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 200,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Layer 3 Outlines 5",
          parent: 7,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 32.43, 0], ix: 2 },
            a: { a: 0, k: [143.693, 16, 0], ix: 1 },
            s: { a: 0, k: [158, 158, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.168, 0],
                        [0, 7.168],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [-7.168, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 7.168]
                      ],
                      v: [
                        [0, 8],
                        [-13, -5],
                        [-13, -8],
                        [13, -8],
                        [13, -5]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "mm",
                  mm: 4,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [143.693, 16], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 200,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Layer 3 Outlines 3",
          parent: 7,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [50, 32.68, 0],
                  to: [0, 4.167, 0],
                  ti: [0, -4.167, 0]
                },
                { t: 137, s: [50, 57.68, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [143.693, 16, 0], ix: 1 },
            s: { a: 0, k: [158, 158, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.168, 0],
                        [0, 7.168],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [-7.168, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 7.168]
                      ],
                      v: [
                        [0, 8],
                        [-13, -5],
                        [-13, -8],
                        [13, -8],
                        [13, -5]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "mm",
                  mm: 4,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.011764705882352941,
                      0.6627450980392157,
                      0.9568627450980393,
                      1
                    ],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [143.693, 16], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 200,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Layer 3 Outlines",
          parent: 7,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50.06, 0], ix: 2 },
            a: { a: 0, k: [143.693, 27, 0], ix: 1 },
            s: { a: 0, k: [158, 158, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [125.693, 49],
                        [161.693, 49]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.227144607843, 0.227144607843, 0.227144607843, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [125.693, 5],
                        [161.693, 5]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.227144607843, 0.227144607843, 0.227144607843, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-8.84, 0],
                        [0, -8.84],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [8.84, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -8.84]
                      ],
                      v: [
                        [0, -11],
                        [16, 5],
                        [16, 11],
                        [-16, 11],
                        [-16, 5]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.227144607843, 0.227144607843, 0.227144607843, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [143.693, 38], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [8.84, 0],
                        [0, 8.84],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [-8.84, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 8.84]
                      ],
                      v: [
                        [0, 11],
                        [-16, -5],
                        [-16, -11],
                        [16, -11],
                        [16, -5]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.227144607843, 0.227144607843, 0.227144607843, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [143.693, 16], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 200,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 3,
          nm: "Null 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540, 540, 0], ix: 2 },
            a: { a: 0, k: [50, 50, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 200,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "שעון חול",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 155,
              s: [0]
            },
            { t: 170, s: [180] }
          ],
          ix: 10
        },
        p: { a: 0, k: [500, 499.624, 0], ix: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [627, 627, 100], ix: 6 }
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 0,
      op: 200,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export default lottieAnimationJson;
