import { getFormValues, reset } from "redux-form";
import {
  clearFormProgress,
  restoreFormProgress,
  saveFormProgress
} from "./draft";
import {
  CLEAR_FORM_STATUS,
  CLEAR_STORED_FORM_VALUES,
  GET_STORED_FORM_VALUES,
  SET_STORED_FORM_VALUES,
  STORE_FORM_VALUES,
  STORE_FORM_VALUES_ERROR
} from "./Actions";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { singleUserSteps } from "Fleet/Flows/Manual/ManualContainer";

export const storeFormValues = (userId, step) => async (dispatch, getState) => {
  const formValues = getFormValues("LIST_CAR_FLOW")(getState());
  try {
    await saveFormProgress(userId, formValues, step);
    dispatch({
      type: STORE_FORM_VALUES
    });
  } catch (e) {
    const error = e?.message || e;

    analytics.track(AnalyticsEvents.label.owner.saveListingError, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.errorShown,
      label: AnalyticsEvents.label.owner.saveListingError,
      property: JSON.stringify({
        error: error
      }),
      value: "",
      context: ""
    });
    dispatch({
      type: STORE_FORM_VALUES_ERROR,
      error: "There was an error trying to save your progress"
    });
  }
};

export const clearStoredFormValues = userId => async dispatch => {
  try {
    await clearFormProgress(userId);
    dispatch(reset("LIST_CAR_FLOW"));
    dispatch({
      type: CLEAR_STORED_FORM_VALUES
    });
  } catch (e) {
    dispatch({
      type: STORE_FORM_VALUES_ERROR,
      error: "There was an error trying to clear your progress"
    });
  }
};

export const resetFormState = () => {
  return {
    type: CLEAR_FORM_STATUS
  };
};

export const getStoredFormValues = userId => async dispatch => {
  try {
    const formValues = await restoreFormProgress(userId);
    const payload = {
      formValues,
      restoredFormStep: formValues?.step
    };
    dispatch({
      type: GET_STORED_FORM_VALUES,
      payload
    });
  } catch (e) {
    const error = e?.message || e;

    analytics.track(AnalyticsEvents.label.owner.restoreListingError, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.errorShown,
      label: AnalyticsEvents.label.owner.restoreListingError,
      property: JSON.stringify({
        error: error
      }),
      value: "",
      context: ""
    });

    dispatch({
      type: STORE_FORM_VALUES_ERROR,
      error: "There was an error trying to restore your progress"
    });
  }
};

export const setStoredFormValues = () => (dispatch, getState) => {
  const state = getState();

  const restoredFormStep = state.fleet.listing.restoredFormStep;

  analytics.track(AnalyticsEvents.label.owner.restoreListingSuccess, {
    category: AnalyticsEvents.category.userInteraction,
    action: AnalyticsEvents.action.buttonClicked,
    label: AnalyticsEvents.label.owner.restoreListingSuccess,
    property: JSON.stringify({
      stepRestored: singleUserSteps?.[restoredFormStep]
    }),
    value: "",
    context: ""
  });

  dispatch({
    type: SET_STORED_FORM_VALUES
  });
};
