import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { reduxForm, Field } from "redux-form";
import { useSnackbar } from "notistack";
import get from "lodash/get";
import has from "lodash/has";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";

import { FLEET_UPDATE_CARS } from "Mutations/Owner/OwnerMutations";
import { CAR_PHOTOS } from "Queries/Car/Car";
import { DrawerExpansionPanel } from "Components/Drawers/DrawerExpansionPanel";
import { AsyncImagesOrderer } from "Components/Inputs/AsyncImagesOrderer";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { UnsavedProgressDialogContext } from "Components/Utils/UnsavedProgressDialogProvider";

export const ListingPhotosForm = reduxForm({
  form: "LISTING_PHOTOS",
  destroyOnUnmount: true,
  onChange: (v, d, props) => props.setUnsavedProgressExistsState(props.dirty),
  onSubmitSuccess: (r, d, props) => props.setUnsavedProgressExistsState(false)
})(
  ({
    handleSubmit,
    loading,
    submitting,
    setDisplayUnsavedProgressDialog,
    unsavedProgressExists
  }) => {
    return (
      <DrawerExpansionPanel
        loading={loading}
        onCancel={
          unsavedProgressExists
            ? () => setDisplayUnsavedProgressDialog(true)
            : undefined
        }
        onSubmit={handleSubmit}
        submitting={submitting}
        title="Images"
      >
        <Field
          name="photos"
          component={AsyncImagesOrderer}
          numberOfImages={10}
          dropzoneWhenEmpty
          subtitle="Drag to re-order images"
        />
      </DrawerExpansionPanel>
    );
  }
);

export const ListingPhotos = ({ carId }) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    setDisplayUnsavedProgressDialog,
    setUnsavedProgressExistsState,
    unsavedProgressExists
  } = useContext(UnsavedProgressDialogContext);

  const [updateCar] = useMutation(FLEET_UPDATE_CARS, {
    client: currentRooftopClient
  });
  const { enqueueSnackbar } = useSnackbar();
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;
  const { data, loading } = useQuery(CAR_PHOTOS, {
    variables: {
      id
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });
  const files = get(data, "car.photos")
    ? orderBy(data.car.photos, "order", "asc")
    : [];
  const photos = files
    ? files.reduce((acc, val) => {
        if (has(val, "file")) acc.push(val.file);
        return acc;
      }, [])
    : [];

  const onSubmit = async values => {
    try {
      if (values.photos.length > 0) {
        await updateCar({
          variables: {
            input: [
              {
                id,
                photos: values.photos.map(photo => photo.id)
              }
            ]
          }
        });
        enqueueSnackbar("Successfully Updated Car photos", {
          variant: "success"
        });
      } else {
        enqueueSnackbar(
          "Sorry, you must have at least 1 photo for your vehicle. Please try again.",
          {
            variant: "error"
          }
        );
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <ListingPhotosForm
      onSubmit={onSubmit}
      initialValues={
        loading
          ? null
          : {
              photos
            }
      }
      loading={loading}
      setDisplayUnsavedProgressDialog={setDisplayUnsavedProgressDialog}
      setUnsavedProgressExistsState={setUnsavedProgressExistsState}
      unsavedProgressExists={unsavedProgressExists}
    />
  );
};

ListingPhotos.propTypes = {
  carId: PropTypes.string
};
