import get from "lodash/get";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

export const recursiveObjectFlatten = object => {
  var path = {};
  for (var i in object) {
    if (!Object.prototype.hasOwnProperty.call(object, i)) continue;

    if (typeof object[i] === "object") {
      var flatObject = recursiveObjectFlatten(object[i]);
      for (var x in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue;

        path[i + "." + x] = flatObject[x];
      }
    } else {
      path[i] = object[i];
    }
  }
  return path;
};

export const scrollErrorIntoView = errors => {
  if (!errors) return;

  const flattenedErrors = Object.keys(recursiveObjectFlatten(errors))
    .reduce((acc, key) => {
      const newKey = key.replace(/\.(\d+)\./g, "[$1].");

      const isDisplayNone =
        document.getElementsByName(newKey)[0]?.style.display === "none";

      const element = isDisplayNone
        ? document.getElementsByName(newKey)[0].parentNode
        : document.getElementsByName(newKey)[0];

      if (element && get(errors, newKey)) acc.push(element);

      return acc;
    }, [])
    .sort((first, second) => {
      const firstY = first.getBoundingClientRect().y;
      const secondY = second.getBoundingClientRect().y;

      if (firstY < secondY) {
        return -1;
      } else if (firstY > second) {
        return 1;
      } else {
        return 0;
      }
    });

  if (flattenedErrors[0])
    flattenedErrors[0].scrollIntoView({ behavior: "smooth", block: "center" });
};

const trackFormErrors = (errors, values) => {
  if (errors.vin) {
    analytics.track(AnalyticsEvents.label.owner.vinInputError, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.errorShown,
      label: AnalyticsEvents.label.owner.vinInputError,
      property: JSON.stringify({
        vin: values.vin,
        year: values.year?.value,
        make: values.make?.value,
        model: values.model?.value
      })
    });
  }
};

export const handleSubmitFails = (errors, formValues) => {
  scrollErrorIntoView(errors);
  trackFormErrors(errors, formValues);
};
