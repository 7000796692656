import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import pick from "lodash/pick";
import { destroy } from "redux-form";
import { connect } from "react-redux";
import get from "lodash/get";

import { RouteEnum } from "Enums/RouteEnum";
import { CONFIRM_PICKUP } from "Mutations/Rental/RentalMutations";
import { FlowStepper } from "Components/Flows/FlowStepper";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { BasicHeader } from "Components/Header/BasicHeader";
import { ConfirmDriverIdentity } from "Fleet/Flows/CheckInFlow/ConfirmDriverIdentity";
import { CurrentCarCondition } from "Fleet/Flows/CheckInFlow/CurrentCarCondition";
import { MileageAndGas } from "Fleet/Flows/CheckInFlow/MileageAndGas";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";

const steps = ["Identity", "Car Condition", "Mileage"];

const getCurrentComponent = step => {
  switch (step) {
    case 0:
      return <ConfirmDriverIdentity />;
    case 1:
      return <CurrentCarCondition />;
    case 2:
      return <MileageAndGas />;
    default:
      return <ConfirmDriverIdentity />;
  }
};

export const StepperContext = React.createContext();

export const CheckInFlowContainer = connect(null, { destroy })(
  ({ destroy }) => {
    const { id } = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [step, setStep] = useState(0);
    const { currentRooftopClient } = useContext(ClientFactoryContext);
    const [checkIn] = useMutation(CONFIRM_PICKUP, {
      client: currentRooftopClient
    });
    const { data } = useQuery(CURRENT_USER_QUERY, {
      client: currentRooftopClient
    });
    const viewer = data?.viewer;
    const currentRental = useQuery(RENTAL_REQUEST_QUERY, {
      variables: { id },
      client: currentRooftopClient
    });
    const nextStep = () => {
      if (step < steps.length) {
        setStep(prevStep => prevStep + 1);
      }
    };

    const previousStep = () =>
      step === 0 ? history.goBack() : setStep(step => step - 1);

    const handleOnSubmit = async values => {
      try {
        analytics.track(AnalyticsEvents.label.owner.pickupConfirmed, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.buttonClicked,
          label: AnalyticsEvents.label.owner.pickupConfirmed,
          property: JSON.stringify({
            ownerId: get(viewer, "me.owner.id"),
            rentalId: id
          }),
          value: "",
          context: {
            rental: get(currentRental, "data.rental", {})
          }
        });
        await checkIn({
          variables: {
            rentalId: id,
            isDriverIdentified: true,
            ...pick(values, [
              "carFrontImage",
              "carBackImage",
              "carDriverSideImage",
              "carPassengerSideImage",
              "carMileageImage",
              "carGasLevelImage"
            ])
          }
        });
        analytics.track(AnalyticsEvents.label.owner.pickupConfirmedSuccess, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.webConversion,
          label: AnalyticsEvents.label.owner.pickupConfirmedSuccess,
          property: JSON.stringify({
            ownerId: get(viewer, "me.owner.id"),
            rentalId: id
          }),
          value: "",
          context: ""
        });
        destroy("CHECK_IN_FLOW");
        history.replace(`${RouteEnum.rentalsActive}`);
      } catch (e) {
        console.error(e);
        enqueueSnackbar("Sorry, something went wrong. Please try again.", {
          variant: "error"
        });
      }
    };

    return (
      <>
        <BasicHeader />
        <StepperContext.Provider
          value={{ nextStep, previousStep, handleOnSubmit }}
        >
          <DashboardLayout fixed hasAppBar>
            <FlowStepper steps={steps} header="Check In" step={step} />
            {getCurrentComponent(step)}
          </DashboardLayout>
        </StepperContext.Provider>
      </>
    );
  }
);
