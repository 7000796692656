import React, { useState, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AvatarEditor from "react-avatar-editor";
import PropTypes from "prop-types";

import { DocumentDropzone } from "Components/Forms/Dropzone/DocumentDropzone";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    "& canvas": {
      width: "100% !important",
      height: "100% !important"
    }
  },
  editor: {
    position: "relative"
  },
  controls: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    background: theme.palette.background.paper,
    justifyContent: "flex-end",
    boxShadow: theme.shadows[1]
  }
}));

export const ImageEditor = ({ image, onDelete, onDrop, onSave }) => {
  const avatarRef = useRef();
  const [rotate, setRotate] = useState(0);
  const classes = useStyles();

  const handleSetRotate = () => setRotate(prev => prev + 90);

  const handleSave = () => {
    if (avatarRef.current) {
      const canvas = avatarRef.current.getImage().toDataURL();
      fetch(canvas)
        .then(res => res.blob())
        .then(blob => {
          const f = new File([blob], image.name, {
            type: image.type
          });
          f["path"] = `modified/${image.path}`;
          onSave(f);
        });
    }
  };

  useEffect(() => {
    setRotate(0);
  }, [image]);

  return (
    <div className={classes.root}>
      {image ? (
        <div className={classes.editor}>
          <AvatarEditor
            image={URL.createObjectURL(image)}
            rotate={rotate}
            ref={avatarRef}
          />
          <div className={classes.controls}>
            <IconButton onClick={handleSetRotate} size="large">
              <Rotate90DegreesCcwIcon />
            </IconButton>
            <IconButton onClick={onDelete} size="large">
              <DeleteForeverIcon />
            </IconButton>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </div>
        </div>
      ) : (
        <DocumentDropzone
          accept="image/jpeg, image/png"
          input={{
            onChange: onDrop
          }}
          meta={{}}
        />
      )}
    </div>
  );
};

ImageEditor.propTypes = {
  image: PropTypes.object,
  onDelete: PropTypes.func,
  onDrop: PropTypes.func,
  onSave: PropTypes.func,
  header: PropTypes.node
};
