import { Flex } from "grid-styled";
import React, { useContext } from "react";
import { withState, compose } from "recompose";
import styled, { withTheme } from "styled-components";

import { analytics } from "Analytics/index";
import LogoImg from "Assets/images/logo-alternative.png";

import {
  Divider,
  Navbar,
  Nav,
  NavDropdown,
  NavItem,
  MenuItem,
  Button,
  Modal
} from "hyrecar-web-components";

import Sidebar from "./Sidebar";

import UserAvatar from "Driver/Components/General/UserAvatar";

import withCurrentUser from "Driver/Containers/Profile/hocs/withCurrentUser";
import withMetricsProp from "Analytics/hocs/withMetricsProp";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { UserTypeEnum } from "Enums/StateEnums";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { GigConnectContext } from "Components/Utils/GigConnectProvider";

const DesktopNavContainer = styled.div`
  display: block;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.screenMd}) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
`;

const MobileNavHeader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0px 10px;
  border-bottom: ${({ theme, noBorder }) =>
    noBorder ? "none" : `1px solid ${theme.gray}`};
`;

const PhoneNavContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.screenMd}) {
    display: block;
  }
`;

const StyledNavbar = styled(Navbar)`
  &.navbar {
    border-left: none;
    border-right: none;
    border-top: none;
  }
  .container {
    width: 100% !important;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const getVisibleLinks = ({ routes, handleLink }) => {
  const links = [];
  links.push(
    <NavItem
      key={"howItWorks"}
      eventKey={"howItWorks"}
      onClick={() => handleLink(routes["howItWorks"])}
    >
      How It Works
    </NavItem>,
    <NavItem
      key={"reservations"}
      eventKey={"reservations"}
      onClick={() => handleLink(routes["reservations"])}
    >
      My Reservations
    </NavItem>
  );
  links.push(
    routes.renderMessagesLink({
      onClick: () => handleLink(routes["messages"])
    })
  );
  return links;
};

const getCollapsedLinksForUserType = (
  {
    routes,
    handleLink,
    universalAppStoreLink,
    openLink,
    setSidebar,
    setComponentOpening,
    currentUser
  },
  WrapperComponent,
  desktopView = false
) => {
  if (desktopView) {
    return [
      <WrapperComponent
        key={"profile"}
        eventKey={"profile"}
        onClick={() => handleLink(routes["profile"])}
      >
        My Profile
      </WrapperComponent>,
      <WrapperComponent
        key={"contactUs"}
        eventKey={"contactUs"}
        onClick={() => handleLink(routes["contactUs"])}
      >
        Help Center
      </WrapperComponent>,
      <WrapperComponent
        key={"contactUs"}
        eventKey={"contactUs"}
        onClick={() => handleLink(routes["supportTickets"])}
        data-testid="driversupport-ticket"
      >
        Support Tickets
      </WrapperComponent>,
      <WrapperComponent
        key={"logout"}
        eventKey={"logout"}
        onClick={() => handleLink(routes["logout"])}
      >
        Log Out
      </WrapperComponent>
    ];
  }

  const gigConnectComponent =
    currentUser.driver.backgroundCheck.verificationStatus === "VERIFIED" ? (
      <WrapperComponent
        key={"gigConnect"}
        eventKey={"gigConnect"}
        onClick={async () => {
          setComponentOpening("navbar");
          setSidebar(false);
          await openLink();
          analytics.track(AnalyticsEvents.label.driver.argyle.open, {
            action: AnalyticsEvents.action.buttonClicked,
            category: AnalyticsEvents.category.userInteraction,
            context: "",
            label: AnalyticsEvents.label.argyle,
            property: JSON.stringify({}),
            value: ""
          });
        }}
      >
        Gig Connect
      </WrapperComponent>
    ) : null;

  return [
    <WrapperComponent
      key={"profile"}
      eventKey={"profile"}
      onClick={() => handleLink(routes["profile"])}
    >
      My Profile
    </WrapperComponent>,
    gigConnectComponent,
    <WrapperComponent
      key={"contactUs"}
      eventKey={"contactUs"}
      onClick={() => handleLink(routes["contactUs"])}
    >
      Help Center
    </WrapperComponent>,
    <WrapperComponent
      key={"contactUs"}
      eventKey={"contactUs"}
      onClick={() => handleLink(routes["supportTickets"])}
      data-testid="driversupport-ticket"
    >
      Support Tickets
    </WrapperComponent>,
    <WrapperComponent
      key={"downloadApp"}
      eventKey={"downloadApp"}
      onClick={() => window.open(universalAppStoreLink)}
    >
      Download App
    </WrapperComponent>,
    <WrapperComponent
      key={"logout"}
      eventKey={"logout"}
      onClick={() => handleLink(routes["logout"])}
    >
      Log Out
    </WrapperComponent>
  ];
};

const getActionButton = ({ routes, handleLink }, block) => (
  <NavItem
    key={"bookACar"}
    eventKey={"bookACar"}
    onClick={() => handleLink(routes["bookACar"])}
  >
    <Button
      onClick={() => handleLink(routes["bookACar"])}
      bsStyle="primary"
      block={block}
    >
      Book a Car
    </Button>
  </NavItem>
);

const UserName = withTheme(({ style, theme, fullName, onClick }) => (
  <div style={style} onClick={onClick}>
    {fullName}
    <div style={{ color: theme.brandPrimary }}>{UserTypeEnum.driver}</div>
  </div>
));

const Avatar = ({ style, picture, onClick }) => (
  <UserAvatar
    style={style}
    src={picture}
    imageProps={{
      style: {
        width: 60,
        height: 60,
        objectFit: "cover"
      }
    }}
    onClick={onClick}
  />
);

const DesktopNavItems = ({
  user: { type, picture, fullName },
  routes,
  handleLink,
  metrics,
  metricsEvents,
  metricsCategories,
  universalAppStoreLink
}) => (
  <DesktopNavContainer>
    <Nav pullRight>
      {getVisibleLinks({
        routes,
        type,
        handleLink,
        metrics,
        metricsEvents,
        metricsCategories
      })}
      {getActionButton({ routes, handleLink })}
      {
        <NavDropdown
          key={1}
          eventKey={1}
          title={<Avatar picture={picture} />}
          id="basic-nav-dropdown"
          rootCloseEvent="null"
        >
          <UserName
            style={{
              color: "white",
              paddingTop: 10,
              paddingBottom: 10,
              textAlign: "center",
              margin: 5,
              borderBottom: ".5px solid gray"
            }}
            type={type}
            fullName={fullName}
          />
          {getCollapsedLinksForUserType(
            {
              routes,
              type,
              handleLink,
              universalAppStoreLink
            },
            MenuItem,
            true
          )}
        </NavDropdown>
      }
    </Nav>
  </DesktopNavContainer>
);

const MobileSidebarNavItems = ({
  user: { type, picture, fullName },
  sidebarOpen,
  setSidebar,
  routes,
  handleLink,
  metrics,
  metricsEvents,
  metricsCategories,
  universalAppStoreLink,
  openLink,
  setComponentOpening,
  currentUser
}) => (
  <Sidebar bsSize="small" show={sidebarOpen} onStateChange={setSidebar}>
    <Modal.Header
      style={{
        padding: "0px",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
      }}
    >
      <MobileNavHeader style={{ flex: 2 }}>
        {
          <Flex alignItems="center">
            <Avatar
              style={{ margin: "5px", display: "inline-block" }}
              type={type}
              picture={picture}
              onClick={() => handleLink(routes["profile"])}
            />
            <UserName
              style={{ display: "inline-block" }}
              type={type}
              fullName={fullName}
              onClick={() => handleLink(routes["profile"])}
            />
          </Flex>
        }
      </MobileNavHeader>
    </Modal.Header>
    <PhoneNavContainer>
      <Nav>
        {getVisibleLinks({
          routes,
          type,
          handleLink,
          metrics,
          metricsEvents,
          metricsCategories
        })}
        {getCollapsedLinksForUserType(
          {
            routes,
            type,
            handleLink,
            universalAppStoreLink,
            openLink,
            setSidebar,
            setComponentOpening,
            currentUser
          },
          NavItem
        )}
        <div
          style={{
            position: "absolute",
            padding: "20px",
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <Divider marginVertical="20px" />
          {getActionButton({ routes, handleLink }, true)}
        </div>
      </Nav>
    </PhoneNavContainer>
  </Sidebar>
);

const WrappedNavbar = compose(
  withState("sidebarOpen", "setSidebar", false),
  withCurrentUser,
  withMetricsProp
)(props => {
  const { setSidebar, routes, handleLink: handleParentLink } = props;
  const handleLink = (...args) => {
    handleParentLink(...args);
    setSidebar(false);
  };
  const { getDeeplink } = useContext(FirebaseContext);
  const universalAppStoreLink = getDeeplink("universalAppStore");

  const { openLink, setComponentOpening } = useContext(GigConnectContext);

  return (
    <StyledNavbar inverse collapseOnSelect id="outer-container">
      <Navbar.Header>
        <MobileSidebarNavItems
          {...{
            ...props,
            handleLink,
            universalAppStoreLink,
            openLink,
            setComponentOpening
          }}
        />
        <LogoContainer onClick={() => handleLink(routes["home"])}>
          <Navbar.Brand>
            <img alt="logo" style={{ height: "75px" }} src={LogoImg} />
          </Navbar.Brand>
        </LogoContainer>
      </Navbar.Header>
      <DesktopNavItems {...{ ...props, handleLink, universalAppStoreLink }} />
    </StyledNavbar>
  );
});

WrappedNavbar.Main = Sidebar.Main;
export default WrappedNavbar;
