import React from "react";
import { AsyncButton } from "hyrecar-web-components";
import { compose } from "recompose";

import withCurrentUser from "Driver/utils/hocs/withCurrentUser";

import withMetricsProp from "Analytics/hocs/withMetricsProp";
import { useContext } from "react";
import { AuthContext } from "Login/Auth";

const SwitchFromDriverToOwnerButton = ({
  children,
  metricsEvents,
  metricsCategories,
  ...restProps
}) => {
  const { switchAccount, switchAccountLoading } = useContext(AuthContext);

  return (
    <AsyncButton
      onClick={async () => {
        await switchAccount();
      }}
      {...restProps}
      data-metrics-event-name={metricsEvents.switchedAccount}
      data-metrics-category={metricsCategories.driver}
    >
      {() =>
        switchAccountLoading
          ? "Switching Account..."
          : `${children || ""} Switch to Owner Account`
      }
    </AsyncButton>
  );
};

export default compose(
  withCurrentUser,
  withMetricsProp
)(SwitchFromDriverToOwnerButton);
