const variables = require("!!sass-variables-loader!./bootstrap/_variables.scss");

const baseVariables = {
  ...Object.keys(variables).reduce(function(result, key) {
    const newResult = { ...result };
    newResult[key] = variables[key].replace("!default", "").trim();
    return newResult;
  }, {})
};
export default {
  ...baseVariables
};
