import gql from "graphql-tag";

export const InboxTemplateFragments = {
  inboxTemplate: gql`
    fragment InboxTemplateInfo on InboxTemplate {
      id
      title
      message
    }
  `
};
