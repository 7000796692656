import { useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";

import { UserTypeEnum } from "Enums/StateEnums";
import { CURRENT_USER_QUERY } from "../Queries/User/UserQueries";
import {
  asyncConnectToSendBird,
  asyncUpdateUserInfo
} from "Redux/SendBird/ActionCreators";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const mapStateToProps = (state, props) => {
  return {
    ...props,
    sendbird: state.fleet.sendbird
  };
};

const mapDispatchToProps = { asyncConnectToSendBird, asyncUpdateUserInfo };

export const SendBirdController = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ sendbird, children, asyncConnectToSendBird, asyncUpdateUserInfo }) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "network-only",
    client: currentRooftopClient
  });

  useEffect(() => {
    if (!loading) {
      const currentUser = get(data, "viewer.me");
      const userType = get(currentUser, "type");
      const userId =
        userType === UserTypeEnum.owner || userType === UserTypeEnum.dealer
          ? get(currentUser, "owner.id")
          : get(currentUser, "driver.id");
      if (get(sendbird, "user.userId") !== userId) {
        if (userId) {
          asyncConnectToSendBird(userId).then(() => {
            return asyncUpdateUserInfo(
              `${upperFirst(get(currentUser, "firstName"))} ${upperFirst(
                get(currentUser, "lastName")
              ).charAt(0)}.`,
              get(currentUser, "profilePhoto.url")
            );
          });
        }
      }
    }
  }, [data, loading, sendbird, asyncConnectToSendBird, asyncUpdateUserInfo]);

  return children;
});
