import { getAlgoliaResults } from "@algolia/autocomplete-js";

const getQuery = (query, index) => ({
  indexName: index,
  query,
  params: { hitsPerPage: 100 }
});

export const getItems = (query, searchClient) =>
  getAlgoliaResults({
    searchClient,
    queries: [
      getQuery(query, "owner-rentals"),
      getQuery(query, "owner-listings")
    ]
  });
