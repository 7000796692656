import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { ImageEditor } from "Components/Inputs/ImageEditor";
import { ImagesOrderer } from "Components/Inputs/ImagesOrderer";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap"
    }
  },
  imageEditorWrapper: {
    width: "400px",
    height: "400px",
    minWidth: "400px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      position: "relative",
      margin: theme.spacing(0, 0, 4, 0),
      paddingTop: "100%",
      minWidth: "100%",
      width: "100%",
      height: "100%"
    }
  },
  imageEditorInner: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  imageOrdererWrapper: {
    flexGrow: 1
  },
  error: {
    marginTop: theme.spacing(2)
  }
}));

export const ImagesEditor = ({
  input: { value, onChange },
  meta: { touched, invalid, error },
  id
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(null);

  const handleSetSelected = index => setSelected(index);

  const handleImageEditorDrop = file => {
    onChange([file]);
  };

  const handleImageEditorDelete = () => {
    if (selected && Array.isArray(value)) {
      onChange([
        ...value.slice(0, selected),
        ...value.slice(selected + 1, value.length)
      ]);
      setSelected(null);
    } else {
      onChange([...value.slice(1, value.length)]);
    }
  };

  const handleImageEditorOnSave = image => {
    if (selected) {
      onChange([
        ...value.slice(0, selected),
        image,
        ...value.slice(selected + 1, value.length)
      ]);
    } else {
      onChange([image, ...value.slice(1, value.length)]);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.imageEditorWrapper}>
          <div id={id && id} className={classes.imageEditorInner}>
            <ImageEditor
              image={selected ? value[selected] : value[0]}
              onDrop={handleImageEditorDrop}
              onSave={handleImageEditorOnSave}
              onDelete={handleImageEditorDelete}
              header="Testing"
              width={"100%"}
              className="Testing"
            />
          </div>
        </div>
        <div className={classes.imageOrdererWrapper}>
          <ImagesOrderer
            input={{
              value,
              onChange
            }}
            numberOfImages={10}
            onSelect={handleSetSelected}
            selected={selected}
          />
        </div>
      </div>
      {touched && invalid && (
        <Typography
          component="span"
          variant="body2"
          color="error"
          display="block"
          className={classes.error}
        >
          {error}
        </Typography>
      )}
    </>
  );
};

ImagesEditor.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    onChange: PropTypes.func.isRequired
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  })
};
