import React from "react";

export const useDrawer = ({ initialState }) => {
  const [drawerOpen, setDrawerOpened] = React.useState(initialState);
  const handleDrawerOpen = () => setDrawerOpened(true);
  const handleDrawerClose = () => setDrawerOpened(false);

  return {
    drawerOpen,
    handleDrawerOpen,
    handleDrawerClose
  };
};
