import React from "react";
import { compose, withProps } from "recompose";
import { Route } from "react-router-dom";

import { SwitchFromDriverToOwner } from "Driver/Containers/Auth/SwitchFromDriverToOwner";

class PrivateRoute extends React.Component {
  render() {
    const {
      currentUser,
      component,
      userIsAuthenticated,
      userRoleVerified,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          if (!userIsAuthenticated) return null;

          if (!userRoleVerified)
            return React.createElement(SwitchFromDriverToOwner, {
              ...props
            });

          return React.createElement(component, {
            ...props,
            currentUser
          });
        }}
      />
    );
  }
}

export default compose(
  withProps(({ userType, userRole = undefined }) => {
    let userRoleVerified = false;

    if (userRole === undefined) {
      userRoleVerified = true;
    } else {
      userRoleVerified = userType === userRole;
    }

    return {
      userRoleVerified
    };
  })
)(PrivateRoute);
