import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import StepAppBar from "../StepAppBar";
import InfoCard from "../InfoCard";
import SwitchAvatars from "../SwitchAvatars";
import { RentalStatusEnum } from "Enums/StateEnums";
import { useHistory } from "react-router";
import { RouteEnum } from "Enums/RouteEnum";
import { getPhotoUrl } from "./stepUtils";

const Success = ({ onClose, oldRental, newRental }) => {
  const history = useHistory();

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  return (
    <>
      <StepAppBar title="" onClose={onClose} />

      <DrawerPaddingContainer>
        <SwitchAvatars
          oldCarPhoto={getPhotoUrl(oldRental.car)}
          newCarPhoto={getPhotoUrl(newRental.car)}
        />

        <InfoCard
          cardType="success"
          title="Successfully switched!"
          subtitle="What's next?"
        >
          <ol>
            <li>The original rental has ended.</li>
            <li>
              When you&apos;re ready to hand off the keys, Check-in the driver
              to start the new rental.
            </li>
            <li>
              If you need inmediate support, contact HyreCar Support at
              {customerServicePhoneNumber}
            </li>
          </ol>
        </InfoCard>
      </DrawerPaddingContainer>
      {newRental.status === RentalStatusEnum.pendingInsurance ? (
        <DrawerBottomBar
          onSubmit={() => {
            onClose();
            history.replace(`${RouteEnum.rentalsActive}/${newRental.id}`);
          }}
          submitText={"View Rental Details"}
          disabled={false}
        />
      ) : (
        <DrawerBottomBar
          onSubmit={() => {
            history.replace(`${RouteEnum.checkIn}/${newRental.id}`);
          }}
          submitText={"Check-In Now"}
          disabled={false}
        />
      )}
    </>
  );
};

Success.propTypes = {
  onClose: PropTypes.func,
  oldRental: PropTypes.object,
  newRental: PropTypes.object
};

export default Success;
