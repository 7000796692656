/* eslint-disable react/display-name */
import React, { useContext } from "react";
import { reduxForm, Field } from "redux-form";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { StepperFunctions } from "../ManualContainer";
import { FlowContentContainer } from "../../../../Components/Flows/FlowContentContainer";
import { FormTextField } from "Components/Inputs/FormTextField";
import { validate } from "./validate";

const Content = reduxForm({
  form: "LIST_CAR_FLOW",
  destroyOnUnmount: false,
  validate
})(({ handleSubmit, previousStep }) => (
  <FlowContentContainer
    header="Describe your car"
    stepId="continue-car-description"
    nextStep={handleSubmit}
    nextStepLabel="Continue"
    previousStep={previousStep}
    previousStepLabel="Back"
  >
    <Typography>
      Add details to make your car stand out such as highlighting unique
      features, emphasizing fuel efficiency or mentioning any extras or
      accessories that come with the car. Add other information such as your
      business hours, availability or even your preference for gig drivers.
    </Typography>
    <Box sx={{ mt: 2 }}>
      <Field
        id="car-description-field"
        name="description"
        label="Car description"
        component={FormTextField}
        multiline
        rows={8}
        fullWidth
        variant="filled"
      />
    </Box>
  </FlowContentContainer>
));

export const Description = () => {
  const { nextStep, previousStep } = useContext(StepperFunctions);

  return (
    <ColumnFlowLayout
      leftComponent={
        <Content onSubmit={nextStep} previousStep={previousStep} />
      }
      useBottomDrawer
    />
  );
};
