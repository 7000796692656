import React from "react";
import styled from "styled-components";
import ReactImageFallback from "react-image-fallback";
import { Box } from "grid-styled";
import assets from "hyrecar-web-components/src/assets/index";

export const CarUserAvatarContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 60px;
`;

const UserAvatar = ({ src, imageProps, ...restProps }) => {
  return (
    <div>
      <CarUserAvatarContainer {...restProps}>
        <ReactImageFallback
          src={src}
          fallbackImage={assets.defaultAvatar}
          initialImage={assets.defaultAvatar}
          style={{
            width: 50,
            height: 50,
            objectFit: "cover"
          }}
          className="img-circle img-thumbnail"
          {...imageProps}
        />
      </CarUserAvatarContainer>
    </div>
  );
};

export default UserAvatar;
