import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { ButtonRouter } from "Components/Buttons/ButtonRouter";

const useStyles = makeStyles(theme => ({
  root: ({ noMarginBottom }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    ...(!noMarginBottom && { marginBottom: theme.spacing(3) })
  }),
  centerAligned: {
    display: "flex",
    alignItems: "center"
  }
}));

export const CardHeader = ({
  label,
  to,
  link,
  noMarginBottom,
  actionComponent
}) => {
  const classes = useStyles({ noMarginBottom });

  return (
    <Grid container justifyContent="space-between" className={classes.root}>
      <Grid item>
        <Typography component="span" variant="h6" color="textSecondary">
          {label}
        </Typography>
      </Grid>
      {to && (
        <Grid item>
          <ButtonRouter color="primary" to={to}>
            {link}
          </ButtonRouter>
        </Grid>
      )}
      {actionComponent && (
        <Grid className={classes.centerAligned} item>
          {actionComponent}
        </Grid>
      )}
    </Grid>
  );
};

CardHeader.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  link: PropTypes.string,
  noMarginBottom: PropTypes.bool,
  actionComponent: PropTypes.node
};
