import React, { useContext } from "react";

import withMetricsProp from "Analytics/hocs/withMetricsProp";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { StepperFunctions } from "../ManualContainer";
import { TermsForm } from "./TermsForm";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { List, ListItem, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(theme => ({
  list: {
    marginLeft: theme.spacing(3)
  },
  listItem: {
    paddingTop: 0
  },
  listItemText: {
    margin: 0
  }
}));

export const Price = withMetricsProp(() => {
  const classes = useStyles();
  const { nextStep, previousStep } = useContext(StepperFunctions);
  const { getJSONValue } = useContext(FirebaseContext);
  const tooltips = getJSONValue("web_manualFlowTermsTooltips");

  const left = (
    <TermsForm
      onSubmit={() => {
        analytics.track(AnalyticsEvents.label.owner.ownerICCTermsComplete, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.webConversion,
          label: AnalyticsEvents.label.owner.ownerICCTermsComplete,
          property: JSON.stringify({
            category: "OWNER"
          }),
          value: "",
          context: ""
        });
        nextStep();
      }}
      previousStep={previousStep}
    />
  );

  const right = (
    <TooltipContainer header="Tips" useIcon>
      {tooltips?.map((tooltip, i) => (
        <Tooltip
          key={i}
          paragraphs={[
            <List
              key="tooltip"
              sx={{ listStyleType: "disc" }}
              className={classes.list}
            >
              {tooltip?.content.map((paragraph, i) => (
                <ListItem key={i} sx={{ display: "list-item" }}>
                  <ListItemText primary={paragraph.text} />
                </ListItem>
              ))}
            </List>
          ]}
          useSpan
        />
      ))}
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={left}
      rightComponent={right}
      useBottomDrawer
    />
  );
});
