import makeStyles from "@mui/styles/makeStyles";
import { reduxForm } from "redux-form";
import AppleIcon from "@mui/icons-material/Apple";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import { ReactComponent as GoogleIcon } from "Assets/images/google.svg";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";

import { AuthContext } from "Login/Auth";
import { LoginFormFields } from "Components/Forms/FormFields/LoginFormFields";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { analytics } from "Analytics/index";

const useStyles = makeStyles(theme => ({
  appleButton: {
    backgroundColor: "black",
    color: "white",
    fontHeight: "600",
    fontSize: "120%",
    fontStyle: "normal",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 56,
    position: "relative",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "#FC6347"
    },
    "& .MuiButton-sizeSmall": {
      left: 16,
      paddingLeft: 48,
      paddingRight: 32,
      position: "absolute"
    },
    "& .MuiButton-startIcon": {
      left: 16,
      position: "absolute"
    }
  },

  appleIcon: {
    color: "#E5E5E5",
    fontSize: "medium"
  },

  containerText: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.fontSize,
    letterSpacing: theme.spacing(0.16),
    marginTop: theme.spacing(0)
  },

  divider: {
    margin: theme.spacing(4, 0)
  },

  error: {
    marginBottom: theme.spacing(4)
  },

  facebookButton: {
    backgroundColor: "#3A559F",
    color: "white",
    fontSize: "120%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 56,
    position: "relative",
    "&:hover": {
      backgroundColor: "#324886"
    },
    "& .MuiButton-sizeSmall": {
      paddingLeft: 48,
      paddingRight: 32,
      position: "absolute",
      left: 16
    },
    "& .MuiButton-startIcon": {
      position: "absolute",
      left: 16
    },
    textTransform: "none"
  },

  forgotPasswordText: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.fontSize,
    letterSpacing: theme.spacing(0.16),
    marginTop: theme.spacing(0),
    "&:hover": {
      backgroundColor: "#fff",
      color: "#FC6347"
    }
  },

  facebookIcon: {
    color: "white",
    fontSize: "medium"
  },

  googleButton: {
    backgroundColor: "#FFFFFF",
    color: "black",
    fontSize: "120%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 56,
    position: "relative",
    "&:hover": {
      backgroundColor: "#D5D5D5"
    },
    "& .MuiButton-sizeSmall": {
      paddingLeft: 48,
      paddingRight: 32,
      position: "absolute",
      left: 16
    },
    "& .MuiButton-startIcon": {
      position: "absolute",
      left: 16
    },
    textTransform: "none"
  },

  loginButton: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(6)
  },

  socialLoginContainer: {
    alignItems: "center",
    justify: "center",
    margin: theme.spacing(1),
    xs: "12"
  },

  signupRedirectContainer: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.fontSize,
    letterSpacing: theme.spacing(0.16),
    marginTop: theme.spacing(4)
  },

  signupRedirectLink: {
    cursor: "pointer"
  },

  title: {
    color: "black",
    marginBottom: theme.spacing(3)
  }
}));

export const LoginForm = reduxForm({
  form: "FLEET_LOGIN_FORM"
})(
  ({
    handleSubmit,
    submitting,
    sendToForgotPassword,
    sendToSignUp,
    error,
    userType
  }) => {
    const classes = useStyles();
    const {
      loginWithFacebook,
      loginWithApple,
      loginWithGoogle,
      socialLoginSubmitting
    } = useContext(AuthContext);

    const handleLogIn = (event, loginCallback) => {
      try {
        analytics.track(
          event.legacyEventName,
          {
            category: AnalyticsEvents.category.userInteraction,
            action: AnalyticsEvents.action.buttonClicked,
            label: event.label,
            property: JSON.stringify({
              flow: AnalyticsEvents.flow.login
            }),
            value: "",
            context: ""
          },
          () => {
            loginCallback(userType);
          }
        );
      } catch (error) {
        loginCallback(userType);
      }
    };

    const continueWithGoogle = () => {
      handleLogIn(
        AnalyticsEvents.label.auth.logInWithGoogleSelected,
        loginWithGoogle
      );
    };

    const continueWithApple = () => {
      handleLogIn(
        AnalyticsEvents.label.auth.logInWithAppleSelected,
        loginWithApple
      );
    };

    const continueWithFacebook = () => {
      handleLogIn(
        AnalyticsEvents.label.auth.logInWithFacebookSelected,
        loginWithFacebook
      );
    };

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Typography
            component="h6"
            variant="h6"
            align="center"
            className={classes.title}
          >
            Welcome back
          </Typography>
          {error && (
            <Typography color="error" align="center" className={classes.error}>
              {error}
            </Typography>
          )}
          <LoginFormFields />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitting || socialLoginSubmitting}
            className={classes.loginButton}
          >
            {submitting ? "Logging In" : "LogIn"}
          </Button>
        </form>

        <Button
          className={classes.forgotPasswordText}
          onClick={sendToForgotPassword}
          fullWidth
          disabled={socialLoginSubmitting || submitting}
        >
          Forgot Password?
        </Button>

        <Typography align="center" className={classes.containerText}>
          DON&apos;T HAVE AN ACCOUNT?{" "}
          <Link
            onClick={sendToSignUp}
            className={classes.signupRedirectLink}
            underline="hover"
          >
            SIGN UP
          </Link>
        </Typography>

        <Grid container alignItems="center" className={classes.divider}>
          <Grid item xs={5}>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.containerText} align="center">
              OR
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Divider />
          </Grid>
        </Grid>

        {/* Single Sign In */}
        <Grid container className={classes.socialLoginContainer}>
          {/** Apple */}
          <Grid container item xs={12}>
            <Button
              className={classes.appleButton}
              disabled={socialLoginSubmitting || submitting}
              disableRipple={true}
              fullWidth
              onClick={continueWithApple}
              startIcon={<AppleIcon className={classes.appleIcon} />}
              variant="contained"
            >
              Continue with Apple
            </Button>
          </Grid>

          {/** Facebook */}
          <Grid container item xs={12}>
            <Button
              className={classes.facebookButton}
              disabled={socialLoginSubmitting || submitting}
              fullWidth
              onClick={continueWithFacebook}
              startIcon={<FacebookIcon className={classes.facebookIcon} />}
              variant="contained"
            >
              Continue with Facebook
            </Button>
          </Grid>

          {/** Google */}
          <Grid container item xs={12}>
            <Button
              className={classes.googleButton}
              disabled={socialLoginSubmitting || submitting}
              fullWidth
              onClick={continueWithGoogle}
              startIcon={
                <SvgIcon>
                  <GoogleIcon />
                </SvgIcon>
              }
              variant="contained"
            >
              Continue with Google
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
);
