import React from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  menuItem: {
    padding: theme.spacing(0)
  },
  listItem: {
    padding: ({ matches }) => (matches ? theme.spacing(2) : theme.spacing(3))
  },
  cardLayoutContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    overflow: "hidden"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
    overflow: "hidden",
    width: "100%"
  },
  rooftopCardTitle: {
    color: theme.palette.common.black
  },
  skeleton: {
    width: "100%"
  }
}));

export const SimpleMenuItemLayout = ({
  showAvatar = true,
  avatarAlt,
  avatarSrc,
  avatarIcon,
  title,
  subTitle,
  onClick,
  loading
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles({ matches });

  return (
    <MenuItem
      onClick={onClick}
      classes={{ root: classes.menuItem }}
      className={classes.listItem}
    >
      <div className={classes.cardLayoutContainer}>
        {showAvatar && (
          <div>
            {loading ? (
              <Skeleton variant="circular" height={50} width={50} />
            ) : (
              <Avatar alt={avatarAlt} src={avatarSrc}>
                {avatarIcon && avatarIcon}
              </Avatar>
            )}
          </div>
        )}
        <div className={classes.textContainer}>
          {loading ? (
            <>
              <Skeleton variant="text" classes={{ text: classes.skeleton }} />
              <Skeleton variant="text" classes={{ text: classes.skeleton }} />
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                noWrap={true}
                className={classes.rooftopCardTitle}
              >
                {title}
              </Typography>
              {subTitle && (
                <Typography variant="body2" noWrap={true}>
                  {subTitle}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </MenuItem>
  );
};

SimpleMenuItemLayout.propTypes = {
  avatarAlt: PropTypes.string,
  avatarSrc: PropTypes.string,
  avatarIcon: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onClick: PropTypes.func
};
