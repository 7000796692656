import React from "react";
import { PropTypes } from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import LoadingLottieAnimation from "Fleet/Dashboard/DashboardViews/Financials/Tables/Animations/LoadingAnimation";
import { Player } from "@lottiefiles/react-lottie-player";

const DEFAULT_HEADER = "Loading...";

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: 16
  },
  header: {
    color: theme.palette.grey[800],
    fontSize: 34,
    margin: 8,
    marginTop: 0,
    textAlign: "center"
  },
  message: {
    color: theme.palette.grey[600],
    fontSize: 20,
    textAlign: "center"
  }
}));

const LoadingHourglass = ({ header = DEFAULT_HEADER, message }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Player
        autoplay
        loop
        src={LoadingLottieAnimation}
        style={{ height: "auto", width: "160px" }}
      />
      <p className={classes.header}>{header}</p>
      {message && <p className={classes.message}>{message}</p>}
    </div>
  );
};

LoadingHourglass.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string
};

export default LoadingHourglass;
