import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { CardHeader } from "./CardHeader";

const useStyles = makeStyles(theme => ({
  card: {
    height: 330,
    display: "flex",
    flexDirection: "column"
  },
  header: {
    paddingBottom: 0
  },
  content: {
    paddingTop: theme.spacing(2),
    overflow: "auto"
  },
  emptyLabel: {
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5)
  }
}));

const ScrollableCard = ({
  items,
  loading,
  loadingStateComponent,
  headerActionComponent,
  title,
  emptyStateCopy
}) => {
  const classes = useStyles();

  const renderEmptyState = () => (
    <Typography variant="subtitle2" className={classes.emptyLabel}>
      {emptyStateCopy}
    </Typography>
  );

  const renderContent = () => (items.length ? items : renderEmptyState());

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.header}>
        <CardHeader
          label={`${loading ? "" : items.length} ${title}`}
          noMarginBottom
          actionComponent={headerActionComponent}
        />
      </CardContent>
      <div className={classes.content}>
        {loading ? loadingStateComponent : renderContent()}
      </div>
    </Card>
  );
};

ScrollableCard.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  loadingStateComponent: PropTypes.node,
  headerActionComponent: PropTypes.node,
  title: PropTypes.string,
  emptyStateCopy: PropTypes.string
};

export default ScrollableCard;
