import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import jwtDecode from "jwt-decode";

import { INBOX_TEMPLATES } from "Queries/InboxTemplate/InboxTemplateQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { Table } from "Components/Tables/Table";
import { TableHead } from "Components/Tables/TableHead";
// import { TableFooter } from "Components/Tables/TableFooter";
import { useTableState } from "Components/Tables/hooks/useTableState";
import InboxSettingsEditModal from "./InboxSettingsEditModal";
import InboxSettingsConfirmationModal from "./InboxSettingsConfirmationModal";
import InboxSettingsLoading from "./InboxSettingsLoading";
import useInboxSettingsState from "./useInboxSettingsState";

const columns = [
  {
    title: "Shortcut",
    field: "shortcutDisplay"
  },
  {
    title: "Message",
    field: "message"
  },
  {
    title: "",
    field: "messageEdit"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.border.light}`
  },
  editButton: {
    cursor: "pointer"
  }
}));

export const InboxSettings = () => {
  const classes = useStyles();
  const {
    first: rowsPerPage,
    page
    // handleFirstChange,
    // nextPage,
    // previousPage
  } = useTableState({
    initialFirst: 10
  });

  const {
    confirmationModalOpen,
    editModalOpen,
    handleCloseConfirmationModal,
    handleOpenEditModal,
    handleCloseEditModal,
    handleEditButtonClick,
    editReplyId,
    editShortCut,
    editMessage,
    setEditMessage,
    setEditShortCut
  } = useInboxSettingsState();

  const { currentRooftopToken, currentOrganizationClient } = useContext(
    ClientFactoryContext
  );

  const { data, loading } = useQuery(INBOX_TEMPLATES, {
    variables: { id: jwtDecode(currentRooftopToken).id },
    client: currentOrganizationClient
  });

  const quickReplyRows =
    data?.rooftop?.inboxTemplates.map(template => ({
      ...template,
      shortcutDisplay: `/\u00A0${template.title}`,
      messageEdit: (
        <EditIcon
          className={classes.editButton}
          onClick={() =>
            handleEditButtonClick(template.id, template.title, template.message)
          }
        />
      )
    })) ?? [];

  const inboxTemplatesPage = quickReplyRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper className={classes.root} elevation={0}>
      <TableHead
        tableName="Quick Replies"
        tableSubtext={
          <div>
            <br></br>
            Set up templates that you can use as shortcuts to respond to driver
            messages
            <br></br>
            Type / on your chat inbox to bring up your quick replies
          </div>
        }
        loading={loading}
        hideSearch={true}
      />
      <Table
        columns={columns}
        data={inboxTemplatesPage}
        noDataCellLabel="-"
        disableRowPointer
        disableHover
        loading={loading}
        onRowClick={null}
        loadingComponent={InboxSettingsLoading}
      />
      {/* <TableFooter
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleFirstChange}
        hasNextPage={quickReplyRows.length > (page + 1) * rowsPerPage}
        hasPreviousPage={page > 0}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        page={page}
      /> */}
      <InboxSettingsConfirmationModal
        confirmationModalOpen={confirmationModalOpen}
        handleOnClose={handleCloseConfirmationModal}
        handleAction={() => {
          handleCloseConfirmationModal();
          handleOpenEditModal();
        }}
        handleCancel={() => {
          handleCloseConfirmationModal();
          handleCloseEditModal();
        }}
      />
      <InboxSettingsEditModal
        open={editModalOpen}
        currentOrganizationClient={currentOrganizationClient}
        handleOnClose={handleCloseEditModal}
        editReplyId={editReplyId}
        shortCut={editShortCut}
        message={editMessage}
        handleOnShortCutChange={e => {
          setEditShortCut(e.target.value);
        }}
        handleOnMessageChange={e => setEditMessage(e.target.value)}
      />
    </Paper>
  );
};
