import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Field, formValueSelector } from "redux-form";
import { FormTextField } from "Components/Inputs/FormTextField";
import { connect } from "react-redux";
import FormSelect from "../FormSelect";

const useStyles = makeStyles(theme => ({
  select: {
    marginLeft: theme.spacing(4),
    width: "80%"
  },
  textField: {
    marginLeft: theme.spacing(4),
    width: "80%"
  }
}));

export const StatusReasonForm = connect(state => ({
  snoozedReason: formValueSelector("CAR_STATUS_EDIT")(state, "snoozedReason"),
  deletedReason: formValueSelector("CAR_STATUS_EDIT")(state, "deletedReason")
}))(
  ({
    snoozedReason,
    deletedReason,
    inputLabel,
    statusReasonOptions,
    inputName
  }) => {
    const classes = useStyles();

    const statusReason =
      inputName === "snoozedReason" ? snoozedReason : deletedReason;

    return (
      <>
        <Field
          className={classes.select}
          name={inputName}
          component={FormSelect}
          label={inputLabel}
          margin="normal"
          options={Object.values(statusReasonOptions)}
        />
        {(statusReason === statusReasonOptions.unhappy ||
          statusReason === statusReasonOptions.unhappyWithDriver ||
          statusReason === statusReasonOptions.others) && (
          <Field
            id={"delete-car-reasons"}
            className={classes.textField}
            label="Tell us Why"
            multiline
            minRows={3}
            margin="normal"
            name="unhappyReason"
            component={FormTextField}
          />
        )}
      </>
    );
  }
);
