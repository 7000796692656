import { useCallback, useEffect, useState } from "react";

const checkOverflow = element => {
  if (element)
    return (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    );
  return false;
};

const useScrollbar = ({ elementRef }) => {
  const [overflowActive, setOverflowActive] = useState(false);

  const onResize = useCallback(() => {
    if (!elementRef.current) return;
    if (checkOverflow(elementRef.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [elementRef]);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  const scroll = useCallback(
    (direction, spaceToScroll) => {
      const topMovement = direction === "top" ? spaceToScroll : 0;
      const botMovement = direction === "bottom" ? -spaceToScroll : 0;
      const leftMovement = direction === "left" ? -spaceToScroll : 0;
      const rightMovement = direction === "right" ? spaceToScroll : 0;

      elementRef.current.scrollBy({
        top: topMovement || botMovement,
        left: leftMovement || rightMovement,
        behavior: "smooth"
      });
    },
    [elementRef]
  );

  return {
    scroll,
    overflowActive
  };
};

export default useScrollbar;
