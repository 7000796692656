import React, { useContext } from "react";
import { Field, formValues, arrayRemove } from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetApp from "@mui/icons-material/GetApp";
import get from "lodash/get";
import { useSnackbar } from "notistack";

import { ImageDropzone } from "Components/Forms/Dropzone/ImageDropzone";
import { FormDatePicker } from "Components/Inputs/FormDatePicker";
import { Image } from "Components/Images/Image";
import { AcceptEnum } from "Enums/AcceptEnum";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: theme.spacing(4, 3),
    marginBottom: theme.spacing(4)
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  fieldContainer: {
    flex: 1
  },
  imageContainer: {
    marginTop: theme.spacing(2)
  }
}));

export const DocumentFormFields = compose(
  formValues("cars"),
  connect(null, { arrayRemove })
)(({ cars, arrayRemove, fields, form }) => {
  const classes = useStyles();
  const { getAsset } = useContext(FirebaseContext);

  const { enqueueSnackbar } = useSnackbar();
  // const [uploadFile] = useMutation(UPLOAD_FILE);

  const handleRejectedFiles = () => {
    enqueueSnackbar(
      "Sorry, some of the files you uploaded might have been rejected because there were too many or were too large.",
      {
        variant: "error",
        autoHideDuration: 5000
      }
    );
  };

  const handleOnDelete = (fieldIndex, name, field) => () => {
    const currentUpload = fields.get(fieldIndex)["documents"][name]["photo"][0];
    arrayRemove(form, `${field}.documents.${name}.photo`, 0);
    URL.revokeObjectURL(currentUpload);
  };

  // This is not used for now due to changes in release
  // const asyncAction = async value => {
  //   try {
  //     const { data } = await uploadFile({
  //       variables: {
  //         file: value,
  //         type: FileTypeEnum.carDocument
  //       }
  //     });
  //     const url = get(data, "uploadFile.url");
  //     const id = get(data, "uploadFile.id");
  //     const contentType = get(data, "uploadFile.contentType");

  //     return {
  //       id,
  //       url,
  //       contentType
  //     };
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const preview = (fieldIndex, document, file, field) => {
    const contentType = get(file, `type`);
    // when async comment in the next line and comment out the following one
    // const photoUrl = get(file, `url`);
    const photoUrl = URL.createObjectURL(file);
    return (
      <>
        {contentType === "application/pdf" ? (
          <Image
            src={getAsset("pdfPreviewIcon")}
            objectFit="cover"
            border
            height={165}
            width={252}
            textOverImage={
              <>
                <div>PDF Preview Unavailable.</div>
                <div>Download to view.</div>
              </>
            }
            actions={[
              {
                href: photoUrl,
                icon: <GetApp color="primary" />
              },
              {
                action: handleOnDelete(fieldIndex, document.name, field),
                icon: <DeleteForeverIcon color="error" />
              }
            ]}
          />
        ) : (
          <Image
            src={photoUrl}
            objectFit="contain"
            border
            height={165}
            width={252}
            actions={[
              {
                action: handleOnDelete(fieldIndex, document.name, field),
                icon: <DeleteForeverIcon color="error" />
              }
            ]}
          />
        )}
      </>
    );
  };

  return fields.map((field, fieldIndex) => {
    const car = fields.get(fieldIndex);

    return (
      <Paper key={car.vin} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.title}>
              {car.year} {car.make} {car.model}
            </Typography>
            <Typography variant="body2">{car.vin}</Typography>
          </Grid>
          <Grid container item xs={9} spacing={3}>
            {[
              {
                name: "registration",
                content: "Upload Registration"
              },
              {
                name: "insurance",
                content: "Upload Insurance"
              },
              {
                name: "inspection",
                content: "Upload Inspection"
              }
            ].map(document => {
              const file = get(
                cars,
                `${fieldIndex}.documents.${document.name}.photo[0]`
              );

              return (
                <Grid
                  key={document.name}
                  container
                  item
                  direction="column"
                  spacing={2}
                  className={classes.fieldContainer}
                >
                  <Grid item>
                    {file ? (
                      preview(fieldIndex, document, file, field)
                    ) : (
                      <Field
                        name={`${field}.documents.${document.name}.photo`}
                        component={ImageDropzone}
                        accept={[
                          AcceptEnum.png,
                          AcceptEnum.jpeg,
                          AcceptEnum.pdf
                        ]}
                        maxSize={7340032}
                        height={165}
                        fullWidth
                        content={
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                {document.content}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <AddIcon />
                            </Grid>
                          </Grid>
                        }
                        hover
                        hideButton
                        onRejectFiles={handleRejectedFiles}
                        // asyncAction={asyncAction}
                        maxToUpload={1}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Field
                      name={`${field}.documents.${document.name}.expiration`}
                      component={FormDatePicker}
                      label="Expiration Date"
                      fullWidth
                      variant="inline"
                      inputVariant="filled"
                      disablePast
                      disableToolbar
                      autoOk
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    );
  });
});
