import { gql } from "@apollo/client";

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    fullName
    type
    type
    photo
    phone
    email
    dob
    status
    requiresTosAcceptance
    profilePhoto {
      id
      url
    }
    location {
      id
      apt
      lat
      lng
      address
      street
      formattedAddress
      city
      state
      zip
    }
    paymentAccounts {
      id
      type
      stripePaymentInformation {
        id
        customerId
      }
    }
    payoutAccounts {
      id
      type
    }
    driver {
      id
      legacyId
      aboutMe
      backgroundCheck {
        id
        verificationStatus
        checkrCandidateId
        dateOfBirth
      }
    }
    owner {
      id
      createdAt
      legacyId
      aboutMe
      verified
      defaultProtectionPlan
    }
  }
`;

export default gql`
  ${UserFragment}
  query CurrentUserType {
    viewer {
      id
      me {
        id
        ...UserFragment
      }
    }
  }
`;
