import { combineReducers } from "redux";
import moment from "moment";
import { getDatesFromRange } from "Redux/utils";
import {
  GET_REVENUE_DATA,
  GET_REVENUE_DATA_SUCCESS,
  GET_REVENUE_DATA_ERROR,
  UPDATE_VIN,
  UPDATE_CURSOR,
  UPDATE_LOADING,
  GET_OWNER_CAR_IDS,
  GET_OWNER_CAR_IDS_ERROR,
  GET_OWNER_CAR_IDS_SUCCESS,
  UPDATE_TRANSACTION_DATE_FILTERS,
  UPDATE_TRANSACTION_CUSTOM_DATE_FILTERS,
  TOGGLE_TRANSACTIONS_DATE_DIALOG
} from "./Actions";

const error = (state = null, action) => {
  switch (action.type) {
    case GET_REVENUE_DATA_ERROR:
      return action.error;
    default:
      return state;
  }
};

const cars = (state = [], action) => {
  switch (action.type) {
    case GET_OWNER_CAR_IDS_SUCCESS:
      return action.dataset.map(car => ({
        id: car.node.id,
        vin: car.node.vin
      }));
    case GET_REVENUE_DATA_SUCCESS: {
      return state.map(item => {
        action.dataset.forEach(car => {
          if (car.id === item.id) {
            item = {
              ...car,
              hasData: true
            };
          }
        });
        return item;
      });
    }
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case GET_REVENUE_DATA:
      return true;
    case GET_REVENUE_DATA_SUCCESS:
    case GET_REVENUE_DATA_ERROR:
      return false;
    case UPDATE_LOADING:
      return action.loading;
    default:
      return state;
  }
};

const carIdsLoading = (state = true, action) => {
  switch (action.type) {
    case GET_OWNER_CAR_IDS:
      return true;
    case GET_OWNER_CAR_IDS_SUCCESS:
    case GET_OWNER_CAR_IDS_ERROR:
      return false;
    default:
      return state;
  }
};

const vin = (state = "", action) => {
  switch (action.type) {
    case UPDATE_VIN:
      return action.vin;
    default:
      return state;
  }
};

const cursor = (state = { start: 0, end: 10 }, action) => {
  switch (action.type) {
    case UPDATE_CURSOR:
      return {
        start: state.start + action.offset,
        end: state.end + action.offset
      };
    default:
      return state;
  }
};

const transactions = (
  state = {
    startDate: moment(new Date())
      .startOf("day")
      .subtract(7, "days")
      .format(),
    endDate: moment(new Date()).format(),
    dateDescriptionText: "Past 7 days",
    customDateDialogOpen: false
  },
  action
) => {
  const { selectedRange, customRange } = action;
  const { startDate, endDate } = getDatesFromRange(selectedRange);
  switch (action.type) {
    case UPDATE_TRANSACTION_DATE_FILTERS:
      return {
        ...state,
        startDate,
        endDate,
        dateDescriptionText: selectedRange
      };

    case UPDATE_TRANSACTION_CUSTOM_DATE_FILTERS:
      return {
        startDate: customRange.startDate,
        endDate: customRange.endDate,
        dateDescriptionText: "Custom",
        customDateDialogOpen: false
      };

    case TOGGLE_TRANSACTIONS_DATE_DIALOG:
      return {
        ...state,
        customDateDialogOpen: action.customDateDialogOpen
      };

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  carIdsLoading,
  cursor,
  vin,
  cars,
  error,
  transactions
});
