import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import clsx from "clsx";

export const STATUS_DISABLED = "STATUS_DISABLED";
export const STATUS_WARNING = "STATUS_WARNING";
export const STATUS_ERROR = "STATUS_ERROR";

const useStyles = makeStyles(theme => ({
  carItem: ({ clickable, disabled }) => ({
    display: "flex",
    flexGrow: 1,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: !disabled && clickable && "pointer"
  }),
  checkedIcon: {
    backgroundColor: "#eeeeee"
  },
  description: ({ disabled }) => ({
    color: disabled ? "#9e9e9e" : theme.palette.common.black,
    fontSize: 18,
    marginBottom: theme.spacing(0.3),
    fontWeight: 400
  }),
  vin: {
    fontSize: 11,
    letterSpacing: 3,
    fontWeight: 300
  },
  secondary: ({ disabled, warning, error }) => ({
    fontSize: 16,
    fontWeight: 400,
    ...(disabled && { color: "#9e9e9e" }),
    ...(warning && { color: theme.palette.warning.dark }),
    ...(error && { color: theme.palette.error.dark })
  }),
  avatar: ({ disabled }) => ({
    width: 45,
    height: 45,
    marginRight: 18,
    opacity: disabled ? 0.4 : 1,
    marginTop: 5
  })
}));

const CarListItem = ({ car, checked, secondaryText, onClick, status }) => {
  const classes = useStyles({
    disabled: status === STATUS_DISABLED,
    warning: status === STATUS_WARNING,
    error: status === STATUS_ERROR,
    clickable: !!onClick
  });
  const photo = car?.photos?.[0]?.file?.url;
  return (
    <div
      onClick={onClick}
      className={clsx(classes.carItem, { [classes.checkedIcon]: checked })}
    >
      <Avatar className={classes.avatar} src={photo} />
      <div>
        <Typography className={classes.vin}>
          {car.vin.slice(car.vin.length - 6)}
        </Typography>
        <Typography className={classes.description}>{`‘${car.year.slice(-2)} ${
          car.make
        } ${car.model}`}</Typography>
        <Typography className={classes.secondary}>{secondaryText}</Typography>
      </div>
    </div>
  );
};

CarListItem.propTypes = {
  car: PropTypes.object,
  checked: PropTypes.bool,
  secondaryText: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.string
};

export default CarListItem;
