import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import moment from "moment";

import { createShortenedString } from "Utils/Helpers";
import { RouteEnum } from "Enums/RouteEnum";
import { DrawerContainer } from "Components/Drawers/DrawerContainer";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";
import { selectChannel } from "Redux/SendBird/ActionCreators";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { checkPermission } from "Utils/RooftopUtils";
import { InboxDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";

const useStyles = makeStyles(() => ({
  root: {
    width: "350px"
  },
  noRecentMessages: {}
}));

export const NotificationsDrawer = connect(
  state => ({
    sendbird: state.fleet.sendbird
  }),
  { selectChannel }
)(
  ({
    open,
    onClose,
    onOpen,
    sendbird: { connecting, loading, messageNotifications },
    selectChannel
  }) => {
    const classes = useStyles();
    const history = useHistory();
    const { scopes } = useContext(ClientFactoryContext);

    return (
      <SwipeableDrawer
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        anchor="right"
        style={{ zIndex: 1250 }}
      >
        <DrawerContainer classes={{ root: classes.root }}>
          {loading || connecting ? (
            <SkeletonDrawer />
          ) : messageNotifications.length === 0 ? (
            <DrawerPaddingContainer>
              <Typography
                component="span"
                display="block"
                variant="body1"
                align="center"
              >
                No recent messages.
              </Typography>
            </DrawerPaddingContainer>
          ) : (
            <List>
              {messageNotifications.map((message, index) => {
                return (
                  <Fragment key={index}>
                    <ListItem
                      alignItems="flex-start"
                      button
                      onClick={() => {
                        selectChannel(message.channel);
                        history.push(
                          checkPermission(
                            InboxDomainPermissionPaths.viewMultiUserInbox,
                            scopes
                          )
                            ? RouteEnum.inboxMine
                            : RouteEnum.inbox
                        );
                        onClose();
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt="driver"
                          src={get(message, "_sender.profileUrl")}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${get(
                          message,
                          "_sender.nickname"
                        )} - ${moment(message.createdAt).format(
                          "ddd, MMM D, h:ma"
                        )}`}
                        secondary={
                          <>
                            <Typography component="span" variant="body2">
                              {createShortenedString(message.message, 50)}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </Fragment>
                );
              })}
            </List>
          )}
        </DrawerContainer>
      </SwipeableDrawer>
    );
  }
);

NotificationsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
