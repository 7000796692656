import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Doughnut } from "react-chartjs-2";

import { Card } from "./Card";
import { CardHeader } from "./CardHeader";
import { rentalStatusAggregate } from "Queries/Rentals/hocs/RentalQueryHocs";
import { RouteEnum } from "Enums/RouteEnum";
import { RentalStatusCopyEnum } from "Enums/StateEnums";

const keyToLabelMap = {
  active: RentalStatusCopyEnum.active,
  applied: RentalStatusCopyEnum.requested,
  late: RentalStatusCopyEnum.late,
  pending_insurance: RentalStatusCopyEnum.accepted,
  pending_pickup: RentalStatusCopyEnum.checkIn
};

const useStyles = makeStyles(theme => ({
  table: {
    padding: theme.spacing(0.5)
  }
}));

export const RentalStatusCard = rentalStatusAggregate(props => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { loading } = props;
  const classes = useStyles();

  const pieChartColors = [
    "#03a6ef",
    "#e3f5fd",
    "#8bd4f7",
    "#49bdf3",
    "#a9dff9",
    "#25b1f0",
    "#c7e9fb"
  ];
  const pieData = Object.keys(props).reduce(
    (acc, key, index) => {
      if (Object.prototype.hasOwnProperty.call(props, key) && props[key]) {
        if (props[key].aggregate) {
          acc.labels.push(key);
          acc.datasets[0].data.push(props[key].aggregate);
          acc.datasets[0].backgroundColor.push(pieChartColors[index]);
        }
      }
      return acc;
    },
    { labels: [], datasets: [{ data: [], backgroundColor: [] }] }
  );
  if (pieData.datasets[0].data.length === 0) {
    pieData.labels.push("No Data");
    pieData.datasets[0].data.push(1);
    pieData.datasets[0].backgroundColor.push(pieChartColors[0]);
  }

  return (
    <Card>
      <CardHeader
        label="Rental Status"
        to={RouteEnum.rentalsActive}
        link="View More"
      />
      <Grid container alignItems="stretch">
        <Grid container item xs={12} md={6} alignItems="center">
          {loading ? (
            <Skeleton
              variant="circular"
              width={175}
              height={175}
              style={{ margin: "0 auto" }}
            />
          ) : (
            <Doughnut
              data={pieData}
              options={{
                legend: {
                  display: false
                },
                aspectRatio: 4 / 3
              }}
            />
          )}
        </Grid>
        <Grid container item xs={12} md={6}>
          {[
            "applied",
            "pending_insurance",
            "pending_pickup",
            "active",
            "late"
          ].map((val, key) => (
            <Grid
              key={key}
              container
              item
              xs={12}
              direction="column"
              justifyContent="center"
              className={classes.table}
            >
              {loading ? (
                <Skeleton variant="text" width={matches ? "100%" : "75%"} />
              ) : (
                <Typography component="span" variant="h6" color="textSecondary">
                  {`${props[val].aggregate} ${keyToLabelMap[val]}`}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
});
