import React from "react";
import MuiCircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh"
  }
}));

export const CircularProgress = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <MuiCircularProgress className={classes.progress} {...props} />
    </div>
  );
};
