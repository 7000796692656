export const CONNECT_TO_SENDBIRD = "CONNECT_TO_SENDBIRD";
export const CONNECT_TO_SENDBIRD_SUCCESS = "CONNECT_TO_SENDBIRD_SUCCESS";
export const CONNECT_TO_SENDBIRD_FAILURE = "CONNECT_TO_SENDBIRD_FAILURE";
export const GET_SENDBIRD_CHANNELS = "GET_SENDBIRD_CHANNELS";
export const GET_SENDBIRD_CHANNELS_SUCCES = "GET_SENDBIRD_CHANNELS_SUCCESS";
export const GET_SENDBIRD_CHANNELS_FAILURE = "GET_SENDBIRD_CHANNELS_FAILURE";

export const GET_CHANNEL_MESSAGES = "GET_CHANNEL_MESSAGES";
export const GET_CHANNEL_MESSAGES_SUCCESS = "GET_CHANNEL_MESSAGES_SUCCESS";
export const GET_CHANNEL_MESSAGES_FAILURE = "GET_CHANNEL_MESSAGES_FAILURE";

export const SEND_MESSAGE_TO_CHANNEL = "SEND_MESSAGE_TO_CHANNEL";
export const SEND_MESSAGE_TO_CHANNEL_SUCCESS =
  "SEND_MESSAGE_TO_CHANNEL_SUCCESS";
export const SEND_MESSAGE_TO_CHANNEL_FAILURE =
  "SEND_MESSAGE_TO_CHANNEL_FAILURE";

export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";

export const MARK_CHANNEL_AS_READ = "MARK_CHANNEL_AS_READ";
export const READ_RECEIPT_UPDATED = "READ_RECEIPT_UPDATED";

export const ON_CHANNEL_CHANGED = "ON_CHANNEL_CHANGED";
export const ADD_CHANNEL_RENTAL_STATUS = "ADD_CHANNEL_RENTAL_STATUS";

export const ON_RECONNECT_STARTED = "ON_RECONNECT_STARTED";
export const ON_RECONNECT_SUCCEEDED = "ON_RECONNECT_SUCCEEDED";
export const ON_RECONNECT_FAILED = "ON_RECONNECT_FAILED";

export const SELECT_CHANNEL = "SELECT_CHANNEL";
export const DESELECT_CHANNEL = "DESELECT_CHANNEL";
export const START_CHANNEL = "START_CHAT";
export const START_CHANNEL_SUCCESS = "START_CHANNEL_SUCCESS";
export const START_CHANNEL_FAILURE = "START_CHANNEL_FAILURE";

export const UPDATE_USER_INFO = "UPDATE_USER";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILURE = "UPDATE_USER_INFO_FAILURE";

export const UPDATE_INBOX_SEARCH = "UPDATE_INBOX_SEARCH";
export const UPDATE_INBOX_FILTERS = "UPDATE_INBOX_FILTERS";

export const UPDATE_CHANNEL_MESSAGE_INPUT = "UPDATE_CHANNEL_MESSAGE_INPUT";
