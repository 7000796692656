import React, { useContext } from "react";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { AchFormFields } from "Components/Forms/FormFields/AchFormFields";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerHead } from "Components/Drawers/DrawerHead";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { PaymentDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  headerText: {
    color: theme.palette.common.white
  }
}));

export const AchDrawerForm = compose(
  reduxForm({
    form: "RENTAL_REQUEST"
  })
)(({ handleSubmit, submitting, onClose }) => {
  const { scopes } = useContext(ClientFactoryContext);
  const classes = useStyles();

  return renderByPermissions(
    <>
      <DrawerHead
        title="Accept Rental"
        subtitle="To accept this rental, you need to set up receiving payments through ACH."
        onClose={onClose}
      >
        <Typography
          component="p"
          variant="subtitle1"
          className={classes.headerText}
        >
          You will only be asked to do this once and can update this anytime in
          your settings. We do not store your social security number nor tax ID.
        </Typography>
      </DrawerHead>
      <DrawerPaddingContainer>
        <AchFormFields compact />
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onSubmit={handleSubmit}
        submitText={submitting ? "submitting..." : "submit and accept"}
        disabled={submitting}
      ></DrawerBottomBar>
    </>,
    [PaymentDomainPermissionPaths.createACH],
    scopes,
    <>
      <DrawerHead
        title="Accept Rental"
        subtitle="To accept this rental, you need to set up receiving payments through ACH."
        onClose={onClose}
      >
        <Typography
          component="p"
          variant="subtitle1"
          className={classes.headerText}
        >
          You do not have permission to set up ACH on this account. Contact the
          account owner to update permissions.
        </Typography>
      </DrawerHead>
    </>
  );
});
