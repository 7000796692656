import React, { useState, useEffect, useContext } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import get from "lodash/get";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

import { useListingsTable } from "Queries/Fleet/hooks/useListingsTable";
import { useTableState } from "Components/Tables/hooks/useTableState";
import { setFilters } from "Redux/Header/ActionCreators";
import { CarInfoHeader } from "Components/Car/CarInfoHeader";
import { CarInfoFooter } from "Components/Car/CarInfoFooter";
import { CarInfoFilter } from "Components/Car/CarInfoFilter";
import { CircularProgress } from "Components/Loading/CircularProgress";
import { ListingStatusIndicator } from "Components/Car/ListingStatusIndicator";
import { ownerGetCarDeepLink as getCarDeepLink } from "Utils/BranchHelper";
import { RouteEnum } from "Enums/RouteEnum";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { MobileSearchFilter } from "Components/Header/MobileSearchFilter";
import { OverpricedCarsContext } from "Components/Utils/OverpricedCarsProvider";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2, 2, 2),
    marginBottom: theme.spacing(2)
  }
}));

const ShareButton = ({ car }) => {
  const [copyLink, setCopyLink] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (car) {
      try {
        const carDeepLink = async () => {
          const carDeepLink = await getCarDeepLink({
            car: {
              ...car,
              car_photo: get(car, "photos[0].file.url"),
              p2p_price_daily: get(car, "dailyPriceInCents", 0)
            },
            utmSource: "listing_summary",
            pageName: "Listings Card View",
            componentName: "Share Button"
          });
          setCopyLink(carDeepLink);
        };
        carDeepLink();
      } catch (e) {
        console.error(e);
        setCopyLink("");
      }
    }
  }, [car]);

  return (
    <CopyToClipboard
      text={copyLink}
      onCopy={() =>
        enqueueSnackbar("Copied to Clipboard!", { variant: "success" })
      }
    >
      <Button color="primary">Share</Button>
    </CopyToClipboard>
  );
};

const ListingsCard = ({
  data,
  loading,
  hasNextPage,
  onLoadMoreClick,
  fetchingMore
}) => {
  const classes = useStyles();
  const { isCarOverpriced } = useContext(OverpricedCarsContext);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          {data.map(data => {
            return (
              <Paper className={classes.paper} key={data.id}>
                <CarInfoHeader
                  title={data["yearMakeModel"]}
                  carPhoto={data["carPhoto"]}
                  details={[data["vin"]]}
                  to={`${RouteEnum.listingsAll}/${data["id"]}`}
                  isCarOverpriced={isCarOverpriced(data["id"])}
                  dailyPriceInDollars={data["dailyPriceInDollars"]}
                />

                <CarInfoFooter
                  statusComponent={
                    <ListingStatusIndicator
                      status={data.status}
                      verification={data.verification}
                    />
                  }
                  shareButton={<ShareButton car={data["carSummary"]} />}
                  actionButtons={[
                    {
                      label: isCarOverpriced(data["id"]) ? "review" : "view",
                      to: `${RouteEnum.listingsAll}/${data["id"]}`
                    }
                  ]}
                />
              </Paper>
            );
          })}
          {hasNextPage && (
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onLoadMoreClick}
            >
              {fetchingMore ? "Loading..." : "Load More"}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export const ListingsCardView = connect(
  state => ({
    search: state.fleet.header.search,
    selectedFilters: state.fleet.header.selectedFilters
  }),
  { setFilters }
)(
  ({
    initialWhere,
    filters,
    noDataComponent,
    search,
    setFilters,
    selectedFilters
  }) => {
    const { first, orderBy } = useTableState({
      initialFirst: 10,
      initialOrderBy: "updatedAt_DESC"
    });
    const { currentRooftopClient } = useContext(ClientFactoryContext);

    const {
      mappedData,
      pageInfo: { hasNextPage },
      loading,
      fetchMoreData,
      fetchingMore
    } = useListingsTable({
      variables: {
        searchText: search,
        first,
        orderBy,
        where: {
          ...initialWhere,
          ...selectedFilters.reduce((acc, val) => {
            if (Object.prototype.hasOwnProperty.call(acc, `${val.key}`)) {
              acc[val.key].push(val.value);
            } else {
              acc[val.key] = [val.value];
            }
            return acc;
          }, {})
        }
      },
      client: currentRooftopClient,
      fetchPolicy: "network-only"
    });

    useEffect(() => {
      if (filters) {
        setFilters(filters);
      }
    }, [setFilters, filters]);

    return (
      <>
        <MobileSearchFilter />
        <CarInfoFilter />
        {mappedData.length === 0 && !loading ? (
          <Grid container justifyContent="center" direction="column">
            {noDataComponent}
          </Grid>
        ) : (
          <ListingsCard
            data={mappedData}
            noDataComponent={noDataComponent}
            loading={loading}
            hasNextPage={hasNextPage}
            onLoadMoreClick={fetchMoreData}
            fetchingMore={fetchingMore}
          />
        )}
      </>
    );
  }
);

ListingsCardView.propTypes = {
  initialWhere: PropTypes.object,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  noDataLabel: PropTypes.string,
  noDataComponent: PropTypes.node
};
