import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";

import { linkRegex, splitTextByLinkRegex } from "Utils/Regex";
import { AvatarWithName } from "Components/Avatar/AvatarWithName";

const useStyles = makeStyles(theme => ({
  chatMessageWrapper: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    borderRadius: "12px",
    maxWidth: "60%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "75%"
    }
  },
  driver: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
    background: theme.palette.grey["100"],
    border: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: "0px",
    overflowWrap: "anywhere"
  },
  owner: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderTopRightRadius: "0px",
    overflowWrap: "anywhere"
  },
  ownerText: {
    color: theme.palette.primary.contrastText
  },
  link: {
    overflowWrap: "break-word"
  }
}));

export const InboxChatMessage = ({
  isDriver,
  name,
  text,
  timestamp,
  profilePhoto,
  className
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      justifyContent={isDriver ? "flex-start" : "flex-end"}
      className={className}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent={isDriver ? "flex-start" : "flex-end"}
      >
        <AvatarWithName
          url={profilePhoto}
          firstName={name}
          size="small"
          avatarLast={!isDriver}
        />
      </Grid>
      <Grid
        item
        className={classNames(classes.chatMessageWrapper, {
          [classes.driver]: isDriver,
          [classes.owner]: !isDriver
        })}
      >
        <Typography component="p" variant="body2">
          {typeof text === "string"
            ? text.split(splitTextByLinkRegex).map((t, index) => {
                if (linkRegex.test(t)) {
                  return (
                    <Link
                      key={index}
                      href={t}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classNames(classes.link, {
                        [classes.ownerText]: !isDriver
                      })}
                      underline="hover"
                    >
                      {t}
                    </Link>
                  );
                } else {
                  return t;
                }
              })
            : text}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={isDriver ? "flex-start" : "flex-end"}
      >
        <Typography component="span" variant="caption" color="textSecondary">
          {moment(timestamp).format("ddd, MMM D, h:mma")}
        </Typography>
      </Grid>
    </Grid>
  );
};

InboxChatMessage.propTypes = {
  isDriver: PropTypes.bool,
  name: PropTypes.string,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};
