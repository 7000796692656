import { gql } from "@apollo/client";

import { OwnerFragments } from "Queries/Owner/OwnerFragments";

export const UserFragments = {
  currentUser: gql`
    fragment CurrentUser on User {
      id
      legacyId
      createdAt
      updatedAt
      email
      firstName
      lastName
      fullName
      hyrecarTosAcceptedVersion
      hyrecarTosAcceptedAt
      requiresTosAcceptance
      lastLoginAt
      phone
      status
      type
      impactClickId
      dob
      profilePhoto {
        id
        url
      }
      owner {
        id
        createdAt
        legacyId
        aboutMe
        verified
        type
        ...OwnerConnectedIcc
      }
      driver {
        id
        legacyId
        aboutMe
        backgroundCheck {
          id
          dateOfBirth
          verificationStatus
          checkrCandidateId
        }
      }
    }
    ${OwnerFragments.ownerConnectedIcc}
  `,
  userProfileInfo: gql`
    fragment UserProfileInfo on User {
      id
      email
      type
      firstName
      lastName
      phone
      location {
        zip
        formattedAddress
        lat
        lng
      }
    }
  `,
  userType: gql`
    fragment UserType on User {
      id
      type
    }
  `,
  userLocation: gql`
    fragment UserLocation on UserLocation {
      id
      createdAt
      updatedAt
      address
      formattedAddress
      street
      city
      state
      zip
      lat
      lng
    }
  `,
  userRentalTable: gql`
    fragment UserRentalTable on User {
      id
      fullName
      firstName
      lastName
      profilePhoto {
        id
        url
      }
    }
  `,
  userFirstAndLastName: gql`
    fragment UserFirstAndLastName on User {
      id
      fullName
      firstName
      lastName
    }
  `
};
