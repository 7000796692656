import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import get from "lodash/get";
import { ApolloClient, from, split } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { extractFiles } from "extract-files";
import objectPath from "object-path";
import { createUploadLink } from "apollo-upload-client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { InMemoryCache } from "@apollo/client/cache";
import config from "Config/config";
import moment from "moment";

import {
  authLink,
  additionalHeadersLink,
  errorLink,
  apiV1BatchHttpLink,
  apiV1HttpLink,
  apiV2HttpLink,
  apiV2BatchHttpLink,
  client
} from "Components/Utils/ApolloProvider";
import { GET_TOKENS } from "Queries/Organization/OrganizationQueries";
import PropTypes from "prop-types";
import { authController } from "Login/AuthController";

export const ClientFactoryContext = React.createContext();

/** @function ClientProvider
 * @param {ReactNode} children - react node being wrapped
 * @summary Wrapper that provides creation of clients for organizations and rooftops.
 * @description ClientProvider is a solution for initializing Apollo Clients for each of the organizations and rooftops.
 * When the query GET_TOKENS has been called with the default Apollo Client client from ApolloProvider tokens are assigned to the user's organizations and rooftops.
 * (By default all users have an organization and a rooftop, if user is subscribed to HC4B it will have at least one extra organization and rooftop per subscription).
 * (Each HC4B organization may have multiple rooftops)
 * These tokens are used for most of the queries and mutations in the app, by using them in the Apollo Client header x-organization-authorization or x-rooftop-authorization,
 * allowing hyre-graph to grab information related directly to that organization or rooftop.
 * The importance behind creating multiple clients (one for each rooftop and organization) relies on the caching functionality ApolloClient provides, giving us the oportunity to update the cache
 * with the returned information and displaying it instantly without causing the app to do a hard reload.
 * @example update nested cache refer to useMemberFormController.js mutation INVITE_ORGANIZATION_MEMBER
 */
export const ClientProvider = ({ children }) => {
  const [organizationClients, setOrganizationClients] = useState({});
  const [rooftopClients, setRooftopClients] = useState({});

  const [organizationTokens, setOrganizationTokens] = useState([]);
  const [rooftopTokens, setRooftopTokens] = useState([]);

  const [currentRooftopToken, setCurrentRooftopToken] = useState(null);
  const [currentOrganizationToken, setCurrentOrganizationToken] = useState(
    null
  );

  const [tokenStructure, setTokenStructure] = useState([]);

  /** @function setDefaultClients
   * @summary Sets the default Apollo Clients for selected organization and rooftop.
   * @description Allowing users to come back to the last selected organization and rooftop is a functionality we want to support. To make this possible we need to set the correct clients
   * from the list of available created organization clients and rooftop clients, by setting the default clients we will allow the queries and mutations to reach out to hyre-graph and return information
   * from the current selected organization and rooftop.
   *
   * Per request of product: Users paying HC4B will be placed at the first rooftop in that HC4B organization the first time they log in.
   (this is done to avoid confusion)
   */

  const getStartIndex = () =>
    get(tokenStructure, "[1].rooftopTokens", []).length > 1 ? 1 : 0;

  const getOrganinzationToken = tokenIndexToDefaultTo =>
    get(tokenStructure, `[${tokenIndexToDefaultTo}].organizationToken`);

  const getRooftopTokens = tokenIndexToDefaultTo =>
    [
      ...get(tokenStructure, `[${tokenIndexToDefaultTo}].rooftopTokens`, [])
    ].sort((a, b) =>
      jwtDecode(a).name.toLowerCase() < jwtDecode(b).name.toLowerCase() ? -1 : 1
    );

  const validateTokens = (organizationToken, rooftopToken) =>
    !organizationToken ||
    !rooftopToken ||
    rooftopToken === "undefined" ||
    organizationToken === "undefined" ||
    rooftopToken === "" ||
    organizationToken === ""
      ? false
      : true;

  const setDefaultClients = () => {
    const organizationToken = window.localStorage.getItem("organizationToken");
    const rooftopToken = window.localStorage.getItem("rooftopToken");
    // Check if tokens are not in localStorage and sets them to first returned org/rooftop (default)
    if (!validateTokens(organizationToken, rooftopToken)) {
      const isP2P = tokenStructure.length < 2;

      if (isP2P) {
        const p2pOrganizationToken = get(
          tokenStructure,
          "[0].organizationToken"
        );
        const p2pRooftopToken = get(tokenStructure, "[0].rooftopTokens[0]");
        setCurrentOrganizationToken(p2pOrganizationToken);
        changeCurrentOrganizationClient(p2pOrganizationToken);
        setCurrentRooftopToken(p2pRooftopToken);
        changeCurrentRooftopClient(p2pRooftopToken);
      } else {
        const startIndex = getStartIndex();
        const hc4bOrganizationToken = getOrganinzationToken(startIndex);
        const hc4bRooftopTokens = getRooftopTokens(startIndex);

        setCurrentOrganizationToken(hc4bOrganizationToken);
        changeCurrentOrganizationClient(hc4bOrganizationToken);
        setCurrentRooftopToken(hc4bRooftopTokens[0]);
        changeCurrentRooftopClient(hc4bRooftopTokens[0]);
      }
    } else {
      // If tokens are in localStorage, we can find the rooftop they were on before they refreshed and set it
      const decodedOrgId = get(jwtDecode(organizationToken), "id");
      const decodedRooftopId = get(jwtDecode(rooftopToken), "id");
      const matchedOrgToken = organizationTokens.find(
        token => get(jwtDecode(token), "id") === decodedOrgId
      );
      const matchedRooftopToken = rooftopTokens.find(
        token => get(jwtDecode(token), "id") === decodedRooftopId
      );
      setCurrentOrganizationToken(matchedOrgToken);
      setCurrentRooftopToken(matchedRooftopToken);
      changeCurrentOrganizationClient(matchedOrgToken);
      changeCurrentRooftopClient(matchedRooftopToken);
    }
  };

  /** @function insertRooftopInfo
   * @param {string} rooftopId - rooftop id
   * @param {string} refreshedTokens - The refreshed tokens of the organization structure
   * @summary Modifies state were the rooftop token should be.
   * @description Inserts the rooftop token into the rooftopToken state, into the tokenStructure state and
   * creates a client for the rooftop including it into the rooftopClients state and also updating the session storage of current tokens (refreshing them)
   */
  const insertRooftopInfo = (rooftopId, refreshedTokens) => {
    const createdRooftopToken = refreshedTokens.rooftopTokens.find(
      rooftopToken => jwtDecode(rooftopToken).id === rooftopId
    );
    const createdRooftopId = jwtDecode(createdRooftopToken).id;

    const newTokenIndex = tokenStructure.findIndex(
      tokenBranch =>
        jwtDecode(tokenBranch.organizationToken).id ===
        jwtDecode(refreshedTokens.organizationToken).id
    );
    const newTokenStructure = tokenStructure.slice();
    newTokenStructure.splice(newTokenIndex, 1, refreshedTokens);
    setTokenStructure(newTokenStructure);
    setRooftopTokens(rooftopTokens.concat(createdRooftopToken));
    setRooftopClients(
      Object.assign({}, rooftopClients, {
        [createdRooftopId]: handleNewRooftopClient(createdRooftopToken)
      })
    );
    updateStorageTokens(refreshedTokens);
  };

  /** @function updateStorageTokens
   * @param {object} tokenBranch - token branch containing organization token and rooftop tokens
   * @summary Updates the session storage mapping for tokens
   * @description Updates the session storage mapping for organization tokens and rooftop tokens,
   * mapping the ids_organization or ids_rooftop to the passed in tokens
   */
  const updateStorageTokens = tokenBranch => {
    const updatedOrganizationToken = get(tokenBranch, "organizationToken");
    const updatedRooftopTokens = get(tokenBranch, "rooftopTokens");

    window.sessionStorage.setItem(
      `${jwtDecode(updatedOrganizationToken).id}_organization`,
      updatedOrganizationToken
    );

    for (let newRooftopToken of updatedRooftopTokens) {
      window.sessionStorage.setItem(
        `${jwtDecode(newRooftopToken).id}_rooftop`,
        newRooftopToken
      );
    }
  };

  /** @function refreshClientTokens
   * @param {string} organizationToken - organization token
   * @summary Updates the session storage mapping for tokens
   * @description Updates the session storage mapping for organization tokens and rooftop tokens,
   * mapping the ids_organization or ids_rooftop to the passed in tokens
   */
  const refreshClientTokens = async () => {
    try {
      const resp = await client.query({
        query: GET_TOKENS,
        fetchPolicy: "network-only",
        context: {
          apiv2: true
        }
      });
      const tokenStructure = get(resp, "data.getTokens");

      for (const tokenBranch of tokenStructure) {
        updateStorageTokens(tokenBranch);
      }
    } catch (e) {
      console.error(
        "id_token is not valid GET_TOKENS is failing, attempting to refresh id_token"
      );
      await authController.refreshToken();
    }
  };

  /** @function handleNewOrganizationClient
   * @param {string} organizationToken - Contains the organization token that will be used to create the Apollo Client
   * @summary Creates Apollo Client for an organization.
   * @description Creates client for an organization with expiration handled.
   */
  const handleNewOrganizationClient = organizationToken =>
    new ApolloClient({
      link: from([
        authLink,
        setContext(async (_, { headers = {} }) => {
          if (
            !window.sessionStorage.getItem(
              `${jwtDecode(organizationToken).id}_organization`
            )
          ) {
            window.sessionStorage.setItem(
              `${jwtDecode(organizationToken).id}_organization`,
              organizationToken
            );
          }
          const decoded = jwtDecode(
            window.sessionStorage.getItem(
              `${jwtDecode(organizationToken).id}_organization`
            )
          );
          const { exp } = decoded;
          if (
            exp &&
            !moment
              .unix(exp)
              .subtract(10, "seconds")
              .isSameOrAfter(moment())
          ) {
            // HANDLE IF EXPIRED!!!!
            await refreshClientTokens();
          }
          headers[
            "x-organization-authorization"
          ] = `Bearer ${window.sessionStorage.getItem(
            `${jwtDecode(organizationToken).id}_organization`
          )}`;
          return {
            headers
          };
        }),
        additionalHeadersLink,
        errorLink,
        split(
          operation => operation.getContext().important,
          from([
            createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }),
            apiV2HttpLink
          ]),
          apiV2BatchHttpLink
        )
      ]),
      cache: new InMemoryCache({
        dataIdFromObject: object =>
          object.id && object.__typename ? object.id + object.__typename : null
      }),
      queryDeduplication: false
    });

  /** @function handleNewRooftopClient
   * @param {string} rooftopToken - Rooftop token that will be used to create the Apollo Client
   * @summary Creates Apollo Client for a rooftop.
   * @description Creates client for an rooftop with expiration handled.
   */
  const handleNewRooftopClient = rooftopToken =>
    new ApolloClient({
      link: from([
        authLink,
        setContext(async (_, { headers = {} }) => {
          if (
            !window.sessionStorage.getItem(
              `${jwtDecode(rooftopToken).id}_rooftop`
            )
          ) {
            window.sessionStorage.setItem(
              `${jwtDecode(rooftopToken).id}_rooftop`,
              rooftopToken
            );
          }
          const decoded = jwtDecode(
            window.sessionStorage.getItem(
              `${jwtDecode(rooftopToken).id}_rooftop`
            )
          );
          const { exp } = decoded;
          if (
            exp &&
            !moment
              .unix(exp)
              .subtract(10, "seconds")
              .isSameOrAfter(moment())
          ) {
            // HANDLE IF EXPIRED!!!!
            await refreshClientTokens();
          }

          headers[
            "x-rooftop-authorization"
          ] = `Bearer ${window.sessionStorage.getItem(
            `${jwtDecode(rooftopToken).id}_rooftop`
          )}`;

          return {
            headers
          };
        }),
        additionalHeadersLink,
        errorLink,
        split(
          operation => {
            const tree = operation.variables;
            const files = extractFiles(tree);
            const isFileQuery = files.length > 0;
            const treePath = objectPath(tree);
            files.forEach(({ path, file }) => treePath.set(path, file));
            return isFileQuery;
          },
          createUploadLink({ uri: config.graphqlUrl }),
          split(
            operation => operation.getContext().important,
            split(
              operation => operation.getContext().apiv2,
              from([
                createPersistedQueryLink({
                  useGETForHashedQueries: true,
                  sha256
                }),
                apiV2HttpLink
              ]),
              from([
                createPersistedQueryLink({
                  useGETForHashedQueries: true,
                  sha256
                }),
                apiV1HttpLink
              ])
            ),
            split(
              operation => operation.getContext().apiv2,
              apiV2BatchHttpLink,
              apiV1BatchHttpLink
            )
          )
        )
      ]),
      cache: new InMemoryCache({
        dataIdFromObject: object =>
          object.id && object.__typename ? object.id + object.__typename : null
      }),
      queryDeduplication: false
    });

  /** @function handleNewClients
   * @param {array} tokenStructure - Array containing organization and rooftop tokens that should have a Apollo Clients
   * @summary Initiates all of the states for the provider
   * @description Creates the clients and tokens for the organizations and rooftops, it will also create the tokenStructure
   */
  const handleNewClients = tokenStructure => {
    const localOrganizationClients = {};
    const localRooftopClients = {};
    const localOrganizationTokens = [];
    const localRooftopTokens = [];
    for (const tokenBranch of tokenStructure) {
      const localOrganizationToken = get(tokenBranch, "organizationToken", "");
      const localOrganizationId = jwtDecode(localOrganizationToken).id;
      localOrganizationClients[
        localOrganizationId
      ] = handleNewOrganizationClient(localOrganizationToken);
      localOrganizationTokens.push(localOrganizationToken);

      const organizationRooftops = get(tokenBranch, "rooftopTokens", []);
      for (const rooftopToken of organizationRooftops) {
        const rooftopId = jwtDecode(rooftopToken).id;
        localRooftopClients[rooftopId] = handleNewRooftopClient(rooftopToken);
        localRooftopTokens.push(rooftopToken);
      }
    }
    setOrganizationClients(
      Object.assign({}, organizationClients, localOrganizationClients)
    );
    setRooftopClients(Object.assign({}, rooftopClients, localRooftopClients));
    setOrganizationTokens(
      organizationTokens.concat(...localOrganizationTokens)
    );
    setRooftopTokens(rooftopTokens.concat(...localRooftopTokens));
    setTokenStructure(tokenStructure);
  };

  /** @function changeCurrentRooftopClient
   * @param {string} token - Token meant to replace the current rooftop token
   * @summary Replaces the current rooftop token
   * @description Replaces the current rooftop token from the localstorage and in the currentRooftopToken state
   */
  const changeCurrentRooftopClient = token => {
    window.localStorage.setItem("rooftopToken", token);
    setCurrentRooftopToken(token);
  };

  /** @function changeCurrentOrganizationClient
   * @param {string} token - Token meant to replace the current organization token
   * @summary Replaces the current organization token
   * @description Replaces the current organization token from the localstorage and in the currentOrganizationToken state
   */
  const changeCurrentOrganizationClient = token => {
    window.localStorage.setItem("organizationToken", token);
    setCurrentOrganizationToken(token);
  };

  /** @function getOrganizationRooftops
   * @param {string} organizationToken - Organization token for returning corresponding rooftops
   * @description Gives back the rooftopTokens that belong to a specific organization
   * @returns {array} Rooftoptokens that belong to an organization
   */
  const getOrganizationRooftops = organizationId => {
    const foundOrganization = tokenStructure.find(
      tokenBranch =>
        jwtDecode(tokenBranch.organizationToken).id === organizationId
    );

    return get(foundOrganization, "rooftopTokens", []);
  };

  /** @function resetClients
   * @summary Resets all the Apollo Clients and sets all state to the initial values.
   * @description Resets the store of all rooftop and organization Apollo Clients.
   * @example If user logs out ClientProvider should be cleaned to avoid having extra information stored.
   * Sets organizations and rooftops clients to the initial value.
   * Sets organizations and rooftops tokens to the initial value.
   * Sets organization and rooftop current token to the initial value.
   */
  const resetClients = () => {
    Object.keys(organizationClients).forEach(organizationId => {
      if (window.sessionStorage.getItem(`${organizationId}_organization`))
        window.sessionStorage.removeItem(`${organizationId}_organization`);
      organizationClients[organizationId].resetStore();
    });
    Object.keys(rooftopClients).forEach(rooftopId => {
      if (window.sessionStorage.getItem(`${rooftopId}_rooftop`))
        window.sessionStorage.removeItem(`${rooftopId}_rooftop`);
      rooftopClients[rooftopId].resetStore();
    });

    window.localStorage.removeItem("rooftopToken");
    window.localStorage.removeItem("organizationToken");

    setOrganizationClients({});
    setRooftopClients({});

    setOrganizationTokens([]);
    setRooftopTokens([]);

    setTokenStructure({});

    setCurrentRooftopToken(null);
    setCurrentOrganizationToken(null);
  };

  return (
    <ClientFactoryContext.Provider
      value={{
        changeCurrentOrganizationClient,
        changeCurrentRooftopClient,
        handleNewClients,
        getOrganizationRooftops,
        rooftopClients,
        organizationClients,
        setDefaultClients,
        currentOrganizationToken,
        currentOrganizationClient:
          organizationClients[
            currentOrganizationToken
              ? jwtDecode(currentOrganizationToken).id
              : ""
          ],
        currentRooftopClient:
          rooftopClients[
            currentRooftopToken ? jwtDecode(currentRooftopToken).id : ""
          ],
        currentRooftopToken,
        currentOrganizationInfo: currentOrganizationToken
          ? jwtDecode(currentOrganizationToken)
          : {},
        organizationTokens,
        rooftopTokens,
        resetClients,
        insertRooftopInfo,
        role: currentOrganizationToken
          ? jwtDecode(currentOrganizationToken).role
          : "",
        scopes: currentOrganizationToken
          ? jwtDecode(currentOrganizationToken).scopes
          : {}
      }}
    >
      {children}
    </ClientFactoryContext.Provider>
  );
};

ClientProvider.propTypes = {
  children: PropTypes.node
};
