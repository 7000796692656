import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

import { encodeCsv } from "Utils/CsvUtils";

const useStyles = makeStyles({
  circularProgress: {
    margin: 12
  }
});

const CsvDownloadRemote = ({
  downloadData,
  size = "small",
  color = "primary"
}) => {
  const [loading, setLoading] = useState(false);
  const { headers, fetchData, fileName, formatData } = downloadData;
  const classes = useStyles();

  const downloadCsv = data => {
    const link = document.createElement("a");
    link.target = "_self";
    link.download = fileName;
    link.href = encodeCsv(headers, formatData(data));
    link.click();
    link.remove();
  };

  const handleClick = async () => {
    setLoading(true);
    const { data } = await fetchData();
    downloadCsv(data);
    setLoading(false);
  };

  return loading ? (
    <CircularProgress
      size={24}
      className={size === "medium" ? classes.circularProgress : ""}
      color={color}
    />
  ) : (
    <IconButton color={color} size={size} onClick={handleClick}>
      <GetAppIcon />
    </IconButton>
  );
};

CsvDownloadRemote.propTypes = {
  downloadData: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string
};

export default CsvDownloadRemote;
