import React from "react";
import Fab from "@mui/material/Fab";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    width: props => (props.width ? `${props.width}px` : "100%"),
    height: props => (props.height ? `${props.height}px` : "initial"),
    background: theme.palette.grey[500],
    position: "relative",
    display: "flex"
  },
  image: {
    width: "inherit",
    height: "inherit",
    objectFit: props => (props.objectFit ? props.objectFit : "fill"),
    border: ({ border }) => (border ? "2px solid #9E9E9E" : null)
  },
  textOverImageContainer: {
    width: "inherit",
    height: "inherit",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  actionsContainer: {
    zIndex: 1,
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(1, 2),
    justifyContent: "flex-end"
  },
  fab: {
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white
    }
  },
  spacing: {
    marginLeft: theme.spacing(1)
  }
}));

export const Image = props => {
  const { src, alt, actions, textOverImage } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {actions && (
        <div className={classes.actionsContainer}>
          {actions.map(({ action, icon, href }, index) => (
            <Fab
              color="primary"
              size="small"
              onClick={action}
              key={index}
              className={classNames(classes.fab, {
                [classes.spacing]: index > 0
              })}
              component={href ? "a" : "button"}
              href={href}
              target={href && "_blank"}
              rel={href && "noopener noreferrer"}
            >
              {icon}
            </Fab>
          ))}
        </div>
      )}
      <img src={src} alt={alt} className={classes.image} />
      {textOverImage && (
        <div className={classes.textOverImageContainer}>
          <Typography variant="h6" align="center">
            {textOverImage}
          </Typography>
        </div>
      )}
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  objectFit: PropTypes.oneOf(["contain", "cover", "fill"]),
  width: PropTypes.number,
  textOverImage: PropTypes.string,
  height: PropTypes.number,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      icon: PropTypes.node.isRequired,
      href: PropTypes.string
    })
  )
};
