import { gql } from "@apollo/client";

import { AnalyticsFragments } from "./AnalyticsFragments";

export const CAR_REVENUE = gql`
  query CarRevenue($from: DateTime, $to: DateTime, $carIds: [String]) {
    getCarRevenue(input: { from: $from, to: $to, id_in: $carIds }) {
      code
      success
      message
      carRevenueAnalytics
    }
  }
`;

export const GET_CAR_EVENT_DATA = gql`
  query GetCarEventData($from: DateTime!, $to: DateTime!) {
    getCarEventData(where: { from: $from, to: $to }) {
      ...CarEventData
    }
  }
  ${AnalyticsFragments.carEventData}
`;
