const animation = {
  v: "5.7.5",
  fr: 30,
  ip: 0,
  op: 90,
  w: 640,
  h: 640,
  nm: "piggy_bank",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Null 1",
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [-18.246, 110.102, 0],
              to: [0, 2.324, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 3,
              s: [-18.246, 124.049, 0],
              to: [0, 0, 0],
              ti: [0, 2.324, 0]
            },
            { t: 7, s: [-18.246, 110.102, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: { a: 0, k: [46, 46, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Line Outlines",
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [254.555, 57.289, 0], ix: 2, l: 2 },
        a: { a: 0, k: [44.896, 5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [5, 5],
                    [84.791, 5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.277999997606, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Mask Outlines",
      parent: 10,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [254.556, 102.862, 0], ix: 2, l: 2 },
        a: { a: 0, k: [45.473, 45.823, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [45.223, 45.573],
                    [-45.223, 45.573],
                    [-45.223, -45.573],
                    [45.223, -45.573]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.976000019148, 0.8, 0.451000019148, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [45.473, 45.823], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Coin Outlines",
      tt: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.1], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 4.667,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.9], y: [0] },
              t: 39.667,
              s: [360]
            },
            { t: 60, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [327.802, 326.099, 0],
              to: [0, -38.667, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.9, y: 0 },
              t: 35,
              s: [327.802, 94.099, 0],
              to: [0, 0, 0],
              ti: [0, -38.667, 0]
            },
            { t: 60, s: [327.802, 326.099, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [59.401, 59.401, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -18.999],
                    [18.999, 0],
                    [0, 18.999],
                    [-18.999, 0]
                  ],
                  o: [
                    [0, 18.999],
                    [-18.999, 0],
                    [0, -18.999],
                    [18.999, 0]
                  ],
                  v: [
                    [34.401, 0],
                    [0, 34.401],
                    [-34.401, 0],
                    [0, -34.401]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.277999997606, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [59.401, 59.401], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -18.999],
                    [18.999, 0],
                    [0, 18.999],
                    [-18.999, 0]
                  ],
                  o: [
                    [0, 18.999],
                    [-18.999, 0],
                    [0, -18.999],
                    [18.999, 0]
                  ],
                  v: [
                    [34.401, 0],
                    [0, 34.401],
                    [-34.401, 0],
                    [0, -34.401]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.011764705882352941,
                  0.6627450980392157,
                  0.9568627450980393,
                  1
                ],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [66.196, 71.158], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -18.999],
                    [18.999, 0],
                    [0, 18.999],
                    [-18.999, 0]
                  ],
                  o: [
                    [0, 18.999],
                    [-18.999, 0],
                    [0, -18.999],
                    [18.999, 0]
                  ],
                  v: [
                    [34.401, 0],
                    [0, 34.401],
                    [-34.401, 0],
                    [0, -34.401]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [59.401, 59.401], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Taill Outlines",
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [423.89, 143.314, 0], ix: 2, l: 2 },
        a: { a: 0, k: [24.865, 65.072, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 21.942],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [21.941, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [19.864, -19.656],
                          [19.864, -20.072]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 4,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-3.084, -26.894],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [17.172, -6.623],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [21.97, 44.554],
                          [21.97, 44.138]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 8,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 21.942],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [21.941, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [19.864, -19.656],
                          [19.864, -20.072]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 55,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 21.942],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [21.941, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [19.864, -19.656],
                          [19.864, -20.072]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 58,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-3.084, -26.894],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [17.172, -6.623],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [21.97, 44.554],
                          [21.97, 44.138]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 21.942],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [21.941, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [19.864, -19.656],
                          [19.864, -20.072]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 64,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-3.084, -26.894],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [17.172, -6.623],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [21.97, 44.554],
                          [21.97, 44.138]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 67,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 21.942],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [21.941, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-19.864, 20.072],
                          [-19.864, 20.072],
                          [19.864, -19.656],
                          [19.864, -20.072]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.277999997606, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [44.865, 45.073], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Closed eye",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [362.772, 89.13, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-119.125, 34, 0], ix: 1, l: 2 },
        s: { a: 0, k: [217.391, 217.391, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-103, 34],
                    [-127.5, 34]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.215686289469, 0.215686289469, 0.278431372549, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 2,
      op: 8,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Eye Outlines 2",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [370.272, 84.818, 0], ix: 2, l: 2 },
        a: { a: 0, k: [16.911, 16.91, 0], ix: 1, l: 2 },
        s: { a: 0, k: [217.391, 217.391, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -7.959],
                    [7.959, 0],
                    [0, 7.959],
                    [-7.959, 0]
                  ],
                  o: [
                    [0, 7.959],
                    [-7.959, 0],
                    [0, -7.959],
                    [7.959, 0]
                  ],
                  v: [
                    [14.411, -0.001],
                    [0, 14.41],
                    [-14.411, -0.001],
                    [0, -14.41]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.277999997606, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.224000010771, 0.224000010771, 0.289999988032, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [16.911, 16.911], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 2,
      st: -8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Eye Outlines",
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [129.079, 126.118, 0], ix: 2, l: 2 },
        a: { a: 0, k: [16.911, 16.91, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -7.959],
                    [7.959, 0],
                    [0, 7.959],
                    [-7.959, 0]
                  ],
                  o: [
                    [0, 7.959],
                    [-7.959, 0],
                    [0, -7.959],
                    [7.959, 0]
                  ],
                  v: [
                    [14.411, -0.001],
                    [0, 14.41],
                    [-14.411, -0.001],
                    [0, -14.41]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.277999997606, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.224000010771, 0.224000010771, 0.289999988032, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [16.911, 16.911], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 8,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "Ear Outlines",
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 3,
              s: [-8.846]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 6,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 55,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 58,
              s: [9.453]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 61,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 64,
              s: [4.86]
            },
            { t: 67, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [143.49, 58.059, 0], ix: 2, l: 2 },
        a: { a: 0, k: [53.822, 93.873, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.098, -0.074],
                    [-10.376, -50.619]
                  ],
                  o: [
                    [0, 0],
                    [0, -3.099],
                    [12.373, 0.292],
                    [0, 0]
                  ],
                  v: [
                    [-28.822, 36.873],
                    [-28.822, -31.274],
                    [-23.184, -36.799],
                    [28.822, 20.346]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.277999997606, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [53.822, 61.873], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "Stroke Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [308.581, 567.439, 0], ix: 2, l: 2 },
        a: { a: 0, k: [233.334, 341.541, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 3,
              s: [100, 90, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 6,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 55,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 58,
              s: [100, 107, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 61,
              s: [100, 96, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 64,
              s: [100, 103, 100]
            },
            { t: 67, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [68.644, 0],
                          [0, 0],
                          [5.619, -63.54],
                          [0, 0],
                          [0, -2.19],
                          [0, 0],
                          [-2.189, 0],
                          [0, 0],
                          [-47.497, -7.43],
                          [0, 0],
                          [-8.014, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-8.015, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [0, 62.104],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-64.936, 0],
                          [0, 0],
                          [-2.189, 0],
                          [0, 0],
                          [0, 2.189],
                          [0, 0],
                          [13.813, 45.129],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [8.015, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [8.014, 0],
                          [0, 0],
                          [59.594, -9.323],
                          [0, 0],
                          [0, -68.644]
                        ],
                        v: [
                          [74.884, -150.962],
                          [-39.008, -150.962],
                          [-163.32, -37.16],
                          [-195.726, -37.16],
                          [-199.691, -33.195],
                          [-199.691, 22.587],
                          [-195.726, 26.553],
                          [-158.373, 26.553],
                          [-58.248, 113.59],
                          [-58.248, 136.45],
                          [-43.737, 150.962],
                          [-21.969, 150.962],
                          [-7.457, 136.45],
                          [-7.457, 115.085],
                          [43.333, 115.085],
                          [43.333, 136.45],
                          [57.845, 150.962],
                          [79.613, 150.962],
                          [94.124, 136.45],
                          [94.124, 113.59],
                          [199.691, -9.721],
                          [199.691, -26.154]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 3,
                    s: [
                      {
                        i: [
                          [68.644, 0],
                          [0, 0],
                          [2.492, -57.145],
                          [0, 0],
                          [-0.107, -1.959],
                          [0, 0],
                          [-2.186, 0.12],
                          [0, 0],
                          [-47.497, -7.43],
                          [0, 0],
                          [-8.014, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-8.015, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [0, 62.104],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-64.936, 0],
                          [0, 0],
                          [-2.186, 0.12],
                          [0, 0],
                          [0.107, 1.958],
                          [0, 0],
                          [16.007, 39.611],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [8.015, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [8.014, 0],
                          [0, 0],
                          [59.594, -9.323],
                          [0, 0],
                          [0, -68.644]
                        ],
                        v: [
                          [74.884, -150.962],
                          [-39.008, -150.962],
                          [-163.242, -26.357],
                          [-189.283, -23.529],
                          [-193.048, -19.765],
                          [-187.152, 30.132],
                          [-182.998, 33.462],
                          [-155.175, 30.363],
                          [-58.248, 113.59],
                          [-58.248, 136.45],
                          [-43.737, 150.962],
                          [-21.969, 150.962],
                          [-7.457, 136.45],
                          [-7.457, 115.085],
                          [43.333, 115.085],
                          [43.333, 136.45],
                          [57.845, 150.962],
                          [79.613, 150.962],
                          [94.124, 136.45],
                          [94.124, 113.59],
                          [199.691, -9.721],
                          [199.691, -26.154]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 6,
                    s: [
                      {
                        i: [
                          [68.644, 0],
                          [0, 0],
                          [5.619, -63.54],
                          [0, 0],
                          [0, -2.19],
                          [0, 0],
                          [-2.189, 0],
                          [0, 0],
                          [-47.497, -7.43],
                          [0, 0],
                          [-8.014, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-8.015, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [0, 62.104],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-64.936, 0],
                          [0, 0],
                          [-2.189, 0],
                          [0, 0],
                          [0, 2.189],
                          [0, 0],
                          [13.813, 45.129],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [8.015, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 8.015],
                          [0, 0],
                          [8.014, 0],
                          [0, 0],
                          [59.594, -9.323],
                          [0, 0],
                          [0, -68.644]
                        ],
                        v: [
                          [74.884, -150.962],
                          [-39.008, -150.962],
                          [-163.32, -37.16],
                          [-195.726, -37.16],
                          [-199.691, -33.195],
                          [-199.691, 22.587],
                          [-195.726, 26.553],
                          [-158.373, 26.553],
                          [-58.248, 113.59],
                          [-58.248, 136.45],
                          [-43.737, 150.962],
                          [-21.969, 150.962],
                          [-7.457, 136.45],
                          [-7.457, 115.085],
                          [43.333, 115.085],
                          [43.333, 136.45],
                          [57.845, 150.962],
                          [79.613, 150.962],
                          [94.124, 136.45],
                          [94.124, 113.59],
                          [199.691, -9.721],
                          [199.691, -26.154]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.277999997606, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [224.691, 175.962], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: "Yellow Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [344.624, 503.742, 0],
              to: [2, 1.333, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.9, y: 0 },
              t: 33,
              s: [356.624, 511.742, 0],
              to: [0, 0, 0],
              ti: [2, 1.333, 0]
            },
            { t: 55, s: [344.624, 503.742, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [169.126, 238.22, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 3,
              s: [90, 90, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 6,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 55,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 58,
              s: [107, 107, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 61,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 64,
              s: [104, 104, 100]
            },
            { t: 67, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [64.261, 0],
                    [0, 0],
                    [0, 64.26],
                    [0, 0],
                    [-64.261, 0],
                    [0, 0],
                    [0, -64.26],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-64.261, 0],
                    [0, 0],
                    [0, -64.26],
                    [0, 0],
                    [64.261, 0],
                    [0, 0],
                    [0, 64.26]
                  ],
                  v: [
                    [50.038, 126.97],
                    [-50.038, 126.97],
                    [-166.876, 10.133],
                    [-166.876, -10.133],
                    [-50.038, -126.97],
                    [50.038, -126.97],
                    [166.876, -10.133],
                    [166.876, 10.133]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.011764705882352941,
                  0.6627450980392157,
                  0.9568627450980393,
                  1
                ],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [167.126, 127.22], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: "White part Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [299.581, 395.439, 0], ix: 2, l: 2 },
        a: { a: 0, k: [199.941, 151.212, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [68.644, 0],
                    [0, 0],
                    [5.619, -63.54],
                    [0, 0],
                    [0, -2.19],
                    [0, 0],
                    [-2.189, 0],
                    [0, 0],
                    [-47.497, -7.43],
                    [0, 0],
                    [-8.014, 0],
                    [0, 0],
                    [0, 8.015],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-8.015, 0],
                    [0, 0],
                    [0, 8.015],
                    [0, 0],
                    [0, 62.104],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-64.936, 0],
                    [0, 0],
                    [-2.189, 0],
                    [0, 0],
                    [0, 2.189],
                    [0, 0],
                    [13.813, 45.129],
                    [0, 0],
                    [0, 8.015],
                    [0, 0],
                    [8.015, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 8.015],
                    [0, 0],
                    [8.014, 0],
                    [0, 0],
                    [59.594, -9.323],
                    [0, 0],
                    [0, -68.644]
                  ],
                  v: [
                    [74.884, -150.962],
                    [-39.008, -150.962],
                    [-163.32, -37.16],
                    [-195.726, -37.16],
                    [-199.691, -33.195],
                    [-199.691, 22.587],
                    [-195.726, 26.553],
                    [-158.373, 26.553],
                    [-58.248, 113.59],
                    [-58.248, 136.45],
                    [-43.737, 150.962],
                    [-21.969, 150.962],
                    [-7.457, 136.45],
                    [-7.457, 115.085],
                    [43.333, 115.085],
                    [43.333, 136.45],
                    [57.845, 150.962],
                    [79.613, 150.962],
                    [94.124, 136.45],
                    [94.124, 113.59],
                    [199.691, -9.721],
                    [199.691, -26.154]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [199.941, 151.212], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 1,
      nm: "White Solid 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [320, 320, 0], ix: 2, l: 2 },
        a: { a: 0, k: [320, 320, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      sw: 640,
      sh: 640,
      sc: "#ffffff",
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export default animation;
