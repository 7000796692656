import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme => ({
  container: { padding: theme.spacing(3) },
  title: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  help: {
    color: theme.palette.grey[700]
  }
}));

export const NoResults = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Sorry, we couldn’t find an exact match.
      </Typography>
      <div className={classes.help}>
        Search Suggestions:
        <ul>
          <li>Check your search for typos</li>
          <li>Try other search terms</li>
        </ul>
      </div>
    </div>
  );
};
