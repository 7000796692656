import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(3)
  },
  header: {
    color: theme.palette.common.black
  }
}));

export const TextContainer = ({ title, subtitle, paragraphs }) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="h6" variant="h6" className={classes.header}>
        {title}
      </Typography>
      <Typography component="h6" variant="h6" className={classes.header}>
        {subtitle && subtitle}
      </Typography>
      <Typography component="p" variant="body1" className={classes.spacing}>
        {paragraphs.map((paragraph, index) => {
          return (
            <Fragment key={index}>
              {typeof paragraph === "function" ? (
                <Typography component={paragraph} key={index} paragraph />
              ) : (
                <Typography key={index} paragraph>
                  {paragraph}
                </Typography>
              )}
            </Fragment>
          );
        })}
      </Typography>
    </>
  );
};

TextContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  paragraphs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func])
  )
};
