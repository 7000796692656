import React from "react";
import { useSnackbar } from "notistack";
import { reduxForm, destroy } from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AchFormController } from "Components/Forms/FormControllers/AchFormController";
import { InfoDrawer } from "Components/Drawers/InfoDrawer";
import { DrawerContainer } from "Components/Drawers/DrawerContainer";
import { AchFormFields } from "Components/Forms/FormFields/AchFormFields";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";

const AchSettingDrawerForm = compose(
  reduxForm({
    form: "SETTINGS_ACH_FORM"
  })
)(({ handleCloseAchForm, handleSubmit, submitting }) => {
  return (
    <>
      <DrawerAppBar
        icon={
          <IconButton onClick={handleCloseAchForm} size="large">
            <CloseIcon />
          </IconButton>
        }
        title="Create ACH"
        border={true}
      />
      <DrawerPaddingContainer>
        <AchFormFields compact />
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onSubmit={handleSubmit}
        submitText={submitting ? "submitting" : "submit"}
        disabled={submitting}
      />
    </>
  );
});

export const AchDrawer = connect(null, { destroy })(
  ({ open, onClose, destroy, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const onSubmitSuccess = () => {
      enqueueSnackbar("ACH Information successfully updated!", {
        variant: "success",
        autoHideDuration: 8000
      });
      onClose();
      destroy("SETTINGS_ACH_FORM");
    };

    const onSubmitFail = e => {
      enqueueSnackbar(e.message, {
        variant: "error",
        autoHideDuration: 8000
      });
    };
    return (
      <InfoDrawer open={open} onClose={onClose}>
        <DrawerContainer>
          <AchFormController
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={onSubmitFail}
            refetch={refetch}
          >
            {achFormControllerProps =>
              achFormControllerProps.loading ? (
                <SkeletonDrawer />
              ) : (
                <AchSettingDrawerForm
                  handleCloseAchForm={onClose}
                  {...achFormControllerProps}
                />
              )
            }
          </AchFormController>
        </DrawerContainer>
      </InfoDrawer>
    );
  }
);
