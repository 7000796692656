import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Hidden from "@mui/material/Hidden";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },
  select: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      marginRight: 0
    }
  }
}));

export const TableFooter = ({
  page,
  rowsPerPage,
  onRowsPerPageChange,
  onPreviousPage,
  onNextPage,
  hasNextPage,
  hasPreviousPage,
  totalItems
}) => {
  const classes = useStyles();

  const totalPages = (totalItems && Math.ceil(totalItems / rowsPerPage)) || 0;

  return (
    <Fragment>
      <Hidden mdDown>
        <Divider />
      </Hidden>
      <Grid container alignItems="center" className={classes.root}>
        {rowsPerPage && (
          <Fragment>
            <Typography component="span" color="textSecondary">
              Rows per page:
            </Typography>
            <FormControl
              className={classes.select}
              id={joyrideTutorialIds.tablePagination}
            >
              <Select
                variant="standard"
                value={rowsPerPage}
                inputProps={{ name: "rows-per-page", id: "rows-per-page" }}
                onChange={onRowsPerPageChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Fragment>
        )}
        <IconButton
          disabled={!hasPreviousPage}
          onClick={onPreviousPage}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        {typeof page === "number" && (
          <Typography component="span">
            {page + 1} {totalPages ? `of ${totalPages}` : ""}
          </Typography>
        )}
        <IconButton disabled={!hasNextPage} onClick={onNextPage} size="large">
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Fragment>
  );
};

TableFooter.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool
};
