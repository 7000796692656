import React, { useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import Badge from "@mui/material/Badge";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  openNavigation,
  closeNavigation
} from "Redux/Navigation/ActionCreators";
import { Logo } from "Components/Logo/Logo";
import { UserAvatarWithDropDown } from "Components/Avatar/UserAvatarWithDropDown";
import { NotificationsDrawer } from "Components/Drawers/NotificationsDrawer";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { RooftopSelector } from "Components/Header/RooftopSelector";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { checkAllPermissions } from "Utils/RooftopUtils";
import { RooftopDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { UniversalSearch } from "./universalSearch/UniversalSearch";
import GeneralAlert from "Components/Alerts/OwnerGeneralAlert";
import { OwnerAlertContext } from "Components/Utils/OwnerAlertProvider";

const useStyles = makeStyles(theme => ({
  dashboardHeader: {
    zIndex: ({ matches }) => (matches ? 1 : theme.zIndex.drawer + 1)
  },
  drawerIcon: {
    color: theme.palette.primary.contrastText,
    marginRight: ({ matches }) =>
      matches ? theme.spacing(0) : theme.spacing(2)
  },
  button: {
    fontWeight: "bold",
    marginRight: theme.spacing(2)
  },
  flexGrow: {
    flexGrow: 1
  },
  gutters: {
    padding: ({ matches }) => matches && theme.spacing(0)
  },
  notificationsIcon: {
    color: theme.palette.common.white
  },
  avatar: {
    margin: `0 ${theme.spacing(5)} 0 ${theme.spacing(4)}`
  },
  leftContainer: {
    height: theme.spacing(7.75),
    flex: 1
  },
  divider: {
    backgroundColor: "white",
    height: theme.spacing(4.375),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2)
  }
}));

export const OwnerDashboardHeader = connect(
  state => ({
    headerEnabled: state.fleet.header.headerEnabled,
    title: state.fleet.header.title,
    messageNotifications: state.fleet.sendbird.messageNotifications,
    navigationOpen: state.fleet.navigation.navigationOpen
  }),
  { openNavigation, closeNavigation }
)(
  ({
    openNavigation,
    closeNavigation,
    logoutUser,
    headerEnabled,
    title,
    messageNotifications,
    navigationOpen,
    ...rest
  }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles({ ...rest, matches });
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const { organizationTokens, scopes, role } = useContext(
      ClientFactoryContext
    );
    const { showAlert } = useContext(OwnerAlertContext);
    const showRooftopSelector = organizationTokens.length > 1;

    const handleNotificationsClose = () => setNotificationsOpen(false);
    const handleNotificationsOpen = () => setNotificationsOpen(true);

    return headerEnabled ? (
      <AppBar position="fixed" className={classNames(classes.dashboardHeader)}>
        <ToolBar classes={{ gutters: classes.gutters }}>
          {matches ? (
            <>
              {openNavigation && closeNavigation && (
                <IconButton
                  onClick={navigationOpen ? closeNavigation : openNavigation}
                  className={classes.drawerIcon}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              )}
              {showRooftopSelector ? (
                <RooftopSelector location="Dashboard" />
              ) : (
                <Typography
                  component="h1"
                  variant="h6"
                  className={classes.flexGrow}
                >
                  Dashboard
                </Typography>
              )}
              {role !== "p2p" && <UniversalSearch />}
              <Tooltip title="New Messages">
                <IconButton
                  onClick={handleNotificationsOpen}
                  className={classes.notificationsIcon}
                  size="large"
                >
                  <Badge
                    badgeContent={messageNotifications.length}
                    color="error"
                  >
                    <NotificationsIcon
                      className={classes.notificationsIcon}
                      id={joyrideTutorialIds.home.notificationsButton}
                    />
                  </Badge>
                </IconButton>
              </Tooltip>
              <NotificationsDrawer
                open={notificationsOpen}
                onClose={handleNotificationsClose}
                onOpen={handleNotificationsOpen}
              />
            </>
          ) : (
            <>
              <Grid
                container
                alignItems="center"
                className={classes.leftContainer}
              >
                {showRooftopSelector ? (
                  <>
                    {checkAllPermissions(
                      [RooftopDomainPermissionPaths.viewHeader],
                      scopes
                    ) ? (
                      <Logo
                        title={title}
                        containStretch
                        subTitle="FOR BUSINESS"
                      />
                    ) : (
                      <Logo title={title} containStretch />
                    )}
                    <Divider
                      orientation="vertical"
                      className={classes.divider}
                    />
                    <RooftopSelector />
                  </>
                ) : (
                  <>
                    <Logo title={title} />
                  </>
                )}
              </Grid>
              {role !== "p2p" && <UniversalSearch />}
              <Tooltip title="New Messages">
                <IconButton
                  onClick={handleNotificationsOpen}
                  className={classes.notificationsIcon}
                  size="large"
                >
                  <Badge
                    badgeContent={messageNotifications.length}
                    color="error"
                  >
                    <NotificationsIcon
                      className={classes.notificationsIcon}
                      id={joyrideTutorialIds.home.notificationsButton}
                    />
                  </Badge>
                </IconButton>
              </Tooltip>
              {!matches && (
                <div className={classes.avatar}>
                  <UserAvatarWithDropDown logoutUser={logoutUser} />
                </div>
              )}
              <NotificationsDrawer
                open={notificationsOpen}
                onClose={handleNotificationsClose}
                onOpen={handleNotificationsOpen}
              />
            </>
          )}
        </ToolBar>
        {showAlert && <GeneralAlert />}
      </AppBar>
    ) : null;
  }
);

OwnerDashboardHeader.propTypes = {
  logoutUser: PropTypes.func.isRequired
};
