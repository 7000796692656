import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

const useStyles = ({ cursor }) =>
  makeStyles(theme => ({
    containerPadding: {
      cursor,
      paddingTop: "8px"
    },
    divider: {
      margin: theme.spacing(1, 0),
      marginBottom: 0
    },
    hidden: {
      visibility: "hidden"
    },
    fullWidthHeightFix: {
      height: "48px"
    }
  }));

export const DrawerManageRow = ({
  icon,
  actionText,
  action,
  subtitle,
  onClick,
  href,
  disabled,
  fullWidthButton,
  tutorialId,
  id,
  cursor = "pointer"
}) => {
  const classes = useStyles({ cursor: disabled ? "default" : cursor })();

  if (fullWidthButton) {
    const linkProperties = {
      color: "inherit",
      target: "_blank",
      rel: "noopener noreferrer",
      underline: "none"
    };
    if (href) {
      linkProperties.href = href;
    }
    if (onClick) {
      linkProperties.onClick = onClick;
    }

    return (
      <Link
        data-component={actionText}
        {...linkProperties}
        underline="hover"
        id={tutorialId && tutorialId}
      >
        <Grid container className={classes.containerPadding}>
          <Grid
            container
            item
            xs={2}
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </Grid>
          <Grid container item xs={7} alignItems="center">
            <Typography component="span" variant="subtitle1">
              {actionText}
            </Typography>
          </Grid>
          <Grid
            container
            className={classes.fullWidthHeightFix}
            item
            xs={3}
            alignItems="center"
            justifyContent="center"
          >
            {action ? (
              <>{action}</>
            ) : href ? (
              <ChevronRightIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Grid item xs={10}>
              {subtitle && (
                <Typography component="span" variant="body2" display="block">
                  {subtitle}
                </Typography>
              )}
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
      </Link>
    );
  }

  const onClickHandler = onClick && !disabled ? onClick : () => {};
  const idProperty = id ? { id } : {};

  return (
    <div {...idProperty} onClick={onClickHandler}>
      <Grid container className={classes.containerPadding}>
        <Grid container item xs={2} justifyContent="center" alignItems="center">
          {icon}
        </Grid>
        <Grid container item xs={7} alignItems="center">
          <Typography component="span" variant="subtitle1">
            {actionText}
          </Typography>
        </Grid>
        <Grid container item xs={3} alignItems="center" justifyContent="center">
          {action ? (
            <>{action}</>
          ) : href ? (
            <Link
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
            >
              <IconButton sx={{ minHeight: "50px" }} size="large">
                {!disabled && <ChevronRightIcon />}
              </IconButton>
            </Link>
          ) : (
            <IconButton
              onClick={onClick}
              disabled={disabled}
              className={classNames({ [classes.hidden]: !onClick })}
              size="large"
              sx={{ minHeight: "50px" }}
            >
              {!disabled && <ChevronRightIcon />}
            </IconButton>
          )}
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Grid item xs={10}>
            {subtitle && (
              <Typography component="span" variant="body2" display="block">
                {subtitle}
              </Typography>
            )}
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

DrawerManageRow.propTypes = {
  icon: PropTypes.node,
  actionText: PropTypes.string.isRequired,
  action: PropTypes.node,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidthButton: PropTypes.bool
};
