import React, { useContext } from "react";
import { reduxForm, Field } from "redux-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { StepperContext } from "./CheckInFlowContainer";
import { DocumentDropzone } from "Components/Forms/Dropzone/DocumentDropzone";
import { requiredFields } from "Utils/FormHelpers";
import { AcceptEnum } from "Enums/AcceptEnum";

const useStyles = makeStyles(() => ({
  dropzoneContainer: {
    height: "260px"
  }
}));

export const CurrentCarCondition = reduxForm({
  form: "CHECK_IN_FLOW",
  destroyOnUnmount: false,
  validate: values => {
    return requiredFields(values, [
      "carFrontImage",
      "carBackImage",
      "carDriverSideImage",
      "carPassengerSideImage"
    ]);
  }
})(({ handleSubmit }) => {
  const classes = useStyles();
  const { nextStep, previousStep } = useContext(StepperContext);
  const onSubmit = handleSubmit(nextStep);

  const leftComponent = (
    <FlowContentContainer
      header="Confirm your car's current condition"
      nextStep={onSubmit}
      previousStep={previousStep}
      nextStepLabel="Continue"
      previousStepLabel="Back"
    >
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography component="h6" variant="h6" gutterBottom>
            Front
          </Typography>
          <div className={classes.dropzoneContainer}>
            <Field
              name="carFrontImage"
              component={DocumentDropzone}
              objectFit
              accept={[AcceptEnum.jpeg, AcceptEnum.png, AcceptEnum.pdf]}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography component="h6" variant="h6" gutterBottom>
            Rear
          </Typography>
          <div className={classes.dropzoneContainer}>
            <Field
              name="carBackImage"
              component={DocumentDropzone}
              objectFit
              accept={[AcceptEnum.jpeg, AcceptEnum.png, AcceptEnum.pdf]}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography component="h6" variant="h6" gutterBottom>
            Driver Side
          </Typography>
          <div className={classes.dropzoneContainer}>
            <Field
              name="carDriverSideImage"
              component={DocumentDropzone}
              objectFit
              accept={[AcceptEnum.jpeg, AcceptEnum.png, AcceptEnum.pdf]}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography component="h6" variant="h6" gutterBottom>
            Passenger Side
          </Typography>
          <div className={classes.dropzoneContainer}>
            <Field
              name="carPassengerSideImage"
              component={DocumentDropzone}
              objectFit
              accept={[AcceptEnum.jpeg, AcceptEnum.png, AcceptEnum.pdf]}
            />
          </div>
        </Grid>
      </Grid>
    </FlowContentContainer>
  );

  const rightComponent = (
    <TooltipContainer header="Why?" useIcon>
      <Tooltip
        paragraphs={[
          "If, on rare occasion, there happens to be an accident, we will use your images to help file a claim on your behalf."
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      useBottomDrawer
      leftComponent={leftComponent}
      rightComponent={rightComponent}
    />
  );
});
