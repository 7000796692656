import React from "react";
import { useMutation } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { isAlphaWithDash } from "Utils/Helpers";

import { UPDATE_INBOX_TEMPLATE } from "Mutations/InboxTemplate/InboxTemplateMutations";
import { ActionDialog } from "Components/Dialog/ActionDialog";
import { FormHelperText } from "@mui/material";

const useStyles = makeStyles(theme => ({
  shortCutInput: {
    marginBottom: theme.spacing(2)
  },
  inputAdornment: {
    marginRight: `0px`
  }
}));

const EDIT_MODAL_CONFIRM_TEXT = "SAVE";
const EDIT_MODAL_CANCEL_TEXT = "CANCEL";

const isOverCharLimit = str => str.length > 15;

const validateInput = str =>
  isOverCharLimit(str) || !isAlphaWithDash(str) || str === "";

export const InboxSettingsEditModal = ({
  open,
  handleOnClose,
  editReplyId,
  shortCut,
  message,
  currentOrganizationClient,
  handleOnShortCutChange,
  handleOnMessageChange
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [updateInboxTemplate, { loading: editLoading }] = useMutation(
    UPDATE_INBOX_TEMPLATE,
    {
      context: { apiv2: true },
      client: currentOrganizationClient
    }
  );
  const hasError = validateInput(shortCut);

  const handleEditReply = async () => {
    if (hasError) return;
    try {
      const { errors } = await updateInboxTemplate({
        variables: {
          input: {
            id: editReplyId,
            title: shortCut,
            message
          }
        }
      });

      if (errors) {
        enqueueSnackbar(errors[0].message.replace(/graphql error: /gim, ""), {
          variant: "error"
        });
        return;
      }

      handleOnClose();
    } catch (err) {
      enqueueSnackbar(
        err.message
          ? err.message.replace(/graphql error: /gim, "")
          : "Failed to submit.",
        {
          variant: "error"
        }
      );
    }
  };

  return (
    <ActionDialog
      open={open}
      onClose={() => !editLoading && handleOnClose()}
      title="Edit Quick Reply"
      maxWidth="md"
      content={
        <div>
          <TextField
            className={classes.shortCutInput}
            id="edit-short-cut"
            value={shortCut}
            onChange={handleOnShortCutChange}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={classes.inputAdornment}
                  position="start"
                >
                  /
                </InputAdornment>
              )
            }}
            fullWidth
            label="Label your shortcut"
            variant="filled"
            error={hasError}
            helperText={
              <>
                <FormHelperText>
                  Tip: Use a shortcut that is easy to understand and remember.
                  Max 15 characters, only letters and dashes allowed
                </FormHelperText>
              </>
            }
          />
          <TextField
            id="edit-reply-message"
            label="Message"
            variant="filled"
            value={message}
            onChange={handleOnMessageChange}
            multiline
            fullWidth
            rows={5}
          />
        </div>
      }
      actionLabel={EDIT_MODAL_CONFIRM_TEXT}
      actionVariant="contained"
      action={handleEditReply}
      cancelLabel={EDIT_MODAL_CANCEL_TEXT}
      cancelVariant="contained"
      cancel={handleOnClose}
      disabled={editLoading || hasError}
    />
  );
};

export default InboxSettingsEditModal;
