import { combineReducers } from "redux";
import find from "lodash/find";
import get from "lodash/get";

import { getErrorMessage } from "SendBird/SendBirdHelpers";
import { sb } from "SendBird/SendBirdUtil";

import {
  CONNECT_TO_SENDBIRD,
  CONNECT_TO_SENDBIRD_SUCCESS,
  CONNECT_TO_SENDBIRD_FAILURE,
  GET_SENDBIRD_CHANNELS,
  GET_SENDBIRD_CHANNELS_FAILURE,
  GET_SENDBIRD_CHANNELS_SUCCES,
  GET_CHANNEL_MESSAGES,
  GET_CHANNEL_MESSAGES_SUCCESS,
  GET_CHANNEL_MESSAGES_FAILURE,
  SEND_MESSAGE_TO_CHANNEL_SUCCESS,
  MESSAGE_RECEIVED,
  ON_CHANNEL_CHANGED,
  DESELECT_CHANNEL,
  SELECT_CHANNEL,
  START_CHANNEL,
  START_CHANNEL_SUCCESS,
  START_CHANNEL_FAILURE,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  UPDATE_INBOX_SEARCH,
  UPDATE_CHANNEL_MESSAGE_INPUT,
  UPDATE_INBOX_FILTERS,
  ADD_CHANNEL_RENTAL_STATUS
} from "./Actions.js";

const selectedChannel = (state = null, action) => {
  switch (action.type) {
    case SELECT_CHANNEL: {
      return action.channel;
    }
    case DESELECT_CHANNEL: {
      return null;
    }
    default:
      return state;
  }
};

const channelMessageInputs = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CHANNEL_MESSAGE_INPUT: {
      const { channel, message } = action;
      return Object.assign({}, state, {
        [channel.url]: {
          messagePreview: message
        }
      });
    }
    default: {
      return state;
    }
  }
};

const messages = (state = {}, action) => {
  switch (action.type) {
    case GET_CHANNEL_MESSAGES: {
      const { channel } = action;
      if (state[channel.url]) {
        return Object.assign({}, state, {
          [channel.url]: {
            ...state[channel.url],
            loading: true
          }
        });
      } else {
        return Object.assign({}, state, {
          [channel.url]: {
            loading: true,
            error: null,
            messages: []
          }
        });
      }
    }
    case GET_CHANNEL_MESSAGES_SUCCESS: {
      const { channel, messages } = action;

      return Object.assign({}, state, {
        [channel.url]: {
          loading: false,
          error: null,
          messages: [
            ...(state[channel.url]?.messages || []),
            ...[...messages].reverse()
          ]
        }
      });
    }
    case GET_CHANNEL_MESSAGES_FAILURE: {
      const { channel, error } = action;

      return Object.assign({}, state, {
        [channel.url]: {
          loading: false,
          error: error,
          messages: []
        }
      });
    }
    case GET_SENDBIRD_CHANNELS_SUCCES: {
      const { channels } = action;
      const stateObj = {};

      channels.forEach(channel => {
        stateObj[channel.url] = {
          loading: true,
          error: null,
          messages: []
        };
      });

      return stateObj;
    }
    case MESSAGE_RECEIVED: {
      const { channel, message } = action;
      const channelMessagesState = get(state, `[${channel.url}].messages`);

      if (channelMessagesState?.length) {
        return Object.assign({}, state, {
          [channel.url]: {
            ...state[channel.url],
            messages: [message, ...channelMessagesState]
          }
        });
      }
      return state;
    }
    case SEND_MESSAGE_TO_CHANNEL_SUCCESS: {
      const { channel, message } = action;
      const channelMessagesState = get(state, `[${channel.url}].messages`);

      if (channelMessagesState) {
        return Object.assign({}, state, {
          [channel.url]: {
            ...state[channel.url],
            messages: [message, ...channelMessagesState]
          }
        });
      }
      return state;
    }
    default:
      return state;
  }
};

const channels = (state = [], action) => {
  switch (action.type) {
    case ON_CHANNEL_CHANGED: {
      const { channel } = action;
      const channelExists = find(state, { url: channel.url });
      if (channelExists) {
        return state.map(currentChannel => {
          if (currentChannel.url === channel.url) {
            return channel;
          } else {
            return currentChannel;
          }
        });
      } else {
        return [...state, channel];
      }
    }
    case START_CHANNEL_SUCCESS: {
      const { channel } = action;
      const channelExists = find(state, o => o.url === channel.url);
      if (channelExists) {
        return state;
      } else {
        return [...state, channel];
      }
    }
    case GET_SENDBIRD_CHANNELS_SUCCES:
      return action.channels;
    case GET_SENDBIRD_CHANNELS_FAILURE:
      return [];
    default:
      return state;
  }
};

const channelStatuses = (state = {}, action) => {
  switch (action.type) {
    case ADD_CHANNEL_RENTAL_STATUS: {
      const { channelUrl, rentalStatus } = action;
      return { ...state, [channelUrl]: rentalStatus };
    }
    default:
      return state;
  }
};

const messageNotifications = (state = [], action) => {
  switch (action.type) {
    case GET_SENDBIRD_CHANNELS_SUCCES: {
      const { channels } = action;
      let messageNotifications = [];
      channels.forEach(channel => {
        if (sb.channelHasUnreadMessage(channel)) {
          const lastMessage = sb.channelGetLastMessage(channel);
          if (!sb.messageBelongsToCurrentUser(lastMessage)) {
            messageNotifications.push({ channel, ...lastMessage });
          }
        }
      });
      return messageNotifications;
    }
    case ON_CHANNEL_CHANGED: {
      const { channel } = action;
      if (sb.channelHasUnreadMessage(channel)) {
        const lastMessage = sb.channelGetLastMessage(channel);
        if (!sb.messageBelongsToCurrentUser(lastMessage)) {
          return [...state, { channel, ...lastMessage }];
        } else {
          return state;
        }
      } else {
        return state.filter(messageNotification => {
          const { channelUrl } = messageNotification;

          return channelUrl !== channel.url;
        });
      }
    }
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case GET_SENDBIRD_CHANNELS:
    case START_CHANNEL:
      return true;
    case GET_SENDBIRD_CHANNELS_FAILURE:
    case GET_SENDBIRD_CHANNELS_SUCCES:
    case START_CHANNEL_SUCCESS:
    case START_CHANNEL_FAILURE:
      return false;
    default:
      return state;
  }
};

const user = (state = { updating: false }, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return {
        ...state,
        updating: true
      };
    case UPDATE_USER_INFO_SUCCESS:
      return {
        updating: false,
        ...action.user
      };
    case CONNECT_TO_SENDBIRD_SUCCESS:
      return {
        ...state,
        ...action.user
      };
    case CONNECT_TO_SENDBIRD_FAILURE:
      return {
        updating: false
      };
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case CONNECT_TO_SENDBIRD_SUCCESS:
      return null;
    case GET_SENDBIRD_CHANNELS_FAILURE:
    case GET_CHANNEL_MESSAGES_FAILURE:
    case CONNECT_TO_SENDBIRD_FAILURE:
    case START_CHANNEL_FAILURE:
    case UPDATE_USER_INFO_FAILURE:
      return {
        ...action.error,
        message: getErrorMessage(action.error) || action.error.message
      };
    default:
      return state;
  }
};

const connected = (state = false, action) => {
  switch (action.type) {
    case CONNECT_TO_SENDBIRD_FAILURE:
      return false;
    case CONNECT_TO_SENDBIRD_SUCCESS:
      return true;
    default:
      return state;
  }
};

const connecting = (state = true, action) => {
  switch (action.type) {
    case CONNECT_TO_SENDBIRD:
      return true;
    case CONNECT_TO_SENDBIRD_FAILURE:
    case CONNECT_TO_SENDBIRD_SUCCESS:
      return false;
    default:
      return state;
  }
};

const inboxSearch = (state = "", action) => {
  switch (action.type) {
    case UPDATE_INBOX_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const inboxFilters = (
  state = { messageTypes: [], rentalStatus: [] },
  action
) => {
  switch (action.type) {
    case UPDATE_INBOX_FILTERS: {
      const { messageTypes, rentalStatus } = action.filters;
      return {
        messageTypes: Object.keys(messageTypes).filter(
          type => messageTypes[type]
        ),
        rentalStatus: Object.keys(rentalStatus).filter(
          status => rentalStatus[status]
        )
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  user,
  error,
  connected,
  connecting,
  loading,
  channels,
  messageNotifications,
  messages,
  channelMessageInputs,
  selectedChannel,
  inboxSearch,
  inboxFilters,
  channelStatuses
});
