import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import { ActionDialog } from "Components/Dialog/ActionDialog";

const useStyles = makeStyles(theme => ({
  confirmationText: {
    marginBottom: theme.spacing(2)
  },
  confirmActionButton: {
    color: theme.palette.error.main
  }
}));

const CONFIRM_MODAL_CONFIRM_TEXT = "YES, CONTINUE";
const CONFIRM_MODAL_CANCEL_TEXT = "NO, CANCEL";

const InboxSettingsConfirmationModal = ({
  confirmationModalOpen,
  handleOnClose,
  handleAction,
  handleCancel
}) => {
  const classes = useStyles();

  return (
    <ActionDialog
      open={confirmationModalOpen}
      onClose={handleOnClose}
      title="Are you sure?"
      content={
        <div>
          <Typography component="p" className={classes.confirmationText}>
            Changes made to your quick replies will be applied across your
            entire rooftop.
          </Typography>
          <Typography component="p">
            Are you sure you want to continue?
          </Typography>
        </div>
      }
      actionLabel={CONFIRM_MODAL_CONFIRM_TEXT}
      action={handleAction}
      actionClass={classes.confirmActionButton}
      actionColor="inherit"
      cancelLabel={CONFIRM_MODAL_CANCEL_TEXT}
      cancel={handleCancel}
    />
  );
};
export default InboxSettingsConfirmationModal;
