import React from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Badge from "@mui/material/Badge";
import Tab from "@mui/material/Tab";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper
  },
  active: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: "inline-block"
  },
  button: {
    lineHeight: 4,
    whiteSpace: "nowrap"
  },
  badge: {
    top: "5px",
    right: "-22px"
  }
}));

const LinkTab = React.forwardRef(({ children, badge, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <Link {...rest} ref={ref}>
      {badge ? (
        <Badge
          color={badge.color}
          badgeContent={badge.content}
          classes={{
            badge: classes.badge
          }}
        >
          {children}
        </Badge>
      ) : (
        children
      )}
    </Link>
  );
});

export const RouterTab = ({
  classes,
  disabled,
  icon,
  label,
  value,
  wrapped,
  to,
  badge,
  ...rest
}) => (
  <Tab
    {...rest}
    classes={classes}
    badge={badge}
    disabled={disabled}
    icon={icon}
    label={label}
    value={value}
    wrapped={wrapped}
    component={LinkTab}
    to={to}
  />
);
