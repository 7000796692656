import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { useTableState } from "Components/Tables/hooks/useTableState";
import { Table } from "Components/Tables/Table";
import { TableHead } from "Components/Tables/TableHead";
import { TableFooter } from "Components/Tables/TableFooter";
import { useTeamTable } from "Fleet/Dashboard/DashboardViews/Settings/Team/hooks/useTeamTable";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const TeamTable = ({
  initialColumns,
  onRowClick,
  fabOnClick,
  hideFab
}) => {
  const {
    first,
    page,
    // setPage,
    handleFirstChange,
    nextPage,
    previousPage
  } = useTableState({
    initialFirst: 10
  });
  const { currentOrganizationClient } = useContext(ClientFactoryContext);
  const { mappedData, loading } = useTeamTable({
    client: currentOrganizationClient,
    fetchPolicy: "cache-and-network"
  });

  // used for pagination
  const handleMemberSelection = () => {
    if (loading) return [];
    const infoBump = page * first;
    const paginatedInfo = mappedData.slice(infoBump, infoBump + first);
    if (paginatedInfo.length < 1) {
      let calcLastPage = Math.floor(infoBump / mappedData.length);
      if (calcLastPage + first >= mappedData.length) calcLastPage = 0;
      const errorBump = calcLastPage * first;
      return mappedData.slice(errorBump, errorBump + first);
    }
    return paginatedInfo;
  };

  return (
    <Paper>
      <TableHead
        tableName="Team Members"
        buttonComponent={
          hideFab ? null : (
            <Fab onClick={fabOnClick} color="primary">
              <AddIcon />
            </Fab>
          )
        }
        hideSearch
        hideButtonDivider
      />
      <Table
        columns={initialColumns}
        onRowClick={onRowClick}
        loading={loading}
        data={handleMemberSelection()}
      />

      <TableFooter
        rowsPerPage={first}
        onRowsPerPageChange={handleFirstChange}
        hasNextPage={mappedData.length > (page + 1) * first}
        hasPreviousPage={page > 0}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        page={page}
      />
    </Paper>
  );
};
