import React from "react";
import { Field, formValues } from "redux-form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import InputAdornment from "@mui/material/InputAdornment";
import map from "lodash/map";
import orderBy from "lodash/orderBy";

import { FormTextField } from "Components/Inputs/FormTextField";
import { FormSelect } from "Components/Inputs/FormSelect";
import {
  gas_options,
  normalize
} from "Components/Forms/FormControllers/hooks/useClaimsReimbursementFormController";

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.common.black
  },
  field: {
    marginBottom: theme.spacing(4)
  },
  gridSpacing: {
    marginBottom: theme.spacing(2)
  }
}));

export const ReimbursementDetailsFormFields = formValues("damagedAreas")(
  ({ damagedAreas }) => {
    const classes = useStyles();

    let show = null;
    return (
      <>
        {map(orderBy(damagedAreas, ["label"], ["asc"]), area => {
          switch (area.label) {
            case "Gas":
              show = (
                <Grid container spacing={3} className={classes.gridSpacing}>
                  <Grid item xs={6}>
                    <Field
                      name={area.fields[0].name}
                      label={area.fields[0].label}
                      component={FormSelect}
                      variant="filled"
                      margin="normal"
                      fullWidth
                      options={gas_options}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name={area.fields[1].name}
                      label={area.fields[1].label}
                      component={FormSelect}
                      variant="filled"
                      margin="normal"
                      fullWidth
                      options={gas_options}
                    />
                  </Grid>
                </Grid>
              );
              break;
            case "Mileage":
              show = (
                <Grid container spacing={3} className={classes.gridSpacing}>
                  <Grid item xs={6}>
                    <Field
                      name={area.fields[0].name}
                      label={area.fields[0].label}
                      component={FormTextField}
                      variant="filled"
                      margin="normal"
                      fullWidth
                      normalize={normalize}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name={area.fields[1].name}
                      label={area.fields[1].label}
                      component={FormTextField}
                      variant="filled"
                      margin="normal"
                      fullWidth
                      normalize={normalize}
                    />
                  </Grid>
                </Grid>
              );
              break;
            case "Others":
              show = (
                <Grid container spacing={3} className={classes.gridSpacing}>
                  <Grid item xs={6}>
                    <Field
                      name={area.fields[0].name}
                      label={area.fields[0].label}
                      component={FormTextField}
                      variant="filled"
                      margin="normal"
                      fullWidth
                      normalize={normalize}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name={area.fields[1].name}
                      label={area.fields[1].label}
                      component={FormTextField}
                      variant="filled"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              );
              break;
            default:
              show = (
                <Field
                  name={area.fields[0].name}
                  label={area.fields[0].label}
                  component={FormTextField}
                  variant="filled"
                  margin="normal"
                  fullWidth
                  normalize={normalize}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  className={classes.field}
                />
              );
              break;
          }

          return (
            <>
              <Typography
                component="h6"
                variant="h6"
                className={classes.header}
              >
                {area.label}
              </Typography>
              <Typography component="p" variant="body2">
                {area.description}
              </Typography>
              {show}
            </>
          );
        })}
      </>
    );
  }
);
