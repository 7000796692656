import React, { useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import RadioGroup from "@mui/material/RadioGroup";

import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { StatusReasonForm } from "./StatusReasonForm";
import { ListingStatusCopyEnum } from "Enums/StateEnums";
import { useEffect } from "react";

const snoozedReasonOptions = {
  maintenance: "Needs Regular Maintenance",
  anotherPlatform: "Car on another Platform",
  underRepair: "Car under repair from accident",
  expiredDocuments: "Expired documents (registration, inspection, insurance)",
  personalUse: "Personal Use",
  unhappy: "Unhappy with HyreCar"
};

const deletedReasonOptions = {
  carSold: "Car was sold",
  carAccident: "Car was involved in an accident/total loss",
  rentUnwanted: "Don't want to rent my car anymore",
  carIneligible:
    "Vehicle is ineligible (salvaged, ineligible for rideshare, etc.)",
  unhappy: "Unhappy with HyreCar",
  unhappyWithDriver: "Unhappy with Driver",
  others: "Others"
};

const useStyles = makeStyles(theme => ({
  radioGroupContainer: {
    width: "100%",
    marginBottom: theme.spacing(1.5)
  },
  labelContainer: {
    display: "flex",
    flex: 0,
    flexDirection: "column",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  label: {
    width: "100%",
    color: theme.palette.common.black
  },
  warning: {
    color: "red",
    marginLeft: theme.spacing(4),
    width: "70%"
  }
}));

export const StatusOptionsForm = connect(state => ({
  selectedState: formValueSelector("CAR_STATUS_EDIT")(state, "carStatus")
}))(
  ({
    input: { onChange, value, name },
    options,
    selectedState,
    setOptionSelected,
    initialCarStatus
  }) => {
    const classes = useStyles();
    const [snoozedReasonOpen, setSnoozedReasonOpen] = useState(false);
    const [removedReasonOpen, setRemovedReasonOpen] = useState(false);

    useEffect(() => {
      setOptionSelected(selectedState);
    }, [selectedState, setOptionSelected]);

    const handleChange = (e, value) => {
      setSnoozedReasonOpen(value === ListingStatusCopyEnum.snoozed);
      setRemovedReasonOpen(value === ListingStatusCopyEnum.deleted);
      onChange(e, value);
    };

    return (
      <RadioGroup
        onChange={handleChange}
        aria-label={name}
        value={value}
        name={name}
        className={classes.radioGroupContainer}
      >
        {options.map(({ label, value, description }, index) => (
          <span key={index}>
            <FormControlLabel
              disabled={value === initialCarStatus}
              key={label}
              value={value}
              control={<Radio color="primary" />}
              label={
                <div className={classes.labelContainer}>
                  <Typography className={classes.label}>
                    <b>{label}</b>
                  </Typography>
                  <Typography>{description}</Typography>
                </div>
              }
            />
            {value === ListingStatusCopyEnum.snoozed && snoozedReasonOpen && (
              <StatusReasonForm
                statusReasonOptions={snoozedReasonOptions}
                inputLabel="Select reason for snoozing vehicle"
                inputName="snoozedReason"
              />
            )}
            {value === ListingStatusCopyEnum.deleted && removedReasonOpen && (
              <StatusReasonForm
                statusReasonOptions={deletedReasonOptions}
                inputLabel="Select reason for deleting vehicle"
                inputName="deletedReason"
              />
            )}
          </span>
        ))}
      </RadioGroup>
    );
  }
);
