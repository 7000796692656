export function optionalChaining(func) {
  try {
    return func();
  } catch (e) {
    return undefined;
  }
}
export const requiredFields = (values, fields) => {
  const errors = {};
  fields.map(field => {
    errors[field] = !values[field] ? "Required" : undefined;
    return errors;
  });
  return errors;
};

export const convertToFloat = value => {
  return typeof value === "number" ? value : parseFloat(value);
};

export const toPascalCaseWithSingleDelimiter = str => {
  let delim;
  if (str.includes("-")) delim = "-";
  else delim = " ";
  str = str.toLowerCase().split(delim);
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(delim);
};

export const convertToInt = value => {
  return typeof value === "number" ? value : parseInt(value, 10);
};

// Speed up calls to hasOwnProperty
var hasOwnProperty = Object.prototype.hasOwnProperty;

export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;
  const valuesFilled = Object.keys(obj)
    .map(val => obj[val] || false)
    .reduce((accumulator, currentValue) => {
      return accumulator || currentValue;
    }, false);
  if (!valuesFilled) {
    return true;
  }
  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export const removeEmpty = obj => {
  Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
};

export const removeFalsy = obj => {
  Object.keys(obj).forEach(key => obj[key] || delete obj[key]);
};

export const flattenObject = function(ob) {
  let toReturn = {};

  for (let i in ob) {
    if (!Object.prototype.hasOwnProperty.call(ob, i)) continue;

    if (typeof ob[i] === "object") {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const getHttpReferrer = () => {
  const referrer = document.referrer;

  if (referrer === "" || !referrer)
    return {
      referrerOrigin: "",
      referrerParams: ""
    };

  const paramSplit = referrer.search(/\?/);

  return paramSplit === -1
    ? {
        referrerOrigin: referrer.slice(0, 499),
        referrerParams: ""
      }
    : {
        referrerOrigin: referrer.slice(0, paramSplit).slice(0, 499),
        referrerParams: referrer
          .slice(paramSplit, referrer.length)
          .slice(0, 499)
      };
};

export const getDecodedItemFromQueryParams = parameter => {
  if (!parameter) {
    return null;
  }
  const params = new URLSearchParams(window.location.search);
  if (params.get(parameter)) {
    return JSON.parse(window.atob(params.get(parameter)));
  } else return null;
};

export const shortenLastName = name => {
  return `${name.slice(0, 1).toUpperCase()}.`;
};

export const validateEmail = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
