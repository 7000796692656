/* eslint-disable react/display-name */
import React, { useContext } from "react";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { StepperFunctions } from "../ManualContainer";
import { CheckVin } from "./CheckVIN";

export const Eligibility = () => {
  const { nextStep } = useContext(StepperFunctions);
  const left = <CheckVin onSubmit={nextStep} />;

  return <ColumnFlowLayout leftComponent={left} useBottomDrawer />;
};
