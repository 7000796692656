import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import * as get from "lodash/get";

import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { analytics } from "./";

export const Identifier = () => {
  const { data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-only"
  });
  const user = get(data, "viewer.me", {});

  useEffect(() => {
    if (user.id) {
      analytics.identify(user.id, user, {
        context: {
          traits: {
            email: user.email
          }
        }
      });
    }
  }, [user]);

  return null;
};
