export const INBOX_MESSAGE_STATUS = {
  READ: {
    value: "READ",
    label: "Read"
  },
  UNREAD: {
    value: "UNREAD",
    label: "Unread"
  }
};

export const INBOX_STATUS_FILTERS = {
  INQUIRY: { value: "INQUIRY", label: "Inquiry" },
  APPLIED: { value: "APPLIED", label: "Requested" },
  PENDING_INSURANCE: { value: "PENDING_INSURANCE", label: "Accepted" },
  PENDING_PICKUP: { value: "PENDING_PICKUP", label: "Ready for Check-In" },
  ACTIVE: { value: "ACTIVE", label: "Active" },
  LATE: { value: "LATE", label: "Late" },
  COMPLETED: { value: "COMPLETED", label: "Completed" },
  CANCELLED: { value: "CANCELLED", label: "Cancelled" },
  APPLICATION_REJECTED: { value: "APPLICATION_REJECTED", label: "Rejected" },
  APPLICATION_AUTO_REJECTED: {
    value: "APPLICATION_AUTO_REJECTED",
    label: "Auto-Rejected"
  }
};

export const messageTypesFilters = Object.keys(INBOX_MESSAGE_STATUS).map(
  status => INBOX_MESSAGE_STATUS[status]
);

export const rentalStatusFilters = Object.keys(INBOX_STATUS_FILTERS).map(
  status => INBOX_STATUS_FILTERS[status]
);
