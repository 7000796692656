/* eslint-disable react/jsx-key */
import React, { useState, useMemo } from "react";
import Paper from "@mui/material/Paper";

import { Table } from "Components/Tables/Table";
import { TableHead } from "Components/Tables/TableHead";

import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useDebounce } from "Utils/useDebounce";
import DetailedInfoDrawer from "./DetailedInfoDrawer";
import LoadingComponent from "Fleet/Dashboard/DashboardViews/Financials/Tables/LoadingComponent";
import PropTypes from "react-metrics/lib/react/PropTypes";

const useStyles = makeStyles(() => ({
  paper: {
    border: "1px solid #E5E5E5"
  },
  tableHeadClass: {
    position: "relative"
  }
}));

const columns = [
  {
    title: "Transaction Date",
    field: "transactionDate"
  },
  {
    title: "Type",
    field: "type"
  },
  {
    title: "Driver",
    field: "driver"
  },
  {
    title: "Car",
    field: "car"
  },
  {
    title: "VIN",
    field: "vin"
  },
  {
    title: "Rental Period",
    field: "rentalPeriod"
  },
  {
    title: "Net",
    field: "net",
    align: "right"
  }
];

const mobileColumns = [
  {
    title: "Transaction Date",
    field: "transactionDate"
  },
  {
    title: "Driver",
    field: "driver"
  },
  {
    title: "Net",
    field: "net",
    align: "right"
  }
];

export const TransactionsTable = ({
  data,
  loading,
  buttonComponent,
  hideButtonDivider
}) => {
  const [search, setSearch] = useState("");
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const debouncedSearch = useDebounce(search, 500);
  const [moreInfoDrawer, setMoreInfoDrawer] = useState(false);
  const [moreInfoDrawerItem, setMoreInfoDrawerItem] = useState({});

  const handleItemClick = item => {
    setMoreInfoDrawer(true);
    setMoreInfoDrawerItem(item);
  };

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  const mapType = (typeString = "") => {
    const maxStringLength = 33;
    if (typeString && typeString.includes("Reimbursement (#")) {
      return {
        tooltip: typeString,
        value:
          typeString.substr(0, maxStringLength - 1) +
          (typeString.length > maxStringLength ? "..." : "")
      };
    }
    return typeString;
  };

  const mappedDataToTableRow = useMemo(() => {
    return (data?.viewTransactions || []).map(transaction => {
      return {
        transactionDate: moment
          .unix(transaction.paymentDate)
          .format("MM/DD/YY"),
        type: mapType(
          transaction.rentalPeriodType?.value
            ? transaction.rentalPeriodType.value
            : transaction.rentalPeriodType
        ),
        driver: transaction.driverName,
        car: transaction.car,
        vin: transaction.vin,
        rentalPeriod:
          transaction.rentalPeriodStart && transaction.rentalPeriodEnd
            ? `${moment(transaction.rentalPeriodStart).format(
                "MM/DD/YY"
              )} - ${moment(transaction.rentalPeriodEnd).format("MM/DD/YY")} (${
                transaction.days
              } day${transaction.days === 1 ? "" : "s"})`
            : null,
        net: transaction.netEarnings
      };
    });
  }, [data]);

  const getSearchResults = () => {
    if (!data || loading) return [];
    if (!debouncedSearch) return mappedDataToTableRow;

    const getlowerCaseText = tableCell =>
      tableCell.value ? tableCell.value.toLowerCase() : tableCell.toLowerCase();

    return mappedDataToTableRow.filter(row =>
      Object.keys(row).some(key => {
        const tableCell = row[key];
        const lowerCaseKeyText = tableCell && getlowerCaseText(tableCell);
        return (
          lowerCaseKeyText &&
          lowerCaseKeyText.includes(debouncedSearch.toLowerCase())
        );
      })
    );
  };

  const renderLoading = () => (
    <LoadingComponent subHeader="Just a moment. We're loading your transactions." />
  );

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableHead
        tableName="Transactions"
        search={search}
        onSearchChange={handleSearchChange}
        searchPlaceholder="Search"
        mobileSearchGrow
        loading={loading || search !== debouncedSearch}
        hideButtonDivider={hideButtonDivider}
        buttonComponent={buttonComponent}
        customClassName={classes.tableHeadClass}
      />
      <Table
        columns={matches ? mobileColumns : columns}
        data={getSearchResults()}
        noDataCellLabel="-"
        tableGrows
        disableRowPointer
        disableHover
        loading={loading}
        onRowClick={matches ? handleItemClick : null}
        loadingComponent={renderLoading}
        paginate
      />
      {matches && (
        <DetailedInfoDrawer
          open={moreInfoDrawer}
          onClose={() => setMoreInfoDrawer(false)}
          item={moreInfoDrawerItem}
        />
      )}
    </Paper>
  );
};

TransactionsTable.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.boolean,
  buttonComponent: PropTypes.element,
  hideButtonDivider: PropTypes.bool
};
