import React, { Fragment, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import GoogleMap from "Driver/Components/General/GoogleMap";
import withGoogleMaps from "Driver/utils/hocs/withGoogleMaps";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import LocationMarker from "./LocationMarker";
import { ThemeProvider } from "Styles/ThemeProvider";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "350px"
  }
});

export const LocationMap = ({ geo_lat, geo_lng, className }) => {
  const classes = useStyles();
  const { getAsset } = useContext(FirebaseContext);
  const assetSrc = getAsset("googleMarkerIcon");

  return (
    <Fragment>
      <div className={classNames(className, classes.root)}>
        <GoogleMap
          center={{
            lng: geo_lng || 0,
            lat: geo_lat || 0
          }}
          onCenterChange={() => null}
          onZoomChange={() => null}
          options={{
            panControl: false,
            mapTypeControl: false,
            scrollwheel: false,
            zoomControl: true,
            fullscreenControl: false
          }}
        >
          <ThemeProvider>
            <LocationMarker
              lng={geo_lng || 0}
              lat={geo_lat || 0}
              assetSrc={assetSrc}
            />
          </ThemeProvider>
        </GoogleMap>
      </div>
    </Fragment>
  );
};

export default compose(
  withGoogleMaps(),
  connect((state, props) => {
    const geo_coords = {
      geo_lat: props.carId ? `cars.${props.carId}.geo_lat` : "geo_lat",
      geo_lng: props.carId ? `cars.${props.carId}.geo_lng` : "geo_lng"
    };
    return {
      geo_lat: props.selector(state, geo_coords.geo_lat) || 34.052359,
      geo_lng: props.selector(state, geo_coords.geo_lng) || -118.252743
    };
  })
)(LocationMap);

LocationMap.propTypes = {
  geo_lat: PropTypes.number,
  geo_lng: PropTypes.number,
  className: PropTypes.string
};
