import React from "react";
import { Field } from "redux-form";

import { FormRadioGroup } from "Components/Inputs/FormRadioGroup";

const CarSwitchReason = {
  BETTER_CAR_AVAILABLE: "Better Car Available",
  CAR_UNAVAILABLE: "Car Unavailable",
  DOCUMENT_ISSUES: "Document Issues",
  DRIVER_REQUEST: "Driver Request",
  MAINTENANCE: "Maintenance"
};

const reasons = Object.keys(CarSwitchReason).map(key => ({
  label: CarSwitchReason[key],
  value: key
}));

const CarSwitchReasonFormFields = () => {
  return <Field name="reason" component={FormRadioGroup} options={reasons} />;
};

export const defaultSnoozeReasons = [
  CarSwitchReason.CAR_UNAVAILABLE,
  CarSwitchReason.MAINTENANCE
];

export default CarSwitchReasonFormFields;
