import React, { useState } from "react";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles(theme => ({
  pickerContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  dialogContent: {
    padding: "8px 14px"
  },
  datePicker: {
    margin: "10px",
    minWidth: "150px"
  },
  dialogActions: {
    padding: "8px 14px"
  },
  cancelButton: {
    color: "rgba(0, 0, 0, 0.54)"
  }
}));

function CustomDateDialog({
  open,
  handleClose,
  handleUpdate,
  defaultStartDate,
  defaultEndDate,
  invalidDateMessage,
  oldestAcceptableDate,
  dateRangeMaxDays = 90
}) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment(defaultStartDate));
  const [endDate, setEndDate] = useState(moment(defaultEndDate));
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // Requirement to only show error on most the most recent input of
  // dates that violate the max date range
  const handleStartDate = date => {
    if (!date) {
      return;
    }
    const isAfterEndDate = date.isAfter(endDate);
    const differenceInDays = endDate.diff(date, "day");
    if (
      dateRangeMaxDays &&
      (differenceInDays > dateRangeMaxDays || isAfterEndDate)
    ) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
    setStartDate(date);
  };

  // Requirement to only show error on most the most recent input of
  // dates that violate the max date range
  const handleEndDate = date => {
    if (!date) {
      return;
    }
    const isBeforeStartDate = date.isBefore(startDate);
    const differenceInDays = date.diff(startDate, "day");
    if (
      dateRangeMaxDays &&
      (differenceInDays > dateRangeMaxDays || isBeforeStartDate)
    ) {
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
    setEndDate(date);
  };

  // Sanity check for all of our custom validation
  const validateSubmissionCapability = () => {
    if (!startDate || !endDate) return false;

    // Check for error states
    const dateErrors = endDateError || startDateError;
    if (dateErrors) return false;

    // Check to see that the dates are valid
    const bothDatesValid = startDate.isValid() && endDate.isValid();
    if (!bothDatesValid) return false;

    if (dateRangeMaxDays) {
      // Ensure that no dates have violated max date check
      const allowedRange = endDate.diff(startDate, "day") <= dateRangeMaxDays;
      if (!allowedRange) return false;
    }

    // Sanity acheived
    return true;
  };

  const handleDialogUpdate = () => {
    // One last sanity check before api call
    const canSubmit = validateSubmissionCapability();
    if (canSubmit) {
      handleUpdate(startDate, endDate);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {dateRangeMaxDays && (
        <DialogTitle>Select up to {dateRangeMaxDays} days</DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <div className={classes.pickerContainer}>
            <DatePicker
              renderInput={props => (
                <TextField
                  className={classes.datePicker}
                  helperText={
                    startDateError &&
                    dateRangeMaxDays &&
                    `Select a start date no greater than ${dateRangeMaxDays} days before ${endDate.format(
                      "MMM D, YYYY"
                    )}`
                  }
                  {...props}
                  error={startDateError}
                />
              )}
              autoOk
              label="Start Date"
              value={startDate}
              invalidDateMessage={invalidDateMessage}
              maxDateMessage={
                "Start date should not be later than the end date"
              }
              minDate={moment(oldestAcceptableDate)}
              maxDate={endDate ? endDate : moment()}
              onChange={date => handleStartDate(date)}
            />
            <DatePicker
              renderInput={props => (
                <TextField
                  className={classes.datePicker}
                  helperText={
                    endDateError &&
                    dateRangeMaxDays &&
                    `Select an end date no greater than ${dateRangeMaxDays} days after ${startDate.format(
                      "MMM D, YYYY"
                    )}`
                  }
                  {...props}
                  error={endDateError}
                />
              )}
              disableFuture
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="End Date"
              format="MM/DD/YYYY"
              value={endDate}
              inputProps={{
                "aria-label": "End Date"
              }}
              invalidDateMessage={invalidDateMessage}
              minDate={startDate ? startDate : false}
              minDateMessage={
                "End date should not be sooner than the start date"
              }
              onChange={date => handleEndDate(date)}
            />
          </div>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} className={classes.cancelButton}>
          Cancel
        </Button>
        <Button
          onClick={handleDialogUpdate}
          disabled={!validateSubmissionCapability()}
          color="primary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDateDialog;
