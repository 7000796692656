import { connect as reduxConnect } from "react-redux";
import {
  messagingModalHideAction,
  messagingModalShowAction
} from "Driver/redux/actions/messagingModalActions";

const withMessagingModal = Component =>
  reduxConnect(
    state => ({
      messagingModal: state.messagingModal
    }),
    dispatch => ({
      messagingModalHide: () => dispatch(messagingModalHideAction()),
      messagingModalShowChannel: channelId =>
        dispatch(messagingModalShowAction(channelId)),
      messagingModalShowList: () => dispatch(messagingModalShowAction())
    })
  )(Component);

export const WithMessagingModal = withMessagingModal(
  ({ children, ...messagingModalProps }) => {
    return children(messagingModalProps);
  }
);

export default withMessagingModal;
