import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";

import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { AchDrawer } from "Components/Drawers/AchDrawer";
import { PaymentDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import { PayoutSetupRequirements } from "Components/Paper/PayoutSetupRequirements";

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(0, 4)
  },
  infoContainer: {
    marginTop: theme.spacing(1.5)
  },
  accountInfo: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.black
  },
  bankInfo: {
    fontSize: theme.typography.fontSize
  },
  bankIcon: {
    color: theme.palette.common.black,
    fontSize: theme.spacing(5)
  },
  setupButton: {
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(6.5)
  }
}));

export const SettingsACHController = ({ payoutData, refetch }) => {
  const classes = useStyles();
  const { state } = useLocation();
  const [showAchForm, setShowAchForm] = useState(!!state?.showAchForm);
  const { getLink } = useContext(FirebaseContext);

  const { scopes } = useContext(ClientFactoryContext);

  const handleShowAchForm = () => setShowAchForm(true);
  const handleCloseAchForm = () => setShowAchForm(false);

  const last4 = get(
    payoutData,
    "getPayoutAccountInfo.payoutAccounts.stripePayoutInformation.bank.last4"
  );

  const bankName = get(
    payoutData,
    "getPayoutAccountInfo.payoutAccounts.stripePayoutInformation.bank.bankName",
    "No Bank"
  );

  const left = (
    <>
      <Grid container className={classes.header} spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4">
            Automated Clearing House
          </Typography>
          <Typography component="span" variant="subtitle1" gutterBottom>
            Setup your ACH to receive payment directly into your bank account.
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.infoContainer}
          >
            <Grid item>
              <AccountBalanceIcon className={classes.bankIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.accountInfo}>
                {last4 ? `*****${last4}` : "No Account"}
              </Typography>
              <Typography className={classes.bankInfo}>{bankName}</Typography>
            </Grid>
          </Grid>
          {renderByPermissions(
            <Button
              color="primary"
              onClick={handleShowAchForm}
              variant="contained"
              className={classes.setupButton}
            >
              {last4 ? `Update` : `Setup`}
            </Button>,
            [PaymentDomainPermissionPaths.createACH],
            scopes
          )}
        </Grid>
      </Grid>
    </>
  );

  const right = (
    <TooltipContainer header="Helpful Tips">
      <Tooltip
        header="Privacy"
        paragraphs={[
          "HyreCar does not store your personal information like social security, account and routing numbers, or Tax ID."
        ]}
      />
      <Tooltip
        header="Need help?"
        paragraphs={[
          props => (
            <div {...props}>
              If you have questions about updating your payout information or
              general inquiries, please contact{" "}
              <Link
                href={getLink("support")}
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                CUSTOMER SUPPORT
              </Link>
              .
            </div>
          )
        ]}
      />
    </TooltipContainer>
  );

  return (
    <>
      <AchDrawer
        refetch={refetch}
        open={showAchForm}
        onClose={handleCloseAchForm}
      />
      <PayoutSetupRequirements />
      <ColumnFlowLayout
        leftComponent={left}
        rightComponent={right}
        useBottomDrawer
      />
    </>
  );
};

SettingsACHController.propTypes = {
  payoutData: PropTypes.any,
  refetch: PropTypes.func
};
