import { useEffect } from "react";

export const LegacyRedirect = () => {
  useEffect(() => {
    const hostname = window.location.hostname;
    if (
      hostname === "fleet.app.hyrecar.com" ||
      hostname === "legacy.app.hyrecar.com"
    ) {
      window.location.replace("https://app.hyrecar.com");
    } else if (
      hostname === "staging.fleet.app.hyrecar.com" ||
      hostname === "staging.legacy.app.hyrecar.com"
    ) {
      window.location.replace("https://staging.app.hyrecar.com");
    }
  }, []);

  return null;
};
