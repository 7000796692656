import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { analytics } from "./";

export const PageTracker = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    analytics.page();
  }, [pathname]);

  return null;
};
