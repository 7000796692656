import React from "react";
import { Field } from "redux-form";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { FormSelect } from "Components/Inputs/FormSelect";
import { FormCheckboxGroup } from "Components/Inputs/FormCheckboxGroup";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(3)
  }
}));

const checkboxOptions = [
  {
    label: "Auto Theft",
    value: "autoTheft",
    id: "auto-theft"
  },
  {
    label: "Car Accident",
    value: "carAccident",
    id: "car-accident"
  },
  {
    label: "Car Damage",
    value: "carDamage",
    id: "car-damage"
  },
  {
    label: "Hit And Run",
    value: "hitAndRun",
    id: "hit-and-run"
  },
  {
    label: "Interior Damage",
    value: "interiorDamage",
    id: "interior-damage"
  },
  {
    label: "Mechanical Issues",
    value: "mechanicalIssues",
    id: "mechanical-issues"
  }
];

export const DamageSelectRentalFormFields = ({ options }) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="h6" variant="h6">
        Which rental are you reporting a damage claim for?
      </Typography>
      <Typography component="p" variant="subtitle2" className={classes.spacing}>
        Damage claims must be reported within 24 hours of Check-out.
      </Typography>
      <Field
        name="rentalId"
        component={FormSelect}
        options={options}
        fullWidth
        variant="filled"
        label="Select Rental"
        id="select-rental"
      />
      <div className={classes.spacing} />
      <Typography component="h6" variant="h6" gutterBottom>
        What type of damage?
      </Typography>
      <Field
        name="damage"
        component={FormCheckboxGroup}
        options={checkboxOptions}
        useErrorAsLabel
      />
    </>
  );
};

DamageSelectRentalFormFields.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};
