import axios from "axios";
import branch from "branch-sdk";

import config from "Config/config";

export async function ownerGetCarDeepLink(options) {
  const payload = {
    branch_key: config.branchKey,
    data: {
      $og_title: `Sharing a ${options.car.year} ${options.car.mark} ${options.car.model}`,
      $og_description: `${options.car.year} ${options.car.mark} ${
        options.car.model
      } for $${options.car.p2p_price_daily / 100}`,
      $og_image_url: `${options.car.car_photo}`,
      $fallback_url: `${config.auth0.redirectUri}offers/${options.car.id}`,
      offerId: `${options.car.id}`,
      utm_campaign: "",
      utm_source: options.utmSource,
      utm_medium: "share_car_link",
      source: "organic",
      domain: "app.hyrecar.com",
      page: options.pageName || "",
      component: options.componentName || ""
    }
  };
  const res = await axios.post("https://api2.branch.io/v1/url", payload);
  return res.data ? res.data.url : "";
}

export const initBranch = () => {
  branch.init(config.branchKey, (err, data) => {
    const offerId = data?.data_parsed?.offerId;

    if (err) {
      console.error("Error initializing Branch", err);
    } else if (offerId) {
      window.location.replace(`/offers/${offerId}`);
    }
  });
};
