import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { ShareCarCard } from "./ShareCarCard";

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  subtitle: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  shareCarCard: {
    width: "30%"
  }
}));

export const NoDataShareCars = ({ title, subtitle, cars }) => {
  const classes = useStyles();

  return (
    <div>
      {title && (
        <Typography
          component="h5"
          variant="h5"
          align="center"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          component="p"
          variant="subtitle1"
          align="center"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      )}
      {cars && Array.isArray(cars) && (
        <Grid container direction="row" justifyContent="space-between">
          {cars.map((car, index) => (
            <ShareCarCard key={index} carData={car} />
          ))}
        </Grid>
      )}
    </div>
  );
};

NoDataShareCars.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cars: PropTypes.array
};
