import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { reduxForm, Field } from "redux-form";
import { useSnackbar } from "notistack";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import moment from "moment";
import PropTypes from "prop-types";

import { FLEET_UPDATE_CARS } from "Mutations/Owner/OwnerMutations";
import { UPLOAD_FILE } from "Mutations/File/FileMutations";
import { CAR_SUMMARY } from "Queries/Car/Car";
import { DocumentDropzone } from "Components/Forms/Dropzone/DocumentDropzone";
import { FormDatePicker } from "Components/Inputs/FormDatePicker";
import { DrawerExpansionPanel } from "Components/Drawers/DrawerExpansionPanel";
import {
  CarVerificationEnum,
  DocumentTypeEnum,
  DocumentTypeKeysEnum,
  FileTypeEnum
} from "Enums/StateEnums";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { AsyncDropzoneLoadingContext } from "Components/Forms/Dropzone/AsyncDropzoneLoadingProvider";
import { UnsavedProgressDialogContext } from "Components/Utils/UnsavedProgressDialogProvider";

moment.suppressDeprecationWarnings = true;

export const STATUS_VALID = "STATUS_VALID";
export const STATUS_WARNING = "STATUS_WARNING";
export const STATUS_ERROR = "STATUS_ERROR";
const EXPIRING_THRESHOLD_DAYS = 45;

const useStyles = makeStyles(theme => ({
  container: {
    display: "block",
    width: "100%",
    height: "240px",
    margin: theme.spacing(1, 0, 2, 0)
  },
  root: {
    flexGrow: 1
  },
  documentContainer: {
    marginTop: theme.spacing(5)
  },
  warning: {
    color: theme.palette.warning.dark
  },
  error: { color: theme.palette.error.dark }
}));

const validateDateExpiration = (value, errorMessage) => {
  if (moment(value, "MM/DD/YYYY", true).isValid() && moment(value) < moment()) {
    return errorMessage;
  }
  return null;
};

const validateDateFormat = (value, errorMessage) => {
  if (!moment(value, "MM/DD/YYYY", true).isValid()) {
    return errorMessage;
  }
  return null;
};

const ListingDocumentsForm = reduxForm({
  form: "LISTING_DOCUMENTS",
  onChange: (v, d, props) => props.setUnsavedProgressExistsState(props.dirty),
  onSubmitSuccess: (r, d, props) => props.setUnsavedProgressExistsState(false),
  validate: values => {
    const errors = {};

    if (!values.registrationFile) {
      errors.registrationFile =
        "Please provide a document or select upload later.";
    }

    if (!values.registrationExpirationAt) {
      errors.registrationExpirationAt = "Required.";
    } else {
      const formatError = validateDateFormat(
        values.registrationExpirationAt,
        "Please enter a valid expiration date for the document in the format MM/DD/YYYY."
      );

      if (formatError) {
        errors.registrationExpirationAt = formatError;
      }

      const expiredError = validateDateExpiration(
        values.registrationExpirationAt,
        "Document cannot be expired."
      );

      if (expiredError) errors.registrationExpirationAt = expiredError;
    }

    if (!values.personalInsuranceFile) {
      errors.personalInsuranceFile =
        "Please provide a document or select upload later.";
    }

    if (!values.insuranceExpirationAt) {
      errors.insuranceExpirationAt = "Required.";
    } else {
      const formatError = validateDateFormat(
        values.insuranceExpirationAt,
        "Please enter a valid expiration date for the document in the format MM/DD/YYYY."
      );

      if (formatError) {
        errors.insuranceExpirationAt = formatError;
      }

      const expiredError = validateDateExpiration(
        values.insuranceExpirationAt,
        "Document cannot be expired."
      );

      if (expiredError) errors.insuranceExpirationAt = expiredError;
    }

    /*
      Leaving commented until we have a finalized product decision about how to handle
      inspection documents.
    */

    // if (!values.uberLyftInspectionFile) {
    //   errors.uberLyftInspectionFile =
    //     "Please provide a document or select upload later.";
    // }

    // if (!values.inspectionExpirationAt) {
    //   errors.inspectionExpirationAt = "Required.";
    // } else {
    //   const formatError = validateDateFormat(
    //     values.inspectionExpirationAt,
    //     "Please enter a valid expiration date for the document in the format MM/DD/YYYY."
    //   );

    //   if (formatError) {
    //     errors.inspectionExpirationAt = formatError;
    //   }

    //   const expiredError = validateDateExpiration(
    //     values.inspectionExpirationAt,
    //     "Document cannot be expired."
    //   );

    //   if (expiredError) errors.inspectionExpirationAt = expiredError;
    // }

    return errors;
  }
})(
  ({
    car,
    carVerified,
    expanded,
    handleSubmit,
    submitting,
    setDisplayUnsavedProgressDialog,
    unsavedProgressExists
  }) => {
    const { currentRooftopClient } = useContext(ClientFactoryContext);
    const [uploadFile] = useMutation(UPLOAD_FILE, {
      client: currentRooftopClient
    });
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { documentsLoading } = useContext(AsyncDropzoneLoadingContext);

    const asyncAction = async value => {
      try {
        const { data } = await uploadFile({
          variables: {
            file: value,
            type: FileTypeEnum.carDocument
          }
        });
        const url = get(data, "uploadFile.url");
        const id = get(data, "uploadFile.id");
        const contentType = get(data, "uploadFile.contentType");

        return {
          id,
          url,
          contentType
        };
      } catch (e) {
        console.error(e);
        enqueueSnackbar("Sorry, something went wrong. Please try again.", {
          variant: "error"
        });
        throw e;
      }
    };

    const getFormattedExpirationDate = date => {
      if (!date) {
        return { copy: "No Expiration Dates", status: "VALID" };
      } else {
        const expirationDate = moment(date);
        const formattedDate = expirationDate.format("ll");
        if (expirationDate.isBefore(moment())) {
          return { copy: `Expired ${formattedDate}`, status: STATUS_ERROR };
        } else if (
          expirationDate.isBefore(moment().add(EXPIRING_THRESHOLD_DAYS, "days"))
        ) {
          return { copy: `Expires ${formattedDate}`, status: STATUS_WARNING };
        } else {
          return { copy: `Expires ${formattedDate}`, status: STATUS_VALID };
        }
      }
    };

    const getDocumentExpirationClass = status => {
      switch (status) {
        case STATUS_ERROR:
          return classes.error;
        case STATUS_WARNING:
          return classes.warning;
        default:
          return "";
      }
    };

    const getDocumentExpirationComponent = document => (
      <Typography
        variant="subtitle2"
        gutterBottom
        className={getDocumentExpirationClass(document.status)}
      >
        {document.copy}
      </Typography>
    );

    const insuranceExpiration = getFormattedExpirationDate(
      car.insuranceExpirationAt
    );
    const registrationExpiration = getFormattedExpirationDate(
      car.registrationExpirationAt
    );
    const inspectionExpiration = getFormattedExpirationDate(
      car.inspectionExpirationAt
    );

    return (
      <DrawerExpansionPanel
        awaitingAsyncValues={documentsLoading}
        defaultExpanded={expanded}
        onCancel={
          unsavedProgressExists
            ? () => setDisplayUnsavedProgressDialog(true)
            : undefined
        }
        onSubmit={handleSubmit}
        submitting={submitting}
        title="Documents"
      >
        <div className={classes.root}>
          <div>
            <Typography variant="subtitle2" gutterBottom>
              Registration
            </Typography>
            {getDocumentExpirationComponent(registrationExpiration)}
            <div className={classes.container}>
              <Field
                name="registrationFile"
                component={DocumentDropzone}
                expirationStatus={registrationExpiration.status}
                objectFit
                accept="image/jpeg, image/png, application/pdf"
                asyncAction={asyncAction}
                documentType="registration"
                disableUpload={submitting}
              />
            </div>
            {!carVerified ? (
              <Field
                name="registrationExpirationAt"
                component={FormDatePicker}
                label="Expiration Date"
                fullWidth
                variant="inline"
                inputVariant="filled"
                disablePast
                disableToolbar
                autoOk
              />
            ) : null}
          </div>
          <div className={classes.documentContainer}>
            <Typography component="span" variant="subtitle2" gutterBottom>
              Insurance
            </Typography>
            {getDocumentExpirationComponent(insuranceExpiration)}
            <div className={classes.container}>
              <Field
                name="personalInsuranceFile"
                component={DocumentDropzone}
                expirationStatus={insuranceExpiration.status}
                objectFit
                accept="image/jpeg, image/png, application/pdf"
                asyncAction={asyncAction}
                documentType="insurance"
                disableUpload={submitting}
              />
            </div>
            {!carVerified ? (
              <Field
                name="insuranceExpirationAt"
                component={FormDatePicker}
                label="Expiration Date"
                fullWidth
                variant="inline"
                inputVariant="filled"
                disablePast
                disableToolbar
                autoOk
              />
            ) : null}
          </div>

          <div className={classes.documentContainer}>
            <Typography component="span" variant="subtitle2" gutterBottom>
              Inspection
            </Typography>
            {getDocumentExpirationComponent(inspectionExpiration)}
            <div className={classes.container}>
              <Field
                name="uberLyftInspectionFile"
                component={DocumentDropzone}
                expirationStatus={inspectionExpiration.status}
                objectFit
                accept="image/jpeg, image/png, application/pdf"
                asyncAction={asyncAction}
                documentType="inspection"
                disableUpload={submitting}
              />
            </div>
            {!carVerified ? (
              <Field
                name="inspectionExpirationAt"
                component={FormDatePicker}
                label="Expiration Date"
                fullWidth
                variant="inline"
                inputVariant="filled"
                disablePast
                disableToolbar
                autoOk
              />
            ) : null}
          </div>
        </div>
      </DrawerExpansionPanel>
    );
  }
);

export const ListingDocuments = ({ carId }) => {
  const location = useLocation();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    setDisplayUnsavedProgressDialog,
    setUnsavedProgressExistsState,
    unsavedProgressExists
  } = useContext(UnsavedProgressDialogContext);

  const [updateCar] = useMutation(FLEET_UPDATE_CARS, {
    client: currentRooftopClient
  });
  const { enqueueSnackbar } = useSnackbar();
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;
  const { data } = useQuery(CAR_SUMMARY, {
    fetchPolicy: "cache-only",
    variables: {
      id
    },
    client: currentRooftopClient
  });
  const car = get(data, "car", {});
  const documents = keyBy(get(car, "documents", {}), "type");
  const registration = documents[DocumentTypeEnum.registration];
  const insurance = documents[DocumentTypeEnum.personalInsurance];
  const inspection = documents[DocumentTypeEnum.inspection];

  const handleOnSubmit = async values => {
    try {
      await updateCar({
        variables: {
          input: [
            {
              id,
              registrationExpirationAt: moment(values.registrationExpirationAt),
              insuranceExpirationAt: moment(values.insuranceExpirationAt),
              inspectionExpirationAt: moment(values.inspectionExpirationAt),
              [DocumentTypeKeysEnum.registration]: get(
                values,
                "registrationFile.id",
                null
              ),
              [DocumentTypeKeysEnum.insurance]: get(
                values,
                "personalInsuranceFile.id",
                null
              ),
              [DocumentTypeKeysEnum.inspection]: get(
                values,
                "uberLyftInspectionFile.id",
                null
              )
            }
          ]
        }
      });
      enqueueSnackbar("Car documents successfully updated!", {
        variant: "success"
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <ListingDocumentsForm
      car={car}
      carVerified={car.verification === CarVerificationEnum.verified}
      expanded={location?.state?.expandListingDocuments}
      initialValues={{
        registrationExpirationAt: car.registrationExpirationAt
          ? moment(car.registrationExpirationAt).format("MM/DD/YYYY")
          : null,
        insuranceExpirationAt: car.insuranceExpirationAt
          ? moment(car.insuranceExpirationAt).format("MM/DD/YYYY")
          : null,
        inspectionExpirationAt: car.inspectionExpirationAt
          ? moment(car.inspectionExpirationAt).format("MM/DD/YYYY")
          : null,
        registrationFile: get(registration, "file", null),
        personalInsuranceFile: get(insurance, "file", null),
        uberLyftInspectionFile: get(inspection, "file", null)
      }}
      onSubmit={handleOnSubmit}
      setDisplayUnsavedProgressDialog={setDisplayUnsavedProgressDialog}
      setUnsavedProgressExistsState={setUnsavedProgressExistsState}
      unsavedProgressExists={unsavedProgressExists}
    />
  );
};

ListingDocuments.propTypes = {
  carId: PropTypes.string
};
