import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  labelBold: {
    fontWeight: theme.typography.fontWeightBold
  },
  formHelperText: {
    fontSize: "20px"
  }
}));

export const FormRadioGroup = ({
  input: { onChange, onBlur, value, name },
  meta: { touched, invalid, error },
  options,
  row
}) => {
  const classes = useStyles();

  return (
    <FormControl error={touched && invalid}>
      {touched && error && (
        <FormHelperText className={classes.formHelperText}>
          {error}
        </FormHelperText>
      )}
      <RadioGroup
        aria-label={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        row={row}
      >
        {options.map(({ label, value, info, id }, index) => (
          <span key={index}>
            <FormControlLabel
              key={label}
              value={value}
              control={
                <Radio
                  color="primary"
                  id={id || `damage-claim-gig-option-${index}`}
                />
              }
              label={label}
              classes={{ label: classes.labelBold }}
            />
            {info && info}
          </span>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

FormRadioGroup.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  }),
  name: PropTypes.string,
  row: PropTypes.bool
};
