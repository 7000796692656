import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import { HCBusinessHeader } from "Components/Header/HCBusinessHeader";
import { FlowStepper } from "Components/Flows/FlowStepper";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { CSVPickupAddressContainer } from "Fleet/Flows/CSV/CSVPickupAddressContainer";
import { Rooftop } from "../Manual/Rooftop/Rooftop";
import { CSVUploadContainer } from "Fleet/Flows/CSV/CSVUploadContainer";
import { CSVTableContainer } from "Fleet/Flows/CSV/CSVTableContainer";
import { CSVProtectionPriceContainer } from "Fleet/Flows/CSV/CSVProtectionPriceContainer";
import { CSVImageContainer } from "Fleet/Flows/CSV/CSVImageContainer";
import { CSVDocumentContainer } from "Fleet/Flows/CSV/CSVDocumentContainer";
import { ActionDialog } from "Components/Dialog/ActionDialog";
import { RouteEnum } from "Enums/RouteEnum";
import { useCSVFormController } from "Components/Forms/FormControllers/hooks/useCSVFormController";
import { useDialog } from "Components/Dialog/hooks/useDialog";
import { checkAllPermissions } from "Utils/RooftopUtils";
import { CarsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText
  },
  icon: {
    color: theme.palette.primary.contrastText
  }
}));

export const StepperContext = React.createContext();

export const CSVController = ({ dispatch }) => {
  const { onSubmit } = useCSVFormController(dispatch);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { scopes } = useContext(ClientFactoryContext);
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const [step, setStep] = useState(0);
  const [exitDialogOpen, setExitDialogOpened] = useState(false);
  const { dialogOpen, handleDialogClose } = useDialog(true);
  const [steps, setSteps] = useState([
    "Pickup Address",
    "Plan + Price",
    "CSV Upload",
    "Fix Errors",
    "Car Images",
    "Documents"
  ]);

  useEffect(() => {
    if (
      checkAllPermissions([CarsDomainPermissionPaths.csvRooftopSelect], scopes)
    ) {
      setSteps([
        "Rooftop",
        "Plan + Price",
        "CSV Upload",
        "Fix Errors",
        "Car Images",
        "Documents"
      ]);
    }
  }, [scopes]);

  const handleMobileDialogClose = () => {
    handleDialogClose();
    history.push(RouteEnum.manual);
  };

  const handleMobileDialogExit = () => {
    history.push(RouteEnum.baseRoute);
  };

  const handleExitDialogClose = () => setExitDialogOpened(false);

  const handleGoBack = () => {
    handleExitDialogClose();
    history.push(RouteEnum.baseRoute);
  };

  const nextStep = () => {
    if (step < steps.length) {
      window.scrollTo(0, 0);
      setStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    if (step !== 0) {
      window.scrollTo(0, 0);
      setStep(prev => prev - 1);
    } else {
      history.goBack();
    }
  };

  const handleExit = () => setExitDialogOpened(true);

  const getCurrentStep = step => {
    switch (step) {
      case 0:
        return <CSVPickupAddressContainer />;
      case 1:
        return <CSVProtectionPriceContainer />;
      case 2:
        return <CSVUploadContainer />;
      case 3:
        return <CSVTableContainer />;
      case 4:
        return <CSVImageContainer />;
      case 5:
        return <CSVDocumentContainer />;
      default:
        return <CSVPickupAddressContainer />;
    }
  };
  const getCurrentStepHC4B = step => {
    switch (step) {
      case 0:
        return <Rooftop listingType="CSV" />;
      case 1:
        return <CSVProtectionPriceContainer />;
      case 2:
        return <CSVUploadContainer />;
      case 3:
        return <CSVTableContainer />;
      case 4:
        return <CSVImageContainer />;
      case 5:
        return <CSVDocumentContainer />;
      default:
        return <Rooftop listingType="CSV" />;
    }
  };

  return matches ? (
    <ActionDialog
      open={dialogOpen}
      onClose={handleMobileDialogClose}
      title="CSV Import Unavailable"
      maxWidth="md"
      action={handleMobileDialogClose}
      actionLabel="Yes, continue"
      cancel={handleMobileDialogExit}
      cancelLabel="exit"
      content={
        <>
          <div>
            CSV Import is not available on mobile devices. To create listings
            with our CSV import tool, please use a desktop computer.
          </div>
          <br />
          <div>Would you like to use our Manual Import tool instead?</div>
        </>
      }
    />
  ) : (
    <>
      <HCBusinessHeader>
        <Button className={classes.button} onClick={handleExit}>
          Exit
        </Button>
      </HCBusinessHeader>
      <StepperContext.Provider value={{ nextStep, prevStep, onSubmit }}>
        <DashboardLayout hasAppBar>
          <FlowStepper steps={steps} step={step} header="CSV Import" />
          {checkAllPermissions(
            [CarsDomainPermissionPaths.csvRooftopSelect],
            scopes
          )
            ? getCurrentStepHC4B(step)
            : getCurrentStep(step)}
        </DashboardLayout>
      </StepperContext.Provider>
      <ActionDialog
        open={exitDialogOpen}
        onClose={handleExitDialogClose}
        title="Are you sure you want to exit?"
        content={
          <Typography component={"p"}>
            You may lose some of your progress. If only taks a few minutes to
            finish listing your cars.
          </Typography>
        }
        action={handleGoBack}
        actionLabel="Yes, Exit"
        cancel={handleExitDialogClose}
        cancelLabel="cancel"
        cancelOnRight
      />
    </>
  );
};
