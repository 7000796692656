/* eslint-disable react/display-name */
import React, { useContext } from "react";

import { InfoForm } from "./InfoForm";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { StepperFunctions } from "../ManualContainer";

export const Details = () => {
  const { nextStep, previousStep } = useContext(StepperFunctions);

  const left = <InfoForm onSubmit={nextStep} previousStep={previousStep} />;

  return <ColumnFlowLayout leftComponent={left} useBottomDrawer />;
};
