import React from "react";
import styled, { keyframes } from "styled-components";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

const kf = keyframes`
0% {
  transform: rotateZ(-20deg);
}
50% {
  transform: rotateZ(20deg) scaleY(1.2);
  background: rgb(163, 231, 252);
}
100% {
  transform: rotateZ(-20deg);
}
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(8)} 0`,
    textAlign: "center"
  },
  fullScreen: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  loadingText: {
    display: "block",
    textAlign: "center",
    width: "100%",
    marginTop: theme.spacing(3)
  }
}));

export const Chimes = styled(
  ({ loadingText, fullScreen, children, className }) => {
    const classes = useStyles();
    return (
      <div
        className={classNames(className, classes.root, {
          [classes.fullScreen]: fullScreen
        })}
      >
        <div>
          <div className="cssload-shimes">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          {loadingText && (
            <Typography
              component="span"
              variant="h5"
              className={classes.loadingText}
            >
              {loadingText}
            </Typography>
          )}
          {children}
        </div>
      </div>
    );
  }
)`
  .cssload-shimes {
    position: relative;
    display: flex;
    justify-content: center;
    width: 234px;
    margin: 0 auto;
  }

  .cssload-shimes,
  .cssload-shimes * {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .cssload-shimes span {
    display: inline-block;
    height: 32px;
    width: 32px;
    background: rgb(0, 157, 236);
    background-clip: padding-box;
    -o-background-clip: padding-box;
    -ms-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    border-radius: 1063px;
    transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    width: 5.5px;
    height: 64px;
    margin: 0 5.5px;
  }

  .cssload-shimes span:nth-child(1) {
    animation: ${kf} 1.45s 0.07s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.07s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.07s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.07s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.07s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }

  .cssload-shimes span:nth-child(2) {
    animation: ${kf} 1.45s 0.15s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.15s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.15s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.15s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.15s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }
  .cssload-shimes span:nth-child(3) {
    animation: ${kf} 1.45s 0.22s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.22s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.22s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.22s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.22s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }

  .cssload-shimes span:nth-child(4) {
    animation: ${kf} 1.45s 0.29s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.29s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.29s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.29s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.29s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }
  .cssload-shimes span:nth-child(5) {
    animation: ${kf} 1.45s 0.36s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.36s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.36s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.36s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.36s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }
  .cssload-shimes span:nth-child(6) {
    animation: ${kf} 1.45s 0.44s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.44s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.44s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.44s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.44s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }

  .cssload-shimes span:nth-child(7) {
    animation: ${kf} 1.45s 0.51s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.51s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.51s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.51s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.51s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }
  .cssload-shimes span:nth-child(8) {
    animation: ${kf} 1.45s 0.58s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.58s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.58s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.58s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.58s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }

  .cssload-shimes span:nth-child(9) {
    animation: ${kf} 1.45s 0.65s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.65s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.65s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.65s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.65s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }
  .cssload-shimes span:nth-child(10) {
    animation: ${kf} 1.45s 0.73s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -o-animation: ${kf} 1.45s 0.73s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -ms-animation: ${kf} 1.45s 0.73s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -webkit-animation: ${kf} 1.45s 0.73s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
    -moz-animation: ${kf} 1.45s 0.73s infinite
      cubic-bezier(0.005, 0.56, 0.58, 1.59);
  }
`;

Chimes.propTypes = {
  loadingText: PropTypes.string,
  fullScreen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};
