import { sb } from "SendBird/SendBirdUtil";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import {
  CONNECT_TO_SENDBIRD,
  CONNECT_TO_SENDBIRD_FAILURE,
  CONNECT_TO_SENDBIRD_SUCCESS,
  GET_SENDBIRD_CHANNELS,
  GET_SENDBIRD_CHANNELS_FAILURE,
  GET_SENDBIRD_CHANNELS_SUCCES,
  GET_CHANNEL_MESSAGES,
  GET_CHANNEL_MESSAGES_SUCCESS,
  GET_CHANNEL_MESSAGES_FAILURE,
  SEND_MESSAGE_TO_CHANNEL,
  SEND_MESSAGE_TO_CHANNEL_SUCCESS,
  SEND_MESSAGE_TO_CHANNEL_FAILURE,
  MESSAGE_RECEIVED,
  MARK_CHANNEL_AS_READ,
  READ_RECEIPT_UPDATED,
  ON_CHANNEL_CHANGED,
  ON_RECONNECT_STARTED,
  ON_RECONNECT_SUCCEEDED,
  ON_RECONNECT_FAILED,
  DESELECT_CHANNEL,
  SELECT_CHANNEL,
  START_CHANNEL,
  START_CHANNEL_SUCCESS,
  START_CHANNEL_FAILURE,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  UPDATE_INBOX_SEARCH,
  UPDATE_CHANNEL_MESSAGE_INPUT,
  ADD_CHANNEL_RENTAL_STATUS,
  UPDATE_INBOX_FILTERS
} from "./Actions";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { currentUserClient } from "Components/Utils/ApolloProvider";

export const connectingToSendBird = () => {
  return {
    type: CONNECT_TO_SENDBIRD
  };
};

export const connectToSendBirdSuccess = user => {
  return {
    type: CONNECT_TO_SENDBIRD_SUCCESS,
    user: user
  };
};

export const connectToSendBirdFailure = error => {
  return {
    type: CONNECT_TO_SENDBIRD_FAILURE,
    error: error
  };
};

export const getSendBirdChannelList = () => {
  return {
    type: GET_SENDBIRD_CHANNELS
  };
};

export const getSendBirdChannelListFailure = error => {
  return {
    type: GET_SENDBIRD_CHANNELS_FAILURE,
    error: error
  };
};

export const getSendBirdChannelListSuccess = (channels, userId) => {
  return {
    type: GET_SENDBIRD_CHANNELS_SUCCES,
    channels: channels,
    userId: userId
  };
};

export const getChannelMessages = channel => {
  return {
    type: GET_CHANNEL_MESSAGES,
    channel: channel
  };
};

export const getChannelMessagesSuccess = (channel, messages) => {
  return {
    type: GET_CHANNEL_MESSAGES_SUCCESS,
    channel: channel,
    messages: messages
  };
};

export const getChannelMessagesFailure = (channel, error) => {
  return {
    type: GET_CHANNEL_MESSAGES_FAILURE,
    channel: channel,
    error: error
  };
};

export const selectChannel = channel => dispatch => {
  if (!channel.messageListQuery) {
    sb.appendMessageListQuery(channel);
  }
  dispatch({
    type: SELECT_CHANNEL,
    channel: channel
  });
};

export const deselectChannel = () => {
  return {
    type: DESELECT_CHANNEL
  };
};

export const startChannel = () => {
  return {
    type: START_CHANNEL
  };
};

export const startChannelSuccess = channel => {
  return {
    type: START_CHANNEL_SUCCESS,
    channel: channel
  };
};

export const startChannelFailure = error => {
  return {
    type: START_CHANNEL_FAILURE,
    error: error
  };
};

export const sendMessageToChannel = () => {
  return {
    type: SEND_MESSAGE_TO_CHANNEL
  };
};

export const sendMessageToChannelSuccess = (channel, message) => {
  return {
    type: SEND_MESSAGE_TO_CHANNEL_SUCCESS,
    channel: channel,
    message: message
  };
};

export const sendMessageToChannelFailure = error => {
  return {
    type: SEND_MESSAGE_TO_CHANNEL_FAILURE,
    error: error
  };
};

export const messageReceived = (channel, message) => {
  return {
    type: MESSAGE_RECEIVED,
    channel: channel,
    message: message
  };
};

export const readReceiptUpdated = channel => {
  return {
    type: READ_RECEIPT_UPDATED,
    channel: channel
  };
};

export const onChannelChanged = channel => {
  return {
    type: ON_CHANNEL_CHANGED,
    channel: channel
  };
};

export const addChannelRentalStatus = (channelUrl, rentalStatus) => {
  return {
    type: ADD_CHANNEL_RENTAL_STATUS,
    channelUrl,
    rentalStatus
  };
};

export const onReconnectStarted = () => {
  return {
    type: ON_RECONNECT_STARTED
  };
};

export const onReconnectSucceeded = () => {
  return {
    type: ON_RECONNECT_SUCCEEDED
  };
};

export const onReconnectFailed = () => {
  return {
    type: ON_RECONNECT_FAILED
  };
};

export const markChannelAsRead = channel => {
  return {
    type: MARK_CHANNEL_AS_READ,
    channel: channel
  };
};

export const updateUserInfo = () => {
  return {
    type: UPDATE_USER_INFO
  };
};

export const updateUserInfoSuccess = user => {
  return {
    type: UPDATE_USER_INFO_SUCCESS,
    user: user
  };
};

export const updateUserInfoFailure = error => {
  return {
    type: UPDATE_USER_INFO_FAILURE,
    error: error
  };
};

export const updateInboxSearch = search => ({
  type: UPDATE_INBOX_SEARCH,
  search
});

export const updateInboxFilters = filters => ({
  type: UPDATE_INBOX_FILTERS,
  filters
});

export const updateChannelMessageInput = (channel, message) => ({
  type: UPDATE_CHANNEL_MESSAGE_INPUT,
  channel,
  message
});

export const asyncStartChannel = driverId => {
  return dispatch => {
    dispatch(startChannel());
    return sb
      .createGroupChannel(driverId)
      .then(channel => {
        dispatch(startChannelSuccess(channel));
        dispatch(selectChannel(channel));
      })
      .catch(error => {
        dispatch(startChannelFailure(error));
      });
  };
};

export const asyncSendMessageToChannel = (channel, message) => {
  return dispatch => {
    dispatch(sendMessageToChannel());
    currentUserClient
      .query({
        query: CURRENT_USER_QUERY,
        fetchPolicy: "cache-first"
      })
      .then(({ data }) => {
        const loggedInUser = get(data, "viewer.me");
        const messageParams = {
          message: message,
          data: JSON.stringify({
            senderUserId: get(loggedInUser, "id"),
            firstName: upperFirst(get(loggedInUser, "firstName")),
            lastName: upperFirst(get(loggedInUser, "lastName")),
            profilePhoto: get(loggedInUser, "profilePhoto.url")
          })
        };
        const channelData = JSON.stringify({
          assignedToUserId: get(loggedInUser, "id"),
          assignedToFirstName: upperFirst(get(loggedInUser, "firstName")),
          assignedToLastName: upperFirst(get(loggedInUser, "lastName"))
        });
        sb.sendMessageToChannel(channel, messageParams)
          .then(message => {
            sb.updateChannel(channel, channelData)
              .then(updatedChannel => {
                dispatch(sendMessageToChannelSuccess(updatedChannel, message));
              })
              .catch(error => {
                dispatch(sendMessageToChannelFailure(error));
              });
          })
          .catch(error => {
            dispatch(sendMessageToChannelFailure(error));
          });
      })
      .catch(error => {
        dispatch(sendMessageToChannelFailure(error));
      });
  };
};

export const asyncGetChannelMessages = channel => {
  return dispatch => {
    dispatch(getChannelMessages(channel));
    sb.getPreviousMessagesForChannel(channel)
      .then(messages => {
        dispatch(getChannelMessagesSuccess(channel, messages));
        sb.markChannelAsRead(channel);
      })
      .catch(error => {
        dispatch(getChannelMessagesFailure(channel, error));
      });
  };
};

export const getChannelList = userId => {
  return dispatch => {
    dispatch(getSendBirdChannelList());
    sb.getChannelList()
      .then(channels => {
        dispatch(getSendBirdChannelListSuccess(channels, userId));
      })
      .catch(error => {
        dispatch(getSendBirdChannelListFailure(error));
      });
  };
};

export const asyncConnectToSendBird = userId => {
  return dispatch => {
    dispatch(connectingToSendBird());
    return sb
      .connect(userId)
      .then(user => {
        sb.createChannelHandler(dispatch);
        dispatch(connectToSendBirdSuccess(user));
        dispatch(getChannelList(userId));
      })
      .catch(error => {
        dispatch(connectToSendBirdFailure(error));
      });
  };
};

export const asyncUpdateUserInfo = (nickname, profilePhoto) => {
  return dispatch => {
    dispatch(updateUserInfo());
    sb.updateUserInfo(nickname, profilePhoto)
      .then(user => {
        dispatch(updateUserInfoSuccess(user));
      })
      .catch(error => {
        dispatch(updateUserInfoFailure(error));
      });
  };
};
