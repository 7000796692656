export const RouteEnum = {
  root: "/",
  fleet: "/fleet",
  baseRoute: "/fleet/dashboard",
  rentals: "/fleet/dashboard/rentals",
  rentalsRequests: "/fleet/dashboard/rentals/requests",
  rentalsActive: "/fleet/dashboard/rentals/active",
  rentalsPast: "/fleet/dashboard/rentals/past",
  listings: "/fleet/dashboard/listings",
  listingsInventory: "/fleet/dashboard/listings/inventory",
  listingsProgress: "/fleet/dashboard/listings/progress",
  listingsAll: "/fleet/dashboard/listings/all",
  listingEdit: "/fleet/dashboard/listing/edit",
  analytics: "/fleet/dashboard/analytics",
  analyticsRevenue: "/fleet/dashboard/analytics/revenue",
  analyticsOccupancy: "/fleet/dashboard/analytics/occupancy",
  analyticsListings: "/fleet/dashboard/analytics/listings",
  financials: "/fleet/dashboard/financials",
  financialsTransactions: "/fleet/dashboard/financials/transactions",
  financialsListings: "/fleet/dashboard/financials/listings",
  financialsPayouts: "/fleet/dashboard/financials/payouts",
  financialsPayoutDetails: "/fleet/dashboard/financials/payout",
  inbox: "/fleet/dashboard/inbox",
  inboxAll: "/fleet/dashboard/inbox/all",
  inboxMine: "/fleet/dashboard/inbox/mine",
  resources: "/fleet/dashboard/resources",
  settings: "/fleet/dashboard/settings",
  settingsInbox: "/fleet/dashboard/settings/inbox",
  settingsProfile: "/fleet/dashboard/settings/profile",
  settingsPayment: "/fleet/dashboard/settings/payment",
  settingsRooftops: "/fleet/dashboard/settings/rooftops/",
  settingsRooftopsListing: "/fleet/dashboard/settings/rooftops/listing",
  settingsRooftopsEdit: "/fleet/dashboard/settings/rooftops/edit",
  settingsTeam: "/fleet/dashboard/settings/team",
  marketplace: "/fleet/dashboard/marketplace",
  manual: "/fleet/manual",
  checkIn: "/fleet/checkin",
  claims: "/fleet/claims",
  claimsDamage: "/fleet/claims/damage",
  claimsReimbursment: "/fleet/claims/reimbursment",
  claimsTickets: "/fleet/claims/tickets",
  csv: "/fleet/csv",
  login: "/login",
  selectListingType: "/fleet/select",
  offers: "/offers",
  driverReservations: "/driver/reservations"
};
