import { combineReducers } from "redux";

import {
  CLOSE_CREATE_ROOFTOP_DIALOG,
  OPEN_CREATE_ROOFTOP_DIALOG
} from "./Actions";

const createRooftopDialogToken = (state = false, action) => {
  switch (action.type) {
    case OPEN_CREATE_ROOFTOP_DIALOG:
      return action.token;
    case CLOSE_CREATE_ROOFTOP_DIALOG:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  createRooftopDialogToken
});
