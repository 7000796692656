import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    margin: `24px 8px`,
    padding: props => (props.padding ? props.padding : theme.spacing(3, 2)),
    border: props =>
      `1px solid ${
        props.warning ? theme.palette.warning.dark : theme.palette.divider
      }`
  }
}));

const BubbleContainer = ({ warning, children, padding }) => {
  const { container } = useStyles({ warning, padding });
  return <div className={container}>{children}</div>;
};

export default BubbleContainer;
