import React from "react";
import Hourglass from "../../Loading/Hourglass";

const LOADING_COPY =
  "Just a moment. We're updating your car listings to the new plan.";

const HEADER_COPY = "Updating...";

const LoadingStep = () => (
  <Hourglass header={HEADER_COPY} message={LOADING_COPY} />
);

export default LoadingStep;
