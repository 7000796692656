import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import randomMaterialColor from "random-material-color";
import PropTypes from "prop-types";

import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getAvatarSize } from "./utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  userAvatar: {
    width: props => props.avatarSize,
    height: props => props.avatarSize,
    fontSize: props => (props.size === "small" ? theme.spacing(1) : "1.25rem"),
    cursor: props => (props.onClick ? "pointer" : "default"),
    backgroundColor: props =>
      props.greyed ? theme.palette.grey[300] : props.color,
    color: props =>
      props.greyed
        ? theme.palette.white
        : theme.palette.getContrastText(props.color)
  }
}));

export const LetterAvatar = ({
  onClick,
  id,
  firstName,
  lastName,
  greyed,
  size
}) => {
  const firstInitial = firstName ? firstName.slice(0, 1).toUpperCase() : "";
  const lastInitial = lastName ? lastName.slice(0, 1).toUpperCase() : "";
  const initials = `${firstInitial}${lastInitial}`;
  const randomColor = randomMaterialColor.getColor({ text: initials });
  const classes = useStyles({
    color: randomColor,
    greyed: greyed,
    size,
    avatarSize: getAvatarSize(size),
    onClick
  });

  return (
    <Avatar className={classes.userAvatar} onClick={onClick} id={id}>
      {initials ? initials : <AccountCircleIcon />}
    </Avatar>
  );
};

LetterAvatar.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  greyed: PropTypes.bool
};
