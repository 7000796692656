import { gql } from "@apollo/client";

import { UserMutationFragments } from "./UserMutationFragments";

export const USER_SEND_EMAIL = gql`
  mutation UserSendEmail($data: AdminSendEmailInput!) {
    adminSendEmail(data: $data) {
      ...UserSendEmail
    }
  }
  ${UserMutationFragments.userSendEmail}
`;

export const SWITCH_ACCOUNT = gql`
  mutation SwitchAccount {
    switchAccount {
      ...LoginUserPayload
    }
  }
  ${UserMutationFragments.loginUserPayload}
`;

export const UPDATE_MISSING_INFO = gql`
  mutation UpdateMissingInfo($zip: String, $phone: String, $email: String) {
    updateProfile(input: { zip: $zip, phone: $phone, email: $email }) {
      ...UpdateProfilePayload
    }
  }
  ${UserMutationFragments.updateProfilePayload}
`;

export const UPDATE_OWNER_PROFILE = gql`
  mutation UpdateOwnerProfile(
    $firstName: String
    $lastName: String
    $phone: String
    $about: String
    $street: String
    $city: String
    $state: String
    $zip: String
    $address: String
    $lat: Float!
    $lng: Float!
  ) {
    updateProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        about: $about
        street: $street
        city: $city
        state: $state
        zip: $zip
        address: $address
        lat: $lat
        lng: $lng
      }
    ) {
      ...UpdateProfilePayload
    }
  }
  ${UserMutationFragments.updateProfilePayload}
`;

export const UPDATE_PROFILE_PHOTO = gql`
  mutation UpdateProfilePhoto($file: Upload!) {
    updateProfilePhoto(data: { file: $file }) {
      ...UpdateProfilePhotoPayload
    }
  }
  ${UserMutationFragments.updateProfilePhotoPayload}
`;

export const UPDATE_USERS_IMPACT_CLICK_ID = gql`
  mutation UpdateUsersImpactClickId($impactClickId: String) {
    updateUsersImpactClickId(impactClickId: $impactClickId) {
      code
      success
      message
    }
  }
`;

export const TRACK_LEAD_REFERRER = gql`
  mutation TrackLeadReferrer($input: TrackLeadReferrerInput!) {
    trackLeadReferrer(input: $input) {
      code
    }
  }
`;

export const IDENTIFY_MUTATION = gql`
  mutation Identify($referrer: ReferrerInput!) {
    identifyUser(referrer: $referrer) {
      code
    }
  }
`;

export const SUBMIT_ACH = gql`
  mutation SubmitAch($data: PayoutAccountInput!) {
    upsertPayoutAccount(data: $data) {
      id
      stripePayoutInformation {
        accountId
        bank {
          accountId
          last4
          bankName
        }
      }
    }
  }
`;

export const UPDATE_OWNER_PHONE = gql`
  mutation UpdateOwnerProfile($phone: String) {
    updateProfile(input: { phone: $phone }) {
      ...UpdateProfilePayload
    }
  }
  ${UserMutationFragments.updateProfilePayload}
`;

export const ACCEPT_PROTECTION_PLAN_CHANGE = gql`
  mutation AcceptProtectionPlanChange($date: DateTime!) {
    upsertUserLookupProtection(data: { protectionChangeAcceptedAt: $date }) {
      userLookup {
        protectionChangeAcceptedAt
      }
    }
  }
`;
