import React from "react";
import { cleaningReimbursement } from "Components/Forms/FormControllers/hooks/useClaimsReimbursementFormController";
import { ProtectionPlanEnum } from "Enums/StateEnums";

const PROTECTION_PLAN_CONFIG = {
  [ProtectionPlanEnum.premium_plus]: {
    title: "Premium Plus",
    cleaningReimbursement:
      cleaningReimbursement[ProtectionPlanEnum.premium_plus],
    gasReimbursement: "100%",
    milageReimbursement: "100%",
    impoundReimbursement: "100%",
    lostStolenKeyReimbursement: "Up to $300"
  },
  [ProtectionPlanEnum.premium]: {
    title: "Premium",
    cleaningReimbursement: cleaningReimbursement[ProtectionPlanEnum.premium],
    gasReimbursement: "85%",
    milageReimbursement: "85%",
    impoundReimbursement: "85%",
    lostStolenKeyReimbursement: "Up to $250"
  },
  [ProtectionPlanEnum.standard]: {
    title: "Standard",
    cleaningReimbursement: cleaningReimbursement[ProtectionPlanEnum.standard],
    gasReimbursement: "80%",
    milageReimbursement: "80%"
  },
  [ProtectionPlanEnum.basic]: {
    title: "Basic",
    cleaningReimbursement: cleaningReimbursement[ProtectionPlanEnum.basic],
    gasReimbursement: "65%",
    milageReimbursement: "65%"
  }
};

const ClaimsReimbursementProtectionPlanTips = ({ plan }) => {
  const config = PROTECTION_PLAN_CONFIG[ProtectionPlanEnum[plan.toLowerCase()]];
  return config ? (
    <>
      This rental was covered by our {config.title} protection plan. You’re
      eligible to receive:
      <ul>
        <li>Up to ${config.cleaningReimbursement} Cleaning reimbursement</li>
        <li>{config.gasReimbursement} Gas reimbursement</li>
        <li>{config.milageReimbursement} Mileage overage reimbursement</li>
        {config.impoundReimbursement ? (
          <li>{config.impoundReimbursement} Impound fees reimbursement</li>
        ) : null}
        {config.lostStolenKeyReimbursement ? (
          <li>
            {config.lostStolenKeyReimbursement} Lost/Stolen key reimbursement
          </li>
        ) : null}
      </ul>
    </>
  ) : null;
};

export default ClaimsReimbursementProtectionPlanTips;
