import { combineReducers } from "redux";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";

import {
  ENABLE_HEADER,
  DISABLE_HEADER,
  SET_TITLE,
  RESET_TITLE,
  RESET_SEARCH,
  ON_SEARCH_CHANGE,
  SET_FILTERS,
  RESET_FILTERS,
  SELECT_FILTER,
  DESELECT_FILTER
} from "./Actions";

const headerEnabled = (state = true, action) => {
  switch (action.type) {
    case ENABLE_HEADER:
      return true;
    case DISABLE_HEADER:
      return false;
    default:
      return state;
  }
};

const title = (state = "HyreCar", action) => {
  switch (action.type) {
    case SET_TITLE: {
      const { title } = action;
      return title;
    }
    case RESET_TITLE:
      return "HyreCar";
    default:
      return state;
  }
};

const search = (state = "", action) => {
  switch (action.type) {
    case ON_SEARCH_CHANGE:
      return action.value;
    case RESET_SEARCH:
      return "";
    default:
      return state;
  }
};

/*
 * @description - state should be an array of objects with the shape below.
 * {
 *  key: String,
 *  value: any,
 *  label: String
 * }
 */
const filters = (state = [], action) => {
  switch (action.type) {
    case SET_FILTERS:
      return action.filters;
    case RESET_FILTERS:
      return [];
    default:
      return state;
  }
};

const selectedFilters = (state = [], action) => {
  switch (action.type) {
    case SELECT_FILTER: {
      if (action.filter) {
        return uniqBy([...state, action.filter], "value");
      }
      return state;
    }
    case DESELECT_FILTER: {
      if (action.filter) {
        return [
          ...filter(
            state,
            currentFilter => currentFilter.value !== action.filter.value
          )
        ];
      }
      return state;
    }
    case RESET_FILTERS:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  headerEnabled,
  title,
  search,
  filters,
  selectedFilters
});
