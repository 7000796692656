import { HyrecarClient, HyrecarRequest } from "hyrecar-js";
import urlJoin from "url-join";
import config from "Config/config";

const { restUrl, ACCESS_TOKEN_NAME } = config;

const client = HyrecarClient(mapping => {
  const options = {
    method: mapping.method,
    cache: "force-cache",
    headers: mapping.headers,
    credentials: mapping.credentials,
    redirect: mapping.redirect,
    body: mapping.body
  };
  if (localStorage.getItem(ACCESS_TOKEN_NAME)) {
    options.headers[ACCESS_TOKEN_NAME] = localStorage.getItem(
      ACCESS_TOKEN_NAME
    );
  }
  if (mapping.disableAuth) {
    options.headers[ACCESS_TOKEN_NAME] = null;
  }
  return new HyrecarRequest(urlJoin(restUrl, mapping.url), options);
});

export default client;
