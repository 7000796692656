import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles(theme => ({
  filledInputRoot: {
    paddingBottom: theme.spacing(1),
    paddingTop: `${theme.spacing(3)} !important`
  },
  skeleton: {
    height: theme.spacing(7),
    width: "100%"
  }
}));

/*
 * Please read material-ui documentation here for Autocomplete api and the
 * PropTypes this component accepts. https://material-ui.com/api/autocomplete/
 * You can think of this component strictly as a mapping between the Autocomplete component
 * and the props passed from redux-forms Field component.
 *
 * label, input, meta, options, placeholder are props this component uses and aren't specific
 * to the Autocomplete component.
 */
export const FormSelectAutocomplete = props => {
  const {
    input,
    meta,
    label,
    options,
    placeholder,
    variant = "filled",
    multiple = true,
    disableCloseOnSelect,
    getOptionLabel,
    getOptionSelected,
    renderOption,
    autoComplete,
    disabled,
    groupBy,
    showSkeleton,
    id
  } = props;
  const { name, value, onChange, onBlur } = input;
  const { error, touched, valid } = meta;
  const hasError = touched && !valid;
  const classes = useStyles();

  //The second argument is where the actual onChange value is.
  const handleChange = (e, value) => onChange(value);

  //Need to call onBlur with the stored value because onBlur acts somewhat like onChange.
  const handleBlur = () => onBlur(value);

  return showSkeleton ? (
    <Skeleton
      variant="rectangular"
      classes={{ rectangular: classes.skeleton }}
    />
  ) : (
    <FormControl
      error={hasError}
      fullWidth
      id={id || "form-select-auto-complete"}
    >
      <Autocomplete
        value={value}
        name={name}
        onChange={handleChange}
        options={options}
        isOptionEqualToValue={getOptionSelected}
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        autoComplete={autoComplete}
        disabled={disabled}
        groupBy={groupBy}
        id={id || "form-select-auto-complete"}
        renderInput={params => (
          <TextField
            id={id || "form-select-auto-complete"}
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password"
            }}
            variant={variant}
            label={label}
            placeholder={placeholder}
            error={hasError}
            onBlur={handleBlur}
            fullWidth
          />
        )}
        ChipProps={{ color: "primary" }}
        classes={{
          inputRoot: classNames({
            [classes.filledInputRoot]:
              variant === "filled" && Array.isArray(value) && value.length > 0
          })
        }}
      />
      {hasError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

FormSelectAutocomplete.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    invalid: PropTypes.bool,
    touched: PropTypes.bool
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  multiple: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  autoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  groupBy: PropTypes.func
};
