import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import pick from "lodash/pick";

import { FLEET_UPDATE_CARS } from "Mutations/Owner/OwnerMutations";
import { CAR_SUMMARY } from "Queries/Car/Car";
import { DrawerExpansionPanel } from "Components/Drawers/DrawerExpansionPanel";
import { FormTextField } from "Components/Inputs/FormTextField";
import PropTypes from "prop-types";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { UnsavedProgressDialogContext } from "Components/Utils/UnsavedProgressDialogProvider";

const ListingInformationForm = reduxForm({
  form: "LISTING_INFORMATION",
  destroyOnUnmount: true,
  onChange: (v, d, props) => props.setUnsavedProgressExistsState(props.dirty),
  onSubmitSuccess: (r, d, props) => props.setUnsavedProgressExistsState(false),
  validate: values => {
    const errors = {};

    if (!values.mileage) {
      errors.mileage = "Required";
    }

    if (!values.licensePlate) {
      errors.licensePlate = "Required";
    }

    return errors;
  }
})(
  ({
    handleSubmit,
    submitting,
    setDisplayUnsavedProgressDialog,
    unsavedProgressExists
  }) => {
    return (
      <DrawerExpansionPanel
        onCancel={
          unsavedProgressExists
            ? () => setDisplayUnsavedProgressDialog(true)
            : undefined
        }
        onSubmit={handleSubmit}
        submitting={submitting}
        title="Car Information"
      >
        <Field
          name="mileage"
          component={FormTextField}
          fullWidth
          label="Odometer"
          variant="filled"
          margin="normal"
          normalize={value => {
            const numberRegex = /^[0-9]*$/g;

            if (numberRegex.test(value)) {
              return value;
            }
          }}
        />
        <Field
          name="description"
          component={FormTextField}
          fullWidth
          label="Add a description"
          variant="filled"
          margin="normal"
        />
        <Field
          name="licensePlate"
          component={FormTextField}
          fullWidth
          label="Plate Number"
          variant="filled"
          margin="normal"
        />
      </DrawerExpansionPanel>
    );
  }
);

export const ListingInformation = ({ carId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    setDisplayUnsavedProgressDialog,
    setUnsavedProgressExistsState,
    unsavedProgressExists
  } = useContext(UnsavedProgressDialogContext);

  const [updateCar] = useMutation(FLEET_UPDATE_CARS, {
    client: currentRooftopClient
  });
  const {
    data: { car }
  } = useQuery(CAR_SUMMARY, {
    variables: {
      id
    },
    client: currentRooftopClient
  });

  const onSubmit = async values => {
    try {
      await updateCar({
        variables: {
          input: [
            {
              id,
              ...values
            }
          ]
        }
      });
      enqueueSnackbar("Successfully Updated Car", {
        variant: "success"
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <ListingInformationForm
      onSubmit={onSubmit}
      initialValues={pick(car, ["mileage", "description", "licensePlate"])}
      setDisplayUnsavedProgressDialog={setDisplayUnsavedProgressDialog}
      setUnsavedProgressExistsState={setUnsavedProgressExistsState}
      unsavedProgressExists={unsavedProgressExists}
    />
  );
};

ListingInformation.propTypes = {
  carId: PropTypes.string
};
