import React, { useContext } from "react";
import { reduxForm } from "redux-form";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsReimbursmentController";
import { useClaimsReimbursementFormController } from "Components/Forms/FormControllers/hooks/useClaimsReimbursementFormController";
import { ClaimsReimbursmentDetailsTip } from "Fleet/Flows/Claims/ClaimsViews/ClaimsReimbursment/ClaimsReimbursmentDetailsTip";
import { ReimbursementDetailsFormFields } from "Components/Forms/FormFields/Claims/ReimbursementDetailsFormFields";

const ClaimsReimbursmentDetailsForm = reduxForm({
  form: "CLAIMS_REIMBURSEMENT",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      header="Details"
      nextStepLabel="continue"
      previousStepLabel="Back"
      previousStep={prevStep}
    >
      <ReimbursementDetailsFormFields />
    </FlowContentContainer>
  );
});

export const ClaimsReimbursmentDetails = () => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const {
    validate,
    onSubmitFail,
    warn
  } = useClaimsReimbursementFormController();

  const leftComponent = (
    <ClaimsReimbursmentDetailsForm
      onSubmit={nextStep}
      prevStep={prevStep}
      validate={validate}
      onSubmitFail={onSubmitFail}
      warn={warn}
    />
  );

  const rightComponent = (
    <TooltipContainer header="Helpful Tips">
      <ClaimsReimbursmentDetailsTip />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
};
