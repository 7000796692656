import React from "react";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: 0,
    width: "inherit",
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    zIndex: "1000"
  },
  inner: {
    display: "flex",
    padding: theme.spacing(2),
    justifyContent: "space-between"
  },
  bottomBar: {
    height: "50px"
  },
  circularProgress: {
    color: theme.palette.action.disabled
  }
}));

export const DrawerBottomBar = ({
  onCancel,
  cancelText,
  onSubmit,
  submitText,
  disabled,
  submitDisabled,
  useLoadingComponent,
  tutorialId
}) => {
  const classes = useStyles();

  const endIcon =
    useLoadingComponent &&
    (disabled ? (
      <CircularProgress className={classes.circularProgress} size={20} />
    ) : (
      <SendIcon />
    ));

  return (
    <>
      <div className={classes.bottomBar} />
      <div className={classes.root}>
        <div className={classes.inner}>
          {onCancel && cancelText && (
            <Button onClick={onCancel} disabled={disabled}>
              {cancelText}
            </Button>
          )}
          {onSubmit && submitText && (
            <Button
              onClick={onSubmit}
              disabled={disabled || submitDisabled}
              color="primary"
              variant="contained"
              fullWidth={!onCancel}
              endIcon={endIcon}
              id={tutorialId && tutorialId}
            >
              {submitText}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

DrawerBottomBar.propTypes = {
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  useLoadingComponent: PropTypes.bool
};
