import { parse } from "qs";

export const setLeadReferrer = () => {
  const params = parse(window.location.search);
  if (params.lead_source || params["?lead_source"])
    localStorage.setItem(
      "lead_source",
      params.lead_source || params["?lead_source"]
    );
};
