import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Field } from "redux-form";

import Car from "./selectCarOptions/CarOption";
import NoCarResults from "./selectCarOptions/NoCarResults";

const useStyles = makeStyles({
  container: {
    margin: 0
  },
  root: {
    padding: 0,
    flexGrow: 1,
    textAlign: "left",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});

const SelectCarOptions = ({ cars }) => {
  const classes = useStyles();
  return !cars.length ? (
    <NoCarResults />
  ) : (
    <Field
      name="car"
      component={({ input, ...rest }) => {
        return (
          <div className={classes.container}>
            {cars.map(car => (
              <Car key={car.id} car={car} {...input} {...rest} />
            ))}
          </div>
        );
      }}
    />
  );
};

SelectCarOptions.propTypes = {
  cars: PropTypes.array
};

export default SelectCarOptions;
