/* eslint-disable react/display-name */
import React, { useContext } from "react";

import withMetricsProp from "Analytics/hocs/withMetricsProp";
import { StepperFunctions } from "../ManualContainer";
import { ProtectionForm } from "./ProtectionForm";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { ColumnFlowLayout } from "../../../../Components/Layouts/ColumnFlowLayout";

export const Protection = withMetricsProp(() => {
  const { nextStep, previousStep } = useContext(StepperFunctions);

  const protection = (
    <ProtectionForm
      onSubmit={() => {
        analytics.track(
          AnalyticsEvents.label.owner.ownerICCProtectionComplete,
          {
            category: AnalyticsEvents.category.userInteraction,
            action: AnalyticsEvents.action.webConversion,
            label: AnalyticsEvents.label.owner.ownerICCProtectionComplete,
            property: JSON.stringify({
              category: "OWNER"
            }),
            value: "",
            context: ""
          }
        );
        nextStep();
      }}
      previousStep={previousStep}
    />
  );

  return (
    <ColumnFlowLayout
      leftComponent={protection}
      useBottomDrawer
    ></ColumnFlowLayout>
  );
});
