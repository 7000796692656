import React from "react";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 20,
    paddingRight: 20
  },
  textContainer: {
    flexGrow: 1,
    marginLeft: 18
  },
  topText: {
    marginBottom: 6,
    width: "20%",
    height: 10
  },
  centerText: {
    marginBottom: 10,
    width: "60%",
    height: 13
  },
  bottomText: {
    marginBottom: 18,
    width: "30%",
    height: 12
  },
  avatar: {
    width: 45,
    height: 45,
    marginTop: 5
  }
});

const CarListSkeleton = ({ amount }) => {
  const classes = useStyles();
  return [...Array(amount)].map((e, i) => (
    <div key={i} className={classes.container}>
      <Skeleton
        animation="wave"
        variant="circular"
        className={classes.avatar}
      />
      <div className={classes.textContainer}>
        <Skeleton animation="wave" className={classes.topText} />
        <Skeleton animation="wave" className={classes.centerText} />
        <Skeleton animation="wave" className={classes.bottomText} />
      </div>
    </div>
  ));
};

export default CarListSkeleton;
