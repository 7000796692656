import React, { useState, useEffect, useContext, useMemo } from "react";
import { reduxForm, Field } from "redux-form";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import jwtDecode from "jwt-decode";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import { AvatarDropzoneField } from "Components/Forms/Dropzone/AvatarDropzone";
import { RooftopEditFormController } from "Components/Forms/FormControllers/RooftopEditFormController";
import { FormTextField } from "Components/Inputs/FormTextField";
import { LocationAutoComplete } from "Components/Forms/Location/LocationAutoComplete";
import { RouteEnum } from "Enums/RouteEnum";
// We are leaving this code in, pending product input - as of 2/21
// leaving commented for v2
// import { ButtonRouter } from "Components/Buttons/ButtonRouter";
// import { RouteEnum } from "Enums/RouteEnum";
import { AchDrawer } from "Components/Drawers/AchDrawer";
import { SettingsACHController } from "Fleet/Dashboard/DashboardViews/Settings/ACH/SettingsACHForm";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { checkPermission } from "Utils/RooftopUtils";
import { RooftopDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    }
  },
  backToLink: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3)
    }
  },
  marginBottom: {
    marginBottom: "20px"
  },
  marginTop: {
    marginTop: theme.spacing(3)
  }
}));

const RooftopEditForm = compose(
  reduxForm({
    form: "ROOFTOP_EDIT_FORM",
    enableReinitialize: true,
    destroyOnUnmount: true
  })
)(({ handleSubmit, submitting, initialValues, loading, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Field
        name="image"
        component={AvatarDropzoneField}
        defaultSrc={initialValues.defaultSrc}
        loading={loading}
      />
      <Grid container spacing={4} className={classes.marginTop}>
        <Grid item xs={12} md={6} container spacing={3}>
          <Grid item xs={12}>
            <Field
              name="name"
              label="Rooftop Name"
              placeholder="First Name"
              fullWidth
              variant="filled"
              component={FormTextField}
              showSkeleton={loading}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="address"
              component={LocationAutoComplete}
              showSkeleton={loading}
              variant="filled"
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} spacing={3}>
          <Grid item xs={12}>
            <Field
              name="note"
              label="Bio"
              placeholder="Let drivers know what sets you apart. Do you offer special services like pickup and dropoff? Are you only available during certain days or hours?"
              component={FormTextField}
              multiline
              rows={5}
              fullWidth
              variant="filled"
              showSkeleton={loading}
              InputLabelProps={{ shrink: true }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        {!disabled && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={submitting}
            >
              {submitting ? "Saving..." : "Save Details"}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
});

//TODO: If rooftop token is null or undefined the user should be redirected back to rooftop settings.
export const RooftopSettingsEdit = compose(withRouter)(
  ({ payoutData, refetch }) => {
    const classes = useStyles();
    const [openAch, setOpenAch] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { id } = useParams();
    const { currentRooftopToken, scopes } = useContext(ClientFactoryContext);
    const currentRooftopId = currentRooftopToken
      ? jwtDecode(currentRooftopToken).id
      : "";
    const canUpdateRooftop = checkPermission(
      RooftopDomainPermissionPaths.updateRooftop,
      scopes
    );

    useEffect(() => {
      if (id !== currentRooftopId) {
        history.replace(
          `${RouteEnum.settingsRooftopsEdit}/${currentRooftopId}`
        );
      }
    }, [currentRooftopId, history, id]);

    const handleCloseAchForm = () => setOpenAch(false);
    const handleOpenAchForm = () => setOpenAch(true);

    const handleOnSubmitSuccess = () => {
      enqueueSnackbar("Rooftop profile updated.", {
        variant: "success"
      });
    };

    const handleOnSubmitFailure = () => {
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
    };

    const formTitle = useMemo(
      () => (canUpdateRooftop ? "Edit Rooftop" : "Rooftop Information"),
      [canUpdateRooftop]
    );

    return (
      <>
        <AchDrawer
          refetch={refetch}
          open={openAch}
          onClose={handleCloseAchForm}
        />
        {/* leaving commented for v2 */}
        {/* <ButtonRouter
        to={`${RouteEnum.settingsRooftopsListing}`}
        color="primary"
        className={classes.backToLink}
        startIcon={<ChevronLeftIcon />}
      >
        back
      </ButtonRouter> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container direction="column">
                <Typography
                  component="h1"
                  variant="h6"
                  className={classes.header}
                >
                  {formTitle}
                </Typography>
              </Grid>
              <RooftopEditFormController
                onSubmitSuccess={handleOnSubmitSuccess}
                onSubmitFailure={handleOnSubmitFailure}
              >
                {props => (
                  <RooftopEditForm
                    {...props}
                    handleOpenAchForm={handleOpenAchForm}
                    disabled={!canUpdateRooftop}
                  />
                )}
              </RooftopEditFormController>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SettingsACHController
              payoutData={payoutData}
              refetch={refetch}
              disabled={!canUpdateRooftop}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);
