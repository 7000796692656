import { firstAvailableYear } from "Variables/CarInfoVariables";
import { client } from "Components/Utils/ApolloProvider";
import { gql } from "@apollo/client";

export const validate = values => {
  let errors = {};

  if (!values.vin) {
    errors.vin = "Required";
  }
  if (!values.make) {
    errors.make = "Required";
  }
  if (!values.model) {
    errors.model = "Required";
  }
  if (!values.year) {
    errors.year = "Required";
  }
  if (!values.color) {
    errors.color = "Required";
  }
  if (!values.mileage) {
    errors.mileage = "Required";
  }
  if (!values.licensePlate) {
    errors.licensePlate = "Required";
  }
  const vinValidator = require("vin-validator");
  if (values.vin && !vinValidator.validate(values.vin)) {
    errors.vin = "Please enter a valid Vin number";
  }

  if (values.mileage && values.mileage < 0) {
    errors.mileage = "Must be at least 0";
  }

  if (
    values.year &&
    values.year.value &&
    values.year.value < firstAvailableYear
  ) {
    errors.year = `Must be at least ${firstAvailableYear}`;
  }

  return errors;
};

export const asyncCarInfoValidation = async values => {
  const res = (
    await client.query({
      query: gql`
        query CarVinValidator($vin: String!) {
          validateVin(vin: $vin) {
            success
            message
          }
        }
      `,
      variables: {
        vin: values.vin
      },
      fetchPolicy: "network-only"
    })
  ).data.validateVin;
  if (!res.success) {
    // eslint-disable-next-line
    throw {
      vin: res.message
    };
  }
};
