import React, { useContext } from "react";
import Link from "@mui/material/Link";

import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

export const FLOWS = {
  signup: "signup",
  sso: "sso"
};

const TOSCopy = ({ flow }) => {
  const { getLink } = useContext(FirebaseContext);

  const openNewTab = (url, event) => {
    analytics.track(event, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.buttonClicked,
      label: event,
      property: JSON.stringify({
        flow: AnalyticsEvents.flow.onboarding
      }),
      value: "",
      context: ""
    });
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const viewTOS = () => {
    openNewTab(getLink("tos"), AnalyticsEvents.label.auth.viewTOSClicked);
  };

  const viewPrivacyPolicy = () => {
    openNewTab(
      getLink("privacyPolicy"),
      AnalyticsEvents.label.auth.viewPrivacyPolicyClicked
    );
  };

  const flowCopy = flow === FLOWS.signup ? "signing up" : "clicking submit";

  const privacyPolicy = (
    <Link
      onClick={viewPrivacyPolicy}
      sx={{ cursor: "pointer" }}
      underline="hover"
    >
      Privacy Policy
    </Link>
  );

  const termsOfService = (
    <Link onClick={viewTOS} sx={{ cursor: "pointer" }} underline="hover">
      Terms of Service.
    </Link>
  );

  const googlePrivacyPolicy = (
    <Link
      href="https://policies.google.com/privacy"
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
    >
      Privacy Policy
    </Link>
  );

  const googleTermsOfService = (
    <Link
      href="https://policies.google.com/terms"
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
    >
      Terms of Service
    </Link>
  );

  return (
    <>
      {`By selecting the checkboxes above, I agree to receive text messages from
      HyreCar to the phone number provided. Message and data rates may apply.
      Message frequency varies. Text HELP for help. Text STOP to stop.`}
      <br />
      <br />
      {`By ${flowCopy}, I agree to HyreCar's `}
      {privacyPolicy} and {termsOfService}. This site is protected by reCAPTCHA
      and the Google {googlePrivacyPolicy} and {googleTermsOfService} apply.
    </>
  );
};

export default TOSCopy;
