export const AcceptEnum = {
  jpeg: "image/jpeg",
  png: "image/png",
  pdf: "application/pdf",
  csv: "text/csv",
  csvWindows: "application/vnd.ms-excel",
  excel2007:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  excel2003: ".xls"
};
