import get from "lodash/get";
import { useQuery } from "@apollo/client";

import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { currentUserClient } from "Components/Utils/ApolloProvider";

export const parseSendbirdChannelData = channel => {
  const channelData = channel.data
    ? channel.data
    : JSON.stringify({
        assignedToUserId: null,
        assignedToFirstName: null,
        assignedToLastName: null
      });
  const parsedData = JSON.parse(channelData);
  return {
    assignedToUserId: get(parsedData, "assignedToUserId"),
    assignedToFirstName: get(parsedData, "assignedToFirstName", ""),
    assignedToLastName: get(parsedData, "assignedToLastName", "")
  };
};

export const parseSendbirdMessageData = message => {
  const messageData = message.data
    ? message.data
    : JSON.stringify({
        firstName: null,
        lastName: null,
        profilePhoto: null
      });
  const parsedData = JSON.parse(messageData);
  return {
    firstName: get(parsedData, "firstName", ""),
    lastName: get(parsedData, "lastName", ""),
    profilePhoto: get(parsedData, "profilePhoto")
  };
};

export const useFilterChannelsByAssignedTo = (channels, selectedChannel) => {
  const { data, loading } = useQuery(CURRENT_USER_QUERY, {
    client: currentUserClient,
    fetchPolicy: "network-only"
  });
  let includesSelected = false;
  let filteredChannelsByAssignedTo = [];
  if (!loading) {
    const filterChannels = channels.filter(channel => {
      const { assignedToUserId } = parseSendbirdChannelData(channel);
      return assignedToUserId === get(data, "viewer.me.id");
    });
    filterChannels.forEach(channel => {
      if (get(channel, "url") === get(selectedChannel, "url")) {
        includesSelected = true;
      }
    });
    if (selectedChannel && !includesSelected) {
      filteredChannelsByAssignedTo = [selectedChannel].concat(filterChannels);
    } else {
      filteredChannelsByAssignedTo = filterChannels;
    }
  }
  return {
    filteredChannelsByAssignedTo,
    loading
  };
};
