import { useMemo, useContext } from "react";
import { useQuery } from "@apollo/client";
import get from "lodash/get";
import moment from "moment";

import {
  CarVerificationEnum,
  CarStatusEnum,
  DocumentTypeEnum as DocTypes
} from "Enums/StateEnums";
import { statesThatDontRequireInspection } from "Enums/StatesWithoutInspectionEnum";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CAR_SWITCH_FLEET } from "../FleetQueries";

const getDocError = car => {
  const requiresInspection = !statesThatDontRequireInspection.includes(
    car.location.state
  );
  for (const key in DocTypes) {
    if (requiresInspection && DocTypes[key] === DocTypes.inspection) {
      const document = car.documents.find(doc => doc.type === DocTypes[key]);
      if (!document || !document.fileId) return "Document not found";
    }
  }
  const dateFields = ["insuranceExpirationAt", "registrationExpirationAt"];
  if (requiresInspection) dateFields.push("inspectionExpirationAt");
  const today = moment()
    .utc()
    .startOf("day");
  for (const field of dateFields) {
    if (!car[field]) return "Missing document expiration dates";
    const documentDate = moment(car[field])
      .utc()
      .startOf("day");
    const diff = documentDate.diff(today, "days");
    if (diff < 0) {
      return "Expired documents";
    } else if (diff <= 14) {
      return "Documents expiring soon";
    }
  }
  return "";
};

const orderCars = (cars, prevCar) => {
  const related = [];
  const rest = [];
  const ineligible = [];
  cars.forEach(car => {
    if (car.ineligibleError) {
      ineligible.push(car);
    } else if (
      car.dailyPriceInCents === prevCar.dailyPriceInCents &&
      car.defaultProtectionPlan === prevCar.defaultProtectionPlan
    ) {
      related.push(car);
    } else {
      rest.push(car);
    }
  });
  return [...related, ...rest, ...ineligible];
};

export const useDocumentedListings = prevCar => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data, loading, error } = useQuery(CAR_SWITCH_FLEET, {
    variables: {
      where: {
        verification: CarVerificationEnum.verified,
        status: CarStatusEnum.available
      }
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const cars = useMemo(
    () =>
      orderCars(
        get(data, "viewer.me.owner.cars.edges", []).map(({ node }) => ({
          ...node,
          ineligibleError: getDocError(node)
        })),
        prevCar
      ),
    [data, prevCar]
  );

  return { cars, loading, error };
};
