import { client } from "Components/Utils/ApolloProvider";
import { gql } from "@apollo/client";
import { toPascalCaseWithSingleDelimiter } from "../../../../Driver/utils/helpers";

const GenerateErrorResponse = message => {
  return { success: false, message };
};

const carYearsBackdate = 16;
const firstAvailableYear = new Date().getFullYear() + 1 - carYearsBackdate;

export const validateVin = async (
  result,
  ineligibleMakes,
  ineligibleMakesAndModels
) => {
  const { Make, Model, ModelYear } = result;
  if (!(Make || Model || ModelYear)) {
    return GenerateErrorResponse(
      "VIN is invalid, please check you have the right VIN"
    );
  }
  const res = await asyncVinValidation(result);
  if (!res.success) {
    return GenerateErrorResponse(res.message);
  }
  const reformattedMake = toPascalCaseWithSingleDelimiter(Make);
  const reformattedMakeAndModel = `${reformattedMake} ${Model}`;
  const filteredMakesAndModels = ineligibleMakesAndModels.filter(elem =>
    reformattedMakeAndModel.includes(elem)
  );
  if (
    ineligibleMakes.includes(reformattedMake) ||
    filteredMakesAndModels.length
  ) {
    return GenerateErrorResponse("Make not eligible for our platform");
  }

  if (ModelYear && Number(ModelYear) < firstAvailableYear) {
    return GenerateErrorResponse(
      "Year is older than our allowed policy (Maximum 15 years from current date)"
    );
  }
  return { success: true };
};

const asyncVinValidation = async values => {
  try {
    return (
      await client.query({
        query: gql`
          query CarVinValidator($vin: String!) {
            validateVin(vin: $vin) {
              success
              message
            }
          }
        `,
        variables: {
          vin: values.VIN
        },
        fetchPolicy: "network-only"
      })
    ).data.validateVin;
  } catch (error) {
    console.log(error);
  }
};
