import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import PriceUpdateFormFields from "./PriceUpdateFormFields";
import ResponsiveModal from "../../../../../Dialog/ResponsiveModal";
import {
  PriceRulesErrorMessages,
  PriceRulesInCents
} from "Enums/PriceRulesEnum";
import { dollarsToCents } from "Utils/Calculations";

const TITLE = "Edit Price / Protection Plan";
const FORM_NAME = "CAR_PRICE_UPDATE";

const PriceUpdateModal = ({
  oldPriceInCents,
  handleSubmit,
  invalid,
  ...props
}) => (
  <ResponsiveModal
    title={TITLE}
    onPrimaryAction={handleSubmit}
    disabled={invalid}
    {...props}
  >
    <PriceUpdateFormFields oldPriceInCents={oldPriceInCents} />
  </ResponsiveModal>
);

const validate = ({ price }) => {
  const selectedPriceInCents = dollarsToCents(parseInt(price));
  const errors = {};
  if (!selectedPriceInCents) {
    errors.price = PriceRulesErrorMessages.requiredDaily;
  } else if (selectedPriceInCents < PriceRulesInCents.minimumDaily) {
    errors.price = PriceRulesErrorMessages.minimumDaily;
  } else if (selectedPriceInCents > PriceRulesInCents.maximumDaily) {
    errors.price = PriceRulesErrorMessages.maximumDaily;
  }
  return errors;
};

const PriceUpdateModalForm = reduxForm({ form: FORM_NAME, validate })(
  PriceUpdateModal
);

PriceUpdateModal.propTypes = {
  oldPriceInCents: PropTypes.number,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool
};

export default PriceUpdateModalForm;
