import { gql } from "@apollo/client";

import { RooftopFragments } from "Queries/Rooftop/RooftopFragments";

export const OrganizationFragments = {
  organizationInfo: gql`
    fragment OrganizationInfo on Organization {
      id
      name
    }
  `,
  organizationRole: gql`
    fragment OrganizationRole on Role {
      id
      name
      scopes
    }
  `,
  organizationAddress: gql`
    fragment OrganizationAddress on OrganizationAddress {
      # id
      streetAddress
      postalCode
      city
      state
      county
      country
      lat
      lng
    }
  `,
  organizationMember: gql`
    fragment OrganizationMemberInfo on OrganizationMember {
      id
      userId
      status
    }
  `,
  organizationUser: gql`
    fragment OrganizationUser on User {
      id
      userName
      displayName
      name {
        familyName
        givenName
        formatted
      }
      active
      phoneNumbers {
        value
        type
        primary
      }
    }
  `,
  organizationLegacyUser: gql`
    fragment OrganizationLegacyUser on LegacyUser {
      id
      profilePhoto {
        id
        url
      }
    }
  `
};

export const CompoundOrganizationFragments = {
  organizationMember: gql`
    fragment OrganizationMember on OrganizationMember {
      ...OrganizationMemberInfo
      role {
        ...OrganizationRole
      }
      rooftops {
        ...RooftopInfo
      }
      user {
        ...OrganizationUser
        legacyUser {
          ...OrganizationLegacyUser
        }
      }
    }
    ${OrganizationFragments.organizationMember}
    ${OrganizationFragments.organizationRole}
    ${RooftopFragments.rooftopInfo}
    ${OrganizationFragments.organizationUser}
    ${OrganizationFragments.organizationLegacyUser}
  `
};
