import { useContext } from "react";
import { useQuery } from "@apollo/client";
import get from "lodash/get";
import capitalize from "lodash/capitalize";

import { ORGANIZATION_INFO } from "Queries/Organization/OrganizationQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const useOrganizationRoleOptions = () => {
  const { currentOrganizationClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(ORGANIZATION_INFO, {
    client: currentOrganizationClient
  });

  const roleOptions = get(data, "organization.roles", [])
    .filter(
      role =>
        get(role, "name", "").toLowerCase() !== "p2p" &&
        get(role, "name", "").toLowerCase() !== "owner"
    )
    .map(role => ({
      value: role.id,
      label: capitalize(role.name)
    }));

  return {
    data,
    loading,
    roleOptions
  };
};
