import moment from "moment";

import { ProtectionPlanEnum } from "Enums/StateEnums";

const PROTECTION_PLAN_FEES = {
  [ProtectionPlanEnum.premium_plus]: 0.3,
  [ProtectionPlanEnum.premium]: 0.25,
  [ProtectionPlanEnum.standard]: 0.2,
  [ProtectionPlanEnum.basic]: 0.15
};

export const calculateWeeklyDiscount = (
  dailyPriceInCents,
  weeklyPriceInCents
) => {
  if (dailyPriceInCents && weeklyPriceInCents) {
    return Math.round(
      100 - (weeklyPriceInCents / (dailyPriceInCents * 7)) * 100
    );
  }

  return 0;
};

export const calculateMonthlyDiscount = (
  dailyPriceInCents,
  monthlyPriceInCents
) => {
  if (dailyPriceInCents && monthlyPriceInCents) {
    return Math.round(
      100 - (monthlyPriceInCents / (dailyPriceInCents * 30)) * 100
    );
  }

  return 0;
};

export const calculateWeeklyPriceInCentsWithDiscount = (
  dailyPriceInCents,
  weeklyDiscount
) => {
  return (
    dailyPriceInCents * 7 - (weeklyDiscount / 100) * (dailyPriceInCents * 7)
  );
};

export const calculateMonthlyPriceInCentsWithDiscount = (
  dailyPriceInCents,
  monthlyDiscount
) => {
  return (
    dailyPriceInCents * 30 - (monthlyDiscount / 100) * (dailyPriceInCents * 30)
  );
};

export const dollarsToCents = dollars => {
  return dollars * 100;
};

export const centsToDollarsInt = cents => (cents ? cents / 100 : 0);

export const centsToDollars = cents => {
  if (cents) return (cents / 100).toFixed(2);

  return 0;
};

export const calculateWeeklyPriceInCentsWithDiscountFromDollars = (
  dailyPriceInDollars,
  weeklyDiscount
) => {
  return calculateWeeklyPriceInCentsWithDiscount(
    dollarsToCents(dailyPriceInDollars),
    weeklyDiscount
  );
};

export const calculateMonthlyPriceInCentsWithDiscountFromDollars = (
  dailyPriceInCents,
  monthlyDiscount
) => {
  return calculateMonthlyPriceInCentsWithDiscount(
    dollarsToCents(dailyPriceInCents),
    monthlyDiscount
  );
};

export const getRentalRequestExpiration = (createdAt, shorthand) => {
  const deadline = moment(createdAt).add(24, "hours");
  const now = moment();
  const hoursFromNow = deadline.diff(now, "hours");
  const minutesFromNow = deadline.diff(now, "minutes");
  return Math.abs(hoursFromNow) >= 1
    ? `${hoursFromNow} ${shorthand ? "HR" : "hours"}`
    : `${minutesFromNow} minutes`;
};

export const getRentalDaysLeftOrDaysLateText = endDate => {
  const now = moment();

  if (now.isBefore(endDate)) {
    const duration = moment.duration(moment(endDate).diff(now));
    const days = Math.floor(duration.asDays());
    duration.subtract(days, "days");
    const hours = Math.floor(duration.asHours());

    if (hours <= 0 && days <= 0) {
      return "Returning now";
    } else {
      const dayString =
        days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : "";
      const hourString =
        hours > 0 ? ` ${hours} ${hours === 1 ? "hour" : "hours"}` : "";

      return dayString + hourString + " Left";
    }
  } else {
    const duration = moment.duration(now.diff(endDate));
    const days = Math.floor(duration.asDays());
    duration.subtract(days, "days");
    const hours = Math.floor(duration.asHours());

    if (hours <= 0 && days <= 0) {
      return "Returning now";
    } else {
      const dayString =
        days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : "";
      const hourString =
        hours > 0 ? ` ${hours} ${hours === 1 ? "hour" : "hours"}` : "";

      return dayString + hourString + " Late";
    }
  }
};

export const getRentalDaysLeftOrDaysLateTextShorthand = endDate => {
  const now = moment();

  if (now.isBefore(endDate)) {
    const duration = moment.duration(moment(endDate).diff(now));
    const days = Math.floor(duration.asDays());
    duration.subtract(days, "days");
    const hours = Math.floor(duration.asHours());

    if (hours <= 0 && days <= 0) {
      return "Returning now";
    } else {
      if (days > 0) {
        return `${days} ${days === 1 ? "day" : "days"} Left`;
      } else {
        return `${hours} ${hours === 1 ? "hour" : "hours"} Left`;
      }
    }
  } else {
    const duration = moment.duration(now.diff(endDate));
    const days = Math.floor(duration.asDays());
    duration.subtract(days, "days");
    const hours = Math.floor(duration.asHours());

    if (hours <= 0 && days <= 0) {
      return "Returning now";
    } else {
      if (days > 0) {
        return `${days} ${days === 1 ? "day" : "days"} Late`;
      } else {
        return `${hours} ${hours === 1 ? "hour" : "hours"} Late`;
      }
    }
  }
};

export const getProtectionPercent = protection_plan => {
  let percent;
  switch (protection_plan) {
    case ProtectionPlanEnum.premium_plus:
      percent = 0.7;
      break;
    case ProtectionPlanEnum.premium:
      percent = 0.75;
      break;
    case ProtectionPlanEnum.standard:
      percent = 0.8;
      break;
    case ProtectionPlanEnum.basic:
      percent = 0.85;
      break;
    default:
      percent = 0.8;
      break;
  }
  return percent;
};

export const calculateEarningsSummary = (
  numDays,
  dailyPriceInCents,
  protectionPlan
) => {
  const grossEarningsInCents = dailyPriceInCents * numDays;
  const protectionPlanFee =
    dailyPriceInCents * (1 - getProtectionPercent(protectionPlan));
  const totalProtectionPlanFeeInCents = protectionPlanFee * numDays;
  const netEarningsInCents =
    grossEarningsInCents - totalProtectionPlanFeeInCents;

  return {
    grossEarningsInCents,
    netEarningsInCents,
    totalProtectionPlanFeeInCents
  };
};

const priceToFixed2 = price => price.toFixed(2);
const getProtectionPlanFee = (basePrice, plan) =>
  basePrice * PROTECTION_PLAN_FEES[plan];

export const calculateProtectionFee = (plan, basePrice = 0) => {
  if (typeof plan !== "string") return 0;
  const planEnum = ProtectionPlanEnum[plan?.toLowerCase()];
  return planEnum
    ? priceToFixed2(getProtectionPlanFee(basePrice, planEnum))
    : 0;
};

export const calculateTimePastCompleted = droppedOffAt => {
  const millisecDif = new Date() - new Date(droppedOffAt);
  const hours = (millisecDif / (1000 * 60 * 60)).toFixed(2);
  return hours;
};
