import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3)
  },
  button: {
    padding: theme.spacing(0),
    textTransform: "none",
    display: "block",
    backgroundColor: "transparent"
  }
}));

const ResourceCardContainer = ({ to = "", children, isRoute, onClick }) => {
  const classes = useStyles();

  return (
    <>
      {isRoute ? (
        <Link underline="none" component={RouterLink} to={to}>
          {children}
        </Link>
      ) : (
        <Button
          href={to}
          onClick={onClick}
          color="primary"
          target="_blank"
          rel="noopener"
          className={classes.button}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export const ResourceCard = ({
  image,
  imageTitle,
  subTitle,
  title,
  to,
  linkText,
  children,
  isRoute,
  onClick
}) => {
  const classes = useStyles();

  return (
    <ResourceCardContainer
      to={to}
      isRoute={isRoute ? true : false}
      onClick={onClick}
    >
      <Card>
        <CardActionArea>
          <CardMedia
            image={image}
            title={imageTitle}
            component="img"
            alt={title}
            height="195"
          />
        </CardActionArea>
        <CardContent>
          <Grid container>
            <Grid item container>
              {subTitle && (
                <Grid item xs={12}>
                  <Typography component="h6" variant="h6" align="left">
                    {subTitle}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  component="h3"
                  variant="h5"
                  className={classes.title}
                  align="left"
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {children}
        </CardContent>
        <CardActions>
          <Button color="primary">{linkText}</Button>
        </CardActions>
      </Card>
    </ResourceCardContainer>
  );
};

ResourceCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  children: PropTypes.node,
  isRoute: PropTypes.bool,
  onClick: PropTypes.func
};

ResourceCardContainer.propTypes = {
  to: PropTypes.string,
  isRoute: PropTypes.bool,
  onClick: PropTypes.func
};
