import React, { useContext } from "react";
import {
  PrivateRoute,
  Route,
  Switch,
  CaptureRouteNotFound,
  RouteNotFound
} from "./Navigation";
import { toaster } from "hyrecar-web-components";
import { X } from "react-feather";
import makeStyles from "@mui/styles/makeStyles";

import ThemeContext from "Driver/styles/ThemeContext";
import { AuthContext } from "Login/Auth";
import withLoading from "Driver/utils/hocs/withLoading";
import asyncComponent from "Driver/utils/hocs/asyncComponent";
import Header from "Driver/Containers/NavigationHeader";
import { UserTypeEnum } from "Enums/StateEnums";
import { GigConnectContext } from "Components/Utils/GigConnectProvider";
import { CircularProgress } from "Components/Loading/CircularProgress";
import { DriverAlertProvider } from "Components/Utils/DriverAlertProvider";

const { ToastContainer, toast } = toaster;

const MessagingModal = asyncComponent(() =>
  import("Driver/Containers/Messaging/MessagesModal")
);

const HomePage = asyncComponent(() => import("Driver/Containers/HomePage"));
const ProfileRoutes = asyncComponent(() => import("./Navigation/Profile"));
const DriverRoutes = asyncComponent(() => import("./Navigation/Driver"));
const Offers = asyncComponent(() => import("./Navigation/Offers/index"));
const LocationContextProvider = asyncComponent(() =>
  import("Driver/utils/context/LocationContextProvider")
);
const ProfileCompleterContainer = asyncComponent(() =>
  import("Driver/Containers/Auth/ProfileCompleterContainer")
);

const useStyles = makeStyles(() => ({
  loading: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export const DriverApp = ({ currentUser }) => {
  const classes = useStyles();

  const { logout, userIsAuthenticated } = useContext(AuthContext);
  const { loading, componentOpening } = useContext(GigConnectContext);

  return loading && componentOpening === "navbar" ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : (
    <ThemeContext
      userType={UserTypeEnum.driver}
      showFleet={false}
      userIsAuthenticated={true}
    >
      <CaptureRouteNotFound>
        <div className="App">
          <ToastContainer
            autoClose={4000}
            position={toast.POSITION.TOP_RIGHT}
            closeButton={<X />}
            hideProgressBar={true}
          />
          <ProfileCompleterContainer />
          <MessagingModal />
          <DriverAlertProvider>
            <Header user={currentUser} logoutUser={logout} />
            <LocationContextProvider>
              <Switch>
                <Route path="/" exact component={() => <HomePage />} />
                <Route
                  path="/loading"
                  exact
                  component={withLoading(() => true)(() => (
                    <div />
                  ))}
                />
                <Route
                  path="/offers"
                  render={props => (
                    <Offers
                      {...props}
                      currentUser={currentUser}
                      userIsAuthenticated={userIsAuthenticated}
                    />
                  )}
                />
                <PrivateRoute
                  path="/profile"
                  component={ProfileRoutes}
                  userIsAuthenticated={userIsAuthenticated}
                  userType={UserTypeEnum.driver}
                  currentUser={currentUser}
                />
                <PrivateRoute
                  path="/driver"
                  userRole={"DRIVER"}
                  component={DriverRoutes}
                  userIsAuthenticated={userIsAuthenticated}
                  userType={UserTypeEnum.driver}
                  currentUser={currentUser}
                />
                <RouteNotFound />
              </Switch>
            </LocationContextProvider>
          </DriverAlertProvider>
        </div>
      </CaptureRouteNotFound>
    </ThemeContext>
  );
};
