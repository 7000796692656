import React from "react";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  drawer: {
    background: theme.palette.background.default
  }
}));

export const InfoDrawer = ({ open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawer
      }}
      style={{ zIndex: 1250 }}
    >
      {children}
    </Drawer>
  );
};

InfoDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
