import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { ThemeProvider } from "Styles/ThemeProvider";

const useStyles = makeStyles({
  rooftopName: {
    color: "#03A9F4"
  }
});

export const Header = ({ name }) => {
  const classes = useStyles();
  return (
    <ThemeProvider>
      <Typography className={classes.rooftopName}>{name}</Typography>
    </ThemeProvider>
  );
};

Header.propTypes = {
  name: PropTypes.string
};
