import React, { useContext, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Typography, IconButton, Link } from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBack";

import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { formatPhone } from "Utils/Helpers";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3, 3),
    margin: `24px 16px`
  },
  subtitle1: {
    color: "#000",
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginBottom: "10px"
  },
  body2: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0.25px",
    marginBottom: "28px"
  },
  backIcon: {
    color: "rgba(0,0,0,0.87)"
  },
  list: {
    marginTop: 0,
    paddingLeft: "16px"
  }
}));

const CarSwitchLateRentalUnavailable = ({ onClose }) => {
  const { list, root, subtitle1, body2, backIcon } = useStyles();

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  return (
    <>
      <DrawerAppBar
        title="Car Switch Unavailable"
        border
        isStatic
        icon={
          <IconButton onClick={onClose} size="large">
            <BackIcon className={backIcon} />
          </IconButton>
        }
      />

      <div className={root}>
        <Typography className={subtitle1} variant="subtitle1">
          We&apos;re sorry
        </Typography>
        <Typography className={body2} variant="body2">
          Unfortunately, you cannot switch cars when the rental is currently
          late.
        </Typography>
        <Typography className={subtitle1} variant="subtitle1">
          What are your options?
        </Typography>
        <ol className={list}>
          <li>
            <Typography className={body2} variant="body2">
              If the rental is{" "}
              <strong>
                <u>less than 3 days late</u>
              </strong>
              , message the driver and ask them to get caught up on their past
              due payments. Once updated, you will be able to switch cars.
            </Typography>
          </li>
          <li>
            <Typography className={body2} variant="body2">
              If the rental is{" "}
              <strong>
                <u>more than 3 days late</u>
              </strong>
              , ask the driver to return your car immediately and end the
              rental. (We find that after 3 days, it is difficult for the driver
              to catch up on their payments, which could lead to misuse or
              damage to your car.)
            </Typography>
          </li>
          <li>
            <Typography className={body2} variant="body2">
              Call HyreCar Support for immediate help:{" "}
              <Link
                href={`tel:1-${formatPhone(customerServicePhoneNumber)}`}
                underline="hover"
              >
                {formatPhone(customerServicePhoneNumber)}
              </Link>
            </Typography>
          </li>
        </ol>
      </div>
    </>
  );
};

export default CarSwitchLateRentalUnavailable;
