import { useContext } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { compose, withProps } from "recompose";
import moment from "moment";
import get from "lodash/get";

import { evalLoadingState } from "../../QueryUtils.js";
import { optionalChaining } from "Driver/utils/helpers";
import {
  RENTALS_QUERY,
  RENTAL_AGGREGATE_QUERY,
  RENTAL_STATUS_QUERY,
  RENTAL_REQUEST_QUERY,
  RENTAL_OVERVIEW_QUERY,
  FIRST_RENTAL_PERIOD
} from "../RentalQueries";
import { RentalStatusEnum } from "Enums/StateEnums";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { client } from "Components/Utils/ApolloProvider";

export const rentalsQuery = props => {
  return graphql(RENTALS_QUERY, {
    ...props,
    partialRefetch: true
  });
};

export const rentalRequestQuery = props => {
  return graphql(RENTAL_REQUEST_QUERY, {
    ...props
  });
};

export const rentalStatusQuery = props => {
  return graphql(RENTAL_STATUS_QUERY, {
    ...props
  });
};

export const rentalOverviewQuery = props => {
  return graphql(RENTAL_OVERVIEW_QUERY, {
    ...props
  });
};

export const rentalAggregate = props => {
  return graphql(RENTAL_AGGREGATE_QUERY, {
    ...props,
    partialRefetch: true
  });
};

export const withFirstRentalPeriod = graphql(FIRST_RENTAL_PERIOD, {
  options: ({ rentalId }) => {
    return {
      variables: {
        id: rentalId
      },
      context: {
        important: true
      }
    };
  },
  props: ({ data }) => {
    return {
      loading: data.loading,
      firstRentalPeriod: get(data, "rental.rentalPeriods[0]")
    };
  }
});

const rentalStatusAggregateMap = ({ data }) => {
  return {
    [data.variables.filter.status.toLowerCase()]: {
      aggregate: optionalChaining(
        () => data.viewer.me.owner.rentalsConnection.aggregate.count
      ),
      loading: data.loading
    },
    error: data.error
  };
};

export const rentalStatusAggregate = compose(
  withProps(() => {
    const { currentRooftopClient } = useContext(ClientFactoryContext);
    return {
      client: currentRooftopClient ? currentRooftopClient : client
    };
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          status: RentalStatusEnum.active
        }
      },
      fetchPolicy: "cache-and-network",
      client: client,
      context: {
        important: true
      }
    }),
    props: rentalStatusAggregateMap
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          status: RentalStatusEnum.applied
        }
      },
      fetchPolicy: "cache-and-network",
      client: client,
      context: {
        important: true
      }
    }),
    props: rentalStatusAggregateMap
  }),

  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          status: RentalStatusEnum.late
        }
      },
      fetchPolicy: "cache-and-network",
      client: client,
      context: {
        important: true
      }
    }),
    props: rentalStatusAggregateMap
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          status: RentalStatusEnum.pendingInsurance
        }
      },
      fetchPolicy: "cache-and-network",
      client: client,
      context: {
        important: true
      }
    }),
    props: rentalStatusAggregateMap
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          status: RentalStatusEnum.pendingPickup
        }
      },
      fetchPolicy: "cache-and-network",
      client: client,
      context: {
        important: true
      }
    }),
    props: rentalStatusAggregateMap
  }),
  evalLoadingState
);

export const rentalNumbersKPI = compose(
  withProps(() => {
    const { currentRooftopClient } = useContext(ClientFactoryContext);
    return {
      client: currentRooftopClient ? currentRooftopClient : client
    };
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          createdAt_gt: moment().subtract(1, "days")
        }
      },
      client: client,
      fetchPolicy: "cache-and-network"
    }),
    props: ({ data }) => ({
      day: {
        value: optionalChaining(
          () => data.viewer.me.owner.rentalsConnection.aggregate.count
        ),
        loading: data.loading
      }
    })
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          createdAt_gt: moment().subtract(7, "days")
        }
      },
      client: client,
      fetchPolicy: "cache-and-network"
    }),
    props: ({ data }) => ({
      week: {
        value: optionalChaining(
          () => data.viewer.me.owner.rentalsConnection.aggregate.count
        ),
        loading: data.loading
      }
    })
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          createdAt_gt: moment().subtract(1, "months")
        }
      },
      client: client,
      fetchPolicy: "cache-and-network"
    }),
    props: ({ data }) => ({
      month: {
        value: optionalChaining(
          () => data.viewer.me.owner.rentalsConnection.aggregate.count
        ),
        loading: data.loading
      }
    })
  }),
  rentalAggregate({
    options: ({ client }) => ({
      variables: {
        filter: {
          createdAt_gt: moment().subtract(1, "years")
        }
      },
      client: client,
      fetchPolicy: "cache-and-network"
    }),
    props: ({ data }) => ({
      year: {
        value: optionalChaining(
          () => data.viewer.me.owner.rentalsConnection.aggregate.count
        ),
        loading: data.loading
      }
    })
  }),
  evalLoadingState
);
