import React, { useContext } from "react";
import Link from "@mui/material/Link";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsTicketsController";
import { TicketsChecklist } from "./TicketsChecklist";
import { openSupportPage } from "Utils/openSupportPage";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

export const ClaimsTicketsChecklist = () => {
  const { onSubmit, prevStep } = useContext(StepperContext);
  const { getLink } = useContext(FirebaseContext);

  const leftComponent = (
    <FlowContentContainer
      nextStep={onSubmit}
      header="Tickets and Tolls Checklist"
      nextStepLabel="Submit request"
      previousStepLabel="Back"
      previousStep={prevStep}
    >
      <TicketsChecklist />
    </FlowContentContainer>
  );

  const rightComponent = (
    <TooltipContainer header="Helpful Tips">
      <Tooltip
        header="Ticket and Toll policy"
        paragraphs={[
          props => (
            <div {...props}>
              The Driver is responsible to pay for all tickets and tolls that
              they received during their rental. For more details about our
              policy, read our support article{" "}
              <Link
                component="button"
                onClick={() =>
                  openSupportPage(getLink("salesforceTicketPolicy"))
                }
                underline="hover"
              >
                HERE
              </Link>
            </div>
          ),
          props => (
            <div {...props}>
              If you need immediate help, please contact{" "}
              <Link
                component="button"
                onClick={() => openSupportPage()}
                underline="hover"
              >
                CUSTOMER SUPPORT
              </Link>
              .
            </div>
          )
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
};
