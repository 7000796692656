import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { common } from "@mui/material/colors";

export const driverTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#FC6347",
        contrastText: common.white
      },
      secondary: {
        main: "#26C6DA"
      },
      alternative: {
        main: "#bc00ccff",
        light: "#c93cc8ff"
      },
      text: {
        primary: "#606770"
      },
      success: {
        main: "#00E676",
        light: "#00E676",
        dark: "#00C853"
      },
      warning: {
        main: "#FFC107",
        light: "#FFC107",
        dark: "#FFA000"
      },
      error: {
        main: "#C62828"
      }
    },
    typography: {
      fontFamily: ["proxima-nova", "sans-serif"].join(",")
    }
  })
);
