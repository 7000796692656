//TODO: Implement isDragActive from useDropzone.
import React from "react";
import { useDropzone } from "react-dropzone-latest";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PublishIcon from "@mui/icons-material/Publish";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

import { AcceptEnum } from "Enums/AcceptEnum";

const useStyles = makeStyles(theme => ({
  root: {
    width: props => (props.width ? `${props.width}px` : "250px"),
    height: props => (props.height ? `${props.height}px` : "250px"),
    borderStyle: "dashed",
    borderWidth: "3px",
    borderColor: props =>
      props.disabled
        ? theme.palette.grey[500]
        : props.meta.touched && props.meta.error
        ? theme.palette.error.main
        : theme.palette.primary[500],
    background: theme.palette.grey[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inner: {
    height: props => (props.height ? `${props.height}px` : "250px"),
    textAlign: "center",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },
  content: {
    margin: ({ contentBottomMargin }) =>
      contentBottomMargin ? theme.spacing(0, 0, contentBottomMargin, 0) : null
  },
  fullWidth: {
    width: props => (props.fullWidth ? "inherit" : null)
  },
  borderError: {
    borderColor: theme.palette.error.main
  },
  buttonError: {
    background: `${theme.palette.error.main} !important`
  },
  hover: {
    cursor: ({ disabled }) => (disabled ? null : "pointer")
  }
}));

/**
 * Flexible Dropzone component that can accept multiple different mime types,
 * a max size for file uploads in bytes, a maxToUpload prop specifying how many
 * images can actually be uploaded, a buttonOnly bool prop for showing only the button,
 * width, height and size props for controlling the dimensions.
 *
 * maxToUpload value should change dynamically in the parent.
 */
export const ImageDropzone = props => {
  const {
    accept = [AcceptEnum.jpeg, AcceptEnum.pdf, AcceptEnum.png],
    disabled = false,
    maxSize,
    multiple = false,
    fullWidth,
    buttonText,
    hideButton = false,
    content,
    bottomContent,
    hover,
    onRejectFiles,
    buttonOnly,
    maxToUpload,
    totalMaxToUpload,
    asyncAction,
    buttonId,
    input: { value, name, onBlur, onChange, onFocus },
    meta: { touched, error }
  } = props;

  const classes = useStyles(props);

  const getFileFormatNames = () => {
    const names = [];
    for (let typeName in AcceptEnum) {
      if (accept.includes(AcceptEnum[typeName])) names.push(typeName);
    }
    return names;
  };
  const getFileFormatNamesString = () =>
    getFileFormatNames().reduce((acc, curr, i) => {
      if (i === 0) return curr;
      else if (i === 1) return `${curr} and ${acc}`;
      return `${curr}, ${acc}`;
    }, "");

  const getFirstReason = (rejectedFiles, overflowFiles) => {
    for (const file of rejectedFiles) {
      const size = file.size || file.file.size;
      if (size > maxSize)
        return `The attachment exceeds the file size limit of ${maxSize /
          1000000} MB.`;
      if (!accept.includes(file.type))
        return `Failed to upload. Only files with ${getFileFormatNamesString()} extensions are allowed.`;
    }
    if (overflowFiles)
      return `You’ve exceeded the number of ${totalMaxToUpload} allowable attachments.`;
    return "Something went wrong";
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    maxSize,
    multiple,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      const overflowFiles =
        typeof maxToUpload === "number" && maxToUpload >= 0
          ? acceptedFiles.slice(maxToUpload, acceptedFiles.length)
          : [];
      const files =
        typeof maxToUpload === "number" && maxToUpload >= 0
          ? acceptedFiles.slice(0, maxToUpload)
          : acceptedFiles;

      if (files) {
        if (asyncAction) {
          const multipleFiles = [];

          for await (let droppedFile of files) {
            const values = await asyncAction(droppedFile);
            multipleFiles.push(values);
            onChange([...value, ...multipleFiles]);
          }
        } else {
          onChange([...value, ...files]);
        }
      }

      if (
        (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) ||
        overflowFiles.length > 0
      )
        onRejectFiles(
          [...rejectedFiles, ...overflowFiles],
          getFirstReason(rejectedFiles, overflowFiles)
        );
    }
  });

  const rootProps = getRootProps({
    onFocus,
    onBlur: e => {
      e.preventDefault();
      onBlur();
    }
  });
  const inputProps = getInputProps({ name });

  return buttonOnly ? (
    <>
      <input {...inputProps} />
      <Button
        {...rootProps}
        color="primary"
        variant="contained"
        startIcon={<PublishIcon />}
        className={classNames({ [classes.buttonError]: touched && error })}
        disabled={disabled}
        fullWidth
      >
        {buttonText ? buttonText : "upload photos"}
      </Button>
    </>
  ) : (
    <div
      {...rootProps}
      className={classNames(classes.root, {
        [classes.fullWidth]: fullWidth,
        [classes.borderError]: touched && error,
        [classes.hover]: hover
      })}
    >
      <input {...inputProps} />
      <Grid
        className={classNames(classes.inner)}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {content && (
          <Typography
            component="span"
            variant="h6"
            align="center"
            display="block"
            className={classNames(classes.content)}
          >
            {content}
          </Typography>
        )}
        {!hideButton && (
          <Button
            id={buttonId}
            color="primary"
            variant="contained"
            startIcon={<PublishIcon />}
            className={classNames({
              [classes.buttonError]: touched && error
            })}
            disabled={disabled}
          >
            {buttonText ? buttonText : "upload photos"}
          </Button>
        )}
        {bottomContent || null}
      </Grid>
    </div>
  );
};

ImageDropzone.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf([
        AcceptEnum.jpeg,
        AcceptEnum.png,
        AcceptEnum.pdf,
        AcceptEnum.csv,
        AcceptEnum.csvWindows
      ])
    )
  ]),
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fullWidth: PropTypes.bool,
  buttonText: PropTypes.string,
  hideButton: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bottomContent: PropTypes.node,
  onRejectFiles: PropTypes.func,
  buttonOnly: PropTypes.bool,
  maxToUpload: PropTypes.number,
  totalMaxToUpload: PropTypes.number,
  hover: PropTypes.bool,
  asyncAction: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func
  }).isRequired
};
