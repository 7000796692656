export const PriceRulesInCents = {
  minimumDaily: 2000,
  maximumDaily: 15000
};

export const PriceRulesErrorMessages = {
  requiredDaily: "A daily price is required",
  minimumDaily: "Must be at least $20 per day.",
  maximumDaily: "The maximum daily listing price is $150."
};
