import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    width: "525px",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw)"
    }
  }
}));

/**
 *
 * The DrawerContainer component is used to
 * add width to something like an InfoDrawer or RouterDrawer. Otherwise,
 * these components would expand to the width of their children which is not
 * what we want.
 */
export const DrawerContainer = ({ children, ...rest }) => {
  const classes = useStyles(rest);

  return <div className={classes.root}>{children}</div>;
};

DrawerContainer.propTypes = {
  children: PropTypes.node.isRequired
};
