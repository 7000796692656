import React from "react";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";

export const Checkbox = ({ label, name, value, onChange }) => {
  const handleChecked = e => onChange(e.target.checked);

  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            checked={!!value}
            onChange={handleChecked}
            color="primary"
          />
        }
        label={label}
      />
    </FormControl>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired
};
