import React, { useContext } from "react";
import { reduxForm, FieldArray } from "redux-form";

import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./CSVController";
import { ImageFormFields } from "Components/Forms/FormFields/CSV/ImageFormFields";

const CSVImageContainerForm = reduxForm({
  form: "CSV_IMPORT",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep, form }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      nextStepLabel="continue"
      previousStepLabel="back"
      previousStep={prevStep}
    >
      <FieldArray name="cars" component={ImageFormFields} form={form} />
    </FlowContentContainer>
  );
});

export const CSVImageContainer = () => {
  const { nextStep, prevStep } = useContext(StepperContext);

  return <CSVImageContainerForm onSubmit={nextStep} prevStep={prevStep} />;
};
