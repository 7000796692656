import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { emailRegex } from "Utils/Regex";
import { useSnackbar } from "notistack";
import { destroy } from "redux-form";
import get from "lodash/get";

import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { INVITE_ORGANIZATION_MEMBER } from "Mutations/Organization/OrganizationMutations";
import { ORGANIZATION_MEMBERS } from "Queries/Organization/OrganizationQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const memberRequiredFields = ["email", "role"];

export const useMemberFormController = (dispatch, handleClose) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentOrganizationClient } = useContext(ClientFactoryContext);
  const [inviteOrganizationMember] = useMutation(INVITE_ORGANIZATION_MEMBER, {
    client: currentOrganizationClient,
    update(cache, { data: { inviteOrganizationMember } }) {
      const cachedOrganization = cache.readQuery({
        query: ORGANIZATION_MEMBERS
      });

      const organization = get(cachedOrganization, "organization", {});

      cache.writeQuery({
        query: ORGANIZATION_MEMBERS,
        data: {
          organization: {
            ...organization,
            organizationMembers: organization.organizationMembers.concat([
              inviteOrganizationMember
            ])
          }
        }
      });
    }
  });

  const initialValues = {
    rooftops: []
  };

  const onSubmit = async values => {
    try {
      const data = {
        email: get(values, "email"),
        role: get(values, "role"),
        rooftopIds: get(values, "rooftops", []).map(rooftop => rooftop.value)
      };
      await inviteOrganizationMember({
        variables: {
          data
        }
      });

      handleClose();
      dispatch(destroy("MEMBER_FORM"));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        e.message
          ? e.message.replace(/graphql error: /gim, "")
          : "Failed to submit your member.",
        {
          variant: "error"
        }
      );
    }
  };

  const validate = values => {
    const errors = {};

    memberRequiredFields.map(field => {
      errors[field] = !values[field] ? "Required" : undefined;
      return errors;
    });

    if (values.rooftops.length === 0) {
      errors.rooftops = "Required";
    }

    if (!emailRegex.test(values.email)) {
      errors.email = "Enter a valid email";
    }

    return errors;
  };

  return {
    onSubmit,
    validate,
    initialValues,
    onSubmitFail: errors => scrollErrorIntoView(errors)
  };
};
