import { RouteEnum } from "Enums/RouteEnum";

export const inboxRentalStatusEnum = {
  LOADING: {
    allInboxText: "Loading...",
    backgroundColor: "#C4C4C4",
    title: "...",
    color: "#707070",
    periodVariation: 0
  },
  INQUIRY: {
    allInboxText: "Inquiry",
    backgroundColor: "#388E3C",
    title: "INQUIRY",
    color: "#01579B",
    periodVariation: 0
  },
  APPLIED_NOT_VERIFIED: {
    allInboxText: "Inquiry",
    backgroundColor: "#388E3C",
    title: "INQUIRY",
    color: "#01579B",
    periodVariation: 0
  },
  APPLIED: {
    allInboxText: "Requested",
    backgroundColor: "#F57C00",
    title: "REQUESTED",
    color: "#388E3C",
    periodVariation: 1,
    detailsRoute: RouteEnum.rentalsRequests
  },
  PENDING_INSURANCE: {
    allInboxText: "Accepted",
    backgroundColor: "#F57C00",
    title: "ACCEPTED",
    color: "#F57C00",
    periodVariation: 1,
    detailsRoute: RouteEnum.rentalsActive
  },
  PENDING_PICKUP: {
    allInboxText: "Check-in",
    backgroundColor: "#388E3C",
    title: "CONFIRM PICKUP",
    color: "#388E3C",
    periodVariation: 1,
    detailsRoute: RouteEnum.rentalsActive
  },
  ACTIVE: {
    allInboxText: "Active",
    backgroundColor: "#29B6F6",
    title: "ACTIVE",
    color: "#4FC3F7",
    periodVariation: 2,
    detailsRoute: RouteEnum.rentalsActive
  },
  LATE: {
    allInboxText: "Late",
    backgroundColor: "#C62828",
    title: "LATE",
    color: "#C62828",
    periodVariation: 2,
    detailsRoute: RouteEnum.rentalsActive
  },
  COMPLETED: {
    allInboxText: "Completed",
    backgroundColor: "#C4C4C4",
    title: "COMPLETED",
    color: "#707070",
    periodVariation: 2,
    detailsRoute: RouteEnum.rentalsPast
  },
  CANCELLED: {
    allInboxText: "Cancelled",
    backgroundColor: "#C4C4C4",
    title: "CANCELLED",
    color: "#707070",
    periodVariation: 0
  },
  APPLICATION_REJECTED: {
    allInboxText: "Rejected",
    backgroundColor: "#C4C4C4",
    title: "REJECTED",
    color: "#707070",
    periodVariation: 0
  },
  APPLICATION_AUTO_REJECTED: {
    allInboxText: "Rejected",
    backgroundColor: "#C4C4C4",
    title: "AUTO REJECTED",
    color: "#707070",
    periodVariation: 0
  }
};
