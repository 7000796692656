import React from "react";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

import defaultCar from "Assets/images/default-car.svg";

const useStyles = makeStyles(theme => ({
  image: {
    width: "100%",
    height: "260px",
    objectFit: "cover",
    margin: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    margin: theme.spacing(2, 0)
  },
  margin: {
    marginTop: theme.spacing(7)
  },
  item: {
    width: "48%",
    margin: theme.spacing(1, 0)
  }
}));

export const SkeletonDrawer = ({ showCar }) => {
  const classes = useStyles();

  return (
    <>
      {showCar && (
        <Skeleton
          component={({ className }) => {
            return (
              <img
                src={defaultCar}
                className={classNames(className, classes.image)}
                alt="Car Placeholder"
              />
            );
          }}
          variant="rectangular"
        />
      )}
      <div
        className={classNames(classes.container, {
          [classes.margin]: showCar
        })}
      >
        <Skeleton height={25} className={classes.item} />
        <Skeleton height={25} className={classes.item} />
        <Skeleton height={25} className={classes.item} />
        <Skeleton height={25} className={classes.item} />
        <Skeleton height={25} className={classes.item} />
        <Skeleton height={25} className={classes.item} />
        <Skeleton height={25} className={classes.item} />
        <Skeleton height={25} className={classes.item} />
      </div>
    </>
  );
};

SkeletonDrawer.propTypes = {
  showCar: PropTypes.bool
};
