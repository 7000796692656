export const MESSAGING_MODAL_HIDE = "MESSAGING_MODAL_HIDE";
export const MESSAGING_MODAL_SHOW = "MESSAGING_MODAL_SHOW";

export const messagingModalHideAction = authModalProps => ({
  type: MESSAGING_MODAL_HIDE,
  payload: authModalProps
});

export const messagingModalShowAction = (channelId = null) => ({
  type: MESSAGING_MODAL_SHOW,
  payload: { channelId }
});
