export const carYearsBackdate = 18;
export const firstAvailableYear =
  new Date().getFullYear() + 1 - carYearsBackdate;

export const allowedCarYears = () => {
  const yearsArrayLength = carYearsBackdate + 1;
  const yearsArray = new Array(yearsArrayLength);
  for (let i = 0; i < yearsArrayLength; i++) {
    yearsArray[i] = (firstAvailableYear + i).toString();
  }
  return yearsArray.reverse();
};

export const carColors = [
  "Black",
  "White",
  "Gray",
  "Silver",
  "Blue",
  "Red",
  "Green",
  "Brown",
  "Orange",
  "Yellow",
  "Purple",
  "Pink",
  "Gold"
];

export const carMakeList = [
  {
    Make_Name: "Acura"
  },
  {
    Make_Name: "Alfa Romeo"
  },
  {
    Make_Name: "Aptera"
  },
  {
    Make_Name: "Aston Martin"
  },
  {
    Make_Name: "Audi"
  },
  {
    Make_Name: "Austin"
  },
  {
    Make_Name: "Bentley"
  },
  {
    Make_Name: "Bmw"
  },
  {
    Make_Name: "Bugatti"
  },
  {
    Make_Name: "Buick"
  },
  {
    Make_Name: "Cadillac"
  },
  {
    Make_Name: "Chevrolet"
  },
  {
    Make_Name: "Chrysler"
  },
  {
    Make_Name: "Coda"
  },
  {
    Make_Name: "Corbin"
  },
  {
    Make_Name: "Daewoo"
  },
  {
    Make_Name: "Daihatsu"
  },
  {
    Make_Name: "Dodge"
  },
  {
    Make_Name: "Eagle"
  },
  {
    Make_Name: "Fairthorpe"
  },
  {
    Make_Name: "Ferrari"
  },
  {
    Make_Name: "Fiat"
  },
  {
    Make_Name: "Fillmore"
  },
  {
    Make_Name: "Foose"
  },
  {
    Make_Name: "Ford"
  },
  {
    Make_Name: "Geo"
  },
  {
    Make_Name: "Gmc"
  },
  {
    Make_Name: "Hillman"
  },
  {
    Make_Name: "Holden"
  },
  {
    Make_Name: "Honda"
  },
  {
    Make_Name: "Hummer"
  },
  {
    Make_Name: "Hyundai"
  },
  {
    Make_Name: "Infiniti"
  },
  {
    Make_Name: "Isuzu"
  },
  {
    Make_Name: "Jaguar"
  },
  {
    Make_Name: "Jeep"
  },
  {
    Make_Name: "Jensen"
  },
  {
    Make_Name: "Kia"
  },
  {
    Make_Name: "Lamborghini"
  },
  {
    Make_Name: "Land Rover"
  },
  {
    Make_Name: "Lexus"
  },
  {
    Make_Name: "Lincoln"
  },
  {
    Make_Name: "Lotus"
  },
  {
    Make_Name: "Maserati"
  },
  {
    Make_Name: "Maybach"
  },
  {
    Make_Name: "Mazda"
  },
  {
    Make_Name: "Mclaren"
  },
  {
    Make_Name: "Mercedes-Benz"
  },
  {
    Make_Name: "Mercury"
  },
  {
    Make_Name: "Merkur"
  },
  {
    Make_Name: "Mg"
  },
  {
    Make_Name: "Mini"
  },
  {
    Make_Name: "Mitsubishi"
  },
  {
    Make_Name: "Morgan"
  },
  {
    Make_Name: "Nissan"
  },
  {
    Make_Name: "Oldsmobile"
  },
  {
    Make_Name: "Panoz"
  },
  {
    Make_Name: "Peugeot"
  },
  {
    Make_Name: "Plymouth"
  },
  {
    Make_Name: "Pontiac"
  },
  {
    Make_Name: "Porsche"
  },
  {
    Make_Name: "Ram"
  },
  {
    Make_Name: "Rambler"
  },
  {
    Make_Name: "Renault"
  },
  {
    Make_Name: "Rolls-Royce"
  },
  {
    Make_Name: "Saab"
  },
  {
    Make_Name: "Saturn"
  },
  {
    Make_Name: "Scion"
  },
  {
    Make_Name: "Shelby"
  },
  {
    Make_Name: "Smart"
  },
  {
    Make_Name: "Spyker"
  },
  {
    Make_Name: "Spyker Cars"
  },
  {
    Make_Name: "Studebaker"
  },
  {
    Make_Name: "Subaru"
  },
  {
    Make_Name: "Suzuki"
  },
  {
    Make_Name: "Tesla"
  },
  {
    Make_Name: "Toyota"
  },
  {
    Make_Name: "Volkswagen"
  },
  {
    Make_Name: "Volvo"
  }
];
