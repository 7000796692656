import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import filter from "lodash/filter";

import { Table } from "./Table";
import { TableHead } from "./TableHead";
import { TableFooter } from "./TableFooter";
import { useTableState } from "Components/Tables/hooks/useTableState";
import { useRentalsTable } from "Queries/Rentals/hooks/useRentalsTable";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { useDebounce } from "Utils/useDebounce";

export const RentalsTable = ({
  initialColumns,
  initialFilter,
  filters,
  tableName,
  noDataLabel,
  noDataComponent,
  onRowClick,
  filtersSelectedByDefault
}) => {
  const {
    search,
    first,
    orderBy,
    page,
    selectedFilters,
    columns,
    handleSearchChange,
    handleFirstChange,
    nextPage,
    previousPage,
    handleSelectFilter,
    handleDeleteFilter
  } = useTableState({
    initialFirst: 10,
    initialOrderBy: "updatedAt_DESC",
    initialColumns,
    initialFilters: filtersSelectedByDefault
      ? filter(filters, o => !o.disableSelectedByDefault)
      : []
  });
  const debouncedSearch = useDebounce(search, 500);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { mappedData, pageInfo, loading } = useRentalsTable({
    variables: {
      searchText: debouncedSearch,
      orderBy,
      first,
      skip: page * first,
      filter: {
        ...initialFilter,
        ...selectedFilters.reduce((acc, val) => {
          if (Object.prototype.hasOwnProperty.call(acc, val.key)) {
            acc[val.key].push(val.value);
          } else {
            acc[val.key] = [val.value];
          }
          return acc;
        }, {})
      }
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  return (
    <Paper>
      <TableHead
        tableName={tableName}
        onSearchChange={handleSearchChange}
        search={search}
        searchPlaceholder="Search Driver Name, Year, Make or Model"
        loading={loading}
        filters={filters}
        selectedFilters={selectedFilters}
        onSelectFilter={handleSelectFilter}
        onDeleteFilter={handleDeleteFilter}
      />
      <Table
        columns={columns}
        data={mappedData}
        loading={loading}
        noDataLabel={noDataLabel}
        noDataComponent={noDataComponent}
        onRowClick={onRowClick}
      />
      <TableFooter
        rowsPerPage={first}
        onRowsPerPageChange={handleFirstChange}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={page > 0}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        page={page}
      />
    </Paper>
  );
};

RentalsTable.propTypes = {
  initialColumns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      checked: PropTypes.bool
    })
  ),
  initialFilter: PropTypes.object,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      chipLabel: PropTypes.string,
      chipColor: PropTypes.string,
      disableSelectedByDefault: PropTypes.bool
    })
  ),
  tableName: PropTypes.string,
  noDataLabel: PropTypes.string,
  noDataComponent: PropTypes.node,
  onRowClick: PropTypes.func,
  filtersSelectedByDefault: PropTypes.bool,
  loading: PropTypes.bool
};
