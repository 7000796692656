import React, { Fragment } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";
import get from "lodash/get";
import find from "lodash/find";

import { InboxChannel } from "./InboxChannel";
import { InboxChannelLoading } from "./InboxChannelLoading";
import { InboxChannelsHeader } from "./InboxChannelsHeader";
import { filterChannels } from "Redux/SendBird/Selectors";
import { useFilterChannelsByAssignedTo } from "Fleet/Dashboard/DashboardViews/Inbox/helpers";
import FiltersBar from "./Filters/FiltersBar";

export const InboxChannels = connect(state => ({
  channels: get(state, "fleet.sendbird.channels", []),
  selectedChannel: get(state, "fleet.sendbird.selectedChannel", {}),
  user: get(state, "fleet.sendbird.user", {}),
  loading: get(state, "fleet.sendbird.loading", true),
  ...filterChannels(state)
}))(
  ({
    filteredChannels,
    selectedChannel,
    user,
    loading,
    onClick,
    filterByAssignedTo
  }) => {
    const {
      filteredChannelsByAssignedTo,
      loading: filterLoading
    } = useFilterChannelsByAssignedTo(filteredChannels, selectedChannel);
    const channelsToDisplay = filterByAssignedTo
      ? filteredChannelsByAssignedTo
      : filteredChannels;
    return (
      <Fragment>
        <InboxChannelsHeader />
        <FiltersBar />
        <List>
          {loading || filterLoading ? (
            <Fragment>
              <InboxChannelLoading />
              <InboxChannelLoading />
              <InboxChannelLoading />
            </Fragment>
          ) : (
            <Fragment>
              {channelsToDisplay.map(channel => {
                const driver = find(
                  channel.members,
                  member => member.userId !== user.userId
                );
                const lastMessage = get(channel, "lastMessage", {})
                  ? channel.lastMessage
                  : {};
                return driver ? (
                  <Fragment key={channel.url}>
                    <InboxChannel
                      onClick={onClick}
                      avatarSrc={driver.profileUrl}
                      alt={`${driver.nickname} Profile`}
                      name={driver.nickname}
                      text={lastMessage.message}
                      driverId={driver.userId}
                      channel={channel}
                      timestamp={lastMessage.createdAt}
                    />
                    <Divider />
                  </Fragment>
                ) : null;
              })}
            </Fragment>
          )}
        </List>
      </Fragment>
    );
  }
);
