import { gql } from "@apollo/client";

export const AuthMutationFragments = {
  signUpUserFragment: gql`
    fragment SignUpUserFragment on UserResponse {
      id
      type
      auth0Id
      status
      driver {
        id
      }
      owner {
        id
      }
    }
  `
};
