import { gql } from "@apollo/client";

import { UserFragments } from "../User/UserFragments";

export const DriverFragments = {
  driverRentalTable: gql`
    fragment DriverRentalTable on Driver {
      id
      user {
        ...UserRentalTable
      }
    }
    ${UserFragments.userRentalTable}
  `
};
