import { gql } from "@apollo/client";

export const RentalPeriodFragments = {
  rentalPeriod: gql`
    fragment RentalPeriod on RentalPeriod {
      id
      createdAt
      updatedAt
      startDate
      endDate
      numDays
      type
      protectionPlan
    }
  `,
  rentalPeriodDates: gql`
    fragment RentalPeriodDates on RentalPeriod {
      id
      createdAt
      updatedAt
      startDate
      endDate
    }
  `
};
