import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

export const SsnMask = ({ ...rest }) => (
  <MaskedInput
    {...rest}
    mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={"\u2000"}
  />
);

SsnMask.propTypes = {
  inputRef: PropTypes.func
};
