import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2)
  }
}));

export const DrawerPaddingContainer = ({ children, className }) => {
  const classes = useStyles();

  return <div className={[classes.root, className].join(" ")}>{children}</div>;
};

DrawerPaddingContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
};
