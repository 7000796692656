/* eslint-disable react/display-name */
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { reduxForm, Field } from "redux-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Link from "@mui/material/Link";
import get from "lodash/get";

import { RENTAL_DRIVER_PHOTOS } from "Queries/Rentals/RentalQueries";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { StepperContext } from "./CheckInFlowContainer";
import { FormRadioGroup } from "Components/Inputs/FormRadioGroup";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  imagesContainer: {
    marginTop: theme.spacing(2)
  },
  image: {
    width: "100%"
  }
}));

export const ConfirmDriverIdentity = reduxForm({
  form: "CHECK_IN_FLOW",
  destroyOnUnmount: false,
  validate: values => {
    const errors = {};

    if (!values.isDriverIdentified) {
      errors.isDriverIdentified = "Required";
    } else if (values.isDriverIdentified === "no") {
      errors.isDriverIdentified = "Please contact support!";
    }

    return errors;
  }
})(({ handleSubmit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const { getLink } = useContext(FirebaseContext);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(RENTAL_DRIVER_PHOTOS, {
    client: currentRooftopClient,
    variables: {
      id
    },
    fetchPolicy: "network-only"
  });
  const { nextStep, previousStep } = useContext(StepperContext);
  const onSubmit = handleSubmit(nextStep);
  const licensePhotoUrl = get(data, "rental.driver.licensePhoto.url");
  const profilePhotoUrl = get(data, "rental.driver.user.profilePhoto.url");

  const leftComponent = (
    <FlowContentContainer
      header="Confirm Driver's Identity"
      nextStep={onSubmit}
      nextStepLabel="Continue"
      previousStep={previousStep}
      previousStepLabel="Back"
    >
      <Typography component="h6" variant="h6" gutterBottom>
        Does the driver look like their license and profile image?
      </Typography>
      <Field
        name="isDriverIdentified"
        component={FormRadioGroup}
        options={[
          {
            label: "Yes",
            value: "yes"
          },
          {
            label: "No",
            value: "no"
          }
        ]}
      />
      {!loading && (
        <Grid container spacing={3} className={classes.imagesContainer}>
          <Grid item xs={12} md={6}>
            <img
              src={licensePhotoUrl}
              alt="Driver License"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={profilePhotoUrl}
              alt="Driver Profile"
              className={classes.image}
            />
          </Grid>
        </Grid>
      )}
    </FlowContentContainer>
  );

  const rightComponent = (
    <TooltipContainer header="Other Notes">
      <Tooltip
        paragraphs={[
          "As an extra layer of validation, we also suggest you ask the driver to view their Driver's License in person to make sure it matches.",
          "Additionally, you may want to double check that the name on the Insurance and Rental contract match the name of the driver's license.",
          () => (
            <Typography component="p" variant="body2" color="textSecondary">
              For help, refer to the Rental Pickup and Dropoff Policy{" "}
              <Link
                href={getLink("salesforcePickupDropOffPolicy")}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
              >
                Here
              </Link>
            </Typography>
          )
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
});
