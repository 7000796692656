import React, { useContext } from "react";
import { reduxForm, reset, formValueSelector } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsReimbursmentController";
import { ReimbursementSelectRentalFormFields } from "Components/Forms/FormFields/Claims/ReimbursementSelectRentalFormFields";
import { useClaimsReimbursementFormController } from "Components/Forms/FormControllers/hooks/useClaimsReimbursementFormController";
import { ContactSupportButton } from "Components/Buttons/ContactSupportButton";
import { RouteEnum } from "Enums/RouteEnum";

const ClaimsReimbursmentSelectRentalForm = compose(
  reduxForm({
    form: "CLAIMS_REIMBURSEMENT",
    destroyOnUnmount: false
  }),
  connect(null, { reset })
)(({ handleSubmit, prevStep, options, loading }) => {
  const theme = useTheme();
  const noOptions = options.length === 0;

  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      header="Select Rental"
      nextStepLabel="continue"
      previousStepLabel="Back"
      previousStep={prevStep}
      disabled={loading || noOptions}
    >
      {loading ? (
        <>
          <Skeleton width="50%" />
          <Skeleton width="40%" />
          <Skeleton
            variant="rectangular"
            height={theme.spacing(7)}
            style={{
              marginTop: theme.spacing(3)
            }}
          />
        </>
      ) : (
        <>
          {noOptions ? (
            <Typography component="h6" variant="h6">
              You do not have any eligible rentals for a claim.
              <br />
            </Typography>
          ) : (
            <ReimbursementSelectRentalFormFields options={options} />
          )}
        </>
      )}
    </FlowContentContainer>
  );
});

export const ClaimsReimbursmentSelectRental = connect(state => ({
  rentalId: formValueSelector("CLAIMS_REIMBURSEMENT")(state, "rentalId")
}))(({ options, loading, rentalId }) => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const { initialValues, validate } = useClaimsReimbursementFormController();

  const leftComponent = (
    <ClaimsReimbursmentSelectRentalForm
      initialValues={initialValues}
      validate={validate}
      onSubmit={nextStep}
      prevStep={prevStep}
      options={options}
      loading={loading}
    />
  );

  const rightComponent = (
    <TooltipContainer header="Helpful tips">
      <Tooltip
        header="What is a non-damage claim?"
        paragraphs={[
          "Non-damage claims include reimbursements for Cleaning, Gas, Impound Fees, Late Return, Mileage, or other incidentals not related to physical damage."
        ]}
      />
      <Tooltip
        header="Where can I report damage?"
        paragraphs={[
          props => (
            <div {...props}>
              Physical damage claims must be reported within 24 hours of
              Check-out using a separate form. You can submit a Damage Claim{" "}
              <Link
                component={RouterLink}
                to={{
                  pathname: `${RouteEnum.claimsDamage}`,
                  state: { id: rentalId }
                }}
                underline="hover"
              >
                HERE
              </Link>
              .
            </div>
          )
        ]}
      />
      <Tooltip
        header="Why don't I see the rental I'm looking for?"
        paragraphs={[
          "You will only be able to submit claims for rentals that ended within the past 24 hours. If you believe there is an error, please contact HyreCar Support.",
          <ContactSupportButton
            key={"24f2ac47-88d3-4af4-ad47-4e077019f41a"}
            preText="Need help? "
            buttonText="CONTACT SUPPORT"
          />
        ]}
      />
    </TooltipContainer>
  );
  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
});

ClaimsReimbursmentSelectRental.propTypes = {
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool
};
