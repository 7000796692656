import React, { createContext } from "react";
import { getUserPriceAlert } from "API/restAPI";
import { useQuery } from "react-query";
import { useQuery as useGraphqlQuery } from "@apollo/client";
import { getCarSummaryData } from "API/restAPI";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { get } from "lodash";

export const OverpricedCarsContext = createContext();

export const OverpricedCarsProvider = ({ children }) => {
  const { data } = useGraphqlQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-only"
  });

  const ownerId = get(data, "viewer.me.owner.id", null);
  const userId = get(data, "viewer.me.id", null);

  const { data: priceAlertResponse, refetch: refetchPriceAlert } = useQuery(
    ["priceAlert"],
    () => getUserPriceAlert(userId).then(response => response.data)
  );

  const {
    data: overpricedCars,
    isLoading: loading,
    isError,
    error,
    refetch: refetchCarSummary
  } = useQuery(
    ["overpricedCars"],
    () => getCarSummaryData(ownerId).then(response => response.data),
    {
      refetchOnMount: true,
      onError: error => console.error(error)
    }
  );

  const getOverpricedCarById = carId => {
    if (!overpricedCars) return null;
    return overpricedCars.data.find(car => car.carId === carId);
  };

  const isCarOverpriced = carId => {
    const car = getOverpricedCarById(carId);
    return car ? car.overpriced : false;
  };

  if (isError) {
    console.error("Failed to fetch overpriced cars:", error.message);
  }

  return (
    <OverpricedCarsContext.Provider
      value={{
        overpricedCars,
        getOverpricedCarById,
        isCarOverpriced,
        loading,
        priceAlertResponse,
        refetchPriceAlert,
        refetchCarSummary
      }}
    >
      {children}
    </OverpricedCarsContext.Provider>
  );
};
