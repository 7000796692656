import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import WarningButton from "Components/Buttons/WarningButton";
import BubbleContainer from "./BubbleContainer";

const UPDATE_PRICE_BUTTON_TEXT = "Edit Price / Protection Plan";

const useStyles = makeStyles(theme => ({
  warningText: {
    marginTop: 20,
    marginBottom: 20
  },
  h6: {
    fontWeight: 600,
    letterSpacing: "0.15px",
    lineHeight: "30px",
    marginBottom: props => props.h6warning && "10px",
    color: props => (props.h6Warning ? theme.palette.warning.dark : "#000")
  }
}));

const ImportantNote = ({ setModalOpen }) => {
  const { h6, warningText } = useStyles({ h6Warning: true });
  const title = "Important Note";
  const note = (
    <>
      You have selected to switch to a car with a{" "}
      <strong>
        <u>different daily price and protection plan.</u>
      </strong>
      <br />
      <br />
      By confirming this car switch, you agree to end the original rental and
      begin a new one that credits the driver through the rest of the original
      booking period, regardless of price and protection difference. Any future
      extensions will reflect the price and protection plan of the new car.
    </>
  );

  return (
    <BubbleContainer warning>
      <Typography variant="h6" className={h6}>
        {title}
      </Typography>
      <Typography variant="body2" className={warningText}>
        {note}
      </Typography>
      <WarningButton onClick={() => setModalOpen(true)}>
        {UPDATE_PRICE_BUTTON_TEXT}
      </WarningButton>
    </BubbleContainer>
  );
};

export default ImportantNote;
