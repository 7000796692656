import { useState, useContext } from "react";
import get from "lodash/get";

import {
  passwordCapitalRegex,
  passwordNumberRegex,
  passwordSpecialCharRegex
} from "Utils/Regex";
import { AuthContext } from "Login/Auth";

export const ConfirmResetPasswordFormController = ({ children, rpt }) => {
  const { confirmResetPassword } = useContext(AuthContext);
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const onSubmit = values => {
    confirmResetPassword({
      token: rpt,
      password: values.newPassword,
      confirmPassword: values.confirmNewPassword
    })
      .then(res => {
        const success = get(res, "data.confirmResetPassword.success", false);
        if (success) {
          setSuccess(true);
        } else {
          setErrorText("There was an error with your submission.");
        }
      })
      .catch(error => {
        console.error(error);
        setErrorText("There was an error with your submission.");
      });
  };

  const validate = values => {
    const errors = {};

    let passwordErrors = [];

    if (!values.newPassword) errors.newPassword = "Required";

    if (!values.confirmNewPassword) {
      errors.confirmNewPassword = "Required";
    } else if (values.confirmNewPassword !== values.newPassword) {
      errors.confirmNewPassword = "Passwords must match!";
    }

    if (!values.newPassword) values.newPassword = "";

    if (!passwordCapitalRegex.test(values.newPassword))
      passwordErrors.push("Capital");

    if (!passwordNumberRegex.test(values.newPassword))
      passwordErrors.push("Number");

    if (!passwordSpecialCharRegex.test(values.newPassword))
      passwordErrors.push("Special Character");

    if (passwordErrors.length !== 0) {
      let passwordErrorString = "Your password must contain a";

      if (passwordErrors.length === 1) {
        passwordErrorString = passwordErrorString.concat(
          ` ${passwordErrors[0]}.`
        );
      } else {
        for (let i = 0; i < passwordErrors.length; i++) {
          if (i === passwordErrors.length - 1) {
            passwordErrorString = passwordErrorString.concat(
              ` and ${passwordErrors[i]}.`
            );
          } else {
            passwordErrorString = passwordErrorString.concat(
              ` ${passwordErrors[i]},`
            );
          }
        }
      }

      errors.newPassword = passwordErrorString;
    }

    if (values.newPassword.length < 8) {
      if (errors.newPassword !== undefined) {
        errors.newPassword = errors.newPassword.concat(
          " Your password must be at least 8 characters in length."
        );
      } else {
        errors.newPassword =
          "Your password must be at least 8 characters in length.";
      }
    }

    return errors;
  };

  return children({
    validate,
    onSubmit,
    success,
    errorText,
    rpt
  });
};
