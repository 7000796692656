import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Link,
  Typography
} from "@mui/material";
import { handleSubmitFails } from "../../../../Components/Forms/FormUtils";
import { makeStyles } from "@mui/styles";
import React, { useContext, useState } from "react";
import { FormTextField } from "../../../../Components/Inputs/FormTextField";
import { toPascalCaseWithSingleDelimiter } from "../../../../Driver/utils/helpers";
import { FlowContentContainer } from "../../../../Components/Flows/FlowContentContainer";
import { EligibleCar } from "./EligibleCar";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IneligibleCar } from "./IneligibleCar";
import { validateVin } from "./validateVin";
import { FirebaseContext } from "../../../../Components/Utils/FirebaseProvider";
import { getCarInfoFromVin } from "../../../../Utils/getCarInfoFromVin";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  input: {
    width: 500,
    marginRight: 20
  },
  divider: {
    marginBottom: theme.spacing(3)
  },
  list: {
    marginBottom: theme.spacing(3)
  },
  listItemText: {
    color: theme.palette.text.secondary,
    margin: 0
  },
  blackText: {
    marginBottom: theme.spacing(2)
  },
  listItem: {
    padding: 0
  }
}));

export const CheckVin = compose(
  connect(state => ({
    initialValues: state.fleet.listing.initialFormValues,
    vin: formValueSelector("LIST_CAR_FLOW")(state, "vin")
  })),
  reduxForm({
    form: "LIST_CAR_FLOW",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    updateUnregisteredFields: true,
    onSubmitFail: (errors, dispatch, submitError, { values }) =>
      handleSubmitFails(errors, values)
  })
)(({ change, handleSubmit, vin }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [validVin, setValidVin] = useState(false);
  const [carInfo, setCarInfo] = useState();
  const [vinValidated, setVinValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { getJSONValue, getLink } = useContext(FirebaseContext);

  const handleChange = e => {
    if (vin && e.target.value != vin) {
      setValidVin(false);
    }
  };

  const VIN_LENGTH = 17;

  const validate = async () => {
    setLoading(true);
    if (vin.trim().length === VIN_LENGTH) {
      const carInfo = await getCarInfoFromVin(vin);
      const result = carInfo.data.Results[0];
      const ineligibleMakes = getJSONValue("ineligible_makes");
      const ineligibleMakeAndModel = getJSONValue("ineligible_make_and_model");
      const validatorResponse = await validateVin(
        result,
        ineligibleMakes,
        ineligibleMakeAndModel
      );
      const { success, message } = validatorResponse;

      if (carInfo && result && success) {
        setValidVin(true);
        const reformattedMake = toPascalCaseWithSingleDelimiter(result.Make);
        setCarInfo({
          make: reformattedMake,
          model: result.Model,
          year: result.ModelYear
        });
        change("make", reformattedMake);
        change("model", result.Model);
        change("year", result.ModelYear);

        analytics.track(AnalyticsEvents.label.owner.vinValidationSuccess, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.buttonClicked,
          label: AnalyticsEvents.label.owner.vinValidationSuccess,
          property: JSON.stringify({}),
          value: "",
          context: ""
        });
      } else {
        message.length > 0 && setErrorMessage(message);

        analytics.track(AnalyticsEvents.label.owner.vinInputError, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.errorShown,
          label: AnalyticsEvents.label.owner.vinInputError,
          property: JSON.stringify({
            error: message
          }),
          value: "",
          context: ""
        });

        setValidVin(false);
      }
    } else {
      setErrorMessage(
        `VIN is invalid, please input a ${VIN_LENGTH} character VIN`
      );
      setValidVin(false);
    }
    setLoading(false);
    setVinValidated(true);
  };

  return (
    <FlowContentContainer
      header="Getting started"
      stepId="continue-eligibility-check"
      nextStep={handleSubmit}
      nextStepLabel="Continue"
      nextStepId="continue-car-details"
      disabled={!validVin}
    >
      <Typography
        component="div"
        variant="subtitle1"
        className={classes.blackText}
      >
        {"Before we get started, let's determine if your car meets "}
        <Link href={getLink("vehicleListingRequirements")} target="_blank">
          our requirements.
        </Link>
      </Typography>
      <Box className={classes.container}>
        <Field
          id="vin-field"
          name="vin"
          label="VIN"
          className={classes.input}
          variant="filled"
          disabled={loading && !validVin}
          onChange={handleChange}
          InputProps={{
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress className={classes.circleProgress} />
              </InputAdornment>
            ) : null
          }}
          inputProps={{
            maxLength: VIN_LENGTH,
            style: { textTransform: "uppercase" }
          }}
          characterLimit={VIN_LENGTH}
          component={FormTextField}
        />
        <Button
          id="check-vin"
          color="primary"
          variant="contained"
          size="medium"
          onClick={validate}
          disabled={!vin}
        >
          Check
        </Button>
      </Box>
      {validVin && carInfo && <EligibleCar carInfo={carInfo} />}
      {!loading && vinValidated && !validVin && errorMessage && (
        <IneligibleCar message={errorMessage} />
      )}
    </FlowContentContainer>
  );
});
