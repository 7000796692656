import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import get from "lodash/get";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import { RouteEnum } from "Enums/RouteEnum";
import { BasicHeader } from "Components/Header/BasicHeader";
import { ClaimType } from "Fleet/Flows/Claims/ClaimsViews/ClaimType/ClaimType";
import { ClaimsDamageController } from "Fleet/Flows/Claims/ClaimsViews/ClaimsDamage/ClaimsDamageController";
import { ClaimsReimbursmentController } from "Fleet/Flows/Claims/ClaimsViews/ClaimsReimbursment/ClaimsReimbursmentController";
import { ClaimsTicketsController } from "Fleet/Flows/Claims/ClaimsViews/ClaimsTickets/ClaimsTicketsController";
import { MobileHeader } from "Components/Header/MobileHeader";
import { ActionDialog } from "Components/Dialog/ActionDialog";

import { openSupportPage } from "Utils/openSupportPage";

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText
  },
  icon: {
    color: theme.palette.primary.contrastText
  },
  listItem: {
    display: "list-item",
    paddingLeft: theme.spacing(0.5)
  },
  orderedList: {
    listStyleType: "decimal",
    paddingLeft: theme.spacing(2),
    fontSize: "1rem"
  },
  box: {
    margin: 0
  },
  whatsNext: {
    color: theme.palette.common.black
  },
  paragraph: {
    marginBottom: theme.spacing(3)
  },
  cancelButtonRoot: {
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark
    },
    color: "#fff"
  },
  claimId: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1.12rem"
    }
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  }
}));

const DamageClaimText = () => {
  const classes = useStyles();

  return (
    <>
      <Typography
        component="p"
        variant="h6"
        gutterBottom
        className={classes.whatsNext}
      >
        What&apos;s next?
      </Typography>
      <Typography component="p" variant="body1" className={classes.paragraph}>
        <Box
          fontWeight={500}
          fontSize="h6.fontSize"
          m={1}
          className={classes.box}
        >
          For Interior Damage / Mechanical claims:
        </Box>
        Please check that your point of contact received a confirmation email
        from HyreCar. Our internal customer service specialists will process
        your claim and follow up with next steps.
      </Typography>
      <Typography component="p" variant="body1" className={classes.paragraph}>
        <Box
          fontWeight={500}
          fontSize="h6.fontSize"
          m={1}
          className={classes.box}
        >
          For Exterior Damage claims:
        </Box>
        Please check that your point of contact received a confirmation email
        from Sedgwick. Sedgwick, a third party administrator, is assessing your
        exterior damage claim and will follow up with next steps.
      </Typography>
      <Typography component="p" variant="body1" className={classes.paragraph}>
        In addition, we temporarily snoozed your listing. Once the damage has
        been repaired, you can make your listing &apos;Live&apos; on the
        marketplace.
      </Typography>
      <Typography component="p" variant="h6" className={classes.paragraph}>
        Got questions?{" "}
        <Link
          onClick={() => openSupportPage()}
          component="button"
          underline="hover"
        >
          CONTACT SUPPORT
        </Link>
      </Typography>
    </>
  );
};

const ReimbursementClaimText = () => {
  const classes = useStyles();
  return (
    <>
      <Typography component="p" variant="body1" gutterBottom>
        Next steps:
      </Typography>
      <List component="ol" dense className={classes.orderedList}>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Check that your point of contact received a confirmation email."
            disableTypography
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="If you did not submit all of the information, please reply to the confirmation email with any additional images or details within 48 hours."
            disableTypography
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="HyreCar's customer service specialists will process your claim and will reach out to the point of contact with an update."
            disableTypography
          />
        </ListItem>
      </List>
      <Typography component="p" variant="body1" gutterBottom>
        Got questions? Contact{" "}
        <Link
          onClick={() => openSupportPage()}
          component="button"
          underline="hover"
        >
          HyreCar Support
        </Link>
      </Typography>
    </>
  );
};

export const ClaimsController = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const location = useLocation();
  const [dialogOpen, setDialogOpened] = useState(false);
  const [submittedDialogOpen, setSubmittedDialogOpen] = useState(false);
  const [claimId, setClaimId] = useState("");
  const [claimType, setClaimType] = useState("");

  const handleDialogOpen = () => setDialogOpened(true);
  const handleDialogClose = () => setDialogOpened(false);

  const handleGoBack = () => {
    history.replace(RouteEnum.claims, { ...location.state, claimId: "" });
    handleDialogClose();
  };

  const handleExitClaims = () => {
    history.replace(RouteEnum.rentalsPast, {
      ...location.state,
      claimId: ""
    });
    const rentalId = get(history, "location.state.id");
    const url = rentalId
      ? `${RouteEnum.rentalsPast}/${rentalId}`
      : `${RouteEnum.rentalsPast}`;
    history.push(url);
  };

  const handleExitClick = () => {
    if (history.location.pathname.endsWith("claims")) {
      handleExitClaims();
    } else {
      handleDialogOpen();
    }
  };

  const handleFileAnotherClaimClick = () => {
    setSubmittedDialogOpen(false);
    setClaimId("");
    history.replace(location.pathname, { ...location.state, claimId: "" });
  };

  const handleDoneClick = () => {
    setSubmittedDialogOpen(false);
    setClaimId("");
    history.replace(RouteEnum.rentalsPast, { ...location.state, claimId: "" });
    const { id } = get(location, "state");
    history.push(`${RouteEnum.rentalsPast}/${id}`);
  };

  useEffect(() => {
    setClaimId(get(location, "state.claimId", ""));
    setClaimType(get(location, "state.claimType"));

    if (claimId !== "") {
      setSubmittedDialogOpen(true);
    } else {
      setSubmittedDialogOpen(false);
    }
  }, [location, claimId]);

  return (
    <>
      {matches ? (
        <MobileHeader
          title="File a Claim"
          iconButton={
            <IconButton
              className={classes.icon}
              onClick={() => history.goBack()}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          }
        />
      ) : (
        <BasicHeader>
          <div className={classes.container}>
            <Button
              className={classes.button}
              id="exit-report-accident"
              onClick={handleExitClick}
            >
              Exit
            </Button>
          </div>
        </BasicHeader>
      )}
      <ActionDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title="Are you sure?"
        content={
          <>
            <Typography component={"p"}>
              By exiting the claims form, you will lose all progress.
            </Typography>
            <Typography component={"p"}>
              You only have 24 hours to file a claim after Check-out.
            </Typography>
            <br />
            <Typography component={"p"}>
              Are you sure you want to exit?
            </Typography>
          </>
        }
        action={handleDialogClose}
        actionLabel="No, Continue"
        cancel={handleGoBack}
        cancelLabel="Yes, Exit"
        cancelOnRight
      />
      <ActionDialog
        open={submittedDialogOpen}
        onClose={handleFileAnotherClaimClick}
        title={
          <>
            Submitted Claim <span className={classes.claimId}>#{claimId}</span>
          </>
        }
        maxWidth="sm"
        content={
          <>
            {claimType === "damage" && <DamageClaimText />}
            {claimType === "reimbursement" && <ReimbursementClaimText />}
            {claimType === "tickets" && <h1>ticket toll text here</h1>}
          </>
        }
        actionLabel="File Another claim"
        action={handleFileAnotherClaimClick}
        cancelLabel="Done"
        cancel={handleDoneClick}
        cancelOnRight
        cancelVariant="contained"
        classes={{ cancel: classes.cancelButtonRoot }}
      />
      <Switch>
        <Route exact path={RouteEnum.claims} component={ClaimType} />
        <Route
          path={RouteEnum.claimsDamage}
          component={ClaimsDamageController}
        />
        <Route
          path={RouteEnum.claimsReimbursment}
          component={ClaimsReimbursmentController}
        />
        <Route
          path={RouteEnum.claimsTickets}
          component={ClaimsTicketsController}
        />
      </Switch>
    </>
  );
};
