import React from "react";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

import StepAppBar from "../../StepAppBar";
import SelectCarSearch from "./selectCarAppBar/SelectCarSearch";

const SelectCarAppBar = ({
  searching,
  handleSearch,
  searchValue,
  onClose,
  openSearching,
  closeSearching
}) => {
  const searchIcon = (
    <IconButton onClick={openSearching} size="large">
      <SearchIcon />
    </IconButton>
  );
  const closeIcon = (
    <IconButton onClick={closeSearching} size="large">
      <CancelIcon />
    </IconButton>
  );
  return (
    <StepAppBar
      title={!searching && "Select a New Car"}
      onClose={onClose}
      content={
        searching && (
          <SelectCarSearch
            searchValue={searchValue}
            handleSearch={handleSearch}
          />
        )
      }
      rightIcon={searching ? closeIcon : searchIcon}
    />
  );
};

SelectCarAppBar.propTypes = {
  searching: PropTypes.bool,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  onClose: PropTypes.func,
  openSearching: PropTypes.func,
  closeSearching: PropTypes.func
};

export default SelectCarAppBar;
