import React from "react";
import { Box, ListItemText, Typography } from "@mui/material";
import { List, ListItem } from "react-bootstrap/lib/Media";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.common.black,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  body: { marginTop: theme.spacing(2) }
}));

export const EligibleCar = ({ carInfo }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography
        className={classes.header}
        variant="body1"
        fontWeight="bold"
      >{`Great! Your ${carInfo.year} ${carInfo.make} ${carInfo.model} is eligible to be listed.`}</Typography>
      <Typography>
        {`This only checks if your make and model meets our requirements. We do not take salvaged and manual transmission vehicles.`}
      </Typography>
      <Typography
        className={classes.body}
      >{`Before you proceed listing your car, here are things you need:`}</Typography>
      <List>
        <ListItem>
          <ListItemText>{`Car Information (Mileage, License Plate etc)`}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>{`Well-lit Photos of your car`}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {`Car documents (Inspection, Registration and Insurance)`}
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};
