import { gql } from "@apollo/client";

import { UserFragments } from "../../Queries/User/UserFragments";
import { FileFragments } from "../../Queries/File/FileFragments";

export const UserMutationFragments = {
  userSendEmail: gql`
    fragment UserSendEmail on AdminSendEmailPayload {
      code
      message
      success
    }
  `,
  loginUserPayload: gql`
    fragment LoginUserPayload on LoginUserPayload {
      user {
        ...UserType
      }
    }
    ${UserFragments.userType}
  `,
  updateProfilePayload: gql`
    fragment UpdateProfilePayload on UpdateProfilePayload {
      code
      message
      success
      user {
        ...UserProfileInfo
      }
    }
    ${UserFragments.userProfileInfo}
  `,
  updateProfilePhotoPayload: gql`
    fragment UpdateProfilePhotoPayload on UpdateProfilePhotoPayload {
      code
      success
      message
      user {
        profilePhoto {
          ...File
        }
      }
    }
    ${FileFragments.file}
  `
};
