import React from "react";
import { Field } from "redux-form";

import { ProtectionCardSelect } from "Components/Cards/ProtectionCardSelect";

export const ProtectionFields = () => {
  return (
    <Field
      name="defaultProtectionPlan"
      component={ProtectionCardSelect}
      manual
    />
  );
};
