import React, { useState } from "react";
import { reduxForm, change, formValues } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import PropTypes from "prop-types";

import { EditUserFormController } from "Components/Forms/FormControllers/EditUserFormController";
import { DrawerContainer } from "Components/Drawers/DrawerContainer";
import { EditUserFormFields } from "Components/Forms/FormFields/EditUserFormFields";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { ActionDialog } from "Components/Dialog/ActionDialog";
import { RouterDrawer } from "Components/Drawers/RouterDrawer";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";

const useStyles = makeStyles(theme => ({
  helpIcon: {
    marginLeft: "auto"
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  }
}));

const DeleteDialogText = () => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="body1"
        component="p"
        className={classes.marginBottom}
      >
        Deleting this user cannot be undone. You will lose all event history
        associated with this account. Are you sure you want to continue?
      </Typography>
      <Typography variant="body1" component="p">
        You can also temporarily remove access by deactivating the account
        instead of deleting.
      </Typography>
    </>
  );
};

const DeactivateDialogText = () => (
  <Typography variant="body1" component="p">
    Deactivating this account will temporarily remove the ability for this user
    to login. Are you sure you want to continue?
  </Typography>
);

const EditUserForm = compose(
  reduxForm({
    form: "TEAM_EDIT_USER_FORM"
  }),
  formValues({
    accountStatus: "accountStatus"
  }),
  connect(null, { change })
)(
  ({
    handleSubmit,
    submitting,
    onClose,
    initialValues,
    accountStatus,
    roleOptions,
    change
  }) => {
    const [open, setDialogOpen] = useState(false);
    // const [cancelLabel, setCancelLabel] = useState("");
    const initial_accountStatus = get(initialValues, "accountStatus", "");

    const handleCloseDialog = () => setDialogOpen(false);

    const handleActionDialog = () => {
      setDialogOpen(false);
      change("TEAM_EDIT_USER_FORM", "accountStatus", initial_accountStatus);
    };

    return (
      <>
        <DrawerPaddingContainer>
          <EditUserFormFields roleOptions={roleOptions} />
        </DrawerPaddingContainer>
        <DrawerBottomBar
          onCancel={onClose}
          cancelText="cancel"
          onSubmit={handleSubmit}
          submitText={submitting ? "submitting" : "submit"}
          disabled={submitting}
        />
        <ActionDialog
          title="Are you sure?"
          content={
            <>
              {accountStatus === "Delete" && <DeleteDialogText />}
              {accountStatus === "Deactivated" && <DeactivateDialogText />}
            </>
          }
          open={open}
          cancel={handleCloseDialog}
          // cancelLabel={cancelLabel}
          cancelOnRight
          actionLabel="No, Cancel"
          action={handleActionDialog}
        />
      </>
    );
  }
);

export const EditUserDrawer = ({ handleClose, handleHelpDialogOpen }) => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <DrawerContainer>
      <DrawerAppBar
        icon={
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        }
        title="Edit Member"
        border={true}
        rightIcon={
          <IconButton
            onClick={handleHelpDialogOpen}
            className={classes.helpIcon}
            size="large"
          >
            <HelpOutlineIcon />
          </IconButton>
        }
      />
      <EditUserFormController onClose={handleClose} memberId={id}>
        {props =>
          props.loading ? <SkeletonDrawer /> : <EditUserForm {...props} />
        }
      </EditUserFormController>
    </DrawerContainer>
  );
};

EditUserDrawer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleHelpDialogOpen: PropTypes.func.isRequired
};

export const TeamEditUserDrawer = ({ handleHelpDialogOpen }) => {
  return (
    <>
      <RouterDrawer>
        {({ handleClose }) => (
          <EditUserDrawer
            handleClose={handleClose}
            handleHelpDialogOpen={handleHelpDialogOpen}
          />
        )}
      </RouterDrawer>
    </>
  );
};

TeamEditUserDrawer.propTypes = {
  handleHelpDialogOpen: PropTypes.func.isRequired
};
