export const statesThatDontRequireInspection = [
  "CT",
  "GA",
  "ID",
  "IN",
  "IA",
  "ME",
  "MN",
  "MS",
  "MO",
  "MT",
  "NJ",
  "ND",
  "OH",
  "OK",
  "TN",
  "TX",
  "UT",
  "WV",
  "WI",
  "WY"
];
