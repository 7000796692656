import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import jwtDecode from "jwt-decode";
import moment from "moment";

import config from "Config/config";
import { ConfirmResetPasswordFormController } from "Components/Forms/FormControllers/ConfirmResetPasswordFormController";
import { FormTextField } from "Components/Inputs/FormTextField";

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(4, 0, 4, 0)
  }
}));

const ConfirmResetPassword = reduxForm({
  form: "CONFIRM_FORGOT_PASSWORD"
})(({ handleSubmit, submitting, success, errorText, rpt }) => {
  const classes = useStyles();
  const decoded = jwtDecode(rpt);
  const [expired, setExpired] = useState(false);
  const { expiration } = decoded;

  useEffect(() => {
    if (moment(expiration).isSameOrBefore(moment())) {
      setExpired(true);
    }
  }, [expiration]);

  return (
    <>
      <Typography
        component="h6"
        variant="h6"
        align="center"
        className={classes.title}
      >
        {success ? "Success!" : "Create a new password."}
      </Typography>
      {success ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="span">
              Your password has been reset. Please log in with your new
              password.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                window.location.replace(config.gluu.redirect_uri);
              }}
              color="primary"
              fullWidth
              variant="contained"
            >
              Back to Log In
            </Button>
          </Grid>
        </Grid>
      ) : expired ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="span">
              We&apos;re sorry, but this request has expired or is invalid.
              Please navigate back to the login page and select &quot;Forgot
              Password&quot; to send another email link.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                window.location.replace(config.gluu.redirect_uri);
              }}
              fullWidth
              color="primary"
              variant="contained"
            >
              Back to Log In
            </Button>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                name="newPassword"
                type="password"
                placeholder="New Password"
                label="New Password"
                component={FormTextField}
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="confirmNewPassword"
                type="password"
                placeholder="Confirm New Password"
                label="New Password"
                component={FormTextField}
                variant="filled"
                fullWidth
              />
            </Grid>
          </Grid>
          {errorText && (
            <Typography component="span" color="error" display="block">
              {errorText}
            </Typography>
          )}
          <Button
            type="submit"
            disabled={submitting}
            fullWidth
            color="primary"
            variant="contained"
            style={{
              marginTop: "32px"
            }}
          >
            {submitting ? "Resetting Password..." : "Reset Password"}
          </Button>
        </form>
      )}
    </>
  );
});

export const ConfirmResetPasswordForm = ({ rpt }) => {
  return (
    <ConfirmResetPasswordFormController rpt={rpt}>
      {props => <ConfirmResetPassword {...props} />}
    </ConfirmResetPasswordFormController>
  );
};
