import React, { useContext } from "react";
import { reduxForm, Field } from "redux-form";
import { compose } from "recompose";

import {
  LocationAutoComplete,
  validateLocation
} from "Components/Forms/Location/LocationAutoComplete";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./CSVController";
import { useCSVFormController } from "Components/Forms/FormControllers/hooks/useCSVFormController";
import { ContactSupportButton } from "Components/Buttons/ContactSupportButton";

const CSVPickupAddressForm = compose(
  reduxForm({
    form: "CSV_IMPORT",
    destroyOnUnmount: false,
    validate: values => validateLocation(values, "address")
  })
)(({ handleSubmit, prevStep }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      header="What address will drivers be picking up your cars?"
      nextStepLabel="continue"
      previousStepLabel="back"
      previousStep={prevStep}
    >
      <Field
        name="address"
        component={LocationAutoComplete}
        showMap
        variant="filled"
      />
    </FlowContentContainer>
  );
});

export const CSVPickupAddressContainer = () => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const { initialValues } = useCSVFormController();

  const leftComponent = (
    <CSVPickupAddressForm
      onSubmit={nextStep}
      prevStep={prevStep}
      initialValues={initialValues}
    />
  );

  const rightComponent = (
    <TooltipContainer header="Helpful Tips">
      <Tooltip
        header="How do I list cars to different addresses?"
        paragraphs={[
          "Right now you can bulk import cars to only one pickup address at a time. We suggest bulk importing groups of cars that you plan to keep at the same address.",
          "Furthermore, you can always edit the pickup address of each individual listing at any time in the future.",
          <ContactSupportButton
            key="1174c45c-1e90-4327-b4ab-4b97473b37e3"
            preText="Need help? "
            buttonText="CONTACT SUPPORT"
          />
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
};
