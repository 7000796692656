export const DEFAULT_LOCATION = {
  geo_lat: 34.0522,
  geo_lng: -118.2437,
  city: "Los Angeles",
  state: "CA",
  zip: "90012"
};

export const GOOGLE_GEOCODE_STATUS = {
  OK: "OK"
};

export const NAVIGATOR_GEOLOCATION_PERMISSION_STATE = {
  GRANTED: "granted"
};
