/* eslint-disable react/jsx-key */
import React from "react";
import { EmptyStateIcon } from "Fleet/Dashboard/DashboardViews/Financials/Tables/EmptyStateIcon";
import TablePlaceholder from "Fleet/Dashboard/DashboardViews/Financials/Tables/TablePlaceholder";

const EmptyPlaceholder = ({ header, subHeader }) => (
  <TablePlaceholder
    SourceImageComponent={EmptyStateIcon}
    header={header}
    subHeader={subHeader}
  />
);

export default EmptyPlaceholder;
