import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import get from "lodash/get";
import jwtDecode from "jwt-decode";

import { FormTextField } from "Components/Inputs/FormTextField";
import { FormSelect } from "Components/Inputs/FormSelect";
import { FormSelectCheckbox } from "Components/Inputs/FormSelectCheckbox";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { useOrganizationRoleOptions } from "Components/Forms/FormControllers/hooks/useOrganizationRoleOptions";

// import upperFirst from "lodash/upperFirst";

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(4)
  },
  footer: {
    padding: ({ compact }) => (compact ? null : theme.spacing(0, 4)),
    marginTop: theme.spacing(4)
  },
  container: {
    padding: ({ compact }) => (compact ? null : theme.spacing(0, 4))
  },
  button: {
    padding: theme.spacing(1, 4)
  }
}));

export const InviteMemberFormFields = ({ compact }) => {
  const { currentOrganizationInfo, getOrganizationRooftops } = useContext(
    ClientFactoryContext
  );
  const classes = useStyles({ compact });
  const rooftopTokens = getOrganizationRooftops(currentOrganizationInfo.id);
  const { loading, roleOptions } = useOrganizationRoleOptions();

  return (
    <>
      <Grid container className={classes.container} spacing={4}>
        {/* <Grid item xs={12} md={6}>
          <Field
            name="first_name"
            label="First Name*"
            fullWidth
            variant="filled"
            component={FormTextField}
            normalize={value => upperFirst(value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="last_name"
            label="Last Name*"
            fullWidth
            variant="filled"
            component={FormTextField}
            normalize={value => upperFirst(value)}
          />
        </Grid> */}
        <Grid item xs={12} md={compact ? 12 : 7}>
          <Field
            name="email"
            label="Email*"
            fullWidth
            variant="filled"
            component={FormTextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
            showSkeleton={loading}
          />
        </Grid>
        <Grid item xs={12} md={compact ? 12 : 7}>
          <Field
            name="role"
            label="Select Role*"
            fullWidth
            variant="filled"
            component={FormSelect}
            options={roleOptions}
            showSkeleton={loading}
          />
        </Grid>
        <Grid item xs={12} md={compact ? 12 : 7}>
          <Field
            name="rooftops"
            component={FormSelectCheckbox}
            options={rooftopTokens.map(rooftopToken => ({
              value: get(jwtDecode(rooftopToken), "id"),
              label: get(jwtDecode(rooftopToken), "name", "")
            }))}
            label="Select Rooftop(s)*"
            fullWidth
            primaryColor
            showSkeleton={loading}
          />
        </Grid>
      </Grid>
    </>
  );
};

InviteMemberFormFields.propTypes = {
  compact: PropTypes.bool
};
