import React, { useContext } from "react";
import { reduxForm } from "redux-form";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsDamageController";
import { PointOfContactFormFields } from "Components/Forms/FormFields/Claims/PointOfContactFormFields";
import { useClaimsDamageFormController } from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";
import { ContactSupportButton } from "Components/Buttons/ContactSupportButton";

const ClaimsDamagePointOfContactForm = reduxForm({
  form: "CLAIMS_DAMAGE",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      header="Point of Contact"
      nextStepLabel="continue"
      nextStepId="continue-pointofcontact"
      previousStepLabel="back"
      previousStep={prevStep}
      previousStepId="back-pointofcontact"
    >
      <PointOfContactFormFields />
    </FlowContentContainer>
  );
});

export const ClaimsDamagePointOfContact = () => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const { validate } = useClaimsDamageFormController();

  const leftComponent = (
    <ClaimsDamagePointOfContactForm
      onSubmit={nextStep}
      prevStep={prevStep}
      validate={validate}
    />
  );

  const rightComponent = (
    <TooltipContainer header="Helpful Tips">
      <Tooltip
        header="Why do we need this?"
        paragraphs={[
          "We want to make sure that you never miss important information from us regarding your claim. That’s why we double check we have your accurate details. We also give you an opportunity to clarify if someone else should receive cooresponence from our claims adjusters.",
          <ContactSupportButton
            key="1e342b95-1025-4384-b31c-ba66e29ffd96"
            preText="Need help? "
            buttonText="CONTACT SUPPORT"
          />
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
};
