import { zendeskJwtAuthentication } from "API/restAPI";

const env = process.env.REACT_APP_STAGE;

const jwtAuthentication = callback => {
  zendeskJwtAuthentication()
    .then(res => {
      const token = res.data.jwt;
      callback(token);
      console.log("Zendesk Widget initialized");
    })
    .catch(e => {
      console.log("handler error", e);
    });
};

const SHOW_ACTION =
  env === "production" ? ["messenger", "show"] : ["webWidget", "show"];
const HIDE_ACTION =
  env === "production" ? ["messenger", "hide"] : ["webWidget", "hide"];
const LOGOUT_ACTION = ["messenger", "logoutUser"];
const LOGIN_ACTION = ["messenger", "loginUser"];

export const showZendeskChat = zendeskClient => zendeskClient(...SHOW_ACTION);
export const hideZendeskChat = zendeskClient => zendeskClient(...HIDE_ACTION);
export const logoutZendeskChat = zendeskClient =>
  env === "production" && zendeskClient(...LOGOUT_ACTION);
export const loginZendeskChat = zendeskClient =>
  env === "production" && zendeskClient(...LOGIN_ACTION, jwtAuthentication);
