import { gql } from "@apollo/client";

export const CarFragments = {
  carInfo: gql`
    fragment CarInfo on Car {
      id
      createdAt
      updatedAt
      description
      make
      model
      year
      status
      verification
      defaultProtectionPlan
      vin
      licensePlate
      mileage
      dailyPriceInCents
      weeklyPriceInCents
      monthlyPriceInCents
      maxDailyMiles
      maxWeeklyMiles
      maxMonthlyMiles
      registrationExpirationAt
    }
  `,
  carListing: gql`
    fragment CarListing on Car {
      id
      createdAt
      updatedAt
      status
      verification
      defaultProtectionPlan
      vin
      year
      make
      model
      mileage
      dailyPriceInCents
      weeklyPriceInCents
      monthlyPriceInCents
      maxDailyMiles
      description
      registrationExpirationAt
      insuranceExpirationAt
      inspectionExpirationAt
      licensePlate
      photos(where: { isPrimary: true }) {
        id
        isPrimary
        order
        file {
          id
          url
          path
          name
          contentType
          thumbnailUrl
        }
      }
    }
  `,
  carSwitchOption: gql`
    fragment CarSwitchOption on Car {
      id
      vin
      year
      make
      model
      licensePlate
      defaultProtectionPlan
      dailyPriceInCents
      weeklyPriceInCents
      monthlyPriceInCents
      registrationExpirationAt
      insuranceExpirationAt
      inspectionExpirationAt
      photos(where: { isPrimary: true }) {
        file {
          id
          url
        }
      }
    }
  `,
  carExpirationDatesOptions: gql`
    fragment CarExpirationDatesOptions on Car {
      id
      vin
      year
      make
      model
      licensePlate
      registrationExpirationAt
      insuranceExpirationAt
      inspectionExpirationAt
      photos(where: { isPrimary: true }) {
        file {
          id
          url
        }
      }
    }
  `,
  carRentalRequest: gql`
    fragment CarRentalRequest on Car {
      id
      vin
      make
      model
      year
      dailyPriceInCents
      defaultProtectionPlan
    }
  `,
  carPhoto: gql`
    fragment CarPhoto on CarPhoto {
      id
      isPrimary
      order
      file {
        id
        url
        path
        name
        contentType
        thumbnailUrl
      }
    }
  `,
  carDocument: gql`
    fragment CarDocument on CarDocument {
      id
      type
      fileId
      file {
        id
        url
        path
        name
        contentType
        thumbnailUrl
      }
    }
  `,
  carLocation: gql`
    fragment CarLocation on CarLocation {
      id
      formattedAddress
      city
      state
      street
      zip
      lat
      lng
    }
  `,
  carStatus: gql`
    fragment CarStatus on Car {
      id
      status
    }
  `,
  carRegistration: gql`
    fragment CarRegistration on Car {
      id
      registrationExpirationAt
    }
  `,
  carInsurance: gql`
    fragment CarInsurance on Car {
      id
      insuranceExpirationAt
    }
  `,
  carInspection: gql`
    fragment CarInspection on Car {
      id
      inspectionExpirationAt
    }
  `,
  carDocumentsExpiration: gql`
    fragment CarDocumentsExpiration on Car {
      id
      insuranceExpirationAt
      registrationExpirationAt
      inspectionExpirationAt
    }
  `,
  carDefaultProtectionPlan: gql`
    fragment CarDefaultProtectionPlan on Car {
      id
      defaultProtectionPlan
      status
    }
  `,
  carDetails: gql`
    fragment CarDetails on Car {
      make
      model
      year
    }
  `
};
