import React, { createContext, useState } from "react";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";

import { ActionDialog } from "Components/Dialog/ActionDialog";

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: "flex",
    alignItems: "center"
  },
  confirmActionButton: {
    fontWeight: 600
  },
  warningIcon: {
    marginRight: theme.spacing(3)
  },
  content: {
    marginLeft: theme.spacing(7.2)
  }
}));

const Modal = ({
  closeCallback,
  classes,
  open,
  setOpen,
  setUnsavedProgressExistsState,
  unsavedProgressExists
}) => (
  <ActionDialog
    action={() => {
      closeCallback && closeCallback();
      setOpen(false);
      setUnsavedProgressExistsState(false);
    }}
    actionClass={classes.confirmActionButton}
    actionLabel="Close without saving"
    cancel={() => {
      setOpen(false);
    }}
    cancelLabel={"Back"}
    cancelVariant={"string"}
    content={
      <Typography component="p" className={classes.content}>
        {`All changes will be lost. Do you want to continue?`}
      </Typography>
    }
    noTextTransform={true}
    onBackdropClick={() => setOpen(false)}
    open={unsavedProgressExists && open}
    title={
      <div className={classes.titleContainer}>
        <WarningIcon
          fontSize="large"
          color="error"
          className={classes.warningIcon}
        />
        <div>You have unsaved changes</div>
      </div>
    }
  />
);

export const UnsavedProgressDialogContext = createContext();

export const UnsavedProgressDialogProvider = ({ children }) => {
  const classes = useStyles();

  const [unsavedProgressExists, setUnsavedProgressExists] = useState(false);
  const setUnsavedProgressExistsState = boolean =>
    setUnsavedProgressExists(boolean);

  const [closeCallback, setCloseCallback] = useState();
  const setCloseUnsavedProgressCallback = callback =>
    setCloseCallback(callback);

  const [
    displayUnsavedProgressDialogContext,
    setDisplayUnsavedProgressDialog
  ] = useState(false);

  return (
    <UnsavedProgressDialogContext.Provider
      value={{
        displayUnsavedProgressDialogContext,

        setCloseUnsavedProgressCallback,
        setDisplayUnsavedProgressDialog,

        setUnsavedProgressExistsState,
        unsavedProgressExists
      }}
    >
      <Modal
        closeCallback={closeCallback}
        classes={classes}
        open={displayUnsavedProgressDialogContext}
        setOpen={setDisplayUnsavedProgressDialog}
        setUnsavedProgressExistsState={setUnsavedProgressExistsState}
        unsavedProgressExists={unsavedProgressExists}
      />
      {children}
    </UnsavedProgressDialogContext.Provider>
  );
};
