import React, { useEffect, useState, useContext } from "react";

import { useQuery } from "@apollo/client";

import { CURRENT_PAYOUT_ACCOUNT_QUERY } from "Queries/User/UserQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { PaymentDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { checkPermission } from "Utils/RooftopUtils";

export const PayoutConnectContext = React.createContext();

export const PayoutConnectProvider = ({ children }) => {
  const [currentlyDue, setCurrentlyDue] = useState([]);
  const [pastDue, setPastDue] = useState([]);
  const [eventuallyDue, setEventuallyDue] = useState([]);

  const { currentRooftopClient, scopes } = useContext(ClientFactoryContext);

  const { data, loading } = useQuery(CURRENT_PAYOUT_ACCOUNT_QUERY, {
    client: currentRooftopClient,
    context: {
      apiv2: true
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    const requirements =
      data?.getPayoutAccountInfo?.payoutAccounts?.stripePayoutInformation
        ?.account?.requirements;

    if (requirements?.pastDue?.length) {
      setPastDue(requirements.pastDue);
    }
    if (requirements?.currentlyDue?.length) {
      setCurrentlyDue(requirements.currentlyDue);
    }
    if (requirements?.eventuallyDue?.length) {
      setEventuallyDue(requirements.eventuallyDue);
    }
  }, [data]);

  return (
    <PayoutConnectContext.Provider
      value={{
        currentlyDue: currentlyDue,
        pastDue: pastDue,
        eventuallyDue: eventuallyDue,
        requiresPayoutUpdate:
          (pastDue.length > 0 ||
            currentlyDue.length > 0 ||
            eventuallyDue.length > 0) &&
          checkPermission(PaymentDomainPermissionPaths.createACH, scopes),
        payoutAccountLoading: loading
      }}
    >
      {children}
    </PayoutConnectContext.Provider>
  );
};
