import { isEmpty, removeEmpty } from "Driver/utils/helpers";

export const validate = ({
  daily_rate,
  weekly_discount,
  monthly_discount,
  maxDailyMiles
}) => {
  const errors = {};
  if (daily_rate == null) {
    errors.daily_rate = "Required";
  } else if (daily_rate < 20) {
    errors.daily_rate = "Must be at least $20 per day.";
  } else if (daily_rate > 150) {
    errors.daily_rate = "The maximum daily listing price is $150.";
  }
  if (weekly_discount == null || weekly_discount === "") {
    errors.weekly_discount = "Required";
  } else if (weekly_discount < 0) {
    errors.weekly_discount = "Must be at least 0.";
  } else if (weekly_discount > 100) {
    errors.weekly_discount = "Must be less than 100.";
  }
  if (monthly_discount == null || monthly_discount === "") {
    errors.monthly_discount = "Required";
  } else if (monthly_discount < 0) {
    errors.monthly_discount = "Must be at least 0.";
  } else if (monthly_discount > 100) {
    errors.monthly_discount = "Must be less than 100.";
  }
  if (!maxDailyMiles) {
    errors.maxDailyMiles = "Required";
  } else if (maxDailyMiles < 250) {
    errors.maxDailyMiles = "Must be at least 250 miles per day";
  }
  removeEmpty(errors);
  if (!isEmpty(errors)) {
    errors._error = "Please complete required fields";
  }
  return errors;
};
