import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Field, formValues } from "redux-form";
import { compose } from "recompose";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import get from "lodash/get";
import jwtDecode from "jwt-decode";

import { FormTextField } from "Components/Inputs/FormTextField";
import { FormSelect } from "Components/Inputs/FormSelect";
import { FormSelectCheckbox } from "Components/Inputs/FormSelectCheckbox";
import { PhoneMask } from "Components/Forms/InputMasks/PhoneMask";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const EditUserFormFields = compose(
  formValues({
    firstName: "firstName",
    lastName: "lastName",
    phone: "phone"
  })
)(({ roleOptions, firstName, lastName, phone }) => {
  const { currentOrganizationInfo, getOrganizationRooftops } = useContext(
    ClientFactoryContext
  );

  const rooftopTokens = getOrganizationRooftops(currentOrganizationInfo.id);

  return (
    <Grid container spacing={4}>
      {firstName && (
        <Grid item xs={12} md={6}>
          <Field
            name="firstName"
            label="First Name"
            fullWidth
            variant="standard"
            component={FormTextField}
            normalize={value =>
              value &&
              value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            }
            InputProps={{ disableUnderline: true, readOnly: true }}
          />
        </Grid>
      )}
      {lastName && (
        <Grid item xs={12} md={6}>
          <Field
            name="lastName"
            label="Last Name"
            fullWidth
            variant="standard"
            component={FormTextField}
            normalize={value =>
              value &&
              value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            }
            InputProps={{ disableUnderline: true, readOnly: true }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Field
          name="email"
          label="Email"
          fullWidth
          variant="standard"
          component={FormTextField}
          InputProps={{
            endAdornment: (
              <InputAdornment disablePointerEvents={true}>
                <IconButton size="large">
                  <EmailIcon />
                </IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
            readOnly: true
          }}
        />
      </Grid>
      {phone && (
        <Grid item xs={12}>
          <Field
            name="phone"
            component={FormTextField}
            fullWidth
            variant="standard"
            placeholder="Phone Number"
            label="Phone"
            inputComponent={PhoneMask}
            endAdornment={
              <InputAdornment disablePointerEvents={true}>
                <IconButton size="large">
                  <PhoneIcon />
                </IconButton>
              </InputAdornment>
            }
            InputProps={{ disableUnderline: true, readOnly: true }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Field
          name="role"
          label="Role"
          fullWidth
          variant="filled"
          component={FormSelect}
          options={roleOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="rooftops"
          component={FormSelectCheckbox}
          options={rooftopTokens.map(rooftopToken => ({
            value: get(jwtDecode(rooftopToken), "id"),
            label: get(jwtDecode(rooftopToken), "name")
          }))}
          placeholder="Select rooftop"
          label="Rooftop(s)"
        />
      </Grid>
    </Grid>
  );
});
