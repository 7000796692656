import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { compose } from "recompose";
import { reduxForm, Field } from "redux-form";
import { useParams } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { ChangeDailyPriceForActiveRentalFormController } from "Components/Forms/FormControllers/ChangeDailyPriceForActiveRentalFormController";
import { FormTextField } from "Components/Inputs/FormTextField";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";

const useStyles = makeStyles(theme => ({
  note: {
    marginBottom: theme.spacing(3)
  }
}));

const ChangeDailyPrice = compose(
  reduxForm({
    form: "CHANGE_DAILY_PRICE",
    destroyOnUnmount: true
  })
)(({ handleSubmit, submitting }) => {
  const classes = useStyles();

  return (
    <>
      <DrawerPaddingContainer>
        <Typography className={classes.note}>
          <b>NOTE:</b> Lowering the daily price will be reflected the next time
          your driver extends the rental and not for the current period. The
          daily rate of your car will be reverted when the rental ends and you
          have picked up your car.
        </Typography>
        <Field
          name="dailyRentalPrice"
          component={FormTextField}
          fullWidth
          variant="filled"
          label="Daily Price"
          normalize={(value, previousValue) => {
            const regex = /^[0-9]*$/i;
            const parsed = parseInt(value);
            if (regex.test(value)) {
              return isNaN(parsed) ? "" : parsed;
            } else {
              return previousValue;
            }
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onSubmit={handleSubmit}
        submitText="Lower Daily Price"
        submitDisabled={submitting}
      />
    </>
  );
});

export const ChangeDailyPriceForm = ({ onClose }) => {
  const { id } = useParams();
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const { data, loading } = useQuery(RENTAL_REQUEST_QUERY, {
    client: currentRooftopClient,
    variables: {
      id
    }
  });

  return loading ? (
    <SkeletonDrawer />
  ) : (
    <>
      <DrawerAppBar
        title="Lower Daily Price"
        border
        icon={
          <IconButton onClick={onClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        }
      />
      <ChangeDailyPriceForActiveRentalFormController
        dailyPriceInCents={data?.rental?.car?.dailyPriceInCents}
        rentalId={id}
      >
        {props => <ChangeDailyPrice {...props} />}
      </ChangeDailyPriceForActiveRentalFormController>
    </>
  );
};
