import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  logo: {
    fontWeight: theme.typography.fontWeightBold,
    color: "white"
  },
  navBar: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 64,
    backgroundColor: theme.palette.primary.light,
    paddingLeft: 16,
    paddingRight: 60
  },
  contents: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginBottom: 200,
    padding: 16
  },
  centerImage: {
    height: 180,
    backgroundColor: theme.palette.primary.light,
    padding: 40,
    borderRadius: 30,
    color: "white"
  }
}));

const UnderMaintenance = () => {
  const classes = useStyles();
  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <div className={classes.navBar}>
        <Typography component="h1" variant="h5" className={classes.logo}>
          HyreCar
        </Typography>
      </div>
      <div className={classes.contents}>
        <Typography
          component="h1"
          variant="h5"
          style={{ marginBottom: 30, textAlign: "center" }}
        >
          We are currently down for maintenance. Please try again in a few
          moments.
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          style={{ marginBottom: 40, textAlign: "center" }}
        >
          Thank you.
        </Typography>
        <img
          className={classes.centerImage}
          alt="maintenance-logo"
          src="/maintenance.png"
        />
      </div>
    </div>
  );
};

export default UnderMaintenance;
