import React from "react";
import Lightbox from "react-image-lightbox";
import PropTypes from "prop-types";

import "react-image-lightbox/style.css";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

export const FilesLightbox = ({ files, index, setIndex }) => {
  const closeLightBox = () => {
    setIndex(-1);
    analytics.track(AnalyticsEvents.label.owner.listingPhotoSlideShowClosed, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.buttonClicked,
      label: AnalyticsEvents.label.owner.listings,
      property: JSON.stringify({}),
      value: "",
      context: ""
    });
  };
  const handlePreviousImage = () =>
    setIndex(prevState => (prevState + files.length - 1) % files.length);
  const handleNextImage = () =>
    setIndex(prevState => (prevState + 1) % files.length);

  return (
    <Lightbox
      mainSrc={files[index].url}
      nextSrc={files[(index + 1) % files.length].url}
      prevSrc={files[(index + files.length - 1) % files.length].url}
      reactModalStyle={{ overlay: { zIndex: 2000 } }}
      onMovePrevRequest={handlePreviousImage}
      onMoveNextRequest={handleNextImage}
      onCloseRequest={closeLightBox}
    />
  );
};

FilesLightbox.propTypes = {
  files: PropTypes.array,
  index: PropTypes.number,
  setIndex: PropTypes.func
};
