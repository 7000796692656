import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CHANGE_DAILY_PRICE_FOR_ACTIVE_RENTAL } from "Mutations/Rental/RentalMutations";
import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import {
  centsToDollars,
  centsToDollarsInt,
  dollarsToCents
} from "Utils/Calculations";

export const ChangeDailyPriceForActiveRentalFormController = ({
  children,
  dailyPriceInCents,
  rentalId
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [changeDailyPriceForActiveRental] = useMutation(
    CHANGE_DAILY_PRICE_FOR_ACTIVE_RENTAL,
    {
      client: currentRooftopClient
    }
  );

  const onSubmit = async values => {
    try {
      await changeDailyPriceForActiveRental({
        variables: {
          rentalId,
          dailyPriceInCents: dollarsToCents(values.dailyRentalPrice)
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: RENTAL_REQUEST_QUERY, variables: { id: rentalId } }
        ]
      });

      enqueueSnackbar(
        "The daily rate of your rental has been updated! This will be reflected the next time your driver tries to extend.",
        {
          variant: "success"
        }
      );
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        e.message ??
          "Sorry, something went wrong. Please try again or contact support.",
        {
          variant: "error",
          autoHideDuration: 6000
        }
      );
    }
  };

  const validate = values => {
    const errors = {};

    const dailyRentalPriceInCents = dollarsToCents(values.dailyRentalPrice);

    if (dailyRentalPriceInCents < 2000) {
      errors.dailyRentalPrice = "The new daily rate should be greater than $20";
    }

    if (dailyRentalPriceInCents > dailyPriceInCents)
      errors.dailyRentalPrice = `The new daily rate should be less than your current daily rate of $${centsToDollars(
        dailyPriceInCents
      )}`;

    return errors;
  };

  return children({
    onSubmit,
    validate,
    initialValues: {
      dailyRentalPrice: centsToDollarsInt(dailyPriceInCents)
    }
  });
};
