import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import BaseButton from "@mui/material/ButtonBase";
import { RouteEnum } from "Enums/RouteEnum";
import { Box } from "@mui/material";
import LogoImg from "Assets/images/logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: ({ containStretch }) => !containStretch && 1,
    display: "flex",
    alignItems: "center"
  },
  logo: {
    fontWeight: theme.typography.fontWeightBold
  },
  subTitle: {
    fontSize: theme.spacing(1.125),
    position: "relative",
    bottom: theme.spacing(0.75),
    left: theme.spacing(3.375)
  }
}));

const sendToLandingPage = () => {
  window.location.href = process.env.REACT_APP_LANDING_PAGE_URL;
};

const LogoButton = ({ goToLanding, children }) => (
  <BaseButton
    {...(goToLanding
      ? { onClick: sendToLandingPage }
      : {
          component: RouterLink,
          to: RouteEnum.baseRoute,
          disableRipple: true
        })}
  >
    {children}
  </BaseButton>
);

export const Logo = ({
  title,
  subTitle,
  textLogo,
  containStretch,
  goToLanding = false
}) => {
  const classes = useStyles({ containStretch });

  return (
    <LogoButton goToLanding={goToLanding}>
      <div className={classes.root}>
        {textLogo ? (
          <>
            <Typography component="h1" variant="h5" className={classes.logo}>
              {title ? title : "HyreCar"}
            </Typography>
            <Typography variant="body1" className={classes.subTitle}>
              {subTitle ? subTitle : "by getaround"}
            </Typography>
          </>
        ) : (
          <Box
            component="img"
            sx={{
              height: 38
            }}
            alt="Hyrecar logo"
            src={LogoImg}
          />
        )}
      </div>
    </LogoButton>
  );
};

Logo.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  textLogo: PropTypes.bool,
  containStretch: PropTypes.bool,
  goToLanding: PropTypes.bool
};
