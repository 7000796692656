import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(theme => ({
  warning: {
    width: "100%",
    color: theme.palette.warning.dark,
    borderColor: theme.palette.warning.dark
  }
}));

const WarningButton = props => {
  const classes = useStyles();
  return (
    <Button className={classes.warning} {...props} variant="outlined"></Button>
  );
};

export default WarningButton;
