import { createTheme, adaptV4Theme } from "@mui/material/styles";
import lightBlue from "@mui/material/colors/lightBlue";
import { common } from "@mui/material/colors";

export const fleetTheme = createTheme(
  adaptV4Theme({
    palette: {
      background: {
        default: "#fafafa"
      },
      primary: {
        ...lightBlue,
        contrastText: common.white
      },
      secondary: {
        main: "#26C6DA"
      },
      alternative: {
        main: "#bc00ccff",
        light: "#c93cc8ff"
      },
      text: {
        primary: "#606770"
      },
      success: {
        main: "#00E676",
        light: "#00E676",
        dark: "#00C853"
      },
      warning: {
        main: "#FFA000",
        light: "#FFC107",
        dark: "#F57C00"
      },
      border: {
        light: "#E5E5E5"
      }
    },
    typography: {
      fontFamily: ["proxima-nova", "sans-serif"].join(",")
    }
  })
);
