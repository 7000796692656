/* eslint-disable react/display-name */
import React from "react";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

import { reduxForm, formValues, Field } from "redux-form";
import { compose } from "recompose";

import { validate } from "./validate";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { DocumentDropzone } from "Components/Forms/Dropzone/DocumentDropzone";
import { FormDatePicker } from "Components/Inputs/FormDatePicker";
import { FormCheckbox } from "Components/Inputs/FormCheckbox";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  document: {
    height: "225px",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      height: "175px"
    }
  },
  subHeader: {
    marginBottom: theme.spacing(4)
  },
  divider: {
    margin: theme.spacing(4, 0)
  },
  documentTitle: {
    paddingBottom: "0 !important"
  },
  blackText: {
    color: "#000"
  }
}));

const uploadLaterMessage =
  "By uploading later, your car will take longer to get verified for rideshare and delivery rental. If our team don’t get a document after 14 days, we will mark it as delivery rental only, and you might potentially miss out on larger earnings. ";

export const DocumentsFormFields = formValues({
  registrationDisabled: "registrationDisabled",
  insuranceDisabled: "insuranceDisabled",
  inspectionDisabled: "inspectionDisabled"
})(({ registrationDisabled, insuranceDisabled, inspectionDisabled }) => {
  const classes = useStyles();

  const showMessage =
    registrationDisabled || insuranceDisabled || inspectionDisabled;

  return (
    <Box>
      <Grid container spacing={3} columns={{ xs: 3, md: 12 }}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center"
          }}
          item
          xs={4}
        >
          <Box className={classes.documentTitle}>
            <Typography
              variant="body1"
              component="h6"
              fontWeight="bold"
              className={classes.blackText}
            >
              Registration
            </Typography>
          </Box>
          <Box className={classes.document}>
            <Field
              id="add-registration-button"
              name="registrationFile"
              component={DocumentDropzone}
              disabled={registrationDisabled}
              objectFit
              accept="image/jpeg, image/png, application/pdf"
            />
          </Box>
          <Box container item md={6}>
            <Field
              id="registration-date-picker-field"
              name="registrationExpirationAt"
              component={FormDatePicker}
              label="Expiration Date"
              fullWidth
              variant="inline"
              inputVariant="filled"
              disablePast
              disableToolbar
              autoOk
              disabled={registrationDisabled}
            />
            <Field
              id="registration-upload-later-checkbox"
              name="registrationDisabled"
              component={FormCheckbox}
              label="Upload Later"
            />
          </Box>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center"
          }}
          item
          xs={4}
        >
          <Box className={classes.documentTitle}>
            <Typography
              variant="body1"
              component="h6"
              fontWeight="bold"
              className={classes.blackText}
            >
              Insurance
            </Typography>
          </Box>
          <Box className={classes.document}>
            <Field
              id="add-insurance-button"
              name="personalInsuranceFile"
              component={DocumentDropzone}
              disabled={insuranceDisabled}
              objectFit
              accept="image/jpeg, image/png, application/pdf"
            />
          </Box>
          <Box container item md={6}>
            <Field
              id="insurance-date-picker-field"
              name="insuranceExpirationAt"
              component={FormDatePicker}
              label="Expiration Date"
              fullWidth
              variant="inline"
              inputVariant="filled"
              disablePast
              disableToolbar
              autoOk
              disabled={insuranceDisabled}
            />
            <Field
              id="insurance-upload-later-checkbox"
              name="insuranceDisabled"
              component={FormCheckbox}
              label="Upload Later"
            />
          </Box>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center"
          }}
          item
          xs={4}
        >
          <Box className={classes.documentTitle}>
            <Typography
              variant="body1"
              component="h6"
              fontWeight="bold"
              className={classes.blackText}
            >
              Inspection
            </Typography>
          </Box>
          <Box className={classes.document}>
            <Field
              id="add-inspection-button"
              name="uberLyftInspectionFile"
              component={DocumentDropzone}
              disabled={inspectionDisabled}
              objectFit
              accept="image/jpeg, image/png, application/pdf"
            />
          </Box>
          <Box container item md={6}>
            <Field
              id="inspection-date-picker-field"
              name="inspectionExpirationAt"
              component={FormDatePicker}
              label="Expiration Date"
              fullWidth
              variant="inline"
              inputVariant="filled"
              disablePast
              disableToolbar
              autoOk
              disabled={inspectionDisabled}
            />
            <Field
              id="inspection-upload-later-checkbox"
              name="inspectionDisabled"
              component={FormCheckbox}
              label="Upload Later"
            />
          </Box>
        </Grid>
      </Grid>

      {showMessage && (
        <Box mt={3}>
          <Alert severity="info">{uploadLaterMessage}</Alert>
        </Box>
      )}
    </Box>
  );
});

export const DocumentsForm = compose(
  connect(state => ({
    initialValues: state.fleet.listing.initialFormValues
  })),
  reduxForm({
    form: "LIST_CAR_FLOW",
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
    onSubmitFail: errors => scrollErrorIntoView(errors)
  })
)(({ handleSubmit, previousStep, submitting }) => (
  <FlowContentContainer
    header="Upload Documents"
    nextStep={handleSubmit}
    nextStepLabel={submitting ? "Submitting..." : "Submit"}
    previousStep={previousStep}
    previousStepLabel="Back"
    nextStepId="submit-documents"
    previousStepId="back-documents"
    disabled={submitting}
  >
    <DocumentsFormFields submitting={submitting} />
  </FlowContentContainer>
));
