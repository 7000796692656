import React from "react";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  arrow: {
    margin: "1rem",
    fontSize: "2rem"
  },
  avatar: {
    marginBottom: 12,
    width: "95px",
    height: "95px"
  }
});

const SwitchAvatars = ({ oldCarPhoto, newCarPhoto }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar} src={oldCarPhoto} />
      <ArrowForwardIcon className={classes.arrow} />
      <Avatar className={classes.avatar} src={newCarPhoto} />
    </div>
  );
};

export default SwitchAvatars;
