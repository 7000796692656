import {
  OFFERS_LIST_UPDATE,
  OFFERS_FILTER_UPDATE,
  OFFERS_LOADING_UPDATE
} from "../actions/offersActions";

export default (state = { filter: {}, list: [], loading: false }, action) => {
  switch (action.type) {
    case OFFERS_LIST_UPDATE:
      if (typeof action.payload !== "undefined") {
        return {
          ...state,
          list: action.payload
        };
      } else {
        return state;
      }
    case OFFERS_FILTER_UPDATE:
      if (typeof action.payload !== "undefined") {
        return {
          ...state,
          filter: action.payload
        };
      } else {
        return state;
      }
    case OFFERS_LOADING_UPDATE:
      if (typeof action.payload !== "undefined") {
        return {
          ...state,
          loading: action.payload
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
