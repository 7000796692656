import React from "react";
import { Field, formValues, arrayRemove } from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import get from "lodash/get";
import { useSnackbar } from "notistack";

import { ImageDropzone } from "Components/Forms/Dropzone/ImageDropzone";
import { AcceptEnum } from "Enums/AcceptEnum";
import { Image } from "Components/Images/Image";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: theme.spacing(4, 3),
    marginBottom: theme.spacing(4)
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  imageContainer: {
    marginTop: theme.spacing(2)
  }
}));

export const ImageFormFields = compose(
  formValues("cars"),
  connect(null, { arrayRemove })
)(({ cars, fields, arrayRemove, form }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // const [uploadFile] = useMutation(UPLOAD_FILE);

  const handleRejectedFiles = () => {
    enqueueSnackbar(
      "Sorry, some of the files you uploaded might have been rejected because there were too many or were too large.",
      {
        variant: "error",
        autoHideDuration: 5000
      }
    );
  };

  const handleOnDelete = (fieldIndex, photoIndex, field) => () => {
    const currentUpload = fields.get(fieldIndex)["photos"][photoIndex];
    arrayRemove(form, `${field}.photos`, photoIndex);
    URL.revokeObjectURL(currentUpload);
  };

  // This is not used for now due to changes in release
  // const asyncAction = async value => {
  //   try {
  //     const { data } = await uploadFile({
  //       variables: {
  //         file: value,
  //         type: FileTypeEnum.carPhoto
  //       }
  //     });
  //     const url = get(data, "uploadFile.url");
  //     const id = get(data, "uploadFile.id");
  //     const contentType = get(data, "uploadFile.contentType");

  //     return {
  //       id,
  //       url,
  //       contentType
  //     };
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  return fields.map((field, fieldIndex) => {
    const car = fields.get(fieldIndex);

    return (
      <Paper key={car.vin} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.title}>
              {car.year} {car.make} {car.model}
            </Typography>
            <Typography variant="body2">{car.vin}</Typography>
          </Grid>
          <Grid container item xs={9} direction="column">
            <Grid item>
              <Field
                name={`${field}.photos`}
                component={ImageDropzone}
                accept={[AcceptEnum.png, AcceptEnum.jpeg]}
                multiple
                maxSize={7340032}
                height={126}
                fullWidth
                content={
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {`Select up to 10 images (PNG or JPG <10MB)`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <AddIcon />
                    </Grid>
                  </Grid>
                }
                hover
                hideButton
                onRejectFiles={handleRejectedFiles}
                // asyncAction={asyncAction}
                disabled={
                  Array.isArray(get(cars[fieldIndex], "photos")) &&
                  get(cars[fieldIndex], "photos").length >= 10
                }
                maxToUpload={
                  Array.isArray(get(cars[fieldIndex], "photos"))
                    ? 10 - get(cars[fieldIndex], "photos").length
                    : 10
                }
              />
            </Grid>
            <Grid container item spacing={3} className={classes.imageContainer}>
              {Array.isArray(get(cars[fieldIndex], "photos")) &&
                cars[fieldIndex].photos.map((photo, photoIndex) => {
                  return (
                    <Grid item key={photoIndex}>
                      <Image
                        // src={photo.url}
                        src={URL.createObjectURL(photo)}
                        objectFit="contain"
                        height={110}
                        width={154}
                        actions={[
                          {
                            action: handleOnDelete(
                              fieldIndex,
                              photoIndex,
                              field
                            ),
                            icon: <DeleteForeverIcon color="error" />
                          }
                        ]}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  });
});
