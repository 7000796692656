import React, { useState } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";

import { fleetTheme } from "Styles/FleetTheme";
import { driverTheme } from "Styles/DriverTheme";

window.theme = fleetTheme;

export const ThemeFunctionsContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(fleetTheme);

  const setThemeToDriver = () => setTheme(driverTheme);
  const setThemeToFleet = () => setTheme(fleetTheme);

  return (
    <ThemeFunctionsContext.Provider
      value={{ setThemeToDriver, setThemeToFleet }}
    >
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeFunctionsContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};
