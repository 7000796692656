import { useContext } from "react";
import { destroy } from "redux-form";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import get from "lodash/get";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";

import { SUBMIT_INCIDENTAL_CLAIM } from "Mutations/Claims/ClaimMutations";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { emailRegex } from "Utils/Regex";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { RouteEnum } from "Enums/RouteEnum";
import { dollarsToCents } from "Utils/Calculations";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { ProtectionPlanEnum } from "Enums/StateEnums";

export const useClaimsReimbursementFormController = dispatch => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [submitIncidentalClaim] = useMutation(SUBMIT_INCIDENTAL_CLAIM, {
    client: currentRooftopClient
  });
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const rentalId = get(location, "state.id", "");
  const history = useHistory();
  const response = useQuery(CURRENT_USER_QUERY, {
    client: currentRooftopClient
  });
  const viewer = response?.data?.viewer;

  const { data: rentalProtectionPlanData } = useQuery(RENTAL_REQUEST_QUERY, {
    fetchPolicy: "cache-only",
    client: currentRooftopClient,
    variables: {
      id: rentalId
    }
  });
  const protectionPlan = get(
    rentalProtectionPlanData,
    "rental.currentRentalPeriod.protectionPlan"
  );

  const initialValues = {
    damagedAreas: [],
    firstName: get(viewer, "me.firstName"),
    lastName: get(viewer, "me.lastName"),
    phone: get(viewer, "me.phone"),
    email: get(viewer, "me.email"),
    rentalId
  };

  const onSubmit = async values => {
    try {
      const keyCost = get(values, "key");
      const cleaningCost = get(values, "cleaning");
      const impoundFees = get(values, "impound");
      const otherCost = get(values, "otherCost");

      const data = {
        rentalId: values.rentalId,
        reimbursementTypes:
          values.damagedAreas &&
          Array.isArray(values.damagedAreas) &&
          values.damagedAreas.map(val => val.label),
        contactInfo: {
          bestContactTime: get(values, "timeWindow", ""),
          firstName: get(values, "firstName"),
          lastName: get(values, "lastName"),
          email: get(values, "email"),
          phone: get(values, "phone")
        },
        keyCostInCents: keyCost ? dollarsToCents(keyCost) : null,
        cleaningCostInCents: cleaningCost ? dollarsToCents(cleaningCost) : null,
        impoundFeesInCents: impoundFees ? dollarsToCents(impoundFees) : null,
        gasLevelCheckIn: get(values, "gasIn"),
        gasLevelCheckOut: get(values, "gasOut"),
        mileageCheckIn: get(values, "mileageIn"),
        mileageCheckOut: get(values, "mileageOut"),
        otherCostInCents: otherCost ? dollarsToCents(otherCost) : null,
        otherReason: get(values, "otherDescription"),
        photos: get(values, "photos")
      };

      const resp = await submitIncidentalClaim({
        variables: {
          data: {
            ...omitBy(data, isNil)
          }
        }
      });

      dispatch(destroy("CLAIMS_REIMBURSEMENT"));
      history.replace(RouteEnum.claims, {
        ...location.state,
        claimId: get(resp, "data.submitIncidentalClaim.claim.id"),
        claimType: "reimbursement"
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        e.message
          ? e.message.replace(/graphql error: /gim, "")
          : "Failed to submit.",
        {
          variant: "error"
        }
      );
    }
  };

  const convertToDecimal = value => {
    let result = 0;
    result = value.includes("/")
      ? parseInt(value.split("/")[0]) / parseInt(value.split("/")[1])
      : parseInt(value);
    return result;
  };

  const validate = values => {
    const errors = {};
    if (!values.rentalId) errors.rentalId = "Required";

    if (!values.firstName) errors.firstName = "Required";

    if (!values.lastName) errors.lastName = "Required";
    if (!values.email) {
      errors.email = "Enter a valid email";
    } else {
      if (!emailRegex.test(values.email)) {
        errors.email = "Enter a valid email";
      }
    }

    if (!Array.isArray(values.damagedAreas)) {
      errors.damagedAreas = "Required";
    } else if (values.damagedAreas.length === 0) {
      errors.damagedAreas = "Required";
    }

    if (!values.key) errors.key = "Required";

    if (!values.cleaning) errors.cleaning = "Required";

    if (!values.impound) errors.impound = "Required";

    if (!values.mileageIn) errors.mileageIn = "Required";

    if (!values.mileageOut) errors.mileageOut = "Required";

    if (values.mileageIn > values.mileageOut) {
      errors.mileageOut =
        "Mileage at Check-out must be greater than mileage at Check-in to be eligible.";
    }

    if (!values.gasIn) errors.gasIn = "Required";
    if (!values.gasOut) errors.gasOut = "Required";
    if (values.gasIn && values.gasOut) {
      if (convertToDecimal(values.gasIn) < convertToDecimal(values.gasOut)) {
        errors.gasOut =
          "Gas level at Check-out must be less than gas level at Check-in to be eligible.";
      }
    }

    if (!values.otherCost) errors.otherCost = "Required";

    if (!values.otherDescription) errors.otherDescription = "Required";

    if (!Array.isArray(values.photos)) {
      errors.photos = "Required";
    } else if (values.photos.length === 0) {
      errors.photos = "Required";
    }

    return errors;
  };

  const warnClaimDetails = (values, protectionPlan) => {
    const warnings = {};
    const reimbursementAmount = protectionPlan
      ? cleaningReimbursement[protectionPlan]
      : 0;
    const cleaningCost = values.cleaning;

    if (cleaningCost > reimbursementAmount) {
      warnings.cleaning = `Please note, you requested an amount that exceeds what you are eligible to receive ($${reimbursementAmount}) based on ${protectionPlan} Protection Plan. Check the 'Helpful Tips' for more information.`;
    }
    return warnings;
  };

  return {
    onSubmit,
    initialValues,
    validate,
    warn: values => warnClaimDetails(values, protectionPlan),
    onSubmitFail: errors => scrollErrorIntoView(errors)
  };
};

export const normalize = (value, previousValue) => {
  // const regex = /^[0-9]{0,6}$/i;
  const regex = /^[1-9]?[0-9]{0,5}$|^1000000$/i;
  if (regex.test(value)) {
    const parsed = parseInt(value);
    return isNaN(parsed) ? "" : parsed;
  }

  return previousValue;
};

export const gas_options = [
  { value: "0", label: "Empty" },
  { value: "1/8", label: "1/8" },
  { value: "1/4", label: "1/4" },
  { value: "1/2", label: "1/2" },
  { value: "3/4", label: "3/4" },
  { value: "7/8", label: "7/8" },
  { value: "1", label: "Full" }
];

export const NonDamageTypeEnum = {
  cleaning: 0,
  gas: 1,
  impoundFees: 2,
  mileage: 3,
  LostStolenKeys: 4,
  others: 5
};

export const nonDamageDetailsOptions = [
  {
    value: NonDamageTypeEnum.cleaning,
    label: "Cleaning",
    description: "How much did it cost to clean the car?",
    fields: [
      {
        name: "cleaning",
        label: "Cleaning Cost"
      }
    ]
  },
  {
    value: NonDamageTypeEnum.gas,
    label: "Gas",
    description: "Enter the gas level at Check-in and Checkout.",
    fields: [
      {
        name: "gasIn",
        label: "Check-in"
      },
      {
        name: "gasOut",
        label: "Check-out"
      }
    ]
  },
  {
    value: NonDamageTypeEnum.impoundFees,
    label: "Impound Fees",
    description: "How much were the impound fees?",
    fields: [
      {
        name: "impound",
        label: "Impound Fees"
      }
    ]
  },
  {
    value: NonDamageTypeEnum.LostStolenKeys,
    label: "Lost / Stolen Keys",
    description: "What is the cost to replace the key?",
    fields: [
      {
        name: "key",
        label: "Key Replacement Cost"
      }
    ]
  },
  {
    value: NonDamageTypeEnum.mileage,
    label: "Mileage",
    description: "Enter the mileage at Check-in and Checkout.",
    fields: [
      {
        name: "mileageIn",
        label: "Check-in"
      },
      {
        name: "mileageOut",
        label: "Check-out"
      }
    ]
  },
  {
    value: NonDamageTypeEnum.others,
    label: "Others",
    description:
      "Please enter the amount requested and let us know what it's for.",
    fields: [
      {
        name: "otherCost",
        label: "Cost"
      },
      {
        name: "otherDescription",
        label: "Reason"
      }
    ]
  }
];

export const cleaningReimbursement = {
  [ProtectionPlanEnum.basic]: 30,
  [ProtectionPlanEnum.standard]: 50,
  [ProtectionPlanEnum.premium]: 60,
  [ProtectionPlanEnum.premium_plus]: 75
};
