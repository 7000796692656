export const RooftopDomainPermissionPaths = {
  readRooftops: "organization.rooftops.read",
  createRooftop: "organization.rooftops.create",
  updateRooftop: "organization.rooftops.update",
  viewRooftop: "organization.rooftops.view",
  viewTeam: "organization.members.view",
  createMember: "organization.members.invite",
  updateMember: "organization.members.update",
  viewHeader: "organization.viewHeader"
};

export const CarsDomainPermissionPaths = {
  listCar: "car.cars.create",
  csvRooftopSelect: "car.cars.csvRooftopSelect",
  manualRooftopSelect: "car.cars.manualRooftopSelect"
};

export const ListingsDomainPermissionPaths = {
  editProtectionPlan: "listing.listings.editProtectionPlan",
  editPricing: "listing.listings.editPricing"
};

export const PaymentDomainPermissionPaths = {
  viewPayments: "payment.transactions",
  listTransactions: "payment.transactions.read",
  listRevenue: "payment.transactions.listRevenue",
  dashboardEarnings: "payment.transactions.dashboardEarnings",
  createACH: "payment.ach.create",
  viewACH: "payment.ach.viewACH"
};

export const RentalsDomainPermissionPaths = {
  pastViewEarnings: "rental.rentals.past.viewEarnings",
  switchCar: "rental.rentals.switchCar"
};

export const InboxDomainPermissionPaths = {
  viewMultiUserInbox: "inbox.viewMultiUserInbox",
  viewChatAssignments: "inbox.viewChatAssignments",
  updateInboxTemplates: "inbox.templates.update"
};
