import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { DesktopInbox } from "Components/Inbox/DesktopInbox";
import { MobileInbox } from "Components/Inbox/MobileInbox";

export const CompleteInbox = ({ filterByAssignedTo }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {matches ? (
        <MobileInbox filterByAssignedTo={filterByAssignedTo} />
      ) : (
        <DesktopInbox filterByAssignedTo={filterByAssignedTo} />
      )}
    </>
  );
};
