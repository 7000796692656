export const GET_REVENUE_DATA = "GET_REVENUE_DATA";
export const GET_REVENUE_DATA_SUCCESS = "GET_REVENUE_DATA_SUCCESS";
export const GET_REVENUE_DATA_ERROR = "GET_REVENUE_DATA_ERROR";

export const GET_OWNER_CAR_IDS = "GET_OWNER_CAR_IDS";
export const GET_OWNER_CAR_IDS_SUCCESS = "GET_OWNER_CAR_IDS_SUCCESS";
export const GET_OWNER_CAR_IDS_ERROR = "GET_OWNER_CAR_IDS_ERROR";

export const UPDATE_VIN = "UPDATE_VIN";
export const UPDATE_CURSOR = "UPDATE_CURSOR";
export const UPDATE_LOADING = "UPDATE_LOADING";

export const UPDATE_TRANSACTION_DATE_FILTERS =
  "UPDATE_TRANSACTION_DATE_FILTERS";
export const UPDATE_TRANSACTION_CUSTOM_DATE_FILTERS =
  "UPDATE_TRANSACTION_CUSTOM_DATE_FILTERS";
export const TOGGLE_TRANSACTIONS_DATE_DIALOG =
  "TOGGLE_TRANSACTIONS_DATE_DIALOG";
