import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import { useRentalStatusOptions } from "Components/hooks/useRentalStatusOptions";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  icon: {
    color: props => props.color,
    margin: theme.spacing(0, 1, 0, 0)
  },
  text: {
    color: props => props.color
  }
}));

export const RentalStatusIndicator = ({
  alternativeCopy,
  hideIcon,
  status,
  to
}) => {
  const theme = useTheme();
  const { Icon, copy, color } = useRentalStatusOptions(status, theme);
  const classes = useStyles({ color });
  const history = useHistory();

  return (
    <div className={classes.root}>
      {Icon && !hideIcon && <Icon className={classes.icon} />}
      <Typography
        onClick={() => history.push(to)}
        variant="overline"
        component="span"
        className={classes.text}
      >
        {alternativeCopy ? alternativeCopy : copy}
      </Typography>
    </div>
  );
};

RentalStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  alternativeCopy: PropTypes.string,
  hideIcon: PropTypes.bool
};
