import React, { useContext } from "react";
import { compose } from "recompose";
import { NavItem } from "hyrecar-web-components/build/elements/base";
import { withRouter } from "react-router-dom";

import withMessagingModal from "Driver/Containers/Messaging/hocs/withMessagingModal";

import NavBar from "Driver/Components/Navigation/Navbar";

import UnreadMessagesCount from "Driver/Containers/Messaging/UnreadMessagesCount";
import withMetricsProp from "Analytics/hocs/withMetricsProp";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { openSupportPage, openSupportTicketsPage } from "Utils/openSupportPage";
import { UserTypeEnum } from "Enums/StateEnums";
import GeneralAlert from "Components/Alerts/DriverGeneralAlert";
import { DriverAlertContext } from "Components/Utils/DriverAlertProvider";

const DriverNavigationBar = props => {
  const {
    authModalShowLogin,
    logoutUser,
    authModalShowRegister,
    history,
    messagingModalShowList,
    user
  } = props;
  const { showAlert } = useContext(DriverAlertContext);

  const makePushToHistoryFunction = route => {
    return () => history.push(route);
  };
  const routeHandlers = {
    home: makePushToHistoryFunction("/"),
    bookACar: makePushToHistoryFunction("/offers"),
    reservations: makePushToHistoryFunction("/driver/reservations"),
    profile: makePushToHistoryFunction("/profile"),
    messages: () => null,
    renderMessagesLink: ({ onClick }) => (
      <NavItem
        key={"messages"}
        eventKey={"messages"}
        onClick={() => {
          messagingModalShowList();
          onClick();
        }}
        style={{ position: "relative" }}
      >
        Messages
        <UnreadMessagesCount style={{ marginLeft: 5 }} />
      </NavItem>
    ),
    transactions: makePushToHistoryFunction("/profile/transactions"),
    contactUs: () => {
      openSupportPage();
      analytics.track(AnalyticsEvents.label.driver.contactUs, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.driver.contactUs,
        property: JSON.stringify({
          category: UserTypeEnum.driver
        }),
        value: "",
        context: ""
      });
      return;
    },
    supportTickets: () => {
      openSupportTicketsPage();
      return;
    },
    howItWorks: () => {
      window.open("https://hyrecar.com/howitworks", "_blank");
      analytics.track(AnalyticsEvents.label.driver.howItWorks, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.driver.howItWorks,
        property: JSON.stringify({
          category: UserTypeEnum.driver
        }),
        value: "",
        context: ""
      });
      return;
    },
    login: authModalShowLogin,
    logout: () => {
      analytics.track(AnalyticsEvents.label.auth.loggedOut, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.auth.loggedOut,
        property: JSON.stringify({
          category: UserTypeEnum.driver
        }),
        value: "",
        context: ""
      });

      logoutUser();
    },
    register: authModalShowRegister
  };
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1040
      }}
    >
      <NavBar
        handleLink={goToLink => {
          goToLink();
        }}
        routes={routeHandlers}
        user={user}
      />
      {showAlert && <GeneralAlert />}
    </div>
  );
};

export default compose(
  withMessagingModal,
  withRouter,
  withMetricsProp
)(DriverNavigationBar);
