import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { RouterDrawer } from "Components/Drawers/RouterDrawer";
import { DrawerContainer } from "Components/Drawers/DrawerContainer";
import { RentalDrawerSummary } from "Components/Drawers/RentalDrawer/RentalDrawerSummary";
import { RentalDocuments } from "Components/Drawers/RentalDrawer/RentalDocuments";
import { RentalImages } from "Components/Drawers/RentalDrawer/RentalImages";
import { RentalCheckOutController } from "Components/Drawers/RentalDrawer/RentalCheckOut/RentalCheckOutController";
import { RentalEarningsBreakdown } from "Components/Drawers/RentalDrawer/RentalEarningsBreakdown";
import { ChangeDailyPriceForm } from "Components/Drawers/RentalDrawer/ChangeDailyPriceForm";
import CarSwitch from "./CarSwitch/CarSwitch";
import CarSwitchLateRentalUnavailable from "./CarSwitch/CarSwitchLateRentalUnavailable";
import { getRouteWithoutLastPart } from "Components/Utils/Routes";

export const RentalDrawer = ({ payoutData }) => {
  const [step, setStep] = useState(0);
  const location = useLocation();
  const history = useHistory();

  const sendToRentalSummary = () => setStep(0);
  const sendToRentalContracts = () => setStep(1);
  const sendToRentalImages = () => setStep(2);
  const sendToEarningsBreakdown = () => setStep(3);
  const sendToCheckOut = () => setStep(4);
  const sendToCarSwitch = () => setStep(5);
  const sendToCarSwitchLateRentalUnavailable = () => setStep(6);
  const sendToChangeActiveDailyPrice = () => setStep(7);

  const getCurrentComponent = handleClose => {
    switch (step) {
      case 0:
        return (
          <RentalDrawerSummary
            handleClose={handleClose}
            payoutData={payoutData}
            sendToRentalContracts={sendToRentalContracts}
            sendToRentalImages={sendToRentalImages}
            sendToEarningsBreakdown={sendToEarningsBreakdown}
            sendToCheckOut={sendToCheckOut}
            sendToCarSwitchLateRentalUnavailable={
              sendToCarSwitchLateRentalUnavailable
            }
            sendToCarSwitch={sendToCarSwitch}
            sendToChangeActiveDailyPrice={sendToChangeActiveDailyPrice}
          />
        );
      case 1:
        return <RentalDocuments sendToRentalSummary={sendToRentalSummary} />;
      case 2:
        return <RentalImages onClose={sendToRentalSummary} />;
      case 3:
        return <RentalEarningsBreakdown onClose={sendToRentalSummary} />;
      case 4:
        return <RentalCheckOutController onClose={sendToRentalSummary} />;
      case 5:
        return <CarSwitch onClose={sendToRentalSummary} />;
      case 6:
        return <CarSwitchLateRentalUnavailable onClose={sendToRentalSummary} />;
      case 7:
        return <ChangeDailyPriceForm onClose={sendToRentalSummary} />;
      default:
        return (
          <RentalDrawerSummary
            handleClose={handleClose}
            payoutData={payoutData}
            sendToRentalContracts={sendToRentalContracts}
            sendToRentalImages={sendToRentalImages}
            sendToEarningsBreakdown={sendToEarningsBreakdown}
          />
        );
    }
  };

  return (
    <>
      <RouterDrawer
        onClose={() => history.push(getRouteWithoutLastPart(location.pathname))}
      >
        {({ handleClose }) => {
          return (
            <DrawerContainer>
              {getCurrentComponent(handleClose)}
            </DrawerContainer>
          );
        }}
      </RouterDrawer>
    </>
  );
};
