import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import Divider from "@mui/material/Divider";
import Zoom from "@mui/material/Zoom";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";

import {
  INBOX_MESSAGE_STATUS,
  INBOX_STATUS_FILTERS
} from "Components/Inbox/Filters/Filters";
import FiltersDialog from "Components/Inbox/Filters/FiltersDialog";
import { updateInboxFilters } from "Redux/SendBird/ActionCreators";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  addFilterButton: {
    whiteSpace: "nowrap",
    minWidth: "max-content",
    marginRight: theme.spacing(3)
  },
  chipContent: {
    display: "flex",
    alignItems: "center"
  }
}));

const Filters = ({ updateInboxFilters, inboxFilters, statusClasses }) => {
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);
  const classes = useStyles();

  const openDialog = () => {
    setFiltersDialogOpen(true);
  };

  const closeDialog = () => {
    setFiltersDialogOpen(false);
  };

  const removeFilter = (filters, removedFilter) =>
    filters
      .filter(filter => filter !== removedFilter)
      .reduce((prev, curr) => ({ ...prev, [curr]: true }), {});

  const onDeleteFilter = filter => {
    const messageTypes = removeFilter(inboxFilters.messageTypes, filter);
    const rentalStatus = removeFilter(inboxFilters.rentalStatus, filter);
    updateInboxFilters({ messageTypes, rentalStatus });
  };

  const getFilterName = filter => {
    const messageStatus = INBOX_MESSAGE_STATUS[filter];
    if (messageStatus) return messageStatus.label;
    const statusFilter = INBOX_STATUS_FILTERS[filter];
    if (statusFilter) return statusFilter.label;
  };

  useEffect(
    () => () => {
      updateInboxFilters({ messageTypes: [], rentalStatus: [] });
    },
    [updateInboxFilters]
  );

  const allFilters = [
    ...inboxFilters.messageTypes,
    ...inboxFilters.rentalStatus
  ];

  return (
    <div>
      <div className={classes.container}>
        <Button
          onClick={openDialog}
          color="primary"
          className={classes.addFilterButton}
        >
          <FilterListIcon className={classes.filterIcon} />
          Add Filter
        </Button>
        <div className={classes.chips}>
          {allFilters.map(filter => (
            <Zoom key={filter} in={true}>
              <Chip
                label={
                  <div className={classes.chipContent}>
                    {statusClasses[filter] && (
                      <div
                        className={`${statusClasses.rentalStatusCircle} ${statusClasses[filter]}`}
                      />
                    )}
                    {getFilterName(filter)}
                  </div>
                }
                onDelete={() => onDeleteFilter(filter)}
                variant="outlined"
                className={classes.chip}
              />
            </Zoom>
          ))}
        </div>
      </div>
      <Divider />
      <FiltersDialog
        onClose={closeDialog}
        open={filtersDialogOpen}
        onSubmit={updateInboxFilters}
        filters={inboxFilters}
      />
    </div>
  );
};

Filters.propTypes = {
  updateInboxFilters: PropTypes.func,
  inboxFilters: PropTypes.object,
  statusClasses: PropTypes.object
};

export default connect(
  state => ({ inboxFilters: state.fleet.sendbird.inboxFilters }),
  { updateInboxFilters }
)(Filters);
