import { withProps } from "recompose";

const withConfig = withProps({
  config: require("Config/config").default
});

export const WithConfig = withConfig(({ config, children }) =>
  children(config)
);

export default withConfig;
