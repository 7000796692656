export const OFFERS_LIST_UPDATE = "OFFERS_LIST_UPDATE";
export const OFFERS_FILTER_UPDATE = "OFFERS_FILTER_UPDATE";
export const OFFERS_LOADING_UPDATE = "OFFERS_LOADING_UPDATE";

export const updateOffersListAction = payload => ({
  type: OFFERS_LIST_UPDATE,
  payload
});

export const updateOffersFilterAction = payload => ({
  type: OFFERS_FILTER_UPDATE,
  payload
});

export const updateOffersLoadingAction = payload => ({
  type: OFFERS_LOADING_UPDATE,
  payload
});
