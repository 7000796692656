import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { DialogAppBar } from "Components/Dialog/DialogAppBar";
import { FullScreenDialog } from "Components/Dialog/FullScreenDialog";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";

const DEFAULT_PRIMARY_ACTION = "Save";
const DEFAULT_SECONDARY_ACTION = "Cancel";
const DEFAULT_MAX_WIDTH = "sm";

const useStyles = makeStyles(theme => ({
  content: {
    margin: theme.spacing(3)
  },
  mobileActions: {
    width: "100%"
  },
  actionButton: {
    minWidth: 100,
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

const ResponsiveModal = ({
  open = false,
  onClose = () => {},
  title,
  children,
  onPrimaryAction,
  primaryActionTitle,
  maxWidth,
  disabled
}) => {
  const [actionInProgress, setActionInProgress] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(maxWidth || DEFAULT_MAX_WIDTH)
  );

  const onActionTriggered = async () => {
    setActionInProgress(true);
    await onPrimaryAction();
    setActionInProgress(false);
    onClose();
  };

  const spinner = <CircularProgress size={24} />;

  const actionTitle = primaryActionTitle || DEFAULT_PRIMARY_ACTION;

  const mobileActions = (
    <div className={classes.mobileActions}>
      <DrawerBottomBar
        submitText={actionTitle}
        onSubmit={onActionTriggered}
        onCancel={onClose}
        cancelText={DEFAULT_SECONDARY_ACTION}
        disabled={disabled || actionInProgress}
        useLoadingComponent
      />
    </div>
  );

  const desktopActions = (
    <>
      <Button
        onClick={onClose}
        disabled={actionInProgress}
        className={classes.actionButton}
      >
        {DEFAULT_SECONDARY_ACTION}
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={onActionTriggered}
        disabled={disabled || actionInProgress}
        className={classes.actionButton}
      >
        {actionInProgress ? spinner : actionTitle}
      </Button>
    </>
  );

  const actions = isMobile ? mobileActions : desktopActions;

  const mobileHeader = (
    <DialogAppBar
      icon={
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      }
      title={title}
      border
    />
  );

  const desktopHeader = title;

  const header = isMobile ? mobileHeader : desktopHeader;

  const wrappedContent = <div className={classes.content}>{children}</div>;

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth || DEFAULT_MAX_WIDTH}
      fullScreen={isMobile}
      title={header}
      content={wrappedContent}
      actions={actions}
    />
  );
};

ResponsiveModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  onPrimaryAction: PropTypes.func,
  primaryActionTitle: PropTypes.string,
  disabled: PropTypes.bool
};

export default ResponsiveModal;
