import axios from "axios";
import config from "Config/config";

export const hephaestus = axios.create({
  baseURL: config.hephaestusReimbursementUrl
});

export const hephaestusDriverVetting = axios.create({
  baseURL: config.hephaestusDriverVettingUrl
});
