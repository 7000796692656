import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { useListingStatusOptions } from "Components/hooks/useListingStatusOptions";

const useStyles = makeStyles(() => ({
  root: {
    background: props => (props.borderColoring ? "white" : props.color),
    minWidth: "10px",
    width: "10px",
    height: "10px",
    borderRadius: "100%",
    border: props => props.borderColoring && `1px solid ${props.color}`
  }
}));

// background: "white",

export const ListingStatusDotIndicator = ({ status, verification }) => {
  const { color, borderColoring } = useListingStatusOptions(
    status,
    verification
  );
  const classes = useStyles({ color, borderColoring });

  return <div className={classes.root} />;
};

ListingStatusDotIndicator.propTypes = {
  status: PropTypes.string.isRequired
};
