import { SendBirdErrorEnum } from "Enums/SendBirdEnums";

export const getErrorMessage = error => {
  switch (error.code) {
    case SendBirdErrorEnum.networkError: {
      return "No internet connection. Please try again.";
    }
    default:
      return null;
  }
};
