import React, { useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import TextField from "@mui/material/TextField";
import Select from "react-select";
import Typography from "@mui/material/Typography";
import NoSsr from "@mui/material/NoSsr";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minWidth: 290
  },
  input: {
    display: "flex"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 12,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  }
}));

const NoOptionsMessage = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.selectProps.noOptionsString || "No Options"}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = props => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props;

  const [inputActive, setInputActive] = useState(false);

  return (
    <TextField
      fullWidth
      onFocusCapture={() => setInputActive(true)}
      onBlurCapture={() => setInputActive(false)}
      /* fix label overlapping https://mui.com/material-ui/react-text-field/#limitations */
      InputLabelProps={{ shrink: inputActive || !!TextFieldProps.value }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
};

const Option = props => {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = props => {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const SingleValue = props => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const ValueContainer = props => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};

const Menu = props => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

const selectComponents = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export const Autocomplete = ({
  label,
  input,
  error,
  options,
  disabled,
  helperText,
  noOptionsString,
  id,
  ...custom
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: "0px !important",
      marginBottom: theme.spacing(1.25)
    }),
    indicatorSeparator: base => ({
      ...base,
      display: "none"
    })
  };

  const inputId = useMemo(
    () =>
      `react-select-single-${input.name || Math.floor(Math.random() * 100)}`,
    [input]
  );

  return (
    <div id={id && id} className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId={inputId}
          TextFieldProps={{
            label: label,
            error: error,
            variant: custom.variant,
            helperText: helperText,
            value: input.value,
            name: input.name
          }}
          noOptionsString={noOptionsString}
          isDisabled={disabled}
          placeholder=""
          options={options}
          components={selectComponents}
          value={input.value}
          onChange={input.onChange}
        />
      </NoSsr>
    </div>
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  input: PropTypes.any,
  options: PropTypes.any,
  noOptionsString: PropTypes.string,
  meta: PropTypes.any,
  error: PropTypes.any,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string
};

export const FormAutocomplete = ({
  label,
  input,
  placeholder,
  options,
  noOptionsString,
  id,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <Autocomplete
      id={id}
      input={input}
      label={label}
      placeholder={placeholder}
      options={options}
      error={touched && invalid}
      helperText={touched ? error : ""}
      noOptionsString={noOptionsString}
      {...custom}
    />
  );
};

FormAutocomplete.propTypes = {
  label: PropTypes.string,
  input: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.any,
  noOptionsString: PropTypes.string,
  meta: PropTypes.any
};
