import React, { useContext } from "react";
import { reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";

import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./CSVController";
import { DocumentFormFields } from "Components/Forms/FormFields/CSV/DocumentFormFields";
import { useCSVFormController } from "Components/Forms/FormControllers/hooks/useCSVFormController";
import { CSVProgressBar } from "Fleet/Flows/CSV/CSVProgressBar";

const CSVDocumentContainerForm = reduxForm({
  form: "CSV_IMPORT",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep, form, loading }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      nextStepLabel={loading ? "Submitting..." : "Submit"}
      previousStepLabel="back"
      previousStep={prevStep}
      disabled={loading}
    >
      <FieldArray name="cars" component={DocumentFormFields} form={form} />
    </FlowContentContainer>
  );
});

export const CSVDocumentContainer = connect()(({ dispatch }) => {
  const { prevStep } = useContext(StepperContext);
  const {
    validate,
    loading,
    onSubmit,
    onSubmitFail,
    listedCarsProgress,
    totalCars
  } = useCSVFormController(dispatch);

  return loading ? (
    <CSVProgressBar progress={listedCarsProgress} total={totalCars} />
  ) : (
    <CSVDocumentContainerForm
      onSubmit={onSubmit}
      prevStep={prevStep}
      validate={validate}
      loading={loading}
      onSubmitFail={onSubmitFail}
    />
  );
});
