import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import capitalize from "lodash/capitalize";

import { RooftopCard } from "Components/Cards/RooftopCard";
import { RouteEnum } from "Enums/RouteEnum";
import { RooftopDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { openCreateRooftopDialog } from "Redux/UI/ActionCreators";
import { RooftopSettingsEdit } from "Fleet/Dashboard/DashboardViews/Settings/Rooftops/RooftopSettingsEdit";
import { checkPermission, renderByPermissions } from "Utils/RooftopUtils";
import emptyStateIcon from "Assets/images/default-car.svg";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  containerMobile: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3.25, 2, 0, 2)
    }
  },
  headerContainer: {
    marginBottom: theme.spacing(3.25),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    }
  },
  mainTitle: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium
  },
  createButton: {
    letterSpacing: theme.spacing(0.16),
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  cardContainer: {
    flexGrow: 1
  }
}));

export const RooftopSettingsListing = compose(
  connect(
    state => ({
      rooftops: get(state, "fleet.apiv2.rooftops.data"),
      rooftopTokens: get(state, "fleet.apiv2.rooftopTokens")
    }),
    { openCreateRooftopDialog }
  )
)(({ rooftops, rooftopTokens, openCreateRooftopDialog }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles({ matches });
  const { scopes } = useContext(ClientFactoryContext);
  const parsedRooftopTokensMap = keyBy(get(rooftopTokens, "data", []), token =>
    get(token, "parsed.rooftop.id")
  );

  return (
    <>
      {matches &&
        renderByPermissions(
          <Fab
            color="primary"
            onClick={openCreateRooftopDialog}
            className={classes.createButton}
          >
            <AddIcon />
          </Fab>,
          [RooftopDomainPermissionPaths.createRooftop],
          scopes
        )}
      <Grid container className={classes.containerMobile}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headerContainer}
        >
          <Grid item>
            <Typography
              component="h4"
              variant={matches ? "h6" : "h4"}
              className={classes.mainTitle}
            >
              {`${rooftops.length} ${
                rooftops.length === 1 ? "Rooftop" : "Rooftops"
              }`}
            </Typography>
          </Grid>
          {!matches &&
            renderByPermissions(
              <Grid item>
                <Fab
                  color="primary"
                  variant="extended"
                  onClick={openCreateRooftopDialog}
                  className={classes.createButton}
                >
                  <AddIcon className={classes.icon} />
                  CREATE ROOFTOP
                </Fab>
              </Grid>,
              [RooftopDomainPermissionPaths.createRooftop],
              scopes
            )}
        </Grid>
        <Grid item className={classes.cardContainer}>
          {rooftops.map(rooftop => {
            const bankName = get(rooftop, "ach.bankname");
            const last4 = get(rooftop, "ach.last4");
            const members = get(rooftop, "members", []);
            const listings = get(rooftop, "listings", []);
            const note = get(rooftop, "profile.note", "");

            return (
              <RooftopCard
                key={rooftop.id}
                imageSrc={get(rooftop, "profile.image")}
                defaultImageSrc={emptyStateIcon}
                imageAlt="rooftop"
                title={get(rooftop, "profile.name")}
                subTitle={get(rooftop, "profile.address.streetAddress")}
                rightTitle={`My Role: ${capitalize(
                  get(
                    parsedRooftopTokensMap,
                    `${rooftop.id}.parsed.member.roles[0]`
                  )
                )}`}
                to={`${RouteEnum.settingsRooftopsEdit}/${rooftop.id}`}
                buttonText="edit"
                avatars={members.map(member => ({
                  src: member.image,
                  tooltip: get(member, "user.name.formatted", "")
                }))}
                disabled={
                  !checkPermission(
                    RooftopDomainPermissionPaths.updateRooftop,
                    scopes
                  )
                }
                content={
                  matches ? (
                    <>
                      <Typography variant="body2">{`${bankName} (${last4})`}</Typography>
                      <Typography variant="body2">
                        {`${members.length} ${
                          members.length === 1 ? `User` : `Users`
                        } `}
                        <span>&#8226;</span>
                        {` ${listings.length} ${
                          listings.length === 1 ? `Listing` : `Listings`
                        }`}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {bankName && last4 && (
                        <Typography variant="body2">{`ACH: ${bankName} (....${last4})`}</Typography>
                      )}
                      <Typography variant="body2">
                        {`${members.length} Team Member${
                          members.length === 1 ? `` : `s`
                        } `}
                        <span>&#8226;</span>
                        {` ${listings.length} Listing${
                          listings.length === 1 ? `` : `s`
                        }`}
                      </Typography>
                      <Typography noWrap variant="body2">{`Note: ${
                        note
                          ? note.length > 25
                            ? `${get(rooftop, "profile.note", "").slice(
                                0,
                                25
                              )}...`
                            : note
                          : "No note"
                      }`}</Typography>
                    </>
                  )
                }
              ></RooftopCard>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
});

export const RooftopSettingsController = ({ payoutData, refetch }) => {
  return (
    <>
      {/* leaving commented for v2 */}
      {/* <Route
        path={RouteEnum.settingsRooftops}
        exact
        render={() => {
          return <Redirect to={RouteEnum.settingsRooftopsListing} />;
        }}
      /> */}
      <Switch>
        {/* <Route
          path={RouteEnum.settingsRooftopsListing}
          component={RooftopSettingsListing}
        /> */}
        <Route
          path={`${RouteEnum.settingsRooftopsEdit}/:id`}
          render={props => (
            <RooftopSettingsEdit
              {...props}
              payoutData={payoutData}
              refetch={refetch}
            />
          )}
        />
      </Switch>
    </>
  );
};
