import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { getMappedItem } from "./utils/item";

const useStyles = makeStyles(theme => ({
  itemLabel: {
    color: theme.palette.common.black
  }
}));

export function Item({ hit, history, handleRooftopChange }) {
  const classes = useStyles();
  const item = getMappedItem(hit);

  const handleClick = () => {
    document.activeElement.blur();
    handleRooftopChange(hit.rooftopId);
    history.push(`${item.path.route}/${hit.objectID}`);
  };

  return (
    <div onClick={handleClick}>
      <Typography variant="body2" color="textSecondary">
        {item.path.label}
      </Typography>
      <Typography className={classes.itemLabel}>{item.label}</Typography>
    </div>
  );
}

Item.propTypes = {
  hit: PropTypes.object,
  history: PropTypes.object,
  handleRooftopChange: PropTypes.func
};
