import React from "react";
import LoadingLottieAnimation from "Fleet/Dashboard/DashboardViews/Financials/Tables/Animations/LoadingAnimation";
import { Player } from "@lottiefiles/react-lottie-player";
import TablePlaceholder from "./TablePlaceholder";

const Animation = () => (
  <Player
    autoplay
    loop
    src={LoadingLottieAnimation}
    style={{ height: "auto", width: "160px" }}
  />
);

const LoadingTransactionsComponent = ({ subHeader }) => {
  return (
    <TablePlaceholder
      SourceImageComponent={Animation}
      header="Loading"
      subHeader={subHeader}
      subHeaderBottom="Please do not exit or refresh."
    />
  );
};
export default LoadingTransactionsComponent;
