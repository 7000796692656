import { gql } from "@apollo/client";

export const PAYOUTS_QUERY = gql`
  query viewPayouts($input: ViewPayoutsInputs) {
    viewPayouts(input: $input) {
      estimatedNextPayouts
      payouts {
        availableOn
        status
        bankAccount
        netEarnings
        netEarningsInCents
        payoutId
      }
      hasMore
    }
  }
`;
