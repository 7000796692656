import { useState, useContext } from "react";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useInboxSettingsState = () => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editReplyId, setEditReplyId] = useState();
  const [editShortCut, setEditShortCut] = useState("");
  const [editMessage, setEditMessage] = useState("");
  const { role } = useContext(ClientFactoryContext);

  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);
  const handleOpenEditModal = () => setEditModalOpen(true);
  const handleCloseEditModal = () => setEditModalOpen(false);
  const handleEditButtonClick = (id, title, message) => {
    setEditReplyId(id);
    setEditShortCut(title);
    setEditMessage(message);
    if (role === "p2p") {
      handleOpenEditModal();
    } else {
      handleOpenConfirmationModal();
    }
  };
  return {
    confirmationModalOpen,
    handleCloseConfirmationModal,
    handleOpenEditModal,
    handleCloseEditModal,
    editModalOpen,
    editReplyId,
    editShortCut,
    editMessage,
    setEditMessage,
    setEditShortCut,
    handleEditButtonClick
  };
};

export default useInboxSettingsState;
