import { gql } from "@apollo/client";

import { UserFragments } from "./UserFragments";
import { FileFragments } from "../File/FileFragments";
import { PaymentAccountsFragments } from "../PaymentAccounts/PaymentAccountsFragments";

export const CURRENT_PAYOUT_ACCOUNT_QUERY = gql`
  query getPayoutAccountInfo {
    getPayoutAccountInfo {
      payoutAccounts {
        id
        stripePayoutInformation {
          id
          account {
            id
            requirements {
              currentlyDue
              pastDue
              eventuallyDue
            }
          }
          bank {
            id
            last4
            bankName
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_LINK = gql`
  query getAccountLink {
    getAccountLink {
      url
      expiresAt
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    viewer {
      id
      me {
        ...CurrentUser
        location {
          ...UserLocation
        }
        profilePhoto {
          ...File
        }
        paymentAccounts {
          ...PaymentAccount
        }
        payoutAccounts {
          ...PayoutAccount
        }
      }
    }
  }
  ${UserFragments.currentUser}
  ${UserFragments.userLocation}
  ${FileFragments.file}
  ${PaymentAccountsFragments.paymentAccount}
  ${PaymentAccountsFragments.payoutAccount}
`;

export const USER_TYPE = gql`
  query UserType {
    viewer {
      id
      me {
        id
        type
      }
    }
  }
`;

export const JWT_INFO = gql`
  query JwtInfo {
    viewer {
      id
      me {
        id
        email
        fullName
        firstName
        lastName
        phone
        profilePhoto {
          id
          url
        }
      }
    }
  }
`;

export const CURRENT_USER_ID_QUERY = gql`
  query CurrentUserIdQuery {
    viewer {
      id
    }
  }
`;

export const PROTECTION_MODAL_CRITERIA = gql`
  query RequiresProtectionModal {
    viewer {
      id
      me {
        id
        createdAt
        userLookup {
          id
          protectionChangeAcceptedAt
        }
        owner {
          id
          cars(
            where: {
              AND: [
                { verification_in: [VERIFIED, PENDING_VERIFICATION] }
                { status_in: [RENTED, REMOVED, AVAILABLE] }
              ]
            }
            first: 1
          ) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;
