import { gql } from "@apollo/client";

export const AnalyticsFragments = {
  carEventData: gql`
    fragment CarEventData on CarEventDataResponse {
      cars {
        id
        year
        make
        model
        vin
        eventData {
          car_id
          event_text
          timestamp
          args_data_status
        }
      }
    }
  `
};
