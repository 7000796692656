import React, { useContext, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import StepAppBar from "../StepAppBar";
import { useHistory } from "react-router";
import { RouteEnum } from "Enums/RouteEnum";
import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const useStyles = makeStyles(theme => ({
  icon: () => ({
    fontSize: "5rem"
  }),
  title: () => ({
    fontSize: "24px"
  }),
  content: () => ({
    margin: "21px",
    color: "#000000",
    fontWeight: 400
  }),
  importantWarning: {
    color: theme.palette.error.main
  }
}));

const Failure = ({ error, onClose, oldRental, newRental }) => {
  const classes = useStyles();

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  const history = useHistory();
  const title = error.insuranceError
    ? "Insurance Problem"
    : "Car Switch Failed";
  return (
    <>
      <StepAppBar title="" onClose={onClose} />

      <DrawerPaddingContainer>
        <div className={classes.content}>
          <ErrorIcon className={classes.icon} color="error" />
          <Typography className={classes.title} variant="h5" component="h2">
            {title}
          </Typography>
          {error.insuranceError ? (
            <InsuranceErrorContent
              customerServicePhoneNumber={customerServicePhoneNumber}
              error={error}
            />
          ) : (
            <GenericErrorContent
              customerServicePhoneNumber={customerServicePhoneNumber}
            />
          )}
        </div>
      </DrawerPaddingContainer>

      <DrawerBottomBar
        onSubmit={() => {
          onClose();
          history.replace(
            `${RouteEnum.rentalsActive}/${
              error.insuranceError ? newRental.id : oldRental.id
            }`
          );
        }}
        submitText={"Done"}
        disabled={false}
      />
    </>
  );
};

const GenericErrorContent = ({ customerServicePhoneNumber }) => (
  <p>
    Something went wrong. Please call us to resolve this issue at{" "}
    <a href={`tel:1-${customerServicePhoneNumber}`}>
      {customerServicePhoneNumber}
    </a>
  </p>
);

const InsuranceErrorContent = ({ customerServicePhoneNumber, error }) => {
  const classes = useStyles();
  const missingFields = extractMissingFields(error?.insuranceError);

  return (
    <>
      <p>
        Your car has been successfully switched, however, insurance could not be
        issued.
      </p>
      <p className={classes.importantWarning}>
        Your vehicle is not insured, please do not hand off the keys to the
        driver until this issue is resolved
      </p>
      <p>
        To have your insurance issued, please call us at{" "}
        <a href={`tel:1-${customerServicePhoneNumber}`}>
          {customerServicePhoneNumber}
        </a>
      </p>
      {missingFields && (
        <p>
          Missing Information:
          {/* eslint-disable-next-line */}
          <ul padding-inline-start={"10px"}>
            {missingFields.map(missingField => (
              <li key={missingField}>{missingField}</li>
            ))}
          </ul>
        </p>
      )}
    </>
  );
};
const extractMissingFields = errorString => {
  try {
    return errorString
      .split("fields: ")[1]
      .split(", ")
      .map(missingField => {
        const sentenceCaseMissingField = missingField.replace(
          /([A-Z])/g,
          " $1"
        );
        return (
          sentenceCaseMissingField.charAt(0).toUpperCase() +
          sentenceCaseMissingField.slice(1)
        );
      });
  } catch (e) {
    return false;
  }
};
Failure.propTypes = {
  error: PropTypes.object,
  onClose: PropTypes.func,
  oldRental: PropTypes.object,
  newRental: PropTypes.object
};

export default Failure;
