import React, { useContext } from "react";
import Grid from "@mui/material/Grid";

import { RentalAggregateCard } from "Components/Cards/RentalAggregateCard";
import { ActionNeededCard } from "Components/Cards/ActionNeededCard";
import { EarningsCard } from "Components/Cards/EarningsCard";
import { RentalStatusCard } from "Components/Cards/RentalStatusCard";
import { PickupCards } from "Components/Cards/PickupCards";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import {
  JoyrideController,
  JoyrideTutorialEnum
} from "Components/Joyride/JoyrideController";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { PaymentDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import ExpiredDocumentsCardContainer from "Components/Cards/expiringDocuments/ExpiredDocumentsCardContainer";
import OverpricedCarAlert from "Components/Alerts/OverpricedCarAlert";
import useOverpricedCarAlert from "Components/hooks/useOverpricedCarAlert";

export const Home = () => {
  const { scopes } = useContext(ClientFactoryContext);
  const { showAlert, firstOverpricedCar } = useOverpricedCarAlert();

  const carPriceRecommendations = firstOverpricedCar?.priceRecommendations;

  return (
    <>
      <JoyrideController tutorial={JoyrideTutorialEnum.home} />
      <DashboardLayout fixed>
        <Grid container spacing={3} id={joyrideTutorialIds.home.dashboardHome}>
          {showAlert && (
            <Grid item xs={12}>
              <OverpricedCarAlert
                minRecommendedPrice={carPriceRecommendations?.low}
                maxRecommendedPrice={carPriceRecommendations?.high}
                averageEarning={720}
              />
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            <RentalAggregateCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <ActionNeededCard />
          </Grid>
          {renderByPermissions(
            <Grid item md={12} lg={5}>
              <EarningsCard />
            </Grid>,
            [PaymentDomainPermissionPaths.dashboardEarnings],
            scopes
          )}
          <Grid item md={12} lg={7}>
            <RentalStatusCard />
          </Grid>
          <PickupCards />
          <Grid item xs={12} md={6} lg={4}>
            <ExpiredDocumentsCardContainer />
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};
