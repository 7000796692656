import { OverpricedCarsContext } from "Components/Utils/OverpricedCarsProvider";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { checkAllPermissions } from "Utils/RooftopUtils";
import { CarsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useOverpricedCarAlert = () => {
  const { overpricedCars, priceAlertResponse } = useContext(
    OverpricedCarsContext
  );

  const { scopes } = useContext(ClientFactoryContext);

  const isRooftopUser = checkAllPermissions(
    [CarsDomainPermissionPaths.manualRooftopSelect],
    scopes
  );
  const location = useLocation();

  const getMarketingOrigin = searchParams => {
    const queryParams = new URLSearchParams(searchParams);
    const utm_source = queryParams.get("utm_source");
    const utm_campaign = queryParams.get("utm_campaign");
    return utm_source || utm_campaign ? { utm_source, utm_campaign } : null;
  };

  const marketingOrigin = getMarketingOrigin(location.search);

  useEffect(() => {
    if (overpricedCars?.data)
      analytics.track(AnalyticsEvents.label.owner.carOverpricedAlert, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.alertShown,
        label: AnalyticsEvents.label.owner.carOverpricedAlert,
        property: JSON.stringify({
          overpricedCars: overpricedCars.data,
          marketingOrigin: marketingOrigin
        }),
        value: "",
        context: "OverpricedCarAlert"
      });
  }, [overpricedCars]);

  const hasOverpricedCars =
    overpricedCars?.data?.filter(car => car.overpriced).length > 0;

  const alertUnmuteDate =
    priceAlertResponse?.priceAlertInfo?.alertUnmuteDate &&
    moment(priceAlertResponse?.priceAlertInfo?.alertUnmuteDate);

  const userHasUnmutedDate = !!priceAlertResponse?.priceAlertInfo?.id;

  const showAlert =
    !isRooftopUser &&
    hasOverpricedCars &&
    (!userHasUnmutedDate || alertUnmuteDate?.isSameOrBefore(moment()));

  const firstOverpricedCar = overpricedCars?.data?.find(car => car.overpriced);

  return {
    firstOverpricedCar,
    showAlert,
    priceAlertResponse,
    marketingOrigin
  };
};

export default useOverpricedCarAlert;
