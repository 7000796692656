import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

import { updateUserComunicationsConfig } from "API/restAPI";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { CURRENT_TOS_VERSION } from "Queries/TOS/TOSQueries";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { useContext } from "react";
import { TosContext } from "Components/Utils/TosProvider";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { USER_COMMUNICATION_OPTIONS } from "Login/SignupFlow/UserComunicationOptions";

const MARKET_MESSAGES_CHECKBOX_ELEMENT_ID =
  "terms-of-service-marketing-messages-checkbox";
const TRANSACTIONAL_MESSAGES_CHECKBOX_ELEMENT_ID =
  "terms-of-service-transactional-messages-checkbox";
const SUBMIT_POLICIES_ACCEPTED_ELEMENT_ID =
  "owner-terms-of-service-privacy-policy-accept-button";

const OPT_IN_CHECKBOXES_DISCLAIMER_TEXT = `By selecting the checkboxes above, I agree to receive text messages from HyreCar to the phone number provided. Message and data rates may apply. Message frequency varies. Text HELP for help. Text STOP to stop.`;

const useStyles = makeStyles(theme => ({
  accept: {
    float: "right",
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2)
  },
  checkbox: {
    marginLeft: "0px",
    paddingLeft: "0px"
  },
  checkboxes: {
    marginTop: "1%"
  },
  dialogContent: {
    lineHeight: "200%"
  },
  dialogTitle: {
    color: "black",
    fontSize: "2em",
    marginLeft: "0px"
  },
  disclaimer: {
    color: "#9E9E9E",
    fontSize: "70%",
    lineHeight: "120%",
    marginTop: "2%"
  },
  acceptanceDisclamer: {
    color: "#9E9E9E",
    fontSize: "70%",
    left: 120,
    lineHeight: "120%",
    margin: theme.spacing(2),
    marginBottom: "5%",
    position: "relative",
    width: "75%"
  },
  acceptanceDisclamerPortrait: {
    paddingRight: "20%"
  }
}));

const openNewTab = (url, event) => {
  analytics.track(event, {
    category: AnalyticsEvents.category.userInteraction,
    action: AnalyticsEvents.action.buttonClicked,
    label: event,
    property: JSON.stringify({
      flow: AnalyticsEvents.flow.onboarding
    }),
    value: "",
    context: ""
  });
  window.open(url, "_blank", "noopener,noreferrer");
};

const Title = ({ classes }) => (
  <DialogTitle className={classNames(classes.dialogTitle)}>
    Legal Updates
  </DialogTitle>
);

const CompanyTermsOfServiceLink = ({ getLink }) => (
  <Link
    onClick={() =>
      openNewTab(getLink("tos"), AnalyticsEvents.label.auth.viewTOSClicked)
    }
    target="_blank"
    underline="hover"
  >
    Terms of Service
  </Link>
);

const CompanyPrivacyPolicyLink = ({ getLink }) => (
  <Link
    onClick={() =>
      openNewTab(
        getLink("privacyPolicy"),
        AnalyticsEvents.label.auth.viewPrivacyPolicyClicked
      )
    }
    target="_blank"
    underline="hover"
  >
    Privacy Policy
  </Link>
);

const GooglePrivacyPolicyLink = () => (
  <Link
    href="https://policies.google.com/privacy"
    target="_blank"
    rel="noopener noreferrer"
    underline="hover"
  >
    Privacy Policy
  </Link>
);

const GoogleTermsOfServiceLink = () => (
  <Link
    href="https://policies.google.com/terms"
    target="_blank"
    rel="noopener noreferrer"
    underline="hover"
  >
    Terms of Service
  </Link>
);

const AgreementDisclaimerText = ({ classes, getLink, isViewportPortrait }) => (
  <div
    className={classNames(classes.acceptanceDisclamer, {
      [classes.acceptanceDisclamerPortrait]: isViewportPortrait
    })}
  >
    By clicking I accept, I agree to HyreCar &apos;s &nbsp;
    <CompanyPrivacyPolicyLink getLink={getLink} />
    &nbsp; and <CompanyTermsOfServiceLink getLink={getLink} />. This site is
    protected by reCAPTCHA and the Google &nbsp;
    <GooglePrivacyPolicyLink /> and <GoogleTermsOfServiceLink /> apply.
  </div>
);

const Checkboxes = ({
  classes,
  marketingMessagesApproved,
  transactionalMessagesApproved,
  setMarketingMessagesApproved,
  setTransactionalMessagesApproved
}) => (
  <Grid className={classNames(classes.checkboxes)} container spacing={1}>
    <Grid item xs={12}>
      <Checkbox
        className={classNames(classes.checkbox)}
        id={MARKET_MESSAGES_CHECKBOX_ELEMENT_ID}
        defaultChecked={false}
        onChange={() =>
          setMarketingMessagesApproved(!marketingMessagesApproved)
        }
      />
      {USER_COMMUNICATION_OPTIONS.marketing}
    </Grid>
    <Grid item xs={12}>
      <Checkbox
        className={classNames(classes.checkbox)}
        id={TRANSACTIONAL_MESSAGES_CHECKBOX_ELEMENT_ID}
        defaultChecked={false}
        onChange={() =>
          setTransactionalMessagesApproved(!transactionalMessagesApproved)
        }
      />
      {USER_COMMUNICATION_OPTIONS.transactional}
    </Grid>
  </Grid>
);

const AcceptButton = ({
  classes,
  submit,
  marketingMessagesApproved,
  transactionalMessagesApproved,
  submitting
}) => (
  <DialogActions className={classNames(classes.accept)}>
    <Button
      color="primary"
      disabled={submitting}
      fullWidth={false}
      id={SUBMIT_POLICIES_ACCEPTED_ELEMENT_ID}
      onClick={() =>
        submit(marketingMessagesApproved, transactionalMessagesApproved)
      }
      variant="contained"
    >
      I ACCEPT
    </Button>
  </DialogActions>
);

export default () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isViewportPortrait = useMediaQuery(theme.breakpoints.down("md"));

  const {
    bypassTermsOfServiceRequirement,
    client,
    rooftopTos,
    setRooftopTos
  } = useContext(TosContext);
  const { getLink } = useContext(FirebaseContext);

  const { data, refetch } = useQuery(CURRENT_USER_QUERY, {
    client
  });
  const { data: currentTos } = useQuery(CURRENT_TOS_VERSION, {
    client
  });

  const [marketingMessagesApproved, setMarketingMessagesApproved] = useState(
    false
  );
  const [
    transactionalMessagesApproved,
    setTransactionalMessagesApproved
  ] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [acceptCurrentTos] = useMutation(
    gql`
      mutation WebAcceptCurrentTos {
        acceptCurrentTos
      }
    `,
    {
      client
    }
  );

  const requiresTosAcceptance =
    !bypassTermsOfServiceRequirement &&
    (!!data?.viewer?.me?.requiresTosAcceptance || rooftopTos);

  const submit = async (
    marketingMessagesApproved,
    transactionalMessagesApproved
  ) => {
    setSubmitting(true);
    acceptCurrentTos()
      .then(() => {
        setRooftopTos(false);
        refetch();
        analytics.track(AnalyticsEvents.label.auth.tosAccepted, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.webConversion,
          label: AnalyticsEvents.label.auth.tosAccepted,
          property: JSON.stringify({
            versionAccepted: currentTos.CURRENT_TOS_VERSION
          }),
          value: "",
          context: ""
        });
      })
      .catch(e => {
        enqueueSnackbar("Sorry, something went wrong. Please try again.", {
          variant: "error"
        });
        setSubmitting(false);
        console.error(e);
      });
    updateUserComunicationsConfig(
      marketingMessagesApproved,
      transactionalMessagesApproved
    );
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={requiresTosAcceptance}
      fullScreen={isViewportPortrait}
      style={{ zIndex: 9999 }}
    >
      <Title classes={classes} />
      <DialogContent className={classNames(classes.dialogContent)}>
        We&apos;ve updated our &nbsp;
        <CompanyTermsOfServiceLink getLink={getLink} />
        &nbsp; and &nbsp;
        <CompanyPrivacyPolicyLink getLink={getLink} />
        . As part of these changes, we&apos;ve also updated the way we handle
        marketing (promotions) and transactional messages (important messages
        about your rental), giving you more control over how you receive
        communication.
        <Checkboxes
          classes={classes}
          marketingMessagesApproved={marketingMessagesApproved}
          transactionalMessagesApproved={transactionalMessagesApproved}
          setMarketingMessagesApproved={setMarketingMessagesApproved}
          setTransactionalMessagesApproved={setTransactionalMessagesApproved}
        />
        <div className={classNames(classes.disclaimer)}>
          {OPT_IN_CHECKBOXES_DISCLAIMER_TEXT}
        </div>
      </DialogContent>
      <AcceptButton
        classes={classes}
        submit={submit}
        marketingMessagesApproved={marketingMessagesApproved}
        transactionalMessagesApproved={transactionalMessagesApproved}
        submitting={submitting}
      />
      <AgreementDisclaimerText
        classes={classes}
        getLink={getLink}
        isViewportPortrait={isViewportPortrait}
      />
    </Dialog>
  );
};
