export const API_DATA_UPDATE = "API_DATA_UPDATE";
export const API_META_DATA_UPDATE = "API_META_DATA_UPDATE";
export const API_DATA_LOADING = "API_DATA_LOADING";

export const updateApiDataAction = payload => ({
  type: API_DATA_UPDATE,
  payload
});
export const updateApiMetaDataAction = payload => ({
  type: API_META_DATA_UPDATE,
  payload
});

export const updateApiDataLoadingAction = payload => ({
  type: API_DATA_LOADING,
  payload
});
