import { gql } from "@apollo/client";

export const RATE_DRIVER = gql`
  mutation RateDriver($rentalId: ID!, $rating: Int!, $comment: String) {
    rateDriver(
      data: { rentalId: $rentalId, rating: $rating, comment: $comment }
    ) {
      code
      success
      message
    }
  }
`;
