import React from "react";
import { withProps } from "recompose";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";
import { Menu as MenuIcon, X as CloseIcon } from "react-feather";
const PAGE_WRAP_ID = "page-wrap";

const Sidebar = styled(
  withProps(({ className, show, onStateChange }) => ({
    className: className,
    menuClassName: "modal-content",
    overlayClassName: `modal-backdrop fade in`,
    burgerButtonClassName: `navbar-toggle collapsed ${className}`,
    isOpen: show,
    styles: {
      bmBurgerButton: {
        zIndex: 0
      },
      bmOverlay: { background: "auto", opacity: "auto" },
      bmCrossButton: {
        top: 20,
        right: 20
      }
    },
    onStateChange: state => onStateChange(state.isOpen),
    customBurgerIcon: <MenuIcon style={{ width: "24px", height: "24px" }} />,
    customCrossIcon: <CloseIcon />
  }))(Menu)
)`
  &.navbar-toggle {
    padding: 0px 10px;
    padding-top: 3px;
  }
  .bm-icon {
    width: auto !important;
    height: auto !important;
  }
  .bm-burger-button {
    z-index: 0;
    float: right;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  @media (min-width: ${({ theme }) => theme.screenMd}) {
    .bm-menu {
      display: none;
    }
  }
`;
Sidebar.Main = withProps(() => ({ id: PAGE_WRAP_ID }))(styled.div``);

export default Sidebar;
