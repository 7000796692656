import React, { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import { Typography } from "@mui/material";

import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { END_RENTAL } from "Mutations/Rental/RentalMutations";
import { DrawerArrayImageHolder } from "Components/Drawers/DrawerArrayImageHolder";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerStaticBottomNote } from "Components/Drawers/DrawerStaticBottomNote";
import { ResponseCodeEnum } from "Enums/ResponseCodeEnums";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

const useStyles = makeStyles(theme => ({
  textColor: {
    color: theme.palette.common.black
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const RentalCheckOut = ({ onFinish, onCancel }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data } = useQuery(RENTAL_REQUEST_QUERY, {
    variables: {
      id
    },
    client: currentRooftopClient,
    fetchPolicy: "cache-only"
  });
  const response = useQuery(CURRENT_USER_QUERY, {
    client: currentRooftopClient
  });
  const viewer = response?.data?.viewer;

  const [checkOut, { loading }] = useMutation(END_RENTAL, {
    client: currentRooftopClient
  });
  const car = get(data, "rental.car", {});

  const onSubmit = async () => {
    try {
      analytics.track(AnalyticsEvents.label.owner.dropoffConfirmed, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.owner.dropoffConfirmed,
        property: JSON.stringify({
          rentalId: id,
          ownerId: get(viewer, "me.owner.id")
        }),
        value: "",
        context: ""
      });
      const { data } = await checkOut({
        variables: {
          rentalId: id,
          createClaim: false,
          hasPhysicalDamage: false,
          surveyComment: ""
        },
        refetchQueries: ["RentalsQuery"]
      });
      const code = get(
        data,
        "confirmDropoff.code",
        ResponseCodeEnum.REQUEST_FAILED
      );
      if (parseInt(code, 10) !== ResponseCodeEnum.OK) {
        throw new Error(
          get(data, "confirmDropoff.message", JSON.stringify(data))
        );
      } else {
        analytics.track(AnalyticsEvents.label.owner.dropoffSuccess, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.webConversion,
          label: AnalyticsEvents.label.owner.dropoffSuccess,
          property: JSON.stringify({
            rentalId: id,
            ownerId: get(viewer, "me.owner.id")
          }),
          value: "",
          context: ""
        });
        localStorage.setItem("shouldShowNPS", "true");
        enqueueSnackbar("Car was successfully checked out!", {
          variant: "success"
        });
        onFinish();
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
    }
  };

  const noteComponent = (
    <Typography component="p" variant="body1">
      Note: If you need to file a claim, you&apos;ll be able to do so after
      Check-Out.
    </Typography>
  );

  return (
    <>
      <DrawerPaddingContainer>
        <DrawerArrayImageHolder
          border={false}
          images={[
            {
              url: get(data, "rental.driver.user.profilePhoto.url")
            },
            {
              url: get(car, "photos[0].file.url")
            }
          ]}
        />
        <Typography component="h5" variant="h5" className={classes.textColor}>
          {`By pressing submit, you confirm that `}
          <span className={classes.boldText}>
            {`${get(data, "rental.driver.user.firstName")}'s`}
          </span>
          {` rental in your `}
          <span
            className={classes.boldText}
          >{`${car.year} ${car.make} ${car.model}`}</span>
          {` has ended.`}
        </Typography>
      </DrawerPaddingContainer>
      <DrawerStaticBottomNote noteComponent={noteComponent} borderTop={false} />
      <DrawerBottomBar
        onCancel={onCancel}
        cancelText="Cancel"
        onSubmit={onSubmit}
        submitText={loading ? "Submitting" : "Submit"}
        disabled={loading}
      />
    </>
  );
};
