import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Field, formValues } from "redux-form";

import { FormSelect } from "Components/Inputs/FormSelect";
import { FormTextField } from "Components/Inputs/FormTextField";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(4)
  }
}));

const options = [
  {
    label: "Yes",
    value: "Yes"
  },
  {
    label: "No",
    value: "No"
  }
];

const CarConditionMenu = ({ classes }) => (
  <>
    <Typography
      className={classes.spacing}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Car Condition
    </Typography>
    <div style={{ marginBottom: "1%" }} />
    <Field
      autoOk
      component={FormSelect}
      fullWidth
      id="claim-total-loss-assessment-car-condition"
      label="Is the car drivable?"
      name="isCarDrivable"
      options={options}
      variant="filled"
    />
  </>
);

const IsTotalLossMenu = ({ classes }) => (
  <>
    <Typography
      className={classes.spacing}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Total Loss Assessment
    </Typography>
    <Typography component="p" gutterBottom variant="subtitle2">
      Use the{" "}
      {
        <Link
          href={
            "https://docs.google.com/forms/d/e/1FAIpQLSeOVU6IfHwNYKarlOt8cdZu-XBK5j8g_-qUXNJ2gAzz3WEWHg/viewform"
          }
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          Total Loss Calculator
        </Link>
      }{" "}
      to answer this question
    </Typography>

    <div style={{ marginBottom: "1%" }} />
    <Field
      autoOk
      component={FormSelect}
      fullWidth
      id="claim-total-loss-assessment"
      label="Is this a total loss?"
      name="isATotalLoss"
      options={options}
      variant="filled"
    />
  </>
);

const Score = ({ classes }) => (
  <>
    <Typography
      className={classes.spacing}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Total Loss Calculator Score
    </Typography>
    <Field
      component={FormTextField}
      fullWidth
      id="claim-total-loss-assessment-score"
      label="Enter the score from the total loss calculator."
      multiline
      name="totalLossAssessmentScore"
      variant="filled"
    />
  </>
);

export const TotalLossAssessmentFormFields = formValues({
  isCarDrivable: "isCarDrivable",
  isATotalLoss: "isATotalLoss"
})(({ isCarDrivable, isATotalLoss }) => {
  const classes = useStyles();

  return (
    <>
      <CarConditionMenu classes={classes} />
      {isCarDrivable === "No" && <IsTotalLossMenu classes={classes} />}
      {isCarDrivable === "No" && isATotalLoss === "Yes" && (
        <Score classes={classes} />
      )}
    </>
  );
});
