import React from "react";
import { useHistory } from "react-router-dom";

import { FlowStepper } from "Components/Flows/FlowStepper";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { ClaimsTicketsChecklist } from "./ClaimsTicketsChecklist";
import { openSupportPage } from "Utils/openSupportPage";

export const StepperContext = React.createContext();

export const ClaimsTicketsController = () => {
  const history = useHistory();

  const prevStep = () => {
    history.goBack();
  };

  const onSubmit = () => {
    openSupportPage();
  };

  return (
    <StepperContext.Provider value={{ prevStep, onSubmit }}>
      <DashboardLayout hasAppBar>
        <FlowStepper steps={[]} header="Tickets and Tolls" />
        <ClaimsTicketsChecklist />
      </DashboardLayout>
    </StepperContext.Provider>
  );
};
