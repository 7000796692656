import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import RecommendedIcon from "@mui/icons-material/Star";

import SelectionCard from "./SelectionCard";
import { Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      lineHeight: 2
    }
  },
  title: { fontWeight: 500, marginTop: theme.spacing(1) },
  earnings: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(1)
  },
  recommended: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  recommendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProtectionPlanCard = ({ plan, onClick, selected }) => {
  const classes = useStyles();
  const getProtectionId = plan.title
    .replace("Protection", "selected")
    .replaceAll(" ", "-")
    .toLowerCase();
  return (
    <SelectionCard
      id={getProtectionId}
      title={plan.title}
      subtitle={plan.subtitle}
      pointer={!!onClick}
      selected={selected}
      onClick={onClick}
    >
      {plan.coverages.map(coverage => (
        <div key={coverage.title}>
          <Typography variant="body1" className={classes.title}>
            {coverage.title}
          </Typography>
          <ul className={classes.list}>
            {coverage.descriptions.map((description, i) => (
              <li key={i}>{description}</li>
            ))}
          </ul>
        </div>
      ))}
      <Box
        sx={{
          position: "relative",
          mt: "auto",
          mb: 2
        }}
      >
        {plan.recommended && (
          <Box className={classes.recommended}>
            <RecommendedIcon
              color="primary"
              fontSize="small"
              className={classes.recommendedIcon}
            />
            <Typography variant="subtitle1">Recommended</Typography>
          </Box>
        )}
        <Box className={classes.earnings}>
          <Typography fontWeight="bold" variant="h6">
            {plan.earnings}
          </Typography>
        </Box>
      </Box>
    </SelectionCard>
  );
};

export default ProtectionPlanCard;
