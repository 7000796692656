import { gql } from "@apollo/client";

import { FileFragments } from "Queries/File/FileFragments";

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload, $fileUrl: String, $type: FileTypeEnum!) {
    uploadFile(data: { file: $file, fileUrl: $fileUrl, type: $type }) {
      ...File
    }
  }
  ${FileFragments.file}
`;
