import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { MobileTable } from "Components/Tables/MobileTable";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { useTeamTable } from "Fleet/Dashboard/DashboardViews/Settings/Team/hooks/useTeamTable";

const useStyles = makeStyles(theme => ({
  mobileFab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export const MobileTeamTable = ({
  initialColumns,
  onRowClick,
  fabOnClick,
  hideFab
}) => {
  // commented until we need pagination
  // const { nextPage, page, first } = useTableState({
  //   initialFirst: 10
  // });
  const { currentOrganizationClient } = useContext(ClientFactoryContext);
  const { mappedData, loading } = useTeamTable({
    client: currentOrganizationClient,
    fetchPolicy: "cache-and-network"
  });
  const classes = useStyles();

  return (
    <Paper>
      <MobileTable
        disableHover
        columns={initialColumns}
        onRowClick={onRowClick}
        // hasNextPage={members.length > (page + 1) * first}
        loading={loading}
        // fetchMore={nextPage}
        data={mappedData}
      />
      {!hideFab && (
        <Fab className={classes.mobileFab} onClick={fabOnClick} color="primary">
          <AddIcon />
        </Fab>
      )}
    </Paper>
  );
};
