import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import PropTypes from "prop-types";

import { CAR_DEFAULT_PROTECTION_PLAN } from "Queries/Car/Car";
import { OWNER_DEFAULT_PROTECTION_PLAN } from "Queries/Owner/OwnerQueries";
import { UPDATE_CAR_DETAILS } from "Mutations/Car/CarMutations";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import ProtectionPlanCard from "Components/Dialog/protectionPlanDialog/ProtectionPlanCard";
import { protectionPlans } from "Components/Dialog/protectionPlanDialog/protectionPlanCopies";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(() => ({
  listItem: {
    padding: 0
  },
  listItemText: {
    margin: 0
  },
  protectionPlan: {
    marginBottom: "1em",
    width: "100%"
  }
}));

export const ListingProtectionPlan = ({ onClose, carId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [updateCarDetails, { loading: mutating }] = useMutation(
    UPDATE_CAR_DETAILS,
    {
      client: currentRooftopClient
    }
  );
  const { data: carProtectionPlanData } = useQuery(
    CAR_DEFAULT_PROTECTION_PLAN,
    {
      fetchPolicy: "cache-only",
      client: currentRooftopClient,
      variables: {
        id
      }
    }
  );
  const { data: ownerProtectionPlanData } = useQuery(
    OWNER_DEFAULT_PROTECTION_PLAN,
    {
      fetchPolicy: "cache-only",
      client: currentRooftopClient
    }
  );
  const carProtectionPlan = get(
    carProtectionPlanData,
    "car.defaultProtectionPlan",
    null
  );
  const ownerProtectionPlan = get(
    ownerProtectionPlanData,
    "viewer.me.owner.defaultProtectionPlan",
    null
  );
  const [protectionPlan, setProtectionPlan] = useState(
    carProtectionPlan ? carProtectionPlan : ownerProtectionPlan
  );

  const onSubmit = async () => {
    try {
      await updateCarDetails({
        variables: {
          data: {
            defaultProtectionPlan: protectionPlan,
            carId: id
          }
        }
      });
      enqueueSnackbar(
        "Successfully updated this Cars default protection plan!",
        {
          variant: "success"
        }
      );
      onClose();
    } catch (e) {
      enqueueSnackbar("Sorry, something went wrong. Please try again", {
        variant: "error"
      });
      console.error(e);
    }
  };

  return (
    <>
      <DrawerAppBar
        icon={
          <IconButton onClick={onClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        }
        border
        title="Protection Plan"
      />
      <DrawerPaddingContainer>
        <Grid container>
          {protectionPlans.map(plan => (
            <Grid item key={plan.value} className={classes.protectionPlan}>
              <ProtectionPlanCard
                plan={plan}
                selected={protectionPlan === plan.value}
                onClick={() => setProtectionPlan(plan.value)}
              />
            </Grid>
          ))}
        </Grid>
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onCancel={onClose}
        cancelText="Cancel"
        onSubmit={onSubmit}
        submitText={mutating ? "Saving..." : "Save"}
        disabled={mutating}
      />
    </>
  );
};

ListingProtectionPlan.propTypes = {
  onClose: PropTypes.func.isRequired,
  carId: PropTypes.string
};
