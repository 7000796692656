/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import get from "lodash/get";
import { useLocation } from "react-router";

import NPSModal from "Components/Dialog/NPSModal";
import { TabsWithRoutes } from "Components/Tabs/TabsWithRoutes";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { RouteEnum } from "Enums/RouteEnum";
import { RentalStatusEnum, RentalStatusCopyEnum } from "Enums/StateEnums";
import { RentalsTable } from "Components/Tables/RentalsTable";
import { RentalDrawer } from "Components/Drawers/RentalDrawer/RentalDrawer";
import { RentalsCardView } from "Components/Dashboard/RentalsCardView";
import { useRentalStatusAggregate } from "Queries/Rentals/hooks/useRentalStatusAggregate";
import { Chimes } from "Components/Loading/Chimes";
import { enableHeader, disableHeader } from "Redux/Header/ActionCreators";
import { MobileHeader } from "Components/Header/MobileHeader";
import { RouterTab } from "Components/Tabs/RouterTab";
import {
  JoyrideController,
  JoyrideTutorialEnum
} from "Components/Joyride/JoyrideController";
import { NoDataComponent } from "Components/Tables/NoData";
import { NoDataShareCars } from "Components/Tables/NoDataShareCars";
import { getRentalStatusOptions } from "Components/hooks/useRentalStatusOptions";
import {
  CURRENT_USER_QUERY,
  CURRENT_PAYOUT_ACCOUNT_QUERY
} from "Queries/User/UserQueries";
import { RENTAL_AGGREGATE_QUERY } from "Queries/Rentals/RentalQueries";
import { OWNERS_CARS_LATEST } from "Queries/Owner/OwnerQueries";
import emptyStateIcon from "Assets/images/empty-state.svg";
import {
  CarsDomainPermissionPaths,
  PaymentDomainPermissionPaths
} from "Components/Utils/Permissions/PermissionsPaths";
import { checkPermission } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.common.white
  }
}));

export const Rentals = connect(null, { disableHeader, enableHeader })(
  ({ disableHeader, enableHeader }) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const { scopes, currentRooftopClient, currentRooftopToken } = useContext(
      ClientFactoryContext
    );
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [activeTabValue, setActiveTabValue] = useState(
      history.location.pathname === RouteEnum.rentals
        ? RouteEnum.rentalsActive
        : history.location.pathname
    );
    const location = useLocation();

    const { data: rentalData } = useQuery(RENTAL_AGGREGATE_QUERY, {
      variables: {
        filter: {
          status: RentalStatusEnum.applied
        }
      },
      client: currentRooftopClient,
      fetchPolicy: "network-only",
      pollInterval: 60000
    });

    const appliedAggregate =
      rentalData?.viewer?.me?.owner?.rentalsConnection?.aggregate?.count || 0;

    const { data: ownerCars } = useQuery(OWNERS_CARS_LATEST, {
      variables: {
        where: {
          AND: [{ status_in: ["AVAILABLE"] }, { verification_in: ["VERIFIED"] }]
        },
        orderBy: "createdAt_DESC",
        first: 3
      },
      client: currentRooftopClient,
      fetchPolicy: "network-only"
    });

    const {
      loading,
      activeAggregate,
      pendingInsuranceAggregate,
      pendingPickupAggregate,
      lateAggregate,
      refetch
    } = useRentalStatusAggregate();

    const { data: userData } = useQuery(CURRENT_USER_QUERY, {
      client: currentRooftopClient
    });
    const currentUserId = userData?.viewer?.id;

    const { data: payoutData } = useQuery(CURRENT_PAYOUT_ACCOUNT_QUERY, {
      context: {
        apiv2: true,
        headers: {
          "x-rooftop-authorization": currentRooftopToken
        }
      },
      fetchPolicy: "network-only"
    });

    const requiresAch =
      get(
        payoutData,
        "getPayoutAccountInfo.payoutAccounts.stripePayoutInformation",
        null
      ) === null;

    const handleOnRowClick = rental => {
      history.push(`${history.location.pathname}/${rental.id}`);
    };

    const sendToAch = () => history.push(RouteEnum.settingsPayment);
    const sendToListCarFlow = () => history.push(RouteEnum.manual);
    const sendToSelectFlow = () => history.push(RouteEnum.selectListingType);

    const noDataButtons = [
      {
        content: "set up payments",
        onClick: sendToAch,
        hidden:
          !requiresAch ||
          !checkPermission(PaymentDomainPermissionPaths.createACH, scopes)
      },
      {
        content: "list a car",
        onClick: checkPermission(
          CarsDomainPermissionPaths.csvRooftopSelect,
          scopes
        )
          ? isMobile
            ? sendToListCarFlow
            : sendToSelectFlow
          : sendToListCarFlow,
        hidden: !checkPermission(CarsDomainPermissionPaths.listCar, scopes)
      }
    ];

    useEffect(() => {
      refetch();
    }, [history.location.pathname]);

    useEffect(() => {
      if (isMobile) {
        disableHeader();
      } else {
        enableHeader();
      }

      return enableHeader;
    }, [isMobile, disableHeader, enableHeader]);

    const filters = location.state?.filters || [];

    const getDefaultFilterStatus = (key, aggregateAmount) =>
      filters.length ? !filters.includes(key) : aggregateAmount <= 0;

    return (
      <>
        <NPSModal currentUserId={currentUserId} source={"confirmDropOff"} />
        <JoyrideController tutorial={JoyrideTutorialEnum.rentals} />
        <Route
          path={[
            `${RouteEnum.rentalsActive}/:id`,
            `${RouteEnum.rentalsRequests}/:id`,
            `${RouteEnum.rentalsPast}/:id`
          ]}
          render={props => <RentalDrawer {...props} payoutData={payoutData} />}
        />
        {isMobile ? (
          <MobileHeader
            title="Rentals"
            tabs={
              <Tabs
                variant="fullWidth"
                textColor="inherit"
                value={activeTabValue}
                onChange={(event, value) => setActiveTabValue(value)}
                classes={{ indicator: classes.indicator }}
              >
                <RouterTab
                  label="Requests"
                  to={RouteEnum.rentalsRequests}
                  value={RouteEnum.rentalsRequests}
                />
                <RouterTab
                  label="Current"
                  to={RouteEnum.rentalsActive}
                  value={RouteEnum.rentalsActive}
                />
                <RouterTab
                  label="Past"
                  to={RouteEnum.rentalsPast}
                  value={RouteEnum.rentalsPast}
                />
              </Tabs>
            }
          />
        ) : (
          <TabsWithRoutes
            paths={[
              {
                to: RouteEnum.rentalsRequests,
                label: "requests",
                badgeContent: appliedAggregate
              },
              {
                to: RouteEnum.rentalsActive,
                label: "Current"
              },
              {
                to: RouteEnum.rentalsPast,
                label: "Past"
              }
            ]}
          />
        )}
        <DashboardLayout fixed hasAppBar={isMobile}>
          <Route
            path={RouteEnum.rentals}
            exact
            render={() => {
              return <Redirect to={RouteEnum.rentalsActive} />;
            }}
          />
          <Switch>
            <Route
              key={RouteEnum.rentalsRequests}
              path={RouteEnum.rentalsRequests}
              render={() => {
                if (isMobile) {
                  return (
                    <RentalsCardView
                      initialFilter={{ status: RentalStatusEnum.applied }}
                      noDataComponent={
                        <NoDataComponent
                          title="You don't have any requests, yet."
                          subtitle={
                            !checkPermission(
                              PaymentDomainPermissionPaths.createACH,
                              scopes
                            ) &&
                            !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                              ? ""
                              : "In the meantime, you could share your cars with friends and family."
                          }
                          buttons={noDataButtons}
                          image={emptyStateIcon}
                        />
                      }
                      tabName="Requests"
                    />
                  );
                } else {
                  return (
                    <RentalsTable
                      onRowClick={handleOnRowClick}
                      initialFilter={{ status: RentalStatusEnum.applied }}
                      initialColumns={[
                        {
                          field: "driver",
                          title: "Driver"
                        },
                        {
                          field: "expiresIn",
                          title: "Expires"
                        },
                        {
                          field: "pickup",
                          title: "Pickup"
                        },
                        {
                          field: "vehicle",
                          title: "Year, Make, Model"
                        },
                        {
                          field: "vin",
                          title: "VIN"
                        },
                        {
                          field: "rentalPeriod",
                          title: "Rental Period"
                        },
                        {
                          field: "statusTableCell",
                          title: "Status"
                        }
                      ]}
                      tableName="Rental Requests"
                      noDataComponent={
                        <NoDataShareCars
                          cars={ownerCars?.viewer?.me?.owner?.cars?.edges}
                          title="You do not have any rental requests, yet."
                          subtitle={
                            !checkPermission(
                              PaymentDomainPermissionPaths.createACH,
                              scopes
                            ) &&
                            !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                              ? ""
                              : "In the meantime, you could share your cars with friends and family."
                          }
                        />
                      }
                    />
                  );
                }
              }}
            />
            <Route
              key={RouteEnum.rentalsActive}
              path={RouteEnum.rentalsActive}
              render={() => {
                if (isMobile) {
                  return (
                    <RentalsCardView
                      initialFilter={{
                        status_in: [
                          RentalStatusEnum.pendingInsurance,
                          RentalStatusEnum.pendingPickup,
                          RentalStatusEnum.active,
                          RentalStatusEnum.late
                        ]
                      }}
                      filters={[
                        {
                          key: "status_in",
                          value: RentalStatusEnum.pendingInsurance,
                          label: RentalStatusCopyEnum.accepted,
                          chipLabel:
                            pendingInsuranceAggregate > 0
                              ? `${RentalStatusCopyEnum.accepted} (${pendingInsuranceAggregate})`
                              : RentalStatusCopyEnum.accepted,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.pendingInsurance,
                            theme
                          ).color
                        },
                        {
                          key: "status_in",
                          value: RentalStatusEnum.pendingPickup,
                          label: RentalStatusCopyEnum.checkIn,
                          chipLabel:
                            pendingPickupAggregate > 0
                              ? `${RentalStatusCopyEnum.checkIn} (${pendingPickupAggregate})`
                              : RentalStatusCopyEnum.checkIn,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.pendingPickup,
                            theme
                          ).color
                        },
                        {
                          key: "status_in",
                          value: RentalStatusEnum.active,
                          label: RentalStatusCopyEnum.active,
                          chipLabel: activeAggregate
                            ? `${RentalStatusCopyEnum.active} (${activeAggregate})`
                            : RentalStatusCopyEnum.active,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.active,
                            theme
                          ).color
                        },
                        {
                          key: "status_in",
                          value: RentalStatusEnum.late,
                          label: RentalStatusCopyEnum.late,
                          chipLabel: lateAggregate
                            ? `${RentalStatusCopyEnum.late} (${lateAggregate})`
                            : RentalStatusCopyEnum.late,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.late,
                            theme
                          ).color
                        }
                      ]}
                      noDataComponent={
                        <NoDataComponent
                          title="No Current Rentals"
                          subtitle={
                            !checkPermission(
                              PaymentDomainPermissionPaths.createACH,
                              scopes
                            ) &&
                            !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                              ? ""
                              : "In the meantime, you could..."
                          }
                          buttons={noDataButtons}
                          image={emptyStateIcon}
                        />
                      }
                      tabName="Current"
                    />
                  );
                } else {
                  return loading ? (
                    <Chimes loadingText={"Loading..."} />
                  ) : (
                    <RentalsTable
                      onRowClick={handleOnRowClick}
                      initialFilter={{
                        status_in: [
                          RentalStatusEnum.pendingInsurance,
                          RentalStatusEnum.pendingPickup,
                          RentalStatusEnum.active,
                          RentalStatusEnum.late
                        ]
                      }}
                      initialColumns={[
                        {
                          field: "driver",
                          title: "Driver"
                        },
                        {
                          field: "vehicle",
                          title: "Year, Make, Model"
                        },
                        {
                          field: "vin",
                          title: "VIN"
                        },
                        {
                          field: "rentalPeriod",
                          title: "Rental Period"
                        },
                        {
                          field: "netEarnings",
                          title: "Net Earnings"
                        },
                        {
                          field: "statusTableCell",
                          title: "Status"
                        }
                      ]}
                      filters={[
                        {
                          key: "status_in",
                          value: RentalStatusEnum.pendingInsurance,
                          label: RentalStatusCopyEnum.accepted,
                          chipLabel:
                            pendingInsuranceAggregate > 0
                              ? `${RentalStatusCopyEnum.accepted} (${pendingInsuranceAggregate})`
                              : RentalStatusCopyEnum.accepted,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.pendingInsurance,
                            theme
                          ).color,
                          disableSelectedByDefault: getDefaultFilterStatus(
                            RentalStatusEnum.pendingInsurance,
                            pendingInsuranceAggregate
                          )
                        },
                        {
                          key: "status_in",
                          value: RentalStatusEnum.pendingPickup,
                          label: RentalStatusCopyEnum.checkIn,
                          chipLabel:
                            pendingPickupAggregate > 0
                              ? `${RentalStatusCopyEnum.checkIn} (${pendingPickupAggregate})`
                              : RentalStatusCopyEnum.checkIn,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.pendingPickup,
                            theme
                          ).color,
                          disableSelectedByDefault: getDefaultFilterStatus(
                            RentalStatusEnum.pendingPickup,
                            pendingPickupAggregate
                          )
                        },
                        {
                          key: "status_in",
                          value: RentalStatusEnum.active,
                          label: RentalStatusCopyEnum.active,
                          chipLabel: activeAggregate
                            ? `${RentalStatusCopyEnum.active} (${activeAggregate})`
                            : RentalStatusCopyEnum.active,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.active,
                            theme
                          ).color,
                          disableSelectedByDefault: getDefaultFilterStatus(
                            RentalStatusEnum.active,
                            activeAggregate
                          )
                        },
                        {
                          key: "status_in",
                          value: RentalStatusEnum.late,
                          label: RentalStatusCopyEnum.late,
                          chipLabel: lateAggregate
                            ? `${RentalStatusCopyEnum.late} (${lateAggregate})`
                            : RentalStatusCopyEnum.late,
                          chipColor: getRentalStatusOptions(
                            RentalStatusEnum.late,
                            theme
                          ).color,
                          disableSelectedByDefault: getDefaultFilterStatus(
                            RentalStatusEnum.late,
                            lateAggregate
                          )
                        }
                      ]}
                      tableName="Current Rentals"
                      noDataComponent={
                        <NoDataComponent
                          title="You do not have any Current Rentals"
                          subtitle={
                            !checkPermission(
                              PaymentDomainPermissionPaths.createACH,
                              scopes
                            ) &&
                            !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                              ? ""
                              : "In the meantime, you could..."
                          }
                          buttons={noDataButtons}
                        />
                      }
                      filtersSelectedByDefault
                    />
                  );
                }
              }}
            />
            <Route
              key={RouteEnum.rentalsPast}
              path={RouteEnum.rentalsPast}
              render={() => {
                if (isMobile) {
                  return (
                    <RentalsCardView
                      initialFilter={{
                        status_in: [RentalStatusEnum.completed]
                      }}
                      noDataComponent={
                        <NoDataComponent
                          title="No Past Rentals"
                          subtitle={
                            !checkPermission(
                              PaymentDomainPermissionPaths.createACH,
                              scopes
                            ) &&
                            !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                              ? ""
                              : "In the meantime, you could..."
                          }
                          buttons={noDataButtons}
                          image={emptyStateIcon}
                        />
                      }
                      tabName="Past"
                    />
                  );
                } else {
                  return (
                    <RentalsTable
                      onRowClick={handleOnRowClick}
                      initialFilter={{
                        status_in: [RentalStatusEnum.completed]
                      }}
                      initialColumns={[
                        {
                          field: "driver",
                          title: "Driver"
                        },
                        {
                          field: "vehicle",
                          title: "Year, Make, Model"
                        },
                        {
                          field: "vin",
                          title: "VIN"
                        },
                        {
                          field: "rentalPeriod",
                          title: "Rental Period"
                        },
                        {
                          field: "netEarnings",
                          title: "Net Earnings"
                        },
                        {
                          field: "statusTableCell",
                          title: "Status"
                        }
                      ]}
                      tableName="Past Rentals"
                      noDataComponent={
                        <NoDataComponent
                          title="You do not have any Past Rentals"
                          subtitle={
                            !checkPermission(
                              PaymentDomainPermissionPaths.createACH,
                              scopes
                            ) &&
                            !checkPermission(
                              CarsDomainPermissionPaths.listCar,
                              scopes
                            )
                              ? ""
                              : "In the meantime, you could..."
                          }
                          buttons={noDataButtons}
                        />
                      }
                    />
                  );
                }
              }}
            />
          </Switch>
        </DashboardLayout>
      </>
    );
  }
);
