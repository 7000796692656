import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(3)}`,
    marginBottom: theme.spacing(4)
  },
  rectWrapper: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    marginLeft: theme.spacing(3)
  },
  title: {
    width: "25%"
  },
  content: {
    width: "60%"
  }
}));

export const InboxChannelLoading = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Skeleton variant="circular" width={40} height={40} />
      <div className={classes.rectWrapper}>
        <Skeleton variant="rectangular" height={6} className={classes.title} />
        <Skeleton
          variant="rectangular"
          height={6}
          className={classes.content}
        />
      </div>
    </Grid>
  );
};
