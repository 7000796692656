import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import red from "@mui/material/colors/red";

const useStyles = makeStyles({
  root: {
    borderColor: red[900],
    backgroundColor: "transparent"
  },
  title: {
    color: red[900],
    marginBottom: 12
  },
  description: {
    marginBottom: 12
  }
});

const InsuranceWarning = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} variant="h5" component="h2">
          Insurance Warning
        </Typography>
        <Typography className={classes.description} color="textSecondary">
          Before switching a car, make sure the original car is in your
          possession.
        </Typography>
        <Typography className={classes.description} color="textSecondary">
          Once you switch cars, the original car will no longer be insured.
        </Typography>
        <Typography className={classes.description} color="textSecondary">
          Do not perform a car switch if the car has been in an accident. The
          rental must be completed and damaged assessed before the driver can
          rent again.
        </Typography>
        <Typography className={classes.description} color="textSecondary">
          If a car switch is performed on a vehicle that was involved in an
          accident, the car will lose its physical damage coverage.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default InsuranceWarning;
