import React, { useState, useContext } from "react";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import Button from "@mui/material/Button";
import classNames from "classnames";
import PropTypes from "prop-types";

import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { RouteEnum } from "Enums/RouteEnum";
import { HCBusinessHeader } from "Components/Header/HCBusinessHeader";
import { CarsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(8),
    color: "black",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.45rem",
      marginTop: theme.spacing(3)
    }
  },
  subtitle: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem"
    },
    color: "black",
    marginBottom: theme.spacing(3)
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    height: "100%",
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    },
    "& > *": {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(1)
      }
    }
  },
  icon: {
    width: "77px",
    height: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3)
  },
  border: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  button: {
    color: theme.palette.primary.contrastText
  },
  RouterLink: {
    textDecoration: "none"
  },
  link: {
    letterSpacing: "1.25px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  listIcon: {
    marginRight: "12px",
    display: "flex",
    height: "20px",
    width: "22px"
  },
  listText: {
    display: "flex",
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: theme.spacing(4)
  },
  cardDiv: {
    maxWidth: "400px"
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center"
  },
  listingTitle: {
    marginBottom: theme.spacing(3),
    color: "black"
  }
}));

const ListingTypeCard = ({
  icon,
  listingType,
  description1,
  description2,
  description3,
  description4,
  to,
  id
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [hovered, setHovered] = useState(false);
  return (
    <RouterLink
      to={{ pathname: to, state: location.state }}
      className={classes.RouterLink}
    >
      <Paper
        id={id && id}
        className={classNames(classes.paper, classes.border)}
        elevation={hovered ? 4 : 0}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img src={icon} alt={listingType} className={classes.icon} />
        <Typography
          component="h6"
          variant="h6"
          align="center"
          className={classes.listingTitle}
        >
          {listingType}
        </Typography>
        <Typography
          component="div"
          variant="subtitle2"
          align="left"
          className={classes.listText}
        >
          <DirectionsCarIcon className={classes.listIcon} />
          {description1}
        </Typography>
        <Typography
          component="div"
          variant="subtitle2"
          align="left"
          className={classes.listText}
        >
          <AccessTimeIcon className={classes.listIcon} />
          {description2}
        </Typography>
        <Typography
          component="div"
          variant="subtitle2"
          align="left"
          className={classes.listText}
        >
          <ErrorOutlineIcon className={classes.listIcon} />
          {description3}
        </Typography>
        <Typography
          component="div"
          variant="subtitle2"
          align="left"
          className={classes.listText}
        >
          <ImportantDevicesIcon className={classes.listIcon} />
          {description4}
        </Typography>
      </Paper>
    </RouterLink>
  );
};

ListingTypeCard.propTypes = {
  icon: PropTypes.node,
  listingType: PropTypes.string,
  description: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
  description3: PropTypes.string,
  description4: PropTypes.string,
  to: PropTypes.string
};

export const SelectListingType = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getAsset } = useContext(FirebaseContext);
  const { scopes } = useContext(ClientFactoryContext);

  const handleExitClick = () => {
    history.goBack();
  };

  return (
    <>
      <HCBusinessHeader>
        <Button className={classes.button} onClick={handleExitClick}>
          Exit
        </Button>
      </HCBusinessHeader>
      <DashboardLayout hasAppBar>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h3"
              align="center"
              className={classes.title}
            >
              Create a Listing
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              align="center"
              className={classes.subtitle}
            >
              How would you like to list your cars?{" "}
              <Hidden mdDown>
                <br />
              </Hidden>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.cardContainer}>
          <Grid item xs={12} sm={6} className={classes.cardDiv}>
            <ListingTypeCard
              id="single-car-listing-button"
              listingType="One at a time"
              icon={getAsset("manualListingIcon")}
              description1="Great for 1-3 cars"
              description2="Only takes a few minutes"
              description3="Not recommended for listing numerous cars at once"
              description4="Experience optimized for Desktop, Tablet and Mobile"
              to={RouteEnum.manual}
            />
          </Grid>
          {renderByPermissions(
            <Grid item xs={12} sm={6} className={classes.cardDiv}>
              <ListingTypeCard
                listingType="CSV Import"
                icon={getAsset("csvListingIcon")}
                description1="Great for 5+ Cars"
                description2="Could take 5-10 min"
                description3="May require some upfront work to format your CSV"
                description4="Experience optimized for Desktop and Tablet. Not available on Mobile"
                to={RouteEnum.csv}
              />
            </Grid>,
            [CarsDomainPermissionPaths.csvRooftopSelect],
            scopes
          )}
        </Grid>
      </DashboardLayout>
    </>
  );
};
