import React, { useContext } from "react";
import { Box, ListItemText, Typography } from "@mui/material";
import { List, ListItem } from "react-bootstrap/lib/Media";
import { Link } from "react-bootstrap/lib/Navbar";
import { makeStyles } from "@mui/styles";
import { FirebaseContext } from "../../../../Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.common.black,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export const IneligibleCar = ({ message }) => {
  const classes = useStyles();
  const { getLink } = useContext(FirebaseContext);
  return (
    <Box>
      <Typography className={classes.header} variant="body1" fontWeight="bold">
        {
          "Sorry, your VIN is not eligible to be listed. Please try another VIN."
        }
      </Typography>

      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>{message}</ListItemText>
        </ListItem>
      </List>
      {message.includes("Make not eligible") && (
        <Typography>
          <Link href={getLink("vehicleAcceptanceCriteria")} target="_blank">
            Here
          </Link>
          {
            " is the list of cars that we do not allow. Please list another car that is allowed."
          }
        </Typography>
      )}
      <Typography pt={2}>
        {
          "Even if your car is ineligible for HyreCar, it may still be a great match for Getaround. Learn how you can earn money "
        }
        <Link href={getLink("getAroundRentYourCar")} target="_blank">
          here
        </Link>
        .
      </Typography>
    </Box>
  );
};
