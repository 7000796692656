import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  leftColumn: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(9)
    }
  },
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    }
  },
  drawer: {
    padding: theme.spacing(2, 3)
  },
  drawerButton: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1, 2),
    background: theme.palette.primary[900],
    zIndex: 1
  },
  drawerButtonText: {
    flexGrow: "1",
    color: theme.palette.primary.contrastText
  },
  drawerButtonIcon: {
    color: theme.palette.primary.contrastText,
    background: "rgba(255, 255, 255, 0.4)"
  }
}));

export const ColumnFlowLayout = ({
  leftComponent,
  rightComponent,
  bottomRightComponent,
  useBottomDrawer
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);

  const handleBottomDrawerOpen = () => setBottomDrawerOpen(true);
  const handleBottomDrawerClose = () => setBottomDrawerOpen(false);

  return (
    <Grid container spacing={matches ? 3 : 4}>
      <Grid
        item
        md={rightComponent ? 7 : 12}
        xs={12}
        className={useBottomDrawer ? classes.leftColumn : null}
      >
        <Paper variant="outlined" className={classes.paper}>
          {leftComponent}
        </Paper>
      </Grid>
      {rightComponent && (
        <Fragment>
          {matches && useBottomDrawer ? (
            <Fragment>
              <SwipeableDrawer
                open={bottomDrawerOpen}
                onClose={handleBottomDrawerClose}
                anchor="bottom"
                classes={{
                  paper: classes.drawer
                }}
              >
                {rightComponent}
              </SwipeableDrawer>
              <div
                onClick={handleBottomDrawerOpen}
                className={classes.drawerButton}
              >
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.drawerButtonText}
                >
                  Need help? Click here.
                </Typography>
                <IconButton className={classes.drawerButtonIcon} size="large">
                  <ArrowUpwardIcon />
                </IconButton>
              </div>
            </Fragment>
          ) : (
            <Grid item md={5}>
              <Paper variant="outlined" className={classes.paper}>
                {rightComponent}
              </Paper>
              {bottomRightComponent || null}
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  );
};

ColumnFlowLayout.propTypes = {
  leftComponent: PropTypes.node.isRequired,
  rightComponent: PropTypes.node,
  bottomRightComponent: PropTypes.node,
  useBottomDrawer: PropTypes.bool
};
