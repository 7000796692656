import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useHistory } from "react-router-dom";

import CarListSkeleton from "Components/Car/CarListSkeleton";
import { RouteEnum } from "Enums/RouteEnum";
import CarListItem, {
  STATUS_WARNING,
  STATUS_ERROR
} from "Components/Car/CarListItem";
import { listingDrawerStepEnum } from "Enums/FlowStateEnums";
import ScrollableCard from "../ScrollableCard";
import ExpiredCsvDownload from "./ExpiredCsvDownload";

const EXPIRING_THRESHOLD_DAYS = 45;
const SKELETON_ITEM_COUNT = 2;

const isExpired = date => date.isBefore(moment());

const isExpiring = date =>
  date.isBefore(moment().add(EXPIRING_THRESHOLD_DAYS, "days"));

const isExpiredOrExpiring = date => isExpired(date) || isExpiring(date);

const hasAnyExpiredOrExpiringDoc = car =>
  car.expirationDates.reduce(
    (accu, date) => accu || isExpiredOrExpiring(date),
    false
  );

const transform = rawCar => ({
  ...rawCar,
  expirationDates: [
    rawCar.inspectionExpirationAt,
    rawCar.insuranceExpirationAt,
    rawCar.registrationExpirationAt
  ]
    .reduce((accu, date) => (date ? [...accu, moment(date)] : accu), [])
    .sort((a, b) => (a.isBefore(b) ? -1 : 1))
});

const reducer = (accu, rawCar) => {
  const car = transform(rawCar);
  return hasAnyExpiredOrExpiringDoc(car) ? [...accu, car] : accu;
};

const carsSortCriteria = (car1, car2) =>
  car1.expirationDates[0].isBefore(car2.expirationDates[0]) ? -1 : 1;

const ExpiredDocumentsCard = ({ data = [], loading }) => {
  const history = useHistory();
  const cars = useMemo(() => data.reduce(reducer, []).sort(carsSortCriteria), [
    data
  ]);

  const getLocationObject = car => ({
    pathname: `${RouteEnum.listingsAll}/${car.id}`,
    state: {
      openListingStep: listingDrawerStepEnum.edit,
      expandListingDocuments: true
    }
  });

  const getCarItems = cars =>
    cars.map(car => {
      let status;
      let message;
      const earliestDate = car.expirationDates[0];
      if (isExpired(earliestDate)) {
        status = STATUS_ERROR;
        message = `Documents expired on ${earliestDate.format("ll")}`;
      } else if (isExpiring(earliestDate)) {
        status = STATUS_WARNING;
        message = `Documents expiring on ${earliestDate.format("ll")}`;
      }
      return (
        <CarListItem
          key={`expiration_notice_${car.vin}`}
          car={car}
          secondaryText={message || ""}
          onClick={() => history.push(getLocationObject(car))}
          status={status}
        />
      );
    });

  const getLoadingStateComponent = () => (
    <CarListSkeleton amount={SKELETON_ITEM_COUNT} />
  );

  const getCardHeaderActionComponent = () => <ExpiredCsvDownload cars={cars} />;

  return (
    <ScrollableCard
      items={getCarItems(cars)}
      loading={loading}
      loadingStateComponent={getLoadingStateComponent()}
      headerActionComponent={getCardHeaderActionComponent()}
      title="Listings Need Updates"
      emptyStateCopy="Well done. You're up to date!"
    />
  );
};

const carShape = PropTypes.shape({
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  vin: PropTypes.string.isRequired,
  inspectionExpirationAt: PropTypes.string,
  insuranceExpirationAt: PropTypes.string,
  registrationExpirationAt: PropTypes.string
});

ExpiredDocumentsCard.propTypes = {
  data: PropTypes.arrayOf(carShape).isRequired,
  loading: PropTypes.bool
};

export default ExpiredDocumentsCard;
