import React, { useContext } from "react";
import { reduxForm } from "redux-form";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsDamageController";
import { useClaimsDamageFormController } from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";
import { DamageDetailsFormFields } from "Components/Forms/FormFields/Claims/DamageDetailsFormFields";
import { ContactSupportButton } from "Components/Buttons/ContactSupportButton";
import { TotalLossAssessmentFormFields } from "Components/Forms/FormFields/Claims/TotalLossAssessmentFormFields";
import { StorageAssessmentFormFields } from "Components/Forms/FormFields/Claims/StorageAssessmentFormFields";
import { LastInspectionDateFormFields } from "Components/Forms/FormFields/Claims/LastInspectionDateFormFields";

const ClaimsDamageDetailsForm = reduxForm({
  form: "CLAIMS_DAMAGE",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      header="Damage Details"
      nextStepLabel="continue"
      nextStepId="continue-damage-details"
      previousStepLabel="back"
      previousStep={prevStep}
      previousStepId="back-damage-details"
    >
      <DamageDetailsFormFields />
      <TotalLossAssessmentFormFields />
      <StorageAssessmentFormFields />
      <LastInspectionDateFormFields />
    </FlowContentContainer>
  );
});

export const ClaimsDamageDetails = () => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const { validate } = useClaimsDamageFormController();

  const leftComponent = (
    <ClaimsDamageDetailsForm
      validate={validate}
      onSubmit={nextStep}
      prevStep={prevStep}
    />
  );

  const rightComponent = (
    <TooltipContainer header="Helpful Tips">
      <Tooltip
        header="The damaged area isn't listed as an option"
        paragraphs={[
          "There are many parts to a car, and we listed the most common areas where damage occurs. If we missed one, just write it in when you describe what the damage looks like.",
          <ContactSupportButton
            key="b196a70b-3822-443f-9d3b-0625aea0f7d3"
            preText="Need help? "
            buttonText="CONTACT SUPPORT"
          />
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
};
