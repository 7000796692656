export const className = {
  WIDGET_BTN: "widget",
  NOTIFICATION: "notification",

  ACTIVE: "active",
  DISABLED: "disabled",

  CHANNEL_BOARD: "channel-board",
  BOARD_TOP: "board-top",
  OPTION_MENU: "option-menu",
  OPTION_CONTENT: "option-content",

  TITLE: "title",
  INPUT: "input",
  BTN: "btn",
  ITEM: "item",
  IMAGE: "image",
  TOP: "top",
  COUNT: "count",
  TIME: "time",
  UNREAD: "unread",

  CONTENT: "content",
  LOGIN_FORM: "login-form",
  LOGIN_BTN: "login-btn",
  USER_ID: "user-id",
  NICKNAME: "nickname",

  CHANNEL_LIST: "channel-list",
  CONTENT_TOP: "content-top",
  CONTENT_BOTTOM: "content-bottom",
  LAST_MESSAGE: "last-message",
  EMPTY_ITEM: "empty-item",

  CHAT_SECTION: "chat-section",
  CHAT_BOARD: "chat-board",
  MESSAGE_CONTENT: "message-content",
  MESSAGE_LIST: "message-list",
  TYPING: "typing",
  TEXT: "text",
  PREVIEW_NAME: "preview-name",
  PREVIEW_TITLE: "preview-title",
  PREVIEW_DESCRIPTION: "preview-description",
  PREVIEW_IMAGE: "preview-iamge",
  FILE_MESSAGE: "file-message",
  FILE: "file",
  FILE_ICON: "file-icon",
  FILE_NAME: "file-name",
  FILE_DOWNLOAD: "file-download",
  FILE_TEXT: "file-text",
  MESSAGE_SET: "message-set",
  USER: "user",
  MESSAGE_ITEM: "message-item",
  MESSAGE: "message",
  USER_CONTENT: "user-content",
  NEW_CHAT_BTN: "new-chat-btn",
  USER_SELECT: "user-select",
  USER_ITEM: "user-item",
  LEAVE_POPUP: "leave-popup",
  LEAVE_BTN: "leave-btn",
  CANCEL_BTN: "cancel-btn",
  ADMIN_MESSAGE: "admin-message",

  POPUP: "popup",
  MEMBERS: "members",
  INVITE: "invite",
  POPUP_BODY: "popup-body",
  POPUP_TOP: "popup-top",
  POPUP_CONTENT: "popup-content",
  POPUP_BOTTOM: "popup-bottom",
  INVITE_BTN: "invite-btn",
  IMAGE_ME: "image-me",

  TOOLTIP: "tooltip",

  IC_LOGIN: "ic-login",
  IC_CONNECTED: "ic-connected",
  IC_MINIMIZE: "ic-minimize",
  IC_OPTION: "ic-option",
  IC_NEW_CHAT: "ic-new-chat",
  IC_CLOSE: "ic-close",
  IC_MEMBERS: "ic-members",
  IC_INVITE: "ic-invite",
  IC_LEAVE: "ic-leave",

  FADE_IN: "sb-fade-in",
  FADE_OUT: "sb-fade-out",
  SPINNER: "sb-spinner"
};

export const styleValue = {
  CURSOR_INIT: "",
  CURSOR_DEFAULT: "default"
};

export const MAX_COUNT = "+9";
export const MAX_FONT_ZISE = "11";

export const TYPE_STRING = "string";
