import React from "react";
import { Field } from "redux-form";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import capitalize from "lodash/capitalize";
import Grid from "@mui/material/Grid";

import { FormSelectCheckbox } from "Components/Inputs/FormSelectCheckbox";
import { FormSelect } from "Components/Inputs/FormSelect";
import { nonDamageDetailsOptions } from "Components/Forms/FormControllers/hooks/useClaimsReimbursementFormController";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(2)
  }
}));

export const ReimbursementSelectRentalFormFields = ({ options }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6">
          Which rental are you requesting reimbursement for?
        </Typography>
        <Typography
          component="p"
          variant="subtitle2"
          className={classes.spacing}
        >
          All claims must be reported within 24 hours of Check-out.
        </Typography>
        <Field
          name="rentalId"
          component={FormSelect}
          options={options}
          fullWidth
          variant="filled"
          label="Select Rental"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6" className={classes.spacing}>
          What types of non-damage reimbursements?
        </Typography>
        <Field
          name="damagedAreas"
          component={FormSelectCheckbox}
          options={nonDamageDetailsOptions}
          placeholder="Select claims type"
          label="Select claim type"
          groupBy={option => capitalize(option.group)}
        />
      </Grid>
    </Grid>
  );
};

ReimbursementSelectRentalFormFields.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};
