import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  divider: { marginBottom: theme.spacing(2) },
  buttonContainer: {
    marginTop: theme.spacing(5)
  },
  header: ({ defaultHeader }) => {
    return {
      ...(!defaultHeader && {
        color: theme.palette.common.black
      })
    };
  },
  error: {
    color: theme.palette.error.main
  }
}));

export const FlowContentContainer = ({
  header,
  defaultHeader = false,
  subHeader,
  description,
  nextStep,
  nextStepLabel,
  previousStep,
  previousStepLabel,
  disabled,
  error,
  loading,
  children,
  previousStepId,
  nextStepId,
  stepId
}) => {
  const classes = useStyles({ defaultHeader });

  return (
    <Box sx={{ maxWidth: "100%" }}>
      {header && (
        <Fragment>
          <Typography
            component="h6"
            variant="h6"
            gutterBottom
            className={classes.header}
          >
            {header}
          </Typography>
          {subHeader && (
            <Typography variant="subtitle1" gutterBottom>
              {subHeader}
            </Typography>
          )}
          <Divider className={classes.divider} />
          {description && (
            <Box pb={2}>
              <Typography variant="subtitle1" gutterBottom>
                {description}
              </Typography>
            </Box>
          )}
        </Fragment>
      )}
      {children}
      <Grid
        container
        justifyContent="space-between"
        className={classes.buttonContainer}
      >
        {previousStep ? (
          <Grid item>
            <Button
              onClick={previousStep}
              id={previousStepId && previousStepId}
            >
              {previousStepLabel ? previousStepLabel : "Back"}
            </Button>
          </Grid>
        ) : (
          <div />
        )}
        {nextStep && (
          <Grid item>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                {error && (
                  <Typography className={classes.error}>{error}</Typography>
                )}
                {loading && <CircularProgress color="primary" size={35} />}
              </Grid>
              <Grid item>
                <Button
                  id={stepId || nextStepId}
                  onClick={nextStep}
                  color="primary"
                  variant="contained"
                  size="medium"
                  disabled={disabled}
                  data-testId={stepId}
                >
                  {nextStepLabel ? nextStepLabel : "Next"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

FlowContentContainer.propTypes = {
  header: PropTypes.string,
  defaultHeader: PropTypes.bool,
  subHeader: PropTypes.string,
  description: PropTypes.string,
  nextStep: PropTypes.func,
  nextStepLabel: PropTypes.string,
  previousStep: PropTypes.func,
  previousStepLabel: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  stepId: PropTypes.string
};
