import {
  GET_REVENUE_DATA,
  GET_REVENUE_DATA_SUCCESS,
  GET_REVENUE_DATA_ERROR,
  GET_OWNER_CAR_IDS,
  GET_OWNER_CAR_IDS_SUCCESS,
  GET_OWNER_CAR_IDS_ERROR,
  UPDATE_VIN,
  UPDATE_CURSOR,
  UPDATE_LOADING,
  UPDATE_TRANSACTION_DATE_FILTERS,
  UPDATE_TRANSACTION_CUSTOM_DATE_FILTERS,
  TOGGLE_TRANSACTIONS_DATE_DIALOG
} from "./Actions";
import { CAR_REVENUE } from "Queries/Analytics/Analytics";
import { OWNERS_CAR_IDS } from "Queries/Owner/OwnerQueries";
import { client } from "Components/Utils/ApolloProvider";
import { CarVerificationEnum } from "Enums/StateEnums";

export const getRevenueData = () => ({
  type: GET_REVENUE_DATA
});

export const getRevenueDataSuccess = dataset => ({
  type: GET_REVENUE_DATA_SUCCESS,
  dataset
});

export const getRevenueDataError = error => ({
  type: GET_REVENUE_DATA_ERROR,
  error
});

export const getOwnerCarIds = () => ({
  type: GET_OWNER_CAR_IDS
});

export const getOwnerCarIdsSuccess = dataset => ({
  type: GET_OWNER_CAR_IDS_SUCCESS,
  dataset
});

export const getOwnerCarIdsError = error => ({
  type: GET_OWNER_CAR_IDS_ERROR,
  error
});

export const updateVin = vin => ({
  type: UPDATE_VIN,
  vin
});

export const updateCursor = offset => ({
  type: UPDATE_CURSOR,
  offset
});

export const updateLoading = loading => ({
  type: UPDATE_LOADING,
  loading
});

export const updateTransactionDateFilters = selectedRange => ({
  type: UPDATE_TRANSACTION_DATE_FILTERS,
  selectedRange
});

export const handleTransactionsCustomDateFilters = customRange => ({
  type: UPDATE_TRANSACTION_CUSTOM_DATE_FILTERS,
  customRange
});
export const toggleTransactionsDateDialog = customDateDialogOpen => ({
  type: TOGGLE_TRANSACTIONS_DATE_DIALOG,
  customDateDialogOpen
});

export const asyncGetOwnerCarIds = () => dispatch => {
  dispatch(getOwnerCarIds());
  client
    .query({
      query: OWNERS_CAR_IDS,
      variables: {
        orderBy: "updatedAt_DESC",
        where: {
          verification_in: [
            CarVerificationEnum.verified,
            CarVerificationEnum.failedVerification,
            CarVerificationEnum.pendingVerification
          ]
        }
      }
    })
    .then(
      ({
        data: {
          viewer: {
            me: {
              owner: {
                cars: { edges }
              }
            }
          }
        }
      }) => {
        dispatch(getOwnerCarIdsSuccess(edges));
      }
    )
    .catch(e => dispatch(getOwnerCarIdsError(e)));
};

export const asyncGetRevenueData = ({ carIds }) => dispatch => {
  if (carIds.length === 0) {
    dispatch(updateLoading(false));
  } else {
    dispatch(getRevenueData());
    client
      .query({
        query: CAR_REVENUE,
        variables: {
          carIds: carIds
        },
        fetchPolicy: "network-only"
      })
      .then(
        ({
          data: {
            getCarRevenue: { carRevenueAnalytics, success, message }
          }
        }) => {
          if (success) {
            dispatch(getRevenueDataSuccess(carRevenueAnalytics));
          } else {
            dispatch(getRevenueDataError(message));
          }
        }
      )
      .catch(e => dispatch(getRevenueDataError(e)));
  }
};
