import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  search: {
    width: "100%"
  }
});

const SelectCarSearch = ({ searchValue, handleSearch }) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.search}
      placeholder="Search by VIN, Make, Model, Year"
      value={searchValue}
      onChange={handleSearch}
      autoFocus
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

SelectCarSearch.propTypes = {
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func
};

export default SelectCarSearch;
