import { gql } from "@apollo/client";

export const FileFragments = {
  file: gql`
    fragment File on File {
      id
      type
      path
      url
      contentType
      thumbnailUrl
      createdAt
      updatedAt
    }
  `
};
