import React from "react";

export const JoyrideTutorialEnum = {
  home: "HOME",
  rentals: "RENTALS",
  listings: "LISTINGS",
  financials: "FINANCIALS",
  inbox: "INBOX",
  resources: "RESOURCES",
  settings: "SETTINGS",
  reimbursementTool: "REIMBURSEMENT_TOOL",
  reimbursementToolAndClaims: "REIMBURSEMENT_TOOL_AND_CLAIMS",
  ticketReimbursement: "TICKET_REIMBURSEMENT"
};

export const joyrideTutorialIds = {
  home: {
    dashboardHome: "dashboard-home",
    notificationsButton: "notifications-button",
    listCarButton: "list-car-button",
    rentalsDrawerLink: "rentals-drawer-link",
    listingsDrawerLink: "listings-drawer-link",
    financialsDrawerLink: "financials-drawer-link",
    inboxDrawerLink: "inbox-drawer-link",
    resourcesDrawerLink: "resources-drawer-link",
    settingsDrawerLink: "settings-drawer-link",
    userAvatarDropdown: "user-avatar-dropdown"
  },
  tabsWithRoutes: "tabs-with-routes",
  tableDatePicker: "tutorial-table-date-picker",
  tableFab: "tutorial-table-fab",
  tableSearch: "table-search",
  tableFilterButton: "table-filter-button",
  tableWrapper: "table-wrapper",
  tablePagination: "table-pagination",
  inboxChannelsWrapper: "tutorial-inbox-channels-wrapper",
  inboxSearch: "tutorial-inbox-search",
  inboxChat: "tutorial-inbox-chat",
  inboxMessage: "tutorial-inbox-message",
  requestReimbursement: "tutorial-request-reimbursement",
  fileClaimButton: "Start-Report-Accident",
  ticketReimbursement: "salesforce-rental-ticket-reimbursement-form",
  deleteListing: {
    status: "change-listing-status"
  }
};

export const getJoyrideTutorial = type => {
  switch (type) {
    case JoyrideTutorialEnum.rentals:
      return {
        modal: {
          header: "Your Rentals",
          content: [
            "Rentals are the core of your business.",
            "Here you can accept rental requests and manage the status of your current bookings.",
            "This guide (2 minutes) will help you understand steps of the rental process and how to find exactly what you're looking for."
          ]
        },
        steps: [
          {
            content:
              "Quickly switch between incoming Requests from Drivers, Current Rentals, and Past Rentals.",
            target: `#${joyrideTutorialIds.tabsWithRoutes}`,
            disableBeacon: true
          },
          {
            content:
              "Find exactly what you're looking for by searching for Drivers, Make, Model, Year or VIN.",
            target: `#${joyrideTutorialIds.tableSearch}`,
            disableBeacon: true
          },
          {
            content:
              "All important information about your rentals will appear here.",
            target: `#${joyrideTutorialIds.tableWrapper}`
          },
          {
            content:
              "Choose how many results you want to see at once and navigate between pages easier.",
            target: `#${joyrideTutorialIds.tablePagination}`
          },
          {
            content:
              "Don't forget, you can always get in touch with HyreCar here. Our customer support is now available 24/7.",
            target: `#${joyrideTutorialIds.home.userAvatarDropdown}`
          }
        ]
      };
    case JoyrideTutorialEnum.listings:
      return {
        modal: {
          header: "Your Listings",
          content: [
            "Listings are cars that you've added to the HyreCar marketplace.",
            "Here you can create and edit your listings.",
            "This guide (2 minutes) will help you understand how to navigate the page and sort or filter through your fleet."
          ]
        },
        steps: [
          {
            content:
              "Quickly switch between your verified HyreCar Listings and your Listings that are still pending approval from our agents.",
            target: `#${joyrideTutorialIds.tabsWithRoutes}`,
            disableBeacon: true
          },
          {
            content:
              "Find a specific car by searching for Make, Model, Year or VIN",
            target: `#${joyrideTutorialIds.tableSearch}`
          },
          {
            content:
              "All important information about your listings will appear here.",
            target: `#${joyrideTutorialIds.tableWrapper}`
          },
          {
            content:
              "Choose how many results you want to see at once and navigate between pages easier.",
            target: `#${joyrideTutorialIds.tablePagination}`
          },
          {
            content:
              "Don't forget, you can always get in touch with HyreCar here. Our customer support is now available 24/7.",
            target: `#${joyrideTutorialIds.home.userAvatarDropdown}`
          }
        ]
      };
    case JoyrideTutorialEnum.inbox:
      return {
        modal: {
          header: "Inbox",
          content: [
            "Your Inbox is the best place to manage communications with drivers.",
            "Here you'll find a history of all your conversations.",
            "This guide (1 minute) will explain how to navigate your inbox."
          ]
        },
        steps: [
          {
            disableBeacon: true,
            content: "All of your messages with drivers will show up here.",
            target: `#${joyrideTutorialIds.inboxChannelsWrapper}`
          },
          {
            content:
              "Once you've started chats, you can search to find a specific conversation with a driver.",
            target: `#${joyrideTutorialIds.inboxSearch}`
          },
          {
            content: "Your conversation history will show up here.",
            target: `#${joyrideTutorialIds.inboxChat}`
          },
          {
            content:
              "Type your message and press send to chat with the Driver.",
            target: `#${joyrideTutorialIds.inboxMessage}`
          },
          {
            content:
              "Don't forget, you can always get in touch with HyreCar here. Our customer support is now available 24/7.",
            target: `#${joyrideTutorialIds.home.userAvatarDropdown}`
          }
        ]
      };
    case JoyrideTutorialEnum.financials:
      return {
        modal: {
          header: "Financials",
          content: [
            "Understanding Financials is the key to knowing the health of your business.",
            "Here you can view your transactions line by line and export reports.",
            "This guide (2 minutes) will help you understand how to read your transactions data."
          ]
        },
        steps: [
          {
            content:
              "Your Financials are organized by Transactions (payments and payouts) and Listing Revenue, which summarizes earnings per car.",
            target: `#${joyrideTutorialIds.tabsWithRoutes}`,
            disableBeacon: true
          },
          {
            content:
              "We always show transactions for the most current month. Click here to view transactions from a different month.",
            target: `#${joyrideTutorialIds.tableDatePicker}`
          },
          {
            content:
              "Find a certain transaction in that month by searching for Driver Name, Make, Model, or Year.",
            target: `#${joyrideTutorialIds.tableSearch}`
          },
          {
            content:
              "Here you can view all Payouts by transfer date. Within each Payout, you can see all of the indivdual Payments that add up to the total Payout amount.",
            target: `#${joyrideTutorialIds.tableWrapper}`
          },
          {
            content:
              "Export a group of transactions by clicking this big blue button. We'll email you the CSV file.",
            target: `#${joyrideTutorialIds.tableFab}`
          },
          {
            content:
              "Don't forget, you can always get in touch with HyreCar here. Our customer support is now available 24/7.",
            target: `#${joyrideTutorialIds.home.userAvatarDropdown}`
          }
        ]
      };
    case JoyrideTutorialEnum.ticketReimbursement:
      return {
        steps: [
          {
            content: (
              <div>
                We’ve added a way for you to conveniently submit tickets
                incurred during your rental.
                <p></p>
                Our team will attempt to collect on your behalf and we will be
                in touch with you via email.
                <p></p>
                Ensure you have the correct details and evidence to expedite the
                process.
              </div>
            ),
            disableBeacon: true,
            target: `#${joyrideTutorialIds.ticketReimbursement}`
          }
        ]
      };
    case JoyrideTutorialEnum.deleteListing.status:
      return {
        steps: [
          {
            content: (
              <div>
                We’ve added another listing status to help you organize your
                inventory
                <p></p>
                Click on the listing status to find out more
              </div>
            ),
            disableBeacon: true,
            target: `#${joyrideTutorialIds.deleteListing.status}`
          }
        ]
      };
    case JoyrideTutorialEnum.home:
    default:
      return {
        modal: {
          header: "Welcome to the new Owner Experience!",
          content: [
            "We’ve changed the look and feel of our website while adding new features to help you better run your business.",
            "This guide (3 minutes) will help you understand how to navigate through the main menu. We’ll also explain some new tools we’ve introduced."
          ]
        },
        steps: [
          {
            content:
              "Here you'll find an overview of how your fleet is doing as a whole.",
            target: `#${joyrideTutorialIds.home.dashboardHome}`,
            disableBeacon: true
          },
          {
            content:
              "We'll notify you when you have new messages here. Click to view unread messages.",
            target: `#${joyrideTutorialIds.home.notificationsButton}`
          },
          {
            content: "Click here to list a car.",
            target: `#${joyrideTutorialIds.home.listCarButton}`
          },
          {
            content:
              "Find everything related to rentals including Requests, Current, and Past Rentals.",
            target: `#${joyrideTutorialIds.home.rentalsDrawerLink}`
          },
          {
            content: "View and edit your HyreCar listings.",
            target: `#${joyrideTutorialIds.home.listingsDrawerLink}`
          },
          {
            content:
              "Review and export all of your transactions or view how much revenue each car has earned.",
            target: `#${joyrideTutorialIds.home.financialsDrawerLink}`
          },
          {
            content: "Communicate directly with drivers.",
            target: `#${joyrideTutorialIds.home.inboxDrawerLink}`
          },
          {
            content:
              "Visit our Help Center to read FAQs, Policies, or file a claim from your Resources page.",
            target: `#${joyrideTutorialIds.home.resourcesDrawerLink}`
          },
          {
            content:
              "Update your account information and ACH to receive payment in your Settings page.",
            target: `#${joyrideTutorialIds.home.settingsDrawerLink}`
          },
          {
            content: "Got questions? Help is always just a click away.",
            target: `#${joyrideTutorialIds.home.userAvatarDropdown}`
          }
        ]
      };
  }
};
