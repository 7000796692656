import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Player } from "@lottiefiles/react-lottie-player";

import CheckInSubmittedAnimation from "Fleet/Flows/CheckInFlow/CheckInSubmittedAnimation";

const useStyles = makeStyles(theme => ({
  animationContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "0",
    [theme.breakpoints.down("md")]: {
      marginTop: "150px"
    }
  },
  animationHeader: {
    color: "#222222",
    fontSize: "20px",
    lineHeight: "30px",
    margin: "16px 0 8px 0"
  },
  animationSubheader: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "16px",
    lineHeight: "24px",
    maxWidth: "370px",
    textAlign: "center"
  }
}));

export const CheckInSubmittingScreen = () => {
  const classes = useStyles();
  return (
    <div className={classes.animationContainer}>
      <Player
        autoplay
        loop
        src={CheckInSubmittedAnimation}
        style={{ height: "auto", width: "160px" }}
      ></Player>
      <p className={classes.animationHeader}>Processing</p>
      <span className={classes.animationSubheader}>
        Please wait a few seconds while we finish processing your Check-in.
      </span>
    </div>
  );
};
