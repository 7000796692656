/* eslint-disable react/jsx-key */
import React from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import NumberFormat from "react-number-format";

import { Table } from "Components/Tables/Table";
import { TableHead } from "Components/Tables/TableHead";
import moment from "moment";
import { parseNumberFromCurrencyString } from "Utils/Helpers";
import SummarySkeleton from "./SummarySkeleton";

const useStyles = makeStyles(() => ({
  paper: {
    border: "1px solid #E5E5E5",
    marginBottom: "34px"
  }
}));

const columns = [
  {
    title: "",
    field: "transactionType"
  },
  {
    title: "Count",
    field: "count",
    align: "right"
  },
  {
    title: "Gross",
    field: "gross",
    align: "right"
  },
  {
    title: "Fees",
    field: "fees",
    align: "right"
  },
  {
    title: "Net",
    field: "net",
    align: "right"
  }
];

const mobileColumns = [
  {
    title: "",
    field: "transactionType"
  },
  {
    title: "Net",
    field: "net",
    align: "right"
  }
];

export const SummaryTable = ({ data, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const gatherTableData = () => {
    if (loading || !data?.viewTransactions) return [];
    const {
      chargeTotal,
      chargeFeeTotal,
      chargeCount,
      feeTotal,
      feeCount,
      refundTotal,
      refundCount,
      claimTotal,
      claimCount
    } = data.viewTransactions.reduce(
      (acc, curr) => {
        const rentalRevenue = parseNumberFromCurrencyString(curr.rentalRevenue);
        const protectionPlanFee = parseNumberFromCurrencyString(
          curr.protectionPlanFee
        );
        const amountRefunded = parseNumberFromCurrencyString(
          curr.amountRefunded
        );
        const netEarnings = parseNumberFromCurrencyString(curr.netEarnings);
        switch (curr.transactionType) {
          case "charge":
            acc.chargeTotal += rentalRevenue;
            acc.chargeFeeTotal += protectionPlanFee;
            acc.chargeCount++;
            break;
          case "fee":
            acc.feeTotal += netEarnings;
            acc.feeCount++;
            break;
          case "refund":
            acc.refundTotal += amountRefunded;
            acc.refundCount++;
            break;
          case "claim":
            acc.claimTotal += netEarnings;
            acc.claimCount++;
            break;
          default:
            break;
        }
        return acc;
      },
      {
        chargeTotal: 0,
        chargeFeeTotal: 0,
        chargeCount: 0,
        feeTotal: 0,
        feeCount: 0,
        refundTotal: 0,
        refundCount: 0,
        claimTotal: 0,
        claimCount: 0
      }
    );

    const rows = [
      {
        transactionType: "Transactions",
        count: `${chargeCount}`,
        gross: (
          <NumberFormat
            value={chargeTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ),
        fees: (
          <NumberFormat
            value={chargeFeeTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={chargeFeeTotal ? "-$" : "$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ),
        net: (
          <NumberFormat
            value={chargeTotal - chargeFeeTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        )
      },
      {
        transactionType: "Claim Reimbursements",
        count: `${claimCount}`,
        gross: (
          <NumberFormat
            value={claimTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ),
        fees: `$0.00`,
        net: (
          <NumberFormat
            value={claimTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        )
      },
      {
        transactionType: "Refunds",
        count: `${refundCount}`,
        gross: (
          <NumberFormat
            value={refundTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={refundTotal ? "-$" : "$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ),
        fees: `$0.00`,
        net: (
          <NumberFormat
            value={refundTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={refundTotal ? "-$" : "$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        )
      },
      {
        transactionType: "Adjustments",
        count: `${feeCount}`,
        gross: (
          <NumberFormat
            value={feeTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ),
        fees: `$0.00`,
        net: (
          <NumberFormat
            value={feeTotal}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        )
      },
      {
        transactionType: "Payout Total",
        count: "",
        gross: "",
        fees: "",
        net: (
          <NumberFormat
            value={
              chargeTotal - chargeFeeTotal - refundTotal + claimTotal + feeTotal
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        )
      }
    ];
    if (feeCount === 0) rows.splice(3, 1);
    return rows;
  };

  const payoutDate =
    loading || !data
      ? "..."
      : moment
          .unix(data?.viewTransactions?.[0]?.transferDate)
          .format("MMM DD, YYYY");

  const columnsToShow = matches ? mobileColumns : columns;
  return (
    <Paper className={classes.paper} elevation={0}>
      <TableHead tableName="Summary" tableSubtext={payoutDate} hideSearch />
      {loading ? (
        <SummarySkeleton columns={columnsToShow.length} />
      ) : (
        <Table
          columns={columnsToShow}
          data={gatherTableData()}
          noDataCellLabel="-"
          tableGrows
          disableRowPointer
          tableShrinks
          disableHover
          loading={loading}
        />
      )}
    </Paper>
  );
};
