import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  topBar: {
    height: theme.spacing(8)
  },
  root: {
    top: ({ isStatic }) => isStatic && theme.spacing(0),
    position: ({ isStatic }) => isStatic && "fixed",
    width: ({ isStatic }) => isStatic && "inherit",
    zIndex: ({ isStatic }) => isStatic && "1000",

    background: props =>
      props.primaryTheme
        ? theme.palette.primary[800]
        : theme.palette.background.paper,
    boxShadow: props => (props.border ? null : theme.shadows[4]),
    borderBottom: props =>
      props.border ? `1px solid ${theme.palette.divider}` : null
  },
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: props =>
      props.primaryTheme ? theme.spacing(1, 2, 1, 0) : theme.spacing(1, 2)
  },
  title: {
    marginLeft: theme.spacing(3),
    // color: props => (props.primaryTheme ? theme.palette.common.white : null)
    color: props =>
      props.primaryTheme
        ? theme.palette.common.white
        : theme.palette.common.black
  }
}));

export const DialogAppBar = props => {
  const {
    icon,
    title,
    border,
    primaryTheme,
    helpIcon,
    isStatic,
    ...rest
  } = props;
  const classes = useStyles({ border, primaryTheme, isStatic, ...rest });

  return (
    <>
      {isStatic && <div className={classes.topBar} />}
      <div className={classes.root}>
        <div className={classes.inner}>
          {icon}
          <Typography component="span" variant="h6" className={classes.title}>
            {title}
          </Typography>
          {helpIcon && helpIcon}
        </div>
      </div>
    </>
  );
};

DialogAppBar.propTypes = {
  icon: PropTypes.node,
  isStatic: PropTypes.bool,
  title: PropTypes.string.isRequired,
  border: PropTypes.bool,
  primaryTheme: PropTypes.bool,
  helpIcon: PropTypes.node
};

DialogAppBar.defaultProps = {
  border: false,
  isStatic: false
};
