import { useCallback, useContext } from "react";
import { useQuery } from "@apollo/client";
import get from "lodash/get";

import { RentalStatusEnum } from "Enums/StateEnums";
import { RENTAL_AGGREGATE_QUERY } from "Queries/Rentals/RentalQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const useRentalStatusAggregate = (fetchPolicy = "network-only") => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const {
    data: activeData,
    loading: activeLoading,
    refetch: activeRefetch
  } = useQuery(RENTAL_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: RentalStatusEnum.active
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy
  });

  const {
    data: pendingInsuranceData,
    loading: pendingInsuranceLoading,
    refetch: pendingInsuranceRefetch
  } = useQuery(RENTAL_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: RentalStatusEnum.pendingInsurance
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy
  });

  const {
    data: pendingPickupData,
    loading: pendingPickupLoading,
    refetch: pendingPickupRefetch
  } = useQuery(RENTAL_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: RentalStatusEnum.pendingPickup
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy
  });

  const {
    data: lateData,
    loading: lateLoading,
    refetch: lateRefetch
  } = useQuery(RENTAL_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: RentalStatusEnum.late
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy
  });

  const refetch = useCallback(
    () =>
      Promise.all([
        activeRefetch(),
        pendingInsuranceRefetch(),
        pendingPickupRefetch(),
        lateRefetch()
      ]),
    [activeRefetch, pendingInsuranceRefetch, pendingPickupRefetch, lateRefetch]
  );

  return {
    loading:
      lateLoading ||
      pendingPickupLoading ||
      pendingInsuranceLoading ||
      activeLoading,
    refetch,
    activeAggregate: get(
      activeData,
      "viewer.me.owner.rentalsConnection.aggregate.count",
      0
    ),
    pendingInsuranceAggregate: get(
      pendingInsuranceData,
      "viewer.me.owner.rentalsConnection.aggregate.count",
      0
    ),
    pendingPickupAggregate: get(
      pendingPickupData,
      "viewer.me.owner.rentalsConnection.aggregate.count",
      0
    ),
    lateAggregate: get(
      lateData,
      "viewer.me.owner.rentalsConnection.aggregate.count",
      0
    )
  };
};
