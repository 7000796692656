import { exposeMetrics, PropTypes } from "react-metrics";
import { compose, withProps, getContext } from "recompose";

import { MetricsTracks } from "Analytics/deprecated/MetricsTracks";

export default compose(
  exposeMetrics,
  getContext({
    metrics: PropTypes.metrics
  }),
  withProps(() => ({
    metricsEvents: MetricsTracks.MetricsEvents,
    metricsCategories: MetricsTracks.MetricsCategories
  }))
);
