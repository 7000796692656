/* eslint-disable no-throw-literal*/
import React, { useState, useEffect, useContext, useMemo } from "react";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import get from "lodash/get";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";

import {
  dollarsToCents,
  calculateWeeklyPriceInCentsWithDiscount,
  calculateMonthlyPriceInCentsWithDiscount
} from "Utils/Calculations";
import { OWNER_LIST_CAR } from "Mutations/Owner/OwnerMutations";
import { RouteEnum } from "Enums/RouteEnum";
import { FlowStepper } from "Components/Flows/FlowStepper";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { Eligibility } from "./Eligibility/Eligibility";
import { Rooftop } from "./Rooftop/Rooftop";
import { Details } from "./Details/Details";
import { Documents } from "./Documents/Documents";
import { Location } from "./Location/Location";
import { Protection } from "./ProtectionPlan/Protection";
import { Price } from "./Price/Price";
import { Photos } from "./Photos/Photos";
import { HCBusinessHeader } from "Components/Header/HCBusinessHeader";
import { ActionDialog } from "Components/Dialog/ActionDialog";
import withMetricsProp from "Analytics/hocs/withMetricsProp";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { checkAllPermissions } from "Utils/RooftopUtils";
import { CarsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { Description } from "./Description/Description";
import {
  resetFormState as resetFormStateAction,
  clearStoredFormValues as clearStoredFormValuesAction,
  getStoredFormValues as getStoredFormValuesAction,
  setStoredFormValues as setStoredFormValuesAction,
  storeFormValues as storeFormValuesAction
} from "Redux/Listing/ActionCreators";

const getCurrentComponent = step => {
  switch (step) {
    case 0:
      return <Eligibility />;
    case 1:
      return <Details />;
    case 2:
      return <Photos />;
    case 3:
      return <Description />;
    case 4:
      return <Location />;
    case 5:
      return <Protection />;
    case 6:
      return <Price />;
    case 7:
      return <Documents />;
    default:
      return <Eligibility />;
  }
};

const getCurrentComponentHC4B = step => {
  switch (step) {
    case 0:
      return <Rooftop listingType="Manual" />;
    case 1:
      return <Eligibility />;
    case 2:
      return <Details />;
    case 3:
      return <Protection />;
    case 4:
      return <Price />;
    case 5:
      return <Photos />;
    case 6:
      return <Documents />;
    default:
      return <Rooftop />;
  }
};

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText
  }
}));

export const StepperFunctions = React.createContext(null);

export const singleUserSteps = [
  "Eligibility",
  "Details",
  "Photos",
  "Description",
  "Location",
  "Protection Plan",
  "Price",
  "Documents"
];

export const ManualContainer = compose(
  withMetricsProp,
  connect(
    state => ({
      storedFormValues: state.fleet.listing.formValues,
      formStep: state.fleet.listing.formStep,
      storeFormError: state.fleet.listing.error,
      storeFormSuccess: state.fleet.listing.success
    }),
    dispatch => ({
      storeFormValues: (userId, step) =>
        dispatch(storeFormValuesAction(userId, step)),
      clearStoredFormValues: userId =>
        dispatch(clearStoredFormValuesAction(userId)),
      resetFormState: () => dispatch(resetFormStateAction()),
      getStoredFormValues: userId =>
        dispatch(getStoredFormValuesAction(userId)),
      setStoredFormValues: () => dispatch(setStoredFormValuesAction())
    })
  )
)(
  ({
    formStep,
    storeFormValues,
    storeFormError,
    storeFormSuccess,
    clearStoredFormValues,
    resetFormState,
    getStoredFormValues,
    setStoredFormValues,
    storedFormValues
  }) => {
    const history = useHistory();
    const classes = useStyles();
    const [step, setStep] = useState(0);
    const [dialogOpen, setDialogOpened] = useState(false);
    const { scopes, currentRooftopClient, currentRooftopToken } = useContext(
      ClientFactoryContext
    );
    const [listCar] = useMutation(OWNER_LIST_CAR, {
      client: currentRooftopClient
    });
    const [steps, setSteps] = useState(singleUserSteps);
    const [draftDialogOpen, setDraftDialogOpen] = useState(true);

    const { data } = useQuery(CURRENT_USER_QUERY, {
      client: currentRooftopClient
    });
    const { enqueueSnackbar } = useSnackbar();

    const isRooftop = checkAllPermissions(
      [CarsDomainPermissionPaths.manualRooftopSelect],
      scopes
    );

    const handleSaveDraft = () => {
      if (isRooftop) return;
      storeFormValues(userId, step);
    };

    const handleDialogOpen = () => setDialogOpened(true);
    const handleDialogClose = () => setDialogOpened(false);
    const handleDialogExit = () => {
      if (!isRooftop) {
        handleSaveDraft();
      }
      setDialogOpened(false);
    };
    const handleBackToDashboard = () => history.goBack();

    useEffect(() => {
      if (isRooftop) {
        setSteps([
          "Rooftop",
          "Eligibility",
          "Details",
          "Protection Plan",
          "Pricing",
          "Car Images",
          "Documents"
        ]);
      }
    }, [scopes]);

    const userId = useMemo(() => data?.viewer?.me?.owner?.id, [data]);

    useEffect(() => {
      if (formStep >= 0) {
        setStep(formStep);
      }
    }, [formStep]);

    useEffect(async () => {
      if (!userId) return;
      await getStoredFormValues(userId);
    }, [userId]);

    useEffect(() => {
      if (storeFormError) {
        enqueueSnackbar(storeFormError, {
          variant: "error",
          onExited: resetFormState
        });
      }
    }, [storeFormError]);

    useEffect(() => {
      if (storeFormSuccess) {
        enqueueSnackbar("Your changes have been auto-saved", {
          variant: "success",
          onExited: resetFormState
        });
      }
    }, [storeFormSuccess]);

    const nextStep = () => {
      if (step < steps.length) {
        if (step > 0) handleSaveDraft();
        window.scrollTo(0, 0);
        setStep(prevStep => prevStep + 1);
      }
    };

    const handleBackToDashboardClick = () => {
      if (step === 0) {
        history.goBack();
      } else {
        handleDialogOpen();
      }
    };

    const previousStep = () =>
      step === 0 ? history.goBack() : setStep(prevStep => prevStep - 1);

    const onSubmit = async values => {
      const dailyPriceInCents = dollarsToCents(parseFloat(values.daily_rate));
      const registration = values.registrationDisabled
        ? {}
        : {
            registrationExpirationAt: moment(values.registrationExpirationAt),
            registrationFile: values.registrationFile
          };
      const insurance = values.insuranceDisabled
        ? {}
        : {
            insuranceExpirationAt: moment(values.insuranceExpirationAt),
            personalInsuranceFile: values.personalInsuranceFile
          };
      const inspection = values.inspectionDisabled
        ? {}
        : {
            inspectionExpirationAt: moment(values.inspectionExpirationAt),
            uberLyftInspectionFile: values.uberLyftInspectionFile
          };

      try {
        let snowplowLabel = AnalyticsEvents.label.owner.carListed;
        let snowplowLabelSuccess = AnalyticsEvents.label.owner.carListedSuccess;
        let snowplowOwnerID = get(data, "viewer.me.owner.id");
        let snowplowPropertyKey = "ownerId";
        if (isRooftop) {
          snowplowLabel = AnalyticsEvents.label.owner.carListedHC4B;
          snowplowLabelSuccess =
            AnalyticsEvents.label.owner.carListedHC4BSuccess;
          snowplowOwnerID = get(jwtDecode(currentRooftopToken), "id");
          snowplowPropertyKey = "rooftopId";
        }
        analytics.track(snowplowLabel, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.buttonClicked,
          label: snowplowLabel,
          property: JSON.stringify({
            [snowplowPropertyKey]: snowplowOwnerID
          }),
          value: "",
          context: ""
        });
        const resp = await listCar({
          variables: {
            input: {
              city: values.address.city,
              state: values.address.state,
              street: values.address.street,
              zip: values.address.zip,
              pickupLat: values.address.lat,
              pickupLng: values.address.lng,
              vin: values.vin,
              make: values.make,
              model: values.model,
              year: values.year,
              color: values.color.value,
              mileage: values.mileage,
              licensePlate: values.licensePlate,
              description: values.description,
              dailyPriceInCents: dailyPriceInCents,
              weeklyPriceInCents: calculateWeeklyPriceInCentsWithDiscount(
                dailyPriceInCents,
                parseFloat(values.weekly_discount)
              ),
              monthlyPriceInCents: calculateMonthlyPriceInCentsWithDiscount(
                dailyPriceInCents,
                parseFloat(values.monthly_discount)
              ),
              maxDailyMiles: values.maxDailyMiles,
              photos: values.photos,
              defaultProtectionPlan: values.defaultProtectionPlan,
              ...registration,
              ...insurance,
              ...inspection
            }
          }
        });

        if (
          resp &&
          resp.data &&
          resp.data.listCar &&
          resp.data.listCar.success
        ) {
          analytics.track(AnalyticsEvents.label.owner.ownerListCar, {
            category: AnalyticsEvents.category.userInteraction,
            action: AnalyticsEvents.action.submission,
            label: AnalyticsEvents.label.owner.ownerListCar,
            property: JSON.stringify({
              category: "OWNER"
            }),
            value: "",
            context: {
              car: get(resp, "data.listCar.car", {}),
              user: get(data, "viewer.me", {})
            }
          });
          history.push(RouteEnum.listingsProgress, { listedCar: true });
          analytics.track(snowplowLabelSuccess, {
            category: AnalyticsEvents.category.userInteraction,
            action: AnalyticsEvents.action.webConversion,
            label: snowplowLabelSuccess,
            property: JSON.stringify({
              [snowplowPropertyKey]: snowplowOwnerID
            }),
            value: "",
            context: ""
          });
          await clearStoredFormValues(userId);
        } else {
          const message = get(resp, "data.listCar.message", null);
          const code = get(resp, "data.listCar.code", null);
          analytics.track(AnalyticsEvents.label.owner.ownerListCarError, {
            category: AnalyticsEvents.category.userInteraction,
            action: AnalyticsEvents.action.errorShown,
            label: AnalyticsEvents.label.owner.ownerListCarError,
            property: JSON.stringify({
              error: message,
              category: "OWNER"
            }),
            value: "",
            context: {
              car: get(resp, "data.listCar.car", {}),
              user: get(data, "viewer.me", {})
            }
          });
          throw {
            msg: message,
            code: code
          };
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(
          e.msg ? e.msg : "An error has occurred with your submission",
          {
            variant: "error"
          }
        );
      }
    };

    const handleRestoreDraft = () => {
      setStoredFormValues();
      setDraftDialogOpen(false);
    };

    const handleClearDraft = async () => {
      await clearStoredFormValues(userId);
      setDraftDialogOpen(false);
    };

    const shouldOpenDraftDialog = draftDialogOpen && !!storedFormValues;

    return (
      <>
        <ActionDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          title="Are you sure you want to exit?"
          content="If you decide to exit, your progress can be accessed again on the List a Car form."
          action={handleDialogExit}
          actionLabel="Cancel"
          cancel={handleBackToDashboard}
          cancelLabel={isRooftop ? "Exit" : "Save and Exit"}
        />
        <ActionDialog
          open={shouldOpenDraftDialog}
          onClose={handleRestoreDraft}
          title="You have work in progress saved"
          content="Do you want to restore your progress?"
          action={handleRestoreDraft}
          actionLabel="Yes"
          cancel={handleClearDraft}
          cancelLabel="No"
        />
        <HCBusinessHeader>
          {!isRooftop && (
            <Button
              data-testid="save-car-listing"
              onClick={handleSaveDraft}
              className={classes.button}
            >
              Save
            </Button>
          )}
          <Button
            data-testid="exit-car-listing"
            onClick={handleBackToDashboardClick}
            className={classes.button}
          >
            Exit
          </Button>
        </HCBusinessHeader>
        <StepperFunctions.Provider value={{ nextStep, previousStep, onSubmit }}>
          <DashboardLayout fixed hasAppBar>
            <FlowStepper steps={steps} header="Create a Listing" step={step} />
            {isRooftop
              ? getCurrentComponentHC4B(step)
              : getCurrentComponent(step)}
          </DashboardLayout>
        </StepperFunctions.Provider>
      </>
    );
  }
);

ManualContainer.propTypes = {
  metrics: PropTypes.any,
  metricsEvents: PropTypes.object,
  metricsCategories: PropTypes.object
};
