import React, { Fragment, useContext, useEffect, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import find from "lodash/find";
import moment from "moment";
import classNames from "classnames";
import { useQuery } from "@apollo/client";

import { MUTUAL_RENTALS_MULTIPLE } from "Queries/Rentals/RentalQueries";
import Badge from "@mui/material/Badge";
import EmptyPlaceholder from "../../Financials/Tables/EmptyPlaceholder";
import { Table } from "Components/Tables/Table";
import { TableHead } from "Components/Tables/TableHead";
import { TableFooter } from "Components/Tables/TableFooter";
import { AvatarWithName } from "Components/Avatar/AvatarWithName";
import { getChannelRentalInfoFromArray } from "Components/Inbox/InboxUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { truncateData } from "Utils/Helpers";
import { InboxChannel } from "Components/Inbox/InboxChannel";
import {
  selectChannel,
  updateInboxSearch
} from "Redux/SendBird/ActionCreators";
import { filterChannels } from "Redux/SendBird/Selectors";
import { RouteEnum } from "Enums/RouteEnum";
import { parseSendbirdChannelData } from "Fleet/Dashboard/DashboardViews/Inbox/helpers";
import { inboxRentalStatusEnum } from "Enums/InboxRentalStatusEnum";
import { addChannelRentalStatus } from "Redux/SendBird/ActionCreators";
import FiltersBar from "Components/Inbox/Filters/FiltersBar";
import Filters from "./inboxAll/Filters";
import { getNormalizedRentalStatus } from "Components/Inbox/utils/filterUtils";
import optimizeURL from "Driver/utils/cloudImage";

const useStyles = makeStyles(theme => ({
  unreadMessageRow: {
    backgroundColor: "#F5F5F5",
    "&:hover": {
      backgroundColor: "#FFF"
    }
  },
  unreadBadge: {
    position: "absolute",
    left: "-24px",
    top: "50%"
  },
  rentalStatusContainer: {
    alignItems: "center",
    display: "flex",
    width: "100%"
  },
  rentalStatusCircle: {
    borderRadius: "100%",
    height: "8px",
    marginRight: "12px",
    width: "8px"
  },
  filtersBar: {
    marginTop: theme.spacing(1)
  },
  INQUIRY: { backgroundColor: inboxRentalStatusEnum.INQUIRY.backgroundColor },
  APPLIED_NOT_VERIFIED: {
    backgroundColor: inboxRentalStatusEnum.APPLIED_NOT_VERIFIED.backgroundColor
  },
  APPLIED: { backgroundColor: inboxRentalStatusEnum.APPLIED.backgroundColor },
  PENDING_INSURANCE: {
    backgroundColor: inboxRentalStatusEnum.PENDING_INSURANCE.backgroundColor
  },
  PENDING_PICKUP: {
    backgroundColor: inboxRentalStatusEnum.PENDING_PICKUP.backgroundColor
  },
  ACTIVE: { backgroundColor: inboxRentalStatusEnum.ACTIVE.backgroundColor },
  LATE: { backgroundColor: inboxRentalStatusEnum.LATE.backgroundColor },
  COMPLETED: {
    backgroundColor: inboxRentalStatusEnum.COMPLETED.backgroundColor
  },
  CANCELLED: {
    backgroundColor: inboxRentalStatusEnum.CANCELLED.backgroundColor
  },
  APPLICATION_REJECTED: {
    backgroundColor: inboxRentalStatusEnum.APPLICATION_REJECTED.backgroundColor
  },
  APPLICATION_AUTO_REJECTED: {
    backgroundColor:
      inboxRentalStatusEnum.APPLICATION_AUTO_REJECTED.backgroundColor
  },
  LOADING: { backgroundColor: inboxRentalStatusEnum.LOADING.backgroundColor }
}));

export const InboxAll = connect(
  state => {
    return {
      channels: get(state, "fleet.sendbird.channels", []),
      inboxSearch: get(state, "fleet.sendbird.inboxSearch", ""),
      loading: get(state, "fleet.sendbird.loading", false),
      user: get(state, "fleet.sendbird.user", {}),
      ...filterChannels(state)
    };
  },
  { selectChannel, updateInboxSearch, addChannelRentalStatus }
)(
  ({
    channels,
    filteredChannels,
    updateInboxSearch,
    inboxSearch,
    selectChannel,
    loading,
    user,
    tableState,
    addChannelRentalStatus
  }) => {
    const {
      first,
      page,
      handleFirstChange,
      nextPage,
      previousPage
    } = tableState;
    const hasNextPage = filteredChannels.length > (page + 1) * first;
    const dataSliceFrom = page * first;
    const dataSliceTo = (page + 1) * first;
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const matchesMd = useMediaQuery(theme.breakpoints.down("lg"));
    const { currentRooftopClient } = useContext(ClientFactoryContext);

    const handleRowClick = channel => {
      selectChannel(channel);
      history.push(RouteEnum.inboxMine);
    };

    const driverIds = useMemo(
      () =>
        channels
          .map(channel => getDriverId(channel, user.userId))
          .filter(id => !!id),
      [channels, user.userId]
    );

    const { data: rentalData } = useQuery(MUTUAL_RENTALS_MULTIPLE, {
      variables: { driverIds },
      client: currentRooftopClient,
      fetchPolicy: "network-only",
      skip: !driverIds
    });

    const allChannelRentalInfo = useMemo(
      () =>
        driverIds?.map(driverId =>
          getChannelRentalInfoFromArray(driverId, rentalData)
        ),
      [driverIds, rentalData]
    );

    useEffect(() => {
      channels.forEach(channel => {
        const driverId = getDriverId(channel, user.userId);
        const rentalInfo = getRentalInfo(allChannelRentalInfo, driverId);
        if (rentalInfo && rentalInfo.status !== "LOADING")
          addChannelRentalStatus(
            channel.url,
            getNormalizedRentalStatus(rentalInfo.status)
          );
      });
    }, [allChannelRentalInfo, channels, addChannelRentalStatus, user.userId]);

    const renderMobileTable = () => {
      if (!filteredChannels?.length) {
        return (
          <Grid item className={classes.mobileEmptyRow} xs={12}>
            <EmptyPlaceholder
              header="No Messages"
              subHeader="When your rooftop receives a message from a Driver, it will appear here."
            />
          </Grid>
        );
      }
      return filteredChannels.map(channel => {
        const driver = find(
          channel.members,
          member => member.userId !== user.userId
        );
        const lastMessage = get(channel, "lastMessage", {})
          ? channel.lastMessage
          : {};
        return driver ? (
          <Fragment key={channel.url}>
            <InboxChannel
              onClick={channel => handleRowClick(channel)}
              avatarSrc={driver.profileUrl}
              alt={`${driver.nickname} Profile`}
              name={driver.nickname}
              driverId={driver.userId}
              text={
                get(parseSendbirdChannelData(channel), "assignedToFirstName")
                  ? `Assigned to: ${upperFirst(
                      get(
                        parseSendbirdChannelData(channel),
                        "assignedToFirstName"
                      )
                    )} ${upperFirst(
                      get(
                        parseSendbirdChannelData(channel),
                        "assignedToLastName"
                      )
                    )}`
                  : "Unassigned"
              }
              channel={channel}
              timestamp={lastMessage.createdAt}
            />
            <Divider />
          </Fragment>
        ) : null;
      });
    };
    return (
      <>
        {matches ? (
          <>
            <div className={classes.filtersBar}>
              <FiltersBar />
            </div>
            {renderMobileTable()}
          </>
        ) : (
          <Paper>
            <TableHead
              tableName="All Messages"
              onSearchChange={e => updateInboxSearch(e.target.value)}
              search={inboxSearch}
              searchPlaceholder="Search by driver"
            />
            <Filters statusClasses={classes} />
            <Table
              onRowClick={data => handleRowClick(data.channel)}
              columns={[
                {
                  title: "",
                  field: "emptyColumn"
                },
                {
                  title: "Date",
                  field: "date"
                },
                {
                  title: "Driver",
                  field: "driver"
                },
                {
                  title: "Message",
                  field: "message"
                },
                {
                  title: "Assigned To",
                  field: "assignedTo"
                },
                {
                  title: "Rental Status",
                  field: "rentalStatus"
                }
              ]}
              loading={loading}
              data={mapChannelsToTableData(
                filteredChannels.slice(dataSliceFrom, dataSliceTo),
                user,
                matchesMd,
                classes,
                allChannelRentalInfo?.length
                  ? allChannelRentalInfo.slice(dataSliceFrom, dataSliceTo)
                  : []
              )}
              noDataComponent={
                <EmptyPlaceholder
                  header="No Messages"
                  subHeader="When your rooftop receives a message from a Driver, it will appear here."
                />
              }
            />
            <TableFooter
              rowsPerPage={first}
              onRowsPerPageChange={handleFirstChange}
              hasNextPage={hasNextPage}
              hasPreviousPage={page > 0}
              onNextPage={nextPage}
              onPreviousPage={previousPage}
              page={page}
            />
          </Paper>
        )}
      </>
    );
  }
);

const renderRentalStatus = (rentalStatusKey = "LOADING", classes) => {
  const rentalStatusColorClass = classes[rentalStatusKey];
  const rentalStatusText = inboxRentalStatusEnum[rentalStatusKey].allInboxText;
  return (
    <div className={classes.rentalStatusContainer}>
      <div
        className={classNames(
          classes.rentalStatusCircle,
          rentalStatusColorClass
        )}
      ></div>
      <span>{rentalStatusText}</span>
    </div>
  );
};

const getDriverId = (channel, userId) =>
  channel.members.find(member => member.userId !== userId)?.userId;

const getRentalInfo = (allChannelRentalInfo, driverId) =>
  allChannelRentalInfo.find(rentalData => rentalData?.driverId === driverId);

const mapChannelsToTableData = (
  channels,
  user,
  matchesMd,
  classes,
  rentalDataArray
) =>
  channels.map(channel => {
    const { unreadMessageCount } = channel;
    const driver = find(
      channel.members,
      member => member.userId !== user.userId
    );
    const lastMessage = get(channel, "lastMessage", {});
    const {
      assignedToFirstName,
      assignedToLastName
    } = parseSendbirdChannelData(channel);
    const driverId = getDriverId(channel, user.userId);
    const channelRentalData = getRentalInfo(rentalDataArray, driverId);
    return {
      channel: channel,
      emptyColumn: " ",
      className: unreadMessageCount > 0 ? classes.unreadMessageRow : "",
      driver: (
        <AvatarWithName
          url={optimizeURL(get(driver, "profileUrl"), "web.inbox.user.avatar")}
          firstName={get(driver, "nickname", "")}
          size="medium"
        />
      ),
      date: lastMessage.createdAt ? (
        <div
          style={{
            position: "relative",
            width: "100%"
          }}
        >
          <Badge
            className={classes.unreadBadge}
            badgeContent={unreadMessageCount}
            color="primary"
          ></Badge>
          <span>{moment(lastMessage.createdAt).format("LL")}</span>
        </div>
      ) : (
        <div>
          <Badge
            className={classes.unreadBadge}
            badgeContent={unreadMessageCount}
            color="primary"
          ></Badge>
          <span></span>
        </div>
      ),
      message: lastMessage.message
        ? truncateData(lastMessage.message, matchesMd ? 40 : 70)
        : "",
      assignedTo: assignedToFirstName
        ? `${upperFirst(assignedToFirstName)} ${upperFirst(assignedToLastName)}`
        : "unassigned",
      rentalStatus: renderRentalStatus(channelRentalData?.status, classes)
    };
  });
