import { useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import { CAR_LISTING_INFO } from "Queries/Car/Car";
import {
  SOFT_DELETE_LISTING,
  SNOOZE_LISTING,
  ACTIVATE_LISTING
} from "Mutations/Car/CarMutations";
import { useListingStatusOptions } from "Components/hooks/useListingStatusOptions";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CarStatusEnum, ListingStatusCopyEnum } from "Enums/StateEnums";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

const listingStatusRequiredFields = ["snoozedReason", "deletedReason"];

const createStatusOptions = car => {
  const liveDescription =
    car?.status === CarStatusEnum.available
      ? "This car is currently active"
      : "Activate this car";
  const snoozedDescription =
    car?.status === CarStatusEnum.removed
      ? "This car is temporarily deactivated"
      : "Deactivate your car temporarily";
  return [
    {
      label: "Live",
      value: ListingStatusCopyEnum.live,
      description: liveDescription
    },
    {
      label: "Snooze",
      value: ListingStatusCopyEnum.snoozed,
      description: snoozedDescription
    },
    {
      label: "Delete Listing",
      value: ListingStatusCopyEnum.deleted,
      description: "Remove your car permanently"
    }
  ];
};

const handleSnackbarMessage = (info, enqueueSnackbar) => {
  if (!info.success) {
    enqueueSnackbar(info.message, {
      variant: "error"
    });
  } else {
    enqueueSnackbar("Car status changed", {
      variant: "success"
    });
  }
};

export const useListingStatusEditController = (id, onClose) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [softDeleteListing] = useMutation(SOFT_DELETE_LISTING, {
    client: currentRooftopClient
  });
  const [snoozeListing] = useMutation(SNOOZE_LISTING, {
    client: currentRooftopClient
  });
  const [activateListing] = useMutation(ACTIVATE_LISTING, {
    client: currentRooftopClient
  });

  const { data: carData, loading: carLoading } = useQuery(CAR_LISTING_INFO, {
    variables: {
      id
    },
    fetchPolicy: "network-only",
    client: currentRooftopClient
  });

  const car = carData?.car;
  const listingStatusOptions = useListingStatusOptions(
    car?.status,
    car?.verification
  );
  const statusOptionsLabels = createStatusOptions(car);
  const copy = listingStatusOptions?.copy;

  const onSubmit = async values => {
    try {
      const carStatus = values?.carStatus;
      const unhappyReason = values?.unhappyReason;

      if (carStatus === ListingStatusCopyEnum.deleted) {
        const reason = values?.deletedReason;

        const resp = await softDeleteListing({
          variables: {
            carId: id
          }
        });
        const info = resp?.data?.softDeleteListing;
        handleSnackbarMessage(info, enqueueSnackbar);
        analytics.track(AnalyticsEvents.label.owner.carDeleted, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.submission,
          label: AnalyticsEvents.label.owner.carDeleted,
          carId: id,
          reason,
          unhappyReason,
          property: JSON.stringify({
            carId: id,
            reason,
            unhappyReason
          }),
          value: "",
          context: ""
        });
      }

      if (carStatus === ListingStatusCopyEnum.snoozed) {
        const reason = values?.snoozedReason;

        const resp = await snoozeListing({
          variables: {
            carId: id
          }
        });
        const info = resp?.data?.snoozeListing;
        handleSnackbarMessage(info, enqueueSnackbar);
        analytics.track(AnalyticsEvents.label.owner.carSnoozed, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.submission,
          label: AnalyticsEvents.label.owner.carSnoozed,
          carId: id,
          reason,
          unhappyReason,
          property: JSON.stringify({
            carId: id,
            reason,
            unhappyReason
          }),
          value: "",
          context: ""
        });
      }

      if (carStatus === ListingStatusCopyEnum.live) {
        const resp = await activateListing({
          variables: {
            carId: id
          }
        });
        const info = resp?.data?.activateListing;
        handleSnackbarMessage(info, enqueueSnackbar);
      }

      onClose();
    } catch (e) {
      enqueueSnackbar("Sorry, something went wrong. Please try again", {
        variant: "error"
      });
      console.error(e);
    }
  };

  const validate = values => {
    const errors = {};

    listingStatusRequiredFields.map(field => {
      errors[field] = !values[field] ? "Required" : undefined;
      return errors;
    });

    return errors;
  };

  return {
    car,
    onSubmit,
    validate,
    initialValues: {
      carStatus: copy
    },
    carLoading,
    statusOptionsLabels
  };
};
