import React, { useEffect, useState } from "react";

import config from "Config/config";

export const StripeContext = React.createContext();

export const Stripe = ({ children }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (!stripe) {
      setStripe(window.Stripe(config.stripe.pkey));
    }
  }, [stripe]);

  return (
    <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>
  );
};
