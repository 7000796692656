import Typography from "@mui/material/Typography";
import { FileViewer } from "Components/Images/FileViewer";
import React from "react";
import carExample1 from "Assets/images/car-examples/car-example-1.jpeg";
import carExample2 from "Assets/images/car-examples/car-example-2.jpeg";
import carExample3 from "Assets/images/car-examples/car-example-3.jpeg";
import carExample4 from "Assets/images/car-examples/car-example-4.jpeg";
import carExample5 from "Assets/images/car-examples/car-example-5.jpeg";
import carExample6 from "Assets/images/car-examples/car-example-6.jpeg";
import carExample7 from "Assets/images/car-examples/car-example-7.jpeg";
import carExample8 from "Assets/images/car-examples/car-example-8.jpeg";
import carExample9 from "Assets/images/car-examples/car-example-9.jpeg";
import carExample10 from "Assets/images/car-examples/car-example-10.jpeg";

const photos = [
  {
    type: "img",
    src: carExample1
  },
  {
    type: "img",
    src: carExample2
  },
  {
    type: "img",
    src: carExample3
  },
  {
    type: "img",
    src: carExample4
  },
  {
    type: "img",
    src: carExample5
  },
  {
    type: "img",
    src: carExample6
  },
  {
    type: "img",
    src: carExample7
  },
  {
    type: "img",
    src: carExample8
  },
  {
    type: "img",
    src: carExample9
  },
  {
    type: "img",
    src: carExample10
  }
];

const CarPhotosExample = () => {
  return (
    <>
      <Typography component="p" variant="subtitle1">
        High quality photos will increase the chances that driver will book your
        car. Take multiple angles from the exterior and interior of the car in
        clear and well-lit conditions. Use the example below as a guide.
      </Typography>
      <FileViewer files={photos} />
    </>
  );
};

export default CarPhotosExample;
