import React, { useContext, useMemo } from "react";
import { Route, useHistory } from "react-router-dom";

import { ActionDialog } from "Components/Dialog/ActionDialog";
import { useDialog } from "Components/Dialog/hooks/useDialog";
import { checkAllPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import Link from "@mui/material/Link";

export const RouteWithPermission = props => {
  const history = useHistory();
  const {
    requiredPermissions = [],
    deniedPermissionComponent,
    actionRoute,
    ...rest
  } = props;
  const { dialogOpen, handleDialogClose } = useDialog(true);
  const { scopes } = useContext(ClientFactoryContext);
  const hasPermission = checkAllPermissions(requiredPermissions, scopes);

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  const closeDialog = () => {
    handleDialogClose();
    history.replace(actionRoute);
  };

  return hasPermission ? (
    <Route {...rest} />
  ) : deniedPermissionComponent ? (
    deniedPermissionComponent
  ) : (
    <ActionDialog
      open={dialogOpen}
      onClose={closeDialog}
      title="Permissions Error"
      maxWidth="sm"
      action={closeDialog}
      actionLabel="Done"
      content={
        <>
          <div>
            You’ve reached a page that either does not exist or cannot be
            displayed, based on your account permissions. Please contact your
            account owner to update your role.
          </div>
          <br />
          <div>
            Note, some features from HyreCar for Business are not available in
            your personal account (ie. rooftops, team settings).
          </div>
          <br />
          <div>
            If you believe you are receiving this message in error, please
            contact HyreCar Support at{" "}
            <Link underline="none" href={`tel:1-${customerServicePhoneNumber}`}>
              {customerServicePhoneNumber}
            </Link>
            .
          </div>
        </>
      }
    />
  );
};
