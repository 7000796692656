import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  panelDetails: {
    flexWrap: "wrap"
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: theme.spacing(2)
  },
  loading: {
    margin: "0 auto"
  }
}));

export const DrawerExpansionPanel = ({
  defaultExpanded = false,
  children,
  title,
  submitting,
  onSubmit,
  loading,
  awaitingAsyncValues,
  onCancel
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);

  const toggleExpanded = () => setExpanded(prev => !prev);

  return (
    <Accordion defaultExpanded={defaultExpanded} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={toggleExpanded}
      >
        <Typography component="h3" display="block" variant="subtitle1">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetails}>
        {loading ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <>
            {children}
            <div className={classes.buttons}>
              <Button
                onClick={() => {
                  onCancel ? onCancel() : toggleExpanded();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                disabled={submitting || awaitingAsyncValues}
                color="primary"
              >
                {submitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

DrawerExpansionPanel.propTypes = {
  defaultExpanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  awaitingAsyncValues: PropTypes.bool
};
