import { ProtectionPlanEnum } from "Enums/StateEnums";
import {
  CLEAR_STORED_FORM_VALUES,
  CLEAR_FORM_STATUS,
  GET_STORED_FORM_VALUES,
  STORE_FORM_VALUES,
  STORE_FORM_VALUES_ERROR,
  SET_STORED_FORM_VALUES
} from "./Actions";

const initialState = {
  formValues: null,
  formStep: 0,
  restoredFormStep: 0,
  error: null,
  success: null,
  initialFormValues: {
    defaultProtectionPlan: ProtectionPlanEnum.premium_plus,
    daily_rate: null,
    weekly_discount: 5,
    monthly_discount: 10,
    maxDailyMiles: "1000",
    photos: []
  }
};

const listingCarReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_FORM_VALUES: {
      return {
        ...state,
        error: false,
        success: true
      };
    }
    case GET_STORED_FORM_VALUES: {
      return {
        ...state,
        formValues: action.payload.formValues,
        restoredFormStep: action.payload.restoredFormStep
      };
    }
    case SET_STORED_FORM_VALUES: {
      return {
        ...state,
        initialFormValues: state.formValues,
        formStep: state.restoredFormStep
      };
    }
    case CLEAR_STORED_FORM_VALUES: {
      return initialState;
    }
    case CLEAR_FORM_STATUS: {
      return {
        ...state,
        error: initialState.error,
        success: initialState.success
      };
    }
    case STORE_FORM_VALUES_ERROR: {
      return {
        ...state,
        error: action.error,
        success: false
      };
    }
    default:
      return state;
  }
};

export default listingCarReducer;
