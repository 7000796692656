import { useState } from "react";
import uniqBy from "lodash/uniqBy";
import slice from "lodash/slice";

export const useTableState = ({
  initialFirst = null,
  initialOrderBy = null,
  initialColumns = [],
  initialFilters = []
}) => {
  const [search, setSearch] = useState("");
  const [first, setFirst] = useState(initialFirst);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [page, setPage] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState([...initialFilters]);
  const [columns, setColumns] = useState(initialColumns ? initialColumns : []);

  const handleSearchChange = e => setSearch(e.target.value);
  const handleFirstChange = e => {
    setFirst(e.target.value);
    setPage(0);
  };
  const handleOrderByChange = newOrderBy => setOrderBy(newOrderBy);

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    if (page !== 0) setPage(page - 1);
  };

  const handleSelectFilter = filter => {
    const newSelectedFilters = uniqBy([...selectedFilters, filter], "value");
    setSelectedFilters(newSelectedFilters);
    setPage(0);
  };

  const handleDeleteFilter = (filter, key) => {
    const newSelectedFilters = [
      ...slice(selectedFilters, 0, key),
      ...slice(selectedFilters, key + 1, selectedFilters.length)
    ];
    setSelectedFilters(newSelectedFilters);
    setPage(0);
  };

  return {
    search,
    first,
    orderBy,
    page,
    setPage,
    selectedFilters,
    columns,
    setColumns,
    handleSearchChange,
    handleFirstChange,
    handleOrderByChange,
    nextPage,
    previousPage,
    handleSelectFilter,
    handleDeleteFilter
  };
};
