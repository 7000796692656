import moment from "moment";

// Get the date filters based on the dropdown selections
export const getDatesFromRange = newFilter => {
  const today = new Date();
  let dateRange = {};
  dateRange.endDate = moment(today).endOf("day");
  switch (newFilter) {
    case "All Time":
      dateRange.startDate = undefined;
      dateRange.endDate = moment(today).endOf("day");
      break;

    case "Today":
      dateRange.startDate = moment(today).startOf("day");
      break;

    case "Yesterday":
      dateRange.startDate = moment(today)
        .subtract(1, "day")
        .startOf("day");
      dateRange.endDate = moment(today)
        .subtract(1, "day")
        .endOf("day");
      break;

    case "Past 7 days":
      dateRange.startDate = moment(today)
        .startOf("day")
        .subtract(7, "day");
      break;

    case "Past 30 days":
      dateRange.startDate = moment(today)
        .startOf("day")
        .subtract(30, "day");
      break;

    case "Past 90 days":
      dateRange.startDate = moment(today)
        .startOf("day")
        .subtract(90, "day");
      break;

    case "Week to Date":
      dateRange.startDate = moment(today).startOf("week");
      break;

    case "Month to Date":
      dateRange.startDate = moment(today).startOf("month");
      break;

    case "Quarter to Date":
      dateRange.startDate = moment(today).startOf("quarter");
      break;

    default:
      dateRange.startDate = moment(today).subtract(7, "day");
  }
  return dateRange;
};

export const createNamedReducer = (name, reducer) => {
  return (state, action) => {
    const { type } = action;
    return type === `UPDATE_FILTER_${name}` ? reducer(state, action) : state;
  };
};
