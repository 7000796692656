import { useContext } from "react";
import get from "lodash/get";
import moment from "moment";
import { TRANSACTIONS_QUERY } from "Queries/Transactions/TransactionsQueries";
import { arrayPermutator } from "Utils/Helpers";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { useQuery } from "@apollo/client";

const dateFormatString = "MM/DD/YY";

export const useTransactionsTable = (search, from, to) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const { data, loading, error } = useQuery(TRANSACTIONS_QUERY, {
    client: currentRooftopClient,
    fetchPolicy: "no-cache",
    context: {
      apiv2: true
    },
    variables: {
      startDate: moment.utc(from).unix(),
      endDate: moment.utc(to).unix()
    }
  });

  return {
    error,
    loading,
    data: filterReducedData(formatTimestamps(data), search)
  };
};

// Format paymentDate/transferDate from unix timestamp to MM/DD/YYYY string.
const formatTimestamps = txns =>
  txns?.viewTransactions?.map(txn => {
    let transaction = { ...txn };
    if (typeof txn.paymentDate === "number")
      transaction.paymentDate = moment
        .unix(txn.paymentDate)
        .format(dateFormatString);
    if (typeof txn.transferDate === "number")
      transaction.transferDate = moment
        .unix(txn.transferDate)
        .format(dateFormatString);
    return transaction;
  }) ?? [];

const mapType = (typeString = "") => {
  const maxStringLength = 20;
  if (typeString) {
    if (typeString?.tooltip) {
      return typeString;
    }
    if (typeString?.includes("Reimbursement (#")) {
      return {
        tooltip: typeString,
        value:
          typeString.substr(0, maxStringLength - 1) +
          (typeString.length > maxStringLength ? "..." : "")
      };
    }
  }
  return typeString;
};

// Filters transactions by the search word.
const filterReducedData = (data, search) => {
  if (search) {
    const filteredData = data.filter(datum => {
      const arr = [get(datum, "description", null)].filter(Boolean);
      if (arr.length > 0) {
        return arrayPermutator(arr).some(permutation =>
          permutation
            .join(" ")
            .toLowerCase()
            .includes(search.toLowerCase())
        );
      } else return false;
    });
    return filteredData;
  }
  return data.map(el => {
    el.rentalPeriodType = mapType(el.rentalPeriodType);
    return el;
  });
};
