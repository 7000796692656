import { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useLocation, useHistory } from "react-router-dom";
import { destroy } from "redux-form";
import get from "lodash/get";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import moment from "moment";
import imageCompression from "browser-image-compression";

import { SUBMIT_DAMAGE_CLAIM } from "Mutations/Claims/ClaimMutations";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { emailRegex } from "Utils/Regex";
import { validateLocation } from "Components/Forms/Location/LocationAutoComplete";
import { RouteEnum } from "Enums/RouteEnum";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { AcceptEnum } from "Enums/AcceptEnum";

export const MAX_LOSS_DESCRIPTION_CHARACTERS = 997;
export const MAX_DAMAGE_DESCRIPTION_CHARACTERS = 250;

export const useClaimsDamageFormController = dispatch => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [submitDamageClaim] = useMutation(SUBMIT_DAMAGE_CLAIM, {
    client: currentRooftopClient
  });
  const location = useLocation();
  const rentalId = get(location, "state.id", "");
  const history = useHistory();
  const response = useQuery(CURRENT_USER_QUERY, {
    client: currentRooftopClient
  });
  const viewer = response?.data?.viewer;

  const initialValues = {
    damagedAreas: [],
    firstName: get(viewer, "me.firstName"),
    lastName: get(viewer, "me.lastName"),
    phone: get(viewer, "me.phone"),
    email: get(viewer, "me.email"),
    rentalId
  };

  const onSubmit = async values => {
    const files = get(values, "photos", []);
    const compressedImages = [];
    for (const file of files) {
      if (file?.type === AcceptEnum.pdf) {
        compressedImages.push(file);
      } else {
        const compressedImage = await imageCompression(file, { maxSizeMB: 1 });
        const transformedFile = new File([compressedImage], file?.name, {
          type: file?.type,
          lastModified: file?.lastModified
        });
        compressedImages.push(transformedFile);
      }
    }

    try {
      const data = {
        rentalId: values.rentalId,
        autoTheft: get(values, "damage.autoTheft", false),
        carAccident: get(values, "damage.carAccident", false),
        carDamage: get(values, "damage.carDamage", false),
        hitAndRun: get(values, "damage.hitAndRun", false),
        exteriorDamage: get(values, "damage.exteriorDamage", false),
        interiorDamage: get(values, "damage.interiorDamage", false),
        mechanicalIssue: get(values, "damage.mechanicalIssues", false),
        isCarDrivable: get(values, "isCarDrivable"),
        isATotalLoss: get(values, "isATotalLoss", undefined),
        totalLossAssessmentScore: get(
          values,
          "totalLossAssessmentScore",
          undefined
        ),
        isVehicleCurrentInStorage: get(
          values,
          "isVehicleCurrentInStorage",
          undefined
        ),
        contactInfo: {
          bestContactTime: get(values, "timeWindow", ""),
          firstName: get(values, "firstName"),
          lastName: get(values, "lastName"),
          email: get(values, "email"),
          phone: get(values, "phone"),
          address: {
            street: get(values, "address.street"),
            city: get(values, "address.city"),
            state: get(values, "address.state"),
            zip: get(values, "address.zip")
          }
        },
        damagedAreas: values.damagedAreas
          ? values.damagedAreas.map(val => val.label)
          : undefined,
        damageDescription: get(values, "damageDescription", " "),
        photos: compressedImages,
        eventLocation: {
          street: get(values, "lossAddress.street"),
          city: get(values, "lossAddress.city"),
          state: get(values, "lossAddress.state"),
          zip: get(values, "lossAddress.zip")
        },
        occurrDate: values.lossDate
          ? moment(values.lossDate).format()
          : undefined,
        occurrTime: values.lossTime
          ? moment(values.lossTime).format()
          : undefined,
        lossDescription: get(values, "lossDescription"),
        rideshareAppOn: get(values, "gigAppOn"),
        bodilyInjuries: get(values, "bodilyInjuries"),
        policeReportFiled: get(values, "policeReportFiled"),
        storageFacilityAddress: values.storageFacilityAddress
          ? {
              address: get(values, "storageFacilityAddress.address", undefined),
              city: get(values, "storageFacilityAddress.city", undefined),
              country: get(values, "storageFacilityAddress.country", undefined),
              lat: get(values, "storageFacilityAddress.lat", undefined),
              lng: get(values, "storageFacilityAddress.lng", undefined),
              state: get(values, "storageFacilityAddress.state", undefined),
              street: get(values, "storageFacilityAddress.street", undefined),
              streetName: get(
                values,
                "storageFacilityAddress.streetName",
                undefined
              ),
              streetNumber: get(
                values,
                "storageFacilityAddress.streetNumber",
                undefined
              ),
              zip: get(values, "storageFacilityAddress.zip", undefined)
            }
          : undefined,
        storageAdditionalInformation: values.storageAdditionalInformation,
        policeReportCaseNumber: values.policeReportCaseNumber,
        policeReportDepartment: values.policeReportDepartment,
        driverServices: values.driverServices,
        otherDriverServices: values.otherDriverServices
      };

      const resp = await submitDamageClaim({
        variables: {
          data: {
            ...omitBy(data, isNil)
          }
        }
      });

      dispatch(destroy("CLAIMS_DAMAGE"));
      history.replace(RouteEnum.claims, {
        ...location.state,
        claimId: get(resp, "data.submitDamageClaim.claim.id"),
        claimType: "damage"
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        e.message
          ? e.message.replace(/graphql error: /gim, "")
          : "Failed to submit your claim.",
        {
          variant: "error"
        }
      );
    }
  };

  const validate = values => {
    const errors = {};

    if (!values.rentalId) errors.rentalId = "Required";
    if (!values.firstName) errors.firstName = "Required";
    if (!values.lastName) errors.lastName = "Required";
    if (!values.lossDescription) errors.lossDescription = "Required";
    if (
      values.lossDescription &&
      values.lossDescription.length > MAX_LOSS_DESCRIPTION_CHARACTERS
    ) {
      const exceedCharacters =
        MAX_LOSS_DESCRIPTION_CHARACTERS - values.lossDescription.length;
      errors.lossDescription = `${exceedCharacters}`;
    }

    if (!values.phone) {
      errors.phone = "Required";
    } else if (values.phone.replace(/\(|\)|-|\s/gi, "").length !== 10) {
      errors.phone = "Please enter a valid phone number.";
    }

    if (!values.damage) {
      errors.damage = "At least one option must be selected.";
    } else if (Object.keys(values.damage).length === 0) {
      errors.damage = "At least one option must be selected.";
    } else if (
      !values.damage.autoTheft &&
      !values.damage.carAccident &&
      !values.damage.carDamage &&
      !values.damage.exteriorDamage &&
      !values.damage.hitAndRun &&
      !values.damage.interiorDamage &&
      !values.damage.mechanicalIssues
    ) {
      errors.damage = "At least one option must be selected.";
    }

    if (!Array.isArray(values.damagedAreas)) {
      errors.damagedAreas = "Required";
    } else if (values.damagedAreas.length === 0) {
      errors.damagedAreas = "Required";
    }

    if (!Array.isArray(values.photos)) {
      errors.photos = "Required";
    } else if (values.photos.length === 0) {
      errors.photos = "Required";
    }

    if (!values.email) {
      errors.email = "Enter a valid email";
    } else {
      if (!emailRegex.test(values.email)) {
        errors.email = "Enter a valid email";
      }
    }

    if (values.lossDate) {
      if (!moment(values.lossDate).isValid()) {
        errors.lossDate = `${moment(values.lossDate).format()}`;
      } else if (!moment(values.lossDate).isBefore(moment())) {
        errors.lossDate = `Date of loss cannot be past today`;
      }
    }

    if (values.lossTime) {
      const hasDate = values?.lossDate;
      const sameDate = moment(values.lossDate).isSame(moment(), "day");
      const timeDiff = moment(values.lossTime).isBefore(moment());

      if (hasDate && sameDate && !timeDiff) {
        errors.lossTime = `Time of loss cannot be past current time`;
      }
    }

    if (
      values.damageDescription &&
      values.damageDescription.length > MAX_DAMAGE_DESCRIPTION_CHARACTERS
    ) {
      const exceedCharacters =
        MAX_DAMAGE_DESCRIPTION_CHARACTERS - values.damageDescription.length;
      errors.damageDescription = `${exceedCharacters}`;
    }

    if (!values.isVehicleCurrentInStorage) {
      errors.isVehicleCurrentInStorage = `Please select "Yes" or "No".`;
    }

    if (!values.isATotalLoss) {
      errors.isATotalLoss = `Please select "Yes" or "No".`;
    }

    if (
      !values.lossAddress ||
      !values.lossAddress.street ||
      !values.lossAddress.city ||
      !values.lossAddress.state ||
      !values.lossAddress.zip
    ) {
      errors.lossAddress = "Please enter a valid address.";
    }

    if (!values.lossDate) {
      errors.lossDate = "Please enter a date.";
    }

    if (!values.lossTime) {
      errors.lossTime = "Please enter a time.";
    }

    const score = parseInt(values.totalLossAssessmentScore);
    if (!new RegExp("^[0-9]*$").test(values.totalLossAssessmentScore)) {
      errors.totalLossAssessmentScore = `Please enter a score between 1 and 400.`;
    } else if (!score) {
      errors.totalLossAssessmentScore = `Please enter a score between 1 and 400.`;
    } else if (score < 0 || score > 400) {
      errors.totalLossAssessmentScore = `Please enter a score between 1 and 400.`;
    }

    if (!values.isVehicleCurrentInStorage) {
      errors.isVehicleCurrentInStorage = `Please select "Yes" or "No".`;
    }

    if (!values.storageFacilityAddress) {
      errors.storageFacilityAddress = "Please enter an address.";
    }

    if (!values.storageAdditionalInformation) {
      errors.storageAdditionalInformation =
        "Please provide additional information.";
    }

    if (!values.lastInspectionDate) {
      errors.lastInspectionDate = "Please select a date.";
    }

    const isFutureDate = moment(values.lastInspectionDate).isAfter(moment());
    if (isFutureDate) {
      errors.lastInspectionDate = "A future date cannot be selected.";
    }

    if (!values.policeReportCaseNumber) {
      errors.policeReportCaseNumber = `Please enter the police report number.`;
    }

    if (!values.policeReportDepartment) {
      errors.policeReportDepartment = `Please enter the police department.`;
    }

    if (!values.driverServices) {
      errors.driverServices = "Please select a driver service.";
    }

    if (values.driverServices === "Other" && !values.otherDriverServices) {
      errors.otherDriverServices = "Please input the driver service.";
    }

    const locationErrors = validateLocation(values, "address");
    Object.assign(errors, { ...locationErrors });

    return errors;
  };

  return {
    onSubmit,
    initialValues,
    validate,
    DamageTypeEnum
  };
};

//This enum is used in the formfields and on submission.
//The two values below are generated by a script.
export const DamageTypeEnum = {
  engine: 0,
  fenderDriverSide: 1,
  fenderPassengerSide: 2,
  frontBumper: 3,
  frontBumperCornerDriverSide: 4,
  frontBumperCornerPassenger: 5,
  frontDoorDriverSide: 6,
  frontDoorPassenger: 7,
  frontWheelDriverSide: 8,
  frontWheelPassengerSide: 9,
  hood: 10,
  mirrorDriverSide: 11,
  mirrorPassengerSide: 12,
  quarterPanelDriverSide: 13,
  quarterPanelPassengerSide: 14,
  rearBumper: 15,
  rearCornerBumperDriverSide: 16,
  rearCornerBumperPassengerSide: 17,
  rearDoorDriverSide: 18,
  rearDoorPassengerSide: 19,
  rockerPanelDriverSide: 20,
  rockerPanelPassengerSide: 21,
  roofRailDriverSide: 22,
  roofRailPassengerSide: 23,
  spoiler: 24,
  tailLight: 25,
  trunk: 26,
  wheel: 27,
  airConditioning: 28,
  armrest: 29,
  carpet: 30,
  centerConsole: 31,
  cigaretteLighter: 32,
  cupHolder: 33,
  dashPanel: 34,
  doorHandles: 35,
  doorLocksAndSwitches: 36,
  gloveBox: 37,
  lights: 38,
  panelsAndMoldings: 39,
  pedal: 40,
  rearViewMirror: 41,
  seats: 42,
  steeringWheel: 43,
  window: 44,
  electronics: 45,
  tires: 46,
  keysAndLocks: 47,
  vandalism: 48
};

export const damageDetailsOptions = [
  { value: DamageTypeEnum.engine, label: "Engine", group: "exterior" },
  {
    value: DamageTypeEnum.fenderDriverSide,
    label: "Fender (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.fenderPassengerSide,
    label: "Fender (Passenger side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.frontBumper,
    label: "Front Bumper",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.frontBumperCornerDriverSide,
    label: "Front Bumper Corner (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.frontBumperCornerPassenger,
    label: "Front Bumper Corner (Passenger)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.frontDoorDriverSide,
    label: "Front Door (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.frontDoorPassenger,
    label: "Front Door (Passenger)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.frontWheelDriverSide,
    label: "Front Wheel (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.frontWheelPassengerSide,
    label: "Front Wheel (Passenger side)",
    group: "exterior"
  },
  { value: DamageTypeEnum.hood, label: "Hood", group: "exterior" },
  {
    value: DamageTypeEnum.mirrorDriverSide,
    label: "Mirror (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.mirrorPassengerSide,
    label: "Mirror (Passenger side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.quarterPanelDriverSide,
    label: "Quarter Panel (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.quarterPanelPassengerSide,
    label: "Quarter Panel (Passenger side)",
    group: "exterior"
  },
  { value: DamageTypeEnum.rearBumper, label: "Rear Bumper", group: "exterior" },
  {
    value: DamageTypeEnum.rearCornerBumperDriverSide,
    label: "Rear Corner Bumper (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.rearCornerBumperPassengerSide,
    label: "Rear Corner Bumper (Passenger side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.rearDoorDriverSide,
    label: "Rear Door (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.rearDoorPassengerSide,
    label: "Rear Door (Passenger side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.rockerPanelDriverSide,
    label: "Rocker Panel (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.rockerPanelPassengerSide,
    label: "Rocker Panel (Passenger side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.roofRailDriverSide,
    label: "Roof Rail (Driver side)",
    group: "exterior"
  },
  {
    value: DamageTypeEnum.roofRailPassengerSide,
    label: "Roof Rail (Passenger side)",
    group: "exterior"
  },
  { value: DamageTypeEnum.spoiler, label: "Spoiler", group: "exterior" },
  { value: DamageTypeEnum.tailLight, label: "Tail Light", group: "exterior" },
  { value: DamageTypeEnum.tires, label: "Tire/Rim", group: "exterior" },
  { value: DamageTypeEnum.trunk, label: "Trunk", group: "exterior" },
  { value: DamageTypeEnum.vandalism, label: "Vandalism", group: "exterior" },
  { value: DamageTypeEnum.wheel, label: "Wheel", group: "exterior" },
  {
    value: DamageTypeEnum.airConditioning,
    label: "Air conditioning",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.armrest,
    label: "Armrest",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.carpet,
    label: "Carpet",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.centerConsole,
    label: "Center Console",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.cigaretteLighter,
    label: "Cigarette Lighter",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.cupHolder,
    label: "Cup holder",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.dashPanel,
    label: "Dash Panel",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.doorHandles,
    label: "Door handles",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.doorLocksAndSwitches,
    label: "Door locks and switches",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.electronics,
    label: "Electronics/Wiring",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.gloveBox,
    label: "Glove box",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.keysAndLocks,
    label: "Key/Programming/Locks",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.lights,
    label: "Lights",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.panelsAndMoldings,
    label: "Panels and Moldings",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.pedal,
    label: "Pedal",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.rearViewMirror,
    label: "Rear view mirror",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.seats,
    label: "Seats",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.steeringWheel,
    label: "Steering wheel",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.vandalism,
    label: "Vandalism",
    group: "Interior and Mechanical"
  },
  {
    value: DamageTypeEnum.window,
    label: "Window",
    group: "Interior and Mechanical"
  }
];
