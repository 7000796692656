/* eslint-disable react/display-name */
import React, { Fragment, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { useQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Badge } from "@mui/material";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { ListItemLink } from "Components/Lists/ListItemLink";
import { RouteEnum } from "Enums/RouteEnum";
import { AuthContext } from "Login/Auth";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import {
  CarsDomainPermissionPaths,
  PaymentDomainPermissionPaths
} from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions, checkPermission } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { PayoutConnectContext } from "Components/Utils/PayoutConnectProvider";
import { RENTAL_AGGREGATE_QUERY } from "Queries/Rentals/RentalQueries";
import { RentalStatusEnum } from "Enums/StateEnums";
import { connect } from "react-redux";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { OwnerAlertContext } from "Components/Utils/OwnerAlertProvider";

export const primaryDrawerWidth = 240;

const FabRouter = React.forwardRef((props, ref) => (
  <Link {...props} innerRef={ref} />
));

const useStyles = makeStyles(theme => ({
  permanentDrawerOpened: {
    width: primaryDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  permanentDrawerClosed: {
    width: theme.spacing(7) + 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: "hidden"
  },
  tempDrawer: {
    width: primaryDrawerWidth
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  alert: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("lg")]: {
      paddingTop: theme.spacing(6)
    },
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.spacing(7)
    }
  },
  listCarButton: {
    margin: theme.spacing(2, 2, 2, 2),
    background: theme.palette.primary[900]
  },
  listCarButtonIcon: {
    marginRight: theme.spacing(1)
  },
  listItemText: {
    fontWeight: theme.typography.fontWeightMedium
  },
  rentalBadge: {
    marginRight: theme.spacing(1.5)
  }
}));

export const PrimaryDrawer = compose(
  withRouter,
  connect(state => ({
    messageNotifications: state.fleet.sendbird.messageNotifications
  }))
)(
  ({
    open,
    setDrawerClosed,
    permanent,
    location: { pathname },
    messageNotifications
  }) => {
    const { logout, switchAccount, switchAccountLoading } = useContext(
      AuthContext
    );
    const { scopes, currentRooftopClient } = useContext(ClientFactoryContext);
    const { requiresPayoutUpdate } = useContext(PayoutConnectContext);
    const { showAlert } = useContext(OwnerAlertContext);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const {
      permanentDrawerOpened,
      tempDrawer,
      toolbar,
      alert,
      listCarButton,
      listCarButtonIcon,
      listItemText,
      rentalBadge
    } = useStyles();

    const { data } = useQuery(CURRENT_USER_QUERY, {
      client: currentRooftopClient
    });
    const user = data?.viewer;

    const trackListCarClick = () => {
      analytics.track(AnalyticsEvents.label.owner.startedCarListing, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.owner.startedCarListing,
        property: JSON.stringify({
          userId: user?.id,
          firstName: user?.me?.firstName,
          lastName: user?.me?.lastName
        }),
        value: "",
        context: ""
      });
    };

    const { data: rentalRequests, loading: requestsLoading } = useQuery(
      RENTAL_AGGREGATE_QUERY,
      {
        variables: {
          filter: {
            status: RentalStatusEnum.applied
          }
        },
        client: currentRooftopClient,
        fetchPolicy: "network-only",
        pollInterval: 60000
      }
    );

    const requestCount =
      rentalRequests?.viewer?.me?.owner?.rentalsConnection?.aggregate?.count ||
      0;

    const menu = (
      <Fragment>
        {permanent && <div className={toolbar} />}
        {!matches && showAlert && <div className={alert} />}
        <Divider />
        <div />
        {renderByPermissions(
          <Fab
            color="primary"
            variant="extended"
            className={listCarButton}
            component={FabRouter}
            onClick={() => trackListCarClick()}
            to={
              checkPermission(
                CarsDomainPermissionPaths.csvRooftopSelect,
                scopes
              )
                ? matches
                  ? RouteEnum.manual
                  : RouteEnum.selectListingType
                : RouteEnum.manual
            }
            id={joyrideTutorialIds.home.listCarButton}
          >
            <AddIcon className={listCarButtonIcon} />
            LIST A CAR
          </Fab>,
          [CarsDomainPermissionPaths.listCar],
          scopes
        )}
        <List component="nav">
          <ListItemLink
            to={RouteEnum.baseRoute}
            selected={[RouteEnum.baseRoute].includes(pathname)}
            onClick={setDrawerClosed}
          >
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              classes={{ primary: listItemText }}
            />
          </ListItemLink>
          <ListItemLink
            to={RouteEnum.rentals}
            selected={[
              RouteEnum.rentals,
              RouteEnum.rentalsRequests,
              RouteEnum.rentalsActive,
              RouteEnum.rentalsPast
            ].includes(pathname)}
            id={joyrideTutorialIds.home.rentalsDrawerLink}
            onClick={setDrawerClosed}
          >
            <ListItemIcon>
              <EventNoteOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Rentals"
              classes={{ primary: listItemText }}
            />
            {!requestsLoading && requestCount > 0 && (
              <Badge
                badgeContent={requestCount}
                color="primary"
                className={rentalBadge}
              />
            )}
          </ListItemLink>
          <ListItemLink
            to={RouteEnum.listings}
            selected={[
              RouteEnum.listings,
              RouteEnum.listingsInventory,
              RouteEnum.listingsAll,
              RouteEnum.listingsProgress
            ].includes(pathname)}
            id={joyrideTutorialIds.home.listingsDrawerLink}
            onClick={setDrawerClosed}
          >
            <ListItemIcon>
              <DirectionsCarOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Listings"
              classes={{ primary: listItemText }}
            />
          </ListItemLink>
          {renderByPermissions(
            <ListItemLink
              to={RouteEnum.financials}
              selected={
                [
                  RouteEnum.financials,
                  RouteEnum.financialsListings,
                  RouteEnum.financialsPayouts,
                  RouteEnum.financialsTransactions
                ].includes(pathname) ||
                (pathname &&
                  pathname.includes(RouteEnum.financialsPayoutDetails))
              }
              id={joyrideTutorialIds.home.financialsDrawerLink}
              onClick={setDrawerClosed}
            >
              <ListItemIcon>
                <MonetizationOnOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Financials"
                classes={{ primary: listItemText }}
              />
            </ListItemLink>,
            [PaymentDomainPermissionPaths.viewPayments],
            scopes
          )}
          <ListItemLink
            to={RouteEnum.inbox}
            selected={[RouteEnum.inbox].includes(pathname)}
            id={joyrideTutorialIds.home.inboxDrawerLink}
            onClick={setDrawerClosed}
          >
            <ListItemIcon>
              <ChatBubbleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Inbox" classes={{ primary: listItemText }} />
            <Badge
              badgeContent={messageNotifications?.length}
              color="primary"
              className={rentalBadge}
            />
          </ListItemLink>
          <Divider />
          <ListItemLink
            to={RouteEnum.resources}
            selected={[RouteEnum.resources].includes(pathname)}
            id={joyrideTutorialIds.home.resourcesDrawerLink}
            onClick={setDrawerClosed}
          >
            <ListItemIcon>
              <HelpOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Resources"
              classes={{ primary: listItemText }}
            />
          </ListItemLink>
          <ListItemLink
            to={RouteEnum.settings}
            selected={[
              RouteEnum.settings,
              RouteEnum.settingsInbox,
              RouteEnum.settingsPayment,
              RouteEnum.settingsProfile,
              RouteEnum.settingsRooftops,
              RouteEnum.settingsRooftopsEdit,
              RouteEnum.settingsRooftopsListing,
              RouteEnum.settingsTeam
            ].includes(pathname)}
            id={joyrideTutorialIds.home.settingsDrawerLink}
            onClick={setDrawerClosed}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              classes={{ primary: listItemText }}
            />
            {requiresPayoutUpdate && <ErrorOutlineOutlinedIcon color="error" />}
          </ListItemLink>
          {matches && (
            <ListItem
              onClick={() => {
                switchAccount();
              }}
            >
              <ListItemIcon>
                <RepeatOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  switchAccountLoading ? "Switching..." : "Switch to Driver"
                }
                classes={{ primary: listItemText }}
              />
            </ListItem>
          )}
          {!permanent && (
            <ListItem onClick={() => logout()}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="Log Out"
                classes={{ primary: listItemText }}
              />
            </ListItem>
          )}
        </List>
      </Fragment>
    );

    return permanent ? (
      <Drawer
        variant={"permanent"}
        className={classNames({
          [permanentDrawerOpened]: permanent,
          [tempDrawer]: !permanent
        })}
        classes={{
          paper: classNames({
            [permanentDrawerOpened]: permanent,
            [tempDrawer]: !permanent
          })
        }}
        onClose={setDrawerClosed}
      >
        {menu}
      </Drawer>
    ) : (
      <SwipeableDrawer
        open={open}
        onClose={setDrawerClosed}
        className={classNames(tempDrawer)}
        classes={{ paper: classNames(tempDrawer) }}
        onOpen={() => {}}
      >
        {menu}
      </SwipeableDrawer>
    );
  }
);

PrimaryDrawer.propTypes = {
  open: PropTypes.bool,
  setDrawerClosed: PropTypes.func,
  permanent: PropTypes.bool
};
