/* eslint-disable react/jsx-key */
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SummaryTable } from "Fleet/Dashboard/DashboardViews/Financials/PayoutDetails/SummaryTable";
import { TransactionsTable } from "Fleet/Dashboard/DashboardViews/Financials/PayoutDetails/TransactionsTable";
import { RouteEnum } from "Enums/RouteEnum";
import { TRANSACTIONS_QUERY } from "Queries/Transactions/TransactionsQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { ExportTransactionsDrawer } from "Components/Drawers/ExportTransactionsDrawer";
import { usePageQueryAnalytics } from "Analytics/Custom/usePageQueryAnalytics";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

const useStyles = makeStyles(theme => ({
  loading: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  backButton: {
    fontSize: "16px",
    marginBottom: "24px"
  },
  backButtonChevron: {
    marginRight: "4px",
    height: "auto",
    width: "22px"
  },
  exportButtonMobile: {
    position: "absolute",
    right: "16px",
    top: "16px"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

export const PayoutDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const backButtonClick = () => history.push(RouteEnum.financialsPayouts);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data, loading, error } = useQuery(TRANSACTIONS_QUERY, {
    variables: {
      payoutId: id
    },
    client: currentRooftopClient,
    context: {
      apiv2: true
    }
  });

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  usePageQueryAnalytics({
    successLabel: AnalyticsEvents.label.owner.viewTransactions,
    errorLabel: AnalyticsEvents.label.owner.viewTransactionsError,
    data: data?.viewTransactions,
    error
  });

  const buttonComponent = (
    <Fab
      variant="extended"
      color="primary"
      onClick={handleDrawerOpen}
      className={isMobile ? classes.exportButtonMobile : ""}
    >
      <SendIcon className={classes.extendedIcon} />
      Export
    </Fab>
  );

  return (
    <>
      <ExportTransactionsDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        payoutIds={[id]}
      />
      <Button
        className={classes.backButton}
        color="primary"
        onClick={backButtonClick}
      >
        <ChevronLeftIcon className={classes.backButtonChevron} />
        BACK TO ALL PAYOUTS
      </Button>
      <SummaryTable data={data} loading={loading}></SummaryTable>
      <TransactionsTable
        data={data}
        loading={loading}
        buttonComponent={buttonComponent}
        hideButtonDivider
      ></TransactionsTable>
    </>
  );
};
