import React, { useContext } from "react";
import { reduxForm } from "redux-form";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import SocialLoginButton from "Components/Buttons/SocialLoginButton";
import { AuthContext } from "Login/Auth";
import Divider from "@mui/material/Divider";

import { UserTypeEnum } from "Enums/StateEnums";
import { SignUpFormFields } from "Components/Forms/FormFields/SignUpFormFields";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import TOSCopy, { FLOWS } from "./TOSCopy";
import { SelectServicesFormContext } from "Components/Utils/SelectServicesFormProvider";

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(0, 0, 4, 0)
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  error: {
    marginBottom: theme.spacing(4)
  },
  button: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  TOS: {
    margin: theme.spacing(1, 0, 0, 0),
    textAlign: "center",
    fontSize: 12
  },
  signupRedirectContainer: {
    letterSpacing: theme.spacing(0.16),
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[500]
  },
  signupRedirectLink: {
    cursor: "pointer"
  },
  spacing: {
    marginTop: theme.spacing(2)
  },
  driver: {
    color: "#FC6347"
  },
  owner: {
    color: "#03a9f4"
  }
}));

export const SignUpForm = reduxForm({
  form: "SIGNUP_FORM",
  destroyOnUnmount: false
})(
  ({
    handleSubmit,
    submitting,
    sendToLogin,
    error,
    userType,
    setUserTypeOwner,
    setUserTypeDriver
  }) => {
    const classes = useStyles();
    const {
      loginWithApple,
      loginWithGoogle,
      socialLoginSubmitting
    } = useContext(AuthContext);

    const { openSelectServicesForm } = useContext(SelectServicesFormContext);

    const handleSignUp = (event, loginCallback) => {
      try {
        analytics.track(
          event.legacyEventName,
          {
            category: AnalyticsEvents.category.userInteraction,
            action: AnalyticsEvents.action.buttonClicked,
            label: event.label,
            property: JSON.stringify({
              flow: AnalyticsEvents.flow.registration
            }),
            value: "",
            context: ""
          },
          () => {
            openSelectServicesForm();
            loginCallback(userType);
          }
        );
      } catch (error) {
        openSelectServicesForm();
        loginCallback(userType);
      }
    };

    const signUpWithGoogle = () => {
      handleSignUp(
        AnalyticsEvents.label.auth.signUpWithGoogleSelected,
        loginWithGoogle
      );
    };

    const signUpWithApple = () => {
      handleSignUp(
        AnalyticsEvents.label.auth.signUpWithAppleSelected,
        loginWithApple
      );
    };

    const signUpWithEmail = async values => {
      await handleSubmit(values);
      openSelectServicesForm();
    };

    return (
      <>
        <form onSubmit={signUpWithEmail}>
          {/* Single Sign In */}
          {/** Apple */}
          <SocialLoginButton
            buttonText="Sign up with Apple"
            type="apple"
            onClick={signUpWithApple}
            disabled={socialLoginSubmitting || submitting}
          />
          {/** Google */}
          <SocialLoginButton
            buttonText="Sign up with Google"
            type="google"
            onClick={signUpWithGoogle}
            disabled={socialLoginSubmitting || submitting}
          />
          <Grid container alignItems="center" className={classes.divider}>
            <Grid item xs={5}>
              <Divider />
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.containerText} align="center">
                OR
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Divider />
            </Grid>
          </Grid>
          <Typography
            component="h6"
            variant="h6"
            align="center"
            className={classes.title}
          >
            Sign up with email
          </Typography>
          {error && (
            <Typography
              align="center"
              className={classes.error}
              color="error"
              name="_error"
            >
              {error}
            </Typography>
          )}
          <SignUpFormFields submitting={submitting} userType={userType} />
          <div className={classes.TOS}>
            <TOSCopy flow={FLOWS.signup} />
          </div>
          <Button
            type="submit"
            className={classes.button}
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {submitting
              ? "Signing Up..."
              : userType === UserTypeEnum.owner
              ? "sign up as owner"
              : "sign up as driver"}
          </Button>
        </form>
        <Typography align="center" className={classes.signupRedirectContainer}>
          {"ALREADY HAVE AN ACCOUNT? "}
          <Link
            onClick={sendToLogin}
            disabled={submitting}
            className={classes.signupRedirectLink}
            underline="hover"
          >
            LOG IN
          </Link>
        </Typography>
        <Typography
          align="center"
          className={classNames(
            classes.signupRedirectContainer,
            classes.spacing
          )}
        >
          {userType === UserTypeEnum.owner
            ? "NOT AN OWNER? "
            : "NOT A DRIVER? "}
          <Link
            onClick={
              userType === UserTypeEnum.owner
                ? setUserTypeDriver
                : setUserTypeOwner
            }
            disabled={submitting}
            className={classNames(classes.signupRedirectLink, {
              [classes.driver]: userType === UserTypeEnum.owner,
              [classes.owner]: userType === UserTypeEnum.driver
            })}
            underline="hover"
          >
            {userType === UserTypeEnum.owner
              ? "SIGN UP AS A DRIVER"
              : "SIGN UP AS AN OWNER"}
          </Link>
        </Typography>
      </>
    );
  }
);
