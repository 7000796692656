import { connect as reduxConnect } from "react-redux";
import { compose, mapProps, withProps } from "recompose";

import {
  updateApiDataLoadingAction,
  updateApiDataAction
} from "Driver/redux/actions/apiDataActions";

export default compose(
  reduxConnect(
    state => ({
      messagingClient: state.apiData[getReduxMessagingKey()],
      messagingChannels: state.apiData[getReduxMessagingChannelsKey()]
    }),
    dispatch => {
      return {
        updateMessagingChannelsInRedux: newMessaging => {
          dispatch(
            updateApiDataAction({
              key: getReduxMessagingChannelsKey(),
              value: newMessaging
            })
          );
        },
        setMessagingChannelsLoadingInRedux: loading => {
          dispatch(
            updateApiDataLoadingAction({
              key: getReduxMessagingChannelsKey(),
              value: loading
            })
          );
        },
        updateMessagingInRedux: newMessaging => {
          dispatch(
            updateApiDataAction({
              key: getReduxMessagingKey(),
              value: newMessaging
            })
          );
        },
        setMessagingLoadingInRedux: loading => {
          dispatch(
            updateApiDataLoadingAction({
              key: getReduxMessagingKey(),
              value: loading
            })
          );
        }
      };
    }
  ),
  mapProps(({ messagingClient, messagingChannels, ...restProps }) => ({
    ...restProps,
    messagingChannels:
      messagingChannels && messagingChannels.data
        ? messagingChannels.data.channels
        : { loading: true, items: [] },
    messagingClient:
      messagingClient && messagingClient.data
        ? messagingClient.data.client
        : undefined
  })),
  withProps(({ messagingClient }) => ({
    messagingChannelOtherMember: channel => {
      return (
        channel.members.find(
          member => !messagingClient.isCurrentUser(member)
        ) || {}
      );
    },
    messagingStartChat: userId => {
      return new Promise((resolve, reject) =>
        messagingClient.createNewChannel(
          [userId.toString()],
          (error, channel) => {
            if (error) {
              reject(error);
            } else {
              resolve(channel.url);
            }
          }
        )
      );
    }
  }))
);
function getReduxMessagingKey() {
  return `/messaging`;
}
function getReduxMessagingChannelsKey() {
  return `/messagingList`;
}
