import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  inner: {
    padding: theme.spacing(1, 0),
    textAlign: "center"
  },
  center: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  left: {
    border: `1px solid ${theme.palette.divider}`
  },
  right: {
    border: `1px solid ${theme.palette.divider}`
  },
  grey: {
    color: "rgba(0, 0, 0, 0.26)"
  }
}));

export const DrawerActionContainer = ({ children }) => {
  return <Grid container>{children}</Grid>;
};

DrawerActionContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export const DrawerAction = ({
  type,
  href,
  icon,
  text,
  onClick,
  leftBorder,
  rightBorder,
  xs,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={xs}
      className={classNames(classes.center, {
        [classes.left]: leftBorder,
        [classes.right]: rightBorder
      })}
    >
      {type === "link" ? (
        <Button
          color="primary"
          fullWidth
          href={href}
          rel="noopener noreferrer"
          target="_blank"
          disabled={disabled}
        >
          <div className={classes.inner}>
            {icon}
            <Typography
              color="primary"
              display="block"
              className={classNames({ [classes.grey]: disabled })}
            >
              {text}
            </Typography>
          </div>
        </Button>
      ) : (
        <Button color="primary" fullWidth onClick={onClick} disabled={disabled}>
          <div className={classes.inner}>
            {icon}
            <Typography
              color="primary"
              display="block"
              className={classNames({ [classes.grey]: disabled })}
            >
              {text}
            </Typography>
          </div>
        </Button>
      )}
    </Grid>
  );
};

DrawerAction.propTypes = {
  type: PropTypes.oneOf(["link", "button"]),
  href: PropTypes.string,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  leftBorder: PropTypes.bool,
  rightBorder: PropTypes.bool,
  xs: PropTypes.number
};
