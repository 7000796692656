import React from "react";
import { Field, reduxForm } from "redux-form";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";

import { carColors } from "Variables/CarInfoVariables";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { FormTextField } from "Components/Inputs/FormTextField";
import { FormAutocomplete } from "Components/Inputs/FormAutocomplete";
import { handleSubmitFails } from "Components/Forms/FormUtils";
import { connect } from "react-redux";
import { compose } from "recompose";
import { validate } from "./validate";
import { Alert, Box, Typography } from "@mui/material";
import { FormCheckbox } from "Components/Inputs/FormCheckbox";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3, 0)
  },
  divider: {
    margin: theme.spacing(4, 0)
  },
  circleProgress: {
    padding: theme.spacing(1)
  }
}));

const normalize = value => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, "");
};

const colorOptions = carColors.map(color => ({
  value: color,
  label: color
}));

export const InfoForm = compose(
  connect(state => ({
    initialValues: state.fleet.listing.initialFormValues
  })),
  reduxForm({
    form: "LIST_CAR_FLOW",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    validate,
    onSubmitFail: (errors, dispatch, submitError, { values }) =>
      handleSubmitFails(errors, values)
  })
)(({ handleSubmit, previousStep }) => {
  const classes = useStyles();

  return (
    <FlowContentContainer
      header="Tell us about your car"
      nextStep={handleSubmit}
      nextStepLabel="Continue"
      previousStep={previousStep}
      previousStepLabel="Back"
      previousStepId="back-car-details"
      nextStepId="continue-car-details"
    >
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} lg={6}>
          <Field
            id="vin-field"
            name="vin"
            label="VIN"
            fullWidth
            variant="filled"
            disabled
            inputProps={{ style: { textTransform: "uppercase" } }}
            component={FormTextField}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            id="year-field"
            name="year"
            label="Year"
            fullWidth
            variant="filled"
            disabled
            component={FormTextField}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            id="make-field"
            name="make"
            label="Make"
            fullWidth
            variant="filled"
            disabled
            component={FormTextField}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            id="model-field"
            name="model"
            label="Model"
            fullWidth
            variant="filled"
            noOptionsString="No options. Please choose another make."
            disabled
            component={FormTextField}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            id="color-field"
            name="color"
            label="Color"
            fullWidth
            variant="filled"
            options={colorOptions}
            component={FormAutocomplete}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            id="odometer-field"
            name="mileage"
            label="Odometer"
            fullWidth
            variant="filled"
            component={FormTextField}
            normalize={normalize}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            id="plate-number-field"
            name="licensePlate"
            label="Plate Number"
            fullWidth
            variant="filled"
            component={FormTextField}
          />
        </Grid>
      </Grid>
      <Box mt={1}>
        <Alert severity="info">
          You must have a GPS tracker installed in your vehicle prior to
          renting. This precautionary measure will be used in the unlikely event
          of an issue.
        </Alert>
        <Box ml={2}>
          <Field
            name="gps"
            component={FormCheckbox}
            dataTestID="gps-checkbox"
            label={
              <Typography>
                I acknowledge that I need to have a GPS tracker installed in my
                car before my first rental.
              </Typography>
            }
          />
        </Box>
      </Box>
    </FlowContentContainer>
  );
});
