import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import orderBy from "lodash/orderBy";

import { SimpleMenuItemLayout } from "Components/MenuItem/SimpleMenuItemLayout";
import { RooftopDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { checkPermission } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { ROOFTOP_INFO } from "Queries/Rooftop/RooftopQueries";
import { LetterAvatar } from "Components/Avatar/LetterAvatar";

const useStyles = makeStyles(theme => ({
  rooftopContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    cursor: "pointer",
    overflow: "hidden",
    flex: 1
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  rowContainer: {
    display: "flex",
    overflow: "hidden"
  },
  menuPadding: {
    padding: theme.spacing(2.5, 0),
    maxWidth: theme.spacing(43.75),
    maxHeight: theme.spacing(68.75),
    overflowY: "scroll"
  },
  rooftopHeaderTitle: {
    fontSize: theme.spacing(3)
  },
  rooftopHeaderTitleMobile: {
    fontSize: theme.spacing(2)
  },
  location: {
    fontSize: theme.spacing(1.5)
  },
  scrollPaper: {
    alignItems: "flex-start",
    marginTop: theme.spacing(3.75)
  },
  organizationHeader: {
    padding: theme.spacing(0, 3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  skeleton: {
    height: ({ matches }) => (matches ? theme.spacing(3) : theme.spacing(5)),
    width: ({ matches }) => (matches ? theme.spacing(15) : theme.spacing(25))
  }
}));

export const RooftopSelector = ({ location }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const {
    organizationTokens,
    currentRooftopToken,
    currentOrganizationClient
  } = useContext(ClientFactoryContext);

  const [rooftopOpen, setRooftopOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const { data: rooftop, loading: rooftopLoading } = useQuery(ROOFTOP_INFO, {
    variables: {
      id: jwtDecode(currentRooftopToken).id
    },
    client: currentOrganizationClient
  });

  const currentRooftopName = get(rooftop, "rooftop.name", "");
  const reorderedOrgTokens = organizationTokens.sort((a, b) => {
    // If organization does not have viewHeader scope, sort it after
    if (
      !checkPermission(
        RooftopDomainPermissionPaths.viewHeader,
        jwtDecode(a).scopes
      )
    ) {
      return 1;
    } else if (
      !checkPermission(
        RooftopDomainPermissionPaths.viewHeader,
        jwtDecode(b).scopes
      )
    ) {
      return -1;
    }
    return 0;
  });
  const classes = useStyles({ matches });

  const handleToggleRooftop = () => {
    setRooftopOpen(prevOpen => !prevOpen);
  };

  return matches ? (
    <div
      className={classes.rooftopContainer}
      onClick={handleToggleRooftop}
      ref={anchorRef}
    >
      <div className={classes.columnContainer}>
        <div className={classes.rowContainer}>
          {!rooftopLoading ? (
            <>
              <Typography
                noWrap={true}
                className={classes.rooftopHeaderTitleMobile}
              >
                {currentRooftopName}
              </Typography>
              <ArrowDropDownIcon />
            </>
          ) : (
            <Skeleton
              variant="rectangular"
              classes={{ rectangular: classes.skeleton }}
            />
          )}
        </div>
        <Typography className={classes.location}>{location}</Typography>
      </div>
      <Dialog
        open={rooftopOpen}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleToggleRooftop}
        onBackdropClick={handleToggleRooftop}
        classes={{ scrollPaper: classes.scrollPaper }}
      >
        <MenuList
          autoFocusItem={rooftopOpen}
          classes={{ padding: classes.menuPadding }}
        >
          {map(reorderedOrgTokens, (organizationToken, orgsIndex) => {
            return (
              <div key={`org-section-${orgsIndex}`}>
                <OrganizationSection
                  organizationToken={organizationToken}
                  index={orgsIndex}
                />
                {orgsIndex !== reorderedOrgTokens.length - 1 ? (
                  <Divider className={classes.divider} />
                ) : null}
              </div>
            );
          })}
        </MenuList>
      </Dialog>
    </div>
  ) : (
    <div className={classes.rooftopContainer} onClick={handleToggleRooftop}>
      {!rooftopLoading ? (
        <>
          <Typography className={classes.rooftopHeaderTitle} ref={anchorRef}>
            {currentRooftopName}
          </Typography>
          <ArrowDropDownIcon />
        </>
      ) : (
        <Skeleton
          variant="rectangular"
          classes={{ rectangular: classes.skeleton }}
        />
      )}
      <Popper open={rooftopOpen} anchorEl={anchorRef.current} disablePortal>
        <Paper>
          <ClickAwayListener onClickAway={handleToggleRooftop}>
            <MenuList
              autoFocusItem={rooftopOpen}
              classes={{ padding: classes.menuPadding }}
            >
              {map(reorderedOrgTokens, (organizationToken, orgsIndex) => {
                return (
                  <div key={`org-section-${orgsIndex}`}>
                    <OrganizationSection
                      organizationToken={organizationToken}
                      index={orgsIndex}
                    />
                    {orgsIndex !== reorderedOrgTokens.length - 1 ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </div>
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

const OrganizationSection = connect(null)(({ organizationToken, index }) => {
  const { getOrganizationRooftops } = useContext(ClientFactoryContext);

  const decodedOrg = jwtDecode(organizationToken);
  const isDefaultOrg = !checkPermission(
    RooftopDomainPermissionPaths.viewHeader,
    decodedOrg.scopes
  );
  const classes = useStyles();
  const orgName = isDefaultOrg
    ? "PERSONAL ROOFTOP"
    : get(decodedOrg, "name", "").toUpperCase();

  const rooftopTokens = getOrganizationRooftops(
    jwtDecode(organizationToken).id
  );

  return (
    <div key={index}>
      <Typography component="h2" className={classes.organizationHeader}>
        {orgName}
      </Typography>
      {map(
        orderBy(
          rooftopTokens,
          rooftopToken => jwtDecode(rooftopToken).name,
          "asc"
        ),
        (rooftopToken, rooftopsIndex) => {
          return (
            <div key={rooftopsIndex}>
              <RooftopMenuItem
                orgToken={organizationToken}
                rooftopToken={rooftopToken}
              />
            </div>
          );
        }
      )}
      {/* Note: Will need to this rooftop feature*/}
      {/* {renderByPermissions(
          <SimpleMenuItemLayout
            onClick={() => openCreateRooftopDialog(organizationToken)}
            avatarIcon={<AddIcon />}
            title="Add another rooftop"
          />,
          [RooftopDomainPermissionPaths.createRooftop],
          scopes
        )} */}
    </div>
  );
});

const RooftopMenuItem = ({ orgToken, rooftopToken }) => {
  const {
    organizationClients,
    changeCurrentRooftopClient,
    changeCurrentOrganizationClient
  } = useContext(ClientFactoryContext);
  const handleSelectRooftop = (rooftopToken, orgToken) => {
    changeCurrentRooftopClient(rooftopToken);
    changeCurrentOrganizationClient(orgToken);
  };
  const decodedRooftop = jwtDecode(rooftopToken);
  const { data, loading } = useQuery(ROOFTOP_INFO, {
    variables: {
      id: decodedRooftop.id
    },
    client: organizationClients[jwtDecode(orgToken).id]
  });
  const streetAddress = get(data, "rooftop.address.streetAddress");

  return (
    <SimpleMenuItemLayout
      onClick={() => handleSelectRooftop(rooftopToken, orgToken)}
      avatarAlt={
        get(data, "rooftop.name")
          ? get(data, "rooftop.name")
              .charAt(0)
              .toUpperCase()
          : "B"
      }
      avatarIcon={<LetterAvatar firstName={get(data, "rooftop.name")} />}
      avatarSrc={get(data, "rooftop.legacyRooftop.profilePhoto.url")}
      title={get(data, "rooftop.name")}
      subTitle={
        streetAddress
          ? `${get(data, "rooftop.address.streetAddress", "")}, ${get(
              data,
              "rooftop.address.city",
              ""
            )}, ${get(data, "rooftop.address.state", "")}, ${get(
              data,
              "rooftop.address.postalCode",
              ""
            )}`
          : "No Address"
      }
      loading={loading}
    />
  );
};

RooftopSelector.propTypes = {
  location: PropTypes.string
};
