import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";

import { InfoDrawer } from "Components/Drawers/InfoDrawer";
import { DrawerContainer } from "Components/Drawers/DrawerContainer";
import { InviteMemberFormFields } from "Components/Forms/FormFields/InviteMemberFormFields";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { useDialog } from "Components/Dialog/hooks/useDialog";
import { RolesDialog } from "Components/Dialog/RolesDialog";
import { useMemberFormController } from "Components/Forms/FormControllers/hooks/useMemberFormController";

const MemberInviteDrawerForm = reduxForm({
  form: "MEMBER_FORM"
})(({ handleCloseMemberForm, handleSubmit, submitting }) => {
  const { dialogOpen, handleDialogClose, handleDialogOpen } = useDialog(false);

  return (
    <>
      <DrawerAppBar
        icon={
          <IconButton onClick={handleCloseMemberForm} size="large">
            <CloseIcon />
          </IconButton>
        }
        title="Invite Member"
        border={true}
        rightIcon={
          <IconButton onClick={handleDialogOpen} size="large">
            <HelpIcon />
          </IconButton>
        }
        isStatic
      />
      <DrawerPaddingContainer>
        <InviteMemberFormFields compact />
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onSubmit={handleSubmit}
        submitText={submitting ? "inviting" : "invite"}
        disabled={submitting}
        compact
      />
      <RolesDialog open={dialogOpen} handleClose={handleDialogClose} />
    </>
  );
});

export const MemberDrawer = connect()(({ dispatch, open, handleClose }) => {
  const { onSubmit, validate, initialValues } = useMemberFormController(
    dispatch,
    handleClose
  );

  return (
    <InfoDrawer open={open} onClose={handleClose}>
      <DrawerContainer>
        <MemberInviteDrawerForm
          handleCloseMemberForm={handleClose}
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
        />
      </DrawerContainer>
    </InfoDrawer>
  );
});
