import React from "react";
import { Tooltip } from "Components/Flows/Tooltip";
import Link from "@mui/material/Link";

const getLinkedParagraph = (paragraph, linksFirst) => {
  const link = (
    <Link
      href={paragraph.link.href}
      target="_blank"
      rel="noreferrer"
      underline="hover"
    >
      {paragraph.link.text}
    </Link>
  );
  return linksFirst ? (
    <>
      {link}
      {paragraph.text}
    </>
  ) : (
    <>
      {paragraph.text}
      {link}
    </>
  );
};
const ManualFlowTooltip = ({ tooltip, linksFirst }) => (
  <Tooltip
    header={tooltip.title}
    paragraphs={tooltip.content.map(paragraph =>
      paragraph.link
        ? props => (
            <div {...props}>{getLinkedParagraph(paragraph, linksFirst)}</div>
          )
        : paragraph.text
    )}
  />
);

export default ManualFlowTooltip;
