import React, { createContext, useContext, useState } from "react";
import { FirebaseContext } from "./FirebaseProvider";

export const OwnerAlertContext = createContext();

export const OwnerAlertProvider = ({ children }) => {
  const [alertEnabled, setAlertEnabled] = useState(true);
  const { getJSONValue } = useContext(FirebaseContext);
  const configuration = getJSONValue("owner_web_banner");
  const showAlert =
    alertEnabled &&
    configuration?.should_appear &&
    configuration?.allowed_to_continue;

  return (
    <OwnerAlertContext.Provider
      value={{
        showAlert,
        disableAlert: () => setAlertEnabled(false)
      }}
    >
      {children}
    </OwnerAlertContext.Provider>
  );
};
