import React, { useState, useEffect, useContext } from "react";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory
} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import get from "lodash/get";
import reduce from "lodash/reduce";

import { InboxHeader } from "Components/Header/InboxHeader";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { disableHeader, enableHeader } from "Redux/Header/ActionCreators";
import { RouteEnum } from "Enums/RouteEnum";
import { TabsWithRoutes } from "Components/Tabs/TabsWithRoutes";
import { InboxAll } from "Fleet/Dashboard/DashboardViews/Inbox/HCFB/InboxAll";
import { InboxMine } from "Fleet/Dashboard/DashboardViews/Inbox/HCFB/InboxMine";
import { RouterTab } from "Components/Tabs/RouterTab";
import {
  JoyrideController,
  JoyrideTutorialEnum
} from "Components/Joyride/JoyrideController";
import { InboxDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { useFilterChannelsByAssignedTo } from "Fleet/Dashboard/DashboardViews/Inbox/helpers";
import { RouteWithPermission } from "Components/Routers/RouteWithPermission";
import { CompleteInbox } from "Components/Inbox/CompleteInbox";
import { checkPermission } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { useTableState } from "Components/Tables/hooks/useTableState";

const useStyles = makeStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.common.white
  }
}));

export const HCFBInbox = connect(
  state => ({
    channels: get(state, "fleet.sendbird.channels", [])
  }),
  { disableHeader, enableHeader }
)(({ disableHeader, enableHeader, channels }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { scopes } = useContext(ClientFactoryContext);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useLocation();
  const history = useHistory();
  const [activeTabValue, setActiveTabValue] = useState(
    history.location.pathname === RouteEnum.inbox
      ? RouteEnum.inboxAll
      : history.location.pathname
  );
  const [mineUnread, setMineUnread] = useState(0);
  const [allUnread, setAllUnread] = useState(0);
  const { filteredChannelsByAssignedTo } = useFilterChannelsByAssignedTo(
    channels
  );
  const tableState = useTableState({ initialFirst: 10 });

  useEffect(() => {
    setActiveTabValue(pathname);
  }, [pathname]);

  useEffect(() => {
    const allUnreadCount = reduce(
      channels,
      (acc, channel) => acc + get(channel, "unreadMessageCount", 0),
      0
    );
    const mineUnreadCount = reduce(
      filteredChannelsByAssignedTo,
      (acc, channel) => acc + get(channel, "unreadMessageCount", 0),
      0
    );
    setMineUnread(mineUnreadCount);
    setAllUnread(allUnreadCount);
  }, [filteredChannelsByAssignedTo, channels]);

  useEffect(() => {
    if (matches) {
      disableHeader();
    } else {
      enableHeader();
    }

    return () => {
      enableHeader();
    };
  }, [matches, enableHeader, disableHeader]);

  return (
    <>
      <JoyrideController tutorial={JoyrideTutorialEnum.inbox} />
      {matches ? (
        <InboxHeader
          tabs={
            !checkPermission(
              InboxDomainPermissionPaths.viewMultiUserInbox,
              scopes
            ) ? null : (
              <Tabs
                variant="fullWidth"
                textColor="inherit"
                value={activeTabValue}
                classes={{ indicator: classes.indicator }}
              >
                <RouterTab
                  label="All"
                  to={RouteEnum.inboxAll}
                  value={RouteEnum.inboxAll}
                  badge={{ content: allUnread, color: "error" }}
                />
                <RouterTab
                  label="Mine"
                  to={RouteEnum.inboxMine}
                  value={RouteEnum.inboxMine}
                  badge={{ content: mineUnread, color: "error" }}
                />
              </Tabs>
            )
          }
        />
      ) : (
        <>
          {checkPermission(
            InboxDomainPermissionPaths.viewMultiUserInbox,
            scopes
          ) && (
            <TabsWithRoutes
              paths={[
                {
                  to: RouteEnum.inboxAll,
                  label: "All",
                  badgeContent: allUnread,
                  color: "error"
                },
                {
                  to: RouteEnum.inboxMine,
                  label: "Mine",
                  badgeContent: mineUnread,
                  color: "error"
                }
              ]}
            />
          )}
        </>
      )}
      <DashboardLayout fullScreen={matches} hasAppBar={matches}>
        {checkPermission(
          InboxDomainPermissionPaths.viewMultiUserInbox,
          scopes
        ) && (
          <Route
            exact
            path={RouteEnum.inbox}
            render={() => <Redirect to={RouteEnum.inboxAll} />}
            requiredPermissions={[
              InboxDomainPermissionPaths.viewMultiUserInbox
            ]}
          />
        )}
        <Switch>
          <Route exact path={RouteEnum.inbox} component={CompleteInbox} />
          <RouteWithPermission
            exact
            path={RouteEnum.inboxAll}
            render={props => <InboxAll {...props} tableState={tableState} />}
            requiredPermissions={[
              InboxDomainPermissionPaths.viewMultiUserInbox
            ]}
            actionRoute={RouteEnum.inbox}
          />
          <RouteWithPermission
            path={RouteEnum.inboxMine}
            component={InboxMine}
            requiredPermissions={[
              InboxDomainPermissionPaths.viewMultiUserInbox
            ]}
            actionRoute={RouteEnum.inbox}
          />
        </Switch>
      </DashboardLayout>
    </>
  );
});
