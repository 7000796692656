import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { remoteConfig } from "Utils/Firebase";

export const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteConfig
      .fetchAndActivate()
      .then(() => setLoading(false))
      .catch(err => console.log(err));
  }, []);

  const getJSONValue = key => {
    const value = remoteConfig.getValue(key);
    try {
      const parsedValue = JSON.parse(value._value);
      return parsedValue;
    } catch (error) {
      console.log(`Can't parse ${value._value} as JSON`);
      console.log(error);
      return {};
    }
  };

  const getValue = key => {
    const value = remoteConfig.getValue(key);
    return value?._value;
  };

  const getLink = key => getJSONValue("web_links")[key];
  const getAsset = key => getJSONValue("web_assets")[key];
  const getDeeplink = key => getJSONValue("web_deeplinks")[key];
  const getAuthAffiliateSelection = key =>
    getJSONValue("auth_affiliate_selection")[key];

  return (
    <FirebaseContext.Provider
      value={{
        config: remoteConfig,
        loading,
        getJSONValue,
        getValue,
        getLink,
        getAsset,
        getDeeplink,
        getAuthAffiliateSelection
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};
