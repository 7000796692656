import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    transform: "translate(-50%, -100%)"
  },
  icon: {
    width: "60px",
    [theme.breakpoints.down("md")]: {
      width: "50px"
    }
  }
}));

const LocationMarker = ({ assetSrc }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={assetSrc} alt="marker" className={classes.icon} />
    </div>
  );
};

LocationMarker.propTypes = {
  assetSrc: PropTypes.string
};

export default LocationMarker;
