/* eslint-disable react/prop-types */
import React, {
  useContext,
  Fragment,
  useEffect,
  useRef,
  createElement,
  useCallback
} from "react";
import { render } from "react-dom";
import makeStyles from "@mui/styles/makeStyles";
import { autocomplete } from "@algolia/autocomplete-js";
import { useHistory } from "react-router";
import jwtDecode from "jwt-decode";

import { AlgoliaContext } from "Components/Utils/AlgoliaProvider";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { Item } from "./Item";
import { NoResults } from "./NoResults";
import { groupItemsSource } from "./utils/reshape";
import { getItems } from "./utils/query";

import "@algolia/autocomplete-theme-classic/dist/theme.css";
import "./autocomplete.css";
import { ThemeProvider } from "Styles/ThemeProvider";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: 400,
      marginRight: theme.spacing(2)
    }
  }
}));

export const UniversalSearch = () => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const history = useHistory();

  const {
    currentOrganizationToken,
    currentRooftopToken,
    changeCurrentRooftopClient,
    getOrganizationRooftops
  } = useContext(ClientFactoryContext);
  const organizationId = jwtDecode(currentOrganizationToken).id;
  const rooftopId = jwtDecode(currentRooftopToken).id;
  const rooftopTokens = getOrganizationRooftops(organizationId);
  const { client: algoliaClient } = useContext(AlgoliaContext);

  const handleRooftopChange = useCallback(
    targetRooftopId => {
      if (rooftopId !== targetRooftopId) {
        const rooftopToken = rooftopTokens.find(
          rooftopToken => jwtDecode(rooftopToken).id === targetRooftopId
        );
        changeCurrentRooftopClient(rooftopToken);
      }
    },
    [changeCurrentRooftopClient, rooftopId, rooftopTokens]
  );

  useEffect(() => {
    if (!containerRef.current) return undefined;
    const search = autocomplete({
      container: containerRef.current,
      detachedMediaQuery: "(max-width: 960px)",
      placeholder: "Search by VIN, Driver Name, or Car.",
      renderer: { createElement, Fragment },
      renderNoResults(_, root) {
        render(
          <ThemeProvider>
            <NoResults />
          </ThemeProvider>,
          root
        );
      },
      reshape({ sourcesBySourceId }) {
        const { itemsSource, ...rest } = sourcesBySourceId;
        return [groupItemsSource(itemsSource), Object.values(rest)];
      },
      render({ children }, root) {
        render(children, root);
      },
      getSources: ({ query }) => [
        {
          sourceId: "itemsSource",
          getItems() {
            return getItems(query, algoliaClient);
          },
          templates: {
            item({ item }) {
              return (
                <ThemeProvider>
                  <Item
                    hit={item}
                    history={history}
                    handleRooftopChange={handleRooftopChange}
                  />
                </ThemeProvider>
              );
            }
          }
        }
      ]
    });
    return () => search.destroy();
  }, [handleRooftopChange, history, algoliaClient]);

  return (
    <div className={classes.root}>
      <div ref={containerRef} />
    </div>
  );
};
