/* eslint-disable react/display-name */
import React, { useContext } from "react";
import { reduxForm, Field } from "redux-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { CheckInSubmittingScreen } from "Fleet/Flows/CheckInFlow/CheckInSubmittingScreen";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { StepperContext } from "./CheckInFlowContainer";
import { DocumentDropzone } from "Components/Forms/Dropzone/DocumentDropzone";
import { requiredFields } from "Utils/FormHelpers";
import { AcceptEnum } from "Enums/AcceptEnum";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(() => ({
  dropzoneContainer: {
    height: "260px"
  }
}));

export const MileageAndGas = reduxForm({
  form: "CHECK_IN_FLOW",
  destroyOnUnmount: false,
  validate: values =>
    requiredFields(values, ["carMileageImage", "carGasLevelImage"])
})(({ handleSubmit, submitting }) => {
  if (submitting) return <CheckInSubmittingScreen />;
  const classes = useStyles();
  const { getLink } = useContext(FirebaseContext);
  const { handleOnSubmit, previousStep } = useContext(StepperContext);
  const onSubmit = handleSubmit(handleOnSubmit);

  const leftComponent = (
    <FlowContentContainer
      header="Mileage and Gas Levels"
      nextStepLabel={"Submit"}
      nextStep={onSubmit}
      previousStepLabel="Back"
      previousStep={previousStep}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography component="h6" variant="h6" gutterBottom>
            Odometer
          </Typography>
          <div className={classes.dropzoneContainer}>
            <Field
              name="carMileageImage"
              component={DocumentDropzone}
              objectFit
              accept={[AcceptEnum.jpeg, AcceptEnum.png, AcceptEnum.pdf]}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h6" variant="h6" gutterBottom>
            Gas Level
          </Typography>
          <div className={classes.dropzoneContainer}>
            <Field
              name="carGasLevelImage"
              component={DocumentDropzone}
              objectFit
              accept={[AcceptEnum.jpeg, AcceptEnum.png, AcceptEnum.pdf]}
            />
          </div>
        </Grid>
      </Grid>
    </FlowContentContainer>
  );

  const rightComponent = (
    <TooltipContainer header="Why does this matter?" useIcon>
      <Tooltip
        paragraphs={[
          "Why does this matter? We want our drivers to return your car within the mileage limits you set and with the same gas level. If you choose to file a claim after the rental, we use these images.",
          () => (
            <Typography component="p" variant="body2" color="textSecondary">
              To learn more, read about our{" "}
              <Link
                href={getLink("mileagePolicy")}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
              >
                Mileage
              </Link>{" "}
              and{" "}
              <Link
                href={getLink("gasPolicy")}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
              >
                Gas Policy
              </Link>
              .
            </Typography>
          )
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      useBottomDrawer
      leftComponent={leftComponent}
      rightComponent={rightComponent}
    />
  );
});
