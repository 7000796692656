import React, { Fragment, useState } from "react";
import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";

import partnerTheme from "./partner_styled-theme";
import driverTheme from "./driver_styled-theme";

const ThemeContext = ({ children, userType }) => {
  const [showContent, setShowContent] = useState(false);
  const theme = userType === "OWNER" ? partnerTheme : driverTheme;

  const renderTag = () => {
    return (
      <link
        href={
          userType === "OWNER"
            ? `${process.env.PUBLIC_URL}/partner_bootstrap.css`
            : `${process.env.PUBLIC_URL}/driver_bootstrap.css`
        }
        onLoad={() => setShowContent(true)}
        rel="stylesheet"
        type="text/css"
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        {renderTag()}
        {showContent ? children : null}
      </Fragment>
    </ThemeProvider>
  );
};

ThemeContext.propTypes = {
  userType: PropTypes.string,
  showFleet: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  userIsAuthenticated: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default ThemeContext;
