export const ProtectionPlanEnum = {
  basic: "BASIC",
  standard: "STANDARD",
  premium: "PREMIUM",
  premium_plus: "PREMIUM_PLUS"
};

export const ProtectionPlanCopyEnum = {
  BASIC: "Basic",
  STANDARD: "Standard",
  PREMIUM: "Premium",
  PREMIUM_PLUS: "Premium Plus"
};

export const ListingVeritifactionStatusCopyEnum = {
  INCOMPLETE: "Incomplete",
  VERIFIED: "Verified",
  PENDING_VERIFICATION: "Pending Verification",
  FAILED_VERIFICATION: "Failed Verification"
};

export const DocumentTypeEnum = {
  personalInsurance: "PERSONAL_INSURANCE",
  registration: "REGISTRATION",
  inspection: "UBER_LYFT_INSPECTION"
};

export const DocumentTypeKeysEnum = {
  insurance: "personalInsuranceFile",
  registration: "registrationFile",
  inspection: "uberLyftInspectionFile"
};

export const FileTypeEnum = {
  profilePhoto: "PROFILE_PHOTO",
  licensePhoto: "LICENSE_PHOTO",
  rentalContract: "RENTAL_CONTRACT",
  carPhoto: "CAR_PHOTO",
  carPickup: "CAR_PICKUP",
  carDocument: "CAR_DOCUMENT",
  carRegistrationFile: "CAR_REGISTRATION_FILE",
  uberLyftInspectionFile: "UBER_LYFT_INSPECTION_FILE",
  personalInsuranceFile: "PERSONAL_INSURANCE_FILE",
  claimEvidenceFile: "CLAIM_EVIDENCE_FILE"
};

export const CarVerificationEnum = {
  verified: "VERIFIED",
  pendingVerification: "PENDING_VERIFICATION",
  failedVerification: "FAILED_VERIFICATION",
  incomplete: "INCOMPLETE"
};

export const CarStatusEnum = {
  available: "AVAILABLE",
  removed: "REMOVED",
  rented: "RENTED",
  deleted: "DELETED"
};

export const CombinedCarStatusEnum = {
  pendingVerification: "PENDING_VERIFICATION",
  failedVerification: "FAILED_VERIFICATION",
  snoozed: "SNOOZED",
  live: "LIVE",
  liveRented: "LIVE_RENTED",
  blocked: "BLOCKED",
  onLot: "ON_LOT"
};

export const CombinedCarStatusTextEnum = {
  [CombinedCarStatusEnum.pendingVerification]: "Pending",
  [CombinedCarStatusEnum.failedVerification]: "Failed",
  [CombinedCarStatusEnum.snoozed]: "Snoozed",
  [CombinedCarStatusEnum.live]: "Live",
  [CombinedCarStatusEnum.liveRented]: "Rented",
  [CombinedCarStatusEnum.blocked]: "Blocked",
  [CombinedCarStatusEnum.onLot]: "On Lot"
};

export const RentalStatusEnum = {
  applied: "APPLIED",
  appliedNotVerified: "APPLIED_NOT_VERIFIED",
  applicationRejected: "APPLICATION_REJECTED",
  applicationExpired: "APPLICATION_EXPIRED",
  applicationAutoRejected: "APPLICATION_AUTO_REJECTED",
  pendingInsurance: "PENDING_INSURANCE",
  cancelled: "CANCELLED",
  pendingPickup: "PENDING_PICKUP",
  active: "ACTIVE",
  late: "LATE",
  completed: "COMPLETED",
  archived: "ARCHIVED"
};

export const RentalStatusCopyEnum = {
  requested: "Requested",
  accepted: "Accepted",
  checkIn: "Check-in",
  active: "Active",
  late: "Late",
  completed: "Completed",
  cancelled: "Cancelled"
};

export const ListingStatusCopyEnum = {
  live: "Live",
  snoozed: "Snoozed",
  pending: "Pending",
  unverified: "Unverified",
  failed: "Failed",
  rented: "Rented",
  blocked: "Blocked",
  deleted: "Deleted"
};

export const UserTypeEnum = {
  owner: "OWNER",
  dealer: "DEALER",
  driver: "DRIVER"
};

export const OwnerTypeEnum = {
  franchiseDealership: "FRANCHISE_DEALERSHIP",
  independentDealership: "INDEPENDENT_DEALERSHIP",
  rentalCarCompany: "RENTAL_CAR_COMPANY",
  fleetOwner: "FLEET_OWNER",
  peerToPeer: "P2P"
};

export const ClaimEnum = {
  claim: "CLAIM",
  unlisted: "UNLISTED"
};
