import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";

import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { RentalStatusEnum } from "Enums/StateEnums";
import StepAppBar from "../StepAppBar";
import CarSwitchReasonFormFields from "./selectReason/CarSwitchReasonFormFields";
import InsuranceWarning from "./selectReason/InsuranceWarning";
import { validate } from "./selectReason/validate";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1
  }
});

const SelectReason = ({ onClose, handleSubmit, invalid, rental }) => {
  const classes = useStyles();

  const showInsuranceWarning = [
    RentalStatusEnum.pendingPickup,
    RentalStatusEnum.active
  ].includes(rental.status);

  return (
    <>
      <StepAppBar title="Reason for Switch" onClose={onClose} />
      <DrawerPaddingContainer className={classes.container}>
        <CarSwitchReasonFormFields />
        {showInsuranceWarning && <InsuranceWarning />}
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onCancel={onClose}
        cancelText={"Cancel"}
        onSubmit={handleSubmit}
        submitText={"Next: Select Car"}
        submitDisabled={invalid}
      />
    </>
  );
};

SelectReason.propTypes = {
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  rental: PropTypes.object
};

export default reduxForm({
  form: "CAR_SWITCH", //             <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(SelectReason);
