import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { useListingStatusOptions } from "Components/hooks/useListingStatusOptions";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  icon: {
    color: props => props.color,
    margin: theme.spacing(0, 1, 0, 0)
  },
  text: {
    color: props => props.color
  }
}));

export const ListingStatusIndicator = ({ status, verification }) => {
  const { copy, color, Icon } = useListingStatusOptions(status, verification);
  const classes = useStyles({ color });

  return (
    <div className={classes.root}>
      {Icon && <Icon className={classes.icon} />}
      <Typography variant="body1" component="span" className={classes.text}>
        {copy}
      </Typography>
    </div>
  );
};

ListingStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  verification: PropTypes.string.isRequired
};
