export const getSnowplowSessionInformation = () => {
  return new Promise(resolve => {
    const session = {
      domainUserId: "",
      cookieCreatedDate: "",
      siteVisitCount: "",
      currentVisitTime: "",
      lastVisitTime: "",
      userId: ""
    };
    if (!window?.snowplow) return resolve(session);

    window.snowplow(function() {
      try {
        const information = this.hc.getDomainUserInfo();
        session["isFirstSession"] = information[0] || "";
        session["domainUserId"] = information[1] || "";
        session["cookieCreatedDate"] = `${information[2] || ""}`;
        session["siteVisitCount"] = `${information[3] || ""}`;
        session["currentVisitTime"] = `${information[4] || ""}`;
        session["lastVisitTime"] = `${information[5] || ""}`;
        session["userId"] = this.hc.getUserId() || "";
      } catch (e) {
        console.error("sp cb error", e.message);
        return resolve(session);
      }
      if (session["userId"]) return resolve(session);

      try {
        session["userId"] = JSON.parse(
          atob(window.localStorage.getItem("id_token").split(".")[1])
        ).id;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      resolve(session);
    });
  });
};
