import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import moment from "moment";
import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  parseRentalStartAndEndDate,
  removeUnderScoresAndDashes
} from "Utils/Helpers";
import {
  centsToDollars,
  getRentalDaysLeftOrDaysLateText,
  getRentalRequestExpiration
} from "Utils/Calculations";
import { RentalStatusEnum } from "Enums/StateEnums";
import { RENTALS_QUERY } from "Queries/Rentals/RentalQueries";
import { optionalChaining } from "Driver/utils/helpers";
import { RentalStatusDotIndicator } from "Components/Rentals/RentalStatusDotIndicator";
import { getRentalStatusOptions } from "Components/hooks/useRentalStatusOptions";
import { appendCommasToUSDollarsString } from "Utils/textFormatting";

const getEndDate = (currentRentalPeriod, droppedOffAt) => {
  if (droppedOffAt) return droppedOffAt;
  if (currentRentalPeriod) return currentRentalPeriod.endDate;
  return "";
};

const getRentalDays = currentRentalPeriod =>
  currentRentalPeriod
    ? moment(currentRentalPeriod.endDate).diff(
        moment(currentRentalPeriod.startDate),
        "days"
      )
    : "";

const getStatusLabel = (status, copy, currentRentalPeriod) => {
  const daysLeft =
    currentRentalPeriod &&
    getRentalDaysLeftOrDaysLateText(currentRentalPeriod.endDate);
  const label =
    status === RentalStatusEnum.active || status === RentalStatusEnum.late
      ? daysLeft
      : copy;
  return capitalize(removeUnderScoresAndDashes(label));
};

const hasRentalPeriods = ({ node: { rentalPeriods } }) =>
  rentalPeriods && rentalPeriods.length;

export const useMappedRentalData = data => {
  const theme = useTheme();
  const rentals = get(data, "viewer.me.owner.rentalsConnection.edges", []);

  return rentals.filter(hasRentalPeriods).map(({ node }) => {
    const {
      id,
      createdAt,
      status,
      car,
      currentRentalPeriod,
      rentalPeriods,
      driver,
      overview,
      droppedOffAt
    } = node;
    const { copy } = getRentalStatusOptions(status, theme);

    const rentalPeriodDateSpan = parseRentalStartAndEndDate(node);
    const pickupDate = moment(rentalPeriodDateSpan.startDate).format(
      "MM/DD/YY"
    );
    const returnDate = moment(rentalPeriodDateSpan.endDate).format("MM/DD/YY");

    const netEarningsString = `$${centsToDollars(
      overview.ownerEarningsInCents
    )}`;
    const netEarningsWithCommas = appendCommasToUSDollarsString(
      netEarningsString
    );

    return {
      ...node,
      id,
      status,
      statusTableCell: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <RentalStatusDotIndicator status={status} />
          <Typography
            component="p"
            variant="body2"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {getStatusLabel(status, copy, currentRentalPeriod)}
          </Typography>
        </div>
      ),
      vin: car.vin,
      vehicle: `${car.year} ${car.make} ${car.model}`,
      driverFullName: get(driver, "user.fullName"),
      pickup: pickupDate,
      return: returnDate,
      rentalDays: `${getRentalDays} days`,
      rentalPeriod: `${pickupDate} - ${returnDate}`,
      startDate: get(rentalPeriods, "[0].startDate"),
      endDate: getEndDate(currentRentalPeriod, droppedOffAt),
      expiresIn: getRentalRequestExpiration(createdAt),
      netEarnings: netEarningsWithCommas,
      action: null,
      car: {
        id: car.id,
        year: car.year,
        make: car.make,
        model: car.model
      },
      carPhoto: get(car, "photos[0].file.url"),
      driverFirstName: get(driver, "user.firstName"),
      driverLastName: get(driver, "user.lastName"),
      driverPhoto: get(driver, "user.profilePhoto.url"),
      driver: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            alt="Driver"
            src={get(driver, "user.profilePhoto.url")}
            style={{ width: "30px", height: "30px" }}
          />
          <span style={{ marginLeft: "16px" }}>
            {get(driver, "user.fullName", "No Name")}
          </span>
        </div>
      ),
      yearMakeModel: `${car.year} ${car.make} ${car.model}`,
      driverImg: (
        <>
          {get(driver, "user.profilePhoto.url") && (
            <img alt="Driver" src={get(driver, "user.profilePhoto.url")} />
          )}
        </>
      ),
      driverId: driver.id
    };
  });
};

const useLoadMore = (loading, error, fetchMore, pageInfo) => {
  const [fetchingMore, updateFetchingMore] = useState(false);
  const { hasNextPage, endCursor } = pageInfo;
  const first = 10;

  const fetchMoreData = () => {
    if (!loading && !error) {
      if (hasNextPage) {
        updateFetchingMore(true);
        fetchMore({
          variables: {
            first: first,
            after: endCursor
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const previousEdges =
              previousResult.viewer.me.owner.rentalsConnection.edges;
            const previousPageInfo =
              previousResult.viewer.me.owner.rentalsConnection.pageInfo;
            const nextEdges = optionalChaining(
              () => fetchMoreResult.viewer.me.owner.rentalsConnection.edges
            );
            const nextPageInfo = optionalChaining(
              () => fetchMoreResult.viewer.me.owner.rentalsConnection.pageInfo
            );
            const mergedEdges = previousEdges.concat(nextEdges);

            fetchMoreResult.viewer.me.owner.rentalsConnection.edges = mergedEdges;
            fetchMoreResult.viewer.me.owner.rentalsConnection.pageInfo = nextPageInfo
              ? nextPageInfo
              : previousPageInfo;

            return {
              ...fetchMoreResult
            };
          }
        })
          .then(() => {
            updateFetchingMore(false);
          })
          .catch(() => {
            updateFetchingMore(false);
          });
      }
    }
  };

  return {
    fetchingMore,
    fetchMoreData
  };
};

export const useRentalsTable = options => {
  const { data, loading, error, fetchMore } = useQuery(RENTALS_QUERY, options);
  const mappedData = useMappedRentalData(data);
  const pageInfo = get(data, "viewer.me.owner.rentalsConnection.pageInfo", {});
  const { fetchingMore, fetchMoreData } = useLoadMore(
    loading,
    error,
    fetchMore,
    pageInfo
  );

  return {
    data,
    mappedData,
    pageInfo,
    loading,
    error,
    fetchingMore,
    fetchMoreData
  };
};
