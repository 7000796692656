import { gql } from "@apollo/client";

import { CarFragments } from "../../Queries/Car/CarFragments";

export const OwnerMutationFragments = {
  ownerFleetUpdateCarsPayload: gql`
    fragment OwnerFleetUpdateCarsPayload on FleetUpdateCarsPayload {
      cars {
        ...CarInfo
        location {
          ...CarLocation
        }
        photos {
          ...CarPhoto
        }
        documents {
          ...CarDocument
        }
      }
    }
    ${CarFragments.carInfo}
    ${CarFragments.carLocation}
    ${CarFragments.carPhoto}
    ${CarFragments.carDocument}
  `
};
