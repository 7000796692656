import React, { useContext } from "react";
import { reduxForm, reset, formValueSelector } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsDamageController";
import { useClaimsDamageFormController } from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";
import { DamageSelectRentalFormFields } from "Components/Forms/FormFields/Claims/DamageSelectRentalFormFields";
import { RouteEnum } from "Enums/RouteEnum";

const ClaimsDamageSelectRentalForm = compose(
  reduxForm({
    form: "CLAIMS_DAMAGE",
    destroyOnUnmount: false
  }),
  connect(null, { reset })
)(({ handleSubmit, prevStep, options, loading }) => {
  const theme = useTheme();
  const noOptions = options.length === 0;

  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      header="Select Rental"
      nextStepLabel="continue"
      nextStepId="continue-select-rental"
      previousStepLabel="Back"
      previousStep={prevStep}
      previousStepId="back-select-rental"
      disabled={loading || noOptions}
    >
      {loading ? (
        <>
          <Skeleton width="50%" />
          <Skeleton width="40%" />
          <Skeleton
            variant="rectangular"
            height={theme.spacing(7)}
            style={{
              marginTop: theme.spacing(3)
            }}
          />
        </>
      ) : (
        <>
          {noOptions ? (
            <Typography component="h6" variant="h6">
              You do not have any eligible rentals for a claim.
              <br />
            </Typography>
          ) : (
            <DamageSelectRentalFormFields options={options} />
          )}
        </>
      )}
    </FlowContentContainer>
  );
});

export const ClaimsDamageSelectRental = connect(state => ({
  rentalId: formValueSelector("CLAIMS_DAMAGE")(state, "rentalId")
}))(({ options, loading, rentalId }) => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const { initialValues, validate } = useClaimsDamageFormController();

  const leftComponent = (
    <ClaimsDamageSelectRentalForm
      initialValues={initialValues}
      validate={validate}
      onSubmit={nextStep}
      prevStep={prevStep}
      options={options}
      loading={loading}
    />
  );

  const rightComponent = (
    <TooltipContainer header="Helpful tips">
      <Tooltip
        header="What is a damage claim?"
        paragraphs={[
          "A damage claim is for anything related to actual physical damages to your car such as dents, missing parts, large scrapes, etc."
        ]}
      />
      <Tooltip
        header="Where can I report non-damage claims?"
        paragraphs={[
          props => (
            <div {...props}>
              Non-damage claims (Cleaning, Gas, Impound Fees, Late Return,
              Mileage, Other) must be reported within 24 hours of Check-out
              using a separate form. You can submit a Request for Reimbursement{" "}
              <Link
                component={RouterLink}
                to={{
                  pathname: `${RouteEnum.claimsReimbursment}`,
                  state: { id: rentalId }
                }}
                underline="hover"
              >
                HERE
              </Link>
              .
            </div>
          )
        ]}
      />
      <Tooltip
        header="Why don’t I see the rental I’m looking for?"
        paragraphs={[
          "You will only be able to submit claims for rentals that ended within the past 24 hours. If you believe there is an error, please contact HyreCar Support.",
          <>
            Need Help? Watch our Help Video{" "}
            <Link
              href={
                "https://www.youtube.com/watch?v=E7gKUZhxaaI&ab_channel=HyreCar"
              }
              target="_blank"
              rel="noreferrer"
              underline="hover"
            >
              {"Help video"}
            </Link>
          </>
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
});

ClaimsDamageSelectRental.propTypes = {
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool
};
