import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";

import { RouteEnum } from "Enums/RouteEnum";

const useStyles = makeStyles(theme => ({
  myMessagesContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(3)
  },
  myMessagesTitle: {
    margin: 0,
    fontSize: theme.spacing(3),
    fontWeight: "normal"
  },
  myMessagesLink: {
    lineHeight: 0,
    marginLeft: theme.spacing(2)
  },
  myMessagesSetting: {
    color: "#606770",
    fontSize: theme.spacing(3)
  }
}));

export const InboxChannelsHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.myMessagesContainer}>
      <Typography
        gutterBottom
        component="h2"
        className={classes.myMessagesTitle}
      >
        My Messages
      </Typography>
      <Link to={RouteEnum.settingsInbox} className={classes.myMessagesLink}>
        <SettingsIcon className={classes.myMessagesSetting} />
      </Link>
    </div>
  );
};
