import {
  MESSAGING_MODAL_HIDE,
  MESSAGING_MODAL_SHOW
} from "../actions/messagingModalActions";

const defaultState = {
  show: false,
  channelId: null
};

export default (state = { ...defaultState }, action) => {
  const payload = action.payload || {};
  switch (action.type) {
    case MESSAGING_MODAL_HIDE:
      return {
        ...defaultState
      };
    case MESSAGING_MODAL_SHOW:
      return {
        ...defaultState,
        ...state,
        show: true,
        ...payload
      };
    default:
      return state;
  }
};
