import React from "react";
import Checkbox from "@mui/material/Checkbox";

import { FormSelectAutocomplete } from "Components/Inputs/FormSelectAutocomplete";

export const FormSelectCheckbox = props => (
  <FormSelectAutocomplete
    {...props}
    multiple
    disableCloseOnSelect
    getOptionLabel={option => option.label}
    getOptionSelected={(option, value) => option.value === value.value}
    renderOption={(props, { label }, { selected }) => {
      return (
        <li {...props}>
          <Checkbox checked={selected} />
          {label}
        </li>
      );
    }}
  />
);
