import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CarStatusEnum, CarVerificationEnum } from "Enums/StateEnums";
import { FLEET_LISTINGS_DOCS } from "Queries/Fleet/FleetQueries";
import ExpiredDocumentsCard from "./ExpiredDocumentsCard";

const ExpiredDocumentsCardContainer = () => {
  const [cars, setCars] = useState([]);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(FLEET_LISTINGS_DOCS, {
    variables: {
      where: {
        verification: CarVerificationEnum.verified,
        status_in: [CarStatusEnum.rented, CarStatusEnum.available]
      }
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    const edges = data?.viewer?.me?.owner?.cars?.edges;
    !loading && data && setCars(edges && [...edges.map(edge => edge.node)]);
  }, [data, loading]);

  return <ExpiredDocumentsCard loading={loading} data={cars} />;
};

export default ExpiredDocumentsCardContainer;
