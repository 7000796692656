import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Card } from "Components/Cards/Card";
import { postponeOverpricedCarAlert } from "API/restAPI";
import PostponeAlertModal from "Components/Dialog/PostponeAlertModal";
import { useSnackbar } from "notistack";
import { useQuery } from "@apollo/client";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { get } from "lodash";
import { OverpricedCarsContext } from "Components/Utils/OverpricedCarsProvider";
import { useOverpricedCarEventTracking } from "Components/Utils/OverpricedCarEventTrackingProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import useOverpricedCarAlert from "Components/hooks/useOverpricedCarAlert";

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    textTransform: "none"
  },
  box: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const OverpricedCarAlert = ({
  minRecommendedPrice,
  maxRecommendedPrice,
  averageEarning
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-only"
  });
  const userId = get(user, "viewer.id", {});
  const { refetchPriceAlert, overpricedCars } = useContext(
    OverpricedCarsContext
  );
  const { marketingOrigin } = useOverpricedCarAlert();

  const { updateEventData } = useOverpricedCarEventTracking();

  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const handleShowLater = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalSave = async dismissalPeriod => {
    try {
      const response = await postponeOverpricedCarAlert(
        userId,
        dismissalPeriod
      );
      if (response.status === 200) {
        enqueueSnackbar("Pricing recommendations paused.", {
          variant: "success",
          autoHideDuration: 5000
        });
      }
    } catch (error) {
      enqueueSnackbar("Failed to mute pricing alert. Please try Again", {
        variant: "error",
        autoHideDuration: 5000
      });
    }
    await refetchPriceAlert();
    setModalOpen(false);
  };

  const handleReviewCarListing = () => {
    updateEventData("reviewCarPricingClicked", true);

    analytics.track(AnalyticsEvents.label.owner.reviewPrice, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.buttonClicked,
      label: AnalyticsEvents.label.owner.reviewPrice,
      property: JSON.stringify({
        overpricedCars: overpricedCars.data,
        marketingOrigin: marketingOrigin
      }),
      value: "",
      context: "OverpricedCarAlert"
    });

    history.push("/fleet/dashboard/listings/all");
  };

  const userName = get(user, "viewer.me.firstName", null);

  const overpricedCarsCount = overpricedCars?.data?.filter(
    car => car.overpriced
  )?.length;
  const carsNotRentedText =
    overpricedCarsCount > 1
      ? "It looks like several of your cars hasn't been rented yet."
      : "It looks like no one has rented your car yet.";

  return (
    <Card>
      <Typography pb={2} variant="h6" color="textSecondary">
        Hey {userName}!
      </Typography>
      <Typography variant="body1" paragraph>
        {carsNotRentedText}
      </Typography>
      <Typography variant="body1" paragraph>
        Did you know that cars in your area are being rented from $
        {minRecommendedPrice} - ${maxRecommendedPrice}? And average earnings for
        owners is ${averageEarning} every month. Looking to make money? Optimize
        your price now.
      </Typography>
      <Box className={classes.box}>
        <Button
          variant="outlined"
          onClick={handleShowLater}
          className={classes.button}
        >
          Remind me Later
        </Button>
        <Button
          variant="contained"
          onClick={handleReviewCarListing}
          className={classes.button}
        >
          Review Car Price
        </Button>
      </Box>
      <PostponeAlertModal
        open={modalOpen}
        handleClose={handleModalClose}
        handleSave={handleModalSave}
      />
    </Card>
  );
};

export default OverpricedCarAlert;
