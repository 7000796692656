import React, { useState, useContext, useEffect } from "react";
import {
  reduxForm,
  FieldArray,
  Field,
  getFormSyncErrors,
  getFormAsyncErrors
} from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { default as MuiTableCell } from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import get from "lodash/get";
import { useSnackbar } from "notistack";

import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { StepperContext } from "./CSVController";
import { useCSVFormController } from "Components/Forms/FormControllers/hooks/useCSVFormController";

const useStyles = makeStyles(theme => ({
  inputBase: {
    width: "100%"
  },
  container: {
    maxHeight: "450px",
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tableHead: {
    background: theme.palette.common.white
  },
  tableCell: {
    position: "relative",
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tableCellError: {
    borderBottom: `2px solid ${theme.palette.error.main}`
  },
  errorTooltipContainer: {
    position: "absolute",
    background: "#FCE7E7",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    color: theme.palette.error.main,
    zIndex: 1
  },
  inputError: {
    color: theme.palette.error.main
  }
}));

const columns = [
  {
    label: "VIN",
    value: "vin"
  },
  {
    label: "Odometer",
    value: "odometer"
  },
  {
    label: "Color",
    value: "color"
  },
  {
    label: "Plate (Optional)",
    value: "plate"
  },
  {
    label: "Description (Optional)",
    value: "description"
  }
];

const TableCell = ({ input, meta }) => {
  const classes = useStyles();
  const { touched, error, asyncValidating } = meta;
  const { name, value, onChange, onBlur, onFocus } = input;
  const [errorPopperOpen, setErrorPopperOpen] = useState(false);

  useEffect(() => {
    onBlur();
  }, [onBlur]);

  const handleChange = e => {
    onChange(e);
    setErrorPopperOpen(false);
  };

  const handleMouseEnter = e => {
    e.preventDefault();
    if (error && touched) setErrorPopperOpen(true);
  };

  const handleMouseLeave = e => {
    e.preventDefault();
    setErrorPopperOpen(false);
  };

  return (
    <MuiTableCell
      className={classNames(classes.tableCell, {
        [classes.tableCellError]: error && touched
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <InputBase
        value={value}
        onChange={handleChange}
        inputProps={{ name, onBlur, onFocus }}
        className={classNames({ [classes.inputError]: error && touched })}
        endAdornment={
          asyncValidating ? (
            <InputAdornment>
              <CircularProgress color="primary" size={25} />
            </InputAdornment>
          ) : null
        }
      />
      {errorPopperOpen && (
        <Grow in={errorPopperOpen}>
          <div className={classes.errorTooltipContainer}>
            <div>{error}</div>
          </div>
        </Grow>
      )}
    </MuiTableCell>
  );
};

const RenderCarsTable = ({ fields, columns }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteRow = index => () => {
    if (fields.length === 1)
      enqueueSnackbar("Must have at least 1 vehicle", { variant: "error" });
    else fields.remove(index);
  };

  return fields.map((car, index) => {
    return (
      <TableRow key={car.id}>
        <MuiTableCell className={classNames(classes.tableCell)}>
          <IconButton onClick={handleDeleteRow(index)} size="large">
            <DeleteForeverIcon />
          </IconButton>
        </MuiTableCell>
        {columns.map(({ value }) => (
          <Field
            key={`${car.id}.${value}`}
            name={`${car}.${value}`}
            component={TableCell}
          />
        ))}
      </TableRow>
    );
  });
};

const CSVTableFormFields = ({ columns }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <MuiTableCell className={classes.tableHead} align="center">
              <DeleteForeverIcon />
            </MuiTableCell>
            {columns.map(({ label }) => (
              <MuiTableCell key={label} className={classes.tableHead}>
                {label}
              </MuiTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            name="cars"
            component={RenderCarsTable}
            columns={columns}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CSVTableForm = compose(
  reduxForm({
    form: "CSV_IMPORT",
    destroyOnUnmount: false
  }),
  connect((state, { form }) => ({
    syncErrors: getFormSyncErrors(form)(state),
    asyncErrors: getFormAsyncErrors(form)(state)
  }))
)(({ handleSubmit, prevStep, syncErrors, asyncErrors, asyncValidating }) => {
  const errorCount = get(syncErrors, "cars", [])
    .concat(get(asyncErrors, "cars", []))
    .reduce((acc, curr) => {
      if (curr) acc += Object.keys(curr).length;
      return acc;
    }, 0);

  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      previousStep={prevStep}
      error={
        errorCount > 0
          ? `${errorCount} error${errorCount > 1 ? "s" : ""} left`
          : null
      }
      disabled={asyncValidating}
      loading={asyncValidating}
    >
      <CSVTableFormFields columns={columns} />
    </FlowContentContainer>
  );
});

export const CSVTableContainer = () => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const {
    validate,
    asyncValidate,
    shouldAsyncValidate
  } = useCSVFormController();

  return (
    <ColumnFlowLayout
      leftComponent={
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h5">
                <b> Fix errors</b>
              </Typography>
              <Typography gutterBottom>
                Please resolve all of the missing information or errors marked
                in red.
                <br />
                Hover over a cell for details about the error. Click a cell to
                edit it.
              </Typography>
            </Grid>
          </Grid>
          <CSVTableForm
            onSubmit={nextStep}
            prevStep={prevStep}
            validate={validate}
            asyncValidate={asyncValidate}
            shouldAsyncValidate={shouldAsyncValidate}
          />
        </>
      }
    />
  );
};

TableCell.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
};

CSVTableFormFields.propTypes = {
  columns: PropTypes.array
};
