/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import capitalize from "lodash/capitalize";

import { resetSearch, onSearchChange } from "Redux/Header/ActionCreators";
import { useTableState } from "Components/Tables/hooks/useTableState";
import { useTransactionsTable } from "Queries/Transactions/hooks/useTransactionsTable";
import { Table } from "Components/Tables/Table";
import { TableHead } from "Components/Tables/TableHead";
import { transactionsTooltipEnum } from "Enums/DashboardTooltipEnums";
import { ExportTransactionsDrawer } from "Components/Drawers/ExportTransactionsDrawer";
import { MobileTableRow } from "Components/Tables/MobileTable/MobileTableRow";
import {
  JoyrideController,
  JoyrideTutorialEnum
} from "Components/Joyride/JoyrideController";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { TransactionsDateFilter } from "Fleet/Dashboard/DashboardViews/Financials/Transactions/TransactionsDateFilter";
import { useDebounce } from "Utils/useDebounce";
import LoadingTransactionsComponent from "../Tables/LoadingComponent";
import EmptyPlaceholder from "../Tables/EmptyPlaceholder";
import ErrorComponentController from "../Tables/ErrorController";
import DetailedInfoDrawer from "./DetailedInfoDrawer";

import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { usePageQueryAnalytics } from "Analytics/Custom/usePageQueryAnalytics";
import SearchInput from "Components/Inputs/SearchInput";

const columns = [
  {
    title: "Available On",
    field: "transferDate"
  },
  {
    title: "Transaction Date",
    field: "paymentDate"
  },
  {
    title: "Description",
    field: "description"
  },
  {
    title: "Type",
    field: "rentalPeriodType"
  },
  {
    title: "Net Earnings",
    field: "netEarnings",
    tooltip: transactionsTooltipEnum.earnings.text
  },
  {
    title: "List Price",
    field: "listedPrice",
    tooltip: transactionsTooltipEnum.basePrice.text
  },
  {
    title: "Protection Fee",
    field: "protectionPlanFee",
    tooltip: transactionsTooltipEnum.protectionFee.text
  },
  {
    title: "Plan",
    field: "protectionPlan"
  }
];

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  payoutRow: {
    padding: theme.spacing(2)
  },
  mobileFab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: "50%",
    transform: "translateX(-50%)"
  },
  inputsContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  searchInput: {
    marginBottom: theme.spacing(2)
  },
  mobileEmptyRow: {
    paddingTop: "40px"
  }
}));

export const Transactions = connect(
  state => ({
    headerSearch: state.fleet.header.search,
    dateFilters: get(state, "fleet.financials.transactions")
  }),
  { resetSearch, onSearchChange }
)(({ headerSearch, resetSearch, dateFilters, onSearchChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMdOnly = useMediaQuery(theme.breakpoints.only("md"));
  const { search, handleSearchChange } = useTableState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [lineItemDrawerOpen, setLineItemDrawerOpen] = useState(false);
  const [currentLineItem, setCurrentLineItem] = useState({});
  const debouncedSearchTerm = useDebounce(search, 500);

  const { data, error, loading } = useTransactionsTable(
    matches ? headerSearch : debouncedSearchTerm,
    dateFilters.startDate,
    dateFilters.endDate
  );

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  const handleLineItemClick = lineItem => () => {
    setCurrentLineItem(lineItem);
    setLineItemDrawerOpen(true);
  };

  useEffect(() => resetSearch, [resetSearch]);
  usePageQueryAnalytics({
    successLabel: AnalyticsEvents.label.owner.viewTransactions,
    errorLabel: AnalyticsEvents.label.owner.viewTransactionsError,
    data,
    error
  });

  const noData = data?.length === 0 && !loading && !error;
  const isLoading = loading && !error;
  const hasData = data && data.length && !loading && !error;
  return (
    <>
      <JoyrideController tutorial={JoyrideTutorialEnum.financials} />
      <ExportTransactionsDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        selectedDate={moment()}
        dateFilters={dateFilters}
      />
      {matches ? (
        <>
          <div className={classes.inputsContainer}>
            <div className={classes.searchInput}>
              <SearchInput value={headerSearch} update={onSearchChange} />
            </div>
            <TransactionsDateFilter />
          </div>
          {error ? <ErrorComponentController errorResponse={error} /> : null}
          {isLoading ? (
            <LoadingTransactionsComponent subHeader=" This may take a bit longer for large date ranges or owners with multiple cars." />
          ) : null}
          {noData ? (
            <Grid item className={classes.mobileEmptyRow} xs={12}>
              <EmptyPlaceholder
                header="No Transactions"
                subHeader="Try expanding your date range or changing your search terms."
              />
            </Grid>
          ) : null}
          {hasData
            ? data.map((datum, index) =>
                datum.type === "Payout" ? (
                  <>
                    <Grid container className={classes.payoutRow} key={index}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">
                          {`${datum.description} ${datum.transferDate}`}
                        </Typography>
                      </Grid>
                      <Grid container item xs={6} justifyContent="flex-end">
                        <Typography variant="subtitle1">
                          {datum.earnings}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                ) : (
                  <MobileTableRow
                    title={datum.description}
                    subtitle={datum.paymentDate}
                    onClick={handleLineItemClick(datum)}
                    key={index}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {capitalize(get(datum, "rentalPeriod.type", ""))}
                        </Typography>
                      </Grid>
                      <Grid container item xs={8} justifyContent="flex-end">
                        <Typography variant="body2">
                          {get(datum, "earnings")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MobileTableRow>
                )
              )
            : null}
          <DetailedInfoDrawer
            open={lineItemDrawerOpen}
            onClose={() => setLineItemDrawerOpen(false)}
            item={currentLineItem}
          />
          <Fab
            variant="extended"
            color="primary"
            onClick={handleDrawerOpen}
            className={classes.mobileFab}
          >
            <SendIcon className={classes.extendedIcon} />
            Export
          </Fab>
        </>
      ) : (
        <Paper>
          <TableHead
            tableName="Transactions"
            search={search}
            onSearchChange={handleSearchChange}
            searchPlaceholder={
              matchesMdOnly ? "Search" : "Search Make, Model, Year, Name"
            }
            loading={loading}
            buttonComponent={
              <Fab
                id={joyrideTutorialIds.tableFab}
                variant="extended"
                color="primary"
                onClick={handleDrawerOpen}
              >
                <SendIcon className={classes.extendedIcon} />
                Export
              </Fab>
            }
            inputComponents={[<TransactionsDateFilter />]}
          />
          <Table
            columns={columns}
            data={data}
            loading={loading}
            error={error}
            errorComponent={() => (
              <ErrorComponentController errorResponse={error} />
            )}
            loadingComponent={() => (
              <LoadingTransactionsComponent subHeader=" This may take a bit longer for large date ranges or owners with multiple cars." />
            )}
            noDataComponent={
              <EmptyPlaceholder
                header="No Transactions"
                subHeader="Try expanding your date range or changing your search terms."
              />
            }
            paginate
            noDataCellLabel="-"
            disableHover
            disableRowPointer
            extended
            tableGrows
          />
        </Paper>
      )}
    </>
  );
});
