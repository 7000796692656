import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  circleProgress: {
    padding: theme.spacing(1)
  },
  warning: {
    "& .MuiFilledInput-underline:before": {
      borderBottom: `2px solid ${theme.palette.warning.main}`
    }
  },
  warningLabel: {
    color: `${theme.palette.warning.main} !important`
  },
  warningHelperText: {
    color: theme.palette.warning.main,
    lineHeight: "1.2em"
  },
  skeleton: props => ({
    // match height if multiline text field
    height: theme.spacing(props.rows ? 7 + (props.rows - 1) * 2.375 : 7),
    width: "100%"
  })
}));

export const FormTextField = ({
  label,
  placeholder,
  input,
  meta: { asyncValidating, error, invalid, touched, warning },
  disabled,
  helperText,
  inputComponent,
  endAdornment,
  showSkeleton,
  characterLimit,
  ...custom
}) => {
  const classes = useStyles({ rows: custom.rows });

  if (characterLimit && input.value.length) {
    if (input.value.length > characterLimit) {
      input.onBlur();
    }
    helperText = `${characterLimit - input.value.length} characters left`;
  }

  return showSkeleton ? (
    <Skeleton
      variant="rectangular"
      classes={{ rectangular: classes.skeleton }}
    />
  ) : (
    <TextField
      label={label}
      placeholder={placeholder}
      error={touched && invalid}
      helperText={touched && error ? error : helperText || (warning && warning)}
      disabled={disabled}
      InputProps={{
        endAdornment: asyncValidating ? (
          <InputAdornment position="end">
            <CircularProgress className={classes.circleProgress} />
          </InputAdornment>
        ) : endAdornment ? (
          endAdornment
        ) : null,
        inputComponent
      }}
      InputLabelProps={{
        classes: {
          root: warning ? classes.warningLabel : null
        }
      }}
      FormHelperTextProps={{
        classes: { root: warning ? classes.warningHelperText : null }
      }}
      classes={{
        root: warning ? classes.warning : null
      }}
      {...input}
      {...custom}
    />
  );
};

FormTextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    error: PropTypes.string,
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    warning: PropTypes.string
  }),
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  inputComponent: PropTypes.any,
  endAdornment: PropTypes.node,
  showSkeleton: PropTypes.bool,
  characterLimit: PropTypes.number
};
