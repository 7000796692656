import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";

import { RouterDrawer } from "Components/Drawers/RouterDrawer";
import { ListingSummary } from "Components/Drawers/ListingDrawer/ListingSummary";
import { ListingProtectionPlan } from "Components/Drawers/ListingDrawer/ListingProtectionPlan";
import { ListingEdit } from "Components/Drawers/ListingDrawer/ListingEdit";
import { ListingStatusEdit } from "Components/Drawers/ListingDrawer/ListingStatusEdit";
import { DrawerContainer } from "Components/Drawers/DrawerContainer";

export const ListingDrawerInner = ({
  carId,
  handleClose,
  setStep,
  step,
  useBackButton
}) => {
  const [activeTab, setActiveTab] = useState("");
  const sendToListingSummary = () => setStep(0);
  const sendToListingProtectionPlan = () => setStep(1);
  const sendToListingEdit = (activeTab = null) => {
    setActiveTab(activeTab);
    setStep(2);
  };

  const sendToListingStatusEdit = () => setStep(3);

  const getCurrentComponent = handleClose => {
    switch (step) {
      case 0:
        return (
          <ListingSummary
            handleClose={handleClose}
            sendToListingProtectionPlan={sendToListingProtectionPlan}
            sendToListingEdit={sendToListingEdit}
            sendToListingStatusEdit={sendToListingStatusEdit}
            carId={carId}
            useBackButton={useBackButton ? useBackButton : false}
          />
        );
      case 1:
        return (
          <ListingProtectionPlan onClose={sendToListingSummary} carId={carId} />
        );
      case 2:
        return (
          <ListingEdit
            sendToListingSummary={sendToListingSummary}
            carId={carId}
            activeTab={activeTab}
          />
        );
      case 3:
        return (
          <ListingStatusEdit onClose={sendToListingSummary} carId={carId} />
        );
      default:
        return <ListingSummary handleClose={handleClose} carId={carId} />;
    }
  };

  return <DrawerContainer>{getCurrentComponent(handleClose)}</DrawerContainer>;
};

ListingDrawerInner.propTypes = {
  handleClose: PropTypes.func.isRequired,
  carId: PropTypes.string,
  useBackButton: PropTypes.bool
};

export const ListingDrawer = () => {
  const location = useLocation();
  const [step, setStep] = useState(location?.state?.openListingStep || 0);

  return (
    <>
      <RouterDrawer shouldWarnUnsavedChanges={step === 2}>
        {({ handleClose }) => (
          <ListingDrawerInner
            handleClose={handleClose}
            step={step}
            setStep={setStep}
          />
        )}
      </RouterDrawer>
    </>
  );
};
