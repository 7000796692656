/* eslint-disable react/display-name */
import React from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Badge } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper
  },
  active: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: "inline-block"
  },
  button: {
    lineHeight: 4,
    whiteSpace: "nowrap"
  },
  badge: {
    top: "20px",
    right: "-22px"
  }
}));

const Adapter = React.forwardRef((props, ref) => {
  return <Link innerRef={ref} {...props} />;
});

export const TabsWithRoutes = ({ paths }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      <Container fixed maxWidth="lg" id={joyrideTutorialIds.tabsWithRoutes}>
        <Grid container justifyContent="flex-start">
          {paths.map((path, key) => {
            return path.disabled ? null : (
              <Grid
                item
                lg={2}
                md={3}
                xs={4}
                key={key}
                className={classNames({
                  [classes.active]: pathname === path.to
                })}
              >
                <Button
                  component={Adapter}
                  to={path.to}
                  className={classes.button}
                  color={pathname === path.to ? "primary" : "inherit"}
                  fullWidth
                >
                  {path.badgeContent ? (
                    <Badge
                      color={path.color ? path.color : "primary"}
                      badgeContent={path.badgeContent}
                      classes={{
                        badge: classes.badge
                      }}
                    >
                      {path.label}
                    </Badge>
                  ) : (
                    path.label
                  )}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Divider />
    </div>
  );
};

TabsWithRoutes.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      badgeContent: PropTypes.number
    })
  )
};
