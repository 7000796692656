import React from "react";
import { reduxForm } from "redux-form";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { DrawerContainer } from "Components/Drawers/DrawerContainer";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { ExportTransactionsFormController } from "Components/Forms/FormControllers/ExportTransactionsFormController";
import { ExportTransactionsFormFields } from "Components/Forms/FormFields/ExportTransactionsFormFields";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      width: "350px"
    }
  },
  drawer: {
    zIndex: 1250
  }
}));

const ExportTransactionsForm = reduxForm({
  form: "TRANSACTION_EXPORT"
})(props => {
  const { handleSubmit, submitting, dateFilters, change, payoutIds } = props;
  return (
    <>
      <DrawerPaddingContainer>
        <ExportTransactionsFormFields
          change={change}
          dateFilters={dateFilters}
          payoutIds={payoutIds}
        />
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onSubmit={handleSubmit}
        submitText={submitting ? "Exporting" : "Export"}
        disabled={submitting}
      />
    </>
  );
});

export const ExportTransactionsDrawer = ({
  open,
  onClose,
  onOpen,
  selectedDate,
  startDate,
  endDate,
  dateFilters,
  payoutIds
}) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      open={open}
      onClose={onClose}
      anchor="right"
      onOpen={onOpen}
      className={classes.drawer}
    >
      <DrawerContainer classes={{ root: classes.root }}>
        <DrawerAppBar
          title="Export"
          icon={
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          }
          border
        />
        <ExportTransactionsFormController
          selectedDate={selectedDate}
          onSubmitSuccess={onClose}
          startDate={startDate}
          endDate={endDate}
          payoutIds={payoutIds}
        >
          {props => (
            <ExportTransactionsForm
              dateFilters={dateFilters}
              payoutIds={payoutIds}
              {...props}
            />
          )}
        </ExportTransactionsFormController>
      </DrawerContainer>
    </SwipeableDrawer>
  );
};

ExportTransactionsDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};
