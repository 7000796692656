import React from "react";
import styled from "styled-components";

import { Button, CenteredFlexContainer, Modal } from "hyrecar-web-components";

import { Link } from "Driver/Navigation/index";

import SwitchFromDriverToOwnerButton from "./SwitchFromDriverToOwnerButton";
import { RouteEnum } from "Enums/RouteEnum";

const StyledModal = styled(Modal)`
  display: flex !important;
  .modal-dialog {
    align-self: center;
     }
  }
`;

export const SwitchFromDriverToOwnerModal = props => (
  <StyledModal show={props.show}>
    <SwitchFromDriverToOwner {...props} />
  </StyledModal>
);

export const SwitchFromDriverToOwner = ({ ...rest }) => {
  return (
    <CenteredFlexContainer {...rest}>
      <h4>You are currently signed in as a HyreCar Driver.</h4>
      This page is for Hyrecar Drivers, would you like to switch accounts and
      continue?
      <SwitchFromDriverToOwnerButton
        bsStyle={"success"}
        style={{ marginTop: "20px" }}
      >
        Yes,
      </SwitchFromDriverToOwnerButton>
      <Link to={RouteEnum.driverReservations} style={{ marginTop: 5 }}>
        <Button bsStyle="link-gray">Cancel, back to my Reservations </Button>
      </Link>
    </CenteredFlexContainer>
  );
};

export default SwitchFromDriverToOwnerModal;
