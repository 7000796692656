import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import queryString from "query-string";
import get from "lodash/get";
import toLower from "lodash/toLower";

import { RouteEnum } from "Enums/RouteEnum";
import { ThemeFunctionsContext } from "Styles/ThemeProvider";
import { UserTypeEnum } from "Enums/StateEnums";
import { LoginForm } from "./LoginFlow/LoginForm";
import { Logo } from "Components/Logo/Logo";
import { LoginFormController } from "Components/Forms/FormControllers/LoginFormController";
import { SignUpFormController } from "Components/Forms/FormControllers/SignUpFormController";
import { SignUpForm } from "Login/SignupFlow/SignUpForm";
import { ResetPasswordForm } from "Login/ResetPasswordFlow/ResetPasswordForm";
import { ConfirmResetPasswordForm } from "Login/ResetPasswordFlow/ConfirmResetPassword";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { AuthContext } from "./Auth";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

const LOGIN_FORM_SOURCE = {
  signUpForm: "signup form",
  resetPassword: "reset password",
  navbar: "navbar"
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "540px",
    margin: "72px auto 0",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%"
    }
  },
  inner: {
    padding: theme.spacing(4, 4, 7)
  },
  appBar: {
    ...theme.mixins.toolbar
  },
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white
  },
  navButtonsContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  navButton: {
    height: theme.spacing(8)
  },
  navButtonActive: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "0px"
  },
  imageContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  backgroundImageOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "#000000",
    opacity: "0.2"
  },
  logoContainer: {
    flexGrow: 1,
    color: "#fff",
    textDecoration: "none !important"
  },
  logo: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

const DEFAULT_USER_TYPE = UserTypeEnum.driver;

const LoginControllerInner = ({ defaultStep = 0, urlUserType }) => {
  const classes = useStyles();
  const [step, setStep] = useState(defaultStep);
  const [rpt, setRpt] = useState(null);
  const { getAsset } = useContext(FirebaseContext);
  const { setThemeToDriver, setThemeToFleet } = useContext(
    ThemeFunctionsContext
  );
  const { authUserType: userType, setUserType } = useContext(AuthContext);

  const setUserTypeOwner = () => setUserType(UserTypeEnum.owner);
  const setUserTypeDriver = () => setUserType(UserTypeEnum.driver);

  useEffect(() => {
    if (urlUserType) {
      setUserType(urlUserType);
    } else {
      setUserType(DEFAULT_USER_TYPE);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const rpt = urlParams.get("rpt");
    if (rpt) {
      setRpt(rpt);
      setStep(3);
    }
  }, [urlUserType]);

  const sendToLogin = source => {
    analytics.track(AnalyticsEvents.label.auth.logInSelected.legacyEventName, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.buttonClicked,
      label: AnalyticsEvents.label.auth.logInSelected.label,
      property: JSON.stringify({
        flow: AnalyticsEvents.flow.login,
        source
      }),
      value: "",
      context: ""
    });
    setStep(0);
  };

  const sendToSignUp = () => {
    analytics.track(AnalyticsEvents.label.auth.signUpSelected.legacyEventName, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.buttonClicked,
      label: AnalyticsEvents.label.auth.signUpSelected.label,
      property: JSON.stringify({
        flow: AnalyticsEvents.flow.registration
      }),
      value: "",
      context: ""
    });
    setStep(1);
  };
  const sendToForgotPassword = () => setStep(2);

  useEffect(() => {
    if (userType === UserTypeEnum.driver) {
      setThemeToDriver();
    } else {
      setThemeToFleet();
    }
  }, [userType, setThemeToFleet, setThemeToDriver]);

  const getCurrentComponent = () => {
    switch (step) {
      case 0:
        return (
          <LoginFormController>
            {props => (
              <LoginForm
                {...props}
                sendToForgotPassword={sendToForgotPassword}
                sendToSignUp={sendToSignUp}
                userType={userType}
              />
            )}
          </LoginFormController>
        );
      case 1:
        return (
          <SignUpFormController userType={userType}>
            {props => (
              <SignUpForm
                {...props}
                sendToLogin={() => sendToLogin(LOGIN_FORM_SOURCE.signUpForm)}
                userType={userType}
                setUserTypeDriver={setUserTypeDriver}
                setUserTypeOwner={setUserTypeOwner}
              />
            )}
          </SignUpFormController>
        );
      case 2:
        return (
          <ResetPasswordForm
            sendToLogin={() => sendToLogin(LOGIN_FORM_SOURCE.resetPassword)}
          />
        );
      case 3:
        return <ConfirmResetPasswordForm rpt={rpt} />;
      default:
        return (
          <LoginFormController>
            {props => <LoginForm {...props} />}
          </LoginFormController>
        );
    }
  };

  return (
    <>
      <Hidden smDown>
        <div className={classes.imageContainer}>
          <img
            src={getAsset("loginBackground")}
            className={classes.backgroundImage}
            alt="Login"
          />
          <div className={classes.backgroundImageOverlay} />
        </div>
      </Hidden>
      <AppBar>
        <ToolBar>
          <div className={classes.logoContainer}>
            <Logo goToLanding className={classes.logo} />
          </div>
          <Button
            className={classes.button}
            onClick={() => sendToLogin(LOGIN_FORM_SOURCE.navbar)}
            variant="outlined"
          >
            Log In
          </Button>
        </ToolBar>
      </AppBar>
      <div className={classes.appBar} />
      <Paper className={classes.root}>
        {step !== 2 && step !== 3 && (
          <Grid container className={classes.navButtonsContainer}>
            <Grid item xs={6}>
              <Button
                onClick={setUserTypeDriver}
                fullWidth
                className={classNames(classes.navButton, {
                  [classes.navButtonActive]: userType === UserTypeEnum.driver
                })}
                color={userType === UserTypeEnum.driver ? "primary" : "inherit"}
              >
                Driver
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={setUserTypeOwner}
                fullWidth
                className={classNames(classes.navButton, {
                  [classes.navButtonActive]: userType === UserTypeEnum.owner
                })}
                color={userType === UserTypeEnum.owner ? "primary" : "inherit"}
              >
                Owner
              </Button>
            </Grid>
          </Grid>
        )}
        <div className={classes.inner}>{getCurrentComponent()}</div>
      </Paper>
    </>
  );
};

LoginControllerInner.propTypes = {
  urlUserType: PropTypes.string,
  defaultStep: PropTypes.number
};

export const LoginController = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const urlUserType = get(parsed, "type");
  const form = toLower(get(parsed, "form"));
  let defaultStep;

  switch (form) {
    case "register":
    case "signup":
      defaultStep = 1;
      break;
    case "login":
      defaultStep = 0;
      break;
    case "forgotpassword":
      defaultStep = 2;
      break;
    default:
      defaultStep = undefined;
      break;
  }

  return (
    <Switch>
      {location.pathname !== RouteEnum.login && (
        <Redirect
          to={{
            pathname: RouteEnum.login
          }}
        />
      )}
      <Route
        path={RouteEnum.login}
        render={() => {
          return (
            <LoginControllerInner
              urlUserType={
                urlUserType === UserTypeEnum.driver.toLowerCase() ||
                urlUserType === UserTypeEnum.owner.toLowerCase()
                  ? urlUserType.toUpperCase()
                  : undefined
              }
              defaultStep={defaultStep}
            />
          );
        }}
      />
    </Switch>
  );
};
