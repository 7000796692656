import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { Image } from "Components/Images/Image";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(4)
  },
  spacingBottom: {
    marginBottom: theme.spacing(3)
  },
  extraAvatar: {
    fontSize: theme.spacing(2)
  },
  boldTitle: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium
  },
  imageOverride: {
    height: "100%"
  },
  content: {
    flexGrow: 1,
    display: "flex",
    padding: theme.spacing(3, 2.25)
  }
}));

export const RooftopCard = props => {
  const classes = useStyles(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const {
    imageSrc,
    defaultImageSrc,
    imageAlt,
    title,
    subTitle,
    rightTitle,
    content,
    avatars,
    buttonText,
    to,
    routeState,
    disabled
  } = props;

  const handleClick = () => {
    if (!disabled) history.push(to, routeState);
  };

  return (
    <Paper className={classes.root} onClick={matches ? handleClick : null}>
      <Grid container>
        <Grid item xs={3}>
          <Image
            src={imageSrc ? imageSrc : defaultImageSrc}
            objectFit="cover"
            alt={imageAlt}
            classes={{
              root: classes.imageOverride
            }}
          />
        </Grid>
        <Grid container item xs={9} className={classes.content}>
          <Grid item sm={8}>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              className={classes.boldTitle}
            >
              {title}
            </Typography>
            {!matches && (
              <Typography
                variant="body2"
                gutterBottom
                className={classes.spacingBottom}
              >
                {subTitle}
              </Typography>
            )}
            <div className={classes.spacingBottom}>{content}</div>
            {!matches && avatars && Array.isArray(avatars) && (
              <AvatarGroup>
                {avatars.slice(0, 3).map((avatar, index) => (
                  <Tooltip key={index} title={avatar.tooltip}>
                    <Avatar src={avatar.src} alt={avatar.tooltip} />
                  </Tooltip>
                ))}
                {avatars.length >= 3 && (
                  <Tooltip
                    title={avatars.slice(3).reduce((acc, avatar) => {
                      return acc + ` ${avatar.tooltip}`;
                    }, "")}
                  >
                    <Avatar className={classes.extraAvatar}>{`+${
                      avatars.slice(3).length
                    }`}</Avatar>
                  </Tooltip>
                )}
              </AvatarGroup>
            )}
          </Grid>
          {!matches && (
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
              item
              xs={4}
            >
              <Typography align="right" className={classes.boldTitle}>
                {rightTitle}
              </Typography>
              {!disabled && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                >
                  {buttonText}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

RooftopCard.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  defaultImageSrc: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      tooltip: PropTypes.string
    })
  ),
  content: PropTypes.node,
  buttonText: PropTypes.string,
  to: PropTypes.string,
  routeState: PropTypes.any,
  disabled: PropTypes.bool
};
