export const validate = values => {
  let errors = {};

  if (!values.color) {
    errors.color = "Required";
  }
  if (!values.mileage) {
    errors.mileage = "Required";
  }
  if (!values.licensePlate) {
    errors.licensePlate = "Required";
  }

  if (values.mileage && values.mileage < 0) {
    errors.mileage = "Must be at least 0";
  }
  if (!values.gps) {
    errors.gps = "Required";
  }

  return errors;
};
