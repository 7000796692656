import React, { useState, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import moment from "moment";
import get from "lodash/get";

import { FlowStepper } from "Components/Flows/FlowStepper";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { ClaimsReimbursmentSelectRental } from "./ClaimsReimbursmentSelectRental";
import { ClaimsReimbursmentPointOfContact } from "./ClaimsReimbursmentPointOfContact";
import { ClaimsReimbursmentDetails } from "./ClaimsReimbursmentDetails";
import { ClaimsReimbursmentImages } from "./ClaimsReimbursmentImages";
import { RENTALS_FOR_CLAIMS } from "Queries/Rentals/RentalQueries";
import { useClaimsReimbursementFormController } from "Components/Forms/FormControllers/hooks/useClaimsReimbursementFormController";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const steps = ["Select Rental", "Contact Info", "Details", "Documentation"];

export const StepperContext = React.createContext();

export const ClaimsReimbursmentController = connect()(({ dispatch }) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { onSubmit } = useClaimsReimbursementFormController(dispatch);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const droppedOffAt_lt = useMemo(() => moment().format(), []);
  const droppedOffAt_gt = useMemo(
    () =>
      moment()
        .subtract(24, "hours")
        .format(),
    []
  );
  const { data, loading } = useQuery(RENTALS_FOR_CLAIMS, {
    variables: {
      droppedOffAt_lt,
      droppedOffAt_gt
    },
    client: currentRooftopClient
  });
  const rentals = get(data, "viewer.me.owner.rentals", []);

  const options = rentals.map(rental => {
    const startDate = get(rental, "rentalPeriods[0].startDate");
    const endDate = get(rental, "currentRentalPeriod.endDate");
    const driverUser = get(rental, "driver.user");
    const car = rental.car;

    return {
      value: rental.id,
      label: `${moment(startDate).format("MMM D")} - ${moment(endDate).format(
        "MMM D, YYYY"
      )} / ${car.make} ${car.model} ${car.year} / ${driverUser.firstName} ${
        driverUser.lastName
      }`
    };
  });

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const prevStep = () => {
    if (step !== 0) {
      setStep(prevStep => prevStep - 1);
    } else {
      history.goBack();
    }
  };

  const getCurrentComponent = step => {
    switch (step) {
      case 0:
        return (
          <ClaimsReimbursmentSelectRental loading={loading} options={options} />
        );
      case 1:
        return <ClaimsReimbursmentPointOfContact />;
      case 2:
        return <ClaimsReimbursmentDetails />;
      case 3:
        return <ClaimsReimbursmentImages />;
      default:
        return <ClaimsReimbursmentSelectRental />;
    }
  };

  return (
    <StepperContext.Provider value={{ nextStep, prevStep, onSubmit }}>
      <DashboardLayout hasAppBar>
        <FlowStepper
          steps={steps}
          step={step}
          header="Request a Reimbursment (non-damage)"
        />
        {getCurrentComponent(step)}
      </DashboardLayout>
    </StepperContext.Provider>
  );
});
