import React from "react";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import Card from "@mui/material/Card";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: `0 1px 6px ${theme.palette.divider}`,
    minHeight: 500,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2.5),
    paddingBottom: 0,
    marginBottom: 0,
    width: "100%",
    maxWidth: 400,
    "&:hover": {
      background: theme.palette.primary[50],
      boxShadow: `0 1px 6px ${theme.palette.primary[200]}`,
      border: `2px solid ${theme.palette.primary[200]}`
    }
  },
  title: {
    lineHeight: 1.2
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  titleTextContainer: {
    display: "flex",
    flexDirection: "column"
  },
  selected: {
    border: `2px solid ${theme.palette.primary[500]}`,
    boxShadow: `0 1px 6px ${theme.palette.primary[500]}`,
    background: theme.palette.primary[50],
    "&:hover": {
      border: `2px solid ${theme.palette.primary[500]}`,
      boxShadow: `0 1px 6px ${theme.palette.primary[500]}`,
      background: theme.palette.primary[50]
    }
  },
  pointer: {
    cursor: "pointer"
  },
  selectionBoxContainer: {
    minHeight: 45
  },
  selectedContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const SelectionCard = ({
  title,
  subtitle,
  selected,
  onClick,
  pointer,
  children,
  id
}) => {
  const classes = useStyles();

  return (
    <Card
      id={id && id}
      className={classNames(classes.root, {
        [classes.selected]: selected,
        [classes.pointer]: pointer
      })}
      onClick={onClick}
      elevation={0}
    >
      <div className={classes.titleContainer}>
        {title && (
          <div className={classes.titleTextContainer}>
            <Typography component="span" variant="h6" className={classes.title}>
              {title}
            </Typography>
            {subtitle && (
              <Typography component="span" variant="body2">
                {subtitle}
              </Typography>
            )}
          </div>
        )}
        <div className={classes.selectionBoxContainer}>
          {selected && (
            <div className={classes.selectedContainer}>
              <CheckCircleIcon color="primary" />
              <Typography component="span" variant="subtitle2">
                Selected
              </Typography>
            </div>
          )}
        </div>
      </div>
      {children}
    </Card>
  );
};

export default SelectionCard;
