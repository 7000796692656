import React, { useContext } from "react";
import { reduxForm, Field, change } from "redux-form";
import { connect } from "react-redux";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperFunctions } from "../ManualContainer";
import {
  LocationAutoComplete,
  validateLocation
} from "Components/Forms/Location/LocationAutoComplete";
import { compose } from "recompose";

const LocationForm = compose(
  connect(state => ({
    initialValues: state.fleet.listing.initialFormValues
  })),
  reduxForm({
    form: "LIST_CAR_FLOW",
    validate: values => validateLocation(values, "address"),
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
)(({ handleSubmit, previousStep, submitting }) => {
  return (
    <FlowContentContainer
      header="Where will drivers pickup the car?"
      description="Before a rental starts, you and your driver will agree on a time and place to meet. It is recommended to provide a public address when doing the key exchange."
      nextStep={handleSubmit}
      nextStepLabel={submitting ? "Loading" : "Continue"}
      previousStep={previousStep}
      previousStepLabel="Back"
      disabled={submitting}
      nextStepId="continue-pickup-address"
      previousStepId="back-pickup-address"
    >
      <Field
        id="pickup-address-selection-field"
        name="address"
        component={LocationAutoComplete}
        showMap
        variant="filled"
      />
    </FlowContentContainer>
  );
});

export const Location = connect(null, { change })(() => {
  const { nextStep, previousStep } = useContext(StepperFunctions);

  const handleSubmit = () => nextStep();

  return (
    <ColumnFlowLayout
      leftComponent={
        <LocationForm onSubmit={handleSubmit} previousStep={previousStep} />
      }
      useBottomDrawer
    />
  );
});
