import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  subtitle: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  button: {
    minWidth: "265px"
  },
  image: {
    margin: `${theme.spacing(5)} auto ${theme.spacing(6)} auto`,
    display: "block"
  }
}));

export const NoDataComponent = ({ title, subtitle, buttons, image }) => {
  const classes = useStyles();

  return (
    <div>
      {image && <img src={image} alt="No Data" className={classes.image} />}
      {title && (
        <Typography
          component="h5"
          variant="h5"
          align="center"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          component="p"
          variant="subtitle1"
          align="center"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      )}
      {buttons && Array.isArray(buttons) && (
        <Grid container spacing={3}>
          {buttons.map(({ content, onClick, hidden }, index) =>
            hidden ? null : (
              <Grid item container xs={12} key={index} justifyContent="center">
                <Button
                  onClick={onClick}
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                >
                  {content}
                </Button>
              </Grid>
            )
          )}
        </Grid>
      )}
    </div>
  );
};

NoDataComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      hidden: PropTypes.bool
    })
  ),
  image: PropTypes.string
};
