import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Lightbox from "react-image-lightbox";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import { Skeleton } from "@mui/material";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";
import ScrollContainer from "Components/Utils/ScrollContainer";

const useStyles = makeStyles(theme => ({
  container: { marginTop: theme.spacing(2) },
  file: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1, 1),
    maxHeight: 110,
    minHeight: 110,
    maxWidth: 110,
    minWidth: 110,
    objectFit: "cover",
    cursor: "pointer",
    backgroundColor: theme.palette.grey[300]
  },
  emptyEvidenceBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    display: "flex"
  },
  warningIcon: {
    marginRight: theme.spacing(1)
  }
}));

export const FileViewer = ({ title, files, loading }) => {
  const classes = useStyles();
  const [fileIndex, setFileIndex] = useState(-1);

  const isImg = file => file.type === "img";
  const fileOrder = file => (isImg(file) ? 0 : 1);

  const orderedFiles = [...files].sort((a, b) => fileOrder(a) - fileOrder(b));
  const images = orderedFiles.filter(isImg);

  const handlePdfClick = src => window.open(src);

  const closeLightBox = () => setFileIndex(-1);
  const handlePreviousImage = () =>
    setFileIndex(prevState => (prevState + images.length - 1) % images.length);
  const handleNextImage = () =>
    setFileIndex(prevState => (prevState + 1) % images.length);

  const skeletons = [...new Array(4)].map((_, i) => (
    <Skeleton key={i} className={classes.file} variant="rectangular" />
  ));

  const filePreviews = orderedFiles.map((file, i) =>
    isImg(file) ? (
      <img
        key={file.src}
        onClick={() => setFileIndex(i)}
        className={classes.file}
        src={file.src}
        alt="photoPreview"
      />
    ) : (
      <div
        key={i}
        className={classes.file}
        onClick={() => handlePdfClick(file.src)}
      >
        <PdfIcon />
      </div>
    )
  );

  return (
    <div className={classes.container}>
      <Typography variant="body2">{title}</Typography>
      {files.length ? (
        <ScrollContainer>{loading ? skeletons : filePreviews}</ScrollContainer>
      ) : (
        <div className={classes.emptyEvidenceBox}>
          <WarningIcon
            fontSize="small"
            color="error"
            className={classes.warningIcon}
          />
          <Typography variant="body2" color="error">
            No images to show
          </Typography>
        </div>
      )}
      {fileIndex >= 0 && (
        <Lightbox
          mainSrc={images[fileIndex].src}
          nextSrc={images[(fileIndex + 1) % images.length].src}
          prevSrc={images[(fileIndex + images.length - 1) % images.length].src}
          reactModalStyle={{ overlay: { zIndex: 2000 } }}
          onMovePrevRequest={handlePreviousImage}
          onMoveNextRequest={handleNextImage}
          onCloseRequest={closeLightBox}
        />
      )}
    </div>
  );
};

FileViewer.propTypes = {
  title: PropTypes.string,
  files: PropTypes.array,
  loading: PropTypes.bool,
  isEvidence: PropTypes.bool
};
