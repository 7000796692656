import React, { useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import get from "lodash/get";

import { addChannelRentalStatus } from "Redux/SendBird/ActionCreators";
import { LetterAvatar } from "Components/Avatar/LetterAvatar";
import { createShortenedString } from "Utils/Helpers";
import { inboxRentalStatusEnum } from "Enums/InboxRentalStatusEnum";
import { useChannelRentalInfo } from "./InboxUtils";
import { getNormalizedRentalStatus } from "./utils/filterUtils";
import optimizeURL from "Driver/utils/cloudImage";

const useStyles = hasUnreadMessages =>
  makeStyles(theme => ({
    root: {
      display: "flex",
      width: "100%",
      cursor: "pointer",
      minHeight: "95px",
      maxHeight: "120px",
      alignItems: "center",
      backgroundColor: hasUnreadMessages ? "#F5F5F5" : "FAFAFA",
      "&.Mui-selected": {
        backgroundColor: "#E1F5FE",
        "&:hover": {
          backgroundColor: "#bfe9fd"
        }
      },
      "&:hover": {
        backgroundColor: "#bfe9fd"
      }
    },
    topBar: {
      display: "flex",
      "justify-content": "space-between"
    },
    channelInfo: {
      padding: "5%",
      display: "flex",
      width: "100%",
      "flex-direction": "column"
    },
    messages: {
      display: "flex",
      justifyContent: "space-between"
    },
    unreadMessageCount: {
      width: "18px",
      height: "18px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    secondaryText: {
      overflowWrap: "anywhere"
    },
    name: {
      fontWeight: "600"
    },
    status: {
      fontSize: "10px",
      lineHeight: "12px",
      letterSpacing: "1.5px"
    },
    rentalInfo: {
      fontSize: "12px",
      fontWeight: "300"
    }
  }))();

export const InboxChannel = connect(
  state => ({
    selectedChannel: get(state, "fleet.sendbird.selectedChannel", {})
  }),
  { addChannelRentalStatus }
)(
  ({
    channel,
    avatarSrc,
    alt,
    name,
    text,
    driverId,
    timestamp,
    onClick,
    selectedChannel,
    addChannelRentalStatus
  }) => {
    const rentalInfo = useChannelRentalInfo(driverId);

    useEffect(() => {
      if (rentalInfo.status !== "LOADING")
        addChannelRentalStatus(
          channel.url,
          getNormalizedRentalStatus(rentalInfo.status)
        );
    }, [rentalInfo.status, channel.url, addChannelRentalStatus]);

    const isValidTimestamp = timestamp => !!timestamp;

    const classes = useStyles(!!channel.unreadMessageCount);

    return (
      <ListItem
        button
        onClick={() => onClick(channel)}
        className={classes.root}
        selected={
          selectedChannel && channel && selectedChannel.url === channel.url
        }
      >
        <div className={classes.root}>
          <Avatar
            src={optimizeURL(avatarSrc, "web.inbox.user.avatar")}
            alt={alt}
          >
            <LetterAvatar firstName={name} />
          </Avatar>
          <div className={classes.channelInfo}>
            <div className={classes.topBar}>
              <Typography
                className={classes.status}
                style={{
                  color: inboxRentalStatusEnum[rentalInfo.status].color
                }}
              >
                {inboxRentalStatusEnum[rentalInfo.status].title}
              </Typography>
              {isValidTimestamp(timestamp) && (
                <Typography
                  component="span"
                  variant="caption"
                  color={"textSecondary"}
                  align={"right"}
                >
                  {moment(timestamp).isSame(moment(), "day")
                    ? moment(timestamp).format("HH:mm")
                    : moment(timestamp).format("MMM D")}
                </Typography>
              )}
            </div>
            <Typography style={{ fontWeight: 600 }}>{name}</Typography>
            <div className={classes.messages}>
              <Typography className={classes.text}>
                {createShortenedString(text, 25)}
              </Typography>
              {!!channel.unreadMessageCount && (
                <Typography
                  component="span"
                  variant="caption"
                  color="textSecondary"
                  className={classes.unreadMessageCount}
                >
                  {channel.unreadMessageCount}
                </Typography>
              )}
            </div>
            <Typography className={classes.rentalInfo}>
              {rentalInfo.carInfo &&
                `${rentalInfo.period} • ${rentalInfo.carInfo}`}
            </Typography>
          </div>
        </div>
      </ListItem>
    );
  }
);

InboxChannel.propTypes = {
  avatarSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  name: PropTypes.string,
  text: PropTypes.string
};
