import React, { useContext } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import makeStyles from "@mui/styles/makeStyles";

import { openNavigation } from "Redux/Navigation/ActionCreators";
import { RooftopSelector } from "Components/Header/RooftopSelector";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import CsvDownloadRemote from "Components/Utils/CsvDownloadRemote";
import { UniversalSearch } from "./universalSearch/UniversalSearch";
import OwnerGeneralAlert from "Components/Alerts/OwnerGeneralAlert";
import { OwnerAlertContext } from "Components/Utils/OwnerAlertProvider";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)
  },
  flexGrow: {
    flexGrow: 1
  },
  gutters: {
    padding: theme.spacing(0),
    paddingRight: theme.spacing(1)
  },
  search: {
    transition: theme.transitions.create("width")
  },
  downloadButton: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  tabs: {
    "& .MuiTab-root.Mui-selected": {
      color: `${theme.palette.primary.contrastText} !important`
    }
  }
}));

export const MobileHeader = connect(null, { openNavigation })(
  ({ openNavigation, title, tabs, iconButton, downloadData }) => {
    const classes = useStyles();
    const { organizationTokens, role } = useContext(ClientFactoryContext);
    const showRooftopSelector = organizationTokens.length > 1;
    const { showAlert } = useContext(OwnerAlertContext);

    return (
      <AppBar position="fixed" className={classes.tabs}>
        <ToolBar classes={{ gutters: classes.gutters }}>
          {iconButton ? (
            iconButton
          ) : (
            <IconButton
              className={classes.icon}
              onClick={openNavigation}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}
          {showRooftopSelector && <RooftopSelector location={title} />}
          {!showRooftopSelector && (
            <Typography
              component="h1"
              variant="h6"
              className={classes.flexGrow}
            >
              {title}
            </Typography>
          )}
          {role !== "p2p" && <UniversalSearch />}
          {downloadData && (
            <div className={classes.downloadButton}>
              <CsvDownloadRemote
                downloadData={downloadData}
                color="inherit"
                size="medium"
              />
            </div>
          )}
        </ToolBar>
        {tabs && tabs}
        {showAlert && <OwnerGeneralAlert />}
      </AppBar>
    );
  }
);
