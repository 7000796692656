import React, { useContext, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 20,
    marginBottom: theme.spacing(1)
  },
  paragraph: {
    marginBottom: theme.spacing(3)
  }
}));

const NoCarResults = () => {
  const classes = useStyles();

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  return (
    <DrawerPaddingContainer>
      <Typography className={classes.title}>No Results</Typography>
      <Typography className={classes.paragraph}>
        The car you are searching for cannot be found because it may not be
        eligible* for a car switch. Please try a different car.
      </Typography>
      <Typography className={classes.paragraph}>
        To switch to an ineligible car, please contact HyreCar Customer Support
        at{" "}
        <a href={`tel:1-${customerServicePhoneNumber}`}>
          {customerServicePhoneNumber}
        </a>
        .
      </Typography>
      <Typography className={classes.paragraph}>
        *To be eligible, the car must:
        <ul>
          <li>Be verifiied by a HyreCar agent</li>
          <li>Be Live on the marketplace</li>
          <li>
            Have valid Registration, Insurance, and Inspection (in required
            states)
          </li>
          <li>Documents must not expire within 14 days</li>
        </ul>
      </Typography>
    </DrawerPaddingContainer>
  );
};

export default NoCarResults;
