import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TimePicker from "@mui/lab/MobileTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

export const FormTimePicker = props => {
  const { placeholder, input, meta, id } = props;
  const { value, name, onChange, onBlur } = input;
  const { touched, invalid, error } = meta;
  const hasError = touched && invalid;

  const handleChange = time => {
    if (time) {
      onChange(time.format());
    }
  };

  const handleBlur = () => onBlur();

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <FormControl fullWidth>
        <TimePicker
          id={id || "time-picker"}
          value={value ? value : null}
          label={placeholder}
          name={name}
          renderInput={props => (
            <TextField
              id={id || "time-picker"}
              helperText={touched && error}
              variant="filled"
              {...{ ...props, error: touched && invalid }}
              InputLabelProps={{
                ...props.InputLabelProps
              }}
            />
          )}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {hasError && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </LocalizationProvider>
  );
};

FormTimePicker.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  })
};
