import React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";

import { protectionPlans } from "Components/Dialog/protectionPlanDialog/protectionPlanCopies";
import ProtectionPlanCard from "Components/Dialog/protectionPlanDialog/ProtectionPlanCard";

export const ProtectionCardSelect = ({
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const cardSelected = value => value === input.value;
  return (
    <FormControl error={touched && invalid} {...custom}>
      <Grid container direction={"row"} spacing={2}>
        {protectionPlans.map(plan => (
          <Grid
            item
            xs={12}
            md={6}
            xl={3}
            key={plan.value}
            display="flex"
            justifyContent="center"
          >
            <ProtectionPlanCard
              plan={plan}
              selected={cardSelected(plan.value)}
              onClick={() => input.onChange(plan.value)}
            />
          </Grid>
        ))}
      </Grid>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

ProtectionCardSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    invalid: PropTypes.bool,
    touched: PropTypes.bool
  })
};
