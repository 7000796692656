import { useContext } from "react";
import { SubmissionError } from "redux-form";

import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { AuthContext } from "Login/Auth";

export const LoginFormController = ({ children }) => {
  const { login, loginWithAuth0 } = useContext(AuthContext);

  const validate = values => {
    let errors = {};

    if (!values.username) {
      errors.username = "Required";
    }
    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };

  const handleLogin = values => {
    return new Promise((resolve, reject) => {
      login(values)
        .then(resolve)
        .catch(idpError => {
          loginWithAuth0(values)
            .then(resolve)
            .catch(() => reject(idpError));
        });
    });
  };

  const onSubmit = async values => {
    try {
      await handleLogin(values);
    } catch (e) {
      const error =
        e && e.message
          ? e.message.replace("GraphQL error: ")
          : "Sorry, there was an error during the sign up process. Please try again.";
      throw new SubmissionError({
        _error: error
      });
    }
  };

  return children({
    validate,
    onSubmit,
    onSubmitFail: errors => scrollErrorIntoView(errors)
  });
};
