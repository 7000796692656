import React from "react";
import { reduxForm } from "redux-form";

import { validate } from "./validate";
import { ProtectionFields } from "./ProtectionFields";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { compose } from "recompose";
import { connect } from "react-redux";

const DESCRIPTION =
  "Choose the right coverage for your car. Select the coverage based on your risk profile. If you are a new owner, you might want to select the most protection for your car. But if you are an experienced operator, you can choose the Basic plan for more earnings. Owners are required to select one of this before moving forward, even if you have commercial insurance.";

export const ProtectionForm = compose(
  connect(state => ({
    initialValues: state.fleet.listing.initialFormValues
  })),
  reduxForm({
    form: "LIST_CAR_FLOW",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    updateUnregisteredFields: true,
    validate
  })
)(({ handleSubmit, previousStep }) => {
  return (
    <FlowContentContainer
      header="HyreCar Protection"
      description={DESCRIPTION}
      nextStep={handleSubmit}
      nextStepLabel="Continue"
      previousStep={previousStep}
      previousStepLabel="Back"
      nextStepId="continue-protection-plan"
      previousStepId="back-protection-plan"
    >
      <ProtectionFields />
    </FlowContentContainer>
  );
});
