import React, { useContext, Fragment } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { Field, formValues } from "redux-form";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";

import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { FormTextField } from "Components/Inputs/FormTextField";
import { FormCheckbox } from "Components/Inputs/FormCheckbox";
import { FormDatePicker } from "Components/Inputs/FormDatePicker";
import { SsnMask } from "Components/Forms/InputMasks/SsnMask";
import { ZipMask } from "Components/Forms/InputMasks/ZipMask";
import { formatPhone } from "../../../Utils/Helpers";

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(4)
  },
  footer: {
    padding: ({ compact }) => (compact ? null : theme.spacing(0, 4)),
    marginTop: theme.spacing(4)
  },
  container: {
    padding: ({ compact }) => (compact ? null : theme.spacing(0, 4))
  },
  divider: {
    margin: theme.spacing(6, 0, 4, 0)
  },
  button: {
    padding: theme.spacing(1, 4)
  }
}));

export const AchFormFields = props => {
  const { compact } = props;
  const classes = useStyles(props);
  const { getLink } = useContext(FirebaseContext);

  const renderTosLabel = () => (
    <Typography component="">
      I agree to{" "}
      <Link
        href={getLink("tos")}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
      >
        HyreCar&apos;s Terms of Service
      </Link>
    </Typography>
  );

  return (
    <>
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6">
            Legal Business Representative
          </Typography>
        </Grid>
        <ACHUserInfoFields />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6">
            Business Information
          </Typography>
        </Grid>
        <ACHBusinessInformationFields />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6">
            Billing Address
          </Typography>
        </Grid>
        <ACHBillingFields compact={compact} />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6">
            Payment Information
          </Typography>
        </Grid>
        <ACHPaymentFields compact={compact} />
      </Grid>
      <Grid
        container
        direction="row"
        className={classes.footer}
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Field
            name="tos"
            label={renderTosLabel()}
            required
            component={FormCheckbox}
          />
        </Grid>
      </Grid>
    </>
  );
};

AchFormFields.propTypes = {
  compact: PropTypes.bool
};

const ACHUserInfoFields = () => {
  return (
    <Fragment>
      <Grid item xs={12} md={6}>
        <Field
          name="first_name"
          label="First Name"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="last_name"
          label="Last Name"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="dob"
          label="Date of Birth"
          fullWidth
          variant="inline"
          inputVariant="filled"
          disableToolbar
          disableFuture
          component={FormDatePicker}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="personal_id_number"
          label="Social Security Number"
          placeholder="xxx-xx-xxxx"
          fullWidth
          variant="filled"
          component={FormTextField}
          inputComponent={SsnMask}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="phone"
          label="Phone Number"
          fullWidth
          variant="filled"
          placeholder="Phone Number"
          component={FormTextField}
          normalize={formatPhone}
        />
      </Grid>
    </Fragment>
  );
};

const ACHBusinessInformationFields = formValues("type")(({ type }) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Field
          name="type"
          label="I don't have a Business or a Tax ID Number"
          component={FormCheckbox}
          format={v => v === "individual"} // convert initial value to boolean
          normalize={v => (v ? "individual" : "company")} // normalize boolean input to string
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Collapse in={type !== "individual"}>
          <Field
            name="business_name"
            label="Business Name"
            fullWidth
            variant="filled"
            component={FormTextField}
          />
        </Collapse>
      </Grid>
      <Grid item xs={12} md={6}>
        <Collapse in={type !== "individual"}>
          <Field
            name="business_tax_id"
            label="Tax ID Number"
            fullWidth
            variant="filled"
            component={FormTextField}
          />
        </Collapse>
      </Grid>
    </Fragment>
  );
});

const ACHBillingFields = ({ compact }) => {
  return (
    <Fragment>
      <Grid item xs={12} md={compact ? 12 : 7}>
        <Field
          name="line1"
          label="Address"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
      <Grid item xs={12} md={compact ? 12 : 7}>
        <Field
          name="city"
          label="City"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
      <Grid item xs={12} md={compact ? 12 : 7}>
        <Field
          name="state"
          label="State"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
      <Grid item xs={12} md={compact ? 12 : 7}>
        <Field
          name="postal_code"
          label="Zip"
          fullWidth
          variant="filled"
          component={FormTextField}
          inputComponent={ZipMask}
        />
      </Grid>
    </Fragment>
  );
};

const ACHPaymentFields = ({ compact }) => {
  return (
    <Fragment>
      <Grid item xs={12} md={compact ? 12 : 7}>
        <Field
          name="routing_number"
          label="Routing Number"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
      <Grid item xs={12} md={compact ? 12 : 7}>
        <Field
          name="account_number"
          label="Account Number"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
      <Grid item xs={12} md={compact ? 12 : 7}>
        <Field
          name="confirm_account_number"
          label="Confirm Account Number"
          fullWidth
          variant="filled"
          component={FormTextField}
        />
      </Grid>
    </Fragment>
  );
};
