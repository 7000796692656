import { RouteEnum } from "Enums/RouteEnum";
import { CarVerificationEnum, RentalStatusEnum } from "Enums/StateEnums";

const getVin = vin => vin.slice(vin.length - 6);

const getCarDescription = car => `${car.year} ${car.make} ${car.model}`;

export const getMappedItem = item =>
  item.__autocomplete_indexName === "owner-rentals"
    ? getRentalData(item)
    : getCarData(item);

const getRentalData = rental => ({
  path: mapRentalStatusToTab(rental.status),
  label: `${rental.driverFirstName} ${
    rental.driverLastName
  } (${getCarDescription(rental)} - ${getVin(rental.vin)})`
});

const getCarData = car => ({
  path: mapListingStatusToTab(car.verification),
  label: `${getCarDescription(car)} (${getVin(car.vin)})`
});

const mapRentalStatusToTab = status => {
  switch (status) {
    case RentalStatusEnum.applied:
      return { label: "rentals > requests", route: RouteEnum.rentalsRequests };
    case RentalStatusEnum.pendingInsurance:
    case RentalStatusEnum.pendingPickup:
    case RentalStatusEnum.active:
    case RentalStatusEnum.lat:
      return { label: "rentals > current", route: RouteEnum.rentalsActive };
    case RentalStatusEnum.completed:
    default:
      return { label: "rentals > past", route: RouteEnum.rentalsPast };
  }
};

const mapListingStatusToTab = verification =>
  verification === CarVerificationEnum.verified
    ? { label: "listings > listings", route: RouteEnum.listingsAll }
    : { label: "listings > in progress", route: RouteEnum.listingsProgress };
