import { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import get from "lodash/get";

import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { formatPhone } from "Utils/Helpers";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { UPDATE_ORGANIZATION_MEMBER } from "Mutations/Organization/OrganizationMutations";
import { GET_ORGANIZATION_MEMBER } from "Queries/Organization/OrganizationQueries";

import { useOrganizationRoleOptions } from "Components/Forms/FormControllers/hooks/useOrganizationRoleOptions";

export const EditUserFormController = ({ children, onClose, memberId }) => {
  const { currentOrganizationClient } = useContext(ClientFactoryContext);
  const [updateOrganizationMember] = useMutation(UPDATE_ORGANIZATION_MEMBER, {
    client: currentOrganizationClient
  });
  const { data: memberData, loading: memberLoading } = useQuery(
    GET_ORGANIZATION_MEMBER,
    {
      client: currentOrganizationClient,
      variables: {
        data: {
          id: memberId
        }
      }
    }
  );
  const { loading: rolesLoading, roleOptions } = useOrganizationRoleOptions();

  const memberToEdit = get(memberData, "organizationMember");

  const { enqueueSnackbar } = useSnackbar();

  const email = get(memberToEdit, "user.userName")
    ? get(memberToEdit, "user.userName")
    : get(memberToEdit, "user.id");

  const initialValues = {
    rooftops: get(memberToEdit, "rooftops", []).map(rooftop => ({
      value: get(rooftop, "id"),
      label: get(rooftop, "name")
    })),
    firstName: get(memberToEdit, "user.name.givenName"),
    lastName: get(memberToEdit, "user.name.familyName"),
    phone: formatPhone(get(memberToEdit, "user.phoneNumbers[0].value")),
    email,
    role: get(memberToEdit, "role.id")
  };

  const onSubmit = async values => {
    try {
      await updateOrganizationMember({
        variables: {
          data: {
            id: memberId,
            role: get(values, "role"),
            rooftops: get(values, "rooftops", []).map(rooftop => rooftop.value)
          }
        }
      });

      enqueueSnackbar("Successfully updated this user.", {
        variant: "success"
      });

      onClose();
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        e.message
          ? e.message.replace(/graphql error: /gim, "")
          : "Failed to submit.",
        {
          variant: "error"
        }
      );
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.role) {
      errors.role = "Required";
    }
    if (values.rooftops.length === 0) {
      errors.rooftops = "Required";
    }
    return errors;
  };

  return children({
    onSubmit,
    initialValues,
    validate,
    roleOptions,
    loading: memberLoading || rolesLoading,
    onSubmitFail: errors => scrollErrorIntoView(errors),
    onClose
  });
};
