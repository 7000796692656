import React from "react";
import { Provider } from "react-redux";
import { reducer as formReducer } from "redux-form";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import messagingModalReducer from "Driver/redux/reducers/messagingModalReducer";
import offersReducer from "Driver/redux/reducers/offersReducer";
import apiDataReducer from "Driver/redux/reducers/apiDataReducer";
import fleetReducer from "Redux/Reducers";

const reducers = combineReducers({
  form: formReducer,
  apiData: apiDataReducer,
  messagingModal: messagingModalReducer,
  offers: offersReducer,
  fleet: fleetReducer
});

export const store = process.env.REACT_APP_STAGE
  ? createStore(
      reducers,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__(),
      applyMiddleware(thunkMiddleware)
    )
  : createStore(reducers, applyMiddleware(thunkMiddleware));

export const ReduxProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);
