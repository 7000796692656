import { gql } from "@apollo/client";

import {
  OrganizationFragments,
  CompoundOrganizationFragments
} from "Queries/Organization/OrganizationFragments";
import { RooftopFragments } from "Queries/Rooftop/RooftopFragments";

export const GET_TOKENS = gql`
  query GetTokens {
    getTokens {
      organizationToken
      rooftopTokens
    }
  }
`;

export const REFRESH_TOKENS = gql`
  query RefreshTokens {
    refreshTokens {
      organizationToken
      rooftopTokens
    }
  }
`;

export const ORGANIZATION_INFO = gql`
  query OrganizationInfo {
    organization {
      ...OrganizationInfo
      organizationMembers {
        ...OrganizationMember
      }
      rooftops {
        ...RooftopInfo
        legacyRooftop {
          ...LegacyRooftop
        }
        # address {
        #   ...RooftopAddress
        # }
        # members {
        #   ...RooftopMember
        # }
      }
      roles {
        ...OrganizationRole
      }
    }
  }
  ${OrganizationFragments.organizationInfo}
  ${CompoundOrganizationFragments.organizationMember}
  ${RooftopFragments.rooftopInfo}
  ${RooftopFragments.legacyRooftop}
  # ${RooftopFragments.rooftopAddress}
  # ${RooftopFragments.rooftopMember}
  ${OrganizationFragments.organizationRole}
`;

export const ORGANIZATION_ROOFTOPS = gql`
  query OrganizationRooftops {
    organization {
      ...OrganizationInfo
      rooftops {
        ...RooftopInfo
        address {
          ...OrganizationAddress
        }
      }
    }
  }
  ${OrganizationFragments.organizationInfo}
  ${RooftopFragments.rooftopInfo}
  ${OrganizationFragments.organizationAddress}
`;

export const ORGANIZATION_MEMBERS = gql`
  query OrganizationMembers {
    organization {
      ...OrganizationInfo
      organizationMembers {
        ...OrganizationMember
      }
    }
  }
  ${OrganizationFragments.organizationInfo}
  ${CompoundOrganizationFragments.organizationMember}
`;

export const GET_ORGANIZATION_MEMBER = gql`
  query getOrganizationMember($data: OrganizationMemberQueryInput!) {
    organizationMember(input: $data) {
      ...OrganizationMember
    }
  }
  ${CompoundOrganizationFragments.organizationMember}
`;
