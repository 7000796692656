import React from "react";
import { InputAdornment, TextField } from "@mui/material";

const Field = ({
  label,
  value,
  onChange,
  validate,
  error,
  helperText,
  icon,
  inputComponent
}) => (
  <TextField
    margin="dense"
    label={label}
    fullWidth
    variant="filled"
    value={value}
    onChange={onChange}
    onBlur={validate}
    InputProps={{
      endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
      ...(inputComponent && { inputComponent })
    }}
    InputLabelProps={{ shrink: true }}
    error={error}
    helperText={helperText}
  />
);

export default Field;
