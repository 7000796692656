import SendBird from "sendbird";

import config from "Config/config";
import {
  messageReceived,
  readReceiptUpdated,
  onChannelChanged,
  onReconnectStarted,
  onReconnectSucceeded,
  onReconnectFailed
} from "Redux/SendBird/ActionCreators";

const SENDBIRD_MESSAGE_PAGE_SIZE = 30;

class SendBirdUtil {
  channelHandlerKey = "sendbird-channel-handler";
  connectionHandlerKey = "sendbird-connection-handler";

  constructor() {
    this.sb = new SendBird({
      appId: config["SENDBIRD_APP_ID"]
    });
  }

  createConnectionHandler(dispatch) {
    this.connectionHandler = new this.sb.ConnectionHandler();

    this.connectionHandler.onReconnectStarted = this.subscribeToOnReconnectStarted(
      dispatch
    );
    this.connectionHandler.onReconnectSucceeded = this.subscribeToOnReconnectSucceeded(
      dispatch
    );
    this.connectionHandler.onReconnectFailed = this.subscribeToOnReconnectFailed(
      dispatch
    );

    this.sb.addConnectionHandler(this.connectionHandler);
  }

  createChannelHandler(dispatch) {
    this.channelHandler = new this.sb.ChannelHandler();
    this.channelHandler.onMessageReceived = this.subscribeToMessageReceived(
      dispatch
    );
    this.channelHandler.onReadReceiptUpdated = this.subscribeToReadReceiptUpdated(
      dispatch
    );
    this.channelHandler.onChannelChanged = this.subscribeToOnChannelChanged(
      dispatch
    );
    this.sb.addChannelHandler(this.channelHandlerKey, this.channelHandler);
  }

  connect(userId) {
    return new Promise((resolve, reject) => {
      this.sb.connect(userId, (user, error) => {
        if (error) {
          reject(error);
        } else {
          this.user = user;
          resolve(user);
        }
      });
    });
  }

  updateUserInfo(nickname, profileUrl) {
    return new Promise((resolve, reject) => {
      this.sb.updateCurrentUserInfo(nickname, profileUrl, (user, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(user);
        }
      });
    });
  }

  updateUserMetaData() {
    return new Promise((resolve, reject) => {
      this.sb.currentUser.updateMetaData({}, (_, error) => {
        if (error) reject(error);
        resolve(this.sb.currentUser);
      });
    });
  }

  updateChannel(channel, data) {
    return new Promise((resolve, reject) => {
      channel.updateChannel(
        true,
        "Group Channel",
        "https://static.sendbird.com/sample/channel/channel_05.png",
        data,
        (_, error) => {
          if (error) reject(error);
          resolve(channel);
        }
      );
    });
  }

  messageBelongsToCurrentUser(message) {
    return message?._sender?.userId === this.user?.userId;
  }

  channelGetLastMessage(channel) {
    return channel.lastMessage;
  }

  channelHasUnreadMessage(channel) {
    if (channel.unreadMessageCount > 0) {
      return true;
    }

    return false;
  }

  markChannelAsRead(channel) {
    channel.markAsRead();
  }

  sendMessageToChannel(channel, messageParams) {
    return new Promise((resolve, reject) => {
      channel.sendUserMessage(
        messageParams.message,
        messageParams.data,
        (message, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(message);
          }
        }
      );
    });
  }

  getPreviousMessagesForChannel(channel) {
    return new Promise((resolve, reject) => {
      channel.messageListQuery.load(
        SENDBIRD_MESSAGE_PAGE_SIZE,
        false,
        (messages, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(messages);
          }
        }
      );
    });
  }

  appendMessageListQuery(channel) {
    channel.messageListQuery = channel.createPreviousMessageListQuery();
    return channel;
  }

  getChannelList() {
    return new Promise((resolve, reject) => {
      const channelListQuery = this.sb.GroupChannel.createMyGroupChannelListQuery();

      channelListQuery.includeEmpty = true;
      channelListQuery.limit = 100;
      channelListQuery.next((channels, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(
            channels.map(channel => this.appendMessageListQuery(channel))
          );
        }
      });
    });
  }

  createGroupChannel(driverId) {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.createChannelWithUserIds(
        [driverId],
        true,
        (channel, error) => {
          if (error) reject(error);
          resolve(this.appendMessageListQuery(channel));
        }
      );
    });
  }

  subscribeToOnChannelChanged(dispatch) {
    return channel => {
      dispatch(onChannelChanged(channel, this.user));
    };
  }

  subscribeToMessageReceived(dispatch) {
    return (channel, message) => {
      dispatch(messageReceived(channel, message));
    };
  }

  subscribeToReadReceiptUpdated(dispatch) {
    return channel => {
      dispatch(readReceiptUpdated(channel));
    };
  }

  subscribeToOnReconnectStarted(dispatch) {
    return () => {
      dispatch(onReconnectStarted());
    };
  }

  subscribeToOnReconnectSucceeded(dispatch) {
    return () => {
      dispatch(onReconnectSucceeded());
    };
  }

  subscribeToOnReconnectFailed(dispatch) {
    return () => {
      dispatch(onReconnectFailed());
    };
  }
}

export const sb = new SendBirdUtil();
