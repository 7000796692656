import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link as RouterLink } from "react-router-dom";

import { inboxRentalStatusEnum } from "Enums/InboxRentalStatusEnum";
import optimizeURL from "Driver/utils/cloudImage";

const useStyles = makeStyles({
  root: {
    padding: 16,
    display: "flex",
    cursor: ({ clickable }) => (clickable ? "pointer" : "default")
  },
  carInfo: {
    paddingLeft: 16,
    display: "flex",
    alignItems: "flex-start",
    flexGrow: 1,
    justifyContent: "space-between"
  },
  status: {
    fontSize: 10,
    letterSpacing: 1.5,
    color: ({ status }) => inboxRentalStatusEnum[status].color
  },
  carName: {
    fontWeight: 600
  },
  linkButton: {
    marginLeft: 16
  }
});

const RentalDetails = ({ rentalInfo }) => {
  const detailsRoute = inboxRentalStatusEnum[rentalInfo.status].detailsRoute;
  const clickable = !!detailsRoute;
  const classes = useStyles({ status: rentalInfo.status, clickable });
  const routeTo = `${detailsRoute}/${rentalInfo.id}`;
  const onClick = () => window.open(routeTo, "_blank");
  return (
    <div className={classes.root} onClick={clickable ? onClick : undefined}>
      <Avatar
        variant="rounded"
        src={optimizeURL(rentalInfo?.carImage, "web.inbox.car.avatar")}
      />
      <div className={classes.carInfo}>
        <div>
          <Typography className={classes.status}>
            {inboxRentalStatusEnum[rentalInfo.status].title}
          </Typography>
          <Typography className={classes.carName}>
            {rentalInfo.carInfo}
          </Typography>
          <Typography>{rentalInfo.period}</Typography>
          <Typography>VIN: {rentalInfo.carVin}</Typography>
        </div>
        {detailsRoute && (
          <IconButton
            className={classes.linkButton}
            size="small"
            component={RouterLink}
            to={routeTo}
            target="_blank"
            rel="noopener noreferrer"
          >
            <OpenInNewIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

RentalDetails.propTypes = {
  rentalInfo: PropTypes.object
};

export default RentalDetails;
