import { gql } from "@apollo/client";

import {
  CompoundOrganizationFragments,
  OrganizationFragments
} from "Queries/Organization/OrganizationFragments";
import { RooftopFragments } from "Queries/Rooftop/RooftopFragments";

export const INVITE_ORGANIZATION_MEMBER = gql`
  mutation inviteOrganizationMember($data: InviteOrganizationMemberInput!) {
    inviteOrganizationMember(input: $data) {
      ...OrganizationMember
    }
  }
  ${CompoundOrganizationFragments.organizationMember}
`;

export const UPDATE_ORGANIZATION_MEMBER = gql`
  mutation updateOrganizationMember($data: UpdateOrganizationMember!) {
    updateOrganizationMember(input: $data) {
      ...OrganizationMember
    }
  }
  ${CompoundOrganizationFragments.organizationMember}
`;

export const UPDATE_ORGANIZATION_ROOFTOP = gql`
  mutation updateRooftop($data: UpdateRooftopInput!) {
    updateRooftop(input: $data) {
      ...RooftopInfo
      legacyRooftop {
        ...LegacyRooftop
      }
      address {
        ...OrganizationAddress
      }
    }
  }
  ${RooftopFragments.rooftopInfo}
  ${RooftopFragments.legacyRooftop}
  ${OrganizationFragments.organizationAddress}
`;

export const ACTIVATE_ORGANIZATION_MEMBER = gql`
  mutation activateOrganizationMember($data: ActivateOrganizationMemberInput!) {
    activateOrganizationMember(input: $data) {
      id
      status
    }
  }
`;

export const DEACTIVATE_ORGANIZATION_MEMBER = gql`
  mutation deactivateOrganizationMember(
    $data: DeactivateOrganizationMemberInput!
  ) {
    deactivateOrganizationMember(input: $data) {
      id
      status
    }
  }
`;
