import React from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { useRentalStatusOptions } from "Components/hooks/useRentalStatusOptions";

const useStyles = makeStyles(() => ({
  root: {
    background: props => props.color,
    minWidth: "10px",
    width: "10px",
    height: "10px",
    borderRadius: "100%"
  }
}));

export const RentalStatusDotIndicator = ({ status }) => {
  const theme = useTheme();
  const { color } = useRentalStatusOptions(status, theme);
  const classes = useStyles({ color });

  return <div className={classes.root} />;
};

RentalStatusDotIndicator.propTypes = {
  status: PropTypes.string.isRequired
};
