import { useContext, useState } from "react";
import get from "lodash/get";

import { AuthContext } from "Login/Auth";
import { emailRegex } from "Utils/Regex";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { analytics } from "Analytics/index";

export const ResetPasswordFormController = ({ children }) => {
  const { resetPassword } = useContext(AuthContext);
  const [resetPasswordMessage, setResetPasswordMessage] = useState(null);

  const trackRequest = () => {
    analytics.track(
      AnalyticsEvents.label.auth.passwordResetRequested.legacyEventName,
      {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.formSubmitted,
        label: AnalyticsEvents.label.auth.passwordResetRequested.label,
        property: JSON.stringify({
          provider: "email",
          flow: AnalyticsEvents.flow.auth
        }),
        value: "",
        context: ""
      }
    );
  };

  const onSubmit = async values => {
    trackRequest();
    try {
      const { data } = await resetPassword(values);
      const success = get(data, "resetPassword.success", false);

      if (success) {
        setResetPasswordMessage(
          "An email has been sent to the email address provided. Please use the link in the email to reset your password."
        );
      } else {
        setResetPasswordMessage(
          "Sorry, there was an error validating your request."
        );
      }
    } catch (e) {
      console.error(e);
      setResetPasswordMessage(
        "Sorry, there was an error validating your request."
      );
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.email || !values.email.match(emailRegex)) {
      errors.email = "Please enter a valid email address";
    }
    return errors;
  };

  return children({
    onSubmit,
    validate,
    resetPasswordMessage
  });
};
