import { client } from "Components/Utils/ApolloProvider";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

import { getLeadReferrer, removeLeadReferrer } from "Analytics/utils/index";
import { TRACK_LEAD_REFERRER } from "Mutations/User/UserMutations";

/**
 * Confluence documentation: https://hyre.atlassian.net/l/c/AoafB1k3
 *
 * @param {string} id_token Passed in post-redirect. Lead signed up?
 * @param {string} userId Passed in post-email registration.
 * @returns {void}
 */
export const trackLeadReferrer = id_token => {
  const referrerSource = getLeadReferrer();
  if (!referrerSource) return;

  const leadReferrersMap = new Map();
  leadReferrersMap.set("ev", "Uber - EV");
  leadReferrersMap.set("ice", "Uber - ICE");

  // sso registration
  let userId = "";
  if (id_token) {
    let parsed = {};
    try {
      parsed = JSON.parse(atob(id_token.split(".")[1]));
      if (!parsed.authentication || !parsed.authentication.signUp) return;
      userId = parsed.id;
    } catch (e) {
      return;
    }
  }

  client
    .mutate({
      mutation: TRACK_LEAD_REFERRER,
      variables: {
        input: {
          referrerSource: leadReferrersMap.get(referrerSource)
        }
      }
    })
    .then(() => {
      analytics.track(AnalyticsEvents.label.lead.trackReferrerSuccess, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.webConversion,
        label: AnalyticsEvents.label.lead.trackReferrerSuccess,
        property: JSON.stringify({
          referrerSource,
          userId
        }),
        value: "",
        context: ""
      });
    })
    .catch(error => {
      analytics.track(AnalyticsEvents.label.lead.trackReferrerError, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.webConversion,
        label: AnalyticsEvents.label.lead.trackReferrerError,
        property: JSON.stringify({
          message: error.message,
          referrerSource,
          userId
        }),
        value: "",
        context: ""
      });
    });

  removeLeadReferrer();
};
