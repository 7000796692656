import React, { useContext, useMemo } from "react";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router";
import jwtDecode from "jwt-decode";
import moment from "moment";

import { useNearRentals } from "Queries/Rentals/hooks/useNearRentals";
import ScrollableCard from "./ScrollableCard";
import CarListItem from "Components/Car/CarListItem";
import CarListSkeleton from "Components/Car/CarListSkeleton";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { RouteEnum } from "Enums/RouteEnum";
import CsvDownload from "../Utils/CsvDownload";

const SKELETON_ITEM_COUNT = 2;

const getCsvHeaders = () => ["VIN", "Car", "Plate Number", "Driver Name"];

const rentalToCsv = rental => [
  rental.car.vin,
  `${rental.car.year} ${rental.car.make} ${rental.car.model}`,
  rental.car.licensePlate,
  rental.driver.user.fullName
];

const getCsvContents = rentals => rentals.map(rentalToCsv);

const getCardAction = (filename, rentals) => (
  <CsvDownload
    headers={getCsvHeaders()}
    data={getCsvContents(rentals)}
    fileName={filename}
  />
);

const getFileName = (token, date) =>
  `${date.format("MM-DD-YYYY")} ${jwtDecode(token).name} Pickups.csv`;

export const PickupCards = () => {
  const { currentRooftopToken: token } = useContext(ClientFactoryContext);
  const { rentalsToday, rentalsTomorrow, loading } = useNearRentals();
  const history = useHistory();

  const mapRentalToCarListItem = rental => (
    <CarListItem
      key={rental.id}
      car={rental.car}
      secondaryText={rental.driver.user.fullName}
      onClick={() => history.push(`${RouteEnum.rentalsActive}/${rental.id}`)}
    />
  );

  const getLoadingStateComponent = () => (
    <CarListSkeleton amount={SKELETON_ITEM_COUNT} />
  );

  const todayCardAction = useMemo(
    () => getCardAction(getFileName(token, moment()), rentalsToday),
    [rentalsToday, token]
  );

  const tomorrowCardAction = useMemo(
    () =>
      getCardAction(
        getFileName(token, moment().add(1, "day")),
        rentalsTomorrow
      ),
    [rentalsTomorrow, token]
  );

  return (
    <>
      <Grid item xs={12} md={6} lg={4}>
        <ScrollableCard
          items={rentalsToday.map(mapRentalToCarListItem)}
          loading={loading}
          loadingStateComponent={getLoadingStateComponent()}
          headerActionComponent={todayCardAction}
          title="Pickups Today"
          emptyStateCopy="No pickups scheduled yet."
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ScrollableCard
          items={rentalsTomorrow.map(mapRentalToCarListItem)}
          loading={loading}
          loadingStateComponent={getLoadingStateComponent()}
          headerActionComponent={tomorrowCardAction}
          title="Pickups Tomorrow"
          emptyStateCopy="No pickups scheduled yet."
        />
      </Grid>
    </>
  );
};
