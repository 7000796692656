import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { InfoDrawer } from "Components/Drawers/InfoDrawer";
import { UnsavedProgressDialogContext } from "Components/Utils/UnsavedProgressDialogProvider";

/**
 * The RouterDrawer component acts as a wrapper around the InfoDrawer.
 * This component can be used when you want to display a InfoDrawer on a
 * route change.
 *
 * This component is necessary because the material-ui drawer does
 * perform it's animation when it is mounted with open set to true. Instead,
 * this component will mount and then setState to open which will cause the animation
 * to run. Also, the handleClose function is used to close the drawer and call goBack
 * to return to the previous route. It will pass the handleClose functionality down
 * to its children.
 */

export const RouterDrawer = ({
  children,
  onClose,
  shouldWarnUnsavedChanges
}) => {
  const history = useHistory();
  const onMountRef = useRef();
  const [open, setOpen] = useState(false);

  const { setDisplayUnsavedProgressDialog, unsavedProgressExists } = useContext(
    UnsavedProgressDialogContext
  );

  useEffect(() => setOpen(true), []);

  useEffect(() => {
    if (onMountRef.current && !open) {
      onClose ? onClose() : history.goBack();
    } else {
      onMountRef.current = true;
    }
  }, [open, history]);

  const handleClose = () => {
    if (shouldWarnUnsavedChanges && unsavedProgressExists) {
      setDisplayUnsavedProgressDialog(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <InfoDrawer open={open} onClose={handleClose}>
      {children({
        handleClose
      })}
    </InfoDrawer>
  );
};

RouterDrawer.propTypes = {
  children: PropTypes.func.isRequired,
  onClose: PropTypes.func
};
