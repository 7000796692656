import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

const useStyles = makeStyles(theme => ({
  root: {
    background: "transparent",
    padding: theme.spacing(3, 2),
    height: ({ height }) => (height ? theme.spacing(height) : null)
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  selected: {
    borderColor: theme.palette.primary[500],
    background: theme.palette.primary[50]
  },
  icon: {
    fontSize: ({ iconSize }) => (iconSize ? theme.spacing(iconSize) : null),
    marginRight: theme.spacing(3.5)
  },
  pointer: {
    cursor: "pointer"
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5)
  }
}));

export const OptionCard = ({
  title,
  marginBottom,
  selected,
  pointer,
  onClick,
  hover,
  elevation,
  iconSize,
  height,
  children
}) => {
  const classes = useStyles({ iconSize, height });
  const [hovered, setHovered] = useState(false);

  return (
    <Card
      className={classNames(classes.root, {
        [classes.marginBottom]: marginBottom,
        [classes.selected]: selected,
        [classes.pointer]: pointer
      })}
      onClick={onClick}
      elevation={hover && hovered ? elevation : 0}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={classes.titleContainer}>
        {title && (
          <Typography component="span" variant="h6">
            {title}
          </Typography>
        )}
        {selected && (
          <CheckCircleIcon className={classes.icon} color="primary" />
        )}
      </div>
      {children}
    </Card>
  );
};

OptionCard.propTypes = {
  title: PropTypes.string,
  marginBottom: PropTypes.bool,
  selected: PropTypes.bool,
  pointer: PropTypes.bool,
  hover: PropTypes.bool,
  elevation: PropTypes.number,
  onClick: PropTypes.func,
  iconSize: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

OptionCard.defaultProps = {
  elevation: 4
};
