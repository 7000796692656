import { useState } from "react";
import { compose, withProps, mapProps, withStateHandlers } from "recompose";

import { optionalChaining } from "Driver/utils/helpers";

export const fetchingMore = compose(
  withStateHandlers(
    {
      fetchingMore: false
    },
    {
      startFetchingMore: () => () => ({
        fetchingMore: true
      }),
      stopFetchingMore: () => () => ({
        fetchingMore: false
      })
    }
  ),
  mapProps(props => {
    const { startFetchingMore, stopFetchingMore, fetchMore } = props;
    return {
      ...props,
      fetchMore: options => {
        startFetchingMore();
        return fetchMore({
          ...options
        })
          .then(() => {
            stopFetchingMore();
          })
          .catch(() => {
            stopFetchingMore();
          });
      }
    };
  })
);

export const evalLoadingState = withProps(props => {
  let loading = false;
  Object.keys(props).forEach(key => {
    if (
      Object.prototype.hasOwnProperty.call(props, key) &&
      props[key] &&
      props[key].loading
    )
      loading = true;
  });
  return {
    ...props,
    loading
  };
});

export const reducePayoutData = payouts => {
  return payouts.reduce((acc, payout) => {
    const amount = optionalChaining(() => payout.amount);
    if (amount) acc += amount;
    return acc;
  }, 0);
};

export const useBaseQuery = () => {
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  return {
    loading,
    error,
    data,
    refetching,
    setRefetching,
    setLoading,
    setError,
    setData
  };
};
