import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import { Field } from "redux-form";

import { FormCheckboxGroup } from "Components/Inputs/FormCheckboxGroup";
import ClosableDialogTitle from "Components/Dialog/ClosableDialogTitle";
import { messageTypesFilters, rentalStatusFilters } from "./Filters";

const useStyles = makeStyles(theme => ({
  rentalStatusFilters: {
    marginTop: theme.spacing(2)
  },
  title: {
    color: "#000000de"
  }
}));

const FiltersDialog = ({ onClose, open, handleSubmit, change, filters }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const handleApply = () => {
    handleSubmit();
    onClose();
  };

  const getFilterReducer = filter => (acc, current) => {
    acc[current.value] = filter && filters[filter].includes(current.value);
    return acc;
  };

  const changeMessageTypes = reducer =>
    change("messageTypes", messageTypesFilters.reduce(reducer, {}));

  const changeRentalStatus = reducer =>
    change("rentalStatus", rentalStatusFilters.reduce(reducer, {}));

  const initializeValues = () => {
    changeMessageTypes(getFilterReducer("messageTypes"));
    changeRentalStatus(getFilterReducer("rentalStatus"));
  };

  const clearValues = () => {
    changeMessageTypes(getFilterReducer(false));
    changeRentalStatus(getFilterReducer(false));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      fullWidth
      TransitionProps={{
        onEnter: initializeValues
      }}
    >
      <ClosableDialogTitle id="filter-messages-dialog" onClose={onClose}>
        Filter Messages
      </ClosableDialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" className={classes.title}>
          Message Type
        </Typography>
        <Field
          name="messageTypes"
          component={FormCheckboxGroup}
          options={messageTypesFilters}
        />
        <Typography
          component="h6"
          variant="h6"
          className={`${classes.rentalStatusFilters} ${classes.title}`}
        >
          Rental Status
        </Typography>
        <Field
          name="rentalStatus"
          component={FormCheckboxGroup}
          useColumns
          options={rentalStatusFilters}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={clearValues}>
          Clear
        </Button>
        <Button
          onClick={handleApply}
          color="primary"
          autoFocus
          variant="contained"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FiltersDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  change: PropTypes.func,
  filters: PropTypes.object
};

const initializerReducer = (prev, curr) => ({ ...prev, [curr.value]: false });

export default reduxForm({
  form: "MINE_INBOX_FILTERS",
  enableReinitialize: true,
  initialValues: {
    messageTypes: messageTypesFilters.reduce(initializerReducer, {}),
    rentalStatus: rentalStatusFilters.reduce(initializerReducer, {})
  }
})(FiltersDialog);
