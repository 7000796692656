export const GET_DATASET = "GET_DATASET";
export const GET_DATASET_SUCCESS = "GET_DATASET_SUCCESS";
export const GET_DATASET_ERROR = "GET_DATASET_ERROR";

export const UPDATE_FILTER = "UPDATE_FILTER";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_VIN = "UPDATE_VIN";
export const INIT_FILTER = "INIT_FILTER";
export const GET_FILTERS = "GET_FILTERS";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const GET_FILTERS_ERROR = "GET_FILTERS_ERROR";

export const UPDATE_AGGREGATE_BY = "UPDATE_AGGREGATE_BY";

export const UPDATE_RANGE = "UPDATE_RANGE";
