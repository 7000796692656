import { ProtectionPlanEnum } from "Enums/StateEnums";

export const protectionPlans = [
  {
    title: "Basic Protection",
    subtitle: "More rewards, less protection",
    earnings: "You Earn 85% of Rental Price",
    value: ProtectionPlanEnum.basic,
    coverages: [
      {
        title: "Insurance and Vehicle Coverage",
        descriptions: [
          "$1 Million Liability Coverage",
          "$3,000 deductible per incident",
          "Up to $50,000 Physical damage coverage"
        ]
      },
      {
        title: "Incident Coverage",
        descriptions: [
          "Up to $30 cleaning reimbursement",
          "65% Gas reimbursement",
          "65% mileage overage reimbursement"
        ]
      }
    ]
  },
  {
    title: "Standard Protection",
    earnings: "You Earn 80% of Rental Price",
    value: ProtectionPlanEnum.standard,
    coverages: [
      {
        title: "Insurance and Vehicle Coverage",
        descriptions: [
          "$1 Million Liability Coverage",
          "$2,500 deductible per incident",
          "Up to $50,000 Physical damage coverage"
        ]
      },
      {
        title: "Incident Coverage",
        descriptions: [
          "Up to $50 cleaning reimbursement",
          "80% Gas reimbursement",
          "80% mileage overage reimbursement"
        ]
      }
    ]
  },
  {
    title: "Premium Protection",
    earnings: "You Earn 75% of Rental Price",
    value: ProtectionPlanEnum.premium,
    coverages: [
      {
        title: "Insurance and Vehicle Coverage",
        descriptions: [
          "$1 Million Liability Coverage",
          "$1,000 deductible per incident",
          "Up to $50,000 Physical damage coverage"
        ]
      },
      {
        title: "Incident Coverage",
        descriptions: [
          "Up to $60 cleaning reimbursement",
          "85% Gas reimbursement",
          "85% mileage overage reimbursement",
          "85% Impound fee reimbubrsement",
          "Up to $250 lost key replacement"
        ]
      }
    ]
  },
  {
    title: "Premium Plus Protection",
    subtitle: "More protection, less rewards",
    earnings: "You Earn 70% of Rental Price",
    value: ProtectionPlanEnum.premium_plus,
    recommended: true,
    coverages: [
      {
        title: "Insurance and Vehicle Coverage",
        descriptions: [
          "$1 Million Liability Coverage",
          "$500 deductible per incident",
          "Up to $50,000 Physical damage coverage"
        ]
      },
      {
        title: "Incident Coverage",
        descriptions: [
          "Up to $75 cleaning reimbursement",
          "100% Gas reimbursement",
          "100% mileage overage reimbursement",
          "100% Impound fee reimbubrsement",
          "Up to $300 lost key replacement"
        ]
      }
    ]
  }
];
