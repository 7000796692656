import { useContext } from "react";
import { useQuery } from "@apollo/client";
import get from "lodash/get";

import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const useCurrentUser = () => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { loading, error, data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-only",
    client: currentRooftopClient
  });

  return {
    loading,
    error,
    data,
    user: get(data, "viewer.me", {})
  };
};
