import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { default as MuiTable } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";

const useStyles = makeStyles(theme => ({
  noDataLabelWrapper: {
    padding: `${theme.spacing(6)} 0`
  },
  tableHeadCell: {
    zIndex: 100,
    fontWeight: theme.typography.fontWeightBold,
    background: theme.palette.common.white
  },
  tableHeadCellTooltip: {
    cursor: "pointer"
  },
  tableWrapper: {
    minHeight: "100vh",
    overflow: "auto"
  },
  noTableWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tableRow: {
    cursor: "pointer"
  },
  tableRowHover: {
    "&:hover": {
      boxShadow: theme.shadows[4]
    }
  },
  button: {
    margin: theme.spacing(1.25, 0),
    left: "50%"
  },
  success: {
    background: theme.palette.success
  },
  warning: {
    background: theme.palette.warning
  },
  greyed: {
    color: theme.palette.grey[300]
  }
}));

export const MobileTable = ({
  columns,
  data,
  noDataLabel,
  noDataComponent,
  noDataCellLabel,
  disableHover,
  loading,
  onRowClick,
  size,
  fetchMore,
  fetchingMore,
  hasNextPage
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.tableWrapper, {
        [classes.noTableWrapper]: loading || data.length === 0
      })}
      id={joyrideTutorialIds.tableWrapper}
    >
      {data.length === 0 && !loading ? (
        <Grid
          container
          justifyContent="center"
          className={classes.noDataLabelWrapper}
        >
          {noDataComponent && noDataComponent}
          {noDataLabel && (
            <Typography component="span" variant="h5">
              {noDataLabel}
            </Typography>
          )}
        </Grid>
      ) : (
        <Fragment>
          {loading ? (
            <Typography
              component="span"
              variant="h5"
              className={classes.loadingText}
            >
              Loading...
            </Typography>
          ) : (
            <MuiTable stickyHeader size={size ? size : "small"}>
              <TableHead>
                <TableRow>
                  {columns.map(({ title, tooltip, align, hidden }, key) => {
                    if (hidden) return null;
                    return tooltip ? (
                      <Tooltip key={key} title={tooltip}>
                        <TableCell
                          align={align}
                          className={classNames(
                            classes.tableHeadCell,
                            classes.tableHeadCellTooltip
                          )}
                        >
                          {title}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell
                        key={key}
                        className={classes.tableHeadCell}
                        align={align}
                      >
                        {title}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {data && (
                <TableBody>
                  {data.map((d, key) => {
                    return (
                      <TableRow
                        key={key}
                        style={d.style || {}}
                        className={classNames(classes.tableRow, {
                          [d.className]: d.className,
                          [classes.tableRowHover]: !disableHover
                        })}
                        onClick={onRowClick ? () => onRowClick(d) : null}
                      >
                        {columns.map(({ field, align, hidden }, key) => {
                          if (hidden) return null;
                          return (
                            <TableCell
                              key={key}
                              align={align}
                              className={classNames({
                                [classes.success]: d.success,
                                [classes.warning]: d.warning,
                                [classes.error]: d.error,
                                [classes.greyed]: d.greyed
                              })}
                            >
                              {d[field]
                                ? typeof d[field] === "object"
                                  ? React.isValidElement(d[field])
                                    ? d[field]
                                    : d[field].value
                                  : d[field]
                                : noDataCellLabel
                                ? noDataCellLabel
                                : "No Data"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
              {hasNextPage && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={fetchMore}
                  className={classes.button}
                >
                  {fetchingMore ? "Loading..." : "Load More"}
                </Button>
              )}
            </MuiTable>
          )}
        </Fragment>
      )}
    </div>
  );
};

MobileTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired
    })
  ).isRequired,
  data: PropTypes.array,
  noDataLabel: PropTypes.string,
  noDataComponent: PropTypes.node,
  noDataCellLabel: PropTypes.string,
  disableHover: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  size: PropTypes.oneOf(["size", "medium"]),
  fetchMore: PropTypes.func,
  fetchingMore: PropTypes.bool,
  hasNextPage: PropTypes.bool
};
