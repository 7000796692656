import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { FullScreenDialog } from "Components/Dialog/FullScreenDialog";
import { CreateRooftopFormFields } from "Components/Forms/FormFields/CreateRooftopFormFields";
import { CreateRooftopFormController } from "Components/Forms/FormControllers/CreateRooftopFormController";
import { DialogAppBar } from "Components/Dialog/DialogAppBar";
import { DialogPaddingContainer } from "Components/Dialog/DialogPaddingContainer";
import { DialogBottomBar } from "Components/Dialog/DialogBottomBar";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: "flex",
    flex: 1,
    width: "100%"
  },
  createButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2)
  }
}));

const RooftopDialogForm = reduxForm({
  form: "CREATE_ROOFTOP_FORM",
  destroyOnUnmount: true
})(({ handleSubmit, submitting, open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const content = (
    <DialogPaddingContainer
      extraTopPadding={isMobile ? 2 : 0}
      extraRightPadding={isMobile ? 0 : 2}
      extraLeftPadding={isMobile ? 0 : 2}
    >
      <CreateRooftopFormFields />
    </DialogPaddingContainer>
  );

  const actions = (
    <div className={classes.buttonContainer}>
      {isMobile ? (
        <DialogBottomBar submitText="Create" onSubmit={handleSubmit} />
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          className={classes.createButton}
        >
          Create
        </Button>
      )}
    </div>
  );

  return (
    <FullScreenDialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullScreen={isMobile}
      title={
        isMobile ? (
          <DialogAppBar
            icon={
              <IconButton onClick={handleClose} size="large">
                <CloseIcon />
              </IconButton>
            }
            title="New Rooftop"
            border={true}
          />
        ) : (
          "New Rooftop"
        )
      }
      content={content}
      actions={actions}
    />
  );
});

export const RooftopDialog = ({ open, handleClose, organizationToken }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitSuccess = async () => {
    enqueueSnackbar("Rooftop successfully created!", {
      variant: "success"
    });
    handleClose();
  };

  const onSubmitFail = e => {
    enqueueSnackbar(
      e.message
        ? e.message.replace(/graphql error: /gim, "")
        : "Failed to submit your rooftop.",
      {
        variant: "error"
      }
    );
  };

  return (
    <CreateRooftopFormController
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      organizationToken={organizationToken}
    >
      {props => (
        <RooftopDialogForm {...props} open={open} handleClose={handleClose} />
      )}
    </CreateRooftopFormController>
  );
};

RooftopDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
