import { graphql } from "@apollo/client/react/hoc";
import { mutating } from "../../MutationUtils";
import {
  USER_SEND_EMAIL,
  UPDATE_OWNER_PROFILE,
  UPDATE_USERS_IMPACT_CLICK_ID
} from "../../User/UserMutations";

export const userSendEmail = props => {
  return mutating(
    graphql(USER_SEND_EMAIL, {
      ...props
    })
  );
};

export const updateOwnerProfile = props => {
  return mutating(
    graphql(UPDATE_OWNER_PROFILE, {
      ...props
    })
  );
};

export const updateUsersImpactClickId = props => {
  return graphql(UPDATE_USERS_IMPACT_CLICK_ID, {
    ...props
  });
};
