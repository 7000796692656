import React from "react";

export const useDialog = initialState => {
  const [dialogOpen, setDialogOpened] = React.useState(initialState);
  const handleDialogOpen = () => setDialogOpened(true);
  const handleDialogClose = () => setDialogOpened(false);

  return {
    dialogOpen,
    handleDialogOpen,
    handleDialogClose
  };
};
