import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import get from "lodash/get";
import moment from "moment";

import { Card } from "./Card";
import { CardCell } from "./CardCell";
import { CardHeader } from "./CardHeader";
import { RouteEnum } from "Enums/RouteEnum";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { RENTAL_AGGREGATE_QUERY } from "Queries/Rentals/RentalQueries";

export const RentalAggregateCard = () => {
  const [currentDate] = useState(moment().format());
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data: oneDayData, loading: oneDayLoading } = useQuery(
    RENTAL_AGGREGATE_QUERY,
    {
      variables: {
        filter: {
          createdAt_gt: moment(currentDate)
            .subtract(1, "days")
            .format()
        }
      },
      client: currentRooftopClient,
      fetchPolicy: "cache-and-network"
    }
  );

  const { data: sevenDaysData, loading: sevenDaysLoading } = useQuery(
    RENTAL_AGGREGATE_QUERY,
    {
      variables: {
        filter: {
          createdAt_gt: moment(currentDate)
            .subtract(7, "days")
            .format()
        }
      },
      client: currentRooftopClient,
      fetchPolicy: "cache-and-network"
    }
  );

  const { data: oneMonthData, loading: oneMonthLoading } = useQuery(
    RENTAL_AGGREGATE_QUERY,
    {
      variables: {
        filter: {
          createdAt_gt: moment(currentDate)
            .subtract(1, "months")
            .format()
        }
      },
      client: currentRooftopClient,
      fetchPolicy: "cache-and-network"
    }
  );

  const { data: oneYearData, loading: oneYearLoading } = useQuery(
    RENTAL_AGGREGATE_QUERY,
    {
      variables: {
        filter: {
          createdAt_gt: moment(currentDate)
            .subtract(1, "years")
            .format()
        }
      },
      client: currentRooftopClient,
      fetchPolicy: "cache-and-network"
    }
  );

  return (
    <Card>
      <CardHeader label="Rentals" to={RouteEnum.rentals} link={"view more"} />
      <Grid container>
        <Grid container item xs={6} md={3} justifyContent="center">
          <CardCell
            value={get(
              oneDayData,
              "viewer.me.owner.rentalsConnection.aggregate.count",
              ""
            ).toLocaleString()}
            label="Today"
            loading={oneDayLoading}
          />
        </Grid>
        <Grid container item xs={6} md={3} justifyContent="center">
          <CardCell
            value={get(
              sevenDaysData,
              "viewer.me.owner.rentalsConnection.aggregate.count",
              ""
            ).toLocaleString()}
            label="Past 7 Days"
            loading={sevenDaysLoading}
          />
        </Grid>
        <Grid container item xs={6} md={3} justifyContent="center">
          <CardCell
            value={get(
              oneMonthData,
              "viewer.me.owner.rentalsConnection.aggregate.count",
              ""
            ).toLocaleString()}
            label="Past 30 Days"
            loading={oneMonthLoading}
          />
        </Grid>
        <Grid container item xs={6} md={3} justifyContent="center">
          <CardCell
            value={get(
              oneYearData,
              "viewer.me.owner.rentalsConnection.aggregate.count",
              ""
            ).toLocaleString()}
            label="Past Year"
            loading={oneYearLoading}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
