/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import moment from "moment";

import { PayoutsCards } from "Fleet/Dashboard/DashboardViews/Financials/Payouts/PayoutsCards";
import { PayoutsTable } from "Fleet/Dashboard/DashboardViews/Financials/Payouts/PayoutsTable";
import { getDatesFromRange } from "Redux/utils";
import { usePayouts } from "Queries/Payouts/hooks/usePayouts";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { usePageQueryAnalytics } from "Analytics/Custom/usePageQueryAnalytics";

const NUMBER_OF_PAYOUTS_TO_FETCH = 30;

export const Payouts = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [dateDescriptionText, setDateDescriptionText] = useState("All Time");
  const [customDateDialogOpen, setCustomDateDialogOpen] = useState(false);

  const { loading, error, data, fetchMore } = usePayouts(
    NUMBER_OF_PAYOUTS_TO_FETCH,
    startDate ? moment(startDate).unix() : undefined,
    moment(endDate).unix()
  );

  usePageQueryAnalytics({
    successLabel: AnalyticsEvents.label.owner.viewPayouts,
    errorLabel: AnalyticsEvents.label.owner.viewPayoutsError,
    data: data?.table?.payouts,
    error
  });

  const {
    loading: cardsLoading,
    error: cardsError,
    data: cardsData
  } = usePayouts(NUMBER_OF_PAYOUTS_TO_FETCH);

  const updateFieldDateFilters = selectedRange => {
    const selectedDates = getDatesFromRange(selectedRange);
    setStartDate(selectedDates.startDate);
    setEndDate(selectedDates.endDate);
    setDateDescriptionText(selectedRange);
  };

  const handleFieldCustomDateFilters = customRange => {
    setStartDate(customRange.startDate);
    setEndDate(customRange.endDate);
    setDateDescriptionText("Custom");
    setCustomDateDialogOpen(false);
  };

  const toggleFieldDateDialog = isOpen => {
    setCustomDateDialogOpen(isOpen);
  };

  const customDateFilters = {
    startDate,
    endDate,
    dateDescriptionText,
    customDateDialogOpen
  };

  const customOptions = [
    "All Time",
    "Past 7 days",
    "Past 30 days",
    "Past 90 days",
    "Month to Date",
    "Quarter to Date",
    "Custom"
  ];

  const dateFilterHandlers = {
    defaultStartDate: startDate,
    defaultEndDate: endDate,
    updateFieldDateFilters,
    handleFieldCustomDateFilters,
    toggleFieldDateDialog,
    customDateFilters,
    customOptions,
    customHandlers: true,
    dateRangeMaxDays: null
  };

  return (
    <>
      <PayoutsCards
        data={cardsData?.cards}
        loading={cardsLoading}
        error={cardsError}
      />
      <PayoutsTable
        data={data?.table}
        dateFilterHandlers={dateFilterHandlers}
        fetchMore={fetchMore}
        loading={loading}
        error={error}
      />
    </>
  );
};
