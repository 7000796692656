const removeUrlParameters = (uri = "") => uri?.split("?")[0];

const optimizeURL = (url = "", preset) => {
  if (!url) return url;
  const urlParsed = removeUrlParameters(url);

  const HC_CLOUDIMAGE_PREFIX = "https://static.hyrecar.com";
  return `${HC_CLOUDIMAGE_PREFIX}/${urlParsed}?p=${preset}`;
};

export default optimizeURL;
