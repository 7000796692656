/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
  },
  selected: {}
});

export const ListItemLink = props => {
  const classes = useStyles();
  const { selected, onClick, to, id, children } = props;
  const renderedLink = useMemo(
    () =>
      React.forwardRef((props, ref) => (
        <Link to={to} {...props} innerRef={ref} />
      )),
    [to]
  );

  return (
    <ListItem
      classes={{ root: classes.root, selected: classes.selected }}
      button
      component={renderedLink}
      selected={selected}
      onClick={onClick}
      id={id}
    >
      {children}
    </ListItem>
  );
};

ListItemLink.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
