import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { BasicTable } from "Components/Tables/BasicTable";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  drawer: {
    borderTopLeftRadius: "5%",
    borderTopRightRadius: "5%"
  },
  title: {
    fontSize: 20,
    color: "rgba(0, 0, 0, 0.87)"
  },
  table: {
    marginTop: theme.spacing(3)
  },
  subtitle: {
    fontSize: 16,
    width: "70%",
    color: "rgba(0, 0, 0, 0.87)"
  }
}));

export const LineItemDrawer = ({
  open,
  onClose,
  title,
  titleRight,
  subtitle,
  tableData
}) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      PaperProps={{
        className: classes.drawer
      }}
    >
      <DrawerPaddingContainer>
        <Grid container className={classes.container}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={8}
            justifyContent="flex-end"
            className={classes.title}
          >
            <Typography variant="body1" className={classes.title}>
              {titleRight}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <div className={classes.table}>
          <BasicTable data={tableData} spacing={2} />
        </div>
      </DrawerPaddingContainer>
    </SwipeableDrawer>
  );
};

LineItemDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  titleRight: PropTypes.string,
  subtitle: PropTypes.string,
  tableData: PropTypes.object
};
