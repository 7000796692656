import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { reduxForm, formValues } from "redux-form";
import { compose } from "recompose";
import get from "lodash/get";

import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { Tooltip } from "Components/Flows/Tooltip";
import { ContactSupportButton } from "Components/Buttons/ContactSupportButton";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import ClaimsReimbursementProtectionPlanTips from "./ClaimsReimbursementProtectionPlanTips";

export const ClaimsReimbursmentDetailsTip = compose(
  reduxForm({ form: "CLAIMS_REIMBURSEMENT", destroyOnUnmount: false }),
  formValues("rentalId")
)(({ rentalId }) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data: rentalProtectionPlanData } = useQuery(RENTAL_REQUEST_QUERY, {
    fetchPolicy: "cache-only",
    variables: {
      id: rentalId
    },
    client: currentRooftopClient
  });

  const rentalProtectionPlan = get(
    rentalProtectionPlanData,
    "rental.currentRentalPeriod.protectionPlan"
  );

  return (
    <>
      <Tooltip
        header="How much can I claim?"
        paragraphs={[
          props => (
            <div {...props}>
              <ClaimsReimbursementProtectionPlanTips
                plan={rentalProtectionPlan?.toLowerCase()}
              />
            </div>
          )
        ]}
      />
      <Tooltip
        header="You will need to submit proof"
        paragraphs={[
          "To receive reimbursement, you will need to submit proof so our agents can verify your eligibility. On the next page, you’ll be able to upload images.",
          <ContactSupportButton
            key="15304286-96a5-44ed-9b9a-c7a353d40d00"
            preText="Need help? "
            buttonText="CONTACT SUPPORT"
          />
        ]}
      />
    </>
  );
});
