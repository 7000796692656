import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DialogPaddingContainer } from "../DialogPaddingContainer";
import ProtectionPlanCard from "./ProtectionPlanCard";

import makeStyles from "@mui/styles/makeStyles";
import CsvDownload from "Components/Utils/CsvDownload";

const CSV_FILE_NAME = "Cars_Not_Updated.csv";

const FAILURE_COPY = `However, some cars failed to update.`;

const SUCCESS_COPY = `You can update your plan at anytime, and can even have different plans on 
          every car you list! Updates to plans do not affect any of your current 
          active rentals.`;

const CSV_HEADERS = ["VIN", "Make", "Model", "Year"];

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: 0,
    paddingTop: 0
  },
  coverageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(3)
  },
  protectionPlanContainer: {
    width: "100%",
    maxWidth: 500,
    marginTop: theme.spacing(2)
  },
  csvDownloadContainer: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  csvDownloadCopy: {
    marginLeft: theme.spacing(1)
  }
}));

const ResultsStep = ({ plan, failedUpdates }) => {
  const classes = useStyles();

  const updateFailed = !!failedUpdates.length;

  const getScreenCopy = () => (updateFailed ? FAILURE_COPY : SUCCESS_COPY);

  const getCSVData = () =>
    failedUpdates.map(car => [car.vin, car.make, car.model, car.year]);

  return (
    <>
      <DialogPaddingContainer extraRightPadding={1} extraLeftPadding={1}>
        <Typography paragraph gutterBottom>
          {getScreenCopy()}
        </Typography>
        {updateFailed && (
          <div className={classes.csvDownloadContainer}>
            <CsvDownload
              headers={CSV_HEADERS}
              data={getCSVData()}
              fileName={CSV_FILE_NAME}
            />
            <Typography component="span" className={classes.csvDownloadCopy}>
              Download the list of cars that failed to update. You can update
              your vehicle plan at any time in your listings page. These changes
              won’t affect your active rentals.
            </Typography>
          </div>
        )}
      </DialogPaddingContainer>
      <DrawerPaddingContainer className={classes.container}>
        <div className={classes.coverageContainer}>
          <Typography variant="h6">Your Updated Coverage:</Typography>
          <div className={classes.protectionPlanContainer}>
            <ProtectionPlanCard plan={{ ...plan, recommended: false }} />
          </div>
        </div>
        <Typography variant="body1">
          Learn more about our recent product updates{" "}
          <Link
            href="https://www.hyrecar.com/blog/owner-product-updates-september-2021"
            target="_blank"
            underline="hover"
          >
            here
          </Link>
          .
        </Typography>
      </DrawerPaddingContainer>
    </>
  );
};

export default ResultsStep;
