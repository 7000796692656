import React from "react";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1)
  }
}));

export const DrawerCloseButton = ({ onClick, useBackButton }) => {
  const classes = useStyles();

  return (
    <Fab onClick={onClick} size="small" className={classes.root}>
      {useBackButton ? <ArrowBackIcon /> : <CloseIcon />}
    </Fab>
  );
};

DrawerCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  useBackButton: PropTypes.bool
};
