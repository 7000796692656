import { gql } from "@apollo/client";

import { RentalMutationFragments } from "./RentalMutationFragments";

export const CONFIRM_PICKUP = gql`
  mutation ConfirmPickup(
    $rentalId: ID!
    $carBackImage: Upload!
    $carFrontImage: Upload!
    $carGasLevelImage: Upload!
    $carMileageImage: Upload!
    $carPassengerSideImage: Upload!
    $carDriverSideImage: Upload!
    $isDriverIdentified: Boolean!
  ) {
    confirmPickup(
      data: {
        rentalId: $rentalId
        carBackImage: $carBackImage
        carFrontImage: $carFrontImage
        carGasLevelImage: $carGasLevelImage
        carMileageImage: $carMileageImage
        carPassengerSideImage: $carPassengerSideImage
        carDriverSideImage: $carDriverSideImage
        isDriverIdentified: $isDriverIdentified
      }
    ) {
      ...ConfirmPickupPayload
    }
  }
  ${RentalMutationFragments.confirmPickupPayload}
`;

export const END_RENTAL = gql`
  mutation EndRental(
    $rentalId: ID!
    $createClaim: Boolean!
    $hasPhysicalDamage: Boolean!
    $surveyComment: String!
  ) {
    confirmDropoff(
      data: {
        rentalId: $rentalId
        createClaim: $createClaim
        hasPhysicalDamage: $hasPhysicalDamage
        surveyComment: $surveyComment
      }
    ) {
      ...ConfirmDropoffPayload
    }
  }
  ${RentalMutationFragments.confirmDropoffPayload}
`;

export const REJECT_BOOKING = gql`
  mutation RejectBooking($rentalId: ID!) {
    rejectBooking(data: { rentalId: $rentalId }) {
      ...ApproveRejectBookingPayload
    }
  }
  ${RentalMutationFragments.approveRejectBookingPayload}
`;

export const APPROVE_BOOKING = gql`
  mutation ApproveBooking($rentalId: ID!) {
    approveBooking(data: { rentalId: $rentalId }) {
      ...ApproveRejectBookingPayload
    }
  }
  ${RentalMutationFragments.approveRejectBookingPayload}
`;

export const TOGGLE_RENTAL_EXTENSION = gql`
  mutation ToggleRentalExtension($rentalId: ID!) {
    toggleRentalExtension(data: { rentalId: $rentalId }) {
      ...ToggleRentalExtensionPayload
    }
  }
  ${RentalMutationFragments.toggleRentalExtensionPayload}
`;

export const SWITCH_CAR = gql`
  mutation SwitchCar(
    $rentalId: ID!
    $switchToCarId: ID!
    $carSwitchReason: SwitchCarReason!
    $snoozeCurrentCar: Boolean
  ) {
    ownerSwitchCar(
      where: {
        rentalId: $rentalId
        switchToCarId: $switchToCarId
        carSwitchReason: $carSwitchReason
        snoozeCurrentCar: $snoozeCurrentCar
      }
    ) {
      ...SwitchRentalCarPayload
    }
  }
  ${RentalMutationFragments.switchRentalCarPayload}
`;

export const CHANGE_DAILY_PRICE_FOR_ACTIVE_RENTAL = gql`
  mutation ChangeDailyPriceForActiveRental(
    $rentalId: String!
    $dailyPriceInCents: Int!
  ) {
    changeDailyPriceForActiveRental(
      rentalId: $rentalId
      dailyPriceInCents: $dailyPriceInCents
    ) {
      id
      createdAt
      updatedAt
    }
  }
`;
