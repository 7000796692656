import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles(theme => ({
  skeleton: {
    height: theme.spacing(7),
    width: "100%"
  }
}));

export const FormSelect = ({
  label,
  options,
  input,
  meta: { touched, invalid, error },
  renderValue,
  showSkeleton,
  id,
  ...custom
}) => {
  const classes = useStyles();

  return showSkeleton ? (
    <Skeleton
      variant="rectangular"
      classes={{ rectangular: classes.skeleton }}
    />
  ) : (
    <FormControl error={touched && invalid} {...custom}>
      <InputLabel htmlFor={input.name} shrink={true}>
        {label}
      </InputLabel>
      <Select
        id={id && id}
        value={input.value}
        onChange={input.onChange}
        inputProps={{
          name: input.name,
          id: input.name,
          onBlur: input.onBlur
        }}
        renderValue={renderValue}
      >
        {options.map(({ value, label }, index) => {
          return (
            <MenuItem value={value} key={index}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    invalid: PropTypes.bool,
    touched: PropTypes.bool
  }),
  renderValue: PropTypes.func,
  showSkeleton: PropTypes.bool
};
