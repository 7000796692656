import {
  API_DATA_UPDATE,
  API_DATA_LOADING,
  API_META_DATA_UPDATE
} from "../actions/apiDataActions";

export default (state = {}, action = {}) => {
  switch (action.type) {
    case API_META_DATA_UPDATE:
      if (typeof action.payload === "undefined") {
        return state;
      }
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          meta: action.payload.value
        }
      };
    case API_DATA_UPDATE:
      if (typeof action.payload === "undefined") {
        return state;
      }
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: action.payload.value
        }
      };
    case API_DATA_LOADING:
      if (typeof action.payload === "undefined") {
        return state;
      }
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          loading: action.payload.value
        }
      };
    default:
      return state;
  }
};
