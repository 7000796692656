import scriptLoader from "react-async-script-loader";

import config from "Config/config";

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${
    config.GOOGLE_MAPS_API_KEY
  }&libraries=places`,
  "https://js.stripe.com/v3/"
]);
