/* eslint-disable react/jsx-key */
import React from "react";
import { useHistory } from "react-router";
import Paper from "@mui/material/Paper";
import HelpIcon from "@mui/icons-material/HelpOutline";
import Button from "@mui/material/Button";

import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { Table } from "Components/Tables/Table";
import { TableHead } from "Components/Tables/TableHead";

import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { RouteEnum } from "Enums/RouteEnum";
import LoadingComponent from "Fleet/Dashboard/DashboardViews/Financials/Tables/LoadingComponent";
import ErrorController from "Fleet/Dashboard/DashboardViews/Financials/Tables/ErrorController";
import EmptyPlaceholder from "Fleet/Dashboard/DashboardViews/Financials/Tables/EmptyPlaceholder";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(() => ({
  amountDisplayedRow: {
    alignItems: "center",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    justifyContent: "space-around",
    padding: "16px"
  },
  loadMoreRow: {
    color: "#03A9F4",
    cursor: "pointer",
    display: "flex",
    padding: "16px",
    margin: "auto"
  },
  statusHeader: {
    alignItems: "center",
    cursor: "auto",
    display: "flex",
    justifyContent: "flex-start"
  },
  statusHeaderMobile: {
    alignItems: "center",
    cursor: "auto",
    display: "flex",
    justifyContent: "center"
  },
  statusHeaderText: {
    marginRight: "4px"
  },
  statusHeaderIcon: {
    color: "#9e9e9e"
  },
  chipPaid: {
    background: "#ECF9F3",
    color: "#388E3C"
  },
  chipProcessing: {
    background: "#FFF5E5",
    color: "#F57C00"
  },
  chipPending: {
    background: "#FAFAFA",
    color: "#666666"
  },
  paper: {
    border: "1px solid #E5E5E5"
  },
  mobileEmptyRow: {
    paddingTop: "40px"
  },
  loadingErrorContainer: {
    margin: "40px 0"
  },
  noPayoutsContainer: {
    margin: "40px 0 80px"
  },
  netEarningsTD: {
    whiteSpace: "nowrap"
  }
}));

export const StatusHeader = ({ mobile }) => {
  const classes = useStyles();
  const containerClass = mobile
    ? classes.statusHeaderMobile
    : classes.statusHeader;
  return (
    <div className={containerClass}>
      <span className={classes.statusHeaderText}>Status</span>
      <HelpIcon className={classes.statusHeaderIcon} />
    </div>
  );
};

const statusTooltip = (
  <React.Fragment>
    <p style={{ fontSize: "12px", letterSpacing: "0.4px", lineHeight: "15px" }}>
      <b>Pending</b>: Transactions have been grouped into a payout, and Funds
      are on the way to your bank.
    </p>
    <p style={{ fontSize: "12px", letterSpacing: "0.4px", lineHeight: "15px" }}>
      <b>Paid</b>: Funds have arrived to your bank.
    </p>
  </React.Fragment>
);

const columns = [
  {
    title: "Available On",
    field: "availableOn"
  },
  {
    title: <StatusHeader />,
    field: "status",
    tooltip: statusTooltip
  },
  {
    title: "Bank Account",
    field: "bankAccount",
    width: "65%"
  },
  {
    title: "Net Earnings",
    field: "netEarnings",
    align: "right"
  }
];

const mobileColumns = [
  {
    title: "Payout Date",
    field: "availableOn"
  },
  {
    title: <StatusHeader mobile />,
    field: "status",
    tooltip: statusTooltip,
    align: "center"
  },
  {
    title: "Net Earnings",
    field: "netEarnings",
    align: "right"
  }
];

const StatusChip = ({ status }) => {
  const classes = useStyles();

  let chipClass = "";
  if (status === "Paid") {
    chipClass = classes.chipPaid;
  }
  if (status === "In Transit") {
    chipClass = classes.chipProcessing;
  }
  if (status === "Pending") {
    chipClass = classes.chipPending;
  }

  return <Chip className={chipClass} label={status}></Chip>;
};

export const PayoutsTable = ({
  data,
  fetchMore,
  loading,
  error,
  dateFilterHandlers
}) => {
  const classes = useStyles();
  const onRowClick = thisData =>
    history.push(`${RouteEnum.financialsPayoutDetails}/${thisData.payoutId}`);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const renderLoadMoreButton = () => {
    if (!data.hasMore) return null;
    const lastPayoutId = data.payouts[data.payouts.length - 1].payoutId;

    return (
      <Button
        className={classes.loadMoreRow}
        disabled={loading}
        onClick={() => {
          if (!loading) fetchMore(lastPayoutId);
        }}
      >
        SEE MORE ACTIVITY
      </Button>
    );
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableHead
        tableName="Payouts"
        hideSearch
        dateFilterHandlers={dateFilterHandlers}
      />
      {loading && !data.payouts && (
        <div className={classes.loadingErrorContainer}>
          <LoadingComponent subHeader="Just a moment. We're loading all of your payouts." />
        </div>
      )}
      {error && (
        <div className={classes.loadingErrorContainer}>
          <ErrorController errorResponse={error} tableType="payout" />
        </div>
      )}
      {data?.payouts &&
        (data.payouts.length === 0 ? (
          <Grid item className={classes.mobileEmptyRow} xs={12}>
            <div className={classes.noPayoutsContainer}>
              <EmptyPlaceholder
                header="No Payouts"
                subHeader="You don't have any payouts yet. Once a payout is created, it will appear here."
              />
            </div>
          </Grid>
        ) : (
          <>
            <Table
              columns={matches ? mobileColumns : columns}
              data={
                data.payouts.map(payout => ({
                  ...payout,
                  netEarnings: (
                    <div className={classes.netEarningsTD}>
                      {
                        <NumberFormat
                          value={payout.netEarnings}
                          thousandSeparator={true}
                          displayType={"text"}
                          prefix={"$"}
                        />
                      }
                    </div>
                  ),
                  status: <StatusChip status={payout.status} />
                })) ?? []
              }
              mobileChevron={matches}
              noDataCellLabel="-"
              onRowClick={onRowClick}
              tableGrows
            />
            {renderLoadMoreButton()}
          </>
        ))}
    </Paper>
  );
};
