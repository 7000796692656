import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DescriptionIcon from "@mui/icons-material/Description";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import moment from "moment";
import PropTypes from "prop-types";

import { RENTAL_CONTRACTS } from "Queries/Rentals/RentalQueries";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { OptionCard } from "Components/Cards/OptionCard";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

export const RentalDocuments = ({ sendToRentalSummary }) => {
  const classes = useStyles();
  const { id } = useParams();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(RENTAL_CONTRACTS, {
    variables: {
      id: id
    },
    fetchPolicy: "network-only",
    client: currentRooftopClient
  });
  const rentalPeriods = get(data, "rental.rentalPeriods", []);

  return (
    <>
      <DrawerAppBar
        title="Rental Documents"
        border
        icon={
          <IconButton onClick={sendToRentalSummary} size="large">
            <ChevronLeftIcon />
          </IconButton>
        }
      />
      {loading ? (
        <SkeletonDrawer />
      ) : (
        <DrawerPaddingContainer>
          {rentalPeriods.map((rentalPeriod, index) => {
            return (
              <Link
                key={index}
                href={get(rentalPeriod, "rentalContract.url")}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
              >
                <OptionCard marginBottom pointer>
                  <div className={classes.root}>
                    <DescriptionIcon className={classes.icon} />
                    <Typography component="span" variant="h6">
                      {`${moment(rentalPeriod.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(rentalPeriod.endDate).format(
                        "MM/DD/YYYY"
                      )}`}
                    </Typography>
                  </div>
                </OptionCard>
              </Link>
            );
          })}
        </DrawerPaddingContainer>
      )}
    </>
  );
};

RentalDocuments.propTypes = {
  sendToRentalSummary: PropTypes.func
};
