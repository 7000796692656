import React, { useState, createContext, useContext, useEffect } from "react";
import jwtDecode from "jwt-decode";

import { ClientFactoryContext } from "./ClientProvider";
import { currentUserClient } from "Components/Utils/ApolloProvider";

export const TosContext = createContext();

const isSignupShouldBypass = () => {
  const entry = localStorage?.getItem && localStorage.getItem("id_token");
  try {
    const decoded = jwtDecode(entry);
    const isSignup = decoded?.authentication?.sign_up;
    return Boolean(isSignup);
  } catch (error) {
    // eslint-disable-next-line no-empty
  }
};

export const TosProvider = ({ children }) => {
  const [rooftopTos, setRooftopTos] = useState(false);
  const [bypassTermsOfServiceRequirement, setBypass] = useState(false);

  useEffect(() => {
    const bypass = isSignupShouldBypass();
    setBypass(bypass);
  }, []);

  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const client = rooftopTos ? currentRooftopClient : currentUserClient;

  return (
    <TosContext.Provider
      value={{
        rooftopTos,
        setRooftopTos,
        client,
        bypassTermsOfServiceRequirement
      }}
    >
      {children}
    </TosContext.Provider>
  );
};
