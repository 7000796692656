import {
  hideZendeskChat,
  loginZendeskChat,
  logoutZendeskChat,
  showZendeskChat
} from "Utils/zendeskChat";
import { useEffect } from "react";

const useZendeskChat = () => {
  const zendeskClient = window.zE;

  useEffect(() => {
    loginZendeskChat(zendeskClient);
    showZendeskChat(zendeskClient);
    return () => {
      hideZendeskChat(zendeskClient);
      logoutZendeskChat(zendeskClient);
    };
  }, []);
};

export default useZendeskChat;
