import { compose, withProps } from "recompose";
import Sendbird from "../utils/sendbird";
import config from "Config/config";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";

import withMessaging from "./withMessaging";
import { optionalChaining } from "Driver/utils/helpers";

const withMessagingManager = compose(
  withMessaging,
  withProps(
    ({
      setMessagingLoadingInRedux,
      updateMessagingInRedux,
      updateMessagingChannelsInRedux
    }) => {
      return {
        updateMessagingClient: currentUser => {
          if (
            currentUser === null ||
            currentUser.owner === undefined ||
            currentUser.driver === undefined
          ) {
            return;
          }
          setMessagingLoadingInRedux(true);
          const updateClientInRedux = ({
            client: connectedClient,
            channelsList
          }) => {
            updateMessagingInRedux({
              client: connectedClient
            });
            const updateChannelsListRedux = () =>
              connectedClient.getChannelListFromBeginning(channels =>
                updateMessagingChannelsInRedux({
                  channels: {
                    loading: false,
                    items: Array.isArray(channels) ? [...channels] : [channels]
                  }
                })
              );
            updateChannelsListRedux(channelsList);
            connectedClient.createHandlerGlobal(
              updateChannelsListRedux,
              updateChannelsListRedux,
              updateChannelsListRedux,
              updateChannelsListRedux,
              updateChannelsListRedux,
              updateChannelsListRedux,
              updateChannelsListRedux,
              updateChannelsListRedux,
              "CHANNELS_LIST"
            );
            setMessagingLoadingInRedux(false);
          };

          const client = new Sendbird(config.SENDBIRD_APP_ID);
          if (client.isConnected()) {
            client.disconnect(() => {
              connectClient(client, currentUser).then(updateClientInRedux);
            });
          } else {
            connectClient(client, currentUser).then(updateClientInRedux);
          }
        }
      };
    }
  )
);

export default withMessagingManager;

function connectClient(client, currentUser) {
  return new Promise(resolve => {
    const userId = (
      (currentUser.type === "DRIVER"
        ? currentUser.driver.id
        : currentUser.owner.id) || "1"
    ).toString();
    const nickname = `${upperFirst(
      get(currentUser, "firstName", "")
    )} ${upperFirst(get(currentUser, "lastName", "")).charAt(0)}.`;
    client.connect(
      {
        userId: userId,
        nickname: nickname,
        profileUrl: optionalChaining(() => currentUser.profilePhoto.url)
      },
      channelsList => {
        resolve({ client, channelsList });
      }
    );
  });
}
