import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: ({
      extraTopPadding,
      extraRightPadding,
      extraBottomPadding,
      extraLeftPadding
    }) =>
      theme.spacing(
        extraTopPadding ? extraTopPadding : 0,
        extraRightPadding ? 2 + extraRightPadding : 2,
        extraBottomPadding ? extraBottomPadding : 0,
        extraLeftPadding ? 2 + extraLeftPadding : 2
      )
  }
}));

export const DialogPaddingContainer = ({
  children,
  extraTopPadding,
  extraRightPadding,
  extraBottomPadding,
  extraLeftPadding
}) => {
  const classes = useStyles({
    extraTopPadding,
    extraRightPadding,
    extraBottomPadding,
    extraLeftPadding
  });

  return <div className={classes.root}>{children}</div>;
};

DialogPaddingContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  extraTopPadding: PropTypes.number,
  extraRightPadding: PropTypes.number,
  extraBottomPadding: PropTypes.number,
  extraLeftPadding: PropTypes.number
};
