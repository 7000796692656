import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import get from "lodash/get";

import { Card } from "./Card";
import { CardCell } from "./CardCell";
import { CardHeader } from "./CardHeader";
import {
  APPLIED_RENTALS_AGGREGATE,
  LATE_RENTALS_AGGREGATE
} from "Queries/Fleet/ActionNeededQuery";
import { RouteEnum } from "Enums/RouteEnum";
import { client } from "Components/Utils/ApolloProvider";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { RentalStatusEnum } from "Enums/StateEnums";

const linkStyle = { cursor: "pointer" };

export const ActionNeededCard = () => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const history = useHistory();

  const { data: appliedRentalData, loading: appliedRentalLoading } = useQuery(
    APPLIED_RENTALS_AGGREGATE,
    {
      fetchPolicy: "network-only",
      client: currentRooftopClient ? currentRooftopClient : client,
      context: {
        important: true
      }
    }
  );
  const { data: lateRentalData, loading: lateRentalLoading } = useQuery(
    LATE_RENTALS_AGGREGATE,
    {
      fetchPolicy: "network-only",
      client: currentRooftopClient ? currentRooftopClient : client,
      context: {
        important: true
      }
    }
  );

  const goToRentalsRequests = () => {
    history.push(RouteEnum.rentalsRequests);
  };

  const goToLateRentals = () => {
    history.push(RouteEnum.rentalsActive, { filters: [RentalStatusEnum.late] });
  };

  return (
    <Card>
      <CardHeader
        label="Action Needed"
        to={RouteEnum.rentalsRequests}
        link={"View More"}
      />
      <Grid container>
        <Grid item xs={6} onClick={goToRentalsRequests} style={linkStyle}>
          <CardCell
            value={get(
              appliedRentalData,
              "viewer.me.owner.rentalsConnection.aggregate.count"
            )}
            label="Rental Requests"
            loading={appliedRentalLoading}
          />
        </Grid>
        <Grid item xs={6} onClick={goToLateRentals} style={linkStyle}>
          <CardCell
            value={get(
              lateRentalData,
              "viewer.me.owner.rentalsConnection.aggregate.count"
            )}
            label="Late Rentals"
            loading={lateRentalLoading}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
