import React from "react";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@apollo/client";
import get from "lodash/get";

import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { currentUserClient } from "Components/Utils/ApolloProvider";

const useStyles = makeStyles(() => ({
  userAvatar: {
    width: "45px",
    height: "45px",
    cursor: "pointer"
  }
}));

export const UserAvatar = ({ onClick, id }) => {
  const { data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-only",
    client: currentUserClient
  });
  const classes = useStyles();

  return (
    <Avatar
      src={get(data, "viewer.me.profilePhoto.url")}
      alt="User Profile"
      className={classes.userAvatar}
      onClick={onClick}
      id={id}
    >
      <AccountCircleIcon />
    </Avatar>
  );
};
