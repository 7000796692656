import React from "react";

import { NIL as staleCheckId } from "uuid";
import { useQuery } from "react-query";

import { hephaestusDriverVetting } from "Axios/instances";

import { Badge } from "./Badge";

export const DriverPhoneVerificationBadge = ({ phoneNumber, userId }) => {
  const enrichedPhoneNumber =
    phoneNumber.length === 10 ? `1${phoneNumber}` : phoneNumber;

  const response = useQuery(
    [phoneNumber],
    () =>
      hephaestusDriverVetting
        .post("/driver/phone-verification-status-by-phone-number-and-user-id", {
          phoneNumber: enrichedPhoneNumber,
          userId,
          meta: { staleCheckId }
        })
        .then(response => response?.data),
    {
      enabled: !!phoneNumber && !!userId,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  if (!response?.isSuccess) return null;

  const verified = response?.data?.isPhoneVerified;

  if (verified) return <Badge />;

  return null;
};
