import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { ActionDialog } from "Components/Dialog/ActionDialog";

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  divider: {
    margin: theme.spacing(1, 0, 2, 0)
  }
}));

export const RolesDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <ActionDialog
      open={open}
      onClose={handleClose}
      title="Roles"
      maxWidth="md"
      content={
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <Typography
              className={classes.subtitle}
              component="h6"
              variant="subtitle1"
            >
              Account Owner
            </Typography>
            <Divider className={classes.divider} />
            <Typography component="p" variant="body2" gutterBottom>
              The Account Owner can view and modify everything across all
              locations including dashboard, rentals, listing, financials, and
              messages. They can create listings, export financial reports,
              modify rooftop settings, and manage team permissions. There can
              only be one account owner for a business.
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography
              className={classes.subtitle}
              component="h6"
              variant="subtitle1"
            >
              Adminstrator
            </Typography>
            <Divider className={classes.divider} />
            <Typography component="p" variant="body2" gutterBottom>
              Administrators have access to view and modify the dashboard,
              rentals, listings, financials and messages at the rooftops that
              they are assigned to. They can create listings, rexport financial
              reports, and manage team permissions at their rooftops.
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography
              className={classes.subtitle}
              component="h6"
              variant="subtitle1"
            >
              Agent
            </Typography>
            <Divider className={classes.divider} />
            <Typography component="p" variant="body2" gutterBottom>
              Agents can view and modify the dashboard, rentals, listings, and
              messages at the location(s) they are assigned to. Generally,
              agents handle key exchange and communications directly with
              drivers.
            </Typography>
          </Grid>
        </Grid>
      }
      action={handleClose}
      actionLabel="Close"
    />
  );
};
