import { useContext } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { PAYOUTS_QUERY } from "Queries/Payouts/PayoutsQueries";

const dateFormatString = "MMM D, YYYY";
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

/**
 *  Retrieve the most recent previous payout that has a status of "paid".
 *  This is assuming the incoming data is already sorted from most to least
 *  recent.
 */
const getPreviousPayout = data => {
  const previousPayout =
    data?.viewPayouts?.payouts?.filter(
      payout => payout.status.toLowerCase() === "paid"
    )?.[0] ?? null;
  const mutablePayout = previousPayout && { ...previousPayout };
  if (mutablePayout && mutablePayout.netEarningsInCents) {
    mutablePayout.netEarnings = formatter.format(
      mutablePayout.netEarningsInCents / 100
    );
  }
  return mutablePayout;
};

/**
 *  Calculate the Month to Date Earnings and format the value into a
 *  string dollar amount.
 */
const getMTDNetEarnings = data => {
  const mtdNetEarningsInCents = data?.viewPayouts?.payouts?.reduce(
    (acc, payout) => {
      const payoutMonth =
        typeof payout.availableOn === "number"
          ? moment.unix(payout.availableOn).month()
          : moment(payout.availableOn).month();
      const currentMonth = moment().month();
      if (currentMonth === payoutMonth) acc += payout.netEarningsInCents;
      return acc;
    },
    0
  );
  return formatter.format(mtdNetEarningsInCents / 100);
};

/**
 *  Takes the payouts and formats dates to MM/DD/YYYY. Returns an array of payouts.
 */
const formatPayoutsForTable = queryData => {
  return queryData?.viewPayouts?.payouts?.map(payout => ({
    ...payout,
    netEarnings: `+ ${payout.netEarnings}`,
    availableOn: moment.unix(payout.availableOn).format(dateFormatString)
  }));
};

/**
 *  Adds commas to next payout amount
 */
const formatNextPayout = nextPayoutString => {
  if (nextPayoutString && parseFloat(nextPayoutString.substring(1))) {
    return formatter.format(parseFloat(nextPayoutString.substring(1)));
  }
  return nextPayoutString;
};

export const usePayouts = (payoutsPerPage, startDate, endDate) => {
  const { currentRooftopClient, currentRooftopToken } = useContext(
    ClientFactoryContext
  );

  let inputObject = {
    paginate: {
      payoutsPerPage
    }
  };
  if (startDate && endDate) {
    inputObject.timeRange = {
      startDate: startDate,
      endDate: endDate
    };
  }
  const { data, loading, error, fetchMore } = useQuery(PAYOUTS_QUERY, {
    variables: {
      input: inputObject
    },
    client: currentRooftopClient,
    context: {
      apiv2: true,
      headers: {
        "x-rooftop-authorization": currentRooftopToken
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const fetchAdditionalPayouts = payoutId => {
    fetchMore({
      variables: {
        input: { paginate: { payoutsPerPage, startAfter: payoutId } }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          viewPayouts: {
            ...prev.viewPayouts,
            hasMore: fetchMoreResult.viewPayouts.hasMore,
            payouts: prev.viewPayouts.payouts.concat(
              fetchMoreResult.viewPayouts.payouts
            )
          }
        };
      }
    });
  };

  return {
    loading,
    error,
    data: {
      table: {
        hasMore: data?.viewPayouts?.hasMore,
        payouts: formatPayoutsForTable(data)
      },
      cards: {
        estimatedNextPayouts: formatNextPayout(
          data?.viewPayouts?.estimatedNextPayouts
        ),
        previousPayout: getPreviousPayout(data) ?? {
          availableOn: null,
          netEarnings: "$0.00"
        },
        mtdNetEarnings: getMTDNetEarnings(data)
      }
    },
    fetchMore: fetchAdditionalPayouts
  };
};
