export const DISABLE_HEADER = "DISABLE_HEADER";
export const ENABLE_HEADER = "ENABLE_HEADER";
export const SET_TITLE = "SET_TITLE";
export const RESET_TITLE = "RESET_TITLE";
export const ON_SEARCH_CHANGE = "ON_SEARCH_CHANGE";
export const RESET_SEARCH = "RESET_SEARCH";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const SELECT_FILTER = "SELECT_FILTER";
export const DESELECT_FILTER = "DESELECT_FILTER";
