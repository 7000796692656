import React from "react";
import { Field, formValues } from "redux-form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import makeStyles from "@mui/styles/makeStyles";

import { MAX_LOSS_DESCRIPTION_CHARACTERS } from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";
import { LocationAutoComplete } from "Components/Forms/Location/LocationAutoComplete";
import { FormDatePicker } from "Components/Inputs/FormDatePicker";
import { FormTextField } from "Components/Inputs/FormTextField";
import { FormTimePicker } from "Components/Inputs/FormTimePicker";
import { FormRadioGroup } from "Components/Inputs/FormRadioGroup";
import { FormSelect } from "Components/Inputs/FormSelect";

const injuryOptions = [
  {
    label: "Yes",
    value: "yes",
    id: "yes-injured"
  },
  {
    label: "No",
    value: "no",
    id: "no-injured"
  },
  {
    label: "I'm not sure",
    value: "im not sure",
    id: "notsure-injured"
  }
];
const otherAppsOptions = [
  {
    label: "Yes",
    value: "yes",
    id: "yes-gig-on"
  },
  {
    label: "No",
    value: "no",
    id: "no-gig-off"
  },
  {
    label: "I'm not sure",
    value: "im not sure",
    id: "notsure-gig"
  }
];
const policeReportOptions = [
  {
    label: "Yes",
    value: "yes",
    id: "yes-report"
  },
  {
    label: "No",
    value: "no",
    id: "no-report"
  },
  {
    label: "I'm not sure",
    value: "im not sure",
    id: "notsure-report"
  }
];

const options = [
  {
    label: "Amazon Flex",
    value: "Amazon Flex"
  },
  {
    label: "Doordash",
    value: "Doordash"
  },
  {
    label: "GrubHub",
    value: "GrubHub"
  },
  {
    label: "Instacart",
    value: "Instacart"
  },
  {
    label: "Postmates",
    value: "Postmates"
  },
  {
    label: "Uber",
    value: "Uber"
  },
  {
    label: "Other",
    value: "Other"
  }
];

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(4)
  },
  spacingTop: {
    marginTop: theme.spacing(4)
  }
}));

const Other = ({ classes }) => (
  <Field
    className={classes.spacingTop}
    component={FormTextField}
    fullWidth
    id="claim-other-driver-services"
    label="Enter"
    name="otherDriverServices"
    variant="filled"
  />
);

export const DriverServicesFormFields = ({ classes }) => (
  <>
    <Typography
      className={classes.spacingTop}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Services
    </Typography>
    <Field
      autoOk
      component={FormSelect}
      fullWidth
      id="claim-driver-services"
      label="Which services was driver driving for?"
      name="driverServices"
      options={options}
      variant="filled"
    />
  </>
);

export const PoliceReportFormFields = ({ classes }) => (
  <>
    <Typography
      className={classes.spacing}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Police Report Details
    </Typography>
    <Field
      component={FormTextField}
      fullWidth
      label="What is the police report number? "
      margin="normal"
      name="policeReportCaseNumber"
      variant="filled"
    />
    <Typography
      className={classes.spacing}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Police Department
    </Typography>
    <Field
      component={FormTextField}
      fullWidth
      label="Which police department was it filed?"
      margin="normal"
      name="policeReportDepartment"
      variant="filled"
    />
  </>
);

export const CauseOfDamageFormFields = formValues({
  gigAppOn: "gigAppOn",
  driverServices: "driverServices",
  policeReportFiled: "policeReportFiled"
})(({ gigAppOn, driverServices, policeReportFiled }) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="h6" variant="h6" className={classes.spacing}>
        Please answer the following to the best of your knowledge.
      </Typography>
      <Typography component="p" variant="subtitle1" gutterBottom>
        At what location did the damage occur?
      </Typography>
      <Field
        name="lossAddress"
        component={LocationAutoComplete}
        id="accident-address"
        inputLabel="Address"
      />
      <Typography component="p" variant="subtitle1" gutterBottom>
        When did the damage happen?
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Field
            name="lossDate"
            label="Select date"
            component={FormDatePicker}
            id="accident-date"
            inputVariant="filled"
            fullWidth
            disableFuture
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Field
            name="lossTime"
            component={FormTimePicker}
            id="accident-time"
            inputVariant="filled"
            fullWidth
            placeholder="Select Time"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton size="large">
                    <QueryBuilderIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Typography
        component="p"
        variant="subtitle1"
        className={classes.spacingTop}
        gutterBottom
      >
        What happened?
      </Typography>
      <Field
        name="lossDescription"
        component={FormTextField}
        id="damage-cause-description"
        fullWidth
        variant="filled"
        label="What happened?"
        placeholder="Describe what caused the damage. The more details the better!"
        multiline
        rows={4}
        characterLimit={MAX_LOSS_DESCRIPTION_CHARACTERS}
      />
      <Typography
        component="p"
        variant="subtitle1"
        className={classes.spacingTop}
      >
        Was the Uber, Lyft, or other gig-economy app ON at the time of damage?
      </Typography>
      <Field
        name="gigAppOn"
        row
        component={FormRadioGroup}
        options={otherAppsOptions}
      />
      {gigAppOn === "yes" && <DriverServicesFormFields classes={classes} />}
      {driverServices === "Other" && <Other classes={classes} />}

      <Typography
        component="p"
        variant="subtitle1"
        gutterBottom
        className={classes.spacingTop}
      >
        Was anybody injured?
      </Typography>
      <Field
        name="bodilyInjuries"
        row
        component={FormRadioGroup}
        options={injuryOptions}
      />
      <Typography
        component="p"
        variant="subtitle1"
        gutterBottom
        className={classes.spacingTop}
      >
        Was a police report filed?
      </Typography>
      <Field
        name="policeReportFiled"
        row
        component={FormRadioGroup}
        options={policeReportOptions}
      />
      {policeReportFiled === "yes" && (
        <PoliceReportFormFields classes={classes} />
      )}
    </>
  );
});
