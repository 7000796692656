import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { destroy } from "redux-form";
import { useMutation } from "@apollo/client";

import { useRental } from "Queries/Rentals/hooks/useRental";
import { StepsEnum } from "./StepEnums";
import SelectReason from "./Steps/SelectReason";
import SelectCar from "./Steps/SelectCar";
import ConfirmDetails from "./Steps/confirmDetails/ConfirmDetails";
import Success from "./Steps/Success";
import { SWITCH_CAR } from "Mutations/Rental/RentalMutations";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import Failure from "./Steps/Failure";

const CAR_SWITCH_FORM = "CAR_SWITCH";

export const CarSwitch = ({ onClose, dispatch }) => {
  const [step, setStep] = useState(StepsEnum.SELECT_REASON);
  const { rental: oldRental } = useRental();
  const [newRental, setNewRental] = useState({});
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [switchCar, { loading }] = useMutation(SWITCH_CAR, {
    client: currentRooftopClient
  });
  const [error, setError] = useState(null);

  useEffect(() => () => dispatch(destroy(CAR_SWITCH_FORM)), [dispatch]);

  const nextStep = () => setStep(prevStep => prevStep + 1);
  const prevStep = () => setStep(prevStep => prevStep - 1);

  const handleClose = () => {
    dispatch(destroy(CAR_SWITCH_FORM));
    onClose();
  };

  const onSubmit = async values => {
    const response = await switchCar({
      variables: {
        rentalId: oldRental.id,
        switchToCarId: values.car.id,
        carSwitchReason: values.reason,
        snoozeCurrentCar: !!values.snooze
      },
      refetchQueries: ["RentalsQuery"]
    });

    if (!response?.data?.ownerSwitchCar?.success) {
      setError({
        insuranceError: response.data.ownerSwitchCar?.insuranceError,
        message: response.data.ownerSwitchCar.message
      });
    }
    const rental = response?.data?.ownerSwitchCar?.rental;
    setNewRental({ ...rental, car: { ...values.car } });
    nextStep();
  };

  const getCurrentStep = () => {
    switch (step) {
      case StepsEnum.SELECT_REASON:
        return (
          <SelectReason
            onClose={handleClose}
            onSubmit={nextStep}
            prevStep={prevStep}
            rental={oldRental}
          />
        );
      case StepsEnum.SELECT_CAR:
        return (
          <SelectCar
            onClose={handleClose}
            onSubmit={nextStep}
            prevStep={prevStep}
            rental={oldRental}
          />
        );
      case StepsEnum.CONFIRM_DETAILS:
        return (
          <ConfirmDetails
            onClose={onClose}
            onSubmit={onSubmit}
            prevStep={prevStep}
            loading={loading}
            rental={oldRental}
          />
        );
      case StepsEnum.SUCCESS:
        return (
          <Success
            onClose={onClose}
            oldRental={oldRental}
            newRental={newRental}
          />
        );
      default:
        throw new Error();
    }
  };

  return error ? (
    <Failure
      error={error}
      onClose={onClose}
      oldRental={oldRental}
      newRental={newRental}
    />
  ) : (
    getCurrentStep()
  );
};

export default connect()(CarSwitch);
