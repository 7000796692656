import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import moment from "moment";
import get from "lodash/get";
import jwtDecode from "jwt-decode";

import { EXPORT_TRANSACTIONS } from "Queries/Transactions/TransactionsQueries";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { emailRegex } from "Utils/Regex";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { client } from "Components/Utils/ApolloProvider";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

export const ExportTransactionsFormController = ({
  children,
  onSubmitSuccess,
  onSubmitFail,
  payoutIds
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    currentRooftopClient,
    currentRooftopToken,
    currentOrganizationInfo
  } = useContext(ClientFactoryContext);

  const response = useQuery(CURRENT_USER_QUERY, {
    client: currentRooftopClient
  });
  const viewer = response?.data?.viewer;

  const onSubmit = async props => {
    const { email0, email1, email2, email3, startDate, endDate } = props;
    try {
      const emails = [email0, email1, email2, email3].reduce((acc, email) => {
        if (email !== undefined) {
          acc.push(email);
        }
        return acc;
      }, []);

      const getISODate = dateString =>
        payoutIds ? null : moment(dateString).toISOString();

      await client.query({
        query: EXPORT_TRANSACTIONS,
        variables: {
          startDate: getISODate(startDate),
          endDate: getISODate(endDate),
          payoutIds: payoutIds || null,
          emails
        },
        context: {
          apiv2: true,
          headers: {
            "x-rooftop-authorization": currentRooftopToken
          }
        },
        fetchPolicy: "network-only"
      });

      enqueueSnackbar(
        "Your export has started. Please allow up to 5 minutes for the file to arrive in your inbox.",
        {
          variant: "success",
          autoHideDuration: 8000
        }
      );
      analytics.track(AnalyticsEvents.label.owner.exportTransactions, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.owner.exportTransactions,
        property: JSON.stringify({
          ownerId: viewer?.me?.owner?.id,
          rooftopId: jwtDecode(currentRooftopToken)?.id,
          memberId: currentOrganizationInfo?.organizationMemberId
        }),
        value: "",
        context: ""
      });
      if (onSubmitSuccess) onSubmitSuccess();
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
      analytics.track(AnalyticsEvents.label.owner.exportTransactionsError, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.errorShown,
        label: AnalyticsEvents.label.owner.exportTransactionsError,
        property: JSON.stringify({
          ownerId: viewer?.me?.owner?.id,
          rooftopId: jwtDecode(currentRooftopToken)?.id,
          memberId: currentOrganizationInfo?.organizationMemberId,
          error: e.message
        }),
        value: "",
        context: ""
      });
      if (onSubmitFail) onSubmitFail();
    }
  };

  const validate = values => {
    const { startDate, endDate } = values;
    const errors = {};

    if (!payoutIds && !startDate) errors.from = "Please input start date.";
    if (!payoutIds && startDate && !endDate)
      errors.to = "Please input end date.";

    if (!payoutIds && startDate && endDate) {
      if (moment(endDate).isSameOrBefore(startDate)) {
        errors.to = "Start date must be before end date.";
      }
    }

    if (!payoutIds && moment(endDate).diff(moment(startDate), "days") > 90)
      errors.to = "The date range must be within 90 days.";

    if (!values.email0) errors.email0 = "Required";
    if (!values.email1) errors.email1 = "Required";
    if (!values.email2) errors.email2 = "Required";
    if (!values.email3) errors.email3 = "Required";
    if (values.email0 && !emailRegex.test(values.email0))
      errors.email0 = "Please enter a valid email";
    if (values.email1 && !emailRegex.test(values.email1))
      errors.email1 = "Please enter a valid email";
    if (values.email2 && !emailRegex.test(values.email2))
      errors.email2 = "Please enter a valid email";
    if (values.email3 && !emailRegex.test(values.email3))
      errors.email3 = "Please enter a valid email";

    return errors;
  };

  const initialValues = {
    email0: get(viewer, "me.email")
  };

  return children({
    onSubmit,
    validate,
    initialValues
  });
};
