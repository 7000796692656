export const transactionsTooltipEnum = {
  earnings: {
    title: "Net Earnings",
    text:
      "Your top line revenue minus your weekly or monthly discounts, protection fee, and reimbursements."
  },
  basePrice: {
    title: "Base Price",
    text:
      "Your daily rate multiplied by the number of days booked. This excludes any weekly or monthly discounts, protection plan fee, and reimbursements."
  },
  protectionFee: {
    title: "Protection Plan Fee",
    text:
      "The small commission (15%, 20%, or 25%) that HyreCar receives in exchange for providing you service and protection against incidentals. You cannot update your protection plan retroactively or during an active rental."
  }
};

export const payoutsTooltipEnum = {
  status: {
    title: "Status",
    text: `
    Pending: Transactions have been grouped into a payout, but funds have not begun transferring to your bank. 

    In Transit: Funds are on the way to your bank. 

    Paid: Funds have arrived to your bank. 

    Failed: Funds could not be transferred to your bank account.
    `
  }
};
export const listingRevenueTooltipEnum = {
  avgDailyEarnings: {
    title: "Average Daily Earnings ",
    text:
      "This is based on your net earnings divided by the number of days paid by the driver. A.D.E. accounts for any discounts and your protection plan fee."
  },
  gross: {
    title: "Gross Earnings",
    text: "Your top line revenue you've earned from your rentals."
  },
  net: {
    title: "Net Earnings",
    text: "Your top line revenue minus any refunds."
  }
};
