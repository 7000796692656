import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Card, Typography } from "@mui/material";
import { orange, red, green } from "@mui/material/colors";

const useStyles = makeStyles({
  root: ({ cardType }) => ({
    borderColor: cardColor.border[cardType],
    backgroundColor: "transparent",
    padding: 12
  }),
  title: ({ cardType }) => ({
    color: cardColor.title[cardType],
    marginBottom: 12
  }),
  subtitle: {
    marginBottom: 12,
    color: "#000000",
    fontWeight: 600
  }
});

const cardColor = {
  border: {
    warning: red[900],
    info: orange[700],
    success: green[700]
  },
  title: {
    warning: red[900],
    info: orange[700],
    success: "#000000"
  }
};

const InfoCard = ({ cardType, title, subtitle, children }) => {
  const classes = useStyles({ cardType });

  return (
    <Card className={classes.root} variant="outlined">
      <Typography className={classes.title} variant="h5" component="h2">
        {title}
      </Typography>
      {subtitle && (
        <Typography className={classes.subtitle} variant="h6" component="h2">
          {subtitle}
        </Typography>
      )}
      {children}
    </Card>
  );
};

export default InfoCard;
