import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import set from "lodash/set";
import { INBOX_MESSAGE_STATUS } from "Components/Inbox/Filters/Filters";

const getChannelReadStatus = channel =>
  channel.unreadMessageCount
    ? INBOX_MESSAGE_STATUS.UNREAD.value
    : INBOX_MESSAGE_STATUS.READ.value;

const checkStatusFilter = (channelStatus, filters) =>
  !(filters.length && !filters.includes(channelStatus));

const checkMessageTypeFilter = (channel, filters) =>
  !(filters.length && !filters.includes(getChannelReadStatus(channel)));

const getSearch = state => state.fleet.sendbird.inboxSearch;
const getFilters = state => state.fleet.sendbird.inboxFilters;
const getChannels = state => state.fleet.sendbird.channels;
const getChannelStatuses = state => state.fleet.sendbird.channelStatuses;
const getUser = state => state.fleet.sendbird.user;

export const filterChannels = createSelector(
  [getSearch, getFilters, getChannels, getChannelStatuses, getUser],
  (search, filters, channels, channelStatuses, user) => {
    let totalUnreadMessages = 0;
    const mappedChannels = map(channels, o => {
      totalUnreadMessages += o.unreadMessageCount;
      if (!o.lastMessage) {
        set(o, "lastMessage.createdAt", 0);
      }
      return o;
    });
    const orderedChannels = orderBy(
      mappedChannels,
      ["unreadMessageCount", "lastMessage.createdAt"],
      ["desc", "desc"]
    );
    const filteredChannels = orderedChannels.filter(channel => {
      let nonUserMember;
      channel.members.forEach(member => {
        if (member.userId !== user.userId) {
          nonUserMember = member;
        }
      });
      return (
        nonUserMember &&
        nonUserMember.nickname &&
        nonUserMember.nickname.toLowerCase().includes(search.toLowerCase()) &&
        checkMessageTypeFilter(channel, filters.messageTypes) &&
        checkStatusFilter(channelStatuses[channel.url], filters.rentalStatus)
      );
    });

    return { filteredChannels, totalUnreadMessages };
  }
);
