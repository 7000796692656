import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Route, useHistory } from "react-router-dom";

import { TitleWithTooltipIcon } from "Components/Tooltips/TitleWithTooltipIcon";
import { TeamTable } from "Fleet/Dashboard/DashboardViews/Settings/Team/TeamTable";
import { MobileTeamTable } from "Fleet/Dashboard/DashboardViews/Settings/Team/MobileTeamTable";
import { useDialog } from "Components/Dialog/hooks/useDialog";
import { useDrawer } from "Components/Drawers/hooks/useDrawer";
import { RolesDialog } from "Components/Dialog/RolesDialog";
import { MemberDrawer } from "Components/Drawers/MemberDrawer";
import { RouteEnum } from "Enums/RouteEnum";
import { TeamEditUserDrawer } from "Components/Drawers/DrawerEditUser";
import { RooftopDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { checkPermission } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const TeamTab = () => {
  const theme = useTheme();
  const { dialogOpen, handleDialogOpen, handleDialogClose } = useDialog(false);
  const { drawerOpen, handleDrawerOpen, handleDrawerClose } = useDrawer(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const { scopes } = useContext(ClientFactoryContext);

  const handleOnRowClick = data => {
    history.push(`${history.location.pathname}/${data.id}`);
  };

  return (
    <>
      <Route
        path={`${RouteEnum.settingsTeam}/:id`}
        render={() => (
          <TeamEditUserDrawer handleHelpDialogOpen={handleDialogOpen} />
        )}
      />
      {matches ? (
        <MobileTeamTable
          fabOnClick={handleDrawerOpen}
          hideFab={
            !checkPermission(RooftopDomainPermissionPaths.createMember, scopes)
          }
          onRowClick={
            checkPermission(RooftopDomainPermissionPaths.updateMember, scopes)
              ? handleOnRowClick
              : null
          }
          initialColumns={[
            {
              title: "Name",
              field: "name"
            },
            {
              title: "Status",
              field: "statusButton",
              align: "left"
            }
          ]}
        />
      ) : (
        <TeamTable
          fabOnClick={handleDrawerOpen}
          hideFab={
            !checkPermission(RooftopDomainPermissionPaths.createMember, scopes)
          }
          onRowClick={
            checkPermission(RooftopDomainPermissionPaths.updateMember, scopes)
              ? handleOnRowClick
              : null
          }
          initialColumns={[
            {
              title: "Name",
              field: "name"
            },
            {
              title: (
                <TitleWithTooltipIcon
                  title="Role"
                  color="primary"
                  onClick={handleDialogOpen}
                />
              ),
              field: "role"
            },
            {
              title: "Rooftop(s)",
              field: "rooftops"
            },
            {
              title: "Status",
              field: "status",
              align: "right"
            },
            {
              title: "",
              field: "statusButton",
              align: "left"
            }
          ]}
        />
      )}
      <RolesDialog open={dialogOpen} handleClose={handleDialogClose} />
      <MemberDrawer open={drawerOpen} handleClose={handleDrawerClose} />
    </>
  );
};
