import React, { useContext, useMemo } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import TransactionsErrorAnimation from "Fleet/Dashboard/DashboardViews/Financials/Tables/Animations/GeneralErrorAnimation";
import TransactionsTimeoutAnimation from "Fleet/Dashboard/DashboardViews/Financials/Tables/Animations/TimeoutErrorAnimation";
import TransactionsNoACHAnimation from "Fleet/Dashboard/DashboardViews/Financials/Tables/Animations/NoACHErrorAnimation";
import TablePlaceholder from "Fleet/Dashboard/DashboardViews/Financials/Tables/TablePlaceholder";
import SetupNowButton from "Fleet/Dashboard/DashboardViews/Financials/Tables/SetupNowButton";

const payoutConfig = customerServicePhoneNumber => ({
  general: {
    header: "Error",
    subHeader:
      "It looks like something went wrong. Try refreshing your browser.",
    subHeaderBottom: (() => (
      <>
        If you are still experiencing issues, please contact Customer Support at
        <br />{" "}
        <a href={`tel:1-${customerServicePhoneNumber}`}>
          {customerServicePhoneNumber}.
        </a>
      </>
    ))(),
    src: TransactionsErrorAnimation,
    style: { height: "auto", width: "160px" }
  },
  noAch: {
    header: "No Payout Account",
    subHeader: "You do not have a Payout Account set up to receive funds.",
    ActionComponent: SetupNowButton,
    src: TransactionsNoACHAnimation,
    style: { height: "auto", width: "160px" }
  },
  timeout: {
    header: "Request Timeout",
    subHeader:
      "This usually happens when it takes too long to load a large set of payouts. Please refresh your browser.",
    src: TransactionsTimeoutAnimation,
    style: { height: "auto", width: "100px" }
  }
});

const transactionsConfig = customerServicePhoneNumber => ({
  general: {
    header: "Error",
    subHeader:
      "It looks like something went wrong. Try refreshing your browser or expand your date range.",
    subHeaderBottom: (() => (
      <>
        If you are still experiencing issues, please contact Customer Support at
        <br />{" "}
        <a href={`tel:1-${customerServicePhoneNumber}`}>
          ${customerServicePhoneNumber}.
        </a>
      </>
    ))(),
    src: TransactionsErrorAnimation,
    style: { height: "auto", width: "160px" }
  },
  noAch: {
    header: "No Payout Account",
    subHeader:
      "We can’t load your transactions because you don’t have a payout account created to receive funds.",
    ActionComponent: SetupNowButton,
    src: TransactionsNoACHAnimation,
    style: { height: "auto", width: "160px" }
  },
  timeout: {
    header: "Request Timeout",
    subHeader:
      "This usually happens when it takes too long to load a large set of transactions. Please try a smaller date range.",
    subHeaderBottom:
      "You can also export your current date range to view your transactions in a CSV format.",
    src: TransactionsTimeoutAnimation,
    style: { height: "auto", width: "100px" }
  }
});

const errorContentConfig = customerServicePhoneNumber => ({
  payout: payoutConfig(customerServicePhoneNumber),
  transaction: transactionsConfig(customerServicePhoneNumber)
});

const ErrorController = ({ errorResponse, tableType = "transaction" }) => {
  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  const checkErrorByString = subString => {
    return errorResponse?.message && errorResponse.message.includes(subString)
      ? true
      : false;
  };

  const timeoutError = checkErrorByString("Failed to fetch");
  const noACHError =
    checkErrorByString(
      "Cannot read property 'stripePayoutInformation' of undefined"
    ) || checkErrorByString("No payout info found for user");

  // Select the type of error that is occurring.
  let errorType = "general";
  if (timeoutError) {
    errorType = "timeout";
  } else if (noACHError) {
    errorType = "noAch";
  }

  // For a given table and error, general the correct content.
  const {
    header,
    subHeader,
    subHeaderBottom,
    ActionComponent,
    src,
    style
  } = errorContentConfig(customerServicePhoneNumber)[tableType][errorType];

  return (
    <TablePlaceholder
      SourceImageComponent={() => (
        <Player autoplay loop src={src} style={style} />
      )}
      header={header}
      subHeader={subHeader}
      subHeaderBottom={subHeaderBottom}
      ActionComponent={ActionComponent}
    />
  );
};

export default ErrorController;
