import { useContext } from "react";
import { SubmissionError } from "redux-form";
import PropTypes from "prop-types";

import { UserTypeEnum } from "Enums/StateEnums";
import { AuthContext } from "Login/Auth";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import {
  emailRegex,
  passwordCapitalRegex,
  passwordNumberRegex,
  passwordSpecialCharRegex
} from "Utils/Regex";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

export const SignUpFormController = ({ children, userType }) => {
  const { signupAndLogin } = useContext(AuthContext);

  const trackSignUp = () => {
    analytics.track(
      AnalyticsEvents.label.auth.signUpSubmitted.legacyEventName,
      {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.formSubmitted,
        label: AnalyticsEvents.label.auth.signUpSubmitted.label,
        property: JSON.stringify({
          flow: AnalyticsEvents.flow.registration,
          provider: "email"
        }),
        value: "",
        context: ""
      }
    );
  };

  const onSubmit = async values => {
    try {
      trackSignUp();
      await signupAndLogin({
        ...values,
        phone: values.phone.replace(/[^0-9]/g, ""),
        userType: userType ? userType : UserTypeEnum.owner
      });

      if (window.sendData) {
        window.sendData([values.email], () => {});
      }
    } catch (e) {
      throw new SubmissionError({
        _error:
          e && e.message
            ? e.message.replace("GraphQL error: ", "")
            : "Sorry, there was an error during the sign up process. Please try again."
      });
    }
  };

  const validate = values => {
    const errors = {};

    let passwordErrors = [];

    if (!values.email) {
      errors.email = "Enter a valid email";
    } else {
      if (!emailRegex.test(values.email)) {
        errors.email = "Enter a valid email";
      }
    }

    if (!values.phone) {
      errors.phone = "Please enter a valid phone number";
    } else {
      if (values.phone.replace(/[^0-9]/g, "").length !== 10) {
        errors.phone = "Please enter a valid phone number";
      }
    }

    if (!values.firstName) {
      errors.firstName = "Required";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    }

    if (!values.zip) {
      errors.zip = "Please enter a valid zip";
    } else {
      if (values.zip.replace(/\s/g, "").length !== 5) {
        errors.zip = "Please enter a valid zip";
      }
    }

    if (!values.password) values.password = "";

    if (!passwordCapitalRegex.test(values.password))
      passwordErrors.push("Capital");

    if (!passwordNumberRegex.test(values.password))
      passwordErrors.push("Number");

    if (!passwordSpecialCharRegex.test(values.password))
      passwordErrors.push("Special Character");

    if (passwordErrors.length !== 0) {
      let passwordErrorString = "Your password must contain a";

      if (passwordErrors.length === 1) {
        passwordErrorString = passwordErrorString.concat(
          ` ${passwordErrors[0]}.`
        );
      } else {
        for (let i = 0; i < passwordErrors.length; i++) {
          if (i === passwordErrors.length - 1) {
            passwordErrorString = passwordErrorString.concat(
              ` and ${passwordErrors[i]}.`
            );
          } else {
            passwordErrorString = passwordErrorString.concat(
              ` ${passwordErrors[i]},`
            );
          }
        }
      }

      errors.password = passwordErrorString;
    }

    if (values.password.length < 8) {
      if (errors.password !== undefined) {
        errors.password = errors.password.concat(
          " Your password must be at least 8 characters in length."
        );
      } else {
        errors.password =
          "Your password must be at least 8 characters in length.";
      }
    }

    return errors;
  };

  return children({
    onSubmit,
    validate,
    onSubmitFail: errors => scrollErrorIntoView(errors)
  });
};

SignUpFormController.propTypes = {
  userType: PropTypes.oneOf([UserTypeEnum.owner, UserTypeEnum.driver])
};
