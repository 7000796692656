import { gql } from "@apollo/client";

export const TRANSACTIONS_QUERY = gql`
  query viewTransactions($startDate: Int, $endDate: Int, $payoutId: String) {
    viewTransactions(
      input: { startDate: $startDate, endDate: $endDate, payoutId: $payoutId }
    ) {
      id
      transferDate
      paymentDate
      description
      rentalPeriodType
      netEarnings
      listedPrice
      protectionPlanFee
      protectionPlan
      paymentId
      rentalRevenue
      amountRefunded
      rentalPaymentId
      transactionType
      driverName
      car
      vin
      days
      rentalPeriodStart
      rentalPeriodEnd
    }
  }
`;

export const EARNINGS_QUERY = gql`
  query getDashboardEarnings {
    getDashboardEarnings {
      dashboardEarnings {
        pastThreeDays
        pastSevenDays
        pastFourteenDays
        pastThirtyDays
      }
    }
  }
`;

export const EXPORT_TRANSACTIONS = gql`
  query exportTransactions(
    $endDate: String
    $startDate: String
    $payoutIds: [String!]
    $emails: [String!]!
  ) {
    exportTransactions(
      input: {
        endDate: $endDate
        startDate: $startDate
        emails: $emails
        payoutIds: $payoutIds
      }
    ) {
      code
      success
      message
    }
  }
`;
