import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import get from "lodash/get";

import { RentalStatusEnum } from "Enums/StateEnums";
import { APPROVE_BOOKING } from "Mutations/Rental/RentalMutations";
import { RENTAL_AGGREGATE_QUERY } from "Queries/Rentals/RentalQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

export const ApproveBookingFormController = ({
  children,
  driverId,
  rentalId,
  ownerId,
  handleTosError
}) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [approveBooking] = useMutation(APPROVE_BOOKING, {
    client: currentRooftopClient
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      const resp = await approveBooking({
        variables: {
          rentalId
        },
        refetchQueries: [
          "RentalsQuery",
          {
            query: RENTAL_AGGREGATE_QUERY,
            variables: {
              filter: {
                status: RentalStatusEnum.applied
              }
            }
          }
        ]
      });
      analytics.track(AnalyticsEvents.label.owner.bookingApproved, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.owner.bookingApproved,
        property: JSON.stringify({ driverId, ownerId, rentalId }),
        value: "",
        context: ""
      });

      if (get(resp, "data.approveBooking.code") !== "200") {
        throw new Error(
          get(
            resp,
            "data.approveBooking.message",
            "Sorry, something went wrong. Please try again."
          )
        );
      }
      analytics.track(AnalyticsEvents.label.owner.bookingSuccess, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.webConversion,
        label: AnalyticsEvents.label.owner.bookingSuccess,
        property: JSON.stringify({ driverId, ownerId, rentalId }),
        value: "",
        context: ""
      });

      enqueueSnackbar("Approved Booking.", {
        variant: "success"
      });
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(
        e.message
          ? e.message
          : "Sorry, something went wrong. Please try again.",
        {
          variant: "error",
          autoHideDuration: 10000
        }
      );

      if (e.message && e.message.includes("payment")) {
        analytics.track(AnalyticsEvents.label.owner.driverPaymentError, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.errorShown,
          label: AnalyticsEvents.label.owner.driverPaymentError,
          property: JSON.stringify({ driverId, ownerId, rentalId }),
          value: "",
          context: ""
        });
      }
      if (e.message && e.message.includes("payout")) {
        analytics.track(AnalyticsEvents.label.owner.ownerPayoutError, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.errorShown,
          label: AnalyticsEvents.label.owner.ownerPayoutError,
          property: JSON.stringify({ driverId, ownerId, rentalId }),
          value: "",
          context: ""
        });
      }
      if (e.message && e.message.includes("TOS")) {
        handleTosError && handleTosError();
      }
      analytics.track(AnalyticsEvents.label.owner.bookingFailed, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.webConversion,
        label: AnalyticsEvents.label.owner.bookingFailed,
        property: JSON.stringify({ driverId, ownerId, rentalId }),
        value: "",
        context: ""
      });
    }
  };

  const validate = values => {
    const errors = {};

    if (!values.rentalAgreement)
      errors.rentalAgreement = "You must agree to the rental agreement";

    return errors;
  };

  const initialValues = {
    rentalAgreement: false
  };

  return children({
    onSubmit,
    validate,
    initialValues
  });
};

ApproveBookingFormController.propTypes = {
  children: PropTypes.func.isRequired
};
