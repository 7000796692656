import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

export const AsyncDropzoneLoadingContext = React.createContext({});

const AsyncDropzoneLoadingProvider = ({ children }) => {
  const [documentsLoading, setDocumentsLoading] = useState({});

  const handleDocumentLoading = useCallback((documentType, loading) => {
    setDocumentsLoading(prevState => ({
      ...prevState,
      [documentType]: loading
    }));
  }, []);

  const documentsLoadingMemo = useMemo(
    () => Object.values(documentsLoading).some(loading => loading),
    [documentsLoading]
  );

  const store = {
    documentsLoading: documentsLoadingMemo,
    setLoading: handleDocumentLoading
  };

  return (
    <AsyncDropzoneLoadingContext.Provider value={store}>
      {children}
    </AsyncDropzoneLoadingContext.Provider>
  );
};

AsyncDropzoneLoadingProvider.propTypes = {
  children: PropTypes.node
};

export default AsyncDropzoneLoadingProvider;
