import React, { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import { PayoutConnectContext } from "Components/Utils/PayoutConnectProvider";
import { GET_ACCOUNT_LINK } from "Queries/User/UserQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: props => (props.matches ? theme.spacing(1.5) : 0)
  },
  container: {
    backgroundColor: "#FCE7E7",
    border: "1px solid #C62828",
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(1.25)
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: props => (props.matches ? 0 : theme.spacing(2.5))
  },
  button: {
    backgroundColor: "#E53935",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#EF8885"
    }
  },
  content: {
    marginBottom: props => (props.matches ? theme.spacing(2.5) : 0),
    paddingRight: props => (props.matches ? 0 : theme.spacing(2.5))
  }
}));

export const PayoutSetupRequirements = () => {
  const [accountLinkLoading, setAccountLinkLoading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ matches });
  const { requiresPayoutUpdate } = useContext(PayoutConnectContext);
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const handleClick = async () => {
    setAccountLinkLoading(true);
    const accountLink = await currentRooftopClient.query({
      query: GET_ACCOUNT_LINK,
      fetchPolicy: "network-only",
      context: {
        apiv2: true
      }
    });
    if (accountLink?.data?.getAccountLink?.url) {
      window.open(accountLink?.data?.getAccountLink?.url);
    }
    setAccountLinkLoading(false);
  };

  return requiresPayoutUpdate ? (
    <Paper className={classes.paper}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={10} className={classes.content}>
          <div>
            Additional information is needed to verify your account with our
            payout provider. To prevent delays in any future payouts, securely
            upload your verification documents through Stripe’s portal.
          </div>
        </Grid>
        <Grid item xs={12} sm={2} className={classes.buttonGrid}>
          <Button
            data-testid="verify-ach-identity"
            className={classes.button}
            disabled={accountLinkLoading}
            onClick={handleClick}
          >
            Verify Now
          </Button>
        </Grid>
      </Grid>
    </Paper>
  ) : null;
};
