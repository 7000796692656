import { gql } from "@apollo/client";

export const FETCH_ARGYLE_TOKEN = gql`
  query fetchArgyleToken {
    fetchArgyleToken {
      userId
      token
    }
  }
`;
