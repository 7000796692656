import {
  CarStatusEnum,
  CarVerificationEnum,
  CombinedCarStatusEnum,
  RentalStatusEnum
} from "Enums/StateEnums";

import { capitalize } from "lodash";
import { getRentalDaysLeftOrDaysLateText } from "Utils/Calculations";
import { termsStateEnums } from "Enums/TermsStateEnums";
import { getProtectionPercent } from "Utils/Calculations";
import { alphanumeric, alphaWithDash } from "./Regex";

export const truncateData = (data, length) => {
  if (!data) return;
  if (data.length > length) {
    return `${data.slice(0, length)}...`;
  } else {
    return data;
  }
};

export const lastSixOfVin = vin => {
  return vin ? vin.substring(vin.length - 6, vin.length) : "";
};

export const createShortenedString = (str, characterCount) => {
  if (str) {
    return str.length <= characterCount
      ? str
      : `${str.slice(0, characterCount)}...`;
  } else {
    return str;
  }
};

export const capitalizeAllWords = text => {
  return text.replace(/\b\w/g, l => l.toUpperCase());
};

export const snakeToProperCase = text =>
  text
    .split("_")
    .map(word => capitalize(word))
    .join(" ");

export const removeUnderScoresAndDashes = value => {
  if (value) {
    const regex = /-|_/gi;
    return value.replace(regex, " ");
  }
  return value;
};

export const getCarStatus = (status, verification) => {
  switch (status) {
    case CarStatusEnum.removed: {
      if (verification === CarVerificationEnum.pendingVerification) {
        return CombinedCarStatusEnum.pendingVerification;
      } else if (verification === CarVerificationEnum.failedVerification) {
        return CombinedCarStatusEnum.failedVerification;
      } else if (verification === CarVerificationEnum.verified) {
        return CombinedCarStatusEnum.snoozed;
      } else if (verification === CarVerificationEnum.incomplete) {
        return CombinedCarStatusEnum.onLot;
      }
      break;
    }
    case CarStatusEnum.rented: {
      if (verification === CarVerificationEnum.verified) {
        return CombinedCarStatusEnum.liveRented;
      } else if (verification === CarVerificationEnum.failedVerification) {
        return CombinedCarStatusEnum.blocked;
      }
      break;
    }
    case CarStatusEnum.available: {
      if (verification === CarVerificationEnum.verified) {
        return CombinedCarStatusEnum.live;
      } else if (verification === CarVerificationEnum.failedVerification) {
        return CombinedCarStatusEnum.blocked;
      }
      break;
    }
    default:
      return null;
  }
};

export const getRentalStatusColor = (status, endDate) => {
  let rentalStatusColor = "status-color-gray";
  let statusStr = getRentalDaysLeftOrDaysLateText(endDate);
  switch (status) {
    case RentalStatusEnum.applied:
      return (rentalStatusColor = "status-color-blue");
    case RentalStatusEnum.appliedNotVerified:
      return (rentalStatusColor = "status-color-orange");
    case RentalStatusEnum.applicationRejected:
      return (rentalStatusColor = "status-color-red");
    case RentalStatusEnum.applicationExpired:
      return (rentalStatusColor = "status-color-gray");
    case RentalStatusEnum.applicationAutoRejected:
      return (rentalStatusColor = "status-color-gray");
    case RentalStatusEnum.pendingInsurance:
      return (rentalStatusColor = "status-color-orange");
    case RentalStatusEnum.cancelled:
      return (rentalStatusColor = "status-color-red");
    case RentalStatusEnum.pendingPickup:
      return (rentalStatusColor = "status-color-green");
    case RentalStatusEnum.active:
      if (statusStr.toLowerCase().includes("late")) {
        return (rentalStatusColor = "status-color-red");
      } else {
        return (rentalStatusColor = "status-color-blue");
      }
    case RentalStatusEnum.late:
      return (rentalStatusColor = "status-color-red");
    case RentalStatusEnum.completed:
      return (rentalStatusColor = "status-color-green");
    case RentalStatusEnum.archived:
      return (rentalStatusColor = "status-color-gray");
    default:
      return rentalStatusColor;
  }
};

export const arrayPermutator = inputArr => {
  let result = [];
  const permute = (arr, m = []) => {
    if (arr.length === 0) {
      result.push(m);
    } else {
      for (let i = 0; i < arr.length; i++) {
        let curr = arr.slice();
        let next = curr.splice(i, 1);
        permute(curr.slice(), m.concat(next));
      }
    }
  };
  permute(inputArr);
  return result;
};

export const getEarnings = ({
  daily_rate,
  weekly_discount,
  monthly_discount,
  protection_plan
}) => {
  const protectionPercent = getProtectionPercent(protection_plan);

  const dailyRate = daily_rate ? parseInt(daily_rate, 10) : 0;
  const weeklyEarnings = weekly_discount
    ? dailyRate *
      protectionPercent *
      termsStateEnums.DAYS_IN_WEEK *
      (1 - parseInt(weekly_discount) / 100)
    : dailyRate * protectionPercent * termsStateEnums.DAYS_IN_WEEK;
  const monthlyEarnings = monthly_discount
    ? dailyRate *
      protectionPercent *
      termsStateEnums.DAYS_IN_MONTH *
      (1 - parseInt(monthly_discount) / 100)
    : dailyRate * protectionPercent * termsStateEnums.DAYS_IN_MONTH;
  const netDailyPrice = dailyRate * protectionPercent;

  return {
    weeklyEarnings: Number.parseFloat(weeklyEarnings).toFixed(2),
    monthlyEarnings: Number.parseFloat(monthlyEarnings).toFixed(2),
    netDailyPrice: Number.parseFloat(netDailyPrice).toFixed(2)
  };
};

export const formatPhone = phone => {
  if (!phone) return null;
  const strip = /[(|)|-]/g;
  const phoneVal = phone.replace(strip, "").trim();
  let formattedPhoneVal = phoneVal;

  if (phoneVal.length >= 3) {
    formattedPhoneVal = "(" + phoneVal;

    formattedPhoneVal = [
      formattedPhoneVal.slice(0, 4),
      ")",
      formattedPhoneVal.slice(4)
    ].join("");
    formattedPhoneVal = [
      formattedPhoneVal.slice(0, 5),
      "-",
      formattedPhoneVal.slice(5)
    ].join("");

    if (phoneVal.length >= 6) {
      formattedPhoneVal = [
        formattedPhoneVal.slice(0, 9),
        "-",
        formattedPhoneVal.slice(9)
      ].join("");
    }
  }

  return formattedPhoneVal;
};

export const parseNumberFromCurrencyString = string => {
  if (!string) return 0;
  return Number(string.replace(/[^0-9.-]+/g, ""));
};

export const isAlphanumeric = inputText =>
  inputText === "" || alphanumeric.test(inputText);

export const isAlphaWithDash = inputText =>
  inputText === "" || alphaWithDash.test(inputText);

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const parseRentalStartAndEndDate = rental => {
  const endDate = rental?.currentRentalPeriod?.endDate;
  const startDate = rental?.requestedPickupAt;
  return {
    startDate,
    endDate
  };
};
