import React from "react";
import {
  Link as RRLink,
  Route as RRoute,
  Redirect,
  withRouter
} from "react-router-dom";

import { default as RPrivateRoute } from "./PrivateRoute";
import { pure } from "recompose";

export const RouteNotFound = () => (
  <Redirect to={{ state: { notFoundError: true } }} />
);

export const CaptureRouteNotFound = withRouter(({ children, location }) => {
  return location && location.state && location.state.notFoundError ? (
    <Redirect to="/" />
  ) : (
    children
  );
});

export {
  Redirect,
  withRouter,
  Switch,
  BrowserRouter as Router
} from "react-router-dom";
const Link = pure(RRLink);
const Route = pure(RRoute);
const PrivateRoute = pure(RPrivateRoute);

export { Link, Route, PrivateRoute };
