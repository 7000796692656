import React, { useRef } from "react";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useScrollbar from "Components/hooks/useScrollbar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
  fileContainer: {
    display: "flex",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    height: "auto",
    [theme.breakpoints.up("md")]: {
      overflowX: "hidden"
    },
    [theme.breakpoints.down("md")]: {
      overflowX: "scroll"
    },
    margin: "auto",
    width: "100%",
    msOverflowStyle: "none",
    "-ms-overflow-style": "scroll", // IE 10+
    "scrollbar-width": "none" // Firefox
  },
  arrowButton: {
    borderRadius: 0
  }
}));

const ScrollContainer = ({ children }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const { scroll, overflowActive } = useScrollbar({
    elementRef: ref
  });

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", position: "relative" }}
    >
      <IconButton
        disabled={!overflowActive}
        className={classes.arrowButton}
        onClick={() => scroll("left", 120)}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Box ref={ref} className={classes.fileContainer}>
        {children}
      </Box>
      <IconButton
        disabled={!overflowActive}
        className={classes.arrowButton}
        onClick={() => scroll("right", 120)}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default ScrollContainer;
