import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white
  },
  inner: {
    padding: theme.spacing(2)
  },
  childrenContainer: {
    marginTop: theme.spacing(2)
  },
  noWrap: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  divider: {
    paddingTop: theme.spacing(2)
  }
}));

export const MobileTableRow = ({
  title,
  subtitle,
  children,
  bottomDivider = true,
  onClick
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.inner}>
        <Typography variant="subtitle1" className={classes.noWrap}>
          {title}
        </Typography>
        {subtitle && <Typography variant="body2">{subtitle}</Typography>}

        {children && (
          <div className={classes.childrenContainer}>{children}</div>
        )}
      </div>
      {bottomDivider && <Divider orientation="horizontal" />}
    </div>
  );
};

MobileTableRow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  bottomDivider: PropTypes.bool,
  onClick: PropTypes.func
};
