import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { analytics } from "Analytics/index";
import useOverpricedCarAlert from "Components/hooks/useOverpricedCarAlert";

const useStyles = makeStyles(theme => ({
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    boxShadow: 24,
    outline: "none",
    borderRadius: "8px"
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4)
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1)
  },
  button: {
    textTransform: "none"
  }
}));

const options = [
  { value: "1 week", label: "1 week" },
  { value: "2 weeks", label: "2 weeks" },
  { value: "30 days", label: "In 30 days" },
  { value: "Never", label: "Never" }
];

const PostponeAlertModal = ({ open, handleClose, handleSave }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const { marketingOrigin } = useOverpricedCarAlert();

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = () => {
    analytics.track(AnalyticsEvents.label.owner.ownerPostponedOverpricedAlert, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.click,
      label: AnalyticsEvents.label.owner.ownerPostponedOverpricedAlert,
      property: JSON.stringify({
        dismissalPeriod: selectedValue,
        marketingOrigin: marketingOrigin
      }),
      value: "",
      context: "OverpricedCarAlert"
    });

    handleSave(selectedValue);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalBox}>
        <Typography variant="h6" gutterBottom>
          Remind me again in:
        </Typography>
        <RadioGroup
          value={selectedValue}
          onChange={handleChange}
          className={classes.radioGroup}
        >
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        <Box className={classes.buttonBox}>
          <Button
            variant="outlined"
            onClick={handleClose}
            className={classes.button}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PostponeAlertModal;
