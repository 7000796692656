import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import classNames from "classnames";
import get from "lodash/get";

import { InboxChannels } from "Components/Inbox/InboxChannels";
import { InboxChat } from "Components/Inbox/InboxChat";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { selectChannel, deselectChannel } from "Redux/SendBird/ActionCreators";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "650px",
    maxHeight: "650px",
    [theme.breakpoints.up("xl")]: {
      minHeight: "800px",
      maxHeight: "800px"
    }
  },
  overflow: {
    overflowY: "scroll"
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

export const DesktopInbox = connect(
  state => {
    const selectedChannel = get(state, "fleet.sendbird.selectedChannel", null);
    return {
      messages: selectedChannel
        ? get(state, `fleet.sendbird.messages[${selectedChannel.url}]`)
        : null
    };
  },
  { selectChannel, deselectChannel }
)(({ selectChannel, messages, filterByAssignedTo }) => {
  const classes = useStyles();

  const handleSelectChannel = channel => {
    selectChannel(channel);
  };

  return (
    <Paper className={classes.root}>
      {messages && messages.loading && <LinearProgress />}
      <Grid container className={classes.root}>
        <Grid
          item
          md={4}
          xs={12}
          className={classNames(
            classes.root,
            classes.overflow,
            classes.borderRight
          )}
          id={joyrideTutorialIds.inboxChannelsWrapper}
        >
          <InboxChannels
            onClick={handleSelectChannel}
            filterByAssignedTo={filterByAssignedTo}
          />
        </Grid>
        <Grid
          item
          md={8}
          className={classes.root}
          id={joyrideTutorialIds.inboxChat}
        >
          <InboxChat />
        </Grid>
      </Grid>
    </Paper>
  );
});
