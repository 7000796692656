import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  onSearchChange,
  selectFilter,
  resetSearch,
  resetFilters
} from "Redux/Header/ActionCreators";
import makeStyles from "@mui/styles/makeStyles";

import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2)
  },
  formControl: {
    width: "50em",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 2
  }
}));

export const MobileSearchFilter = connect(
  state => ({
    search: state.fleet.header.search,
    filters: state.fleet.header.filters
  }),
  { onSearchChange, selectFilter, resetSearch, resetFilters }
)(
  ({
    altPlaceholder,
    divider = true,
    search,
    filters,
    selectFilter,
    onSearchChange,
    resetSearch,
    resetFilters,
    stopFilterAndSearchReset
  }) => {
    const classes = useStyles();

    const filtersCheck =
      filters && Array.isArray(filters) && filters.length > 0;

    const handleOnSearchChange = e => onSearchChange(e.target.value);

    const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);

    const handleFiltersClick = event => {
      setFiltersAnchorEl(event.currentTarget);
    };

    const handleFiltersClose = () => {
      setFiltersAnchorEl(null);
    };

    const handleFilterOnClick = filter => {
      selectFilter(filter);
      handleFiltersClose();
    };

    useEffect(() => {
      return () => {
        if (stopFilterAndSearchReset) return;
        resetSearch();
        resetFilters();
      };
    }, [resetSearch, resetFilters, stopFilterAndSearchReset]);

    return (
      <Grid className={classes.container}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="search-input">
            {altPlaceholder ? altPlaceholder : `Search`}
          </InputLabel>
          <Input
            type="text"
            value={search}
            onChange={handleOnSearchChange}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        {filtersCheck && (
          <>
            {divider && (
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            )}
            <IconButton
              onClick={handleFiltersClick}
              className={classes.icon}
              size="large"
            >
              <FilterListIcon />
            </IconButton>
            <Menu
              anchorEl={filtersAnchorEl}
              open={Boolean(filtersAnchorEl)}
              onClose={handleFiltersClose}
            >
              {filters.map((filter, index) => {
                return (
                  <MenuItem
                    onClick={() => {
                      handleFilterOnClick(filter);
                    }}
                    key={index}
                  >
                    {filter.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </Grid>
    );
  }
);
