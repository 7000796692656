import { useTheme } from "@mui/material/styles";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WarningIcon from "@mui/icons-material/Warning";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

import { RentalStatusEnum, RentalStatusCopyEnum } from "Enums/StateEnums";

export const getRentalStatusOptions = (status, theme) => {
  switch (status) {
    case RentalStatusEnum.applied:
    case RentalStatusEnum.appliedNotVerified:
      return {
        copy: RentalStatusCopyEnum.requested,
        color: theme.palette.success.dark,
        Icon: FiberNewIcon
      };
    case RentalStatusEnum.pendingInsurance:
      return {
        copy: RentalStatusCopyEnum.accepted,
        color: theme.palette.warning.dark,
        Icon: AccessTimeIcon
      };
    case RentalStatusEnum.pendingPickup:
      return {
        copy: RentalStatusCopyEnum.checkIn,
        color: theme.palette.success.dark,
        Icon: CheckCircleIcon
      };
    case RentalStatusEnum.active:
      return {
        copy: RentalStatusCopyEnum.active,
        color: theme.palette.primary[900],
        Icon: DirectionsCarIcon
      };
    case RentalStatusEnum.late:
      return {
        copy: RentalStatusCopyEnum.late,
        color: theme.palette.error.dark,
        Icon: WarningIcon
      };
    case RentalStatusEnum.completed:
      return {
        copy: RentalStatusCopyEnum.completed,
        color: theme.palette.grey[500],
        Icon: DoneAllIcon
      };
    case RentalStatusEnum.cancelled:
      return {
        copy: RentalStatusCopyEnum.cancelled,
        color: theme.palette.grey[500],
        Icon: CancelPresentationIcon
      };
    case RentalStatusEnum.applicationAutoRejected:
    case RentalStatusEnum.applicationExpired:
    case RentalStatusEnum.applicationRejected:
      return {};
    default:
      return {};
  }
};

export const useRentalStatusOptions = status => {
  const theme = useTheme();

  return getRentalStatusOptions(status, theme);
};
