import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { DialogPaddingContainer } from "../DialogPaddingContainer";
import { protectionPlans } from "./protectionPlanCopies";
import ProtectionPlanCard from "./ProtectionPlanCard";

const SelectProtectionPlanStep = ({
  selectedProtectionPlan,
  setSelectedProtectionPlan
}) => {
  return (
    <DialogPaddingContainer extraRightPadding={1} extraLeftPadding={1}>
      <Typography paragraph gutterBottom>
        Active rentals will remain on Protection Plan terms selected prior to
        September 30, 2021. The revised plans described below are available only
        on newly accepted rentals effective on, or after, September 30, 2021.
        You have the option of changing the plans for your vehicles later on.
      </Typography>
      <Grid container spacing={2}>
        {protectionPlans.map(plan => (
          <Grid item xs={12} lg={6} key={plan.value}>
            <ProtectionPlanCard
              plan={plan}
              selected={selectedProtectionPlan === plan.value}
              onClick={() => setSelectedProtectionPlan(plan.value)}
            />
          </Grid>
        ))}
      </Grid>
    </DialogPaddingContainer>
  );
};

export default SelectProtectionPlanStep;
