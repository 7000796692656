import { useContext } from "react";
import { useQuery } from "@apollo/client";
import get from "lodash/get";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import startCase from "lodash/startCase";

import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { RENTAL_PREPHOTOS } from "Queries/Rentals/RentalQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const useRentalImages = id => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data: rentalPicturesData, loading } = useQuery(RENTAL_PREPHOTOS, {
    variables: {
      id: id
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const {
    data: {
      viewer: { me }
    }
  } = useQuery(CURRENT_USER_QUERY, { client: currentRooftopClient });

  const publisherName = startCase(`${me.firstName} ${me.lastName.charAt(0)}.`);

  const rentalLookupPrePhotos = get(
    rentalPicturesData,
    "rental.rentalLookup.preRentalByRentalLookupId.preRentalPhotosByPreRentalId.nodes"
  );

  // Gets the array of insurances
  const rentalInsurancePrePhotosArray = get(
    rentalPicturesData,
    "rental.insurances"
  );

  // First orders the arrays descending by createdAt
  // Then in finds the position in the array that contains a valid path to pre.photos
  // Finally it gets the value in pre.photos if it exists
  const rentalInsurancePrePhotos = get(
    find(
      orderBy(rentalInsurancePrePhotosArray, array => array.createdAt, "desc"),
      insurance => get(insurance, "pre.photos")
    ),
    "pre.photos"
  );

  const prePhotos = map(
    rentalLookupPrePhotos ? rentalLookupPrePhotos : rentalInsurancePrePhotos,
    rentalFile => {
      const { url, createdAt } = rentalFile.file;
      return { url, createdAt, by: publisherName };
    }
  );

  return { prePhotos, loading };
};
