import React, { useMemo, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

import { DocumentDropzone } from "Components/Forms/Dropzone/DocumentDropzone";
import { DraggableContainer, Draggable } from "Components/Utils/Draggable";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  item: {
    width: "140px",
    height: "140px",
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(2)
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
    border: `1px solid ${theme.palette.grey[400]}`
  },
  selected: {
    border: `2px solid ${theme.palette.primary[500]}`
  },
  placeholder: {
    width: "100%",
    height: "100%",
    background: theme.palette.grey[100]
  },
  overlay: {
    position: "relative",
    top: "-27px",
    background: "rgba(244,247,252,.8)",
    textAlign: "center",
    width: "100%",
    display: "table"
  }
}));

export const ImagesOrderer = ({
  input: { value, onChange },
  onSelect,
  selected,
  dropzoneWhenEmpty,
  numberOfImages
}) => {
  const images = value ? value : [];
  const classes = useStyles();

  const onDropzoneDrop = useCallback(
    file => onChange(value ? [...value, file] : [file]),
    [onChange, value]
  );

  const onDrop = useCallback(
    ({ destination, dragged }) => {
      const dragIndex = get(dragged, "index", null);
      const destinationIndex = get(destination, "index", null);

      if (
        typeof dragIndex === "number" &&
        typeof destinationIndex === "number" &&
        dragIndex !== destinationIndex
      ) {
        const imagesCopy = images.slice(0, images.length);
        const dragImage = imagesCopy[dragIndex];
        imagesCopy[dragIndex] = null;

        const leftSliceOfImages = imagesCopy.slice(
          0,
          destinationIndex > dragIndex ? destinationIndex + 1 : destinationIndex
        );
        const rightSliceOfImages = imagesCopy.slice(
          destinationIndex > dragIndex
            ? destinationIndex + 1
            : destinationIndex,
          imagesCopy.length
        );

        const mergedImages = [
          ...leftSliceOfImages,
          dragImage,
          ...rightSliceOfImages
        ].filter(image => image !== null);

        if (dragIndex === selected) {
          onSelect(destinationIndex);
        } else {
          if (
            dragIndex > destinationIndex &&
            selected >= destinationIndex &&
            selected <= dragIndex
          ) {
            onSelect(selected + 1);
          } else if (
            dragIndex < destinationIndex &&
            selected <= destinationIndex &&
            selected >= dragIndex
          ) {
            onSelect(selected - 1);
          }
        }

        onChange(mergedImages);
      }
    },
    [images, onChange, onSelect, selected]
  );

  const grid = useMemo(() => {
    const grid = [];

    for (let i = 0; i < numberOfImages; i++) {
      if (images[i]) {
        grid.push(
          <DraggableContainer onDrop={onDrop}>
            <Draggable id={uuidv4()} index={i}>
              {draggable => {
                return (
                  <>
                    <img
                      className={classNames(classes.image, {
                        [classes.selected]: i === selected
                      })}
                      src={URL.createObjectURL(images[i])}
                      onClick={() => onSelect(i)}
                      {...draggable}
                      alt="Car"
                    />
                    {i === 0 ? (
                      <div className={classes.overlay}>{"Cover image"}</div>
                    ) : null}
                  </>
                );
              }}
            </Draggable>
          </DraggableContainer>
        );
      } else if (i === images.length) {
        if (i === 0 && !dropzoneWhenEmpty) {
          grid.push(<div className={classes.placeholder} />);
        } else {
          grid.push(
            <DocumentDropzone
              accept="image/jpeg, image/png"
              input={{
                onChange: onDropzoneDrop
              }}
              meta={{}}
            />
          );
        }
      } else {
        grid.push(<div className={classes.placeholder} />);
      }
    }

    return grid;
  }, [
    selected,
    classes.image,
    classes.placeholder,
    classes.selected,
    classes.overlay,
    dropzoneWhenEmpty,
    images,
    numberOfImages,
    onDrop,
    onDropzoneDrop,
    onSelect
  ]);

  return (
    <div className={classes.root}>
      {grid.map((gridItem, index) => (
        <div className={classes.item} key={index}>
          {gridItem}
        </div>
      ))}
    </div>
  );
};

ImagesOrderer.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
  }),
  onSelect: PropTypes.func,
  selected: PropTypes.number,
  dropzoneWhenEmpty: PropTypes.bool,
  numberOfImages: PropTypes.number.isRequired
};
