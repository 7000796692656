import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";

import { encodeCsv } from "Utils/CsvUtils";

const CsvDownload = ({ headers, data, fileName }) => (
  <a target="_self" href={encodeCsv(headers, data)} download={fileName}>
    <IconButton color="primary" size="small">
      <GetAppIcon />
    </IconButton>
  </a>
);

CsvDownload.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  fileName: PropTypes.string
};

export default CsvDownload;
