import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import moment from "moment";
import PropTypes from "prop-types";
import get from "lodash/get";

import { useTableState } from "Components/Tables/hooks/useTableState";
import { useRentalsTable } from "Queries/Rentals/hooks/useRentalsTable";
import { CarInfoHeader } from "Components/Car/CarInfoHeader";
import { CarInfoFooter } from "Components/Car/CarInfoFooter";
import { CarInfoFilter } from "Components/Car/CarInfoFilter";
import { setFilters } from "Redux/Header/ActionCreators";
import { CircularProgress } from "Components/Loading/CircularProgress";
import { RentalStatusIndicator } from "Components/Rentals/RentalStatusIndicator";
import { asyncStartChannel } from "Redux/SendBird/ActionCreators";
import { RouteEnum } from "Enums/RouteEnum";
import { RentalStatusEnum } from "Enums/StateEnums";
import {
  getRentalRequestExpiration,
  getRentalDaysLeftOrDaysLateTextShorthand
} from "Utils/Calculations";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { checkPermission } from "Utils/RooftopUtils";
import { InboxDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { MobileSearchFilter } from "Components/Header/MobileSearchFilter";

const getRentalStatusCopy = rental => {
  switch (rental.status) {
    case RentalStatusEnum.applied:
      return `Expires ${getRentalRequestExpiration(rental.createdAt, true)}`;
    case RentalStatusEnum.pendingInsurance:
      return "Accepted";
    case RentalStatusEnum.cancelled:
      return "Cancelled";
    case RentalStatusEnum.pendingPickup:
      return "Check-in";
    case RentalStatusEnum.active:
      return `${getRentalDaysLeftOrDaysLateTextShorthand(
        get(rental, "currentRentalPeriod.endDate")
      )}`;
    case RentalStatusEnum.late:
      return `${getRentalDaysLeftOrDaysLateTextShorthand(
        get(rental, "currentRentalPeriod.endDate")
      )}`;
    case RentalStatusEnum.completed:
      return "Completed";
    default:
      return `Expires ${getRentalRequestExpiration(rental.createdAt)}`;
  }
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2, 2, 2),
    marginBottom: theme.spacing(2),
    zIndex: 1
  }
}));

const RentalsCard = connect(null, {
  asyncStartChannel
})(
  ({
    data,
    loading,
    asyncStartChannel,
    hasNextPage,
    onLoadMoreClick,
    fetchingMore
  }) => {
    const { scopes } = useContext(ClientFactoryContext);
    const classes = useStyles();
    const history = useHistory();

    const handleMessageClick = data => async () => {
      await asyncStartChannel(data.driverId);
      history.push(
        checkPermission(InboxDomainPermissionPaths.viewMultiUserInbox, scopes)
          ? RouteEnum.inboxMine
          : RouteEnum.inbox
      );
    };

    return (
      <>
        {loading ? (
          <CircularProgress />
        ) : (
          <div>
            {data.map(data => {
              return (
                <Paper className={classes.paper} key={data.id}>
                  <CarInfoHeader
                    title={data["driverFullName"]}
                    carPhoto={data["driverImg"]}
                    status={data["status"]["value"]}
                    details={[
                      `${moment(data["startDate"]).format("MMM D")} - ${moment(
                        data["endDate"]
                      ).format("MMM D, YYYY")}`,
                      data["yearMakeModel"]
                    ]}
                    to={`${RouteEnum.rentalsActive}/${data["id"]}`}
                  />
                  <CarInfoFooter
                    onMessageClick={handleMessageClick(data)}
                    actionButtons={[
                      {
                        label: "view",
                        to: `${RouteEnum.rentalsActive}/${data["id"]}`,
                        hidden: true
                      }
                    ]}
                    statusComponent={
                      <RentalStatusIndicator
                        alternativeCopy={getRentalStatusCopy(data)}
                        status={data.status}
                        to={`${RouteEnum.rentalsActive}/${data["id"]}`}
                      />
                    }
                  />
                </Paper>
              );
            })}
            {hasNextPage && (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={onLoadMoreClick}
              >
                {fetchingMore ? "Loading..." : "Load More"}
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
);

export const RentalsCardView = connect(
  state => ({
    search: state.fleet.header.search,
    selectedFilters: state.fleet.header.selectedFilters
  }),
  { setFilters }
)(
  ({
    search,
    initialColumns,
    initialFilter,
    filters,
    noDataComponent,
    selectedFilters,
    setFilters
  }) => {
    const { first, orderBy } = useTableState({
      initialFirst: 10,
      initialOrderBy: "updatedAt_DESC",
      initialColumns
    });
    const { currentRooftopClient } = useContext(ClientFactoryContext);

    const {
      mappedData,
      pageInfo: { hasNextPage },
      loading,
      fetchMoreData,
      fetchingMore
    } = useRentalsTable({
      variables: {
        searchText: search,
        orderBy,
        first,
        filter: {
          ...initialFilter,
          ...selectedFilters.reduce((acc, val) => {
            if (Object.prototype.hasOwnProperty.call(acc, val.key)) {
              acc[val.key].push(val.value);
            } else {
              acc[val.key] = [val.value];
            }
            return acc;
          }, {})
        }
      },
      client: currentRooftopClient,
      fetchPolicy: "network-only"
    });

    useEffect(() => {
      if (filters) {
        setFilters(filters);
      }
    }, [setFilters, filters]);

    return (
      <>
        <MobileSearchFilter />
        <CarInfoFilter />
        {mappedData.length === 0 && !loading ? (
          <Grid container justifyContent="center" direction="column">
            {noDataComponent}
          </Grid>
        ) : (
          <>
            <RentalsCard
              data={mappedData}
              noDataComponent={noDataComponent}
              loading={loading}
              hasNextPage={hasNextPage}
              onLoadMoreClick={fetchMoreData}
              fetchingMore={fetchingMore}
            />
          </>
        )}
      </>
    );
  }
);

RentalsCardView.propTypes = {
  initialWhere: PropTypes.object,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  noDataLabel: PropTypes.string,
  noDataComponent: PropTypes.node
};
