import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ProtectionPlanEnum } from "Enums/StateEnums";
import { FullScreenDialog } from "../FullScreenDialog";
import SelectProtectionPlanStep from "./SelectProtectionPlanStep";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { OWNER_PROTECTION_MODAL_CARS } from "Queries/Car/Car";
import { UPDATE_CAR_DETAILS } from "Mutations/Car/CarMutations";
import { ACCEPT_PROTECTION_PLAN_CHANGE } from "Mutations/User/UserMutations";
import LoadingStep from "./LoadingStep";
import { promiseAllInBatches } from "Utils/promiseAllInBatches";
import { protectionPlans } from "./protectionPlanCopies";
import ResultsStep from "./ResultsStep";

const STEPS = {
  PLAN_SELECTION: 1,
  LOADING: 2,
  RESULTS: 3
};

const useStyles = makeStyles(theme => ({
  title: {},
  protectionPlanContainer: {
    display: "flex",
    alignItems: "baseline"
  },
  protectionPlanTitle: {
    display: "inline-block",
    paddingRight: 0,
    paddingBottom: 0,
    color: theme.palette.common.black
  },
  protectionPlanSubtitle: {
    display: "inline-block"
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    width: "100%"
  },
  actionButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2)
  }
}));

export const ProtectionPlanDialog = () => {
  const [open, setOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [failedUpdates, setFailedUpdates] = useState([]);
  const [selectedProtectionPlan, setSelectedProtectionPlan] = useState(
    ProtectionPlanEnum.premium_plus
  );
  const onClose = () => setOpen(false);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data: carQueryData } = useQuery(OWNER_PROTECTION_MODAL_CARS, {
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });
  const [updateCarDetails] = useMutation(UPDATE_CAR_DETAILS, {
    client: currentRooftopClient
  });
  const [acceptProtectionPlanChange] = useMutation(
    ACCEPT_PROTECTION_PLAN_CHANGE,
    {
      client: currentRooftopClient
    }
  );

  const classes = useStyles();
  const maxWidth = step === STEPS.PLAN_SELECTION ? "md" : "sm";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const renderSelectProtectionPlanTitle = () => (
    <div>
      <DialogTitle className={classes.protectionPlanTitle}>
        Select your Protection Plan
      </DialogTitle>
      <DialogTitle className={classes.protectionPlanSubtitle}>
        <Typography>(Plans revised September 30, 2021)</Typography>
      </DialogTitle>
    </div>
  );

  const getResultsTitle = () =>
    failedUpdates.length
      ? `We’ve updated your vehicles to the ${protectionPlan.title} Coverage`
      : `Success! All newly accepted rentals will have ${protectionPlan.title} Coverage.`;

  const onSubmit = async () => {
    setStep(STEPS.LOADING);
    await updateListings(carQueryData?.viewer?.me?.owner?.cars?.edges);
    await updateConsent();
    setStep(STEPS.RESULTS);
  };

  const updateListings = async carEdges => {
    const cars = carEdges.map(edge => edge.node);
    const carResults = await promiseAllInBatches(updateListing, cars, 20);
    setFailedUpdates(carResults.filter(result => !result.success));
  };

  const updateListing = async car => {
    try {
      const { data } = await updateCarDetails({
        variables: {
          data: {
            defaultProtectionPlan: (
              selectedProtectionPlan || ProtectionPlanEnum.basic
            ).toUpperCase(),
            carId: car.id
          }
        }
      });
      return { ...car, success: data?.updateCarDetails?.success || false };
    } catch (error) {
      return { ...car, success: false };
    }
  };

  const updateConsent = () =>
    acceptProtectionPlanChange({
      variables: {
        date: new Date().toISOString()
      }
    });
  const protectionPlan = protectionPlans.find(
    plan => plan.value === selectedProtectionPlan
  );

  const renderTitle = step => {
    switch (step) {
      case STEPS.PLAN_SELECTION:
        return renderSelectProtectionPlanTitle();
      case STEPS.RESULTS:
        return getResultsTitle();
      default:
        return null;
    }
  };

  const renderContent = step => {
    switch (step) {
      case STEPS.PLAN_SELECTION:
        return (
          <SelectProtectionPlanStep
            selectedProtectionPlan={selectedProtectionPlan}
            setSelectedProtectionPlan={setSelectedProtectionPlan}
          />
        );
      case STEPS.LOADING:
        return <LoadingStep />;
      case STEPS.RESULTS:
        return (
          <ResultsStep plan={protectionPlan} failedUpdates={failedUpdates} />
        );
      default:
        return null;
    }
  };

  const renderActionButton = (text, onClick, disabled = false) => {
    return (
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={onClick}
          className={classes.actionButton}
          disabled={disabled}
        >
          {text}
        </Button>
      </div>
    );
  };

  const renderAction = step => {
    switch (step) {
      case STEPS.PLAN_SELECTION:
        return renderActionButton("Confirm", onSubmit);
      case STEPS.RESULTS:
        return renderActionButton("Got It", () => onClose());
      default:
        return null;
    }
  };

  return (
    <FullScreenDialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      title={renderTitle(step)}
      content={renderContent(step)}
      actions={renderAction(step)}
    />
  );
};
