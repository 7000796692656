import config from "Config/config";
import axios from "axios";

export const getCarInfoFromVin = async (
  vin,
  vinApiUrl = config.vinValidatorUrl
) => {
  try {
    return await axios.get(
      `${vinApiUrl}/vehicles/DecodeVinValuesExtended/${vin}?format=json`
    );
  } catch (error) {
    console.log(error);
  }
};
