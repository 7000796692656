import React from "react";
import { default as MuiCard } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  card: {
    height: "100%"
  }
}));

export const Card = ({ children }) => {
  const classes = useStyles();
  return (
    <MuiCard className={classes.card} variant="outlined">
      <CardContent>{children}</CardContent>
    </MuiCard>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired
};
