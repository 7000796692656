import { useState, useEffect, useCallback } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone-latest";
import get from "lodash/get";

export const useDropzone = ({
  multiple,
  accept,
  value,
  onChange,
  asyncAction,
  disabled,
  maxSize,
  onRejected
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPdf, setIsPdf] = useState(false);

  const onDrop = useCallback(
    acceptedFiles => {
      const droppedFile = acceptedFiles.length >= 1 ? acceptedFiles[0] : null;

      if (droppedFile) {
        if (asyncAction) {
          setLoading(true);
          asyncAction(droppedFile)
            .then(values => {
              onChange(values);
              setLoading(false);
            })
            .catch(e => {
              setLoading(false);
              setError(e);
            });
        } else {
          onChange(droppedFile);
        }
      }
    },
    [asyncAction, onChange]
  );

  const onDropRejected = useCallback(
    rejectedFiles => {
      const droppedFile = rejectedFiles.length >= 1 ? rejectedFiles[0] : null;

      if (droppedFile) {
        if (droppedFile.size > maxSize) {
          onRejected(droppedFile, "Max file size 7MB");
        } else {
          onRejected(
            droppedFile,
            "Something went wrong when uploading picture."
          );
        }
      }
    },
    [onRejected, maxSize]
  );

  const { getRootProps, getInputProps, isDragActive } = useReactDropzone({
    multiple,
    accept,
    onDrop,
    disabled,
    maxSize,
    onDropRejected
  });

  useEffect(() => {
    const contentType = get(value, "contentType", null);
    const type = get(value, "type", null);
    if (contentType === "application/pdf" || type === "application/pdf") {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  }, [value]);

  const reset = () => {
    onChange(null);
  };

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    isPdf,
    reset,
    loading,
    error
  };
};
