import { compose, withProps, lifecycle, withState } from "recompose";

import { DEFAULT_LOCATION } from "Variables/DefaultLocation";

import withCurrentUser from "Driver/utils/hocs/withCurrentUser";
import { resolveUserLocation } from "Components/hooks/useLocation";

export const withCurrentLocation = compose(
  withCurrentUser,
  withState("userLocation", "setUserLocation", {
    ...DEFAULT_LOCATION,
    loading: true,
    geoApproved: false
  }),
  lifecycle({
    async componentDidMount() {
      const location = await resolveUserLocation(this.props);
      await this.props.setUserLocation({
        ...location,
        loading: false
      });
    }
  })
);

export default () => withProps(() => ({ google: window.google }));
