/* DO NOT DELETE
  will be using this file as reference later on
*/

export const MetricsTracks = {
  MetricsCategories: {
    dashboard: "DASHBOARD",
    iccList: "ICC_LIST",
    manualList: "MANUAL_LIST",
    activeRental: "ACTIVE_RENTAL",
    bookingFlow: "BOOKING_FLOW",
    claims: "CLAIMS",
    driver: "DRIVER",
    lead: "LEAD",
    owner: "OWNER",
    bgc: "BACKGROUND_CHECK"
  },
  MetricsEvents: {
    driverRegisteredWeb: "DRIVER_REGISTERED_WEB",
    ownerRegisteredWeb: "OWNER_REGISTERED_WEB",
    driverSignedInWeb: "DRIVER_SIGNED_IN_WEB",
    ownerSignedInWeb: "OWNER_SIGNED_IN_WEB",
    allowExtensions: "ALLOW_EXTENSIONS",
    disallowExtensions: "DISALLOW_EXTENSIONS",
    extensionsError: "EXTENSIONS_ERROR",
    approveApplication: "APPROVE_APPLICATION",
    approveApplicationError: "APPROVE_APPLICATION_ERROR",
    rejectApplication: "REJECT_APPLICATION",
    rejectApplicationError: "REJECT_APPLICATION_ERROR",
    confirmPickup: "CONFIRM_PICKUP",
    confirmPickupError: "CONFIRM_PICKUP_ERROR",
    confirmDropoff: "CONFIRM_DROPOFF",
    confirmDropoffError: "CONFIRM_DROPOFF_ERROR",
    damageReported: "DAMAGE_REPORTED",
    ownerRatedDriver: "OWNER_RATED_DRIVER",
    ownerACHSubmit: "OWNER_ACH_SUBMIT",
    ownerACHSubmitError: "OWNER_ACH_SUBMIT_ERROR",
    ownerRatedDriverError: "OWNER_RATED_DRIVER_ERROR",
    bgcFlowAboutYou: "DRIVER_BGC_FLOW_ABOUT_YOU",
    bgcFlowAboutYouError: "DRIVER_BGC_FLOW_ABOUT_YOU_ERROR",
    bgcPaymentSubmitted: "DRIVER_BGC_PAYMENT_SUBMITTED",
    bgcPaymentSubmittedError: "DRIVER_BGC_PAYMENT_SUBMITTED_ERROR",
    bgcFlowSubmitted: "DRIVER_BGC_FLOW_SUBMITTED",
    bgcFlowSubmittedError: "DRIVER_BGC_FLOW_SUBMITTED_ERROR",
    driverCarApplicationSubmitted: "DRIVER_CAR_APPLICATION_SUBMITTED",
    driverCarApplicationSubmittedError:
      "DRIVER_CAR_APPLICATION_SUBMITTED_ERROR",
    profileBgcSubmit: "DRIVER_PROFILE_BGC_SUBMITTED",
    profileBgcSubmitError: "DRIVER_PROFILE_BGC_SUBMITTED_ERROR",
    driverExtendedRental: "DRIVER_EXTENDED_RENTAL",
    driverExtendedRentalError: "DRIVER_EXTENDED_RENTAL_ERROR",
    ownerListCar: "OWNER_LIST_CAR",
    ownerListCarError: "OWNER_LIST_CAR_ERROR",
    usedMessaging: "STARTED_MESSAGE",
    usedMessagingError: "STARTED_MESSAGE_ERROR",
    viewCarDetails: "VIEW_CAR_DETAILS",
    offerDetailsBookNow: "OFFER_DETAILS_BOOK_NOW",
    mobileBookNow: "MOBILE_BOOK_NOW_STICKY",
    prevPager: "PREVIOUS_PAGE",
    nextPager: "NEXT_PAGE",
    rentalContractLinkCheckbox: "RENTAL_CONTRACT_LINK_CHECKBOX",
    cancellationPolicyLinkCheckbox: "CANCELLATION_POLICY_LINK_CHECKBOX",
    offerByMap: "OFFER_BY_MAP",
    ownerEditCar: "OWNER_EDIT_CAR",
    ownerEditCarError: "OWNER_EDIT_CAR_ERROR",
    switchedAccount: "SWITCHED_ACCOUNT",
    termsOfServiceLink: "TERMS_OF_SERVICE_LINK",
    otherPoliciesLink: "OTHER_POLICIES_LINK",
    privacyPolicyLink: "PRIVACY_POLICY_LINK",
    userLogout: "USER_LOGOUT",
    contactUs: "USER_CONTACT_US",
    uberReferral: "UBER_REFERRAL_BUTTON",
    lyftReferral: "LYFT_REFERRAL_BUTTON",
    doordashReferral: "DOORDASH_REFERRAL_BUTTON",
    instacartReferral: "INSTACART_REFERRAL_BUTTON",
    uberEatsReferral: "UBER_EATS_REFERRAL_BUTTON",
    shiptReferral: "SHIPT_REFERRAL_BUTTON",
    listMyFirstCar: "LIST_MY_FIRST_CAR",
    howItWorks: "HOW_IT_WORKS",
    ownerToggleFleetApp: "OWNER_TOGGLE_FLEET_APP",
    ownerApproveBooking: "OWNER_APPROVE_BOOKING",
    ownerApproveBookingError: "OWNER_APPROVE_BOOKING_ERROR",
    ownerRejectBooking: "OWNER_REJECT_BOOKING",
    ownerRejectBookingError: "OWNER_REJECT_BOOKING_ERROR",
    ownerChooseType: "OWNER_CHOOSE_TYPE",
    ownerChooseTypeError: "OWNER_CHOOSE_TYPE_ERROR",
    BackgroundCheck: {
      bgcSmsProfile: "BGC_SMS_PROFILE",
      bgcSmsOffer: "BGC_SMS_OFFER"
    },
    DashboardTracks: {
      ownerStartedManualList: "OWNER_DASHBOARD_STARTED_MANUAL_LIST",
      ownerStartedICCList: "OWNER_DASHBOARD_STARTED_ICC_LIST",
      ownerEditedProfile: "OWNER_DASHBOARD_EDITED_PROFILE",
      ownerFindCarResource: "OWNER_DASHBOARD_FINDCAR_RESOURCE",
      ownerHowItWorksResource: "OWNER_DASHBOARD_HOWITWORKS_RESOURCE",
      ownerHelpResource: "OWNER_DASHBOARD_HELP_RESOURCE",
      ownerBlogResource: "OWNER_DASHBOARD_BLOG_RESOURCE",
      ownerEditCar: "OWNER_EDIT_CAR",
      ownerEditCarProtection: "OWNER_EDIT_CAR_PROTECTION",
      ownerEditCarStatus: "OWNER_EDIT_CAR_STATUS",
      ownerToggledLegacyApp: "OWNER_TOGGLE_LEGACY_APP"
    },
    ManualFlowTracks: {
      ownerBackToDashboard: "OWNER_MANUAL_BACK_TO_DASHBOARD",
      ownerBackToDashboardMidFlow: "OWNER_MANUAL_BACK_TO_DASHBOARD_MIDFLOW",
      ownerPassedLocation: "OWNER_MANUAL_LOCATION_COMPLETE",
      ownerPassedCarInfo: "OWNER_MANUAL_CAR_INFO_COMPLETE",
      ownerPassedProtection: "OWNER_MANUAL_PROTECTION_COMPLETE",
      ownerPassedTerms: "OWNER_MANUAL_TERMS_COMPLETE",
      ownerPassedDocuments: "OWNER_MANUAL_DOCUMENTS_COMPLETE",
      ownerPassedCarImages: "OWNER_MANUAL_CAR_IMAGES_COMPLETE",
      ownerListedCar: "OWNER_LIST_CAR",
      ownerListedCarError: "OWNER_LIST_CAR_ERROR"
    },
    ICCListTracks: {
      ownerBackToDashboard: "OWNER_ICC_BACK_TO_DASHBOARD",
      ownerBackToDashboardMidFlow: "OWNER_ICC_BACK_TO_DASHBOARD_MIDFLOW",
      ownerPassedLocation: "OWNER_ICC_LOCATION_COMPLETE",
      ownerPassedProtection: "OWNER_ICC_PROTECTION_COMPLETE",
      ownerPassedTerms: "OWNER_ICC_TERMS_COMPLETE",
      ownerStartedInsurance: "OWNER_ICC_INSURANCE_UPLOAD_PAGE_STARTED",
      ownerSkippedInsurance: "OWNER_ICC_SKIPPED_INSURANCE",
      ownerPassedInsuranceUpload: "OWNER_ICC_INSURANCE_UPLOAD_PAGE_COMPLETE",
      ownerStartedRegistration: "OWNER_ICC_REGISTRATION_UPLOAD_PAGE_STARTED",
      ownerSkippedRegistration: "OWNER_ICC_SKIPPED_REGISTRATION",
      ownerPassedRegistrationUpload:
        "OWNER_ICC_REGISTRATION_UPLOAD_PAGE_COMPLETE",
      ownerStartedInspection: "OWNER_ICC_INSPECTION_UPLOAD_PAGE_STARTED",
      ownerSkippedInspection: "OWNER_ICC_SKIPPED_INSPECTION",
      ownerPassedInspectionUpload: "OWNER_ICC_INSPECTION_UPLOAD_PAGE_COMPLETE",
      ownerClickedEditICCCar: "OWNER_ICC_CLICKED_EDIT_CAR",
      ownerEditedICCCar: "OWNER_ICC_EDITED_CAR",
      ownerListedICCCars: "OWNER_ICC_LIST_CARS",
      ownerListedICCCarsError: "OWNER_ICC_LIST_CARS_ERROR"
    }
  }
};
