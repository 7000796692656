import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { closeNavigation } from "Redux/Navigation/ActionCreators";
import { AuthContext } from "Login/Auth";
import { OwnerDashboardHeader } from "Components/Header/OwnerDashboardHeader";
import { PrimaryDrawer } from "Components/Drawers/PrimaryDrawer";
import { Home } from "./DashboardViews/Home/Home";
import { Rentals } from "./DashboardViews/Rentals/Rentals";
import { Listings } from "./DashboardViews/Listings/Listings";
import { DashboardSettings } from "./DashboardViews/Settings/DashboardSettings";
import { Resources } from "./DashboardViews/Resources/Resources";
import { HCFBInbox } from "./DashboardViews/Inbox/HCFB/HCFBInbox";
import { Financials } from "./DashboardViews/Financials/Financials";
import { MarketplaceView } from "./DashboardViews/Resources/Marketplace";
import { RouteEnum } from "Enums/RouteEnum";
import { OwnerAlertContext } from "Components/Utils/OwnerAlertProvider";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  alert: {
    paddingTop: theme.spacing(9),
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.spacing(12)
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(15)
    }
  },
  container: {
    flexGrow: 1,
    width: "100%"
  }
}));

export const DashboardController = connect(
  state => ({
    navigationOpen: state.fleet.navigation.navigationOpen
  }),
  { closeNavigation }
)(({ navigationOpen, closeNavigation }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { logout } = useContext(AuthContext);
  const { showAlert } = useContext(OwnerAlertContext);

  return (
    <div className={classes.root}>
      <OwnerDashboardHeader logoutUser={logout} />
      <PrimaryDrawer
        open={navigationOpen}
        setDrawerClosed={closeNavigation}
        permanent={matches}
      />
      <div className={classes.container}>
        <div className={classes.toolbar} />
        {showAlert && <div className={classes.alert} />}
        <Switch>
          <Route path={RouteEnum.baseRoute} component={Home} exact />
          <Route path={RouteEnum.rentals} component={Rentals} />
          <Route path={RouteEnum.listings} component={Listings} />
          <Route path={RouteEnum.inbox} component={HCFBInbox} />
          <Route path={RouteEnum.resources} component={Resources} />
          <Route path={RouteEnum.settings} component={DashboardSettings} />
          <Route path={RouteEnum.marketplace} component={MarketplaceView} />
          <Route path={RouteEnum.financials} component={Financials} />
        </Switch>
      </div>
    </div>
  );
});

DashboardController.propTypes = {
  metrics: PropTypes.object,
  metricsCategories: PropTypes.object,
  metricsEvents: PropTypes.object
};
