import React from "react";
import { TextContainer } from "Components/Text/TextContainer";

export const TicketsChecklist = () => {
  return (
    <>
      <TextContainer
        title="Step 1"
        subtitle="Work it out with the driver"
        paragraphs={[
          `The easiest and fastest way to get reimbursed is to simply ask the
        driver. We suggest sending them a picture of the ticket and requesting
        payment through a safe and secure processor such as PayPal, Venmo, or
        Square.`
        ]}
      />
      <TextContainer
        title="Step 2"
        subtitle="Transfer Liability"
        paragraphs={[
          `If the driver is unresponsive, you may be able to transfer liability
        directly to them, so you are no longer legally responsible. Read the
        back of the ticket or toll and follow the instructions. You will most
        likely need to download the rental contract and include it with your
        Transfer of Liability request.`
        ]}
      />
      <TextContainer
        title="Step 3"
        subtitle="Pay it and send us proof"
        paragraphs={[
          () => (
            <>
              If the driver is unresponsive or the ticket is non-transferable,
              we suggest you pay the ticket before the deadline to avoid late
              fees (we don’t cover those). Don’t worry, we’ll reimburse you!
              Just submit a request in the HyreCar Help Center.
              <br />
              <br />
              In the description, please list the date, car, and driver for each
              ticket/toll. Please attach an image of the original ticket/toll.
            </>
          )
        ]}
      />
    </>
  );
};
