import React, { useContext } from "react";
import { Field, formValues, arrayRemove } from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TelegramIcon from "@mui/icons-material/Telegram";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ImageDropzone } from "Components/Forms/Dropzone/ImageDropzone";
import { Image } from "Components/Images/Image";
import { AcceptEnum } from "Enums/AcceptEnum";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(2)
  },
  spacingBottom: {
    marginBottom: theme.spacing(2)
  }
}));

export const UploadImagesFormFields = compose(
  formValues("photos"),
  connect(null, { arrayRemove })
)(props => {
  const { getAsset } = useContext(FirebaseContext);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchesxs = useMediaQuery(theme.breakpoints.down("sm"));
  const { photos, form, arrayRemove, text } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleRejectedFiles = () => {
    enqueueSnackbar(
      "Sorry, some of the files you uploaded might have been rejected because there were too many or were too large.",
      {
        variant: "error",
        autoHideDuration: 5000
      }
    );
  };

  const handleOnDelete = index => () => {
    const currentUpload = photos[index];
    arrayRemove(form, "photos", index);
    URL.revokeObjectURL(currentUpload);
  };

  if (typeof form !== "string")
    throw new Error(
      'UploadImagesFormFields requires "form" prop to be of type String. It should be the name of the form it is under.'
    );

  const message = text ? (
    text
  ) : (
    <>
      Please provide images of your car’s condition before and after the rental.{" "}
      <br />
      (Hint - the more details you provide, the better)
      <br />
      Maximum File Size: 7MB, Max Uploads: 10
    </>
  );

  return (
    <>
      {matches && (
        <Typography
          component="p"
          variant="subtitle1"
          className={classNames(classes.spacingBottom)}
        >
          {message}
        </Typography>
      )}
      <Field
        name="photos"
        component={ImageDropzone}
        buttonId="upload-damage-photos"
        accept={[AcceptEnum.png, AcceptEnum.jpeg, AcceptEnum.pdf]}
        multiple
        maxSize={7340032}
        fullWidth
        contentBottomMargin={4}
        content={!matches ? message : null}
        onRejectFiles={handleRejectedFiles}
        buttonOnly={matchesxs}
        disabled={Array.isArray(photos) && photos.length >= 10}
        maxToUpload={Array.isArray(photos) ? 10 - photos.length : 10}
      />
      {Array.isArray(photos) && (
        <Grid container spacing={3} className={classNames(classes.spacing)}>
          {photos.map((upload, index) => {
            const src = URL.createObjectURL(upload);

            return [AcceptEnum.jpeg, AcceptEnum.png].includes(upload.type) ? (
              <Grid item md={4} xs={6} key={index}>
                <Image
                  src={src}
                  alt="Damage"
                  objectFit="contain"
                  height={matches ? 105 : 250}
                  actions={[
                    {
                      action: handleOnDelete(index),
                      icon: <DeleteForeverIcon color="error" />
                    }
                  ]}
                />
              </Grid>
            ) : (
              <Grid item md={4} xs={6} key={index}>
                <Image
                  src={getAsset("pdfPreviewIcon")}
                  alt="PDF"
                  objectFit="cover"
                  height={matches ? 105 : 250}
                  actions={[
                    {
                      href: src,
                      icon: <TelegramIcon color="primary" />
                    },
                    {
                      action: handleOnDelete(index),
                      icon: <DeleteForeverIcon color="error" />
                    }
                  ]}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
});

UploadImagesFormFields.propTypes = {
  form: PropTypes.string.isRequired
};
