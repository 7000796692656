import { gql } from "@apollo/client";

export const PaymentFragments = {
  paymentOwnerEarningsInCents: gql`
    fragment PaymentOwnerEarningsInCents on RentalPayment {
      id
      ownerEarningsInCents
    }
  `
};
