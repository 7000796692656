import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    padding: theme.spacing(1)
  },
  title: {
    color: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
  },
  cancel: {
    color: theme.palette.error.main,
    background: "transparent",
    boxShadow: "none"
  },
  buttons: {
    flexWrap: "wrap"
  }
}));

export const ActionDialog = props => {
  const {
    open,
    onClose,
    title,
    dialogContentText,
    content,
    action,
    actionLabel,
    cancel,
    cancelLabel,
    maxWidth,
    cancelOnRight,
    cancelVariant,
    actionVariant,
    disabled,
    actionColor = "primary",
    actionClass = "",
    noTextTransform = false,
    onBackdropClick,
    ...rest
  } = props;
  const classes = useStyles({ classes: rest.classes });

  const textStyle = noTextTransform ? { textTransform: "none" } : {};
  const header = <DialogTitle className={classes.title}>{title}</DialogTitle>;

  const buttons = cancelOnRight ? (
    <>
      {action && actionLabel && (
        <Button
          color="primary"
          onClick={action}
          variant={actionVariant ? actionVariant : "text"}
          disabled={disabled}
          style={textStyle}
        >
          {actionLabel}
        </Button>
      )}
      {cancel && cancelLabel && (
        <Button
          onClick={cancel}
          variant={cancelVariant ? cancelVariant : "text"}
          className={classes.cancel}
          disabled={disabled}
          style={textStyle}
        >
          {cancelLabel}
        </Button>
      )}
    </>
  ) : (
    <>
      {cancel && cancelLabel && (
        <Button
          onClick={cancel}
          disabled={disabled}
          variant={cancelVariant || "text"}
          style={textStyle}
        >
          {cancelLabel}
        </Button>
      )}
      {action && actionLabel && (
        <Button
          color={actionColor}
          className={actionClass}
          onClick={action}
          variant={actionVariant ? actionVariant : "text"}
          disabled={disabled}
          style={textStyle}
        >
          {actionLabel}
        </Button>
      )}
    </>
  );

  return (
    <Dialog
      onBackdropClick={() => onBackdropClick && onBackdropClick()}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth ? maxWidth : "xs"}
      fullWidth
      classes={{
        paper: classes.dialogPaper
      }}
    >
      {title && header}
      <DialogContent>
        {dialogContentText && (
          <DialogContentText>{dialogContentText}</DialogContentText>
        )}
        {content && content}
      </DialogContent>
      <DialogActions className={classes.buttons}>{buttons}</DialogActions>
    </Dialog>
  );
};

ActionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dialogContentText: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node,
    PropTypes.object
  ]),
  action: PropTypes.func,
  actionLabel: PropTypes.string,
  cancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  maxWidth: PropTypes.string,
  cancelOnRight: PropTypes.bool,
  cancelVariant: PropTypes.string,
  actionVariant: PropTypes.string,
  actionColor: PropTypes.string,
  actionClass: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.object
};
