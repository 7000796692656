import {
  ENABLE_HEADER,
  DISABLE_HEADER,
  SET_TITLE,
  RESET_TITLE,
  ON_SEARCH_CHANGE,
  RESET_SEARCH,
  SET_FILTERS,
  RESET_FILTERS,
  SELECT_FILTER,
  DESELECT_FILTER
} from "./Actions";

export const enableHeader = () => {
  return {
    type: ENABLE_HEADER
  };
};

export const disableHeader = () => {
  return {
    type: DISABLE_HEADER
  };
};

export const setTitle = title => {
  return {
    type: SET_TITLE,
    title
  };
};

export const resetTitle = () => {
  return {
    type: RESET_TITLE
  };
};

export const onSearchChange = value => {
  return {
    type: ON_SEARCH_CHANGE,
    value
  };
};

export const resetSearch = () => {
  return {
    type: RESET_SEARCH
  };
};

export const setFilters = filters => ({
  type: SET_FILTERS,
  filters
});

export const resetFilters = () => ({
  type: RESET_FILTERS
});

export const selectFilter = filter => ({
  type: SELECT_FILTER,
  filter
});

export const deselectFilter = filter => ({
  type: DESELECT_FILTER,
  filter
});
