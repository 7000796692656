import React from "react";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PropTypes from "prop-types";

import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";
import { DrawerArrayImageHolder } from "Components/Drawers/DrawerArrayImageHolder";
import { useRentalImages } from "Queries/Rentals/hooks/useRentalImages";

export const RentalImages = ({ onClose }) => {
  const { id } = useParams();
  const { prePhotos, loading } = useRentalImages(id);

  return (
    <>
      <DrawerAppBar
        title="Rental Images"
        border
        icon={
          <IconButton onClick={onClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        }
      />
      {loading ? (
        <SkeletonDrawer />
      ) : (
        <DrawerPaddingContainer>
          <DrawerArrayImageHolder
            border={false}
            images={prePhotos}
            withCaption
          />
        </DrawerPaddingContainer>
      )}
    </>
  );
};

RentalImages.propTypes = {
  onClose: PropTypes.func
};
