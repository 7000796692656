import React from "react";

import BeenhereIcon from "@mui/icons-material/Beenhere";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme => ({
  infoItem: {
    marginTop: "0.15em"
  },
  helpIcon: {
    fontSize: "1.25em",
    marginRight: theme.spacing(0.5)
  }
}));

export const Badge = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.infoItem}
    >
      <BeenhereIcon color="primary" className={classes.helpIcon} />
      <Typography variant="body2">Phone Verified</Typography>
    </Grid>
  );
};
