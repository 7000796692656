import React from "react";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dashboardLayout: {
    padding: theme.spacing(6, 4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    }
  },
  fullScreen: {
    padding: 0
  },
  toolbar: {
    minHeight: theme.spacing(6)
  }
}));

export const DashboardLayout = props => {
  const { children, className = "", fixed, fullScreen, hasAppBar } = props;
  const classes = useStyles(props);

  return (
    <>
      {hasAppBar && <div className={classes.toolbar} />}
      <Container
        fixed={fixed}
        maxWidth="xl"
        className={classNames(
          {
            [classes.dashboardLayout]: !fullScreen,
            [classes.fullScreen]: fullScreen
          },
          className
        )}
      >
        {children}
      </Container>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  fullScreen: PropTypes.bool,
  hasAppBar: PropTypes.bool
};
