import React, { useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ArrowForward from "@mui/icons-material/ArrowForward";

import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import StepAppBar from "../../StepAppBar";
import { Field, reduxForm, formValues } from "redux-form";
import { FormCheckbox } from "Components/Inputs/FormCheckbox";
import { getLastSixDigits, getPhotoUrl } from "../stepUtils";
import { defaultSnoozeReasons } from "../selectReason/CarSwitchReasonFormFields";
import BubbleContainer from "./BubbleContainer";
import DifferentPriceOrPlanContainer from "./DifferentPriceOrPlanContainer";
import { snakeToProperCase } from "Utils/Helpers";

const isSnoozedWhen = reason => defaultSnoozeReasons.includes(reason);

const useStyles = makeStyles(theme => ({
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  h6: {
    fontWeight: 600,
    letterSpacing: "0.15px",
    lineHeight: "30px",
    marginBottom: props => props.h6warning && "10px",
    color: props => (props.h6Warning ? theme.palette.warning.dark : "#000")
  },
  subtitle1: {
    color: "#000",
    fontWeight: 600,
    letterSpacing: "0.15px"
  },
  subtitle2: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0.1px",
    marginBottom: "8px"
  },
  body2: {
    lineHeight: "21px",
    letterSpacing: "0.25px",
    color: props =>
      props.priceOrProtectionPlanDiff && theme.palette.warning.dark,
    whiteSpace: props => props.noWrap && "nowrap"
  },
  backIcon: {
    color: "rgba(0,0,0,0.87)"
  },
  avatar: {
    width: "97px",
    height: "97px"
  },
  overline: {
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "1.5px"
  },
  imagePadding: {
    paddingBottom: "28px"
  }
}));

const CarImageAvatars = ({ oldCar, newCar }) => {
  const { avatar, imagePadding } = useStyles({
    h6Warning: true
  });
  const oldCarPhoto = getPhotoUrl(oldCar);
  const newCarPhoto = getPhotoUrl(newCar);
  return (
    <Grid className={imagePadding} container alignItems="center">
      <Grid container item xs={5}>
        <Avatar
          variant="square"
          className={avatar}
          src={oldCarPhoto}
          justify="center"
        />
      </Grid>
      <Grid container item xs={2}>
        <ArrowForward fontSize="large" />
      </Grid>
      <Grid container item xs={5}>
        <Avatar
          variant="square"
          className={avatar}
          src={newCarPhoto}
          justify="center"
        />
      </Grid>
    </Grid>
  );
};

const CarDetails = ({ car, hasPriceOrProtectionPlanDiff }) => {
  const { overline, subtitle1, body2 } = useStyles({
    h6Warning: true,
    noWrap: true,
    priceOrProtectionPlanDiff: hasPriceOrProtectionPlanDiff
  });
  const displayVin = getLastSixDigits(car.vin);
  const carDetails = `'${car.year.slice(2)} ${car.make} ${car.model}`;
  const carPriceAndProtection = `$${car.dailyPriceInCents /
    100} / ${snakeToProperCase(car.defaultProtectionPlan)}`;

  return (
    <div>
      <Typography className={overline} variant="overline">
        {displayVin}
      </Typography>
      <Typography className={subtitle1} variant="subtitle1">
        {carDetails}
      </Typography>
      <Typography className={body2} variant="body2">
        {carPriceAndProtection}
      </Typography>
    </div>
  );
};

const CarsInformation = ({ oldCar, newCar, hasPriceOrProtectionPlanDiff }) => (
  <BubbleContainer padding="24px 16px 24px 16px">
    <CarImageAvatars oldCar={oldCar} newCar={newCar} />
    <Grid container>
      <Grid item container xs={5}>
        <CarDetails car={oldCar} />
      </Grid>
      <Grid container item xs={2} />
      <Grid container item xs={5}>
        <CarDetails
          car={newCar}
          hasPriceOrProtectionPlanDiff={hasPriceOrProtectionPlanDiff}
        />
      </Grid>
    </Grid>
  </BubbleContainer>
);

const SnoozeOption = ({ oldCar }) => {
  const { h6, subtitle2 } = useStyles({
    warning: true
  });

  const title = "Snooze? (Optional)";
  const subTitle = `Would you like to snooze your ${oldCar.year} ${
    oldCar.make
  } ${oldCar.model} (${getLastSixDigits(oldCar.vin)})?`;
  const optionLabel = (
    <Typography className={subtitle2} variant="subtitle2">
      Yes, Snooze original listing
    </Typography>
  );

  return (
    <BubbleContainer>
      <Typography className={h6} variant="h6">
        {title}
      </Typography>
      <Typography className={subtitle2} variant="subtitle2">
        {subTitle}
      </Typography>
      <Field name="snooze" component={FormCheckbox} label={optionLabel} />
    </BubbleContainer>
  );
};

const ConfirmDetails = ({
  onClose,
  prevStep,
  handleSubmit,
  rental: { car: oldCar },
  loading,
  car: newCar,
  reason,
  change
}) => {
  const updateSnoozeOption = () => change("snooze", isSnoozedWhen(reason));

  useEffect(() => {
    updateSnoozeOption(reason);
  }, []);

  const hasPriceOrProtectionPlanDiff =
    newCar.dailyPriceInCents !== oldCar.dailyPriceInCents ||
    newCar.defaultProtectionPlan !== oldCar.defaultProtectionPlan;
  return (
    <>
      <StepAppBar title="Confirm Details" onClose={onClose} />
      <DrawerPaddingContainer>
        <CarsInformation
          oldCar={oldCar}
          newCar={newCar}
          hasPriceOrProtectionPlanDiff={hasPriceOrProtectionPlanDiff}
        />
        <DifferentPriceOrPlanContainer
          showNote={hasPriceOrProtectionPlanDiff}
          oldCar={oldCar}
          newCar={newCar}
          onUpdate={updatedCar => change("car", updatedCar)}
        />
        <SnoozeOption oldCar={oldCar} />
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onCancel={prevStep}
        cancelText="Back"
        onSubmit={handleSubmit}
        submitText="Switch Cars"
        disabled={loading}
        useLoadingComponent
      />
    </>
  );
};

ConfirmDetails.propTypes = {
  onClose: PropTypes.func,
  prevStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  rental: PropTypes.object,
  loading: PropTypes.bool,
  car: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  reason: PropTypes.string,
  change: PropTypes.func
};

export default reduxForm({ form: "CAR_SWITCH" })(
  formValues({ car: "car", reason: "reason" })(ConfirmDetails)
);
