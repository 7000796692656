import { gql } from "@apollo/client";

import { OwnerMutationFragments } from "./OwnerMutationFragments";

export const OWNER_LIST_CAR = gql`
  mutation OwnerListCar($input: ListCarInput!) {
    listCar(data: $input) {
      code
      success
      message
      car {
        id
      }
    }
  }
`;

export const FLEET_UPDATE_CARS = gql`
  mutation FleetUpdate($input: [FleetUpdateCarInput!]!) {
    fleetUpdateCars(data: $input) {
      ...OwnerFleetUpdateCarsPayload
    }
  }
  ${OwnerMutationFragments.ownerFleetUpdateCarsPayload}
`;

export const UPDATE_OWNER = gql`
  mutation UpdateOwner($id: ID, $type: OwnerType) {
    updateOwnerData(where: { id: $id }, data: { type: $type }) {
      owner {
        id
        type
      }
    }
  }
`;
