import React, { useContext } from "react";
import { reduxForm } from "redux-form";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { Tooltip } from "Components/Flows/Tooltip";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsDamageController";
import { CauseOfDamageFormFields } from "Components/Forms/FormFields/Claims/CauseOfDamageFormFields";
import { ContactSupportButton } from "Components/Buttons/ContactSupportButton";
import { useClaimsDamageFormController } from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";

const ClaimsDamageCauseForm = reduxForm({
  form: "CLAIMS_DAMAGE",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep, submitting }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      header="Cause of Damage"
      nextStepLabel={submitting ? "submitting..." : "submit"}
      nextStepId="submit-accident-form"
      previousStepLabel="Back"
      previousStep={prevStep}
      previousStepId="back-causeofdamage"
      disabled={submitting}
    >
      <CauseOfDamageFormFields />
    </FlowContentContainer>
  );
});

export const ClaimsDamageCause = () => {
  const { onSubmit, prevStep } = useContext(StepperContext);
  const { validate } = useClaimsDamageFormController();

  const leftComponent = (
    <ClaimsDamageCauseForm
      onSubmit={onSubmit}
      prevStep={prevStep}
      validate={validate}
    />
  );

  const rightComponent = (
    <TooltipContainer header="Helpful tips">
      <Tooltip
        header="What if I don’t have everything?"
        paragraphs={[
          "We understand you may not have all the answers. That’s okay! Our claims specialists will attempt to collect this information from the driver too. The more you can provide to us early on, the quicker we can process your claim.",
          <ContactSupportButton
            key="cc962e8c-412e-4d75-ba9e-ce2642cd2f73"
            preText="Need help? "
            buttonText="CONTACT SUPPORT"
          />
        ]}
      />
    </TooltipContainer>
  );

  return (
    <ColumnFlowLayout
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      useBottomDrawer
    />
  );
};
