import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column"
  }
});

export const FormCheckboxGroup = props => {
  const classes = useStyles();
  const { input, meta, options, label, useErrorAsLabel, useColumns } = props;
  const { value, onChange, onBlur } = input;
  const { touched, valid, error } = meta;
  const hasError = touched && !valid;

  const handleChange = optionVal => event => {
    onChange({ ...value, [optionVal]: event.target.checked });
  };

  const handleBlur = () => {
    onBlur();
  };

  const getColumnOptions = () => {
    const half = Math.ceil(options.length / 2);

    const firstHalf = options.slice(0, half);
    const secondHalf = options.slice(-half);
    return [firstHalf, secondHalf];
  };

  const getOptions = () => (useColumns ? getColumnOptions() : [options]);

  return (
    <FormControl error={hasError} fullWidth>
      {useErrorAsLabel && error && hasError && <FormLabel>{error}</FormLabel>}
      {label && !useErrorAsLabel && <FormLabel>{label}</FormLabel>}
      <FormGroup>
        <Grid container>
          {getOptions().map((options, i) => (
            <Grid
              key={i}
              item
              xs={useColumns ? 6 : 12}
              className={classes.container}
            >
              {options.map(({ value: optionVal, label, id }, index) => (
                <FormControlLabel
                  key={index}
                  data-component={label}
                  control={
                    <Checkbox
                      value={optionVal}
                      checked={value[optionVal]}
                      onChange={handleChange(optionVal)}
                      inputProps={{
                        onBlur: handleBlur
                      }}
                      id={id || "checkbox"}
                      color="primary"
                    />
                  }
                  label={label}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

FormCheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ).isRequired,
  label: PropTypes.string,
  useErrorAsLabel: PropTypes.bool,
  useColumns: PropTypes.bool
};
