import { useContext } from "react";
import { useMutation } from "@apollo/client";
import get from "lodash/get";
import jwtDecode from "jwt-decode";

import { validateLocation } from "Components/Forms/Location/LocationAutoComplete";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CREATE_ROOFTOP } from "Mutations/Rooftop/RooftopMutations";
import { REFRESH_TOKENS } from "Queries/Organization/OrganizationQueries";

const memberRequiredFields = ["name"];

export const CreateRooftopFormController = ({
  children,
  onSubmitSuccess,
  onSubmitFail,
  organizationToken
}) => {
  const { organizationClients, insertRooftopInfo } = useContext(
    ClientFactoryContext
  );
  const [createRooftop] = useMutation(CREATE_ROOFTOP, {
    client:
      organizationClients[
        organizationToken ? jwtDecode(organizationToken).id : ""
      ]
  });

  const onSubmit = async values => {
    try {
      const createdRooftop = await createRooftop({
        variables: {
          input: {
            name: get(values, "name"),
            address: {
              geo: {
                lat: get(values, "address.lat"),
                lng: get(values, "address.lng")
              },
              country: get(values, "address.country"),
              streetAddress: get(values, "address.street"),
              city: get(values, "address.city"),
              state: get(values, "address.state"),
              postalCode: get(values, "address.zip")
            },
            profilePhotoId: get(values, "image", "")
          }
        }
      });

      const createdRooftopData = get(createdRooftop, "data.createRooftop");

      const refreshedTokens = get(
        await organizationClients[
          organizationToken ? jwtDecode(organizationToken).id : ""
        ].query({
          query: REFRESH_TOKENS,
          fetchPolicy: "network-only"
        }),
        "data.refreshTokens"
      );

      insertRooftopInfo(createdRooftopData.id, refreshedTokens);

      if (onSubmitSuccess) {
        onSubmitSuccess(createdRooftopData);
      }
    } catch (e) {
      console.error(e);
      if (onSubmitFail) onSubmitFail(e);
    }
  };

  const validate = values => {
    const errors = {};

    memberRequiredFields.map(field => {
      errors[field] = !values[field] ? "Required" : undefined;
      return errors;
    });

    const locationErrors = validateLocation(values, "address");
    Object.assign(errors, { ...locationErrors });
    if (
      get(values, "address.address") &&
      get(values, "address.address")
        .toLowerCase()
        .includes("po box")
    ) {
      errors.address = "PO BOX are not allowed";
    }

    return errors;
  };
  return children({
    onSubmit,
    validate
  });
};
