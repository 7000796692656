import { metrics } from "react-metrics";

import GetSiteControl from "Driver/utils/getSiteControl";

class Analytics {
  constructor(options = {}) {
    this.analytics = window.analytics;
    this.name = "Analytics";
    this._loaded = false;
    this.options = options;
  }
  /**
   *
   * @method pageView
   * @param {String} eventName
   * @param {Object} params
   * @returns {Promise}
   * @internal
   */
  pageView(...args) {
    return this.track(...args);
  }
  user(userId) {
    return new Promise(resolve => {
      this.userId = userId;
      resolve({
        userId
      });
    });
  }
  /**
   *
   * @method track
   * @param {String} eventName
   * @param {Object} params
   * @returns {Promise}
   * @internal
   */
  track(eventName, params) {
    return new Promise(resolve => {
      this._load()
        .then(() => {
          this._track(eventName, params);
          resolve({
            eventName,
            params
          });
        })
        .catch(error => {
          console.error("Failed to initialize analytics", error);
          // reject(error);
        });
    });
  }
  /**
   *
   * @method _track
   * @param {String} eventName
   * @param {Object} params
   * @protected
   */
  _track(eventName, params) {
    try {
      let context = {};

      if (Object.prototype.hasOwnProperty.call(params, "context")) {
        context = JSON.parse(JSON.stringify(params.context));
        delete params.context;
      }

      if (eventName === "pageView") {
        this.analytics.page(params.category, params);
        GetSiteControl.pageView(window.location.pathname);
        return;
      }

      this.analytics.track(eventName, params, {
        context: context
      });
    } catch (e) {
      console.error(e);
    }
  }
  /**
   *
   * @method _load
   * @protected
   */
  _load() {
    this.analytics = window.analytics;
    return (
      this._promise ||
      (this._promise = new Promise(resolve => {
        if (this._loaded) {
          resolve();
        } else {
          this.analytics.ready(() => {
            this._loaded = true;
            resolve();
          });
        }
      }))
    );
  }
}

const config = {
  vendors: [
    {
      name: "Analytics",
      api: new Analytics()
    }
  ],
  pageViewEvent: "pageView",
  pageDefaults: () => {
    return {
      siteName: "Hyrecar"
    };
  }
};

export default metrics(config, {
  autoTrackPageView: false,
  suppressTrackBindingWarning: true
});
