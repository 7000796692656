import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Field } from "redux-form";

import { FormDatePicker } from "Components/Inputs/FormDatePicker";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(4)
  }
}));

export const LastInspectionDateFormFields = () => {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.spacing}
        component="p"
        gutterBottom
        variant="subtitle1"
      >
        Date of last inspection
      </Typography>
      <Field
        autoOk
        component={FormDatePicker}
        disablePast
        disableToolbar
        fullWidth
        inputVariant="filled"
        label="When did you last inspect your car? "
        name="lastInspectionDate"
        variant="inline"
      />
    </>
  );
};
