import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import moment from "moment";
import PropTypes from "prop-types";

import {
  getRentalRequestExpiration,
  getRentalDaysLeftOrDaysLateText
} from "Utils/Calculations";
import { RentalStatusEnum } from "Enums/StateEnums";
import { openSupportPage } from "Utils/openSupportPage";
import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { DrawerHead } from "Components/Drawers/DrawerHead";
import { RentalProgressIndicator } from "Components/Drawers/RentalDrawer/RentalProgressIndicator";
import { calculateTimePastCompleted } from "Utils/Calculations";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { parseRentalStartAndEndDate } from "Utils/Helpers";

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.common.white
  }
}));

export const RentalDrawerHead = ({ onClose }) => {
  const classes = useStyles();
  const { id } = useParams();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    data: { rental }
  } = useQuery(RENTAL_REQUEST_QUERY, {
    variables: {
      id
    },
    client: currentRooftopClient
  });

  const getRentalDrawerHeadText = () => {
    const driverFirstName = get(rental, "driver.user.firstName", "");
    const driverLastName = get(rental, "driver.user.lastName", "");
    const droppedOffAt = get(rental, "droppedOffAt");
    const expireDateForSubmitClaim = moment(droppedOffAt).add(24, "hours");

    switch (rental.status) {
      case RentalStatusEnum.applied:
        return (
          <>
            {`${driverFirstName} ${driverLastName[0]}.`} has requested to rent
            your car. Accepting this rental will auto-reject all other requests
            for this car or driver.
          </>
        );
      case RentalStatusEnum.pendingInsurance:
        return (
          <>
            Hold tight while we generate insurance and rental contracts. Your
            rental is <b>NOT INSURED YET</b>. Please do not exchange keys until
            your car is Ready for Check-in
          </>
        );
      case RentalStatusEnum.cancelled:
        return (
          <>
            We&apos;re sorry. This rental has been cancelled at the request of
            the owner. If you have any questions, contact{" "}
            <Link onClick={() => openSupportPage()} underline="hover">
              SUPPORT
            </Link>
            .
          </>
        );
      case RentalStatusEnum.pendingPickup:
        return `Insurance and contracts are ready. Start the Check-in process and hand off the keys to ${driverFirstName}.`;
      case RentalStatusEnum.active:
        return `Sit back and relax while you earn passive income. We’ll remind you when it’s time to end the rental.`;
      case RentalStatusEnum.late:
        return (
          <>
            Contact {`${driverFirstName}`} immediately to retrieve your car or
            ask them to pay for an extension. If you need immediate help,
            contact{" "}
            <Link onClick={() => openSupportPage()} underline="hover">
              SUPPORT
            </Link>
            .
          </>
        );
      case RentalStatusEnum.completed:
        return calculateTimePastCompleted(droppedOffAt) <= 24 ? (
          <>
            The rental has ended. If you need to report damage or request
            reimbursement, please file a claim before{" "}
            <b>
              {`${expireDateForSubmitClaim.format("MMMM DD")}`} at{" "}
              {`${expireDateForSubmitClaim.format("hh:mm A")}`}
            </b>
            .
          </>
        ) : (
          <>
            The rental has ended. If you need to report damage or request
            reimbursement, please visit our Help Center.
          </>
        );
      default:
        return (
          <>
            {`${driverFirstName} ${driverLastName[0]}.`} has requested to rent
            your car. Accepting this rental will auto-reject all other requests
            for this car or driver.
          </>
        );
    }
  };

  const getRentalDrawerHeadSubtitle = rental => {
    switch (rental.status) {
      case RentalStatusEnum.applied:
        return `Requested - Expires in ${getRentalRequestExpiration(
          rental.createdAt
        )}`;
      case RentalStatusEnum.pendingInsurance:
        return "Accepted (Generating Insurance)";
      case RentalStatusEnum.cancelled:
        return "Cancelled";
      case RentalStatusEnum.pendingPickup:
        return "Ready for Check-in";
      case RentalStatusEnum.active:
        return `Active ${getRentalDaysLeftOrDaysLateText(
          get(rental, "currentRentalPeriod.endDate")
        )}`;
      case RentalStatusEnum.late:
        return `${getRentalDaysLeftOrDaysLateText(
          get(rental, "currentRentalPeriod.endDate")
        )}`;
      case RentalStatusEnum.completed:
        return "Completed";
      default:
        return `Requested - Expires in ${getRentalRequestExpiration(
          rental.createdAt
        )}`;
    }
  };

  const rentalDatesSpan = parseRentalStartAndEndDate(rental);

  const title = `${moment(rentalDatesSpan.startDate).format("MMM D")}
  -
  ${moment(rentalDatesSpan.endDate).format("MMM DD, YYYY")}`;

  return (
    <DrawerHead
      title={title}
      subtitle={getRentalDrawerHeadSubtitle(rental)}
      error={rental.status === RentalStatusEnum.late}
      disabled={rental.status === RentalStatusEnum.cancelled}
      onClose={onClose}
      overlineComponent={
        <RentalProgressIndicator rentalStatus={rental.status} />
      }
    >
      <Typography component="p" variant="subtitle1" className={classes.text}>
        {getRentalDrawerHeadText()}
      </Typography>
    </DrawerHead>
  );
};

RentalDrawerHead.propTypes = {
  onClose: PropTypes.func
};
