import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

export const useRental = () => {
  const { id } = useParams();
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const { data, loading } = useQuery(RENTAL_REQUEST_QUERY, {
    variables: { id },
    client: currentRooftopClient
  });

  return { rental: data?.rental, loading };
};
