import { useContext } from "react";
import { useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { useTheme } from "@mui/material/styles";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FETCH_ALL_LISTINGS } from "Queries/Fleet/FleetQueries";
import { centsToDollars } from "Utils/Calculations";
import {
  CarStatusEnum,
  ListingStatusCopyEnum,
  ListingVeritifactionStatusCopyEnum,
  ProtectionPlanCopyEnum
} from "Enums/StateEnums";
import { getListingStatusOptions } from "Components/hooks/useListingStatusOptions";

const CSV_HEADERS = [
  '"Yr, Make, Model"',
  "VIN",
  "Daily List Price",
  "Protection Plan",
  "Vehicle Status",
  "Vehicle Verification Status"
];

export const useCsvListings = () => {
  const theme = useTheme();
  const { currentRooftopClient, currentRooftopToken } = useContext(
    ClientFactoryContext
  );

  const getRooftopName = () => jwtDecode(currentRooftopToken).name;

  const { refetch: fetchData } = useQuery(FETCH_ALL_LISTINGS, {
    client: currentRooftopClient,
    fetchPolicy: "network-only",
    skip: true
  });

  const getCarStatus = car => {
    const { copy: status } = getListingStatusOptions(
      car.status,
      car.verification,
      theme
    );

    const CAR_STATUS_MAP = {
      [CarStatusEnum.removed]: ListingStatusCopyEnum.snoozed,
      [CarStatusEnum.deleted]: ListingStatusCopyEnum.deleted
    };

    return CAR_STATUS_MAP[car.status] || status;
  };

  const formatData = data =>
    data?.viewer?.me?.owner?.cars?.edges?.map(({ node: car }) => {
      return [
        `${car.year} ${car.make} ${car.model}`,
        car.vin,
        `$${centsToDollars(car.dailyPriceInCents)}`,
        ProtectionPlanCopyEnum[car.defaultProtectionPlan],
        getCarStatus(car),
        ListingVeritifactionStatusCopyEnum[car.verification]
      ];
    });

  return {
    fetchData,
    formatData,
    fileName: `${getRooftopName()} Inventory List.csv`,
    headers: CSV_HEADERS
  };
};
