import React from "react";
import { Field, formValues } from "redux-form";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import capitalize from "lodash/capitalize";
import filter from "lodash/filter";

import { FormSelectCheckbox } from "Components/Inputs/FormSelectCheckbox";
import { FormTextField } from "Components/Inputs/FormTextField";
import {
  damageDetailsOptions,
  MAX_DAMAGE_DESCRIPTION_CHARACTERS
} from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(4)
  }
}));

const filterOptionsByGroup = (damage, options) => {
  const isExterior = option =>
    option.group === "exterior" &&
    (damage.autoTheft ||
      damage.carAccident ||
      damage.carDamage ||
      damage.hitAndRun);

  const isInterior = option =>
    option.group === "Interior and Mechanical" &&
    (damage.interiorDamage || damage.mechanicalIssues);

  const result = filter(options, option => {
    if (isExterior(option)) {
      return true;
    } else if (isInterior(option)) {
      return true;
    }
  });

  return result;
};

export const DamageDetailsFormFields = formValues("damage")(({ damage }) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="p" variant="subtitle1" gutterBottom>
        Where is the damage?
      </Typography>
      <Field
        name="damagedAreas"
        component={FormSelectCheckbox}
        id="damaged-areas"
        options={filterOptionsByGroup(damage, damageDetailsOptions)}
        placeholder="Select all damaged areas"
        label="Damage Type"
        groupBy={option => capitalize(option.group)}
      />
      <Typography
        component="p"
        variant="subtitle1"
        gutterBottom
        className={classes.spacing}
      >
        Please describe what the damage looks like:
      </Typography>
      <Field
        name="damageDescription"
        component={FormTextField}
        id="damage-description"
        multiline
        rows="4"
        fullWidth
        variant="filled"
        label="Describe Damage"
        characterLimit={MAX_DAMAGE_DESCRIPTION_CHARACTERS}
      />
    </>
  );
});
