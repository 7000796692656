import React, { useContext } from "react";

import { ImagesForm } from "./ImagesForm";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { StepperFunctions } from "../ManualContainer";

export const Photos = () => {
  const { nextStep, previousStep } = useContext(StepperFunctions);

  const leftComponent = (
    <ImagesForm onSubmit={nextStep} previousStep={previousStep} />
  );

  return <ColumnFlowLayout leftComponent={leftComponent} />;
};
