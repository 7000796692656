import React, { useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  "@global": {
    ".smcx-modal": {
      top: "calc(95% - 150px) !important",
      left: "calc(95% - 250px) !important"
    }
  }
}));

const Survey = ({ src }) => {
  useStyles();
  useEffect(() => {
    const survey = document.getElementById("smcx-sdk");
    if (!survey) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = !0;
      script.id = "smcx-sdk";
      script.src = src;
      document.body.appendChild(script);
    }
    return () => {
      const surveyScript = document.getElementById("smcx-sdk");
      surveyScript && surveyScript.parentElement.removeChild(surveyScript);
      const surveyDiv = document.querySelector(".smcx-widget");
      surveyDiv && surveyDiv.parentElement.removeChild(surveyDiv);
    };
  }, [src]);
  return <></>;
};

Survey.propTypes = {
  src: PropTypes.string
};

export default Survey;
