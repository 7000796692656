export const phoneStripRegex = /\(|\)|-/g;
export const emailRegex = /^[a-zA-Z0-9.+_~-]+@[a-zA-Z0-9-]{2,30}\.[a-zA-Z]+$/;

export const passwordCapitalRegex = /[A-Z]/;
export const passwordNumberRegex = /\d/;
export const passwordSpecialCharRegex = /[!@#$%^&*)(+=._-]/;

export const linkRegex = /^https?:\/\/[^\s]*$/g;
export const splitTextByLinkRegex = /(https?:\/\/[^\s]*)/gm;
export const alphanumeric = /^[a-z0-9]+$/i;

export const alphaWithDash = /^[a-z-]+$/i;
