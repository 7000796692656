import React, { useContext } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import Link from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import classNames from "classnames";
import PropTypes from "prop-types";
import get from "lodash/get";

import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { RouteEnum } from "Enums/RouteEnum";
import { openSupportPage } from "Utils/openSupportPage";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      fontSize: "1.45rem",
      marginTop: theme.spacing(3)
    }
  },
  subtitle: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem"
    }
  },
  cardContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1em"
  },
  claimText: {
    width: "60%"
  },
  bottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    height: "20em",
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    },
    "& > *": {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(1)
      }
    },
    "&:hover": {
      background: "#e1f5fe",
      border: "1px solid #03a9f4"
    }
  },
  icon: {
    width: "77px",
    height: "auto"
  },
  border: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  RouterLink: {
    textDecoration: "none"
  },
  bottomPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    background: "transparent",
    padding: theme.spacing(3, 5),
    marginTop: theme.spacing(2),
    width: "auto"
  },
  link: {
    letterSpacing: "1.25px"
  }
}));

const ClaimTypeCard = ({ icon, claimType, description, to }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <RouterLink
      to={{ pathname: to, state: location.state }}
      className={classes.RouterLink}
    >
      <Paper
        variant="outlined"
        className={classNames(classes.paper, classes.border)}
        elevation={0}
      >
        <img src={icon} alt={claimType} className={classes.icon} />
        <Typography
          component="h6"
          variant="h6"
          align="center"
          className={classes.claimText}
        >
          {claimType}
        </Typography>
        <Typography
          component="div"
          variant="body1"
          align="center"
          className={classes.claimText}
        >
          {description}
        </Typography>
      </Paper>
    </RouterLink>
  );
};

export const ClaimType = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { getLink, getAsset } = useContext(FirebaseContext);

  return (
    <DashboardLayout hasAppBar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Hidden mdDown>
            <Button
              color="primary"
              className={classes.button}
              startIcon={<ChevronLeftIcon />}
              onClick={() => {
                history.replace(RouteEnum.rentalsPast, {
                  ...location.state,
                  claimId: ""
                });
                const rentalId = get(history, "location.state.id");
                const url = rentalId
                  ? `${RouteEnum.rentalsPast}/${rentalId}`
                  : `${RouteEnum.rentalsPast}`;
                history.push(url);
              }}
            >
              Back to Rentals
            </Button>
          </Hidden>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            className={classes.title}
          >
            What type of claim are you filing?
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            align="center"
            className={classes.subtitle}
          >
            If you need to report damage AND request a reimbursement,{" "}
            <Hidden mdDown>
              <br />
            </Hidden>
            please complete one at a time.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.cardContainer}>
        <Grid item xs={12} sm={6}>
          <ClaimTypeCard
            claimType="Report Damage"
            icon={getAsset("claimsDamageIcon")}
            description="Collisions, Fire, Mechanical Issues, Theft, Vandalism, or Weather Damage"
            to={RouteEnum.claimsDamage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ClaimTypeCard
            claimType="Request a Reimbursement"
            icon={getAsset("claimsReimbursmentIcon")}
            description="Cleaning, Gas, Impound Fees, Lost or Stolen Keys, Mileage"
            to={RouteEnum.claimsReimbursment}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <ClaimTypeCard
            claimType="Tickets or Tolls"
            icon={Assets.claimsTicketIcon}
            description="Parking tickets, speeding tickets, and toll violations, etc."
            to={RouteEnum.claimsTickets}
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={2} className={classes.bottomContainer}>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            className={classNames(classes.bottomPaper, classes.border)}
            elevation={0}
          >
            <Typography component="div" variant="subtitle1" gutterBottom>
              Can&apos;t find what you&apos;re looking for?
            </Typography>
            <Link
              onClick={() => openSupportPage()}
              color="primary"
              className={classes.link}
            >
              faqs + policies
            </Link>
            <Link
              onClick={() => openSupportPage(getLink("zendeskProtectionPlan"))}
              color="primary"
              className={classes.link}
            >
              What&apos;s covered
            </Link>
            <Link
              onClick={() => openSupportPage(getLink("zendeskVehicleRecovery"))}
              color="primary"
              className={classes.link}
            >
              vehicle recovery
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

ClaimTypeCard.propTypes = {
  icon: PropTypes.node,
  claimType: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string
};
