import React, { Fragment, useCallback, useRef } from "react";
import { useSnackbar } from "notistack";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import PinDropIcon from "@mui/icons-material/PinDrop";
import makeStyles from "@mui/styles/makeStyles";
import PlacesAutocomplete, {
  geocodeByPlaceId
} from "react-places-autocomplete";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import result from "lodash/result";
import PropTypes from "prop-types";

import { GoogleMapsEnum } from "Enums/GoogleMapsEnum";
import { LocationMap } from "Components/Forms/Location/LocationMap";

export const validateLocation = (values, key, expose, required = true) => {
  const errors = {};

  const errorsKey = expose ? expose : key;

  if (!values[key] && required) {
    errors[key] = "Required";
  } else {
    if (!values[key].selected) {
      errors[errorsKey] = "Please select an address from the dropdown menu";
    } else {
      if (!values[key].city) {
        errors[errorsKey] = "Please select an address with a valid city";
      } else if (!values[key].state) {
        errors[errorsKey] = "Please select an address with a valid state";
      } else if (!values[key].zip) {
        errors[errorsKey] = "Please select an address with a valid postal code";
      } else if (!values[key].lat || !values[key].lng || !values[key].address) {
        errors[errorsKey] =
          "Sorry, there was a formatting issue with this address. Please try another one.";
      }
    }
  }

  return errors;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    position: "relative",
    marginBottom: theme.spacing(4),
    width: "100%"
  },
  autoCompleteRoot: {
    flexGrow: "1"
  },
  autoCompleteContainer: {
    position: "absolute",
    top: "100%",
    zIndex: "10000",
    width: "100%",
    boxShadow: theme.shadows[8],
    borderRadius: `4px`,
    background: theme.palette.background.paper
  },
  autoCompleteItem: {
    padding: 0
  },
  skeleton: {
    height: theme.spacing(7),
    width: "100%"
  }
}));

const AutoCompleteItem = ({ suggestion }) => {
  return <MenuItem>{suggestion}</MenuItem>;
};

AutoCompleteItem.propTypes = {
  suggestion: PropTypes.string
};

const InputComponent = ({
  name,
  onBlur,
  onChange,
  onFocus,
  value,
  inputRef,
  className,
  id,
  onSelect
}) => {
  const classes = useStyles();
  return (
    <PlacesAutocomplete
      inputProps={{
        name,
        onBlur,
        onChange,
        onFocus,
        value,
        ref: inputRef,
        id
      }}
      onSelect={onSelect}
      googleLogo={false}
      classNames={{
        input: className,
        root: classes.autoCompleteRoot,
        autocompleteContainer: classes.autoCompleteContainer,
        autocompleteItem: classes.autoCompleteItem
      }}
      autocompleteItem={AutoCompleteItem}
      options={{
        componentRestrictions: {
          country: "us"
        },
        types: ["address"]
      }}
    />
  );
};

export const LocationAutoComplete = ({
  input,
  meta,
  inputLabel,
  showMap,
  showSkeleton,
  disabled,
  id
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { onChange, onBlur, value, name } = input;
  const { touched, error } = meta;
  const { enqueueSnackbar } = useSnackbar();

  const onSelect = useCallback(
    (address, placeId) => {
      geocodeByPlaceId(placeId)
        .then(res => {
          const addressComponents = keyBy(
            get(res, "[0].address_components", []),
            addressComponent => {
              return get(addressComponent, "types[0]", null);
            }
          );

          onChange({
            lat: result(res, "[0].geometry.location.lat"),
            lng: result(res, "[0].geometry.location.lng"),
            country: get(
              addressComponents,
              `[${GoogleMapsEnum.country}].short_name`
            ),
            state: get(
              addressComponents,
              `[${GoogleMapsEnum.state}].short_name`
            ),
            city:
              get(addressComponents, `[${GoogleMapsEnum.city}].long_name`) ||
              get(addressComponents, `[${GoogleMapsEnum.town}].long_name`) ||
              get(
                addressComponents,
                `[${GoogleMapsEnum.neighborhood}].long_name`
              ),
            zip: get(addressComponents, `[${GoogleMapsEnum.zip}].long_name`),
            street: `${get(
              addressComponents,
              `[${GoogleMapsEnum.streetNumber}].long_name`,
              ""
            )} ${get(
              addressComponents,
              `[${GoogleMapsEnum.streetName}].long_name`,
              ""
            )}`.trim(),
            streetNumber: get(
              addressComponents,
              `[${GoogleMapsEnum.streetNumber}].long_name`
            ),
            streetName: get(
              addressComponents,
              `[${GoogleMapsEnum.streetName}].long_name`
            ),
            address,
            selected: true
          });
        })
        .catch(error => {
          console.error(error);
          enqueueSnackbar(
            "Sorry, something went wrong while trying to select your location.",
            {
              variant: "error"
            }
          );
        });

      onChange({
        address,
        selected: true
      });
    },
    [enqueueSnackbar, onChange]
  );

  return showSkeleton ? (
    <Skeleton
      variant="rectangular"
      classes={{ rectangular: classes.skeleton }}
    />
  ) : (
    <Fragment>
      <FormControl
        variant="filled"
        error={touched && error}
        className={classes.root}
      >
        <InputLabel shrink htmlFor="location-autocomplete">
          {inputLabel ? inputLabel : "Enter Pickup Address"}
        </InputLabel>
        <FilledInput
          disabled={disabled}
          id={id || "pickup-address-selection-field"}
          onChange={address => {
            onChange({
              address: address,
              selected: false
            });
          }}
          onBlur={() => {
            onBlur();
          }}
          value={value && value.address ? value.address : ""}
          name={name}
          inputComponent={InputComponent}
          fullWidth
          type="search"
          inputRef={inputRef}
          inputProps={{
            onSelect: onSelect
          }}
          endAdornment={
            <InputAdornment>
              <PinDropIcon />
            </InputAdornment>
          }
        />
        {error && touched && (
          <FormHelperText error={error && touched}>{error}</FormHelperText>
        )}
      </FormControl>
      {showMap && (
        <LocationMap
          geo_lat={get(value, "lat", 34.052359)}
          geo_lng={get(value, "lng", -118.252743)}
        />
      )}
    </Fragment>
  );
};

LocationAutoComplete.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  showAddress: PropTypes.bool,
  inputLabel: PropTypes.string,
  showMap: PropTypes.bool,
  showSkeleton: PropTypes.bool
};
