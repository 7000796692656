import { gql } from "@apollo/client";

export const GeneralFragments = {
  cursorInfo: gql`
    fragment CursorInfo on PageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  `
};
