import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  key: {
    fontWeight: "bold"
  },
  value: {
    display: "flex",
    alignItems: "center",
    gap: "2px"
  }
}));

export const BasicTable = ({ data, spacing, isCarOverpriced = false }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={spacing || 0}>
      {Object.keys(data).reduce((acc, val, index) => {
        if (data[val]) {
          acc.push(
            <Fragment key={index}>
              <Grid item xs={6} className={classes.key}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  display="block"
                  className={classes.key}
                  gutterBottom
                >
                  {val}
                </Typography>
              </Grid>
              <Grid container item xs={6} justifyContent="flex-end">
                <Typography
                  component="span"
                  variant="body2"
                  justifyItems="center"
                  gutterBottom
                  align="right"
                  className={classes.value}
                >
                  {val === "Daily Price" && isCarOverpriced && (
                    <WarningAmberIcon color="warning" fontSize="45px" />
                  )}
                  {data[val]}
                </Typography>
              </Grid>
            </Fragment>
          );
        }

        return acc;
      }, [])}
    </Grid>
  );
};

BasicTable.propTypes = {
  data: PropTypes.object,
  spacing: PropTypes.number
};
