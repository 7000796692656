export const removeTrailingSpaces = text => (text || "").trim();
export const removeExtraMiddleSpaces = text =>
  (text || "").replace(/  +/g, " ");

/**
 * @param   {String} str like "$1000.33"
 * @returns {String}     like "$1,000.33"
 */
export const appendCommasToUSDollarsString = usDollarsString => {
  const usDollarsStringWithoutSymbol = usDollarsString.replace(`$`, ``);
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
    style: "currency",
  }).format(usDollarsStringWithoutSymbol);
};
