import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(4)
  },
  header: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.black
  }
}));

export const Tooltip = ({ header, paragraphs, useSpan }) => {
  const classes = useStyles();

  return (
    <div className={classes.marginBottom}>
      {header && (
        <Typography
          component="h3"
          variant="subtitle2"
          className={classes.header}
        >
          {header}
        </Typography>
      )}
      {paragraphs.map((paragraph, index) => {
        return (
          <Fragment key={index}>
            {typeof paragraph === "function" ? (
              <Typography
                component={paragraph}
                variant="body2"
                color="textSecondary"
                key={index}
                paragraph
              />
            ) : (
              <Typography
                component={useSpan ? "span" : "p"}
                variant="body2"
                color="textSecondary"
                key={index}
                paragraph={!useSpan}
              >
                {paragraph}
              </Typography>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

Tooltip.propTypes = {
  header: PropTypes.string,
  paragraphs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func])
  ),
  useSpan: PropTypes.bool
};
