import React from "react";
import { compose, withState, lifecycle } from "recompose";
import { Label } from "hyrecar-web-components";

import withMessaging from "./hocs/withMessaging";
import withLoading from "Driver/utils/hocs/withLoading";

const UnreadMessagesCount = ({ unreadCount, style }) => (
  <Label bsStyle={unreadCount >= 1 ? "primary" : "default"} style={style}>
    {unreadCount}
  </Label>
);
export default compose(
  withState("unreadCount", "updateUnreadCount", 0),
  withMessaging,
  withLoading(
    ({ messagingClient }) => !messagingClient,
    <UnreadMessagesCount unreadCount={".."} style={{ marginLeft: 5 }} />
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.messagingClient) {
        const updateMessageCount = () => {
          this.props.messagingClient.getTotalUnreadCount(unread => {
            this.props.updateUnreadCount(unread);
          });
        };
        updateMessageCount();
        this.props.messagingClient.createHandlerGlobal(
          updateMessageCount,
          updateMessageCount,
          updateMessageCount,
          updateMessageCount,
          updateMessageCount,
          updateMessageCount,
          updateMessageCount,
          updateMessageCount,
          "TOTAL_UNREAD"
        );
      }
    },
    componentWillUnmount() {
      if (this.props.messagingClient) {
        this.props.messagingClient.removeChannelHandler("TOTAL_UNREAD");
      }
    }
  })
)(UnreadMessagesCount);
