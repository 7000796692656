import { gql } from "@apollo/client";

import { RentalPeriodFragments } from "../RentalPeriod/RentalPeriodFragments";
import { CarFragments } from "../Car/CarFragments";
import { PaymentFragments } from "../Payments/PaymentFragments";
import { FileFragments } from "../File/FileFragments";

export const RentalFragments = {
  rentalInfo: gql`
    fragment RentalInfo on Rental {
      id
      createdAt
      updatedAt
      disallowExtensions
      status
      cancellationReason
      requestedPickupAt
      droppedOffAt
      ownerApprovedAt
      statusIndicator {
        text
        textCondensed
        color
      }
    }
  `,
  rentalRequest: gql`
    fragment RentalRequest on Rental {
      id
      createdAt
      updatedAt
      status
      disallowExtensions
      requestedPickupAt
      prospectiveRentalContractUrl
      droppedOffAt
      insurance {
        insuranceCardImage {
          ...File
        }
      }
      driver {
        id
        createdAt
        licensePhoto {
          ...File
        }
        user {
          id
          firstName
          lastName
          phone
          email
          paymentAccounts {
            id
            type
            stripePaymentInformation {
              id
              customerId
            }
          }
          profilePhoto {
            ...File
          }
          driver {
            id
          }
        }
      }
      car {
        ...CarRentalRequest
        location {
          ...CarLocation
        }
        owner {
          id
          user {
            email
            id
          }
        }
        photos(where: { isPrimary: true }) {
          ...CarPhoto
        }
      }
      rentalPeriods(first: 1, orderBy: startDate_ASC) {
        ...RentalPeriod
        rentalContract {
          ...File
        }
      }
      currentRentalPeriod {
        ...RentalPeriod
        payment {
          ...PaymentOwnerEarningsInCents
        }
      }
    }
    ${RentalPeriodFragments.rentalPeriod}
    ${CarFragments.carRentalRequest}
    ${CarFragments.carPhoto}
    ${CarFragments.carLocation}
    ${PaymentFragments.paymentOwnerEarningsInCents}
    ${FileFragments.file}
  `,
  rentalOverview: gql`
    fragment RentalOverview on RentalOverview {
      id
      rentalDays
      totalCostInCents
      totalPaidInCents
      ownerEarningsInCents
      lateFeeCaptured
    }
  `,
  rentalStatus: gql`
    fragment RentalStatus on Rental {
      status
    }
  `
};
