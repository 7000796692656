export const fleetBaseRoutes = {
  manual: "/fleet/manual",
  icc: "/fleet/icc",
  csv: "/fleet/csv"
};

export const manualPathStateEnum = {
  "/fleet/manual": 0,
  "/fleet/manual/Location": 1,
  "/fleet/manual/Info": 2,
  "/fleet/manual/Protection": 3,
  "/fleet/manual/Terms": 4,
  "/fleet/manual/Documents": 5,
  "/fleet/manual/CarImages": 6,
  "/fleet/manual/Review": 7,
  "/fleet/manual/Success": 8
};

export const iccPathStateEnum = {
  "/fleet/icc": 0,
  "/fleet/icc/FindDealership": 1,
  "/fleet/icc/ConnectIFP": 1,
  "/fleet/icc/FindIFP": 1,
  "/fleet/icc/SearchingICC": 2,
  "/fleet/icc/Permission": 2
};

export const iccListPathStateEnum = {
  "/fleet/ICCList": 0,
  "/fleet/ICCList/ConfirmAddress": 1,
  "/fleet/ICCList/Protection": 2,
  "/fleet/ICCList/Terms": 3,
  "/fleet/ICCList/Insurance": 4,
  "/fleet/ICCList/InsuranceUpload": 5,
  "/fleet/ICCList/Registration": 6,
  "/fleet/ICCList/RegistrationUpload": 7,
  "/fleet/ICCList/Inspection": 8,
  "/fleet/ICCList/InspectionUpload": 9,
  "/fleet/ICCList/Review": 10,
  "/fleet/ICCList/Success": 11
};

export const achPathStateEnum = {
  "/fleet/ACH": 0,
  "/fleet/ACH/ACHSuccess": 1
};

export const manualFlowStateEnum = {
  IntroState: 0,
  LocationState: 1,
  InfoState: 2,
  ProtectionState: 3,
  TermsState: 4,
  DocumentsState: 5,
  CarImagesState: 6,
  ReviewState: 7,
  SuccessState: 8
};

export const achFlowStateEnum = {
  ACHState: 0,
  ACHSuccessState: 1
};

export const iccListStateEnum = {
  IntroState: 0,
  ConfirmAddressState: 1,
  ProtectionState: 2,
  TermsState: 3,
  InsuranceState: 4,
  InsuranceUploadState: 5,
  RegistrationState: 6,
  RegistrationUploadState: 7,
  InspectionState: 8,
  InspectionUploadState: 9,
  ReviewState: 10,
  SucessState: 11
};

export const confirmPickupStateEnum = {
  confirmDriver: 0,
  carCondition: 1,
  carMileage: 2
};

export const listingDrawerStepEnum = {
  summary: 0,
  protectionPlan: 1,
  edit: 2,
  statusEdit: 3
};
