import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";

const StepAppBar = ({ onClose, title, rightIcon, content }) => {
  return (
    <DrawerAppBar
      title={title}
      border
      isStatic
      rightIcon={rightIcon}
      content={content}
      icon={
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

StepAppBar.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  rightIcon: PropTypes.element,
  content: PropTypes.any
};

export default StepAppBar;
