/* eslint-disable react/display-name */
import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const Adapter = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

export const ButtonRouter = props => {
  return <Button {...props} component={Adapter} />;
};
