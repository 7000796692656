import React from "react";
import Avatar from "@mui/material/Avatar";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import { LetterAvatar } from "./LetterAvatar";
import { getAvatarSize } from "./utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  userAvatar: {
    width: props => props.avatarSize,
    height: props => props.avatarSize,
    cursor: "pointer"
  },
  name: {
    margin: props =>
      props.avatarLast
        ? theme.spacing(0, props.size === "small" ? 1 : 2, 0, 0)
        : theme.spacing(0, 0, 0, props.size === "small" ? 1 : 2),
    color: props => (props.greyed ? "#606770" : theme.palette.text.primary)
  }
}));

export const AvatarWithName = ({
  onClick,
  id,
  url,
  firstName,
  lastName,
  lastNameFirst,
  avatarLast,
  greyed,
  size
}) => {
  const classes = useStyles({
    greyed,
    size,
    avatarLast,
    avatarSize: getAvatarSize(size)
  });
  return (
    <div className={classes.root}>
      {!avatarLast && (
        <Avatar
          src={url}
          alt="User Profile"
          className={classes.userAvatar}
          onClick={onClick}
          id={id}
        >
          <LetterAvatar
            firstName={firstName}
            lastName={lastName}
            size={size}
            greyed={greyed}
          />
        </Avatar>
      )}
      <Typography
        component="span"
        variant={size === "small" ? "subtitle2" : "subtitle1"}
        className={classes.name}
      >
        {!firstName && !lastName
          ? "No name"
          : firstName && !lastName
          ? firstName
          : lastName && !firstName
          ? lastName
          : lastNameFirst
          ? `${lastName}, ${firstName}`
          : `${firstName} ${lastName}`}
      </Typography>
      {avatarLast && (
        <Avatar
          src={url}
          alt="User Profile"
          className={classes.userAvatar}
          onClick={onClick}
          id={id}
        >
          <LetterAvatar
            firstName={firstName}
            lastName={lastName}
            size={size}
            greyed={greyed}
          />
        </Avatar>
      )}
    </div>
  );
};

AvatarWithName.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  lastNameFirst: PropTypes.bool
};
