import moment from "moment";

const validateDateExpiration = (value, errorMessage) => {
  if (moment(value, "MM/DD/YYYY", true).isValid() && moment(value) < moment()) {
    return errorMessage;
  }
  return null;
};

const validateDateFormat = (value, errorMessage) => {
  if (!moment(value, "MM/DD/YYYY", true).isValid()) {
    return errorMessage;
  }
  return null;
};

export const validate = values => {
  let errors = {};

  if (!values.registrationDisabled) {
    if (!values.registrationFile) {
      errors.registrationFile =
        "Please provide a document or select upload later.";
    }

    if (!values.registrationExpirationAt) {
      errors.registrationExpirationAt = "Required.";
    } else {
      const formatError = validateDateFormat(
        values.registrationExpirationAt,
        "Please enter a valid expiration date for the document in the format MM/DD/YYYY."
      );

      if (formatError) {
        errors.registrationExpirationAt = formatError;
      }

      const expiredError = validateDateExpiration(
        values.registrationExpirationAt,
        "Document cannot be expired."
      );

      if (expiredError) errors.registrationExpirationAt = expiredError;
    }
  }

  if (!values.insuranceDisabled) {
    if (!values.personalInsuranceFile) {
      errors.personalInsuranceFile =
        "Please provide a document or select upload later.";
    }

    if (!values.insuranceExpirationAt) {
      errors.insuranceExpirationAt = "Required.";
    } else {
      const formatError = validateDateFormat(
        values.insuranceExpirationAt,
        "Please enter a valid expiration date for the document in the format MM/DD/YYYY."
      );

      if (formatError) {
        errors.insuranceExpirationAt = formatError;
      }

      const expiredError = validateDateExpiration(
        values.insuranceExpirationAt,
        "Document cannot be expired."
      );

      if (expiredError) errors.insuranceExpirationAt = expiredError;
    }
  }

  if (!values.inspectionDisabled) {
    if (!values.uberLyftInspectionFile) {
      errors.uberLyftInspectionFile =
        "Please provide a document or select upload later.";
    }

    if (!values.inspectionExpirationAt) {
      errors.inspectionExpirationAt = "Required.";
    } else {
      const formatError = validateDateFormat(
        values.inspectionExpirationAt,
        "Please enter a valid expiration date for the document in the format MM/DD/YYYY."
      );

      if (formatError) {
        errors.inspectionExpirationAt = formatError;
      }

      const expiredError = validateDateExpiration(
        values.inspectionExpirationAt,
        "Document cannot be expired."
      );

      if (expiredError) errors.inspectionExpirationAt = expiredError;
    }
  }

  return errors;
};
