import React from "react";
import { LineItemDrawer } from "Components/Drawers/LineItemDrawer";
import moment from "moment";
import PropTypes from "prop-types";

const DetailedInfoDrawer = ({ open, item, onClose }) => {
  return (
    <LineItemDrawer
      open={open}
      onClose={onClose}
      title={moment(item.paymentDate, "MM/DD/YYYY").format("MMM DD, YYYY")}
      titleRight={item.earnings}
      subtitle={item.description}
      tableData={{
        Type: item.type,
        "Base Price": item.basePrice,
        "Transaction Type": item.rentalPeriodType?.tooltip
          ? item.rentalPeriodType?.tooltip
          : null,
        "Protection Fee": item.protectionFee,
        "Protection Plan": item.protectionPlan
      }}
    />
  );
};

DetailedInfoDrawer.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.object,
  onClose: PropTypes.func
};

export default DetailedInfoDrawer;
