import React, { useContext, useState, useEffect } from "react";
import { reduxForm, change } from "redux-form";
import { compose } from "recompose";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from "@mui/styles/makeStyles";
import Papa from "papaparse";

import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { ImageDropzone } from "Components/Forms/Dropzone/ImageDropzone";
import { AcceptEnum } from "Enums/AcceptEnum";
import { StepperContext } from "./CSVController";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: "bold"
  },
  list: {
    listStyleType: "none",
    paddingLeft: "0"
  },
  spacer: {
    marginTop: theme.spacing(5)
  },
  imageDropzoneRoot: {
    height: "100%"
  }
}));

const list = [
  "VIN*",
  "Odometer*",
  "Color*",
  "Plate (optional)",
  "Description (optional)"
];

export const CSVUploadContainer = compose(
  reduxForm({ form: "CSV_IMPORT", destroyOnUnmount: false })
)(({ dispatch, form }) => {
  const classes = useStyles();
  const { getLink } = useContext(FirebaseContext);
  const { nextStep, prevStep } = useContext(StepperContext);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (records.length > 0) {
      dispatch(change(form, "cars", records));
      nextStep();
    }
  }, [records, dispatch, form, nextStep]);

  const handleChange = async files => {
    const file = await files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: results => {
        const result = results.data.map(item => {
          const obj = {};
          Object.keys(item).forEach(key => {
            if (key.toLowerCase() === "odometer") {
              obj[key.toLowerCase()] =
                typeof item[key] === "string" ? "" : item[key];
            } else {
              obj[key.toLowerCase()] = item[key];
            }
          });
          return obj;
        });
        setRecords(result);
      }
    });
  };

  return (
    <ColumnFlowLayout
      leftComponent={
        <FlowContentContainer previousStep={prevStep}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography component="h1" variant="h5" gutterBottom>
                Upload CSV
              </Typography>
              <Typography className={classes.bold} gutterBottom>
                Download our CSV template and fill it out with the required
                columns. Then upload it here.
              </Typography>
              <ul className={classes.list}>
                {list.map((item, index) => (
                  <li key={index}>
                    <Typography variant="body1">{`- ${item}`}</Typography>
                  </li>
                ))}
              </ul>
              <div className={classes.spacer} />
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon color="primary" />}
                component={React.forwardRef((props, ref) => (
                  <a {...props} ref={ref} />
                ))}
                href={getLink("csvTemplateLink")}
                download
              >
                Template
              </Button>
            </Grid>
            <Grid item xs={8}>
              <ImageDropzone
                classes={{ root: classes.imageDropzoneRoot }}
                accept={[
                  AcceptEnum.csv,
                  AcceptEnum.csvWindows,
                  AcceptEnum.excel2007,
                  AcceptEnum.excel2003
                ]}
                input={{
                  name: "csv-upload",
                  value: [],
                  onBlur: () => {},
                  onChange: handleChange
                }}
                meta={{}}
                fullWidth
                hideButton
                content={
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Select file or Drag here
                      </Typography>
                      <Typography variant="h6">
                        (Accepted files: .CSV, .XLS, .XLSX)
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <IconButton size="large">
                        <AddIcon color="primary" fontSize="large" />
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </Grid>
        </FlowContentContainer>
      }
    />
  );
});
