import { combineReducers } from "redux";
import moment from "moment";

import {
  UPDATE_FILTER,
  UPDATE_FILTERS,
  INIT_FILTER,
  GET_FILTERS,
  GET_FILTERS_SUCCESS,
  GET_DATASET,
  GET_DATASET_SUCCESS,
  GET_DATASET_ERROR,
  UPDATE_AGGREGATE_BY,
  UPDATE_RANGE,
  UPDATE_VIN
} from "./Actions";
import { GROUP_ENUM } from "./Enums";

const aggregateBy = name => (state = GROUP_ENUM.day, action) => {
  switch (action.type) {
    case `${UPDATE_AGGREGATE_BY}_${name}`: {
      return action.aggregateBy;
    }
    default:
      return state;
  }
};

const dataset = name => (state = [], action) => {
  switch (action.type) {
    case `${GET_DATASET_SUCCESS}_${name}`: {
      return action.dataset ? action.dataset : [];
    }
    case `${GET_DATASET_ERROR}_${name}`: {
      return [];
    }
    default:
      return state;
  }
};

const loading = name => (state = true, action) => {
  switch (action.type) {
    case `${GET_DATASET}_${name}`: {
      return true;
    }
    case `${GET_DATASET_SUCCESS}_${name}`:
    case `${GET_DATASET_ERROR}_${name}`: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const error = name => (state = null, action) => {
  switch (action.type) {
    case `${GET_DATASET_ERROR}_${name}`: {
      const { error } = action;
      return error;
    }
    default:
      return state;
  }
};

const data = name =>
  combineReducers({
    loading: loading(name),
    error: error(name),
    dataset: dataset(name),
    aggregateBy: aggregateBy(name)
  });

const range = (
  state = { from: moment().subtract(7, "days"), to: moment() },
  action
) => {
  switch (action.type) {
    case UPDATE_RANGE: {
      const { range: { from, to } } = action;
      return {
        from,
        to
      };
    }
    default:
      return state;
  }
};

const vin = (state = "", action) => {
  switch (action.type) {
    case UPDATE_VIN: {
      return action.vin;
    }
    default:
      return state;
  }
};

const filter = name => {
  return (state = [], action) => {
    switch (action.type) {
      case `${UPDATE_FILTERS}_${name}`: {
        const { checked } = action;
        return [
          ...state.map(val => ({
            filter: val.filter,
            checked
          }))
        ];
      }
      case `${UPDATE_FILTER}_${name}`: {
        const { filter } = action;
        return [
          ...state.map(val => ({
            filter: val.filter,
            checked: val.filter === filter ? !val.checked : val.checked
          }))
        ];
      }
      case `${INIT_FILTER}_${name}`: {
        const { filters } = action;
        return [
          ...filters.map(filter => ({
            filter: filter.toLowerCase(),
            checked: true
          }))
        ];
      }
      default:
        return state;
    }
  };
};

const filtersLoading = (state = true, action) => {
  switch (action.type) {
    case GET_FILTERS:
      return true;
    case GET_FILTERS_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  range,
  filtersLoading,
  vin,
  makes: filter("MAKES"),
  models: filter("MODELS"),
  years: filter("YEARS"),
  revenue: data("REVENUE"),
  occupancy: data("OCCUPANCY"),
  status: data("STATUS")
});
