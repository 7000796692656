import React from "react";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  popover: {
    pointerEvents: "auto"
  },
  paper: {
    rentalInfo: {
      pointerEvents: "auto",
      marginTop: 10,
      minWidth: 285
    },
    userDataUnavailable: {
      pointerEvents: "auto",
      marginTop: 10
    }
  }
});

const InfoPopover = ({
  id,
  onClose,
  onOpen,
  anchorEl,
  popoverAnchor,
  type,
  PopoverProps = {},
  children
}) => {
  const classes = useStyles();
  const paperClasses = classes.paper[type];

  return (
    <Popover
      id={id}
      className={classes.popover}
      classes={{
        paper: paperClasses
      }}
      open={!!anchorEl}
      anchorEl={popoverAnchor.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      disableRestoreFocus
      PaperProps={{ onMouseEnter: onOpen, onMouseLeave: onClose }}
      {...PopoverProps}
    >
      {children}
    </Popover>
  );
};

InfoPopover.propTypes = {
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  anchorEl: PropTypes.any,
  popoverAnchor: PropTypes.object,
  PopoverProps: PropTypes.object,
  type: PropTypes.string
};

export default InfoPopover;
