import {
  CLOSE_CREATE_ROOFTOP_DIALOG,
  OPEN_CREATE_ROOFTOP_DIALOG
} from "./Actions";

export const closeCreateRooftopDialog = () => ({
  type: CLOSE_CREATE_ROOFTOP_DIALOG
});

export const openCreateRooftopDialog = token => ({
  type: OPEN_CREATE_ROOFTOP_DIALOG,
  token
});
