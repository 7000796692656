import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Field } from "redux-form";
import Typography from "@mui/material/Typography";
import upperFirst from "lodash/upperFirst";

import { FormTextField } from "Components/Inputs/FormTextField";
import { LocationAutoComplete } from "Components/Forms/Location/LocationAutoComplete";
import { AvatarDropzoneField } from "Components/Forms/Dropzone/AvatarDropzone";

const useStyles = makeStyles(theme => ({
  subtitles: {
    color: theme.palette.common.black
  },
  fields: {
    marginBottom: theme.spacing(4)
  }
}));

export const CreateRooftopFormFields = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.fields}>
        <Field name="image" component={AvatarDropzoneField} />
      </div>
      <Typography component="p" gutterBottom className={classes.subtitles}>
        Name this rooftop*
      </Typography>
      <Field
        name="name"
        label="Rooftop Name"
        fullWidth
        variant="filled"
        component={FormTextField}
        normalize={value => upperFirst(value)}
        className={classes.fields}
      />
      <Typography component="p" gutterBottom className={classes.subtitles}>
        What is the address?*
      </Typography>
      <Field
        name="address"
        inputLabel="Enter address"
        fullWidth
        variant="filled"
        component={LocationAutoComplete}
        className={classes.fields}
      />
    </>
  );
};
