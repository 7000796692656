import React, { Fragment } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  stepper: {
    background: theme.palette.background.default,
    paddingLeft: 0,
    paddingRight: 0
  },
  mobileStepper: {
    position: "fixed",
    left: 0,
    top: theme.mixins.toolbar.minHeight,
    width: "100%"
  }
}));

export const FlowStepper = ({
  steps,
  completed,
  header,
  step,
  nonLinear,
  handleStep
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      {matches ? (
        <>
          <div className={classes.mobileStepper}>
            <LinearProgress
              variant="determinate"
              value={((step / steps.length) * 100).toFixed(2)}
            />
          </div>
          <Box display="flex" justifyContent="flex-start" mt={1}>
            <Typography variant="h6">{`Step: ${steps[step]}`}</Typography>
          </Box>
        </>
      ) : (
        <Fragment>
          <Typography component="h1" variant="h4" className={classes.header}>
            {header}
          </Typography>
          <Stepper
            activeStep={step}
            alternativeLabel
            className={classes.stepper}
            nonLinear={nonLinear}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                {nonLinear && handleStep ? (
                  <StepButton
                    onClick={() => handleStep(index)}
                    completed={completed[index]}
                  >
                    {step}
                  </StepButton>
                ) : (
                  <StepLabel>{step}</StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
        </Fragment>
      )}
    </div>
  );
};

FlowStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  completed: PropTypes.arrayOf(PropTypes.bool),
  header: PropTypes.string,
  step: PropTypes.number.isRequired,
  nonLinear: PropTypes.bool,
  handleStep: PropTypes.func
};
