import React, { useState } from "react";
import PropTypes from "prop-types";

export const SelectServicesFormContext = React.createContext();

const getInitialState = () => {
  const selectServices = localStorage.getItem("select_services");
  return selectServices ? JSON.parse(selectServices) : null;
};

const storeState = value =>
  localStorage.setItem("select_services", JSON.stringify(value));

export const SelectServicesFormProvider = ({ children }) => {
  const [selectServicesFormState, setSelectServicesFormState] = useState(
    getInitialState
  );

  const closeSelectServicesForm = () => {
    storeState(false);
    setSelectServicesFormState(false);
  };

  const openSelectServicesForm = () => {
    storeState(true);
    setSelectServicesFormState(true);
  };

  return (
    <SelectServicesFormContext.Provider
      value={{
        selectServicesFormState,
        closeSelectServicesForm,
        openSelectServicesForm
      }}
    >
      {children}
    </SelectServicesFormContext.Provider>
  );
};

SelectServicesFormProvider.propTypes = {
  children: PropTypes.node
};
