import React, { useContext } from "react";
import { reduxForm } from "redux-form";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./CSVController";
import { ProtectionPriceFormFields } from "Components/Forms/FormFields/CSV/ProtectionPriceFormFields";
import { useCSVFormController } from "Components/Forms/FormControllers/hooks/useCSVFormController";

const CSVProtectionPriceContainerForm = reduxForm({
  form: "CSV_IMPORT",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      nextStepLabel="continue"
      previousStepLabel="back"
      previousStep={prevStep}
    >
      <ProtectionPriceFormFields />
    </FlowContentContainer>
  );
});

export const CSVProtectionPriceContainer = () => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const { validate } = useCSVFormController();

  const leftComponent = (
    <CSVProtectionPriceContainerForm
      onSubmit={nextStep}
      prevStep={prevStep}
      validate={validate}
    />
  );

  return <ColumnFlowLayout leftComponent={leftComponent} useBottomDrawer />;
};
