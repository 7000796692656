import React from "react";
import { Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as GoogleIcon } from "Assets/images/google.svg";

const useStyles = makeStyles(theme => ({
  appleButton: {
    backgroundColor: "black",
    color: "white",
    fontHeight: "600",
    fontSize: "120%",
    fontStyle: "normal",
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 56,
    position: "relative",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "#FC6347"
    },
    "& .MuiButton-sizeSmall": {
      left: 16,
      paddingLeft: 48,
      paddingRight: 32,
      position: "absolute"
    },
    "& .MuiButton-startIcon": {
      left: 16,
      position: "absolute"
    }
  },

  appleIcon: {
    color: "#E5E5E5",
    fontSize: "medium"
  },

  facebookButton: {
    backgroundColor: "#3A559F",
    color: "white",
    fontSize: "120%",
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 56,
    position: "relative",
    "&:hover": {
      backgroundColor: "#324886"
    },
    "& .MuiButton-sizeSmall": {
      paddingLeft: 48,
      paddingRight: 32,
      position: "absolute",
      left: 16
    },
    "& .MuiButton-startIcon": {
      position: "absolute",
      left: 16
    },
    textTransform: "none"
  },
  facebookIcon: {
    color: "white",
    fontSize: "medium"
  },

  googleButton: {
    backgroundColor: "#FFFFFF",
    color: "black",
    fontSize: "120%",
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 56,
    position: "relative",
    "&:hover": {
      backgroundColor: "#D5D5D5"
    },
    "& .MuiButton-sizeSmall": {
      paddingLeft: 48,
      paddingRight: 32,
      position: "absolute",
      left: 16
    },
    "& .MuiButton-startIcon": {
      position: "absolute",
      left: 16
    },
    textTransform: "none"
  },
  googleIcon: {
    color: "white",
    fontSize: "small"
  }
}));

const StyledAppleIcon = () => {
  const classes = useStyles();
  return <AppleIcon className={classes.appleIcon} />;
};

const StyledFacebookIcon = () => {
  const classes = useStyles();
  return <FacebookIcon className={classes.facebookIcon} />;
};

const StyledGoogleIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon>
      <GoogleIcon className={classes.googleIcon} />
    </SvgIcon>
  );
};

const buttonTypeConfig = {
  apple: {
    startIcon: <StyledAppleIcon />,
    disableRipple: true
  },
  facebook: {
    startIcon: <StyledFacebookIcon />
  },
  google: {
    startIcon: <StyledGoogleIcon />
  }
};

const SocialLoginButton = ({
  type,
  buttonText,
  icon,
  onClick,
  disabled,
  buttonProps
}) => {
  const classes = useStyles();
  const socialLoginButtonProps = buttonTypeConfig[type];

  return (
    <Grid container item xs={12}>
      <Button
        className={classes[`${type}Button`]}
        variant="contained"
        startIcon={icon}
        fullWidth
        onClick={onClick}
        disabled={disabled}
        {...socialLoginButtonProps}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </Grid>
  );
};

export default SocialLoginButton;
