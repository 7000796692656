import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  search: {
    flexGrow: 1,
    marginTop: -10
  }
});

const SearchInput = props => {
  const classes = useStyles();
  return (
    <TextField
      variant="standard"
      label={props.label || "Search"}
      value={props.value}
      onChange={e => props.update(e.target.value)}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        )
      }}
      className={classes.search}
    />
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  update: PropTypes.func,
  label: PropTypes.string
};

export default SearchInput;
