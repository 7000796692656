import { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { PROTECTION_MODAL_CRITERIA } from "Queries/User/UserQueries";

const PROTECTION_CHANGE_RELEASE_DATETIME = "2021-09-30T14:20:00-06:00";

export const useShowProtectionDialog = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { role, currentRooftopClient } = useContext(ClientFactoryContext);

  const { data, loading } = useQuery(PROTECTION_MODAL_CRITERIA, {
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (shouldShowDialog(data, role)) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [data, role]);

  return {
    showDialog: showDialog,
    data: data,
    loading: loading
  };
};

const shouldShowDialog = (data, role) =>
  userCreatedBeforeProtectionChanges(data) &&
  hasNotAcceptedProtectionChanges(data) &&
  hasAtLeast1CarFittingCriteria(data) &&
  isCorrectRole(role);

const userCreatedBeforeProtectionChanges = data =>
  data?.viewer?.me?.createdAt &&
  moment(data?.viewer?.me?.createdAt).isBefore(
    PROTECTION_CHANGE_RELEASE_DATETIME
  );

const hasNotAcceptedProtectionChanges = data =>
  !data?.viewer?.me?.userLookup?.protectionChangeAcceptedAt;

const hasAtLeast1CarFittingCriteria = data =>
  data?.viewer?.me?.owner?.cars?.edges?.length > 0;

const isCorrectRole = role => role === "p2p" || role === "owner";
