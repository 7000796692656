import { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import get from "lodash/get";
import jwtDecode from "jwt-decode";

import { UPDATE_OWNER_PROFILE } from "Mutations/User/UserMutations";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { formatPhone } from "Utils/Helpers";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { currentUserClient } from "Components/Utils/ApolloProvider";
import { validateLocation } from "Components/Forms/Location/LocationAutoComplete";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const isP2P = rooftopToken => {
  const memberObject = get(jwtDecode(rooftopToken), "member");
  return memberObject.role === "p2p";
};

export const UserProfileFormController = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading } = useQuery(CURRENT_USER_QUERY, {
    client: currentUserClient,
    fetchPolicy: "cache-and-network"
  });

  const [updateOwnerProfile] = useMutation(UPDATE_OWNER_PROFILE, {
    client: currentUserClient,
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    fetchPolicy: "no-cache",
    awaitRefetchQueries: true
  });

  const { currentRooftopToken } = useContext(ClientFactoryContext);

  const shouldDisplayBio = isP2P(currentRooftopToken);

  const initialValues = {
    firstName: get(data, "viewer.me.firstName", null),
    lastName: get(data, "viewer.me.lastName", null),
    phone: formatPhone(get(data, "viewer.me.phone", null)),
    about: get(data, "viewer.me.owner.aboutMe"),
    address: {
      address: get(data, "viewer.me.location.formattedAddress", null),
      street: get(data, "viewer.me.location.street", null),
      city: get(data, "viewer.me.location.city", null),
      state: get(data, "viewer.me.location.state", null),
      zip: get(data, "viewer.me.location.zip", null),
      lat: get(data, "viewer.me.location.lat", null),
      lng: get(data, "viewer.me.location.lng", null),
      selected: !!get(data, "viewer.me.location.formattedAddress", null)
    }
  };

  const onSubmit = async values => {
    try {
      const resp = await updateOwnerProfile({
        variables: {
          ...values,
          phone: values.phone.replace(/[^0-9]/g, ""),
          street: values.address.street,
          city: values.address.city,
          state: values.address.state,
          address: values.address.address,
          zip: values.address.zip,
          lat: values.address.lat,
          lng: values.address.lng
        }
      });

      const updateProfile = resp?.data?.updateProfile;

      if (updateProfile?.success) {
        enqueueSnackbar("User profile successfully updated!", {
          variant: "success"
        });
      } else {
        enqueueSnackbar(updateProfile?.message, {
          variant: "error"
        });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
    }
  };

  const validate = values => {
    let errors = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    }

    if (!values.phone) {
      errors.phone = "Required";
    } else if (
      values.phone &&
      values.phone.replace(/[^0-9]/g, "").length !== 10
    ) {
      errors.phone = "Pleae enter a valid 10 digit phone number";
    }

    const locationErrors = {
      ...errors,
      ...validateLocation(values, "address", null, false)
    };

    return locationErrors;
  };

  const onSubmitFail = errors => scrollErrorIntoView(errors);

  return children({
    onSubmit,
    validate,
    initialValues,
    onSubmitFail,
    loading,
    shouldDisplayBio
  });
};

UserProfileFormController.propTypes = {
  children: PropTypes.func.isRequired
};
