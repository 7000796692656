import { useContext } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";

import { MUTUAL_RENTALS } from "Queries/Rentals/RentalQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { inboxRentalStatusEnum } from "Enums/InboxRentalStatusEnum";

export const useChannelRentalInfo = driverId => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const { data, loading } = useQuery(MUTUAL_RENTALS, {
    variables: { driverId },
    client: currentRooftopClient,
    fetchPolicy: "network-only",
    skip: !driverId
  });

  const getStatus = (mutualRentals, loading) => {
    if (loading) return { car: false, status: "LOADING" };

    return mutualRentals?.viewer?.me?.owner?.rentals.length > 0
      ? mutualRentals.viewer.me.owner.rentals[0]
      : { car: false, status: "INQUIRY" };
  };

  const {
    car,
    status,
    overview: rentalOverview,
    requestedPickupAt,
    id
  } = getStatus(data, loading);

  const getCarVin = car => car?.vin?.slice(car?.vin?.length - 6);
  const getCarImage = car => car?.photos?.[0]?.file.url;

  const getRentalInfo = (rentalOverview, car, status, requestedPickupAt) => {
    const carInfo = car
      ? `‘${car.year.slice(-2)} ${car.make} ${car.model}`
      : "";
    const rentalPeriod = getRentalPeriod(
      rentalOverview,
      status,
      requestedPickupAt
    );
    const period = rentalPeriod
      ? `${moment(rentalPeriod.start).format("MMM D")} - ${moment(
          rentalPeriod.end
        ).format("MMM D")}`
      : "";
    const carImage = getCarImage(car);
    const carVin = getCarVin(car);
    return { id, status, period, carInfo, carImage, carVin };
  };

  const getRentalPeriod = (rentalOverview, status, requestedPickupAt) => {
    switch (inboxRentalStatusEnum[status].periodVariation) {
      case 1:
        return {
          start: requestedPickupAt,
          end: rentalOverview.endDate
        };
      case 2:
        return {
          start: rentalOverview.startDate,
          end: rentalOverview.endDate
        };
      default:
        return false;
    }
  };
  return getRentalInfo(rentalOverview, car, status, requestedPickupAt);
};

export const getChannelRentalInfoFromArray = (driverId, data) => {
  if (!data?.viewer?.me?.owner?.rentals?.length) return;
  const getStatus = mutualRentals => {
    if (!mutualRentals?.viewer?.me?.owner?.rentals?.length) return;

    const filteredRentalsByDriverId = mutualRentals.viewer.me.owner.rentals.filter(
      rental => {
        return rental.driverId === driverId;
      }
    );

    const mostRecentIndex = filteredRentalsByDriverId.length - 1;
    return filteredRentalsByDriverId.length > 0
      ? filteredRentalsByDriverId[mostRecentIndex]
      : { car: false, status: "INQUIRY" };
  };

  const {
    car,
    status,
    overview: rentalOverview,
    requestedPickupAt
  } = getStatus(data);

  const getRentalInfo = (rentalOverview, car, status, requestedPickupAt) => {
    const carInfo = car
      ? `‘${car.year.slice(-2)} ${car.make} ${car.model}`
      : "";
    const rentalPeriod = getRentalPeriod(
      rentalOverview,
      status,
      requestedPickupAt
    );
    const period = rentalPeriod
      ? `${moment(rentalPeriod.start).format("MMM D")} - ${moment(
          rentalPeriod.end
        ).format("MMM D")}`
      : "";
    return { status, period, carInfo, driverId };
  };

  const getRentalPeriod = (rentalOverview, status, requestedPickupAt) => {
    switch (inboxRentalStatusEnum[status].periodVariation) {
      case 1:
        return {
          start: requestedPickupAt,
          end: rentalOverview.endDate
        };
      case 2:
        return {
          start: rentalOverview.startDate,
          end: rentalOverview.endDate
        };
      default:
        return false;
    }
  };
  return getRentalInfo(rentalOverview, car, status, requestedPickupAt);
};
