import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Field, formValues } from "redux-form";

import { FormSelect } from "Components/Inputs/FormSelect";
import { FormTextField } from "Components/Inputs/FormTextField";
import { LocationAutoComplete } from "Components/Forms/Location/LocationAutoComplete";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(4)
  }
}));

export const AdditionalStorageInformation = ({ classes }) => (
  <>
    <Typography
      className={classes.spacing}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Additional Storage Information
    </Typography>
    <Field
      characterLimit={250}
      component={FormTextField}
      fullWidth
      id="claim-additional-storage-information"
      label="Provide more information about storage below:"
      multiline
      name="storageAdditionalInformation"
      rows="4"
      variant="filled"
    />
  </>
);

const IsVehicleInStorageMenu = ({ classes }) => {
  const options = [
    {
      label: "Yes",
      value: "Yes"
    },
    {
      label: "No",
      value: "No"
    }
  ];

  return (
    <>
      <Typography
        className={classes.spacing}
        component="p"
        gutterBottom
        variant="subtitle1"
      >
        Storage Assessment
      </Typography>
      <Field
        autoOk
        component={FormSelect}
        fullWidth
        id="claim-storage-assessment"
        label="Is this vehicle currently in a storage facility?"
        name="isVehicleCurrentInStorage"
        options={options}
        variant="filled"
      />
    </>
  );
};

export const StorageFacilityAddress = ({ classes }) => (
  <>
    <Typography
      className={classes.spacing}
      component="p"
      gutterBottom
      variant="subtitle1"
    >
      Storage Address
    </Typography>
    <Field
      component={LocationAutoComplete}
      fullWidth
      id="claim-storage-address"
      inputLabel="What is the address of the storage facility?"
      label="What is the address of the storage facility?"
      name="storageFacilityAddress"
      variant="filled"
    />
  </>
);

export const StorageAssessmentFormFields = formValues({
  isVehicleCurrentInStorage: "isVehicleCurrentInStorage"
})(({ isVehicleCurrentInStorage }) => {
  const classes = useStyles();

  return (
    <>
      <IsVehicleInStorageMenu classes={classes} />
      {isVehicleCurrentInStorage === "Yes" && (
        <>
          <StorageFacilityAddress classes={classes} />
          <AdditionalStorageInformation classes={classes} />
        </>
      )}
    </>
  );
});
