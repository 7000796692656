import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import jwtDecode from "jwt-decode";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import CsvDownload from "Components/Utils/CsvDownload";

const headers = [
  "VIN",
  "Car",
  "Plate Number",
  "Registration",
  "Insurance",
  "Inspection"
];

const carToCsvRow = car => [
  car.vin,
  `${car.year} ${car.make} ${car.model}`,
  car.licensePlate,
  car.registrationExpirationAt &&
    moment(car.registrationExpirationAt).format("MM/DD/YYYY"),
  car.insuranceExpirationAt &&
    moment(car.insuranceExpirationAt).format("MM/DD/YYYY"),
  car.inspectionExpirationAt &&
    moment(car.inspectionExpirationAt).format("MM/DD/YYYY")
];

const getFileName = rooftopName =>
  `Expired-Expiring Vehicle Documents ${rooftopName}.csv`;

const ExpiredCsvDownload = ({ cars }) => {
  const { currentRooftopToken: token } = useContext(ClientFactoryContext);
  const data = useMemo(() => cars.map(carToCsvRow), [cars]);
  return (
    <CsvDownload
      headers={headers}
      data={data}
      fileName={getFileName(jwtDecode(token).name)}
    />
  );
};

const carShape = PropTypes.shape({
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  vin: PropTypes.string.isRequired,
  inspectionExpirationAt: PropTypes.string,
  insuranceExpirationAt: PropTypes.string,
  registrationExpirationAt: PropTypes.string
});

ExpiredCsvDownload.propTypes = {
  cars: PropTypes.arrayOf(carShape)
};

export default ExpiredCsvDownload;
