import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import get from "lodash/get";

import { OWNER_DEFAULT_PROTECTION_PLAN } from "Queries/Owner/OwnerQueries";
import {
  RENTAL_REQUEST_QUERY,
  RENTAL_OVERVIEW_QUERY
} from "Queries/Rentals/RentalQueries";
import { OptionCard } from "Components/Cards/OptionCard";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";
import { calculateEarningsSummary } from "Utils/Calculations";
import { BasicTable } from "Components/Tables/BasicTable";
import { centsToDollars } from "Utils/Calculations";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(3, 0)
  }
}));

export const RentalEarningsBreakdown = ({ onClose }) => {
  const classes = useStyles();
  const { id } = useParams();
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(RENTAL_OVERVIEW_QUERY, {
    variables: {
      id
    },
    client: currentRooftopClient,
    context: {
      important: true
    }
  });
  const {
    data: { rental }
  } = useQuery(RENTAL_REQUEST_QUERY, {
    variables: {
      id
    },
    client: currentRooftopClient,
    fetchPolicy: "cache-only"
  });
  const response = useQuery(OWNER_DEFAULT_PROTECTION_PLAN, {
    client: currentRooftopClient,
    fetchPolicy: "cache-only"
  });

  const viewer = response?.data?.viewer;

  const dailyPriceInCents = get(rental, "car.dailyPriceInCents", 0);
  const rentalDays = get(data, "rental.overview.rentalDays");
  const carDefaultProtectionPlan = get(rental, "car.defaultProtectionPlan");
  const ownerDefaultProtectionPlan = get(
    viewer,
    "me.owner.defaultProtectionPlan"
  );
  const {
    grossEarningsInCents,
    netEarningsInCents,
    totalProtectionPlanFeeInCents
  } = calculateEarningsSummary(
    rentalDays,
    dailyPriceInCents,
    carDefaultProtectionPlan
      ? carDefaultProtectionPlan
      : ownerDefaultProtectionPlan
  );

  return loading ? (
    <SkeletonDrawer />
  ) : (
    <>
      <DrawerAppBar
        icon={
          <IconButton onClick={onClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        }
        border
        title="Earnings Breakdown"
      />
      <DrawerPaddingContainer>
        <OptionCard title="Earnings Summary" marginBottom>
          <BasicTable
            data={{
              "Base Price": `$${centsToDollars(grossEarningsInCents)}`,
              "Protection Fee (Premium)": `- $${centsToDollars(
                totalProtectionPlanFeeInCents
              )}`
            }}
          />
          <Divider className={classes.divider} />
          <Typography component="h6" variant="h6" color="primary" align="right">
            {`$${centsToDollars(netEarningsInCents)}`}
          </Typography>
        </OptionCard>
      </DrawerPaddingContainer>
      <DrawerBottomBar onSubmit={onClose} submitText="Back" />
    </>
  );
};

RentalEarningsBreakdown.propTypes = {
  onClose: PropTypes.func.isRequired
};
