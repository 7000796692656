import React from "react";
import { Field } from "redux-form";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { FormTextField } from "Components/Inputs/FormTextField";
import { PhoneMask } from "Components/Forms/InputMasks/PhoneMask";
import { LocationAutoComplete } from "Components/Forms/Location/LocationAutoComplete";
import { FormSelect } from "Components/Inputs/FormSelect";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(4)
  }
}));

const timeWindowOptions = [
  {
    label: "Morning (7AM - 11AM)",
    value: "Morning (7AM - 11AM)"
  },
  {
    label: "Afternoon (11AM - 3PM)",
    value: "Afternoon (11AM - 3PM)"
  },
  {
    label: "Late Afternoon (3PM - 7PM)",
    value: "Late Afternoon (3PM - 7PM)"
  }
];

export const PointOfContactFormFields = ({ showAddressField = true }) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="p" variant="subtitle1" gutterBottom>
        Who is the best point of contact for this claim?
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Field
            name="firstName"
            id="first-name"
            component={FormTextField}
            variant="filled"
            placeholder="First Name"
            label="First Name"
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Field
            name="lastName"
            component={FormTextField}
            id="last-name"
            variant="filled"
            placeholder="Last Name"
            label="Last Name"
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography
        component="p"
        variant="subtitle1"
        gutterBottom
        className={classes.spacing}
      >
        What is the best phone number to reach the Point of Contact?
      </Typography>
      <Field
        name="phone"
        component={FormTextField}
        id="phone-number"
        variant="filled"
        placeholder="Phone Number"
        label="Phone Number"
        fullWidth
        inputComponent={PhoneMask}
      />
      <Typography
        component="p"
        variant="subtitle1"
        gutterBottom
        className={classes.spacing}
      >
        What email should we send a confirmation to?
      </Typography>
      <Field
        name="email"
        component={FormTextField}
        id="email-confirmation"
        variant="filled"
        placeholder="Email"
        label="Email"
        fullWidth
      />
      {showAddressField && (
        <>
          <Typography
            component="p"
            variant="subtitle1"
            gutterBottom
            className={classes.spacing}
          >
            What is your mailing address?
          </Typography>
          <Field
            name="address"
            component={LocationAutoComplete}
            id="mailing-address"
            variant="filled"
            inputLabel="Address"
            label="Address"
            fullWidth
          />
        </>
      )}
      <Typography
        component="p"
        variant="subtitle1"
        gutterBottom
        className={classes.spacing}
      >
        What is the best time of day to contact you?
      </Typography>
      <Field
        name="timeWindow"
        component={FormSelect}
        id="time-of-contact"
        variant="filled"
        label="Time Window"
        fullWidth
        options={timeWindowOptions}
      />
    </>
  );
};
