import { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";

export const usePageQueryAnalytics = ({
  successLabel,
  errorLabel,
  data,
  error,
  category = AnalyticsEvents.category.userInteraction,
  action = AnalyticsEvents.action.buttonClicked
}) => {
  const { currentRooftopToken, currentOrganizationInfo } = useContext(
    ClientFactoryContext
  );

  const response = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-first"
  });

  const viewer = response?.data?.viewer;

  const [analyticsFired, setAnalyticsFired] = useState(false);
  const dataLength = data && data.length ? data.length : 0;
  const ownerId = viewer?.me?.owner?.id;
  const rooftopId = jwtDecode(currentRooftopToken)?.id;
  const memberId = currentOrganizationInfo?.organizationMemberId;

  useEffect(() => {
    if (analyticsFired || !ownerId || !rooftopId || !memberId) {
      return;
    } else if (error) {
      analytics.track(errorLabel, {
        category,
        action: AnalyticsEvents.action.errorShown,
        label: errorLabel,
        property: JSON.stringify({
          ownerId: ownerId,
          rooftopId: rooftopId,
          memberId: memberId,
          error: error.message
        }),
        value: "",
        context: ""
      });
    } else if (dataLength) {
      analytics.track(successLabel, {
        category,
        action,
        label: successLabel,
        property: JSON.stringify({
          ownerId: ownerId,
          rooftopId: rooftopId,
          memberId: memberId
        }),
        value: "",
        context: ""
      });
    }

    setAnalyticsFired(true);
  }, [analyticsFired, rooftopId, memberId, dataLength, error, viewer]);

  return analyticsFired;
};
