import React from "react";
import { useHistory } from "react-router";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  }
}));

export const CarInfoFooter = ({
  statusComponent,
  actionButtons,
  onMessageClick,
  shareButton
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6}>
        {statusComponent && statusComponent}
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end">
        {shareButton && shareButton}
        {onMessageClick && (
          <Button color="primary" onClick={onMessageClick}>
            Message
          </Button>
        )}
        {actionButtons.map(({ label, to, hidden }) =>
          hidden ? null : (
            <Button
              color="primary"
              onClick={() => history.push(to)}
              key={label}
            >
              {label}
            </Button>
          )
        )}
      </Grid>
    </Grid>
  );
};

CarInfoFooter.propTypes = {
  statusComponent: PropTypes.node,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      hidden: PropTypes.bool
    })
  ),
  onMessageClick: PropTypes.func,
  shareButton: PropTypes.node
};
