import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { RouteEnum } from "Enums/RouteEnum";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { TabsWithRoutes } from "Components/Tabs/TabsWithRoutes";
import { PayoutDetails } from "Fleet/Dashboard/DashboardViews/Financials/PayoutDetails/PayoutDetails";
import { Payouts } from "Fleet/Dashboard/DashboardViews/Financials/Payouts/Payouts";
import { Transactions } from "Fleet/Dashboard/DashboardViews/Financials/Transactions/Transactions";
import { enableHeader, disableHeader } from "Redux/Header/ActionCreators";
import { MobileHeader } from "Components/Header/MobileHeader";
import { RouterTab } from "Components/Tabs/RouterTab";
import { PaymentDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { RouteWithPermission } from "Components/Routers/RouteWithPermission";
import { checkAllPermissions, renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import Survey from "../Inbox/HCFB/Survey";

const useStyles = makeStyles(theme => ({
  dashboardPayoutDetails: {
    paddingTop: "22px",
    [theme.breakpoints.down("md")]: {
      marginTop: "-22px",
      paddingTop: "0"
    }
  },
  indicator: {
    backgroundColor: theme.palette.common.white
  }
}));

export const Financials = connect(null, {
  enableHeader,
  disableHeader
})(({ enableHeader, disableHeader }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { scopes } = useContext(ClientFactoryContext);
  const [activeTabValue, setActiveTabValue] = useState(
    location.pathname === RouteEnum.financials ||
      location.pathname === RouteEnum.financialsPayouts
      ? RouteEnum.financialsTransactions
      : location.pathname
  );

  useEffect(() => {
    if (isMobile) {
      disableHeader();
    } else {
      enableHeader();
    }

    return enableHeader;
  }, [isMobile, enableHeader, disableHeader]);

  useEffect(() => {
    checkAllPermissions([PaymentDomainPermissionPaths.listTransactions], scopes)
      ? setActiveTabValue(RouteEnum.financialsPayouts)
      : setActiveTabValue(RouteEnum.financialsListings);
  }, [scopes]);

  //For when routing to transactions page through link instead of header tab
  useEffect(() => {
    location.pathname === RouteEnum.financials ||
    location.pathname === RouteEnum.financialsPayouts ||
    location.pathname.includes(`${RouteEnum.financialsPayoutDetails}/`)
      ? setActiveTabValue(RouteEnum.financialsPayouts)
      : setActiveTabValue(RouteEnum.financialsTransactions);
  }, [location.pathname, setActiveTabValue]);

  const hideTabs = location.pathname.includes(
    `${RouteEnum.financialsPayoutDetails}/`
  );

  const renderHeader = () => {
    if (!isMobile && hideTabs) {
      return null;
    }
    if (isMobile) {
      const mobileTabs = (
        <Tabs
          textColor="inherit"
          variant="fullWidth"
          value={activeTabValue}
          classes={{ indicator: classes.indicator }}
          onChange={(event, value) => setActiveTabValue(value)}
        >
          {renderByPermissions(
            <RouterTab
              label="Payouts"
              to={RouteEnum.financialsPayouts}
              value={RouteEnum.financialsPayouts}
            />,
            [PaymentDomainPermissionPaths.listTransactions],
            scopes
          )}
          {renderByPermissions(
            <RouterTab
              label="Transactions"
              to={RouteEnum.financialsTransactions}
              value={RouteEnum.financialsTransactions}
            />,
            [PaymentDomainPermissionPaths.listTransactions],
            scopes
          )}
        </Tabs>
      );

      return (
        <MobileHeader title="Financials" tabs={hideTabs ? null : mobileTabs} />
      );
    }
    return (
      <TabsWithRoutes
        paths={[
          {
            to: RouteEnum.financialsPayouts,
            label: "Payouts",
            disabled: !checkAllPermissions(
              [PaymentDomainPermissionPaths.listTransactions],
              scopes
            )
          },
          {
            to: RouteEnum.financialsTransactions,
            label: "Transactions",
            disabled: !checkAllPermissions(
              [PaymentDomainPermissionPaths.listTransactions],
              scopes
            )
          }
        ]}
      />
    );
  };

  return (
    <>
      <Survey src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd2j8hJVFLn3thpWRYTv11pba204U6qFqnHLEfaiv5XCx.js" />
      {renderHeader()}
      <DashboardLayout
        fixed
        fullScreen={isMobile}
        hasAppBar={isMobile}
        className={hideTabs ? classes.dashboardPayoutDetails : ""}
      >
        <Switch>
          <Route
            exact
            path={RouteEnum.financials}
            render={() => (
              <Redirect
                to={
                  checkAllPermissions(
                    [PaymentDomainPermissionPaths.listTransactions],
                    scopes
                  )
                    ? RouteEnum.financialsPayouts
                    : RouteEnum.financialsListings
                }
              />
            )}
          />
          <RouteWithPermission
            requiredPermissions={[
              PaymentDomainPermissionPaths.listTransactions
            ]}
            path={RouteEnum.financialsTransactions}
            component={Transactions}
            actionRoute={RouteEnum.financials}
          />
          <RouteWithPermission
            requiredPermissions={[
              PaymentDomainPermissionPaths.listTransactions
            ]}
            path={RouteEnum.financialsPayouts}
            component={Payouts}
            actionRoute={RouteEnum.financials}
          />
          <RouteWithPermission
            requiredPermissions={[
              PaymentDomainPermissionPaths.listTransactions
            ]}
            path={`${RouteEnum.financialsPayoutDetails}/:id`}
            component={PayoutDetails}
            actionRoute={RouteEnum.financials}
          />
        </Switch>
      </DashboardLayout>
    </>
  );
});
