import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import PopOver from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Zoom from "@mui/material/Zoom";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hidden from "@mui/material/Hidden";

import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { TransactionsDateFilter } from "Fleet/Dashboard/DashboardViews/Financials/Transactions/TransactionsDateFilter";
import CsvDownloadRemote from "Components/Utils/CsvDownloadRemote";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0)
  },
  container: {
    padding: theme.spacing(0, 2)
  },
  searchContainer: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.only("md")]: {
      flexWrap: "nowrap"
    }
  },
  formControl: {
    width: "335px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.only("md")]: {
      width: "auto",
      marginRight: 0,
      marginLeft: theme.spacing(2),
      flexGrow: 0.5
    }
  },
  formControlMobileGrow: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: 0,
      marginLeft: 0,
      marginTop: "16px"
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  tableNameContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    [theme.breakpoints.only("md")]: {
      flexGrow: 0
    }
  },
  tableName: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.black,
    flexGrow: 1,
    [theme.breakpoints.only("md")]: {
      flexGrow: 0
    }
  },
  tableNameWithSubtext: {
    fontSize: "20px"
  },
  tableSubtext: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.black,
    flexGrow: 1,
    fontSize: "16px",
    [theme.breakpoints.only("md")]: {
      flexGrow: 0
    }
  },
  divider: {
    margin: theme.spacing(0, 2)
  },
  dividerContainer: {
    padding: theme.spacing(2, 0)
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(0, 1)
  },
  columns: {
    padding: theme.spacing(2)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

export const TableHead = ({
  tableName,
  tableSubtext,
  onSearchChange,
  search,
  hideSearch,
  searchPlaceholder,
  mobileSearchGrow,
  loading,
  filters,
  selectedFilters,
  onSelectFilter,
  onDeleteFilter,
  columns,
  handleColumnChecked,
  buttonComponent,
  inputComponents,
  hideButtonDivider,
  dateFilterHandlers,
  customClassName,
  downloadData
}) => {
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const [columnsAnchorEl, setColumnsAnchorEl] = useState(null);
  const classes = useStyles();

  const handleFiltersClick = event => {
    setFiltersAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFiltersAnchorEl(null);
  };

  const renderTitleText = () => {
    if (!tableSubtext) {
      return (
        <Typography
          component="h1"
          variant="h5"
          className={classNames(classes.tableName)}
        >
          {tableName}
        </Typography>
      );
    }
    return (
      <div className={classes.tableNameContainer}>
        <Typography
          component="h1"
          variant="h5"
          className={classNames(
            classes.tableName,
            classes.tableNameWithSubtext
          )}
        >
          {tableName}
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          className={classNames(classes.tableSubtext)}
        >
          {tableSubtext}
        </Typography>
      </div>
    );
  };

  return (
    <Fragment>
      {loading && <LinearProgress />}
      <Grid
        container
        className={classNames(classes.root, customClassName || "")}
      >
        <Grid
          container
          justifyContent="space-between"
          className={classes.searchContainer}
        >
          {renderTitleText()}
          {dateFilterHandlers && (
            <TransactionsDateFilter {...dateFilterHandlers} />
          )}
          {!hideSearch && (
            <FormControl
              className={classNames(classes.formControl, classes.flowGrow, {
                [classes.formControlMobileGrow]: mobileSearchGrow
              })}
              id={joyrideTutorialIds.tableSearch}
            >
              <InputLabel htmlFor="search-input">
                {searchPlaceholder}
              </InputLabel>
              <Input
                type="text"
                value={search}
                onChange={onSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
          {inputComponents &&
            inputComponents.map((inputComponent, key) => {
              return (
                <Fragment key={key}>
                  <Divider orientation="vertical" className={classes.divider} />
                  {inputComponent}
                </Fragment>
              );
            })}
          {columns && handleColumnChecked && (
            <Fragment>
              <Divider orientation="vertical" className={classes.divider} />
              <IconButton
                onClick={e => setColumnsAnchorEl(e.currentTarget)}
                size="large"
              >
                <ViewColumnIcon />
              </IconButton>
              <PopOver
                onClose={() => setColumnsAnchorEl(null)}
                anchorEl={columnsAnchorEl}
                open={Boolean(columnsAnchorEl)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
              >
                <FormGroup className={classes.columns}>
                  {columns.map((column, key) => {
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            key={key}
                            color="primary"
                            value={column.field}
                            checked={column.checked}
                            onChange={() => handleColumnChecked(column)}
                          />
                        }
                        label={column.title}
                      />
                    );
                  })}
                </FormGroup>
              </PopOver>
            </Fragment>
          )}
          {buttonComponent && (
            <>
              {!hideButtonDivider && (
                <Divider orientation="vertical" className={classes.divider} />
              )}
              {buttonComponent}
            </>
          )}
        </Grid>
        {filters && (
          <Fragment>
            <Hidden mdDown>
              <Grid item container className={classes.dividerContainer}>
                <Divider className={classes.flexGrow} />
              </Grid>
            </Hidden>
            <Grid container className={classes.container}>
              <Button onClick={handleFiltersClick} color="primary">
                <FilterListIcon className={classes.filterIcon} />
                Add Filter
              </Button>
              <div
                className={classNames(classes.flexGrow, classes.chipsContainer)}
              >
                {selectedFilters.map((filter, key) => {
                  return (
                    <Zoom key={filter.label} in={true}>
                      <Chip
                        label={
                          filter.chipLabel ? filter.chipLabel : filter.label
                        }
                        onDelete={() => onDeleteFilter(filter, key)}
                        color="primary"
                        className={classNames(classes.chip)}
                        style={{
                          backgroundColor: filter.chipColor
                        }}
                      />
                    </Zoom>
                  );
                })}
              </div>
              {downloadData && (
                <Tooltip
                  title={downloadData.tooltip || "Download your inventory list"}
                >
                  <div className={classes.downloadContainer}>
                    <CsvDownloadRemote downloadData={downloadData} />
                  </div>
                </Tooltip>
              )}
            </Grid>
            <Menu
              anchorEl={filtersAnchorEl}
              keepMounted
              open={Boolean(filtersAnchorEl)}
              onClose={handleClose}
            >
              {filters.map((filter, key) => {
                return (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      onSelectFilter(filter);
                      handleClose();
                    }}
                  >
                    {filter.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Fragment>
        )}
      </Grid>
      <Hidden mdDown>
        <Divider />
      </Hidden>
    </Fragment>
  );
};

TableHead.propTypes = {
  tableName: PropTypes.string,
  onSearchChange: PropTypes.func,
  search: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  loading: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      chipLabel: PropTypes.string,
      chipColor: PropTypes.string
    })
  ),
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      chipLabel: PropTypes.string,
      chipColor: PropTypes.string
    })
  ),
  onSelectFilter: PropTypes.func,
  onDeleteFilter: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired
    })
  ),
  handleColumnChecked: PropTypes.func,
  buttonComponent: PropTypes.element,
  inputComponents: PropTypes.arrayOf(PropTypes.element),
  customClassName: PropTypes.string
};
