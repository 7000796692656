import { useEffect, useState } from "react";

import config from "Config/config";

import {
  DEFAULT_LOCATION,
  GOOGLE_GEOCODE_STATUS,
  NAVIGATOR_GEOLOCATION_PERMISSION_STATE
} from "Variables/DefaultLocation";

import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";

const {
  GOOGLE_GEOCODE_API_URL,
  GOOGLE_GEOLOCATE_API_KEY,
  GOOGLE_GEOLOCATE_API_URL,
  GOOGLE_MAPS_API_KEY
} = config;

const logLocationPermissionChange = accepted =>
  accepted
    ? analytics.track(AnalyticsEvents.event.location.permissionAccepted, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.permissionAccepted,
        label: AnalyticsEvents.label.location,
        property: JSON.stringify({}),
        value: "",
        context: ""
      })
    : analytics.track(AnalyticsEvents.event.location.permissionDenied, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.permissionDenied,
        label: AnalyticsEvents.label.location,
        property: JSON.stringify({}),
        value: "",
        context: ""
      });

const logLocationRequestFailure = ({
  error,
  Function,
  source,
  properties = {}
}) =>
  analytics.track(AnalyticsEvents.event.location.userLocation, {
    category: AnalyticsEvents.category.deviceNetworking,
    action: AnalyticsEvents.action.requestFailed,
    label: AnalyticsEvents.label.userLocation,
    property: JSON.stringify({
      error,
      function: Function,
      source,
      ...properties
    }),
    value: "",
    context: ""
  });

const logLocationRequestSuccess = ({ Function, source }) =>
  analytics.track(AnalyticsEvents.label.auth.locationRetrieved, {
    category: "Application",
    action: "Location Retrieved",
    label: AnalyticsEvents.label.auth.locationRetrieved,
    property: JSON.stringify({
      function: Function,
      source
    }),
    value: "",
    context: ""
  });

const isApproved = permission =>
  permission.state === NAVIGATOR_GEOLOCATION_PERMISSION_STATE.GRANTED;

const setNavigationGeolocationStateListener = () =>
  new Promise(resolve => {
    if (!window?.navigator?.permissions) {
      logLocationRequestFailure({
        error: "Navigator not available",
        source: "useLocation.js",
        Function: "setNavigationGeolocationStateListener"
      });
      resolve(false);
    } else {
      window.navigator.permissions
        .query({ name: "geolocation" })
        .then(function(permission) {
          permission.onchange = function() {
            const approved = isApproved(permission);
            logLocationPermissionChange(approved);
          };
          const approved = isApproved(permission);
          resolve(approved);
        });
    }
  });

const resolveCoordinates = () => {
  return new Promise(resolve => {
    if (!window.navigator?.geolocation?.getCurrentPosition) {
      logLocationRequestFailure({
        error: "Navigator cannot query permissions",
        source: "useLocation.js",
        Function: "resolveCoordinates"
      });
      return resolve(undefined);
    }
    window.navigator.geolocation.getCurrentPosition(
      location =>
        resolve({
          latitude: location?.coords?.latitude,
          longitude: location?.coords?.longitude
        }),
      error => {
        logLocationRequestFailure({
          error: error.message,
          source: "useLocation.js",
          Function: "resolveCoordinates"
        });
        resolve(undefined);
      }
    );
  });
};

const parseCity = addressComponents =>
  addressComponents?.results[0]?.address_components?.find(component =>
    component?.types?.includes("locality")
  )?.long_name;

const parseState = addressComponents =>
  addressComponents?.results[0]?.address_components?.find(component =>
    component?.types?.includes("administrative_area_level_1")
  )?.long_name;

const parseZipCode = addressComponents =>
  addressComponents?.results[0]?.address_components?.find(component =>
    component?.types?.includes("postal_code")
  )?.short_name;

const parseGoogleMapsAddressComponents = ({
  addressComponents,
  geolocation
}) => {
  const city = parseCity(addressComponents);
  const state = parseState(addressComponents);
  const zip = parseZipCode(addressComponents);
  if (city && state && zip) {
    return {
      geo_lat: geolocation.latitude,
      geo_lng: geolocation.longitude,
      city,
      state,
      zip
    };
  }
};

const fetchAddressComponentsByGeocodeAPI = async ({ latitude, longitude }) => {
  const response = await fetch(
    `${GOOGLE_GEOCODE_API_URL}/json?latlng=${latitude},${longitude}&result_type=street_address&sensor=false&key=${GOOGLE_MAPS_API_KEY}`,
    { method: "GET" }
  )
    .then(res => res.json())
    .catch(e =>
      logLocationRequestFailure({
        error: JSON.stringify(e),
        source: "useLocation.js",
        Function: "fetchAddressComponentsByGeocodeAPI"
      })
    );
  return response;
};

const userHasLocation = user =>
  !!user?.location?.city &&
  !!user?.location?.lat &&
  !!user?.location?.lng &&
  !!user?.location?.state &&
  !!user?.location?.zip;

const resolveLocationByNavigator = async () => {
  const geolocation = await resolveCoordinates();
  if (geolocation) {
    const addressComponents = await fetchAddressComponentsByGeocodeAPI(
      geolocation
    );
    if (addressComponents?.status === GOOGLE_GEOCODE_STATUS.OK) {
      return parseGoogleMapsAddressComponents({
        addressComponents,
        geolocation
      });
    }
  }
};

const resolveLocationByGeolocateAPI = async () => {
  const response = await fetch(
    `${GOOGLE_GEOLOCATE_API_URL}?key=${GOOGLE_GEOLOCATE_API_KEY}`
  ).catch(error => {
    logLocationRequestFailure({
      error: error.message,
      source: "useLocation.js",
      Function: "resolveLocationByGeolocateAPI"
    });
  });
  if (!response) {
    return;
  } else if (response?.status > 299) {
    logLocationRequestFailure({
      error: response?.statusText,
      source: "useLocation.js",
      Function: "resolveLocationByGeolocateAPI"
    });
    return;
  } else if (response?.error) {
    const messages = response?.error?.errors?.map(error => error.message);
    logLocationRequestFailure({
      error: "Google Geolocation API could not resolve geolocation",
      source: "useLocation.js",
      Function: "resolveLocationByGeolocateAPI",
      properties: {
        messages
      }
    });
    return;
  } else {
    const {
      location: { lat: latitude, lng: longitude }
    } = response;
    const addressComponents = await fetchAddressComponentsByGeocodeAPI({
      latitude,
      longitude
    });
    const parsedAddressComponents = await parseGoogleMapsAddressComponents({
      addressComponents,
      geolocation: {
        latitude,
        longitude
      }
    });
    if (parsedAddressComponents) {
      logLocationRequestSuccess({
        Function: "resolveLocationByGeolocateAPI",
        source: "useLocation.js"
      });
    } else {
      logLocationRequestFailure({
        error: "Could not fetch and parse address components from Google",
        source: "useLocation.js",
        Function: "resolveLocationByGeolocateAPI"
      });
    }
    return parsedAddressComponents;
  }
};

const resolveLocationByUserProfile = ({ user }) => {
  logLocationRequestSuccess({
    Function: "resolveLocationByUserProfile",
    source: "useLocation.js"
  });
  return {
    city: user.location.city,
    geo_lat: user.location.lat,
    geo_lng: user.location.lng,
    state: user.location.state,
    zip: user.location.zip
  };
};

const getUserLocation = async user => {
  let location = await resolveLocationByNavigator();
  if (!location) {
    location = await resolveLocationByGeolocateAPI();
  }
  if (!location && userHasLocation(user)) {
    location = resolveLocationByUserProfile({
      user
    });
  }
  if (!location) {
    location = DEFAULT_LOCATION;
  }
  return location;
};

export const useGeolocation = user => {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState(DEFAULT_LOCATION);
  useEffect(() => {
    getUserLocation(user)
      .then(location => {
        setLocation(location);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setLocation(DEFAULT_LOCATION);
        console.error(error);
      });
  }, [user?.location?.lat, user?.location?.lng]);
  return {
    loading,
    location: {
      lat: location.geo_lat,
      lng: location.geo_lng,
      city: location.city,
      state: location.state,
      zip: location.zip
    }
  };
};

export const resolveUserLocation = async props => {
  const geoApproved = await setNavigationGeolocationStateListener();
  const location = await getUserLocation(props.currentUser);
  return {
    ...location,
    geoApproved
  };
};
