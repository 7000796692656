import React from "react";
import { reduxForm } from "redux-form";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";

import { ResetPasswordFormController } from "Components/Forms/FormControllers/ResetPasswordFormController";
import { ResetPasswordFormFields } from "Components/Forms/FormFields/ResetPasswordFormFields";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(6, 0, 4, 0)
  }
}));

const ResetPassword = reduxForm({
  form: "FORGOT_PASSWORD"
})(({ handleSubmit, submitting, resetPasswordMessage, sendToLogin }) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        align="center"
        component="h6"
        variant="h6"
        gutterBottom
        className={classes.title}
      >
        Forgot Password?
      </Typography>
      {resetPasswordMessage && (
        <Typography
          align="center"
          component="p"
          variant="body1"
          className={classes.title}
          color="primary"
        >
          {resetPasswordMessage}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <ResetPasswordFormFields />
        <Button
          fullWidth
          variant="contained"
          disabled={submitting}
          type="submit"
          color="primary"
          className={classes.button}
        >
          {submitting ? "Sending" : "Send"}
        </Button>
      </form>
      <Typography
        align="center"
        gutterBottom
        className={classes.title}
        variant="subtitle1"
      >
        <Link component="button" onClick={sendToLogin} underline="hover">
          Back to Log In.
        </Link>
      </Typography>
    </>
  );
});

export const ResetPasswordForm = ({ sendToLogin }) => {
  return (
    <>
      <ResetPasswordFormController>
        {props => <ResetPassword {...props} sendToLogin={sendToLogin} />}
      </ResetPasswordFormController>
    </>
  );
};
