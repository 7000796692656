import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
import snowplowPlugin from "@analytics/snowplow";
import get from "lodash/get";
import omit from "lodash/omit";

import { fullStoryPlugin } from "./Custom/FullStoryPlugin";
import { heapPlugin } from "./Custom/HeapPlugin";
import googleAnalyticsPlugin from "@analytics/google-analytics";
import googleAnalyticsV3Plugin from "@analytics/google-analytics-v3";

import withMetrics from "./deprecated/analytics";

import { setLeadReferrer } from "Analytics/utils/index";

//This hoc is used to expose a context that provides child
//components with the metrics class as a prop. This class
//is used to call track, identify, and page methods.
//It is legacy/deprecated code now and should not be worked on. It
//is still provided so legacy metrics.track calls will not break.
export const withLegacyMetrics = withMetrics;

/* Helper functions */
export * from "./utils/index";

/* 
  We might be using snowplow actions incorrectly,
  so this is to remap the data from analytics.track
  specifically for the snowplow plugin (named 'hc').
  Will need to look into the snowplow data and fix 
  soon. This workaround is fine for now though.
*/

const snowplowEnricherPlugin = {
  name: "enrich-snowplow",
  "track:hc": ({ payload }) => {
    return Object.assign({}, omit(payload, ["properties.context"]), {
      event: get(payload, "properties.action", payload.event)
    });
  }
};

const segmentEnricherPlugin = {
  name: "enrich-segment",
  "track:segment": ({ payload }) => {
    return Object.assign({}, omit(payload, ["properties.context"]), {
      properties: {
        ...payload.properties,
        ...JSON.parse(get(payload, "properties.property"))
      }
    });
  }
};

export const analytics = Analytics({
  app: "Web-App",
  debug: process.env.NODE_ENV === "development",
  plugins: [
    googleAnalyticsPlugin({
      measurementIds: [process.env.REACT_APP_GA4_TAG_ID]
    }),
    googleAnalyticsV3Plugin({
      trackingId: process.env.REACT_APP_GUA_TAG_ID
    }),
    snowplowEnricherPlugin,
    segmentEnricherPlugin,
    fullStoryPlugin(),
    heapPlugin(),
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
      name: "segment"
    }),
    snowplowPlugin({
      scriptSrc: process.env.REACT_APP_SNOWPLOW_COLLECTOR_SOURCE,
      collectorUrl: process.env.REACT_APP_SNOWPLOW_COLLECTOR_ENDPOINT,
      name: "hc",
      trackerSettings: {
        appId: "Web-App",
        cookieName: "_hc_sp_t_",
        cookieDomain: ".hyrecar.com",
        crossDomainLinker: function(linkElement) {
          return /^https:\/\/hyrecar\.com/.test(linkElement.href);
        },
        encodeBase64: false,
        userFingerprint: true,
        contexts: {
          webPage: true,
          gaCookies: true,
          performanceTiming: true
        }
      }
    })
  ]
});

analytics.on("initialize:snowplow", ({ instance }) => {
  instance.plugins.snowplow.enableLinkClickTracking();
});

setLeadReferrer();
