import { useCallback, useContext } from "react";
import { useQuery } from "@apollo/client";

import { FLEET_AGGREGATE_QUERY } from "Queries/Fleet/FleetQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CarStatusEnum } from "Enums/StateEnums";

export const useFleetStatusAggregate = () => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const {
    data: availableData,
    loading: availableLoading,
    refetch: availableRefetch
  } = useQuery(FLEET_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: CarStatusEnum.available
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const {
    data: snoozedData,
    loading: snoozedLoading,
    refetch: snoozedRefetch
  } = useQuery(FLEET_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: CarStatusEnum.removed
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const {
    data: rentedData,
    loading: rentedLoading,
    refetch: rentedRefetch
  } = useQuery(FLEET_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: CarStatusEnum.rented
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const {
    data: deletedData,
    loading: deletedLoading,
    refetch: deletedRefetch
  } = useQuery(FLEET_AGGREGATE_QUERY, {
    variables: {
      filter: {
        status: CarStatusEnum.deleted
      }
    },
    context: {
      important: true
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const refetch = useCallback(
    () =>
      Promise.all([
        availableRefetch(),
        snoozedRefetch(),
        rentedRefetch(),
        deletedRefetch()
      ]),
    [availableRefetch, snoozedRefetch, rentedRefetch, deletedRefetch]
  );

  return {
    loading:
      availableLoading || snoozedLoading || rentedLoading || deletedLoading,
    availableAggregate:
      availableData?.viewer?.me?.owner?.cars?.aggregate?.count ?? 0,
    snoozedAggregate:
      snoozedData?.viewer?.me?.owner?.cars?.aggregate?.count ?? 0,
    rentedAggregate: rentedData?.viewer?.me?.owner?.cars?.aggregate?.count ?? 0,
    deletedAggregate:
      deletedData?.viewer?.me?.owner?.cars?.aggregate?.count ?? 0,
    refetch
  };
};
