import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import get from "lodash/get";
import PropTypes from "prop-types";

import { UserAvatar } from "./UserAvatar";

import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { currentUserClient } from "Components/Utils/ApolloProvider";

import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { openSupportPage, openSupportTicketsPage } from "Utils/openSupportPage";

import { UserTypeEnum } from "Enums/StateEnums";
import { AuthContext } from "Login/Auth";
import { useContext } from "react";

/**
 *
 * We need to check if the usertype is dealer here because there might be
 * undefined behavior if the a dealer type switches to driver and then
 * switches their account to owner.
 */
export function UserAvatarWithDropDown({ logoutUser }) {
  const { switchAccount, switchAccountLoading } = useContext(AuthContext);

  const { data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-only",
    client: currentUserClient
  });
  const userType = get(data, "viewer.me.type");

  const [anchor, setAnchor] = useState(null);

  return (
    <div>
      <UserAvatar
        onClick={e => setAnchor(e.currentTarget)}
        id={joyrideTutorialIds.home.userAvatarDropdown}
      />
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        keepMounted
        onClose={() => setAnchor(null)}
      >
        <MenuItem component="a" onClick={() => openSupportPage()}>
          Help Center
        </MenuItem>
        <MenuItem
          data-testid="ownersupport-ticket"
          component="a"
          onClick={() => openSupportTicketsPage()}
        >
          Support Tickets
        </MenuItem>
        {userType !== UserTypeEnum.dealer && (
          <MenuItem
            onClick={() => {
              switchAccount();
            }}
          >
            {switchAccountLoading ? "Switching..." : "Switch To Driver"}
          </MenuItem>
        )}
        <MenuItem onClick={() => logoutUser()}>Log Out</MenuItem>
      </Menu>
    </div>
  );
}

UserAvatarWithDropDown.propTypes = {
  logoutUser: PropTypes.func.isRequired
};
