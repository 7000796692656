import React from "react";

import { ThemeProvider } from "Styles/ThemeProvider";
import { Footer } from "../Footer";
import { Header } from "../Header";

const groupItemsById = items =>
  items.reduce((acc, item) => {
    const key = item.rooftopId;
    if (key) {
      if (!(key in acc)) {
        acc[key] = { name: item.rooftopName, items: [] };
      }
      acc[key].items.push(item);
    }
    return acc;
  }, {});

export const groupItemsSource = itemsSource => {
  const groupedItems = groupItemsById(itemsSource.getItems());

  const options = {
    getSource: ({ name, lastOne }) => ({
      templates: {
        header() {
          return <Header name={name} />;
        },
        footer() {
          return (
            !lastOne && (
              <ThemeProvider>
                <Footer />
              </ThemeProvider>
            )
          );
        }
      }
    })
  };

  return Object.entries(groupedItems).map(([groupId, groupData], i) => {
    const source = options.getSource({
      name: groupData.name,
      lastOne: i === Object.keys(groupedItems).length - 1
    });

    return {
      ...itemsSource,
      sourceId: groupId,
      getItems() {
        return groupData.items;
      },
      ...source,
      templates: {
        ...itemsSource.templates,
        ...source.templates
      }
    };
  });
};
