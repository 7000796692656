import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { openSupportPage } from "Utils/openSupportPage";

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.common.black
  }
}));

export const ContactSupportButton = ({ preText, buttonText }, props) => {
  const classes = useStyles();

  return (
    <div {...props}>
      <Typography
        component="span"
        variant="subtitle2"
        className={classes.header}
      >
        {preText}
      </Typography>
      <Button onClick={() => openSupportPage()} color="primary" size="small">
        {buttonText}
      </Button>
    </div>
  );
};

ContactSupportButton.propTypes = {
  preText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};
