import React from "react";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import PropTypes from "prop-types";

import { Logo } from "Components/Logo/Logo";

export const BasicHeader = ({ children }) => {
  return (
    <AppBar position="fixed">
      <ToolBar>
        <Logo />
        {children}
      </ToolBar>
    </AppBar>
  );
};

BasicHeader.propTypes = {
  children: PropTypes.node
};
