import React, { useState, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import moment from "moment";
import get from "lodash/get";

import { FlowStepper } from "Components/Flows/FlowStepper";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { ClaimsDamageSelectRental } from "./ClaimsDamageSelectRental";
import { ClaimsDamagePointOfContact } from "./ClaimsDamagePointOfContact";
import { ClaimsDamageDetails } from "./ClaimsDamageDetails";
import { ClaimsDamageImages } from "./ClaimsDamageImages";
import { ClaimsDamageCause } from "./ClaimsDamageCause";
import { RENTALS_FOR_CLAIMS } from "Queries/Rentals/RentalQueries";
import { useClaimsDamageFormController } from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const steps = [
  "Select Rental",
  "Point of Contact",
  "Damage Details",
  "Documentation",
  "Cause"
];

export const StepperContext = React.createContext();

export const ClaimsDamageController = connect()(({ dispatch }) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { onSubmit } = useClaimsDamageFormController(dispatch);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const droppedOffAt_lt = useMemo(() => moment().format(), []);
  const droppedOffAt_gt = useMemo(
    () =>
      moment()
        .subtract(24, "hours")
        .format(),
    []
  );
  const { data, loading } = useQuery(RENTALS_FOR_CLAIMS, {
    variables: {
      droppedOffAt_lt,
      droppedOffAt_gt
    },
    client: currentRooftopClient
  });
  const rentals = get(data, "viewer.me.owner.rentals", []);

  const options = rentals.map(rental => {
    const startDate = get(rental, "rentalPeriods[0].startDate");
    const endDate = get(rental, "currentRentalPeriod.endDate");
    const driverUser = get(rental, "driver.user");
    const car = rental.car;

    return {
      value: rental.id,
      label: `${moment(startDate).format("MMM D")} - ${moment(endDate).format(
        "MMM D, YYYY"
      )} / ${car.make} ${car.model} ${car.year} / ${driverUser.firstName} ${
        driverUser.lastName
      }`
    };
  });

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const prevStep = () => {
    if (step !== 0) {
      setStep(prevStep => prevStep - 1);
    } else {
      history.goBack();
    }
  };

  const getCurrentComponent = step => {
    switch (step) {
      case 0:
        return <ClaimsDamageSelectRental loading={loading} options={options} />;
      case 1:
        return <ClaimsDamagePointOfContact />;
      case 2:
        return <ClaimsDamageDetails />;
      case 3:
        return <ClaimsDamageImages />;
      case 4:
        return <ClaimsDamageCause />;
      default:
        return <ClaimsDamageSelectRental />;
    }
  };

  return (
    <StepperContext.Provider value={{ nextStep, prevStep, onSubmit }}>
      <DashboardLayout hasAppBar>
        <FlowStepper steps={steps} step={step} header="File a Damage Claim" />
        {getCurrentComponent(step)}
      </DashboardLayout>
    </StepperContext.Provider>
  );
});
