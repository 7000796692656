import React from "react";
import { LineItemDrawer } from "Components/Drawers/LineItemDrawer";
import moment from "moment";
import PropTypes from "prop-types";

const DetailedInfoDrawer = ({ open, item, onClose }) => {
  return (
    <LineItemDrawer
      open={open}
      onClose={onClose}
      title={moment(item.transactionDate, "MM/DD/YYYY").format("MMM DD, YYYY")}
      titleRight={item.net}
      tableData={{
        "Transaction Type": item.type?.tooltip ? item.type?.tooltip : item.type,
        Driver: item.driver,
        Car: item.car,
        VIN: item.vin,
        "Rental Period": item.rentalPeriod
      }}
    />
  );
};

DetailedInfoDrawer.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.object,
  onClose: PropTypes.func
};

export default DetailedInfoDrawer;
