import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBack from "@mui/icons-material/ArrowBack";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary[800]
  },
  text: {
    color: theme.palette.common.white
  },
  title: {
    fontWeight: "600"
  },
  subtitle: {
    fontWeight: "600"
  },
  disabled: {
    background: theme.palette.grey[600]
  },
  error: {
    background: theme.palette.error.dark
  },
  closeIconContainer: {
    padding: theme.spacing(1, 1, 0, 1)
  },
  closeIcon: {
    color: theme.palette.common.white
  },
  content: {
    margin: theme.spacing(3, 0, 2, 0)
  }
}));

export const DrawerHead = ({
  overlineComponent,
  overline,
  title,
  subtitle,
  children,
  disabled,
  error,
  onClose,
  onBack
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, {
        [classes.disabled]: disabled,
        [classes.error]: error
      })}
    >
      {onClose && (
        <div className={classes.closeIconContainer}>
          <IconButton onClick={onClose} size="small">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      )}
      {onBack && (
        <div className={classes.closeIconContainer}>
          <IconButton onClick={onBack} size="small">
            <ArrowBack className={classes.closeIcon} />
          </IconButton>
        </div>
      )}
      <DrawerPaddingContainer>
        {Boolean(overlineComponent) && overlineComponent}
        {overline && (
          <Typography
            component="span"
            display="block"
            variant="overline"
            className={classes.text}
          >
            {overline}
          </Typography>
        )}
        {title && (
          <Typography
            component="h3"
            display="block"
            variant="h4"
            className={classNames(classes.text, classes.title)}
            gutterBottom={!subtitle}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            component="h6"
            variant="h6"
            className={classNames(classes.text, classes.subtitle)}
            gutterBottom
          >
            {subtitle}
          </Typography>
        )}
        <div className={classes.content}>{children}</div>
      </DrawerPaddingContainer>
    </div>
  );
};

DrawerHead.propTypes = {
  overlineComponent: PropTypes.node,
  overline: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onClose: PropTypes.func
};
