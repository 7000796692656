import Loading from "hyrecar-web-components/build//elements/Loading";
import { branch, renderComponent, withProps } from "recompose";

// Loading States
const initialLoading = networkStatus => networkStatus === 1;
const activelyRefetching = networkStatus => networkStatus === 4;
const passivelyRefetching = networkStatus =>
  networkStatus === 2 || networkStatus === 6;
const fetchingMore = networkStatus => networkStatus === 3;

// Error States
export const checkIsErrorStatus = networkStatus => networkStatus === 8;

// State HOCS
export const loadingState = (LoadingComponent = Loading) =>
  branch(
    props => initialLoading(props.data.networkStatus),
    renderComponent(LoadingComponent),
    withProps(props => ({
      activelyRefetching: activelyRefetching(props.data.networkStatus),
      passivelyRefetching: passivelyRefetching(props.data.networkStatus),
      fetchingMore: fetchingMore(props.data.networkStatus)
    }))
  );

export const errorState = Component =>
  branch(
    props => checkIsErrorStatus(props.data.networkStatus),
    withProps(({ data }) => ({ error: data.error }))(Component)
  );
