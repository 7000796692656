import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";

import { SkeletonDrawer } from "Components/Loading/SkeletonDrawer";
import { useListingStatusEditController } from "Components/Drawers/ListingDrawer/hooks/useListingStatusEditController";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { StatusOptionsForm } from "Components/Drawers/ListingDrawer/StatusOptionsForm";
import { DrawerCloseButton } from "Components/Drawers/DrawerCloseButton";
import { DrawerHead } from "Components/Drawers/DrawerHead";
import { RemoveListingDialog } from "Components/Dialog/RemoveListingDialog";
import { ListingStatusCopyEnum } from "Enums/StateEnums";

const useStyles = makeStyles(theme => ({
  statusOptionsContainer: {
    marginTop: theme.spacing(3)
  },
  title: {
    color: theme.palette.common.black
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  image: {
    display: "block",
    width: "100%",
    height: "240px",
    objectFit: "cover"
  },
  text: {
    color: theme.palette.common.white
  }
}));

const ListingStatusEditForm = reduxForm({
  form: "CAR_STATUS_EDIT",
  destroyOnUnmount: true
})(
  ({
    handleSubmit,
    onClose,
    options,
    submitting,
    handleOpenWarning,
    hasShownWarning,
    handleReShowWarning,
    invalid,
    initialCarStatus
  }) => {
    const classes = useStyles();
    const [optionSelected, setOptionSelected] = useState("");
    const [
      displayDeleteListingDialog,
      setDisplayDeleteListingDialog
    ] = useState(false);
    return (
      <>
        <DrawerPaddingContainer className={classes.drawerContainer}>
          <RemoveListingDialog
            displayDeleteListingDialog={displayDeleteListingDialog}
            setDisplayDeleteListingDialog={setDisplayDeleteListingDialog}
            submit={handleSubmit}
            submitting={submitting}
          />
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.title}>
              Update Listing Status
            </Typography>
            <Grid container className={classes.statusOptionsContainer}>
              <Field
                name="carStatus"
                handleOpenWarning={handleOpenWarning}
                component={StatusOptionsForm}
                initialCarStatus={initialCarStatus}
                hasShownWarning={hasShownWarning}
                handleReShowWarning={handleReShowWarning}
                options={options}
                props={{ setOptionSelected }}
              />
            </Grid>
          </Paper>
        </DrawerPaddingContainer>
        <DrawerBottomBar
          onCancel={onClose}
          cancelText="Back"
          onSubmit={props => {
            if (optionSelected === ListingStatusCopyEnum.deleted) {
              setDisplayDeleteListingDialog(true);
            } else {
              handleSubmit(props);
            }
          }}
          submitText={submitting ? "Submitting" : "Submit"}
          disabled={
            invalid || submitting || initialCarStatus === optionSelected
          }
        />
      </>
    );
  }
);

export const ListingStatusEdit = ({ onClose, carId }) => {
  const classes = useStyles();

  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;

  const {
    car,
    onSubmit,
    initialValues,
    carLoading,
    statusOptionsLabels,
    validate
  } = useListingStatusEditController(id, onClose);

  const carImageUrl = car?.photos[0]?.file?.url;

  return (
    <>
      {carLoading ? (
        <SkeletonDrawer showCar />
      ) : (
        <>
          <DrawerCloseButton onClick={onClose} />
          {carImageUrl && (
            <img className={classes.image} src={carImageUrl} alt="car" />
          )}
          <DrawerHead
            overline={car.year}
            title={(car.make || car.year) && `${car.make} ${car.model}`}
          >
            {car.vin && (
              <Typography
                component="span"
                variant="subtitle1"
                className={classes.text}
              >
                {car.vin}
              </Typography>
            )}
          </DrawerHead>
          <ListingStatusEditForm
            car={car}
            onSubmit={onSubmit}
            onClose={onClose}
            initialValues={initialValues}
            initialCarStatus={initialValues.carStatus}
            options={statusOptionsLabels}
            validate={validate}
          />
        </>
      )}
    </>
  );
};

ListingStatusEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  carId: PropTypes.string
};
