import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PropTypes from "prop-types";

import { CAR_SUMMARY } from "Queries/Car/Car";
import { DrawerAppBar } from "Components/Drawers/DrawerAppBar";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { ListingLocation } from "Components/Drawers/ListingDrawer/ListingLocation";
import { ListingInformation } from "Components/Drawers/ListingDrawer/ListingInformation";
import { ListingTerms } from "Components/Drawers/ListingDrawer/ListingTerms";
import { ListingPhotos } from "Components/Drawers/ListingDrawer/ListingPhotos";
import { ListingDocuments } from "Components/Drawers/ListingDrawer/ListingDocuments";
import { CarStatusEnum } from "Enums/StateEnums";
import { ListingsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

import AsyncDropzoneLoadingProvider from "Components/Forms/Dropzone/AsyncDropzoneLoadingProvider";
import { UnsavedProgressDialogContext } from "Components/Utils/UnsavedProgressDialogProvider";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { analytics } from "Analytics/index";
import { OverpricedCarsContext } from "Components/Utils/OverpricedCarsProvider";

export const ListingEdit = ({ sendToListingSummary, carId, activeTab }) => {
  const { id: paramsId } = useParams();
  const id = carId ? carId : paramsId;
  const { scopes, currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    setCloseUnsavedProgressCallback,
    setDisplayUnsavedProgressDialog,
    unsavedProgressExists
  } = useContext(UnsavedProgressDialogContext);

  // overprice car summary context
  const { getOverpricedCarById } = useContext(OverpricedCarsContext);
  const carOverpriceSummary = getOverpricedCarById(id);

  useEffect(() => {
    if (carOverpriceSummary?.carId) {
      analytics.track(AnalyticsEvents.label.owner.ownerEditCar, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.owner.ownerEditCar,
        property: JSON.stringify({
          carId: carOverpriceSummary.carId,
          carPrice: carOverpriceSummary.carPrice,
          priceRecommendations: {
            low: carOverpriceSummary.priceRecommendations.low,
            high: carOverpriceSummary.priceRecommendations.high
          },
          marketingOrigin: null,
          overpriced: carOverpriceSummary.overpriced
        }),
        value: "",
        context: ""
      });
    }
  }, []);

  useEffect(() => {
    setCloseUnsavedProgressCallback(() => sendToListingSummary);
    return () => setCloseUnsavedProgressCallback(undefined);
  }, []);

  const { data, loading } = useQuery(CAR_SUMMARY, {
    variables: { id },
    fetchPolicy: "cache-first",
    client: currentRooftopClient
  });
  const car = data?.car;

  return (
    <>
      <DrawerAppBar
        title="Edit Listing"
        border
        icon={
          <IconButton
            onClick={() => {
              unsavedProgressExists
                ? setDisplayUnsavedProgressDialog(true)
                : sendToListingSummary();
            }}
            size="large"
          >
            <ChevronLeftIcon />
          </IconButton>
        }
      />
      {!loading && (
        <DrawerPaddingContainer>
          {car.status !== CarStatusEnum.rented && (
            <>
              <ListingLocation carId={id} />
              <ListingInformation carId={id} />
              {renderByPermissions(
                <ListingTerms
                  carId={id}
                  isActive={activeTab === "pricing"}
                  carOverpriceSummary={carOverpriceSummary}
                />,
                [ListingsDomainPermissionPaths.editPricing],
                scopes
              )}
              <ListingPhotos carId={id} />
            </>
          )}
          <AsyncDropzoneLoadingProvider>
            <ListingDocuments carId={id} />
          </AsyncDropzoneLoadingProvider>
        </DrawerPaddingContainer>
      )}
    </>
  );
};

ListingEdit.propTypes = {
  sendToListingSummary: PropTypes.func.isRequired,
  carId: PropTypes.string
};
