export const heapPlugin = () => ({
  name: "heap",
  track: ({ payload }) => {
    const { event, properties } = payload;
    window?.heap.track(event, { ...properties });
  },
  identify: ({ payload }) => {
    const { userId, traits } = payload;
    if (userId) {
      window?.heap.identify(userId);
      const { email, fullName } = traits;
      window?.heap.addUserProperties({ email, display_name: fullName });
    }
  }
});
