import { gql } from "@apollo/client";

import { OwnerFragments } from "./OwnerFragments";
import { CarFragments } from "../Car/CarFragments";
import { GeneralFragments } from "../General/GeneralFragments";

export const GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($input: SendTransactionsInput) {
    getUserTransactions(input: $input) {
      ...OwnerTransaction
    }
  }
  ${OwnerFragments.ownerTransactions}
`;

export const OWNER_DEFAULT_PROTECTION_PLAN = gql`
  query OwnerDefaultProtectionPlan {
    viewer {
      id
      me {
        id
        owner {
          id
          ...OwnerDefaultProtectionPlan
        }
      }
    }
  }
  ${OwnerFragments.ownerDefaultProtectionPlan}
`;

export const OWNER_ICC_CONNECTED = gql`
  query OwnerIccConnected {
    viewer {
      id
      me {
        id
        owner {
          id
          ...OwnerConnectedIcc
        }
      }
    }
  }
  ${OwnerFragments.ownerConnectedIcc}
`;

export const OWNERS_CAR_INFO = gql`
  query OWNERS_CAR_INFO {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(
            where: {
              AND: [
                { status_not_in: [DELETED] }
                { verification_not_in: [FAILED_VERIFICATION] }
              ]
            }
          ) {
            edges {
              node {
                ...CarDetails
              }
            }
          }
        }
      }
    }
  }
  ${CarFragments.carDetails}
`;

export const OWNERS_FIRST_CAR_ID = gql`
  query OWNERS_FIRST_CAR_ID {
    viewer {
      cars(first: 1) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const OWNERS_CARS_LATEST = gql`
  query OWNERS_CARS_LATEST(
    $where: CarWhereInput
    $orderBy: CarOrderByInput
    $first: Int
  ) {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(where: $where, orderBy: $orderBy, first: $first) {
            edges {
              node {
                id
                createdAt
                year
                make
                model
                dailyPriceInCents
                photos(where: { isPrimary: true }) {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const OWNER_REJECTED_RENTALS = gql`
  query OWNER_REJECTED_RENTALS($filter: RentalWhereInput) {
    viewer {
      id
      me {
        id
        owner {
          id
          rentalsConnection(filter: $filter) {
            edges {
              node {
                id
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

export const OWNERS_CAR_IDS = gql`
  query OWNERS_CAR_IDS(
    $first: Int
    $before: String
    $after: String
    $searchText: String
    $where: CarWhereInput
    $orderBy: CarOrderByInput
  ) {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(
            where: $where
            first: $first
            before: $before
            after: $after
            searchText: $searchText
            orderBy: $orderBy
          ) {
            pageInfo {
              ...CursorInfo
            }
            edges {
              node {
                id
                vin
              }
            }
          }
        }
      }
    }
  }
  ${GeneralFragments.cursorInfo}
`;
