import { optionalChaining } from "./helpers";

const GetSiteControl = window._gscq || {
  push: () => null
};

export default {
  identify: (userId, user) => {
    const newSiteUser = optionalChaining(
      () => user.id.startsWith("u") || user.id.startsWith("c")
    );
    GetSiteControl.push(["targeting", "loggedIn", true]);
    if (user) {
      GetSiteControl.push(["user", "id", user.id]);
      GetSiteControl.push(["user", "email", user.email]);
      GetSiteControl.push(["user", "name", user.fullName]);
      GetSiteControl.push(["targeting", "oldSite", newSiteUser ? false : true]);
      GetSiteControl.push(["template", "id", user.id]);
      GetSiteControl.push(["template", "email", user.email]);
      GetSiteControl.push(["template", "name", user.fullName]);
    }
  },
  reset: () => {
    GetSiteControl.push(["targeting", "loggedIn", false]);
  },
  pageView: route => {
    GetSiteControl.push(["trackPage", route]);
    GetSiteControl.push(["targeting", "pageView", route]);
  }
};
