import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useMemo } from "react";
import moment from "moment";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import Link from "@mui/material/Link";

import { SUBMIT_ACH, UPDATE_OWNER_PHONE } from "Mutations/User/UserMutations";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { StripeContext } from "Components/Utils/Stripe";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { currentUserClient } from "Components/Utils/ApolloProvider";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { formatPhone } from "Utils/Helpers";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const achRequiredFields = [
  "routing_number",
  "account_number",
  "confirm_account_number",
  "first_name",
  "last_name",
  "line1",
  "city",
  "state",
  "postal_code",
  "dob",
  "personal_id_number",
  "tos",
  "phone"
];

const defaultAchErrorMessage = customerServicePhoneNumber =>
  `Sorry, there was an error submitting your ACH information. Please try again or contact our helpdesk at ${customerServicePhoneNumber}.`;
const POSTAL_CODE_ACH_ERROR_MESSAGE =
  "The postal code is not valid. Please input a valid US Zip Code";
const TAX_ID_ACH_ERROR_MESSAGE =
  "Your Social Security or Tax ID is invalid, please enter a valid 9-digit SSN or Tax ID.";

const trimGraphQLError = errorMessage => {
  if (errorMessage?.includes("GraphQL error: ")) {
    let splitMessage = errorMessage.split("GraphQL error: ");
    return splitMessage[1];
  }
  return errorMessage;
};

const trackErrorEvent = properties =>
  analytics.track(AnalyticsEvents.label.owner.ACHUpdateFailed, {
    category: AnalyticsEvents.category.userInteraction,
    action: AnalyticsEvents.action.requestFailed,
    label: AnalyticsEvents.label.owner.submittedACH,
    property: JSON.stringify(properties),
    value: "",
    context: ""
  });

export const AchFormController = ({ onSubmitSuccess, children, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useContext(StripeContext);
  const { currentRooftopClient, currentRooftopToken } = useContext(
    ClientFactoryContext
  );

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  const [submitAch] = useMutation(SUBMIT_ACH, {
    context: {
      apiv2: true,
      headers: {
        "x-rooftop-authorization": currentRooftopToken
      }
    }
  });
  const { data: rooftopData, loading: rooftopLoading } = useQuery(
    CURRENT_USER_QUERY,
    {
      client: currentRooftopClient,
      fetchPolicy: "network-only"
    }
  );
  const { data: userData, loading: userLoading } = useQuery(
    CURRENT_USER_QUERY,
    {
      client: currentUserClient,
      fetchPolicy: "network-only"
    }
  );

  const [updateOwnerProfile] = useMutation(UPDATE_OWNER_PHONE, {
    client: currentUserClient,
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    fetchPolicy: "no-cache",
    awaitRefetchQueries: true
  });

  //We need to get the rooftop of the email if this is a rooftop.
  //otherwise we can use the email belonging to the logged in user.
  // const rooftop = rooftops.find(rooftop => me.id === rooftop.legacyId);

  const initialValues = {
    first_name: get(userData, "viewer.me.firstName"),
    last_name: get(userData, "viewer.me.lastName"),
    line1: get(rooftopData, "viewer.me.location.street"),
    city: get(rooftopData, "viewer.me.location.city"),
    state: get(rooftopData, "viewer.me.location.state"),
    postal_code: get(rooftopData, "viewer.me.location.zip"),
    dob: get(userData, "viewer.me.dob"),
    type: "company",
    email: get(userData, "viewer.me.email"),
    phone: formatPhone(get(userData, "viewer.me.phone"))
  };

  const getStripeAccountToken = async ({
    dob,
    type,
    first_name,
    last_name,
    line1,
    city,
    state,
    postal_code,
    personal_id_number,
    business_name,
    business_tax_id,
    email,
    phone
  }) => {
    const date_of_birth = moment(dob);
    const accountDetails = {
      tos_shown_and_accepted: true
    };
    const formattedAddress = {
      line1,
      city,
      state,
      postal_code
    };

    switch (type) {
      case "company":
        accountDetails.business_type = "company";
        accountDetails.company = {
          name: business_name,
          tax_id: business_tax_id,
          address: formattedAddress,
          phone,
          owners_provided: true
        };
        break;
      case "individual":
        accountDetails.business_type = "individual";
        accountDetails.individual = {
          address: formattedAddress,
          dob: {
            day: parseInt(date_of_birth.format("DD"), 10),
            month: parseInt(date_of_birth.format("MM"), 10),
            year: parseInt(date_of_birth.format("YYYY"), 10)
          },
          id_number: personal_id_number.replace(/-|\s/gi, ""),
          first_name,
          last_name,
          email,
          phone
        };
        break;
      default:
        throw new Error("Unhandled account type");
    }
    const { token, error } = await stripe.createToken(
      "account",
      accountDetails
    );

    if (error) {
      console.error("Error creating Stripe Account Token ", error);
      trackErrorEvent({
        error,
        message: error?.message,
        operation: "Create Stripe Account Token"
      });
      error.message = defaultAchErrorMessage(customerServicePhoneNumber);
      throw error;
    } else {
      return token;
    }
  };

  const getStripePersonToken = async ({
    dob,
    type,
    first_name,
    last_name,
    line1,
    city,
    state,
    postal_code,
    personal_id_number,
    email,
    phone
  }) => {
    if (type === "individual") return null;
    const formattedAddress = {
      line1,
      city,
      state,
      postal_code
    };
    const date_of_birth = moment(dob);
    const { token, error } = await stripe.createToken("person", {
      person: {
        first_name,
        last_name,
        address: formattedAddress,
        dob: {
          day: parseInt(date_of_birth.format("DD"), 10),
          month: parseInt(date_of_birth.format("MM"), 10),
          year: parseInt(date_of_birth.format("YYYY"), 10)
        },
        id_number: personal_id_number.replace(/-|\s/gi, ""),
        email,
        phone,
        relationship: {
          title: "HyreCar Fleet Admin",
          representative: true,
          owner: true
        }
      }
    });
    if (error) {
      console.error("Error creating Stripe Person Token ", error);
      trackErrorEvent({
        error,
        message: error?.message,
        operation: "Create Stripe Person Token"
      });
      error.message = defaultAchErrorMessage(customerServicePhoneNumber);
      throw error;
    } else {
      return token;
    }
  };

  const sanitizeBankInformationInput = bankAccountNumber =>
    bankAccountNumber.replace(/ /g, "").replace(/\t/g, "");

  const getStripeBankToken = async ({
    routing_number,
    account_number,
    first_name,
    last_name,
    type
  }) => {
    const sanitizedAccountNumber = sanitizeBankInformationInput(account_number);
    const sanitizedRoutingNumber = sanitizeBankInformationInput(routing_number);

    const { token, error } = await stripe.createToken("bank_account", {
      country: "us",
      currency: "usd",
      routing_number: sanitizedRoutingNumber,
      account_number: sanitizedAccountNumber,
      account_holder_name: `${first_name} ${last_name}`,
      type
    });

    if (error) {
      console.error("Error creating Stripe Bank Token ", error);
      trackErrorEvent({
        error,
        message: error?.message,
        operation: "Create Stripe Bank Token"
      });
      error.message = defaultAchErrorMessage(customerServicePhoneNumber);
      throw error;
    } else {
      return token;
    }
  };

  const onSubmit = async values => {
    try {
      const [bankToken, accountToken, personToken] = await Promise.all([
        getStripeBankToken(values),
        getStripeAccountToken(values),
        getStripePersonToken(values)
      ]);

      await updateOwnerProfile({
        variables: {
          phone: values.phone.replace(/[^0-9]/g, "")
        }
      });

      const data = await submitAch({
        variables: {
          data: {
            bankToken: bankToken.id,
            accountToken: accountToken.id,
            personToken: personToken ? personToken.id : null
          }
        }
      });
      if (onSubmitSuccess) {
        onSubmitSuccess(data);
        analytics.track(AnalyticsEvents.label.owner.submittedACH, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.submission,
          label: AnalyticsEvents.label.owner.submittedACH,
          property: JSON.stringify({}),
          value: "",
          context: ""
        });
      }
      refetch();
    } catch (e) {
      console.error("Submit ACH Error ", e);
      trackErrorEvent({
        error: e,
        message: e?.message,
        operation: "Submit ACH Changes"
      });

      let snackbarError = e?.message
        ? trimGraphQLError(e.message)
        : defaultAchErrorMessage(customerServicePhoneNumber);
      // phone number error from API or on token creation
      if (
        e?.message?.includes("phone number") ||
        e?.param === "account[individual][phone]" ||
        e?.param === "person[phone]"
      ) {
        let linkStyles = { color: "white", textDecoration: "underline" };
        snackbarError = (
          <span>
            The phone number used to verify your bank account is not valid.{" "}
            <Link
              style={linkStyles}
              href="/fleet/dashboard/settings/profile"
              target="_blank"
              underline="hover"
            >
              Update your phone number here.
            </Link>
          </span>
        );
      }
      // postal code error on token creation
      if (
        e?.param === "account[individual][address][postal_code]" ||
        e?.param === "person[address][postal_code]"
      ) {
        snackbarError = POSTAL_CODE_ACH_ERROR_MESSAGE;
      }
      // SSN error on token creation
      if (
        e?.param === "account[individual][id_number]" ||
        e?.param === "person[id_number]"
      ) {
        snackbarError = TAX_ID_ACH_ERROR_MESSAGE;
      }
      enqueueSnackbar(snackbarError, {
        variant: "error",
        autoHideDuration: 8000
      });
    }
  };

  const validateNumberLength = (phoneNumber, validLength) =>
    phoneNumber.replace(/[^0-9]/g, "").length === validLength;

  const validate = values => {
    const errors = {};

    achRequiredFields.map(field => {
      errors[field] = !values[field] ? "Required" : undefined;
      return errors;
    });
    if (values.tos === false) {
      errors.tos = "Required";
    }
    if (
      values.personal_id_number &&
      !validateNumberLength(values.personal_id_number, 9)
    ) {
      errors.personal_id_number = "SSN must be 9 digits";
    }
    if (values.phone && !validateNumberLength(values.phone, 10)) {
      errors.phone = "Please enter a valid 10 digit phone number";
    }
    if (!values.phone) {
      errors.phone = "Please enter a phone number";
    }
    if (values.account_number !== values.confirm_account_number) {
      errors.confirm_account_number = "Account numbers must match";
    }
    if (values.type === "company") {
      if (!values.business_name) {
        errors.business_name = "Required";
      }
      if (!values.business_tax_id) {
        errors.business_tax_id = "Required";
      }
    }
    if (
      moment(values.dob, "MM/DD/YYYY", true).isValid() &&
      moment(values.dob) > moment()
    ) {
      errors.dob = "Date of Birth cannot be future date";
    }
    return errors;
  };

  return children({
    onSubmit,
    validate,
    loading: userLoading || rooftopLoading,
    onSubmitFail: errors => scrollErrorIntoView(errors),
    initialValues
  });
};

AchFormController.propTypes = {
  children: PropTypes.func.isRequired
};
