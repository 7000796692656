import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { reduxForm, Field, change } from "redux-form";
import { connect } from "react-redux";
import get from "lodash/get";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperFunctions } from "../ManualContainer";
import { StepperContext } from "../../CSV/CSVController";
import { TooltipContainer } from "Components/Flows/TooltipContainer";
import { ProtectionPlanEnum } from "Enums/StateEnums";
import { LocationMap } from "Components/Forms/Location/LocationMap";
import { validateLocation } from "Components/Forms/Location/LocationAutoComplete";
import { FormSelect } from "Components/Inputs/FormSelect";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import ManualFlowTooltip from "../ManualFlowTooltip";
import { GET_MEDIAN_DAILY_PRICE } from "Queries/Car/Car";
import { currentUserClient } from "Components/Utils/ApolloProvider";
import { centsToDollarsInt } from "Utils/Calculations";

import { ROOFTOP_INFO } from "Queries/Rooftop/RooftopQueries";

const useStyles = makeStyles(theme => ({
  blackText: {
    color: "#000",
    marginBottom: theme.spacing(1),
    fontSize: theme.spacing(2)
  },
  field: {
    marginBottom: theme.spacing(3)
  },
  address: {
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  },
  h3: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.black
  }
}));

const RooftopForm = reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate: values => validateLocation(values, "address", "rooftop")
})(
  ({
    handleSubmit,
    previousStep,
    nextStepLabel,
    submitting,
    currentRooftop,
    getOrganizationRooftops,
    currentOrganizationInfo,
    changeCurrentRooftopClient
  }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles({ matches });

    const rooftopTokens = getOrganizationRooftops(currentOrganizationInfo.id);

    const rooftopOptions = rooftopTokens.map(rooftopToken => ({
      value: {
        id: jwtDecode(rooftopToken).id,
        name: jwtDecode(rooftopToken).name,
        token: rooftopToken
      },
      label: jwtDecode(rooftopToken).name
    }));

    return (
      <FlowContentContainer
        nextStepId="next-rooftop-selection"
        header="Confirm Rooftop"
        nextStep={handleSubmit}
        nextStepLabel={submitting ? "Loading" : nextStepLabel}
        previousStep={previousStep}
        previousStepLabel="Exit"
        disabled={submitting}
      >
        <Typography
          component="div"
          variant="subtitle1"
          className={classes.blackText}
        >
          You are listing this car at {get(currentRooftop, "name")}. To switch
          rooftops, select one now:
        </Typography>
        <Field
          id="rooftop-selection-for-single-car-listing"
          name="rooftop"
          label="Select Rooftop"
          variant="filled"
          fullWidth
          margin="normal"
          component={FormSelect}
          options={rooftopOptions}
          className={classes.field}
          renderValue={value => value.name}
          onChange={(oldValue, newValue) => {
            changeCurrentRooftopClient(get(newValue, "token"));
          }}
        />
        {get(currentRooftop, "address.lat") &&
          get(currentRooftop, "address.lng") && (
            <>
              <Typography className={classes.address}>
                Pickup Address: {get(currentRooftop, "address.streetAddress")}{" "}
                {get(currentRooftop, "address.postalCode")}{" "}
                {get(currentRooftop, "address.city")}
              </Typography>
              <LocationMap
                geo_lat={get(currentRooftop, "address.lat", 34.052359)}
                geo_lng={get(currentRooftop, "address.lng", -118.252743)}
              />
            </>
          )}
      </FlowContentContainer>
    );
  }
);

export const Rooftop = connect(null, { change })(({ listingType, change }) => {
  const { nextStep, previousStep, prevStep } = useContext(
    listingType === "CSV" ? StepperContext : StepperFunctions
  );
  const { getJSONValue } = useContext(FirebaseContext);
  const tooltips = getJSONValue("web_manualFlowRooftopTooltips");
  const insuranceTooltip = getJSONValue("web_manualFlowInsuranceTooltip");
  tooltips.splice(1, 0, insuranceTooltip);

  const {
    currentRooftopToken,
    currentOrganizationClient,
    currentOrganizationInfo,
    changeCurrentRooftopClient,
    getOrganizationRooftops
  } = useContext(ClientFactoryContext);

  const rooftopId = jwtDecode(currentRooftopToken)?.id;

  const { data } = useQuery(ROOFTOP_INFO, {
    variables: {
      id: rooftopId
    },
    client: currentOrganizationClient
  });

  const currentRooftop = data?.rooftop;

  const streetNumber =
    get(currentRooftop, "address.streetAddress") &&
    get(currentRooftop, "address.streetAddress").slice(0, 4);

  const right = (
    <TooltipContainer header="Helpful Tips" useIcon>
      {tooltips?.map((tooltip, i) => (
        <ManualFlowTooltip key={i} tooltip={tooltip} />
      ))}
    </TooltipContainer>
  );

  const handleSubmit = async values => {
    const zip = values?.address?.zip;

    if (zip) {
      const { data } = await currentUserClient.query({
        query: GET_MEDIAN_DAILY_PRICE,
        fetchPolicy: "network-only",
        variables: {
          zip
        }
      });

      const medianDailyPrice = data?.getMedianDailyPrice;

      if (medianDailyPrice) {
        const formattedMedianPrice = Math.round(
          centsToDollarsInt(medianDailyPrice)
        );
        change("LIST_CAR_FLOW", "daily_rate", formattedMedianPrice);
        change("LIST_CAR_FLOW", "median_price", formattedMedianPrice);
      }

      nextStep();
    } else {
      nextStep();
    }
  };

  return (
    <ColumnFlowLayout
      leftComponent={
        <RooftopForm
          currentRooftop={currentRooftop}
          getOrganizationRooftops={getOrganizationRooftops}
          changeCurrentRooftopClient={changeCurrentRooftopClient}
          currentOrganizationInfo={currentOrganizationInfo}
          onSubmit={handleSubmit}
          previousStep={listingType === "CSV" ? prevStep : previousStep}
          nextStepLabel={listingType === "CSV" ? "continue" : "start"}
          form={listingType === "CSV" ? "CSV_IMPORT" : "LIST_CAR_FLOW"}
          initialValues={{
            rooftop: {
              id: currentRooftop?.id,
              name: currentRooftop?.name,
              token: currentRooftopToken
            },
            address: {
              selected: true,
              streetNumber,
              street: get(currentRooftop, "address.streetAddress"),
              zip: get(currentRooftop, "address.postalCode"),
              city: get(currentRooftop, "address.city"),
              state: get(currentRooftop, "address.state"),
              lat: get(currentRooftop, "address.lat"),
              lng: get(currentRooftop, "address.lng"),
              address: get(currentRooftop, "address.streetAddress")
            },
            defaultProtectionPlan: ProtectionPlanEnum.premium_plus,
            daily_rate: 35,
            weekly_discount: 5,
            monthly_discount: 10,
            maxDailyMiles: "1000",
            photos: []
          }}
        />
      }
      rightComponent={right}
      useBottomDrawer
    />
  );
});

Rooftop.propTypes = {
  listingType: PropTypes.string
};
