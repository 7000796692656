import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const DraggableContext = createContext({});

export const DraggableContainer = ({ onDrop, children }) => {
  const onDragEnter = e => e.preventDefault();
  const onDragOver = e => e.preventDefault();

  const onDragStart = e => {
    const id = e.target.getAttribute("id");
    const index = e.target.getAttribute("index");

    e.dataTransfer.setData(
      "application/json",
      JSON.stringify({
        id,
        index
      })
    );
  };

  const draggableOnDrop = e => {
    try {
      const dragged = JSON.parse(e.dataTransfer.getData("application/json"));
      const draggedId = dragged.id;
      const draggedIndex = parseInt(dragged.index, 10);
      const destinationId = e.target.getAttribute("id");
      const destinationIndex = parseInt(e.target.getAttribute("index"), 10);

      onDrop({
        dragged: {
          id: draggedId,
          index: draggedIndex
        },
        destination: {
          id: destinationId,
          index: destinationIndex
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <DraggableContext.Provider
      value={{ draggableOnDrop, onDragEnter, onDragOver, onDragStart }}
    >
      {children}
    </DraggableContext.Provider>
  );
};

DraggableContainer.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export const Draggable = ({ id, index, children }) => {
  const { draggableOnDrop, onDragStart, onDragOver, onDragEnter } = useContext(
    DraggableContext
  );

  return children({
    id,
    index,
    draggable: true,
    onDrop: draggableOnDrop,
    onDragStart,
    onDragOver,
    onDragEnter
  });
};

Draggable.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired
};
