import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FilterIcon from "@mui/icons-material/FilterList";
import Badge from "@mui/material/Badge";

import {
  updateInboxFilters,
  updateInboxSearch
} from "Redux/SendBird/ActionCreators";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import SearchInput from "Components/Inputs/SearchInput";
import FiltersDialog from "./FiltersDialog";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: theme.spacing(2, 2)
  },
  container: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center"
  },
  divider: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: 2
  }
}));

const FiltersBar = ({
  updateInboxFilters,
  inboxFilters,
  inboxSearch,
  updateInboxSearch
}) => {
  const classes = useStyles();
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setFiltersDialogOpen(true);
  };

  const handleClose = () => {
    setFiltersDialogOpen(false);
  };

  const handleFilters = values => {
    updateInboxFilters(values);
  };

  const getFiltersAmount = () =>
    inboxFilters.messageTypes.length + inboxFilters.rentalStatus.length;

  useEffect(
    () => () => {
      updateInboxFilters({ messageTypes: [], rentalStatus: [] });
    },
    [updateInboxFilters]
  );

  const filtersAmount = getFiltersAmount();

  return (
    <div className={classes.root} id={joyrideTutorialIds.inboxSearch}>
      <div className={classes.container}>
        <SearchInput
          value={inboxSearch}
          update={updateInboxSearch}
          label="Search contact"
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <div>
          <IconButton onClick={handleClickOpen} size="small">
            <Badge
              color="primary"
              invisible={!filtersAmount}
              badgeContent={filtersAmount}
            >
              <FilterIcon />
            </Badge>
          </IconButton>
        </div>
        <FiltersDialog
          onClose={handleClose}
          open={filtersDialogOpen}
          onSubmit={handleFilters}
          filters={inboxFilters}
        />
      </div>
    </div>
  );
};

FiltersBar.propTypes = {
  updateInboxFilters: PropTypes.func,
  inboxFilters: PropTypes.object,
  updateInboxSearch: PropTypes.func,
  inboxSearch: PropTypes.string
};

export default connect(
  state => ({
    inboxFilters: state.fleet.sendbird.inboxFilters,
    inboxSearch: state.fleet.sendbird.inboxSearch
  }),
  { updateInboxFilters, updateInboxSearch }
)(FiltersBar);
