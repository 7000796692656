import React, { useState } from "react";
import { reduxForm } from "redux-form";
import { useMutation, useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import LoopIcon from "@mui/icons-material/Loop";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import get from "lodash/get";

import { AvatarDropzone } from "Components/Forms/Dropzone/AvatarDropzone";
import { UserProfileFormController } from "Components/Forms/FormControllers/UserProfileFormController";
import {
  ProfileAccountInfoFields,
  ProfileLocationFields
} from "Components/Forms/FormFields/UserProfileFormFields";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { UPDATE_PROFILE_PHOTO } from "Mutations/User/UserMutations";
import { currentUserClient } from "Components/Utils/ApolloProvider";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    overflowX: "hidden"
  },
  header: {
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(3)
  },
  avatar: {
    width: 82,
    height: 82
  },
  container: {
    padding: theme.spacing(0, 4)
  },
  divider: {
    margin: theme.spacing(6, 0, 4, 0)
  },
  button: {
    padding: theme.spacing(1, 4)
  },
  buttonContainer: {
    marginTop: theme.spacing(4)
  }
}));

const UserProfileForm = reduxForm({
  form: "EDIT_USER_PROFILE",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false
})(({ handleSubmit, submitting, loading, shouldDisplayBio }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.container} spacing={3}>
        <ProfileAccountInfoFields
          loading={loading}
          shouldDisplayBio={shouldDisplayBio}
        />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6">
            Address
          </Typography>
        </Grid>
        <ProfileLocationFields loading={loading} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classNames(classes.container, classes.buttonContainer)}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={submitting || loading}
          >
            {submitting ? "Saving" : "Save"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
});

export const UserProfile = () => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const [updateProfilePhoto] = useMutation(UPDATE_PROFILE_PHOTO, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    awaitRefetchQueries: true,
    client: currentUserClient
  });
  const { data, loading } = useQuery(CURRENT_USER_QUERY, {
    client: currentUserClient
  });
  const src = get(data, "viewer.me.profilePhoto.url");

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  const asyncAction = async acceptedFiles => {
    try {
      await updateProfilePhoto({
        variables: {
          file: get(acceptedFiles, "[0]")
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Paper className={classes.root}>
      <Grid container className={classes.header} spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6">
            Account Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AvatarDropzone
            src={hover ? null : src}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            icon={hover ? <AddIcon /> : <AccountCircleIcon />}
            uploadingIcon={<LoopIcon />}
            asyncAction={asyncAction}
            loading={loading}
          />
        </Grid>
      </Grid>
      <UserProfileFormController>
        {props => <UserProfileForm {...props} />}
      </UserProfileFormController>
    </Paper>
  );
};
