import { gql } from "@apollo/client";

import { CarFragments } from "./CarFragments";

export const CAR_INFO = gql`
  query CarInfo($id: ID) {
    car(where: { id: $id }) {
      ...CarInfo
    }
  }
  ${CarFragments.carInfo}
`;

export const CAR_PHOTOS = gql`
  query CarPhotos($id: ID) {
    car(where: { id: $id }) {
      id
      photos {
        ...CarPhoto
      }
    }
  }
  ${CarFragments.carPhoto}
`;

export const CAR_SUMMARY = gql`
  query CarSummary($id: ID) {
    car(where: { id: $id }) {
      ...CarInfo
      ...CarDocumentsExpiration
      photos {
        ...CarPhoto
      }
      location {
        ...CarLocation
      }
      documents {
        ...CarDocument
      }
    }
  }
  ${CarFragments.carInfo}
  ${CarFragments.carPhoto}
  ${CarFragments.carLocation}
  ${CarFragments.carDocumentsExpiration}
  ${CarFragments.carDocument}
`;

export const CAR_LISTING_INFO = gql`
  query CarListingInfo($id: ID) {
    car(where: { id: $id }) {
      ...CarListing
      documents {
        ...CarDocument
      }
      location {
        ...CarLocation
      }
    }
  }
  ${CarFragments.carListing}
  ${CarFragments.carDocument}
  ${CarFragments.carLocation}
`;

export const CAR_STATUS = gql`
  query CarStatus($id: ID) {
    car(where: { id: $id }) {
      ...CarStatus
    }
  }
  ${CarFragments.carStatus}
`;

export const CAR_REGISTRATION_INFO = gql`
  query CarRegistrationInfo($id: ID) {
    car(where: { id: $id }) {
      ...CarRegistration
      documents(where: { type: REGISTRATION }) {
        ...CarDocument
      }
    }
  }
  ${CarFragments.carRegistration}
  ${CarFragments.carDocument}
`;

export const CAR_DOCUMENTS_EXPIRATION = gql`
  query CarDocumentsExpiration($id: ID) {
    car(where: { id: $id }) {
      ...CarDocumentsExpiration
    }
  }
  ${CarFragments.carDocumentsExpiration}
`;

export const CAR_DOCUMENTS_INFO = gql`
  query CarDocumentsInfo($id: ID) {
    car(where: { id: $id }) {
      ...CarDocumentsExpiration
      documents {
        ...CarDocument
      }
      photos {
        ...CarPhoto
      }
    }
  }
  ${CarFragments.carDocumentsExpiration}
  ${CarFragments.carDocument}
  ${CarFragments.carPhoto}
`;

export const CAR_DEFAULT_PROTECTION_PLAN = gql`
  query CarDefaultProtectionPlan($id: ID) {
    car(where: { id: $id }) {
      ...CarDefaultProtectionPlan
    }
  }
  ${CarFragments.carDefaultProtectionPlan}
`;

export const OWNER_PROTECTION_MODAL_CARS = gql`
  query RequiresProtectionModal {
    viewer {
      id
      me {
        id
        owner {
          id
          cars(
            where: {
              AND: [
                { verification_in: [VERIFIED, PENDING_VERIFICATION] }
                { status_in: [RENTED, REMOVED, AVAILABLE] }
              ]
            }
          ) {
            edges {
              node {
                ...CarInfo
              }
            }
          }
        }
      }
    }
  }
  ${CarFragments.carInfo}
`;

export const GET_MEDIAN_DAILY_PRICE = gql`
  query GetMedianDailyPrice($zip: String!) {
    getMedianDailyPrice(zip: $zip)
  }
`;
