import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import WarningIcon from "@mui/icons-material/Warning";

import { ActionDialog } from "Components/Dialog/ActionDialog";

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: "flex",
    alignItems: "center"
  },
  confirmActionButton: {
    fontWeight: 600
  },
  warningIcon: {
    marginRight: theme.spacing(3)
  },
  content: {
    marginLeft: theme.spacing(7.2)
  }
}));

export const RemoveListingDialog = ({
  displayDeleteListingDialog,
  setDisplayDeleteListingDialog,
  submit,
  submitting
}) => {
  const classes = useStyles();

  return (
    <ActionDialog
      open={displayDeleteListingDialog}
      title={
        <div className={classes.titleContainer}>
          <WarningIcon
            fontSize="large"
            color="error"
            className={classes.warningIcon}
          />
          <div>This action cannot be undone</div>
        </div>
      }
      content={
        <Typography component="p" className={classes.content}>
          {`This will permanently delete the vehicle record in HyreCar. Your rental records will still be available. If things change, you can always re-list this car in the future.`}
        </Typography>
      }
      actionLabel="Delete my car"
      action={submit}
      actionClass={classes.confirmActionButton}
      cancel={() => {
        setDisplayDeleteListingDialog(false);
      }}
      cancelLabel={"Cancel"}
      cancelVariant={"string"}
      disabled={submitting}
      noTextTransform={true}
    />
  );
};
