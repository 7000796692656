import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import makeStyles from "@mui/styles/makeStyles";
import { Field, reduxForm } from "redux-form";

import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { compose } from "recompose";
import { connect } from "react-redux";
import CarPhotosExample from "./CarPhotosExample";
import { ImagesEditor } from "Components/Inputs/ImagesEditor";

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  divider: { marginBottom: theme.spacing(2), marginTop: theme.spacing(3) }
}));

export const ImagesForm = compose(
  connect(state => ({
    initialValues: state.fleet.listing.initialFormValues
  })),
  reduxForm({
    form: "LIST_CAR_FLOW",
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: values => {
      const errors = {};

      if (!values.photos) {
        errors.photos = "Please provide at least one photo of your car.";
      } else {
        if (Array.isArray(values.photos) && values.photos.length === 0) {
          errors.photos = "Please provide at least one photo of your car.";
        }
      }

      return errors;
    }
  })
)(({ handleSubmit, previousStep }) => {
  const classes = useStyles();

  return (
    <FlowContentContainer
      header="Upload Car Photos"
      nextStepLabel="Continue"
      nextStepId="continue-photos"
      previousStepId="back-photos"
      nextStep={handleSubmit}
      previousStep={previousStep}
    >
      <CarPhotosExample />
      <Divider className={classes.divider} />
      <Typography
        component="p"
        variant="subtitle1"
        className={classes.subtitle}
      >
        Click an image to edit. Drag and drop to rearrange order. We accept .jpg
        or .png files up to 3MB.
      </Typography>
      <Field id="add-photos-button" name="photos" component={ImagesEditor} />
    </FlowContentContainer>
  );
});
