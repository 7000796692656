import { gql } from "@apollo/client";

import { ClaimFragments } from "./ClaimFragments";

export const SUBMIT_DAMAGE_CLAIM = gql`
  mutation SubmitDamageClaim($data: DamageClaimInput!) {
    submitDamageClaim(input: $data) {
      success
      message
      claim {
        ...ClaimInfo
      }
    }
  }
  ${ClaimFragments.claimInfo}
`;

export const SUBMIT_INCIDENTAL_CLAIM = gql`
  mutation SubmitIncidentalClaim($data: IncidentalClaimInput!) {
    submitIncidentalClaim(input: $data) {
      success
      message
      claim {
        ...ClaimInfo
      }
    }
  }
  ${ClaimFragments.claimInfo}
`;
