import localforage from "localforage";
import moment from "moment";

localforage.config({
  name: "HC App"
});

export const saveFormProgress = async (userId, values, step) => {
  const inspectionExpirationAt = values?.inspectionExpirationAt?.toDate();
  const registrationExpirationAt = values?.registrationExpirationAt?.toDate();
  const insuranceExpirationAt = values?.insuranceExpirationAt?.toDate();

  localforage.setItem(userId, {
    ...values,
    inspectionExpirationAt,
    registrationExpirationAt,
    insuranceExpirationAt,
    step
  });
};

export const restoreFormProgress = async userId => {
  const storedValues = await localforage.getItem(userId);

  if (!storedValues) return null;

  const inspectionExpirationAt = storedValues?.inspectionExpirationAt
    ? moment(storedValues.inspectionExpirationAt)
    : null;
  const registrationExpirationAt = storedValues?.registrationExpirationAt
    ? moment(storedValues.registrationExpirationAt)
    : null;
  const insuranceExpirationAt = storedValues?.registrationExpirationAt
    ? moment(storedValues.insuranceExpirationAt)
    : null;

  return {
    ...storedValues,
    inspectionExpirationAt,
    registrationExpirationAt,
    insuranceExpirationAt
  };
};

export const clearFormProgress = async userId =>
  await localforage.removeItem(userId);
