import React from "react";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1
  },
  icon: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)
  }
}));

export const MarketplaceHeader = ({ history }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed">
      <ToolBar>
        <IconButton
          className={classes.icon}
          onClick={() => history.push("/fleet/dashboard/resources")}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography component="h1" variant="h6" className={classes.flexGrow}>
          Compare Pricing
        </Typography>
      </ToolBar>
    </AppBar>
  );
};

MarketplaceHeader.propTypes = {
  search: PropTypes.string,
  onSearchChange: PropTypes.func
};
