import { gql } from "@apollo/client";

import { CarFragments } from "../../Queries/Car/CarFragments";

export const CarMutationFragments = {
  updateCarListing: gql`
    fragment UpdateCarListing on UpdateCarPayload {
      code
      message
      success
      car {
        ...CarListing
      }
    }
    ${CarFragments.carListing}
  `,
  toggleListingPayload: gql`
    fragment ToggleListingPayLoad on ToggleListingPayload {
      code
      success
      message
      car {
        ...CarStatus
      }
    }
    ${CarFragments.carStatus}
  `,
  uploadCarDocumentPayload: gql`
    fragment UploadCarDocumentPayload on UploadCarDocumentPayload {
      code
      message
      success
      carDocument {
        id
        file {
          id
          type
          url
          thumbnailUrl
          path
        }
      }
    }
  `,
  fleetUpdateCarsPayload: gql`
    fragment FleetUpdateCarsPayload on FleetUpdateCarsPayload {
      cars {
        ...CarInfo
      }
    }
    ${CarFragments.carInfo}
  `,
  updateCarMileage: gql`
    fragment UpdateCarMileage on UpdateCarPayload {
      code
      success
      message
      car {
        id
        mileage
        status
      }
    }
  `,
  statusChangeResponse: gql`
    fragment StatusChangeResponse on StatusChangeResponse {
      code
      success
      message
      carStatusInfo {
        id
        ownerId
        status
        verification
      }
    }
  `
};
