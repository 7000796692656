import get from "lodash/get";
import find from "lodash/find";

export const checkPermission = (permission, scopes) => {
  const scope = get(scopes, permission);
  return scope !== undefined && scope !== false;
};

export const checkAllPermissions = (permissions, scopes) => {
  const checked = permissions.map(permission =>
    checkPermission(permission, scopes)
  );
  const hasPermission = checked.reduce((acc, curr) => {
    if (!acc) curr = false;
    return curr;
  }, true);
  return hasPermission;
};

export const hasRole = (passedRole, roles) =>
  !!find(roles, role => role === passedRole);

export const renderByPermissions = (
  component,
  permissions,
  scopes,
  defaultComponent = null
) => {
  if (Array.isArray(permissions)) {
    return checkAllPermissions(permissions, scopes)
      ? component
      : defaultComponent;
  } else {
    throw new Error("renderByPermissions: permissions must be an array.");
  }
};
