import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 0)
  },
  border: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`
  },
  image: {
    width: "100%",
    height: props => (props.withCaption ? "160px" : "120px"),
    display: "block",
    objectFit: "cover"
  }
}));

export const DrawerArrayImageHolder = ({ images, border, withCaption }) => {
  const classes = useStyles({ withCaption });

  return (
    <div
      className={classNames(classes.root, {
        [classes.border]: border
      })}
    >
      <Grid container spacing={2}>
        {images.map(image => (
          <Grid key={`${image.createdAt}`} item xs={withCaption ? 6 : 4}>
            <img src={image.url} alt={image.alt} className={classes.image} />
            {withCaption && (
              <Typography variant="body1" component="p">
                {`${moment(image.createdAt).format("MMM DD, YYYY")} by ${
                  image.by
                }`}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

DrawerArrayImageHolder.propTypes = {
  border: PropTypes.bool,
  withCaption: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.object).isRequired
};
