import { gql } from "@apollo/client";

export const APPLIED_RENTALS_AGGREGATE = gql`
  query AppliedRentalsAggregate {
    viewer {
      id
      me {
        id
        owner {
          id
          rentalsConnection(filter: { status: APPLIED }) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;

export const LATE_RENTALS_AGGREGATE = gql`
  query LateRentalsAggregate {
    viewer {
      id
      me {
        id
        owner {
          id
          rentalsConnection(filter: { status: LATE }) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;
