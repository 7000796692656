import baseVariables from "./base_styled-theme";
const variables = require("!!sass-variables-loader!./partner_variables.scss");

export default {
  ...baseVariables,
  ...Object.keys(variables).reduce(function(result, key) {
    const newResult = { ...result };
    newResult[key] = variables[key].replace("!default", "").trim();
    return newResult;
  }, {})
};
