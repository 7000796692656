import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import MessageIcon from "@mui/icons-material/Message";

import { RouteEnum } from "Enums/RouteEnum";
import { asyncStartChannel } from "Redux/SendBird/ActionCreators";
import { openSupportPage } from "Utils/openSupportPage";
import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { RentalStatusEnum } from "Enums/StateEnums";
import { DrawerAction } from "Components/Drawers/DrawerAction";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { checkPermission } from "Utils/RooftopUtils";
import { InboxDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";

export const RentalDrawerActions = connect(null, {
  asyncStartChannel
})(({ asyncStartChannel }) => {
  const history = useHistory();
  const { id } = useParams();
  const { currentRooftopClient, scopes } = useContext(ClientFactoryContext);
  const {
    data: { rental }
  } = useQuery(RENTAL_REQUEST_QUERY, {
    variables: {
      id
    },
    client: currentRooftopClient
  });

  const handleOnClick = async () => {
    await asyncStartChannel(rental.driver.id);

    history.push(
      checkPermission(InboxDomainPermissionPaths.viewMultiUserInbox, scopes)
        ? RouteEnum.inboxMine
        : RouteEnum.inbox
    );
  };

  return (
    <Grid container>
      {[
        RentalStatusEnum.pendingPickup,
        RentalStatusEnum.pendingInsurance,
        RentalStatusEnum.active,
        RentalStatusEnum.late
      ].includes(rental.status) ? (
        <>
          <DrawerAction
            type="button"
            icon={<MessageIcon />}
            text="Message Driver"
            rightBorder
            xs={6}
            onClick={handleOnClick}
          />
          <DrawerAction
            onClick={() => openSupportPage()}
            icon={<HelpIcon />}
            text="Help"
            xs={6}
          />
        </>
      ) : (
        <>
          <DrawerAction
            type="button"
            icon={<MessageIcon />}
            text="Message Driver"
            rightBorder
            xs={6}
            onClick={handleOnClick}
          />
          <DrawerAction
            onClick={() => openSupportPage()}
            icon={<HelpIcon />}
            text="Help"
            xs={6}
          />
        </>
      )}
    </Grid>
  );
});
