import get from "lodash/get";

export const fullStoryPlugin = () => ({
  name: "full-story",
  identify: ({ payload }) => {
    const { userId, traits } = payload;

    window.FS.identify(userId, {
      displayName: `${get(traits, "firstName")}${
        traits.lastName ? ` ${traits.lastName.slice(0, 1)}` : ""
      }`,
      status_str: get(traits, "status"),
      driverID_str: get(traits, "driver.id"),
      ownerID_str: get(traits, "owner.id"),
      userType_str: get(traits, "type"),
      environment_str: process.env.REACT_APP_STAGE
    });
  }
});
