import React, { Fragment, useEffect, useState, useContext } from "react";
import jwtDecode from "jwt-decode";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from "@apollo/client";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { UserProfile } from "Fleet/Dashboard/DashboardViews/Settings/Profile/UserProfile";
import { SettingsACHController } from "Fleet/Dashboard/DashboardViews/Settings/ACH/SettingsACHForm";
import { TeamTab } from "Fleet/Dashboard/DashboardViews/Settings/Team/TeamTab";
import { InboxSettings } from "Fleet/Dashboard/DashboardViews/Settings/Inbox/InboxSettings";
import { RooftopSettingsController } from "Fleet/Dashboard/DashboardViews/Settings/Rooftops/RooftopSettings";
import { RouteEnum } from "Enums/RouteEnum";
import { DashboardLayout } from "Components/Layouts/DashboardLayout";
import { TabsWithRoutes } from "Components/Tabs/TabsWithRoutes";
import { enableHeader, disableHeader } from "Redux/Header/ActionCreators";
import { MobileHeader } from "Components/Header/MobileHeader";
import { RouterTab } from "Components/Tabs/RouterTab";
import {
  RooftopDomainPermissionPaths,
  PaymentDomainPermissionPaths
} from "Components/Utils/Permissions/PermissionsPaths";
import { RouteWithPermission } from "Components/Routers/RouteWithPermission";
import { checkAllPermissions, renderByPermissions } from "Utils/RooftopUtils";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { CURRENT_PAYOUT_ACCOUNT_QUERY } from "Queries/User/UserQueries";
import { PayoutConnectContext } from "Components/Utils/PayoutConnectProvider";

const useStyles = makeStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.common.white
  },
  achUpdateBadgeIcon: {
    position: "absolute"
  },
  achBadgeMobile: {
    position: "absolute",
    width: "0.6em",
    height: "0.6em",
    marginRight: "1.2em"
  }
}));

export const DashboardSettings = connect(null, {
  enableHeader,
  disableHeader
})(({ enableHeader, disableHeader }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [activeTabValue, setActiveTabValue] = useState(pathname);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { currentRooftopToken, scopes } = useContext(ClientFactoryContext);
  const { requiresPayoutUpdate } = useContext(PayoutConnectContext);
  const currentRooftopId = currentRooftopToken
    ? jwtDecode(currentRooftopToken).id
    : "";

  const { data: payoutData, refetch } = useQuery(CURRENT_PAYOUT_ACCOUNT_QUERY, {
    context: {
      apiv2: true,
      headers: {
        "x-rooftop-authorization": currentRooftopToken
      }
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    const onP2PACH = pathname === RouteEnum.settingsPayment;
    const onRooftopACH = pathname.includes(RouteEnum.settingsRooftopsEdit);
    setActiveTabValue(pathname);
    if (currentRooftopId && (onP2PACH || onRooftopACH)) {
      refetch();
    }
  }, [pathname, currentRooftopId, refetch]);

  useEffect(() => {
    if (matches) {
      disableHeader();
    }
    return enableHeader;
  }, [matches, enableHeader, disableHeader]);

  return (
    <Fragment>
      {matches ? (
        <MobileHeader
          title="Settings"
          tabs={
            <Tabs
              variant="fullWidth"
              value={activeTabValue}
              textColor="inherit"
              classes={{ indicator: classes.indicator }}
            >
              <RouterTab
                label="Profile"
                to={RouteEnum.settingsProfile}
                value={RouteEnum.settingsProfile}
              />
              {renderByPermissions(
                <RouterTab
                  label="ACH"
                  to={RouteEnum.settingsPayment}
                  value={RouteEnum.settingsPayment}
                  badge={
                    requiresPayoutUpdate
                      ? {
                          content: (
                            <ErrorOutlineOutlinedIcon
                              color="error"
                              className={classes.achBadgeMobile}
                            />
                          )
                        }
                      : null
                  }
                />,
                [PaymentDomainPermissionPaths.viewACH],
                scopes
              )}
              {renderByPermissions(
                <RouterTab
                  label="Rooftop"
                  to={`${RouteEnum.settingsRooftopsEdit}/${currentRooftopId}`}
                  value={`${RouteEnum.settingsRooftopsEdit}/${currentRooftopId}`}
                  badge={
                    requiresPayoutUpdate
                      ? {
                          content: (
                            <ErrorOutlineOutlinedIcon
                              color="error"
                              className={classes.achBadgeMobile}
                            />
                          )
                        }
                      : null
                  }
                />,
                [RooftopDomainPermissionPaths.viewRooftop],
                scopes
              )}
              {renderByPermissions(
                <RouterTab
                  label="Team"
                  to={RouteEnum.settingsTeam}
                  value={RouteEnum.settingsTeam}
                />,
                [RooftopDomainPermissionPaths.viewTeam],
                scopes
              )}
              <RouterTab
                label="Inbox"
                to={RouteEnum.settingsInbox}
                value={RouteEnum.settingsInbox}
              />
            </Tabs>
          }
        />
      ) : (
        <>
          {/* leaving commented for v2 */}
          {/* {!pathname.includes(RouteEnum.settingsRooftopsEdit) && ( */}
          <TabsWithRoutes
            paths={[
              {
                to: RouteEnum.settingsProfile,
                label: "Profile"
              },
              {
                to: RouteEnum.settingsPayment,
                label: (
                  <div>
                    ACH
                    {requiresPayoutUpdate && (
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        className={classes.achUpdateBadgeIcon}
                      />
                    )}
                  </div>
                ),
                disabled: !checkAllPermissions(
                  [PaymentDomainPermissionPaths.viewACH],
                  scopes
                )
              },
              {
                to: `${RouteEnum.settingsRooftopsEdit}/${currentRooftopId}`,
                label: (
                  <div>
                    Rooftop
                    {requiresPayoutUpdate && (
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        className={classes.achUpdateBadgeIcon}
                      />
                    )}
                  </div>
                ),
                disabled: !checkAllPermissions(
                  [RooftopDomainPermissionPaths.viewRooftop],
                  scopes
                )
              },
              {
                to: RouteEnum.settingsTeam,
                label: "Team",
                disabled: !checkAllPermissions(
                  [RooftopDomainPermissionPaths.viewTeam],
                  scopes
                )
              },
              {
                to: RouteEnum.settingsInbox,
                label: "Inbox"
              }
            ]}
          />
          {/* )} */}
        </>
      )}
      <DashboardLayout fixed fullScreen={matches} hasAppBar={matches}>
        <Route
          exact
          path={RouteEnum.settings}
          render={() => <Redirect to={RouteEnum.settingsProfile} />}
        />
        <Switch>
          <Route
            exact
            path={RouteEnum.settingsProfile}
            component={UserProfile}
          />
          <RouteWithPermission
            exact
            path={RouteEnum.settingsPayment}
            requiredPermissions={[PaymentDomainPermissionPaths.viewACH]}
            actionRoute={RouteEnum.settingsProfile}
            render={props => (
              <SettingsACHController
                {...props}
                payoutData={payoutData}
                refetch={refetch}
              />
            )}
          />
          <RouteWithPermission
            path={RouteEnum.settingsRooftopsEdit}
            render={props => (
              <RooftopSettingsController
                {...props}
                payoutData={payoutData}
                refetch={refetch}
              />
            )}
            requiredPermissions={[RooftopDomainPermissionPaths.viewRooftop]}
            actionRoute={RouteEnum.settingsProfile}
          />
          <RouteWithPermission
            path={RouteEnum.settingsTeam}
            component={TeamTab}
            requiredPermissions={[RooftopDomainPermissionPaths.viewTeam]}
            actionRoute={RouteEnum.settingsProfile}
          />
          <Route
            exact
            path={RouteEnum.settingsInbox}
            render={props => <InboxSettings {...props} />}
          />
        </Switch>
      </DashboardLayout>
    </Fragment>
  );
});
