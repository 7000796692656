import { gql } from "@apollo/client";

import { CarMutationFragments } from "./CarMutationFragments";

export const SOFT_DELETE_LISTING = gql`
  mutation softDeleteListing($carId: String!) {
    softDeleteListing(carId: $carId) {
      ...StatusChangeResponse
    }
  }
  ${CarMutationFragments.statusChangeResponse}
`;

export const SNOOZE_LISTING = gql`
  mutation snoozeListing($carId: String!) {
    snoozeListing(carId: $carId) {
      ...StatusChangeResponse
    }
  }
  ${CarMutationFragments.statusChangeResponse}
`;

export const ACTIVATE_LISTING = gql`
  mutation activateListing($carId: String!) {
    activateListing(carId: $carId) {
      ...StatusChangeResponse
    }
  }
  ${CarMutationFragments.statusChangeResponse}
`;

export const FLEET_UPDATE_CARS = gql`
  mutation FleetUpdateCars($data: [FleetUpdateCarInput!]!) {
    fleetUpdateCars(data: $data) {
      cars {
        ...FleetUpdateCarsPayload
      }
    }
  }
  ${CarMutationFragments.fleetUpdateCarsPayload}
`;

export const UPDATE_CAR_MILEAGE = gql`
  mutation UpdateCarMileage($carId: ID!, $mileage: Int) {
    updateCarDetails(data: { carId: $carId, mileage: $mileage }) {
      ...UpdateCarMileage
    }
  }
  ${CarMutationFragments.updateCarMileage}
`;

export const UPDATE_CAR_DETAILS = gql`
  mutation UpdateCarDetails($data: UpdateCarDetailsInput!) {
    updateCarDetails(data: $data) {
      ...UpdateCarListing
    }
  }
  ${CarMutationFragments.updateCarListing}
`;

export const TOGGLE_LISTING = gql`
  mutation ToggleListing($carId: ID!) {
    toggleListing(data: { carId: $carId }) {
      ...ToggleListingPayLoad
    }
  }
  ${CarMutationFragments.toggleListingPayload}
`;

export const UPLOAD_CAR_DOCUMENT = gql`
  mutation UploadCarDocument(
    $carId: ID!
    $file: Upload!
    $type: CarDocumentTypeEnum!
  ) {
    uploadCarDocument(data: { carId: $carId, file: $file, type: $type }) {
      ...UploadCarDocumentPayload
    }
  }
  ${CarMutationFragments.uploadCarDocumentPayload}
`;
