import { Fragment } from "react";
import React from "react";
import Dialog from "@mui/material/Dialog";
import { connect } from "react-redux";
import get from "lodash/get";

import { InboxChannels } from "Components/Inbox/InboxChannels";
import { InboxChat } from "Components/Inbox/InboxChat";
import { selectChannel, deselectChannel } from "Redux/SendBird/ActionCreators";

export const MobileInbox = connect(
  state => ({
    selectedChannel: get(state, "fleet.sendbird.selectedChannel", null)
  }),
  { selectChannel, deselectChannel }
)(({ selectedChannel, selectChannel, deselectChannel, filterByAssignedTo }) => {
  const handleSelectChannel = channel => {
    selectChannel(channel);
  };

  const handleDeselectChannel = () => {
    deselectChannel();
  };

  return (
    <Fragment>
      {selectedChannel ? (
        <Dialog open onClose={handleDeselectChannel} fullScreen>
          <div style={{ maxHeight: "100%" }}>
            <InboxChat onCloseChat={handleDeselectChannel} mobile />
          </div>
        </Dialog>
      ) : (
        <InboxChannels
          onClick={handleSelectChannel}
          filterByAssignedTo={filterByAssignedTo}
        />
      )}
    </Fragment>
  );
});
