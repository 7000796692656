import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: props => (props.justify ? props.justify : "flex-start")
  },
  icon: {
    height: theme.spacing(1.75),
    width: theme.spacing(1.75),
    cursor: "pointer"
  },
  title: {
    marginRight: theme.spacing(0.5)
  }
}));

export const TitleWithTooltipIcon = ({ title, onClick, color, justify }) => {
  const classes = useStyles({ justify });
  return (
    <div className={classes.root}>
      <span className={classes.title}>{title}</span>
      <HelpOutlineIcon
        className={classes.icon}
        color={color}
        onClick={onClick}
      />
    </div>
  );
};

TitleWithTooltipIcon.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string
};
