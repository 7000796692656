/* eslint-disable react/jsx-key */
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  tablePlaceholderContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  tablePlaceholderHeader: {
    color: "#222222",
    fontSize: "34px",
    lineHeight: "42px",
    margin: "16px 0 8px 0"
  },
  tablePlaceholderSubHeader: {
    color: "#666666",
    fontSize: "20px",
    lineHeight: "30px",
    maxWidth: "370px",
    textAlign: "center"
  },
  tablePlaceholderSubHeaderBottom: {
    color: "#666666",
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "40px",
    maxWidth: "370px",
    textAlign: "center"
  }
}));

const TablePlaceholder = ({
  SourceImageComponent,
  header,
  subHeader,
  subHeaderBottom,
  ActionComponent
}) => {
  const classes = useStyles();
  return (
    <div className={classes.tablePlaceholderContainer}>
      {SourceImageComponent && <SourceImageComponent />}
      {header && <p className={classes.tablePlaceholderHeader}>{header}</p>}
      {subHeader && (
        <span className={classes.tablePlaceholderSubHeader}>{subHeader}</span>
      )}
      {subHeaderBottom && (
        <span className={classes.tablePlaceholderSubHeaderBottom}>
          {subHeaderBottom}
        </span>
      )}
      {ActionComponent && <ActionComponent />}
    </div>
  );
};

export default TablePlaceholder;
