import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Rating from "@mui/material/Rating";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";

import { openSupportPage } from "Utils/openSupportPage";
import { RATE_DRIVER } from "Mutations/Driver/DriverMutations";
import { RENTAL_REQUEST_QUERY } from "Queries/Rentals/RentalQueries";
import { DrawerPaddingContainer } from "Components/Drawers/DrawerPaddingContainer";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import { ClaimEnum } from "Enums/StateEnums";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(5)
  },
  rating: {
    marginBottom: theme.spacing(2)
  }
}));

export const RateDriver = ({ claim, onCancel }) => {
  const classes = useStyles();
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [rateDriver] = useMutation(RATE_DRIVER, {
    client: currentRooftopClient
  });
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { data } = useQuery(RENTAL_REQUEST_QUERY, {
    variables: {
      id
    },
    client: currentRooftopClient,
    fetchPolicy: "cache-only"
  });

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      await rateDriver({
        variables: {
          rentalId: id,
          rating: rating,
          comment: comment
        }
      });
      setSubmitting(false);
      enqueueSnackbar("Rated Driver!", {
        variant: "success"
      });
      onCancel();
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Sorry, something went wrong. Please try again.", {
        variant: "error"
      });
      setSubmitting(false);
    }
  };

  return (
    <>
      <DrawerPaddingContainer>
        <Typography
          component="h5"
          variant="h5"
          gutterBottom
          className={classes.header}
        >
          Thanks for renting with HyreCar!
        </Typography>
        {claim && (
          <>
            {claim === ClaimEnum.unlisted && (
              <Typography component="h5" variant="h5" gutterBottom>
                Your listing has been temporarily removed from the marketplace.
              </Typography>
            )}
            {claim === ClaimEnum.claim && (
              <Typography component="h5" variant="h5" gutterBottom>
                We opened a claim.
              </Typography>
            )}
            <Typography component="p" variant="body1" gutterBottom>
              Based on the damage you reported, we have opened a claim and
              removed your car from the marketplace. You can check on the status
              and add additional details in the{" "}
              <Link onClick={() => openSupportPage()} underline="hover">
                Help Center
              </Link>
              .
            </Typography>
          </>
        )}
      </DrawerPaddingContainer>
      <Divider />
      <DrawerPaddingContainer>
        <Typography component="h6" variant="h6" gutterBottom>
          {`Rate ${get(data, "rental.driver.user.firstName", "")} ${
            get(data, "rental.driver.user.lastName", "")[0]
          }.`}
        </Typography>
        <Rating
          name="driverRating"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          className={classes.rating}
        />
        <TextField
          fullWidth
          label={`How was your experience with ${get(
            data,
            "rental.driver.user.firstName",
            ""
          )} ${get(data, "rental.driver.user.lastName", "")[0]}.`}
          placeholder="Were they quick to respond? Did they return the car in good condition?"
          multiline
          rows="4"
          margin="normal"
          variant="filled"
          onChange={e => setComment(e.target.value)}
        />
      </DrawerPaddingContainer>
      <DrawerBottomBar
        onCancel={onCancel}
        cancelText="Cancel"
        onSubmit={onSubmit}
        submitText={submitting ? "Submitting" : "Done"}
        disabled={submitting}
      />
    </>
  );
};
