import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: "535px",
    minHeight: "535px",
    overflowY: "scroll",
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    [theme.breakpoints.down("md")]: {
      maxHeight: "unset",
      minHeight: "unset",
      overflowY: "unset"
    }
  }
}));

export const ScrollingContainer = ({ children, position = 0 }) => {
  const scrollRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: position,
        behavior: "smooth"
      });
    }
  }, [position]);

  return (
    <div className={classes.root} ref={scrollRef}>
      {children}
    </div>
  );
};

ScrollingContainer.propTypes = {
  children: PropTypes.node,
  position: PropTypes.number
};
