import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3, 2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  image: {
    width: "100%",
    height: "120px",
    objectFit: "cover"
  },
  actionContainer: {
    marginTop: theme.spacing(2)
  }
}));

export const DrawerInfoCard = ({
  overline,
  title,
  subtitle,
  info,
  imageUrl,
  alt,
  marginBottom,
  action
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, {
        [classes.marginBottom]: marginBottom
      })}
    >
      <Grid container spacing={3}>
        <Grid item xs={8}>
          {overline && (
            <Typography variant="overline" gutterBottom>
              {overline}
            </Typography>
          )}
          {title && (
            <Typography component="h5" variant="h5" gutterBottom>
              {title}
            </Typography>
          )}
          {subtitle && <Typography variant="body2">{subtitle}</Typography>}
          {info && <Typography variant="body2">{info}</Typography>}
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end">
          <img src={imageUrl} alt={alt} className={classes.image} />
        </Grid>
      </Grid>
      {action && <div className={classes.actionContainer}>{action}</div>}
    </div>
  );
};

DrawerInfoCard.propTypes = {
  overline: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
  info: PropTypes.node,
  marginBottom: PropTypes.bool,
  action: PropTypes.node
};
