import { useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { object, number, array, string, date } from "yup";
import { change, destroy } from "redux-form";
import has from "lodash/has";
import set from "lodash/set";
import get from "lodash/get";
import merge from "lodash/merge";
import capitalize from "lodash/capitalize";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import {
  dollarsToCents,
  calculateWeeklyPriceInCentsWithDiscount,
  calculateMonthlyPriceInCentsWithDiscount
} from "Utils/Calculations";
import { ProtectionPlanEnum } from "Enums/StateEnums";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { carColors as colors } from "Variables/CarInfoVariables";
import { RouteEnum } from "Enums/RouteEnum";
import { OWNER_LIST_CAR } from "Mutations/Owner/OwnerMutations";
import { toPascalCaseWithSingleDelimiter } from "Driver/utils/helpers";
import { firstAvailableYear } from "Variables/CarInfoVariables";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { analytics } from "Analytics/index";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { asyncCarInfoValidation } from "../../../../Fleet/Flows/Manual/DetailsHC4B/validate";
import { getCarInfoFromVin } from "../../../../Utils/getCarInfoFromVin";

export const useCSVFormController = dispatch => {
  const [loading, setLoading] = useState(false);
  const [totalCars, setTotalCars] = useState(0);
  const { currentRooftopClient, currentRooftopToken } = useContext(
    ClientFactoryContext
  );
  const [listedCarsProgress, setListedCarsProgress] = useState(0);
  const [ownerListCar] = useMutation(OWNER_LIST_CAR, {
    client: currentRooftopClient
  });
  const { data } = useQuery(CURRENT_USER_QUERY, {
    client: currentRooftopClient
  });

  const history = useHistory();

  const pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 1);

  const [listCarResponses, updateListCarResponses] = useState([]);

  const initialValues = {
    defaultProtectionPlan: ProtectionPlanEnum.premium_plus,
    daily_rate: 35,
    weekly_discount: 5,
    monthly_discount: 10,
    maxDailyMiles: "1000",
    cars: [],
    documents: {}
  };

  async function* asyncGenerator(values) {
    for (let i = 0; i < values.cars.length; i++) {
      setListedCarsProgress(i + 1);
      try {
        const listCarResponse = await ownerListCar({
          variables: {
            input: {
              city:
                values.cars[i].description === "failme"
                  ? null
                  : values.address.city,
              state: values.address.state,
              street: values.address.street,
              zip: values.address.zip,
              pickupLat: values.address.lat,
              pickupLng: values.address.lng,
              dailyPriceInCents: dollarsToCents(parseFloat(values.daily_rate)),
              weeklyPriceInCents: calculateWeeklyPriceInCentsWithDiscount(
                dollarsToCents(parseFloat(values.daily_rate)),
                parseFloat(values.weekly_discount)
              ),
              monthlyPriceInCents: calculateMonthlyPriceInCentsWithDiscount(
                dollarsToCents(parseFloat(values.daily_rate)),
                parseFloat(values.monthly_discount)
              ),
              maxDailyMiles: values.maxDailyMiles,
              defaultProtectionPlan: values.defaultProtectionPlan,
              ...mapCarInputToCarDetails(values.cars[i])
            }
          }
        });
        if (!get(listCarResponse, "data.listCar.success", false)) {
          yield [
            values.cars[i].vin,
            values.cars[i].odometer,
            values.cars[i].color,
            values.cars[i].plate,
            values.cars[i].description,
            get(listCarResponse, "data.listCar.message", "No error specified")
          ];
        } else {
          updateListCarResponses(arr => [...arr, listCarResponse]);
        }
      } catch (e) {
        console.error(e);
        yield [
          values.cars[i].vin,
          values.cars[i].odometer,
          values.cars[i].color,
          values.cars[i].plate,
          values.cars[i].description,
          e.message ? e.message : "No error specified"
        ];
        continue;
      }
    }
  }

  const onSubmit = async values => {
    setTotalCars(values.cars.length);
    setLoading(true);
    const failedCarsArray = [];
    for await (const res of asyncGenerator(values)) {
      failedCarsArray.push(res);
    }
    setLoading(false);
    dispatch(destroy("CSV_IMPORT"));
    const succesfullyImportedAmt = values.cars.length - failedCarsArray.length;
    let listingsWithPhoto = 0;
    let listingsWithInspection = 0;
    let listingsWithInsurance = 0;
    let listingsWithRegistration = 0;
    values.cars.forEach(car => {
      if (car.photos && car.photos.length) {
        listingsWithPhoto++;
      }
      if (car.documents) {
        if (
          car.documents.inspection &&
          car.documents.inspection.photo &&
          car.documents.inspection.photo.length
        ) {
          listingsWithInspection++;
        }
        if (
          car.documents.insurance &&
          car.documents.insurance.photo &&
          car.documents.insurance.photo.length
        ) {
          listingsWithInsurance++;
        }
        if (
          car.documents.registration &&
          car.documents.registration.photo &&
          car.documents.registration.photo.length
        ) {
          listingsWithRegistration++;
        }
      }
    });
    analytics.track(AnalyticsEvents.label.owner.bulkUploadDocuments, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.buttonClicked,
      label: AnalyticsEvents.label.owner.bulkUploadDocuments,
      property: JSON.stringify({
        listingsWithPhoto: listingsWithPhoto,
        listingsWithInspection: listingsWithInspection,
        listingsWithInsurance: listingsWithInsurance,
        listingsWithRegistration: listingsWithRegistration,
        rooftopId: get(jwtDecode(currentRooftopToken), "id")
      }),
      value: "",
      context: {
        user: get(data, "viewer.me", {})
      }
    });
    if (succesfullyImportedAmt > 0) {
      analytics.track(AnalyticsEvents.label.owner.bulkUploadSuccess, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.owner.bulkUploadSuccess,
        property: JSON.stringify({
          amountListed: succesfullyImportedAmt,
          rooftopId: get(jwtDecode(currentRooftopToken), "id")
        }),
        value: "",
        context: {
          car: get(listCarResponses, "[0].data.listCar.car", {}),
          user: get(data, "viewer.me", {})
        }
      });
      updateListCarResponses([]);
    }
    if (failedCarsArray.length) {
      analytics.track(AnalyticsEvents.label.owner.bulkUploadFail, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.buttonClicked,
        label: AnalyticsEvents.label.owner.bulkUploadFail,
        property: JSON.stringify({
          amountFailed: failedCarsArray.length,
          rooftopId: get(jwtDecode(currentRooftopToken), "id")
        }),
        value: "",
        context: ""
      });
    }
    history.push(RouteEnum.listingsProgress, {
      failedCSVListings: failedCarsArray,
      totalCSVCarCount: values.cars.length,
      listedCSV: true
    });
  };

  const validate = values => {
    const errors = {};

    const schema = object().shape({
      daily_rate: number()
        .typeError("Required")
        .min(20, "Must be at least $20 per day.")
        .max(150, "The maximum daily listing price is $150."),
      weekly_discount: number()
        .typeError("Required")
        .min(0, "Must be at least 0.")
        .max(100, "Must be less than 100."),
      monthly_discount: number()
        .typeError("Required")
        .min(0, "Must be at least 0.")
        .max(100, "Must be less than 100."),
      maxDailyMiles: number()
        .typeError("Required")
        .min(250, "Must be at least 250 miles per day"),
      cars: array()
        .of(
          object().shape({
            odometer: number()
              .typeError("Odometer must be a number.")
              .required("Required")
              .min(0, "Odometer must not be negative."),
            color: string()
              .lowercase()
              .required("Color is a required field.")
              .oneOf(
                colors.map(color => color.toLowerCase()),
                `Must be one of these colors ${colors
                  .map(capitalize)
                  .join(", ")}`
              ),
            documents: object().shape({
              registration: object().shape(
                {
                  photo: array().when("expiration", {
                    is: expiration => expiration,
                    then: array().required(),
                    otherwise: array()
                      .nullable()
                      .notRequired()
                  }),
                  expiration: date().when("photo", {
                    is: photo => photo && photo.length !== 0,
                    then: date()
                      .typeError("Date format must be MM/DD/YYYY")
                      .min(pastDate, "Expiration Date cannot be in the past")
                      .required("Expiration date is required"),
                    otherwise: date()
                      .typeError("Date format must be MM/DD/YYYY")
                      .min(pastDate, "Expiration Date cannot be in the past")
                      .nullable()
                      .notRequired()
                  })
                },
                ["photo", "expiration"]
              ),
              insurance: object().shape(
                {
                  photo: array().when("expiration", {
                    is: expiration => expiration,
                    then: array().required(),
                    otherwise: array()
                      .nullable()
                      .notRequired()
                  }),
                  expiration: date().when("photo", {
                    is: photo => photo && photo.length !== 0,
                    then: date()
                      .typeError("Date format must be MM/DD/YYYY")
                      .min(pastDate, "Expiration Date cannot be in the past")
                      .required("Expiration date is required"),
                    otherwise: date()
                      .typeError("Date format must be MM/DD/YYYY")
                      .min(pastDate, "Expiration Date cannot be in the past")
                      .nullable()
                      .notRequired()
                  })
                },
                ["photo", "expiration"]
              ),
              inspection: object().shape(
                {
                  photo: array().when("expiration", {
                    is: expiration => expiration,
                    then: array().required(),
                    otherwise: array()
                      .nullable()
                      .notRequired()
                  }),
                  expiration: date().when("photo", {
                    is: photo => photo && photo.length !== 0,
                    then: date()
                      .typeError("Date format must be MM/DD/YYYY")
                      .min(pastDate, "Expiration Date cannot be in the past")
                      .required("Expiration date is required"),
                    otherwise: date()
                      .typeError("Date format must be MM/DD/YYYY")
                      .min(pastDate, "Expiration Date cannot be in the past")
                      .nullable()
                      .notRequired()
                  })
                },
                ["photo", "expiration"]
              )
            })
          })
        )
        .required("Required")
    });

    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (e) {
      e.inner.reduce((acc, error) => {
        if (!has(acc, error.path)) set(acc, error.path, error.message);
        return acc;
      }, errors);
    }

    return errors;
  };

  const asyncValidate = async (values, dispatch, props, blurredField) => {
    const currentAsyncErrors = get(props, "asyncErrors", {});

    const handleDispatch = (form, path, name, value) => {
      dispatch(change(form, `${path}.${name}`, value));
    };

    const handleVin = (vin, path) => {
      return new Promise(resolve => {
        asyncCarInfoValidation({ vin })
          .then(() => {
            return getCarInfoFromVin(vin);
          })
          .then(res => {
            const errorCodes = get(res, "Results[0].ErrorCode", "").split(",");
            const form = get(props, "form");

            if (!get(res, "Results[0].Make", null)) {
              return Promise.reject({
                vin: `Could not determine vehicle make. Please check VIN and try again.`
              });
            }

            if (!get(res, "Results[0].Model", null)) {
              return Promise.reject({
                vin: `Could not determine vehicle model. Please check VIN and try again.`
              });
            }

            if (!get(res, "Results[0].ModelYear", null)) {
              return Promise.reject({
                vin: `Could not determine vehicle year. Please check VIN and try again.`
              });
            }

            if (get(res, "Results[0].ModelYear") < firstAvailableYear) {
              return Promise.reject({
                vin: `Year must be at least ${firstAvailableYear}`
              });
            }

            if (errorCodes.length > 0) {
              switch (errorCodes[0]) {
                case "6":
                  return Promise.reject({ vin: "Incomplete VIN" });
                case "0":
                  //Non error
                  break;
                default:
                  return Promise.reject({
                    vin:
                      "Issue with VIN format. Double check that the VIN is 17 characters, no spaces, and is in the proper format."
                  });
              }
            }

            handleDispatch(form, path, "make", get(res, "Results[0].Make"));
            handleDispatch(form, path, "model", get(res, "Results[0].Model"));
            handleDispatch(
              form,
              path,
              "year",
              get(res, "Results[0].ModelYear")
            );
            resolve();
          })
          .catch(e => {
            const errors = {};
            set(
              errors,
              `${path}.vin`,
              e.vin
                ? e.vin
                : "Issue with VIN format. Double check that the VIN is 17 characters, no spaces, and is in the proper format."
            );
            resolve(errors);
          });
      });
    };

    if (blurredField) {
      const resp = await handleVin(
        get(values, blurredField),
        blurredField.slice(0, blurredField.indexOf("."))
      );
      if (resp) {
        //We have to merge with the current async errors because redux-form overrides all async errors whenever you return.
        throw merge(currentAsyncErrors, resp);
      }
    } else {
      const handleVinPromises = get(values, "cars", []).map((car, index) =>
        handleVin(car.vin, `cars[${index}]`)
      );

      const resp = await Promise.all(handleVinPromises);
      const errors = resp.filter(r => r !== undefined);

      if (errors.length > 0) {
        const mergedErrors = errors.reduce((acc, error) => {
          acc = merge(acc, error);
          return acc;
        }, {});

        throw mergedErrors;
      }
    }
  };

  const shouldAsyncValidate = params => {
    const { blurredField, trigger } = params;
    switch (trigger) {
      case "submit":
        return true;
      case "change": {
        if (blurredField && blurredField.includes("vin")) {
          return true;
        }
        break;
      }
      default:
        return false;
    }
  };

  return {
    onSubmit,
    initialValues,
    validate,
    asyncValidate,
    shouldAsyncValidate,
    loading,
    listedCarsProgress,
    totalCars,
    onSubmitFail: errors => scrollErrorIntoView(errors)
  };
};

const mapCarInputToCarDetails = values => {
  const registration =
    get(values, "documents.registration.photo[0]", null) &&
    get(values, "documents.registration.expiration")
      ? {
          registrationExpirationAt: values.documents.registration.expiration,
          registrationFile: values.documents.registration.photo[0]
        }
      : {};
  const insurance =
    get(values, "documents.insurance.photo[0]", null) &&
    get(values, "documents.insurance.expiration")
      ? {
          insuranceExpirationAt: values.documents.insurance.expiration,
          personalInsuranceFile: values.documents.insurance.photo[0]
        }
      : {};
  const inspection =
    get(values, "documents.inspection.photo[0]", null) &&
    get(values, "documents.inspection.expiration")
      ? {
          inspectionExpirationAt: values.documents.inspection.expiration,
          uberLyftInspectionFile: values.documents.inspection.photo[0]
        }
      : {};

  return {
    mileage: get(values, "odometer", null),
    vin: get(values, "vin", null),
    make: toPascalCaseWithSingleDelimiter(get(values, "make", "")),
    model: get(values, "model", null),
    year: get(values, "year", null),
    licensePlate: get(values, "plate", null),
    description: get(values, "description", null),
    photos: get(values, "photos", []),
    ...registration,
    ...insurance,
    ...inspection
  };
};

export const mileage_options = [
  { value: "250", label: "250 miles per day" },
  { value: "300", label: "300 miles per day" },
  { value: "350", label: "350 miles per day" },
  { value: "400", label: "400 miles per day" },
  { value: "450", label: "450 miles per day" },
  { value: "500", label: "500 miles per day" },
  { value: "550", label: "550 miles per day" },
  { value: "600", label: "600 miles per day" },
  { value: "650", label: "650 miles per day" },
  { value: "700", label: "700 miles per day" },
  { value: "750", label: "750 miles per day" },
  { value: "1000", label: "1000 miles per day" }
];

export const normalize = (value, previousValue) => {
  const regex = /^[0-9]*$/i;
  if (regex.test(value)) {
    const parsed = parseInt(value);
    return isNaN(parsed) ? "" : parsed;
  }

  return previousValue;
};
