import React, { Fragment, useContext } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import PropTypes from "prop-types";

import { FormTextField } from "Components/Inputs/FormTextField";
import { FormSelect } from "Components/Inputs/FormSelect";
import { validate } from "./validate";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { getEarnings } from "Utils/Helpers";
import { scrollErrorIntoView } from "Components/Forms/FormUtils";
import { Alert } from "@mui/material";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";

const mileage_options = [
  { value: "250", label: "250 miles per day" },
  { value: "350", label: "350 miles per day" },
  { value: "450", label: "450 miles per day" },
  { value: "550", label: "550 miles per day" },
  { value: "1000", label: "1000 miles per day" }
];

export const TermsFields = ({
  netDailyPrice,
  weeklyEarnings,
  monthlyEarnings,
  carYear,
  dailyRate
}) => {
  const { getJSONValue } = useContext(FirebaseContext);

  const priceRecommendations = getJSONValue("web_priceRecommendationTooltip");

  const carPriceRecommendation = priceRecommendations?.[carYear];
  const carMaxPriceRecommendation = carPriceRecommendation?.max;
  const showPriceWarning =
    carPriceRecommendation &&
    carMaxPriceRecommendation + carMaxPriceRecommendation * 0.1 <= dailyRate;

  return (
    <Fragment>
      <Typography fontWeight="bold">Set Rental Price</Typography>
      {carPriceRecommendation && (
        <Typography component="span" variant="subtitle1" gutterBottom>
          <>
            The best price range to maximize your earnings is between{" "}
            <strong>
              {`$${carPriceRecommendation.min}-$${carPriceRecommendation.max}`}
            </strong>{" "}
            based on our data of a {carYear} car.
          </>
        </Typography>
      )}
      <Grid mb={2} container spacing={3}>
        <Grid item xs={12}>
          <Field
            id="daily-price-field"
            name="daily_rate"
            label="Daily Price"
            variant="filled"
            fullWidth
            margin="normal"
            component={FormTextField}
            helperText={`Earn $${netDailyPrice} daily`}
            normalize={(value, previousValue) => {
              const regex = /^[0-9]*$/i;
              const parsed = parseInt(value);
              if (regex.test(value)) {
                return isNaN(parsed) ? "" : parsed;
              } else {
                return previousValue;
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
          {showPriceWarning && (
            <Alert severity="warning">
              Setting a higher daily rate for your vehicle may affect the amount
              of rental requests you receive
            </Alert>
          )}
        </Grid>
      </Grid>
      <Typography fontWeight="bold" mt={1}>
        Set discounts
      </Typography>
      <Typography>
        Drivers often book from owners who offer incentives to rent longer.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Field
            id="weekly-price-field"
            name="weekly_discount"
            label="Weekly Discount"
            variant="filled"
            fullWidth
            margin="normal"
            component={FormTextField}
            helperText={`Earn $${weeklyEarnings} weekly`}
            normalize={(value, previousValue) => {
              const regex = /^[0-9]*$/i;
              if (regex.test(value)) {
                const parsed = parseInt(value);
                return isNaN(parsed) ? "" : parsed;
              }

              return previousValue;
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            id="monthly-price-field"
            name="monthly_discount"
            label="Monthly Discount"
            variant="filled"
            fullWidth
            margin="normal"
            component={FormTextField}
            helperText={`Earn $${monthlyEarnings} monthly`}
            normalize={(value, previousValue) => {
              const regex = /^[0-9]*$/i;
              if (regex.test(value)) {
                const parsed = parseInt(value);
                return isNaN(parsed) ? "" : parsed;
              }

              return previousValue;
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Typography fontWeight="bold" mt={1}>
        Set daiy mileage cap
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            id="max-allowed-miles-field"
            name="maxDailyMiles"
            label="Daily Allowed Miles"
            variant="filled"
            fullWidth
            margin="normal"
            component={FormSelect}
            options={mileage_options}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

TermsFields.propTypes = {
  netDailyPrice: PropTypes.string,
  weeklyEarnings: PropTypes.string,
  monthlyEarnings: PropTypes.string
};

export const TermsForm = compose(
  connect(state => ({
    initialValues: state.fleet.listing.initialFormValues
  })),
  reduxForm({
    form: "LIST_CAR_FLOW",
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    onSubmitFail: errors => scrollErrorIntoView(errors)
  }),
  connect((state, { form }) => ({
    defaultProtectionPlan: formValueSelector(form)(
      state,
      "defaultProtectionPlan"
    ),
    daily_rate: formValueSelector(form)(state, "daily_rate"),
    weekly_discount: formValueSelector(form)(state, "weekly_discount"),
    monthly_discount: formValueSelector(form)(state, "monthly_discount"),
    carYear: formValueSelector(form)(state, "year"),
    dailyRate: formValueSelector(form)(state, "daily_rate")
  }))
)(
  ({
    handleSubmit,
    previousStep,
    defaultProtectionPlan,
    daily_rate,
    weekly_discount,
    monthly_discount,
    carYear,
    dailyRate
  }) => {
    const { netDailyPrice, weeklyEarnings, monthlyEarnings } = getEarnings({
      daily_rate,
      weekly_discount,
      monthly_discount,
      defaultProtectionPlan
    });

    return (
      <FlowContentContainer
        header="Pricing"
        nextStep={handleSubmit}
        nextStepLabel="Continue"
        previousStep={previousStep}
        previousStepLabel="Back"
        nextStepId="continue-pricing"
        previousStepId="back-pricing"
      >
        <TermsFields
          weeklyEarnings={weeklyEarnings}
          monthlyEarnings={monthlyEarnings}
          netDailyPrice={netDailyPrice}
          carYear={carYear}
          dailyRate={dailyRate}
        />
      </FlowContentContainer>
    );
  }
);
