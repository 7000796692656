import { gql } from "@apollo/client";

export const OwnerFragments = {
  ownerTransactions: gql`
    fragment OwnerTransaction on getTransactionsPayload {
      code
      success
      message
      totalRentals
      payouts
      refunds
      claimsPayments
    }
  `,
  ownerDefaultProtectionPlan: gql`
    fragment OwnerDefaultProtectionPlan on Owner {
      id
      defaultProtectionPlan
    }
  `,
  ownerConnectedIcc: gql`
    fragment OwnerConnectedIcc on Owner {
      id
      iccDealerAccount {
        id
        ownerId
        dealerId
        exportId
      }
    }
  `
};
