import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { reduxForm } from "redux-form";

import { useDocumentedListings } from "Queries/Fleet/hooks/useDocumentedListings";
import { DrawerBottomBar } from "Components/Drawers/DrawerBottomBar";
import CarListSkeleton from "Components/Car/CarListSkeleton";
import { useDebounce } from "Utils/useDebounce";
import SelectCarAppBar from "./selectCar/SelectCarAppBar";
import SelectCarOptions from "./selectCar/SelectCarOptions";

import { validate } from "./selectCar/validate";

const SelectCar = ({
  onClose,
  handleSubmit,
  prevStep,
  invalid,
  rental,
  change
}) => {
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const debouncedSearch = useDebounce(search, 200);
  const { cars, loading } = useDocumentedListings(rental.car);
  const { closeSnackbar } = useSnackbar();

  useEffect(() => () => closeSnackbar(), [closeSnackbar]);

  const handleSearch = e => {
    closeSnackbar();
    change("car", {});
    setSearch(e.target.value);
  };
  const openSearching = () => {
    closeSnackbar();
    setSearching(true);
  };
  const closeSearching = () => {
    setSearch("");
    setSearching(false);
    closeSnackbar();
  };

  const filteredCars = useMemo(() => {
    if (!searching) return cars;
    const searchWords = debouncedSearch
      .split(/[ ,]+/)
      .map(w => w.toLowerCase());
    return cars.filter(car => {
      const carData = [car.vin, car.make, car.model, car.year].map(field =>
        field.toLowerCase()
      );
      return searchWords.every(word =>
        carData.find(field => field.includes(word))
      );
    });
  }, [cars, debouncedSearch, searching]);

  let content = <SelectCarOptions cars={filteredCars} />;
  if (loading) content = <CarListSkeleton amount={5} />;
  return (
    <>
      <SelectCarAppBar
        searching={searching}
        handleSearch={handleSearch}
        searchValue={search}
        openSearching={openSearching}
        closeSearching={closeSearching}
        onClose={onClose}
      />
      {content}
      <DrawerBottomBar
        onCancel={prevStep}
        cancelText={"Back"}
        onSubmit={handleSubmit}
        submitDisabled={filteredCars.length ? invalid : true}
        submitText={"Next: Confirm"}
        disabled={false}
      />
    </>
  );
};

SelectCar.propTypes = {
  onClose: PropTypes.func,
  prevStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  rental: PropTypes.object,
  change: PropTypes.func
};

export default reduxForm({
  form: "CAR_SWITCH",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(SelectCar);
