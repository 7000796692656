import { combineReducers } from "redux";

import sendbird from "./SendBird/Reducers";
import cars from "./Cars/Reducers";
import analytics from "./Analytics/Reducers";
import financials from "./Financials/Reducers";
import header from "./Header/Reducers";
import navigation from "./Navigation/Reducers";
import listing from "./Listing/Reducers";
import ui from "./UI/Reducers";
import { RESET_APP } from "./Actions";

const fleetReducer = combineReducers({
  sendbird,
  cars,
  analytics,
  financials,
  header,
  navigation,
  ui,
  listing
});

export default (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }

  return fleetReducer(state, action);
};
