import gql from "graphql-tag";

import { InboxTemplateFragments } from "Queries/InboxTemplate/InboxTemplateFragments";

export const INBOX_TEMPLATES = gql`
  query InboxTemplates($id: ID!) {
    rooftop(input: { id: $id }) {
      id
      inboxTemplates {
        ...InboxTemplateInfo
      }
    }
  }
  ${InboxTemplateFragments.inboxTemplate}
`;
