import React, { useContext } from "react";
import { reduxForm } from "redux-form";

import { ColumnFlowLayout } from "Components/Layouts/ColumnFlowLayout";
import { FlowContentContainer } from "Components/Flows/FlowContentContainer";
import { StepperContext } from "./ClaimsDamageController";
import { UploadImagesFormFields } from "Components/Forms/FormFields/Claims/UploadImagesFormFields";
import { useClaimsDamageFormController } from "Components/Forms/FormControllers/hooks/useClaimsDamageFormController";

const ClaimsDamageImagesForm = reduxForm({
  form: "CLAIMS_DAMAGE",
  destroyOnUnmount: false
})(({ handleSubmit, prevStep, form }) => {
  return (
    <FlowContentContainer
      nextStep={handleSubmit}
      nextStepLabel="continue"
      nextStepId="continue-documentation"
      previousStep={prevStep}
      previousStepLabel="back"
      previousStepId="back-documentation"
      header="Upload Images of the damage"
    >
      <UploadImagesFormFields form={form} />
    </FlowContentContainer>
  );
});

export const ClaimsDamageImages = () => {
  const { nextStep, prevStep } = useContext(StepperContext);
  const { validate } = useClaimsDamageFormController();

  const leftComponent = (
    <ClaimsDamageImagesForm
      onSubmit={nextStep}
      prevStep={prevStep}
      validate={validate}
    />
  );

  return <ColumnFlowLayout leftComponent={leftComponent} />;
};
