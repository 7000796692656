import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { UPDATE_MISSING_INFO } from "Mutations/User/UserMutations";
import { emailRegex } from "Utils/Regex";
import { useSnackbar } from "notistack";
import ProfileCompleter from "./ProfileCompleter";
import { updateUserComunicationsConfig } from "API/restAPI";

const INVALID_EMAIL_ERROR = "Please enter a valid email";
const DUPLICATED_EMAIL_ERROR = "This email is already in use.";
const INVALID_PHONE_ERROR = "Please enter a valid phone";
const INVALID_ZIP_ERROR = "Please enter a valid zip";

const ProfileCompleterContainer = ({ userProfile }) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const [updateMissingInfo] = useMutation(UPDATE_MISSING_INFO, {
    client: currentRooftopClient
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  const [showProfileCompleter, setShowProfileCompleter] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [transactional, setTransactional] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const changeEmail = event => {
    setEmail(event.target.value);
    setEmailError(false);
  };
  const changePhoneNumber = event => {
    setPhoneNumber(event.target.value);
    setPhoneNumberError(false);
  };
  const changeZipCode = event => {
    setZipCode(event.target.value);
    setZipCodeError(false);
  };

  const me = userProfile?.viewer?.me;
  const userEmail = me?.email;
  const userPhoneNumber = me?.phone;
  const userZipCode = me?.location?.zip;

  useEffect(() => {
    if (!userEmail || !userPhoneNumber || !userZipCode) {
      if (userEmail) setEmail(userEmail);
      if (userPhoneNumber) setPhoneNumber(userPhoneNumber);
      if (userZipCode) setZipCode(userZipCode);
      setShowProfileCompleter(true);
    }
  }, [userEmail, userPhoneNumber, userZipCode]);

  const handleUpdateUserCommunicationsConfig = async (
    marketing,
    transactional
  ) => {
    try {
      await updateUserComunicationsConfig(!!marketing, !!transactional);
    } catch (error) {
      console.error("Error updating user subscriptions: ", error);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (validate()) {
      try {
        const response = await updateMissingInfo({
          variables: {
            email,
            phone: parsePhoneNumber(phoneNumber),
            zip: zipCode
          }
        });
        if (!response.data.updateProfile.user) {
          setSubmitLoading(false);
          return setEmailError(DUPLICATED_EMAIL_ERROR);
        }
        await handleUpdateUserCommunicationsConfig(marketing, transactional);

        setShowProfileCompleter(false);
      } catch (error) {
        enqueueSnackbar("An error occurred, please try again later", {
          variant: "error"
        });
      }
    }
    setSubmitLoading(false);
  };

  const parsePhoneNumber = phoneNumber => phoneNumber.replace(/[^0-9]/g, "");

  const validate = () => {
    let errors = 0;
    if (!email || !emailRegex.test(email)) {
      setEmailError(INVALID_EMAIL_ERROR);
      errors += 1;
    }
    if (!phoneNumber || parsePhoneNumber(phoneNumber).length !== 10) {
      setPhoneNumberError(INVALID_PHONE_ERROR);
      errors += 1;
    }
    if (!zipCode || zipCode.replace(/\s/g, "").length !== 5) {
      setZipCodeError(INVALID_ZIP_ERROR);
      errors += 1;
    }
    return !errors;
  };

  return (
    <ProfileCompleter
      {...{
        showProfileCompleter,
        email,
        changeEmail,
        emailError,
        phoneNumber,
        changePhoneNumber,
        phoneNumberError,
        zipCode,
        changeZipCode,
        zipCodeError,
        marketing,
        changeMarketing: setMarketing,
        transactional,
        changeTransactional: setTransactional,
        handleSubmit,
        validate,
        loading: submitLoading
      }}
    />
  );
};

export default ProfileCompleterContainer;
