import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  value: {
    paddingBottom: theme.spacing(2)
  }
}));

export const CardCell = ({ value, label, loading }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {loading ? (
        <>
          <Skeleton variant="text" width="100%" height={35} />
          <Skeleton variant="text" width="100%" />
        </>
      ) : (
        <>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.value}
          >
            <Typography component="span" variant="h4">
              {value}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Typography component="span" color="textSecondary" align="center">
              {label}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

CardCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool
};
