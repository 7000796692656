/* eslint-disable react/display-name */
import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import PropTypes from "prop-types";

import { RentalStatusEnum } from "Enums/StateEnums";

const useStylesStepIcon = makeStyles(theme => ({
  active: {
    color: theme.palette.common.white
  }
}));

const CustomStepIcon = props => {
  const classes = useStylesStepIcon();
  const { active, IconComponent } = props;

  return (
    <IconComponent
      className={classNames({
        [classes.active]: active
      })}
    />
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  IconComponent: PropTypes.elementType
};

const steps = ["Request", "Accepted", "Check-in", "Active", "Completed"];

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0, 6, 0)
  },
  stepperRoot: {
    padding: "0",
    background: "rgba(0, 0, 0, 0)"
  },
  stepLabel: {
    color: theme.palette.common.white,
    "&$stepLabelActive, &$stepLabelCompleted, &$stepLabelError, &$stepLabelDisabled": {
      color: theme.palette.common.white
    },
    "&$stepAlternativeLabel": {
      marginTop: theme.spacing(1)
    }
  },
  stepLabelActive: {},
  stepLabelCompleted: {},
  stepLabelError: {},
  stepLabelDisabled: {},
  stepAlternativeLabel: {},
  stepIcon: {
    color: "rgba(255, 255, 255, 0.6)",
    "&$stepIconCompleted, &$stepIconError": {
      color: "rgba(255, 255, 255, 0.6)"
    },
    "&$stepIconActive": {
      color: theme.palette.common.white
    }
  },
  stepIconActive: {},
  stepIconCompleted: {},
  stepIconError: {},
  stepIconText: {
    color: theme.palette.primary[900],
    fill: theme.palette.primary[900]
  }
}));

const getActiveCurrentStepperProps = rentalStatus => {
  switch (rentalStatus) {
    case RentalStatusEnum.pendingInsurance:
      return {
        activeStep: 1,
        stepLabelProps: {
          StepIconComponent: props => (
            <CustomStepIcon
              {...props}
              IconComponent={NotificationImportantIcon}
            />
          )
        }
      };
    case RentalStatusEnum.pendingPickup:
      return {
        activeStep: 2,
        stepLabelProps: {}
      };
    case RentalStatusEnum.active:
      return {
        activeStep: 3,
        stepLabelProps: {}
      };
    case RentalStatusEnum.late:
      return {
        activeStep: 3,
        stepLabelProps: {
          StepIconComponent: props => (
            <CustomStepIcon {...props} IconComponent={WarningIcon} />
          )
        }
      };
    case RentalStatusEnum.completed:
      return {
        activeStep: 4,
        stepLabelProps: {}
      };
    case RentalStatusEnum.appliedNotVerified:
    case RentalStatusEnum.applicationAutoRejected:
    case RentalStatusEnum.applicationRejected:
    case RentalStatusEnum.applied:
    case RentalStatusEnum.archived:
    default:
      return {
        activeStep: 0,
        stepLabelProps: {}
      };
  }
};

export const RentalProgressIndicator = ({ rentalStatus, ...rest }) => {
  const classes = useStyles(rest);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { activeStep, stepLabelProps } = getActiveCurrentStepperProps(
    rentalStatus
  );

  return matches ? (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        classes={{
          root: classes.stepperRoot
        }}
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              {index === activeStep ? (
                <StepLabel
                  disabled
                  classes={{
                    label: classes.stepLabel,
                    active: classes.stepLabelActive,
                    completed: classes.stepLabelCompleted,
                    error: classes.stepLabelError,
                    disabled: classes.stepLabelDisabled,
                    alternativeLabel: classes.stepAlternativeLabel
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.stepIcon,
                      active: classes.stepIconActive,
                      completed: classes.stepIconCompleted,
                      error: classes.stepIconError,
                      text: classes.stepIconText
                    }
                  }}
                  {...stepLabelProps}
                >
                  {label}
                </StepLabel>
              ) : (
                <StepLabel
                  disabled
                  classes={{
                    label: classes.stepLabel,
                    active: classes.stepLabelActive,
                    completed: classes.stepLabelCompleted,
                    error: classes.stepLabelError,
                    disabled: classes.stepLabelDisabled,
                    alternativeLabel: classes.stepAlternativeLabel
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.stepIcon,
                      active: classes.stepIconActive,
                      completed: classes.stepIconCompleted,
                      error: classes.stepIconError,
                      text: classes.stepIconText
                    }
                  }}
                >
                  {label}
                </StepLabel>
              )}
            </Step>
          );
        })}
      </Stepper>
    </div>
  ) : null;
};
