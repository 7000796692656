import { gql } from "@apollo/client";

export const RentalMutationFragments = {
  approveRejectBookingPayload: gql`
    fragment ApproveRejectBookingPayload on ApproveRejectBookingPayload {
      code
      success
      message
      rental {
        id
        status
      }
    }
  `,
  toggleRentalExtensionPayload: gql`
    fragment ToggleRentalExtensionPayload on ToggleRentalExtensionPayload {
      code
      success
      message
      rental {
        id
        disallowExtensions
      }
    }
  `,
  confirmPickupPayload: gql`
    fragment ConfirmPickupPayload on ConfirmPickupPayload {
      code
      success
      message
      rental {
        id
        status
      }
    }
  `,
  confirmDropoffPayload: gql`
    fragment ConfirmDropoffPayload on ConfirmDropoffPayload {
      code
      success
      message
      rental {
        id
        status
      }
    }
  `,
  switchRentalCarPayload: gql`
    fragment SwitchRentalCarPayload on SwitchRentalCarPayload {
      code
      success
      message
      rental {
        id
        status
      }
      insuranceError
    }
  `
};
