import React, { useState, useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ImageIcon from "@mui/icons-material/Image";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  progressContainer: {
    position: "relative",
    display: "flex",
    width: "300px",
    height: "300px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    margin: "0 auto"
  },
  progressIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    width: "300px",
    height: "150px",
    textAlign: "center",
    transformOrigin: "bottom center",
    opacity: props => (props.progress >= 100 ? 1 : 0),
    animation: `3s $rotate infinite`,
    animationIterationCount: props => (props.progress >= 100 ? 0 : "infinite")
  },
  icon: {
    fontSize: "64px"
  },
  checkCircleIcon: {
    fontSize: "64px",
    color: "#00C853"
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(90deg)"
    },
    "10%": {
      opacity: 0
    },
    "35%": {
      transform: "rotate(0deg)",
      opacity: 1
    },
    "65%": {
      transform: "rotate(0deg)",
      opacity: 1
    },
    "80%": {
      opacity: 0
    },
    "100%": {
      transform: "rotate(-90deg)"
    }
  }
}));

export const CSVProgressBar = ({ progress, total }) => {
  const [text, setText] = useState("");
  const [icon, setIcon] = useState("");
  const [percentage, setPercentage] = useState(0);
  const classes = useStyles({ percentage });

  const changeText = useCallback(
    value => {
      switch (true) {
        case value <= 10:
          setText("Creating your listings...");
          setIcon(<LocalTaxiIcon color="primary" className={classes.icon} />);
          break;
        case value > 10 && value <= 25:
          setText("Setting your pricing...");
          setIcon(<AttachMoneyIcon color="primary" className={classes.icon} />);
          break;
        case value > 25 && value <= 60:
          setText("Uploading images...");
          setIcon(<ImageIcon color="primary" className={classes.icon} />);
          break;
        case value > 60 && value <= 99:
          setText("Attaching documents...");
          setIcon(<AssignmentIcon color="primary" className={classes.icon} />);
          break;
        case value >= 100:
          setText("Done!");
          setIcon(<CheckCircleIcon className={classes.checkCircleIcon} />);
          break;
        default:
          setText("Creating your listings");
      }
    },
    [classes.icon, classes.checkCircleIcon]
  );

  useEffect(() => {
    const calculatedPercentage = Math.trunc(
      total === 0 ? 0 : (progress / total) * 100
    );
    setPercentage(calculatedPercentage);
    changeText(calculatedPercentage);
  }, [progress, total, changeText]);

  return (
    <div className={classes.progressContainer}>
      <div className={classes.progressIcon}>{icon}</div>
      <Typography variant="h3" style={{ marginBottom: "16px" }}>
        {percentage}%
      </Typography>
      <Typography variant="h5">{text}</Typography>
    </div>
  );
};
