import React, { useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ImageIcon from "@mui/icons-material/Image";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import get from "lodash/get";
import set from "lodash/set";
import map from "lodash/map";
import concat from "lodash/concat";

import { centsToDollars } from "Utils/Calculations";
import { FLEET_LISTINGS } from "../FleetQueries";
import { OWNER_DEFAULT_PROTECTION_PLAN } from "Queries/Owner/OwnerQueries";
import { ListingStatusDotIndicator } from "Components/Car/ListingStatusDotIndicator";
import { getListingStatusOptions } from "Components/hooks/useListingStatusOptions";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { snakeToProperCase } from "Utils/Helpers";

export const useMapListingData = (data, defaultProtectionPlan) => {
  const cars = get(data, "viewer.me.owner.cars.edges", []);
  const theme = useTheme();

  return map(cars, ({ node }) => {
    const { copy } = getListingStatusOptions(
      node.status,
      node.verification,
      theme
    );
    const photo = get(node, "photos[0].file.url", null);

    return {
      id: node.id,
      vin: node.vin,
      protectionPlan: node.defaultProtectionPlan
        ? snakeToProperCase(node.defaultProtectionPlan)
        : snakeToProperCase(defaultProtectionPlan),
      car: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {photo ? (
            <Avatar
              alt="Driver"
              src={photo}
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <Avatar style={{ width: "30px", height: "30px" }}>
              <ImageIcon />
            </Avatar>
          )}
          <span style={{ marginLeft: "16px" }}>
            {`${node.year} ${node.make} ${node.model}`}
          </span>
        </div>
      ),
      dailyPriceInDollars: `$${centsToDollars(node.dailyPriceInCents)}`,
      updatedAt: moment(node.updatedAt).format("MM/DD/Y"),
      status: node.status,
      verification: node.verification,
      statusTableCell: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListingStatusDotIndicator
            status={node.status}
            verification={node.verification}
          />
          <Typography
            component="p"
            variant="body2"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {copy}
          </Typography>
        </div>
      ),
      yearMakeModel: `${node.year} ${node.make} ${node.model}`,
      carPhoto: <>{photo && <img alt="Driver" src={photo} />}</>,
      carSummary: node,
      tableRowGrey: node.status === "DELETED"
    };
  });
};

const useLoadMore = (loading, error, fetchMore, pageInfo) => {
  const [fetchingMore, updateFetchingMore] = useState(false);
  const { hasNextPage, endCursor } = pageInfo;
  const first = 10;

  const fetchMoreData = () => {
    if (!loading && !error) {
      if (hasNextPage) {
        updateFetchingMore(true);
        fetchMore({
          variables: {
            first: first,
            after: endCursor
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            const previousEdges = get(
              previousResult,
              "viewer.me.owner.cars.edges",
              []
            );
            const nextEdges = get(
              fetchMoreResult,
              "viewer.me.owner.cars.edges",
              []
            );
            const nextPageInfo = get(
              fetchMoreResult,
              "viewer.me.owner.cars.pageInfo",
              {}
            );

            set(
              fetchMoreResult,
              "viewer.me.owner.cars.edges",
              concat(previousEdges, nextEdges)
            );
            set(fetchMoreResult, "viewer.me.owner.cars.pageInfo", nextPageInfo);
            return fetchMoreResult;
          }
        })
          .then(() => {
            updateFetchingMore(false);
          })
          .catch(() => {
            updateFetchingMore(false);
          });
      }
    }
  };

  return {
    fetchingMore,
    fetchMoreData
  };
};

export const useListingsTable = options => {
  const { data, loading, error, fetchMore } = useQuery(FLEET_LISTINGS, options);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const {
    data: defaultProtectionPlanData,
    loading: defaultProtectionPlanLoading
  } = useQuery(OWNER_DEFAULT_PROTECTION_PLAN, {
    fetchPolicy: "network-only",
    client: currentRooftopClient
  });
  const pageInfo = get(data, "viewer.me.owner.cars.pageInfo", {});
  const mappedData = useMapListingData(
    data,
    get(defaultProtectionPlanData, "viewer.me.owner.defaultProtectionPlan", "")
  );
  const { fetchingMore, fetchMoreData } = useLoadMore(
    loading,
    error,
    fetchMore,
    pageInfo
  );

  return {
    data,
    mappedData,
    pageInfo,
    loading: loading || defaultProtectionPlanLoading,
    error,
    fetchingMore,
    fetchMoreData
  };
};
