import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import { Field } from "redux-form";
import makeStyles from "@mui/styles/makeStyles";

import { FormTextField } from "Components/Inputs/FormTextField";
import { PhoneMask } from "Components/Forms/InputMasks/PhoneMask";
import { LocationAutoComplete } from "Components/Forms/Location/LocationAutoComplete";

const useStyles = makeStyles(() => ({
  singleLineInput: {
    marginBottom: "24px"
  }
}));
export const ProfileAccountInfoFields = ({ loading, shouldDisplayBio }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid item xs={12} md={6}>
        <Field
          className={classes.singleLineInput}
          name="firstName"
          label="First Name"
          placeholder="First Name"
          fullWidth
          variant="filled"
          component={FormTextField}
          showSkeleton={loading}
        />
        <Field
          className={classes.singleLineInput}
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
          fullWidth
          variant="filled"
          component={FormTextField}
          showSkeleton={loading}
        />
        <Field
          name="phone"
          label="Phone Number"
          placeholder="Phone Number"
          fullWidth
          variant="filled"
          component={FormTextField}
          inputComponent={PhoneMask}
          showSkeleton={loading}
        />
      </Grid>
      {shouldDisplayBio ? (
        <Grid item xs={12} md={6}>
          <Field
            name="about"
            label="Bio"
            placeholder="Let drivers know what sets you apart. Do you offer special services like pickup and dropoff? Are you only available during certain days or hours?"
            component={FormTextField}
            multiline
            rows={5}
            fullWidth
            variant="filled"
            showSkeleton={loading}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      ) : null}
    </Fragment>
  );
};

export const ProfileLocationFields = ({ loading }) => {
  return (
    <Fragment>
      <Grid item xs={12} md={6}>
        <Field
          name="address"
          inputLabel="Home Address"
          fullWidth
          variant="filled"
          component={LocationAutoComplete}
          showSkeleton={loading}
          showMap={false}
        />
      </Grid>
    </Fragment>
  );
};
