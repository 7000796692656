const timeoutAnimation = {
  v: "5.0.1",
  fr: 60,
  ip: 0,
  op: 245,
  w: 120,
  h: 140,
  assets: [],
  layers: [
    {
      ind: 1,
      nm: "Layer 1",
      ks: {
        p: { a: 0, k: [60, 56] },
        a: { a: 0, k: [51, 51, 0] },
        s: { a: 0, k: [100, 100, 100] },
        r: { a: 0, k: 0 },
        o: { a: 0, k: 100 }
      },
      ao: 0,
      ip: 0,
      op: 245,
      st: 0,
      bm: 0,
      sr: 1,
      ty: 4,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ty: "sh",
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [0, 0],
                        [-23.5, -15]
                      ],
                      c: false
                    },
                    hd: false
                  }
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0.584, 0.584, 0.584, 1] },
                  o: { a: 0, k: 100 },
                  w: { a: 0, k: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [51, 51.5] },
                  a: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  r: {
                    a: 1,
                    k: [
                      {
                        t: 28,
                        s: [0],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [21]
                      },
                      {
                        t: 43,
                        s: [21],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [-13.5]
                      },
                      {
                        t: 54,
                        s: [-13.5],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [19]
                      },
                      {
                        t: 67,
                        s: [19],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [19]
                      },
                      {
                        t: 78,
                        s: [19],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [-93]
                      },
                      {
                        t: 100,
                        s: [-93],
                        i: { x: [0.15], y: [0.86] },
                        o: { x: [0.785], y: [0.135] },
                        e: [-93]
                      },
                      { t: 245, s: [-93] }
                    ]
                  },
                  o: { a: 0, k: 100 },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              nm: "Object",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ty: "sh",
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [0, 0],
                        [12.5, -14]
                      ],
                      c: false
                    },
                    hd: false
                  }
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0.584, 0.584, 0.584, 1] },
                  o: { a: 0, k: 100 },
                  w: { a: 0, k: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [51, 51.5] },
                  a: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  r: {
                    a: 1,
                    k: [
                      {
                        t: 28,
                        s: [0],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [-20]
                      },
                      {
                        t: 43,
                        s: [-20],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [28]
                      },
                      {
                        t: 54,
                        s: [28],
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        e: [-13]
                      },
                      {
                        t: 67,
                        s: [-13],
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        e: [-13]
                      },
                      {
                        t: 78,
                        s: [-13],
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        e: [114]
                      },
                      { t: 100, s: [114] }
                    ]
                  },
                  o: { a: 0, k: 100 },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              nm: "Object",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ty: "sh",
                  d: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.472], y: [0.715] },
                        o: { x: [0.955], y: [0.127] },
                        t: 26,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -51],
                              [51, 0]
                            ],
                            c: true
                          }
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -63.403],
                              [51, 0]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: [0.15], y: [0.86] },
                        o: { x: [0.23], y: [0.445] },
                        t: 39,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -63.403],
                              [51, 0]
                            ],
                            c: true
                          }
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -51],
                              [51, 0]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: [0.15], y: [0.86] },
                        o: { x: [0.785], y: [0.135] },
                        t: 56,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -51],
                              [51, 0]
                            ],
                            c: true
                          }
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -51],
                              [51, 0]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        t: 67,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -51],
                              [51, 0]
                            ],
                            c: true
                          }
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -61],
                              [51, 0]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: [0.175], y: [1] },
                        o: { x: [0.77], y: [0] },
                        t: 85,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -61],
                              [51, 0]
                            ],
                            c: true
                          }
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [28.167, 0],
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166]
                            ],
                            o: [
                              [0, 28.167],
                              [-28.166, 0],
                              [0, -28.166],
                              [29, 0],
                              [0, 0]
                            ],
                            v: [
                              [51, 0],
                              [0, 51],
                              [-51, 0],
                              [0, -51],
                              [51, 0]
                            ],
                            c: true
                          }
                        ]
                      },
                      { t: 99 }
                    ],
                    hd: false
                  }
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0.584, 0.584, 0.584, 1] },
                  o: { a: 0, k: 100 },
                  w: { a: 0, k: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [51, 51] },
                  a: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              nm: "Object",
              hd: false
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    t: 26,
                    s: [51, 51],
                    i: { x: [0.15], y: [0.86] },
                    o: { x: [0.785], y: [0.135] },
                    e: [51, 74]
                  },
                  {
                    t: 45,
                    s: [51, 74],
                    i: { x: [0.15], y: [0.86] },
                    o: { x: [0.785], y: [0.135] },
                    e: [51, 54]
                  },
                  {
                    t: 56,
                    s: [51, 54],
                    i: { x: [0.15], y: [0.86] },
                    o: { x: [0.785], y: [0.135] },
                    e: [51, 49]
                  },
                  {
                    t: 67,
                    s: [51, 49],
                    i: { x: [0.15], y: [0.86] },
                    o: { x: [0.785], y: [0.135] },
                    e: [51, 79]
                  },
                  { t: 87, s: [51, 79] }
                ]
              },
              a: { a: 0, k: [51, 51] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 }
            }
          ],
          nm: "Object",
          hd: false
        }
      ]
    }
  ]
};

export default timeoutAnimation;
