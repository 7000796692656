import React, { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationIcon from "@mui/icons-material/LocationOn";

import { PhoneMask } from "Components/Forms/InputMasks/PhoneMask";
import { ZipMask } from "Components/Forms/InputMasks/ZipMask";
import Field from "./Field";
import { USER_COMMUNICATION_OPTIONS } from "Login/SignupFlow/UserComunicationOptions";
import { Checkbox } from "./Checkbox";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { analytics } from "Analytics/index";

const ProfileCompleter = ({
  showProfileCompleter,
  email,
  changeEmail,
  emailError,
  phoneNumber,
  changePhoneNumber,
  phoneNumberError,
  zipCode,
  changeZipCode,
  zipCodeError,
  marketing,
  changeMarketing,
  transactional,
  changeTransactional,
  handleSubmit,
  validate,
  loading
}) => {
  const { getLink } = useContext(FirebaseContext);

  const openNewTab = (url, event) => {
    analytics.track(event, {
      category: AnalyticsEvents.category.userInteraction,
      action: AnalyticsEvents.action.buttonClicked,
      label: event,
      property: JSON.stringify({
        flow: AnalyticsEvents.flow.onboarding
      }),
      value: "",
      context: ""
    });
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const viewTOS = () => {
    openNewTab(getLink("tos"), AnalyticsEvents.label.auth.viewTOSClicked);
  };

  const viewPrivacyPolicy = () => {
    openNewTab(
      getLink("privacyPolicy"),
      AnalyticsEvents.label.auth.viewPrivacyPolicyClicked
    );
  };

  const privacyPolicy = (
    <Link
      onClick={viewPrivacyPolicy}
      sx={{ cursor: "pointer" }}
      underline="hover"
    >
      Privacy Policy
    </Link>
  );

  const termsOfService = (
    <Link onClick={viewTOS} sx={{ cursor: "pointer" }} underline="hover">
      Terms of Service
    </Link>
  );

  const googlePrivacyPolicy = (
    <Link
      href="https://policies.google.com/privacy"
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
    >
      Privacy Policy
    </Link>
  );

  const googleTermsOfService = (
    <Link
      href="https://policies.google.com/terms"
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
    >
      Terms of Service
    </Link>
  );

  return (
    <Dialog open={showProfileCompleter}>
      <DialogTitle>
        <Typography color="black" variant="h5">
          Complete your profile
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <DialogContentText sx={{ marginBottom: 3 }}>
          This information is required so we can contact you about important
          updates related to your rental.
        </DialogContentText>
        <Field
          label="Email address"
          value={email}
          onChange={changeEmail}
          validate={validate}
          icon={<EmailIcon />}
          error={emailError}
          helperText={emailError || " "}
        />
        <Field
          label="Phone number"
          value={phoneNumber}
          onChange={changePhoneNumber}
          validate={validate}
          icon={<PhoneIcon />}
          error={phoneNumberError}
          helperText={phoneNumberError || " "}
          inputComponent={PhoneMask}
        />
        <Field
          label="ZIP code"
          value={zipCode}
          onChange={changeZipCode}
          validate={validate}
          icon={<LocationIcon />}
          error={zipCodeError}
          helperText={zipCodeError || " "}
          inputComponent={ZipMask}
        />
        <Checkbox
          label={USER_COMMUNICATION_OPTIONS.marketing}
          name="marketing"
          onChange={changeMarketing}
          value={marketing}
        />
        <Checkbox
          label={USER_COMMUNICATION_OPTIONS.transactional}
          name="transactional"
          onChange={changeTransactional}
          value={transactional}
        />
        <Typography sx={{ mt: 1 }} component="p" variant="caption">
          By selecting the checkboxes above, I agree to receive text messages
          from HyreCar to the phone number provided. Message and data rates may
          apply. Message frequency varies. Text HELP for help. Text STOP to
          stop.
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          sx={{ marginRight: 2 }}
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
      <DialogContent sx={{ overflowY: "visible" }}>
        <Typography
          sx={{
            textAlign: "right"
          }}
          component="p"
          variant="caption"
        >
          {`By signing up, I agree to HyreCar's `}
          {privacyPolicy} and {termsOfService}. <br />
          This site is protected by reCAPTCHA and the Google{" "}
          {googlePrivacyPolicy} and {googleTermsOfService} apply.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileCompleter;
