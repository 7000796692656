import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import SnoozeIcon from "@mui/icons-material/Snooze";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import WarningIcon from "@mui/icons-material/Warning";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useTheme } from "@mui/material/styles";

import {
  CarStatusEnum,
  CarVerificationEnum,
  ListingStatusCopyEnum
} from "Enums/StateEnums";

export const getListingStatusOptions = (status, verification, theme) => {
  switch (status) {
    case CarStatusEnum.available: {
      switch (verification) {
        case CarVerificationEnum.verified:
        default:
          return {
            copy: ListingStatusCopyEnum.live,
            color: theme.palette.primary[900],
            Icon: OfflineBoltIcon
          };
      }
    }
    case CarStatusEnum.removed: {
      switch (verification) {
        case CarVerificationEnum.verified:
          return {
            copy: ListingStatusCopyEnum.snoozed,
            color: theme.palette.grey[500],
            Icon: SnoozeIcon
          };
        case CarVerificationEnum.pendingVerification:
          return {
            copy: ListingStatusCopyEnum.pending,
            color: theme.palette.grey[500],
            Icon: AccessTimeIcon
          };
        case CarVerificationEnum.incomplete:
          return {
            copy: ListingStatusCopyEnum.unverified,
            color: theme.palette.warning.dark,
            Icon: TurnedInNotIcon
          };
        case CarVerificationEnum.failedVerification:
        default:
          return {
            copy: ListingStatusCopyEnum.failed,
            color: theme.palette.error.dark,
            Icon: WarningIcon
          };
      }
    }
    case CarStatusEnum.rented:
      switch (verification) {
        case CarVerificationEnum.verified:
        default:
          return {
            copy: ListingStatusCopyEnum.rented,
            color: theme.palette.success.dark,
            Icon: EventAvailableIcon
          };
      }
    case CarStatusEnum.deleted:
      return {
        copy: ListingStatusCopyEnum.deleted,
        color: "#666666",
        Icon: WarningIcon,
        borderColoring: true
      };
    default:
      return {
        copy: ListingStatusCopyEnum.failed,
        color: theme.palette.error,
        Icon: WarningIcon
      };
  }
};

export const useListingStatusOptions = (status, verification) => {
  const theme = useTheme();

  return getListingStatusOptions(status, verification, theme);
};
