import React from "react";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3)
  },
  header: ({ defaultHeader }) => ({
    ...(!defaultHeader && {
      color: theme.palette.common.black
    })
  }),
  icon: {
    marginRight: theme.spacing(2)
  }
}));

export const TooltipContainer = ({
  header,
  defaultHeader = false,
  useIcon,
  children
}) => {
  const classes = useStyles({ defaultHeader });

  return (
    <div>
      <div className={classes.headerWrapper}>
        {useIcon && <HelpOutlineIcon className={classes.icon} />}
        {header && (
          <Typography component="h6" variant="h6" className={classes.header}>
            {header}
          </Typography>
        )}
      </div>

      {children}
    </div>
  );
};

TooltipContainer.propTypes = {
  header: PropTypes.string,
  defaultHeader: PropTypes.bool,
  useIcon: PropTypes.bool,
  children: PropTypes.node
};
