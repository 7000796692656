import { useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { RentalStatusEnum } from "Enums/StateEnums";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { RENTALS_QUERY } from "Queries/Rentals/RentalQueries";

const getDatePlusDays = increment => {
  return moment()
    .startOf("day")
    .add(increment, "days");
};

export const useNearRentals = () => {
  const now = getDatePlusDays(0);
  const oneDay = getDatePlusDays(1);
  const twoDays = getDatePlusDays(2);
  const { currentRooftopClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(RENTALS_QUERY, {
    variables: {
      filter: {
        status_in: [
          RentalStatusEnum.pendingInsurance,
          RentalStatusEnum.pendingPickup
        ],
        requestedPickupAt_gte: now,
        requestedPickupAt_lt: twoDays
      }
    },
    client: currentRooftopClient,
    fetchPolicy: "network-only"
  });

  const { rentalsToday, rentalsTomorrow } = useMemo(() => {
    const rentalsToday = [];
    const rentalsTomorrow = [];
    const rentals = data?.viewer?.me.owner.rentalsConnection.edges || [];
    rentals.forEach(({ node }) => {
      if (new Date(node.requestedPickupAt) < oneDay.toDate()) {
        rentalsToday.push(node);
      } else {
        rentalsTomorrow.push(node);
      }
    });
    return { rentalsToday, rentalsTomorrow };
  }, [data, oneDay]);

  return { rentalsToday, rentalsTomorrow, loading };
};
